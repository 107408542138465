import {useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend,} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import chartOptions from "./charts.config";
//
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);



export default function WeightCharts(props){

    const {data , error , isLoading } = useFetch(apis.stats.weight())

    const labels = data?.weight_data?.map((item) => item.name)


     const chartData = {
        labels ,
        datasets: [
            {
                label: 'الاوازن',
                data: labels?.map((l , index) => data?.weight_data[index].ratio),
                backgroundColor :'#F69323'
            },
        ],
    };

    return(
        <>
           <header> مخطط اجمالي انتاج الكراتين حسب الاوزان </header>
            <div className="center-container">
                <Bar  data={chartData} options={chartOptions.weights}  />
            </div>
        </>
    )
}
