import React, { useEffect } from 'react'
import axiosPost from '../../../helpers/axios/axios.post'
import { apis } from '../../../config/apis'
import { showErrorToast } from '../../../stores/toast.store'

export default function useBreadCrumb({id , name}) {



    const [data , setData] = React.useState([])
    const [loading , setLoading] = React.useState(false)


    useEffect(() => {

        const fetch = async () => {
            setLoading(true)

            const { data  , error} = await axiosPost(apis.helpers.breadCrumbs() , {
                id,
                 name
               })
            setLoading(false)
            if(error) return showErrorToast(error)

            setData(data)

        }

        fetch()


    } , [id , name])



   return {
       data ,
       loading
   }
 
}
