import React, { useEffect, useMemo, useState } from "react"
import { apis } from "../../../config/apis"
import useFetch from "../../../hooks/useFetch"
import { InputSelect } from "../../../config/imports"
import { accio } from "../../../helpers/axios/axios.get"

 const useStockDrugs = (type)=>{

    const [options , setOptions] = useState([])
    let   [Input , setInput] = useState(<></>);

    useEffect(() => {
        
      if(type !== "drug") {
        setOptions([])
        setInput(<></>)
        return 
      }

      const fetch = async ()=>{
          const response = await accio( apis.origins.drug.base()) 
          const {data , error} = response
          if(error) {
            setOptions([])
            setInput(<></>)
            return
          }
          setOptions(data?.map(d => ({value : d?._id , text : d?.name})))
          setInput( <InputSelect  options={options}  />  )
      }

      fetch()
   
    } , [type])


    return { 

      Input : (props) => React.cloneElement(Input , {options , ...props}),
      options

    }










}

export default useStockDrugs