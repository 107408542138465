import { useMemo, useState } from "react";
import { apis } from "../../../../../config/apis";
import { setInputsToDefault, updateFormHandler } from "../../../../../helpers/form.helper";
import { EditForm, InputNumber, InputSelect, InputTextArea, Signboard } from "../../../../../config/imports";
import { useParams } from "react-router-dom";
import useStockDishes from "../../../../stocks/hooks/useStockDishes";

export default function Update(props){
    const {id : home_id } = useParams()

    const {toggle , item , setRefresh , types } = props
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        home_id,
        type:item.type,
        unit:item.unit,
        quantity:item.quantity,
        info:item.info,
    })

    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const {  options   } = useStockDishes("dish")

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.orders.dishes.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=>{},
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };
    



    const measuringUnits = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])


    return(
        <>
            
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>تعديل بيانات الطلب</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>
            <EditForm visible={true} onSubmit={handleFormSubmit}  toggle={toggle}  onChange={handleInputChange}>
                <InputSelect      name={'type'} title={'النوع '} defaultValue={data.type}  options={options} />
                <InputSelect      name={'unit'} title={'وحدة القياس '} defaultValue={data.unit}  options={measuringUnits} />
                {/* <InputMassUnit name={'unit'} title={'وحدة القياس'} value={data.unit} setData={setData}   /> */}
                <InputNumber   name={'quantity'} title={'الكمية المطلوبة'} defaultValue={data.quantity} onChange={handleInputChange} />
                <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />

            </EditForm>
        </>
    )
}
