import {InputText , InputNumber} from "../../../../config/imports";

const roadsInputs = [
    {name : 'name', title :  'أسم الطريق', input : InputText},
    {name : 'count', title :  'عدد', input : InputNumber},
    {name : 'value', title :  'قيمة الطريق', input : InputText},
    {name : 'length', title :  'طول الطريق', input : InputNumber},
    {name : 'width', title :  'عرض الطريق', input : InputNumber},
    {name : 'area', title :  'مساحة الطريق', input : InputNumber},
    {name : 'account_name', title :  'أسم الحساب', input : InputText},
    {name : 'account_symbol', title :  'رمز الحساب', input : InputText},
    {name : 'location', title :  'موقعها', input : InputText},
]
export default roadsInputs
