import { useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import updateData from "../../../../../helpers/axios/axios.put";
import { originAPI, operatorAPI } from "../../../origin.helpers";

import {
  validateForm,
  checkIfInputErrors,
  catchInputsWithErrors,
  setParent,
  setInputsToDefault,
  hasDataChanged,
} from "../../../../../helpers/form.helper";
import { errorHandler } from "../../../../../helpers/error.helper";
import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";

const UPDATE_SUCCESS_CODE = 200;

const UpdateFeed = ({ toggle , setFeedData, item, operators }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const [loading, setLoading] = useState(false);

  const [isDataChanged, setIsDataChanged] = useState(false);
  const [data, setData] = useState({
    name: item.name,
    measuringUnit: item.measuringUnit,
    operator: item.operator,
    info: item.info,
  });

  useEffect(() => {
    setParent(".temp-form");

    const ischanged = hasDataChanged(item, data);

    setIsDataChanged(ischanged);
  }, [data]);


  const handleFormSubmit = async (e) => {
    setLoading(true);
    setIsDataChanged(false);
    setError("");
    setSuccess("");



    checkIfInputErrors();

    const {
      data: updates,
      error,
      statusCode,
    } = await updateData(originAPI.feed + "/" + item._id, data);

    if (error) {
      setLoading(false);
      setIsDataChanged(false);
      setSuccess("");
      return setError(errorHandler(error));
    }

    if (statusCode === UPDATE_SUCCESS_CODE && updates.data) {
      setLoading(false);
      setError("");
      setSuccess("تم تعديل البيانات بنجاح");
      setFeedData((prevState) => {
        const itemIndex = prevState.findIndex(
          (item) => item._id === updates.data._id
        );
        prevState[itemIndex] = updates.data;
        return [...prevState];
      });

      setIsDataChanged(false);
    }
  };

  const handleInputChange = (e) => {
    if (e.target.name === "operator") {
      setData((prevState) => ({
        ...prevState,
        [e.target.name]: {
          _id: e.target.value,
          name: e.target.options[e.target.selectedIndex].text,
        },
      }));

      return;
    } else
      setData((prevState) => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));
  };

  return (
    <div className="temp-form">
      <PageMeta title={"تعديل بيانات " + item.name} originTitle={"الاعلاف"} />
      <header className="text-center border-bottm-line w-fit mx-auto px-5 pb-3">
        <h3>تعديل بيانات {item.name}</h3>
      </header>

      <Signboard error={error} success={success} loading={loading} />



      <div className="row align-items-center">
        <div className="my-3 col-12 col-md-6">
          <div data-error="" className="form-group">
            <label htmlFor="name">اسم العلف</label>
            <input
              type="text"
              name="name"
              id="name"
              required
              placeholder="اسم العلف"
              value={data.name}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        </div>
        <div className="my-3 col-12 col-md-6">
          <div className="form-group" data-error="">
            <label htmlFor="measuringUnit">وحدة القياس</label>
            <select
              name="measuringUnit"
              id="measuringUnit"
              onChange={handleInputChange}
              required
              defaultValue={data.measuringUnit}
              className="form-select">
              <option value="">اختر وحدة القياس</option>
              <option value="طن">طن</option>
              <option value="كيلو">كيلو</option>
              <option value="جرام">جرام</option>
            </select>
          </div>
        </div>

        <div className="my-3 col-12 col-md-12">
          <div className="form-group" data-error="">
            <label htmlFor="operator">المورد</label>
            <select
              name="operator"
              id="operator"
              required
              defaultValue={data.operator._id}
              onChange={handleInputChange}
              className="form-select py-2">
              <option value="">اختر المورد</option>
              {operators &&
                operators.map((operator) => (
                  <option value={operator._id} key={operator._id}>
                    {operator.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="my-3 col-12 col-md-12">
          <div className="form-group" data-error="">
            <label htmlFor="info">معلومات اضافيه</label>
            <textarea
              name="info"
              id="info"
              rows={4}
              placeholder="معلومات اضافيه عن العلف"
              onChange={handleInputChange}
              required
              defaultValue={data.info}
              className="form-control"></textarea>
          </div>
        </div>

        <div className="col-12 col-md-12 mt-4">
          <div className="form-group row justify-content-between align-items-center">
            <div className="col">
              <button onClick={() => toggle()} className="close-btn">
                الغاء
              </button>
            </div>
            <div className="col d-flex justify-content-end">
              <button
                className="add-btn"
                disabled={!isDataChanged}
                onClick={handleFormSubmit}>
                تعديل
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateFeed;
