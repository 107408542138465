import { NavLink, Outlet } from "react-router-dom";
import PageMeta from "./PageMeta";
import useNavigator from "../hooks/useNavigator";
import { useEffect } from "react";
import { setBreadCrumbsStore } from "./breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, warehousesBreadCrumb } from "./breadcrumbs/data/breadcrumbs.data";

const OriginAndWarehouseContainer = () => {
  // useNavigator("warehouses", "stocks");


  useEffect(()=>{
    setBreadCrumbsStore([
        homeBreadCrumb,
        warehousesBreadCrumb,
    ])
} , [])


  return (
    <>
      <PageMeta title="المستودعات والاصول" originTitle="المستودعات والاصول" />
        <div className="main-links-container">
          <NavLink to="stocks">المستودعات</NavLink>
          <NavLink to="origin">الاصول</NavLink>
        </div>
        <Outlet />
    </>
  );
};

export default OriginAndWarehouseContainer;
