import {InputSelect} from "../../../config/imports";

export default function SellInvoiceComponent(props){
    const { handlers , projects , departments , baseInv   , warehouses} = props

    // console.log({type : baseInv.type})

    return(

        <div className="row justify-content-center    index-inv-container">
               <InputSelect
                title={"مكان ادخال الفاتورة"}
                className={"col-12  mb-4 col-md-6 col-lg-6 col-sm-12"}
                defaultValue={baseInv.whereToEnter}
                handleInputChange={handlers.shared.whereToEnter}
                name="whereToEnter"
                options={[
                    {_id: "مشروع", name: "مشروع"},
                    {_id: "مستودع", name: "مستودع"},
                ]}
            />
            <InputSelect
                title={"نوع الفاتورة"}
                className={"col-12 mb-4  col-md-6 col-lg-6 col-sm-12"}
                handleInputChange={handlers.shared.change}
                name="type"
                defaultValue={baseInv.type}
                options={ (()=>{
                    if(baseInv?.whereToEnter === "مستودع"){
                        return [
                                {_id: "شراء اعلاف", name: "شراء اعلاف"},
                                {_id: "شراء ادوية", name: "شراء ادوية"},
                                {_id: "فاتورة صيانة", name: "فاتورة صيانة"},
                                {_id: "مصروفات اخرى", name: "مصروفات اخرى"},
                                {_id: "كراتين", name: "كراتين"},
                                {_id: "اطباق", name: "اطباق"},
                            ]
                        } else {
                            return [
                                { _id: "شراء اعلاف", name: "شراء اعلاف" },
                            ]
                    }
                })() }
            />
            {
                baseInv.whereToEnter === "مستودع" ? (
                    <InputSelect
                        title={"اسم المستودع"}
                        className={"col-12 mb-4 col-md-12 col-lg-12 col-sm-12"}
                        options={warehouses}
                        name="warehouse_id"
                        handleInputChange={handlers.shared.warehouseChange}
                    />
                ) : (
                    <>
                        <InputSelect
                            title={"اسم المشروع"}
                            className={"col-12  mb-4 col-md-6 col-lg-6 col-sm-12"}
                            options={projects}
                            handleInputChange={handlers.sell.project}
                            name="project_id"
                        />
                        <InputSelect
                            title={"القسم"}
                            className={"col-12  mb-4 col-md-6 col-lg-6 col-sm-12"}
                            options={departments}
                            handleInputChange={handlers.sell.department}
                            name="department_id"
                        />


                    </>
                )
            }
        </div>


    )
}
