import {projectsAPI} from "../../../../../projects.helper";


import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    SuperCreate,
    SuperUpdate,
    addColumn,
    InputDate,
    InputNumber,
    InputText,
    InputTextArea,
    extractCreateData, extractTableData
} from '../../../../../../../config/imports'

import AddBuyFeedInvoice from "./AddBuyFeedInvoice";
import AddBuyFeedRequest from "./AddBuyFeedRequest";

import DeleteFeedConsumptionReport from "./DeleteFeedConsumptionReport";
import React from "react";

const FeedMovementReports = () => {
    const MAIN_TITLE = "تقرير حركة اعلاف";
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            date: new Date(),
            inv_number: 121151,
            type: 654,
            buy_quantity: 545,
            buy_quality: 54,
            consumption_quantity: 215,
            consumption_quality: 215,
            remaining: 15,
            quantity_quality: 5
        },
        {
            date: new Date(),
            inv_number: 151520,
            type: 120,
            buy_quantity: 500,
            buy_quality: 25,
            consumption_quantity: 175,
            consumption_quality: 36,
            remaining: 3,
            quantity_quality: 91
        },
    ];

    const dailyRef = useRef();
    const feedInvoiceRef = useRef();
    const buyFeedRef = useRef();
    const updateFeedRef = useRef();
    const deleteFeedRef = useRef();

    const buttons = [
        {
            title: "اضافة تقرير يومي",
            onclick: () => {

                dailyRef.current.toggle();
            },
        },
        {
            title: "اضافة فاتورة اعلاف",
            onclick: () => {

                feedInvoiceRef.current.toggle();
            },
        },
        {
            title: "طلب شراء اعلاف",
            onclick: () => {

                buyFeedRef.current.toggle();
            },
        },
    ];

    // const table = [
    //     {name: "idx", col: 1, title: "م"},
    //     {custom: itm => new Date(itm.date).toLocaleDateString("ar-EG"), col: 2, title: "تاريخ الفاتورة"},
    //     {name: "inv_number", col: 1, title: "رقم الفاتورة"},
    //     {name: "type", col: 1, title: "نوع العلف"},
    //     {name: "buy_quantity", col: 1, title: "كمية الشراء"},
    //     {name: "buy_quality", col: 1, title: "قيمة الشراء"},
    //     {name: "consumption_quantity", col: 1, title: "كمية الاستهلاك"},
    //     {name: "consumption_quality", col: 1, title: "قيمة الاستهلاك"},
    //     {name: "remaining", col: 1, title: "الكمية المتبقية"},
    //     {name: "quantity_quality", col: 1, title: "قيمة الكمية"},
    //     {name: "settings", col: 1, title: ""},
    // ];
    const table = [
        addColumn("", "م", ''),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        addColumn("type","نوع العلف",InputText , 1),
        addColumn("buy_quantity","كمية الشراء",InputText , 1),
        addColumn("buy_quality","قيمة الشراء",InputText , 1),
        addColumn("consumption_quantity","كمية الاستهلاك",InputText , 1),
        addColumn("consumption_quality","قيمة الاستهلاك",InputText , 1),
        addColumn("remaining","الكمية المتبقية",InputText , 1),
        addColumn("quantity_quality","قيمة الكمية",InputText , 1),
        addColumn("settings", "", ''),
    ]

    const views =
        [
            {
                ref: dailyRef,
                component: <SuperCreate
                    table={table}
                    setMainData={setMainData}
                    mainTitle={MAIN_TITLE}
                    formHeader={"اضافة فاتورة تقرير يومي"}
                    api={"/api/users"}
                />
            },
            {
                ref: feedInvoiceRef,
                component: <AddBuyFeedInvoice setMainData={setMainData}/>
            },
            {
                ref: buyFeedRef,
                component: <AddBuyFeedRequest setMainData={setMainData}/>
            },
            {
                ref: updateFeedRef,
                component: <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={MAIN_TITLE}
                    formHeader={"تعديل فاتورة تقرير يومي"}
                    table={table}
                />
            },
            {
                ref: deleteFeedRef,
                component: <DeleteFeedConsumptionReport setMainData={setMainData}/>
            }
        ]

    return (
        <>
            <PageMeta title={"قائمة تقرير علف"}/>


            <QuickViewContainer views={views}/>

            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={"تقرير حركة اعلاف"}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateFeedRef} deleteRef={deleteFeedRef}/>
        </>
    );
};

export default FeedMovementReports;
