import {InputText , InputNumber, InputSelect} from "../../../../config/imports"
import Helpers from "../../../../helpers/helpers";

const buildingsInputs = [
        { name :  'name' , title :  'أسم المبنى', input :  InputText , size : 6},
        { name :  'location' , title :  'موقعها', input :  InputText , size : 6},
        { name :  'level' , title :  'المستوى', input :  InputSelect , options : [
                { value :  'خدمية' , text : 'خدمية' },
                { value :  'عمالية' , text : 'عمالية' },
                { value :  'ضيافة' , text : 'ضيافة' },
                { value :  'مخزن' , text : 'مخزن' },
        ]},
        { name :  'characteristic' , title :  'صفة المبنى', input :  InputText , size : 8},
        { name :  'height' , title :  'طول المبنى', input :  InputNumber ,size : 4},
        { name :  'width' , title :  'عرض المبنى', input :  InputNumber, size : 4},
        { name :  'area' , disabled : true ,title :  'مساحة المبنى', input :  InputNumber , size : 4, customValue : (data)=> {
                data.area =  Helpers.validateNumber(data?.width * data?.height)
                const input = document.getElementById('area')
                if(input) input.value = data.area
        }},
      
        { name :  'value' , title :  'قيمة المبنى', input :  InputText},
        { name :  'account_name' , title :  'أسم الحساب', input :  InputText},
        { name :  'account_symbol' , title :  'رمز الحساب', input :  InputText},

]

    export default buildingsInputs
