// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container .sized-height {
  max-height: 650px;
}
.modal-container .modal-container-content {
  background-color: #fff;
  padding: 20px 50px;
  width: 900px;
  overflow-y: auto;
}
.modal-container .modal-container-content::-webkit-scrollbar-track {
  background-color: #F4F4F4;
  border-radius: 20px 0 0 20px;
}
.modal-container .modal-container-content::-webkit-scrollbar {
  background-color: rgba(55, 181, 105, 0.7);
  border-radius: 20px 0 0 20px;
}
.modal-container .modal-container-content::-webkit-scrollbar-thumb {
  background-color: rgba(55, 181, 105, 0.7);
  border-radius: 20px;
}

@media screen and (max-width: 1000px) {
  .modal-container .modal-container-content {
    width: 700px;
  }
}
@media screen and (max-width: 500px) {
  .modal-container .modal-container-content {
    padding: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/modal.styles.scss"],"names":[],"mappings":"AAGE;EACE,iBAAA;AAFJ;AAKE;EACE,sBAAA;EACA,kBAAA;EACA,YAAA;EAEA,gBAAA;AAJJ;AAMI;EACE,yBAAA;EACA,4BAAA;AAJN;AAQI;EAEE,yCAAA;EAEA,4BAAA;AARN;AAWI;EACE,yCAAA;EACA,mBAAA;AATN;;AAiBA;EAEI;IACE,YAAA;EAfJ;AACF;AAkBA;EAEI;IACE,aAAA;EAjBJ;AACF","sourcesContent":[".modal-container{\n\n\n  .sized-height{\n    max-height:650px;\n  }\n\n  .modal-container-content{\n    background-color: #fff;\n    padding: 20px 50px;\n    width: 900px;\n    // max-height:650px; // TODO : add it to the only ones it needs\n    overflow-y: auto;\n\n    &::-webkit-scrollbar-track {\n      background-color: #F4F4F4;\n      border-radius: 20px 0 0 20px;\n\n    }\n\n    &::-webkit-scrollbar {\n\n      background-color: rgba(55, 181, 105, 0.7);\n\n      border-radius: 20px 0 0 20px;\n    }\n\n    &::-webkit-scrollbar-thumb {\n      background-color: rgba(55, 181, 105, 0.7);\n      border-radius: 20px;\n\n    }\n\n\n\n  }\n}\n@media screen and (  max-width:  1000px) {\n  .modal-container{\n    .modal-container-content{\n      width: 700px;\n    }\n  }\n}\n@media screen and (  max-width:  500px) {\n  .modal-container{\n    .modal-container-content{\n      padding: 20px ;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
