import {useEffect, useState} from "react";
import {
    createFormHandler,
    EditForm,
    InputSelect,
    PageMeta,
    setInputsToDefault,
    Signboard
} from "../../../config/imports";
import {strainAPI} from "../bird_breed/bird_breed.routes";
import origin_cards_tables from "./assests_forms";
import ArrayHelpers from "../../../helpers/array.helpers";
import FormFooter from "../../../components/FormControls/FormFooter";

export default function CreateOriginAsset(props){

    const { toggle } = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [inputs, setInputs] = useState()
    let   [data, setData] = useState({})

    const handleInputChange = (e) => {
        const {name , value , type} = e.target
        setData((prevState) => ({
            ...prevState,
            [name]: type === "number" ?  +value : value
        }));
    };



    useEffect(() => {
        if(inputs){
            data = {}
            inputs?.forEach((input)=>{
                data[input.name] = ''
            })
        }
        setData({...data})

    } , [inputs])


    const handleFormSubmit = async (e) => {
        data["total"] = data["total"] === 0 ? data["unitprice"] * data["quantity"] : data["total"];

        e.preventDefault();
        const create_props = {
            url: strainAPI.url,
            data,
            setLoading,
            setError,
            setSuccess,
            // setMainData,
            setInputsToDefault,
            setData,
        };

        // await createFormHandler(create_props);
    };



    const cards_types = [
            {value: "housing", text: "بيوت الدواجن"},
            {value: "buildings", text: "المباني"},
            {value: "land", text: "الأرض"},
            {value: "roads", text: "الطرق", },
            {value: "toolsAndEquipments", text: "الالات والمعدات" },
            {value: "vehicles", text: "السيارت"},
            {value: "living", text: "أصل حيوي"}
    ];


    const onCardTypeSelected = (e) => {
        const value = e.target.value
        setInputs(origin_cards_tables[value])
    }

    return(
        <>
            <PageMeta title={'إضافة كارت اصل'} originTitle={'بيانات الاصول'}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>إضافة كارت اصل</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>

                <>
                    <InputSelect  handleInputChange={onCardTypeSelected} className={"col-12 mb-3"} options={cards_types}  title={"نوع الاصل"}/>

                    { ArrayHelpers.simpleInputsGenerator(inputs,data , handleInputChange) }

                </>

                <FormFooter handleFormSubmit={handleFormSubmit} toggle={toggle} />

            </EditForm>


        </>
    )
}
