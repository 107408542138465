import React, { useState } from 'react'
import { apis, departments_api } from '../../../../config/apis';
import Signboard from '../../../../components/Signboard';
import { EditForm, InputDate, InputText, InputTextArea, _date, createFormHandler, updateFormHandler } from '../../../../config/imports';
import OperatorsInput from '../../../Invoices/shared/OperatorsInput';
import FeedTypeSelectInput from '../../../Invoices/shared/FeedTypeSelectInput';
import MeasuringUnitInput from '../../../Invoices/shared/MeasuringUnitInput';
import UnitPriceInput from '../../../Invoices/shared/UnitPriceInput';
import AmountInput from '../../../Invoices/shared/AmountInput';
import SharedHomesInputs from '../../../Invoices/shared/SharedHomesInputs';
import TotalFromHomesInput from '../../../Invoices/shared/TotalFromHomesInput';
import TotalPriceInput from '../../../Invoices/shared/TotalPriceInput';

export default function Update(props) {
    const {
        project , department,
        setMainData, toggle , item
    } = props



    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    console.log(item);

    const signboard = {
        loading ,
        error ,
        success,
    } 
    
    const [data , setData] = useState({
        // home_id,
        ...item,
        // total_from_homes : +
    })

    // console.log({data})


    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));

    };


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: departments_api.feed_invoices.create(department),
            data : {
                ...data,
                total_from_homes : +data.total_quantity_from_homes
            } ,
            setLoading,
            setError,
            setSuccess,
            setMainData  : ()=> setMainData('refresh-api'),
            setData,
            FORM_HEADER: `الاضافة`,
        };
        console.log(create_props.data)

        await updateFormHandler(create_props);
    };


    console.log({data})


  return (
    <div>
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>  تعديل فاتورة الاعلاف </h3>
        </header>
        <Signboard {...signboard}/>
        <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InputText name="invNumber" title=" رقم الفاتورة" disabled={true} defaultValue={data.invNumber} handleInputChange={handleInputChange} />

                <OperatorsInput className={'col-4'} data={data} setData={setData} defaultValue={data?.operator?._id} />

                <InputDate  name="date" label="تاريخ الفاتورة" defaultValue={_date(data.date).forInputDate()} handleInputChange={handleInputChange} />

                <FeedTypeSelectInput data={data} setData={setData} defaultValue={data?.feedType?._id} />

                <MeasuringUnitInput data={data} setData={setData}  defaultValue={data?.measuring_unit}/>

                <UnitPriceInput data={data} setData={setData} defaultValue={data.unit_price} />

                <AmountInput data={data} setData={setData} defaultValue={data.amount ?? data.total_quantity_from_homes} />
                
                <SharedHomesInputs project={project}  setError={setError}  department={department} data={data} setData={setData} update={true}/> 
                
                <TotalFromHomesInput data={data} setData={setData} />

                <TotalPriceInput data={data} setData={setData} defaultValue={data.total_price}/>

                <InputTextArea name="more_info" title="ملاحظات" defaultValue={data.more_info} handleInputChange={handleInputChange} />
            </EditForm>

    </div>
  )
}
