import Flex from "./Flex";
import Row from "./Row";
import Span from "./Span"
import Column from "./Column"
import Responsive from "./Responsive";
import FlexResponsive from './FlexResponsive'
import Head from "./Head"
import DataRow from "./DataRow"
import "../../styles/grid.styles.scss"

const Grid = {
    Flex,
    Row,
    Span,
    Column,
    Responsive,
    FlexResponsive , Head , DataRow
}
export{
    Flex,
    Row,
    Span,
    Column,
    Responsive,
    FlexResponsive , Head , DataRow
}
export default Grid
