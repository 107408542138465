// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invoices {
  padding: 50px 0;
}
.invoices .form-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.invoices header {
  text-align: center;
}
.invoices select {
  width: 100%;
}
.invoices .toggle-disabled,
.invoices .add-btn:disabled {
  background-color: #FCA119;
  opacity: 0.5;
  cursor: not-allowed;
}
.invoices .index-inv-container {
  width: 70%;
  margin: auto;
}
.invoices .select-invoices {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}
.invoices .select-invoices .select-container {
  width: 70%;
}
.invoices .shared-homes label {
  display: none;
}
.invoices .shared-homes .tg-list {
  padding: 0;
}
.invoices .shared-homes .tg-list label {
  display: block;
}
.invoices .shared-homes .tg-list li {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/styles/invoices.styles.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AAAE;EACE,aAAA;EAEA,uBAAA;EAGA,sBAAA;AADJ;AAGE;EACE,kBAAA;AADJ;AAGE;EACE,WAAA;AADJ;AAGE;;EAGE,yBAAA;EACA,YAAA;EACA,mBAAA;AAFJ;AAIE;EACE,UAAA;EACA,YAAA;AAFJ;AAQE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;AANJ;AAQI;EACE,UAAA;AANN;AAeI;EACE,aAAA;AAbN;AAgBI;EACE,UAAA;AAdN;AAeM;EACE,cAAA;AAbR;AAgBM;EACE,SAAA;AAdR","sourcesContent":[".invoices {\n  padding : 50px 0 ;\n  .form-container {\n    display: flex;\n\n    justify-content: center;\n    // min-height: 50vh;\n\n    flex-direction: column;\n  }\n  header {\n    text-align: center;\n  }\n  select {\n    width: 100%;\n  }\n  .toggle-disabled ,\n  .add-btn:disabled\n  {\n    background-color: #FCA119;\n    opacity : .5 ;\n    cursor : not-allowed;\n  }\n  .index-inv-container{\n    width : 70%;\n    margin : auto;\n  }\n\n  *{\n    //outline :1px solid salmon ;\n  }\n  .select-invoices{\n    display: flex;\n    align-items:center;\n    justify-content : center;\n    min-height : 500px;\n\n    .select-container{\n      width: 70%;\n    }\n  }\n\n\n\n//  new invoices\n\n  .shared-homes{\n    label{\n      display: none;\n    }\n\n    .tg-list{\n      padding: 0;\n      label{\n        display: block;\n      }\n\n      li{\n        margin: 0;\n      }\n    }\n\n\n\n\n\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
