import React from 'react'
import { _date } from '../../../helpers/date.helpers'
import Table from '../../../layout/table/Table'
import Icons from '../../../layout/Icons'
import { Link, useParams } from 'react-router-dom'
import { CiCircleChevLeft } from "react-icons/ci";


export default function HomeDetailsTable({data}) {
  const {id} = useParams()
  // console.log(data); 
  const statusEnum = {
    open : <span className='p-2 px-4  block font-[500] rounded-lg w-fit   bg-[#F3FDF7] text-[#0CC857] '> فعاله  </span>,
    closed : <span className='p-2 px-4  block font-[500] rounded-lg w-fit  bg-[#f8808054] text-[#ff7676]  '> مغلقه  </span>,
    close : <span className='p-2 px-4  block font-[500] rounded-lg w-fit bg-[#f8808054] text-[#ff7676]   '> مغلقه  </span>,
    "no level" : <span className='p-2 px-4  block font-[500] rounded-lg w-fit bg-[#ffc72dd4] text-[#000000]  '> بدون  </span>,
  }


  const table = [
    { name : 'name' , size : 4  , text : 'الاسم'  },
    { name : 'createdAt' , size : 4  , text : 'تاريخ الانشاء' , custom : d => _date(d.createdAt).dmyt()  },
    { name : "maximum_birds_capacity" , size : 4  , text : 'استيعاب الطيور الاعظمي'  , custom : () =>  data?.storage?.maximum_birds_capacity   },
    { name : "current_level_status" , size : 3  , text : 'حاله المرحله الحاليه'  , custom : d => statusEnum[d.current_level_status]  },
    { name : "current_level_start_date" , size : 4  , text : ' تاريخ بدئ المرحله الحاليه' , custom : d => d.current_level_start_date ?    _date(d.current_level_start_date).dmyt()  : "-" },
    { name : "current_level_end_date" , size : 4  , text : 'تاريخ نهاية المرحله الحاليه' , custom : d => d.current_level_end_date ? _date(d.current_level_end_date).dmyt()  : "-" },
  ]




  return data && data.home &&  (
    <div className="page my-[50px] ">
          <div className="flex items-center w-full justify-between">
        
              <h3 className='mb-4 '>تفاصيل البيت</h3>
              {/* {  // TODO : add level logic 
                data?.home?.current_level_status !== "open" && (
                  <button className="add-btn flex items-center py-2 w-fit text-[18px] ">
                      <Icons.Plus /> 
                      <span className=' text-white'>اضافة بيت جديد</span>
                  </button>
                ) 
              } */}

        </div>

        
        <Table table={table} data={[data.home]} />

        <div className="flex justify-end py-2">
              <Link to={"/home/" + id + "/details/stages"}  className='flex items-center '>
                <span className='mx-2 text-[#fdb447]'>  عرض المراحل السابقة للبيت </span>
                <Icons.CircleArrow />
              </Link>
        </div>
    </div>
  )
}
