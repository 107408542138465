export default function MonthOrderHomeTotals(props){
    const {totals : data} = props
    return(
        <div className='month-totals b1'>
            <div className="month-totals-header d-flex bt-0 dim">
                <div className="span-6 p-0">
                    <div className="h-40 bb-0  br-0 txt">الاستهلاك اليومي</div>
                    <div className="h-40 bb-0  br-0 txt"></div>
                    <div className="h-40 bb-0  br-0 txt"></div>
                    <div className="h-40   br-0 txt"></div>
                </div>
                <div className="span-6 p-0">
                    <div className="h-120 br-0 bb-0  txt">الكميات المطلوبة</div>
                    <div className="h-40  br-0  txt"></div>
                </div>
                <div className="span-6 p-0">
                    <div className="h-120 br-0 bb-0  txt">عدد السيارات المطلوبة</div>
                    <div className="h-40  br-0  txt"></div>
                </div>
                <div className="span-6 p-0">
                    <div className="h-120 br-0 bb-0  txt">الأستهلاك التراكمي</div>
                    <div className="h-40  br-0  txt"></div>
                </div>
            </div>

                {
                    data && data.map( (d , index) => (
                        <div className="month-totals-row d-flex" key={index}>
                            <div className="span-6 h-40 bt-0 br-0 txt"> {d?.total_consumption ?? 0 } </div>
                            <div className="span-6 h-40 bt-0 br-0 txt"> {d?.total_quantities_required ?? 0} </div>
                            <div className="span-6 h-40 bt-0 br-0 txt"> {d?.total_vehicles_required ?? 0} </div>
                            <div className="span-6 h-40 bt-0 br-0 txt"> {d?.cumulative_consumption ?? 0} </div>
                        </div>
                    ))
                }



        </div>
    )
}
