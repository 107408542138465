import { DATA_LIMIT } from "../config/configs.env"

const Helpers = {
    validateNumber : value =>{
        // check if value is a number that is not a nan or infinity
        // return value when it is a valid else return 0
        return !isNaN(value) && value !== Infinity ? value : 0
    },
    initQuery : (api) =>{
        return api + `?search=&limit=${DATA_LIMIT}&page=1`
    },
    buildQuery : (search , limit , page) =>{
        return `search=${search}&limit=${limit}&page=${page}`
    },
    buildPaginationQuery : (page) =>{
       return Helpers.buildQuery( '' ,   DATA_LIMIT , page)
    },
    buildApi : (api , query) =>{
        return `${api}?${query}`
    },
    baseApi : (api) =>{
        return `${api}?`+ Helpers.buildQuery( '' ,   DATA_LIMIT , 1)
    },
    buildSearchQuery : (search) =>{
        return Helpers.buildQuery( search ,   DATA_LIMIT , 1)
    },
    buildSearchAndPaginationQuery : (search , page) =>{
        return Helpers.buildQuery( search ,   DATA_LIMIT , page)
    },
    search : (value , setSearch) =>{
        return setSearch(value)
    },
    Number : {
        toArabicFormat(number){
            const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
            return String(number).replace(/[0-9]/g, (match) => arabicDigits[match]);
        },
        commaFormat(number){
            if (!number) return 0
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        formatViewsCount(views) {
            if (!views) return 0
            if (Math.abs(views) < 1000) {
              return views.toString();
            } else if (views >= 1000 && views < 1000000) {
              // Convert to k (thousands)
              return (views / 1000).toFixed(1) + 'K';
            } else if (views >= 1000000 && views < 1000000000) {
              // Convert to m (millions)
              return (views / 1000000).toFixed(2) + 'M';
            } else {
              // Convert to b (billions)
              return (views / 1000000000).toFixed(2) + 'B';
            }
        },
        percentage(number){
            if (!number) return 0
            if(number < 100) return number
            const _number = number.toString()
            const length = _number.length
            const multiplier =  "1" + "0".repeat(length - 2)
            return +( +_number / +multiplier ).toFixed(2)
        },
        to100(number){
            const percent = Helpers.Number.percentage(number) / 10 
            return Math.ceil( percent ) * 100
        },
        formatIfNumber(data){
            const valid = isNaN(+data) ? false : true
            if(valid) return Helpers.Number.commaFormat(+data)
            return data
        }
    },
    Text : {
         typeWriterEffect(text, elementId, delay = 100) {
            let i = 0;
            const speed = delay; // typing speed in milliseconds
            const targetElement = document.getElementById(elementId);
            
          
            function type() {
              if (i < text.length) {
                if(!targetElement) return
                targetElement.innerHTML += text.charAt(i);
                i++;
                setTimeout(type, speed);
              }
            }
          
            type();
          }
          
          
    }
}

export default Helpers


export const initQuery = (api) =>{
    return api + `?search=&limit=${DATA_LIMIT}&page=1`
}




Number.prototype.toArabicFormat = function () {
    const arabicDigits = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    return String(this).replace(/[0-9]/g, (match) => arabicDigits[match]);
}

Number.prototype.commaFormat = function () {
    if (!this) return 0
    return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Number.prototype.formatIfNumber = function () {
    const valid = isNaN(+this) ? false : true
    if(valid) return Helpers.Number.commaFormat(+this)
    return this
}



export const group = (data) => {
    return (selector) => {
        return data.reduce((acc, cur) => {
            acc[cur[selector]] = cur
            return acc
        }, {})
    }
}

export const groupById = (data) => {
    const _group = group(data)
    return _group('_id')
}


export const Group = data =>{
    const _group = group(data)
    return  {
        byId : _group('_id'),
        by : (key) => _group(key)
    }

}


export const hasValue = (value) => value !== null && value !== undefined && value !== '' && value.length > 0

export const hasValues = (values)=> values.every(hasValue)

export const objectHasValues = (obj) => {
    if(!obj) return false
    return Object.keys(obj).every((key) => obj[key])
}

export const objectHasSomeValues = (obj) => {
    if(!obj) return false
    return Object.keys(obj).some((key) => obj[key])
}