import React from 'react'
import axiosPost from '../../../../helpers/axios/axios.post'
import { apis } from '../../../../config/apis'
import { useParams } from 'react-router-dom'
import { showErrorToast } from '../../../../stores/toast.store'


export default function useReportQuantityPrice({unit , consumption , sub_category , category}) {

    const id = useParams().id


    const [price , setPrice] = React.useState(0)
    const [loading , setLoading] = React.useState(false)


    const fetchPrice = async () => {

        if(!unit || !consumption || !sub_category || !category) return setPrice(0)
        
        setLoading(true)

        const response  = await axiosPost(apis.storages.price() , {
            location_id : id,
                unit,
                consumption,
                sub_category,
                category
        })


        setLoading(false)

        if(response.error) {
            setPrice(0)
            return showErrorToast(response.error)
        }

        setPrice(response?.data?.price)

    }


    return { price , fetchPrice , loading }
        

}


export const useFeedReportQuantityPrice = ({ unit , consumption , sub_category }) => {
     return  useReportQuantityPrice({
        unit : unit , consumption : consumption , sub_category : sub_category , category : 'feed'
    })
}

export const useDrugReportQuantityPrice = ({ unit , consumption , sub_category }) => {
     return  useReportQuantityPrice({
        unit : unit , consumption : consumption , sub_category : sub_category , category : 'drug'
    })
}

export const useOtherReportQuantityPrice = ({ unit , consumption , sub_category }) => {
     return  useReportQuantityPrice({
        unit : unit , consumption : consumption , sub_category : sub_category , category : 'other'
    })
}

export const useMaintenanceReportQuantityPrice = ({ unit , consumption , sub_category }) => {
     return  useReportQuantityPrice({
        unit : unit , consumption : consumption , sub_category : sub_category , category : 'maintenance'
    })
}

export const useCartonReportQuantityPrice = ({ unit , consumption , sub_category }) => {
     return  useReportQuantityPrice({
        unit : unit , consumption : consumption , sub_category : sub_category , category : 'carton'
    })
}

export const useDishReportQuantityPrice = ({ unit , consumption , sub_category }) => {
     return  useReportQuantityPrice({
        unit : unit , consumption : consumption , sub_category : sub_category , category : 'dish'
    })
}