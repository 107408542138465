import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { InputText, useFetch } from '../../../config/imports'
import { apis } from '../../../config/apis'

function InvoiceId(props) {

    const [id , setId] = useState("")
    // const {data} = useFetch(apis.invoices.counter())


    useEffect(() => {
        if(!props.value) return
        props.setData((pre)=>{
            return {
                ...pre,
                invNumber : props.value
            }
        })
    }, [props.value])



  return (
    <InputText  className={ props?.className ?? "col-4" } name="invNumber" title=" المسلسل  " readOnly={true} disabled={true} defaultValue={props.value ?? ""}  />
  )
}

InvoiceId.propTypes = {
    type : PropTypes.string,
    setData : PropTypes.func,
    className : PropTypes.string,
    value : PropTypes.string,
    // product : PropTypes.string
}

export default InvoiceId

