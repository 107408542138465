import {InputNumber} from "../../../config/imports";
import {useEffect} from "react";
import Helpers from "../../../helpers/helpers";

export default function TotalPriceInput(props){
    const {data , setData , className  , defaultValue} = props


    const handleInputChange = (e) => {
        setData( { ...data , total_price : Helpers.validateNumber( data.unit_price * data.quantity)})
    };

    return( <InputNumber className={className ?? 'col-6'} name={'total_price'} title={'إجمالي المبلغ'} handleInputChange={handleInputChange} defaultValue={ Helpers.validateNumber( data.unit_price * data.quantity) ?? defaultValue } /> )
}
