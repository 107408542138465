import {useEffect, useState} from "react";
import postData from "../../../helpers/axios/axios.post";
import {users_api} from "../../../config/apis";
import {EditForm, InputText, InputTextAsNumber, PageMeta, Signboard, updateData} from "../../../config/imports";

export default function ResetPassword(props){

    const { toggle  , item} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        _id : item._id,
        old_password : "",
        new_password : "",
    })

    const handleFormSubmit = async (e) => {
        setLoading(true);
        setError('')
        setSuccess('')
        e.preventDefault();

        const {data: postedData, error, statusCode, message} =
            await updateData(users_api.rest_password  ,  {...data})

        setLoading(false)
        if (error)  return setError(error)
        setSuccess('تم   تعديل كلمة المرور بنجاح')

    };

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    useEffect(() => {
        document.querySelector(('.modal-container-content')).style.width = 'fit-content'
    })

    return(
        <>
            <PageMeta title={'تعديل كلمة مرور المستخدم'} originTitle={' المستخدمين '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>     تعديل كلمة مرور المستخدم { item?.username }</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
                <div className={'my-2 '}>
                    <InputText  className={'w-100'} name={'old_password'} handleInputChange={handleInputChange} placeholder={'02asd156$4'} title={' كلمة المرور الحالية'} defaultValue={data.old_password}/>
                    <InputText  className={'w-100'} name={'new_password'} handleInputChange={handleInputChange} placeholder={'#$%SDFGH541as'} title={'كلمة المرور الجديده'} defaultValue={data.new_password}/>
                </div>
            </EditForm>

            </>
    )
}
