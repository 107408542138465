import CreateHatcheries from "./CreateHatcheries";
import DepositInHatchery from "./DepositInHatchery";
import DepositInIncubation from "./DepositInIncubation";
const HatcheriesCRUD =  {
    Create : CreateHatcheries,
    Incubator : DepositInIncubation, // حضانه
    Hatchery : DepositInHatchery, // فقاسه
}

export default HatcheriesCRUD;
