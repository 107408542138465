// import config from "../../helpers/configs.env";
import { paths } from "../../config/configs.env";

export const originAPI = {
  url: paths.api + "/origin",
  feed: paths.api + "/origin/feed",
  drug: paths.api + "/origin/drug",
  packaging: paths.api + "/origin/packaging",
  maintenance: paths.api + "/origin/maintenance",
  other: paths.api + "/origin/other",
};

export const operatorAPI = {
  url: paths.api + "/operator/selected",
};
