import {useNavigator, RouterLinks, PageMeta} from "../../config/imports";
import {useRef} from "react";

const usersMainContainerDetails = {
    origin: "users",
    navigateTo: "base",
    title: "المستخدمين",
    links: [
        {
            to: "base",
            name: "المستخدمين ",
        },
        {
            to: "roles",
            name: "الادوار",
        },
    ],
};
export default function UsersIndex() {




    const USERS_LINKS = [
        { name : "المستخدمين" , path : 'base' },
        {name : "الادوار" , path : 'roles'},
    ]


    useNavigator('users' , 'base')

    return (
        <div className='base-card mt-5'>
            <PageMeta title={'المستخدمين'} />
            <RouterLinks data={USERS_LINKS} />

        </div>
    )
}
export {
    usersMainContainerDetails
}
