import BirdsCountCharts from './BirdsCountCharts'
import OperatorsCharts from './OperatorsCharts'
import WeightCharts from './WeightCharts'
import BalancesCharts from './BalancesCharts'


const StatsCharts = {
    Birds : BirdsCountCharts,
    Operators : OperatorsCharts,
    Weights : WeightCharts,
    Balances : BalancesCharts
}
export default StatsCharts
