import { AiFillEye } from "react-icons/ai"; 
import React, { useEffect, useMemo, useRef } from 'react'
import useFetch from '../../hooks/useFetch';
import { invoices_api } from '../../config/apis';
import { Table } from '../../layout/table';
import Signboard from '../../components/Signboard';
import { _date } from '../../helpers/date.helpers';
import { NavLink } from 'react-router-dom';
import Icons from '../../layout/Icons';
import {  refresh } from '../../helpers/id.helper';
import PropTypes from 'prop-types'
import QuickView from "../../components/QuickView";
import PreviewInvoice from "./components/PreviewInvoice";
import { FiEye } from "react-icons/fi";
import { MdDeleteOutline } from "react-icons/md";
import DeleteInvoice from "./components/DeleteInvoice";
import { ImSpinner2 } from "react-icons/im";
function SmallInvoicesList(props) {

  const invoices = useMemo(() => {
    return props?.data?.slice(0, 4)
  } , [props?.data])

      
  const ref = useRef()
  const deleteRef = useRef();


  const detailsHandler = (data) => {
     ref.current?.toggle(data)
  }
  

  const deleteHandler = (data) => {
      deleteRef.current?.toggle(data)
  }



  const table = [
    {size : 4 , name : "invNumber" , text : "#"  },
    {size : 4 , name : "created_by", text : 'اسم منشئ الفاتورة'  },
    {size : 4 , name : 'place_name' , text : 'مكان ادخال الفاتورة'  },
    // {size : 4 , name : 'department_name' , text : 'المستودع / المشروع'  },
    {size : 3 , name : 'classification' , text : 'تصنيف الفاتورة'  },
    {size : 2 , name : 'quantity' , text : 'الكمية'  },
    {size : 2 , name : 'ar_unit' , text : 'الوحدة'  },
    {size : 2 , name : 'total_price' , text : 'الاجمالي'  },
    // {size : 5 , name : 'date' , text : 'تاريخ الفاتورة' , custom : d => _date(d.date).dmyt()   },
    {size : 3 , name : 'actions' , text : 'الاعدادت' , custom : d => {
      return (
        <div className="flex items-center gap-2">
          <div
            className="cursor-pointer"
            onClick={() => detailsHandler(d)}>
               <FiEye className="text-[20px]" />
          </div>
          <div
            className="cursor-pointer"
            onClick={() => deleteHandler(d)}>
               <MdDeleteOutline className="text-[20px]" />
          </div>
        </div>
      );

    }  },
  ]

  
  return (
    <>
    <QuickView ref={ref} ><PreviewInvoice/></QuickView>
    <QuickView ref={deleteRef} ><DeleteInvoice setRefresh={props.setRefresh} /></QuickView>
    <div className="row justify-content-between align-items-center">
      <div className="col-6">
        <h4 className="flex items-center gap-3">
           الفواتير المُضافة مؤخراً 
           {props.loading && <ImSpinner2 className="animate-spin" />}
        </h4>
      </div>
      <div className="col-6 d-flex px-5 justify-content-end">
        <NavLink to="/invoices#list"  className="flex items-center" >
            <span className='mx-2'>عرض كل الفواتير </span>
            <Icons.Arrow />
           </NavLink>
      </div>
    </div>
    <Signboard {...alert} />
    <Table table={table} data={invoices}  />
    </>
  )
}






SmallInvoicesList.propTypes = {
  data : PropTypes.array
}



export default SmallInvoicesList