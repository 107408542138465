import React, { useRef } from 'react'
import { apis } from '../../../../config/apis';
import useFetch from '../../../../hooks/useFetch';
import PageMeta from '../../../../components/PageMeta';
import Signboard from '../../../../components/Signboard';
import QuickView from '../../../../components/QuickView';
import { TableHeader } from '../../../../components/TableView';
import { Table } from '../../../../layout/table';
import Helpers from '../../../../helpers/helpers';
import Crud from './crud';

export default function CarsIndex(props) {


    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();
  
  
    const api = apis.assets.asset("car").paginate()
    
  
    const { data  , paginate , setters , alert } = useFetch(api);


    const buttons = [
        {
          title: "اضافة سياره",
          onclick: () => createRef?.current?.toggle(),
        },
      ];

    const table = [
        {size : 1 , name : "#" , text : "#"  },
        {size : 4 , name : "name" , text :  'أسم السيارة' },
        {size : 4 , name : "characteristic" , text :  'صفة السيارة' },
        {size : 4 , name : "model" , text :  'الموديل',  },
        {size : 3 , name : "plate_number" , text :  '  رقم اللوحة ' },
        {size : 3 , name : "structure_number" , text :  ' رقم الهيكل' },
        {size : 3 , name : "device_value" , text :  'قيمة المعدة ',  },
        {size : 2 , name : '' , text : '' , }
    ]
    
  return (
    <div className='base-card'>
    <PageMeta title=" السيارات" />
    <Signboard {...alert}/>

    <QuickView ref={createRef} ><Crud.Create setMainData={setters.data} asset={"car"} /></QuickView>
    <QuickView ref={updateRef} ><Crud.Update setMainData={setters.data} asset={"car"} /></QuickView>
    <QuickView ref={deleteRef} ><Crud.Delete setMainData={setters.data} asset={"car"} /></QuickView>
    <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
    <div className="py-5"></div>
    <Table table={table} data={data}  updateRef={updateRef} deleteRef={deleteRef}  className="mt-3" />
  </div>
  )
}
