import React, { useEffect, useState } from 'react'
import { DEPARTMENT_TYPE } from '../../../../config/local_storage.config'
import { useParams } from 'react-router-dom'
import useFetch from '../../../../hooks/useFetch'
import { apis } from '../../../../config/apis'

const parseType = (id) => {
    try {
        const homes =  JSON.parse(window.localStorage.getItem(DEPARTMENT_TYPE))
        if (homes) {
            return homes.find(h => h.id === id)?.type
        }
    } catch (error) {
        const homes = window.localStorage.getItem(DEPARTMENT_TYPE)
        if (homes) {
            return JSON.parse(homes).find(h => h.id === id)?.type
        }
    }
}


export const departmentsTypeEnum = {
    breeding : "breeding_chicken" ,
    production : "production_chicken"  
}
// breeding_egg
// production_egg
// production_chicken
// breeding_chicken

export const departmentsTypesEnum = {
   "entag_omahat" : "production_chicken" ,
   "entag_bayad" : "production_egg",
   "trbyat_bayad" : "breeding_egg",
   "trbyat_omahat" : "breeding_chicken"
}


const roosterDepartments = [ "breeding_chicken" , "production_chicken"]

export default function useHomeType() {
    
    const {id} = useParams()

    const [homeType , setHomeType] = React.useState(parseType(id))


    const {data} = useFetch(apis.homes.departmentType(id))


    const [stored , setStored] = useState([])


    useEffect(() => {
       const _stored = window.localStorage.getItem(DEPARTMENT_TYPE);
     
       if (!_stored) {
           window.localStorage.setItem(DEPARTMENT_TYPE, JSON.stringify([]));
           setStored([]);
       } else {

            try {
                if(typeof JSON.parse(_stored) !== "object") return window.localStorage.setItem(DEPARTMENT_TYPE, JSON.stringify([]));
                setStored(JSON.parse(_stored));
            } catch (error) {
                // this means that the local storage is not valid as it's a string
                // we should reset it to an empty array
                window.localStorage.setItem(DEPARTMENT_TYPE, JSON.stringify([]));
                setStored([]);
            }
       }
   }, []);

   useEffect(() => {
       if (!data) return;
       setStored(prev => {
           const isExists = prev.find(i => i.id === id);
           if (isExists) return prev;
           const updatedStored = [...prev, { id: id, type: data.type }];
           window.localStorage.setItem(DEPARTMENT_TYPE, JSON.stringify(updatedStored));
           return updatedStored;
       });
   }, [data]);


   useEffect(() => {
     setHomeType(parseType())
   }, [])


    const isRoosterType = () => roosterDepartments.includes(parseType(id))


    return {type : homeType , hasRoosterInfo : isRoosterType() , data}
}
