import {
    createFormHandler,
    EditForm, InputCheckBox,
    InputText,
    PageMeta,
    setInputsToDefault,
    Signboard, updateFormHandler,
    useFetch
} from "../../config/imports";
import {useEffect, useState} from "react";
import {roles_api, users_api} from "../../config/apis";

export default function LockRoleForm(props) {

    const  {setMainData , toggle , item   } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [isDataChanged, setIsDataChanged] = useState(false);




    const signboard = {
        error :   error,
        loading :   loading,
        success : success
    }
    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const updatesParams = {
            url: roles_api.role,
            data: {
                _id : item._id,
                is_active  : !item.is_active
            },
            setLoading,
            setError,
            setSuccess,
            setMainData : setMainData(prev => {
                let roleToBeUpdated = prev.filter(role => role._id === item._id)[0]
                roleToBeUpdated.is_active = !roleToBeUpdated.is_active

                return [...prev]
            }),
            setInputsToDefault,
            setIsDataChanged,
            // setData: setMainData,
            FORM_HEADER: `التعديل`,
        };

        await updateFormHandler(updatesParams);

    }






    return (
        <>
            <PageMeta title={'تعديل حالة الدور'} originTitle={'الادوار'}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>{ 'تعديل حالة الدور' }</h3>
            </header>
            <Signboard {...signboard}/>


            <form action="" onSubmit={handleFormSubmit}>



                <div className="col-12 col-md-6 col-lg-6">
                    <div data-error="" className="form-group">
                        <input
                            type='hidden'
                            name='role'
                            id='role'
                            required
                            placeholder='مدير مشروع'
                            value={item.is_active}
                            className="form-control"

                        />
                    </div>
                </div>


            </form>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button onClick={() => toggle() } className="close-btn">
                            الغاء
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        <button className="add-btn" onClick={handleFormSubmit}>
                            تعديل
                        </button>
                    </div>
                </div>
            </div>

        </>
    )

}



