import React, { useMemo } from 'react'
import useFetch from '../../../hooks/useFetch'
import { apis, projects_api } from '../../../config/apis'
import useHomeHierarchical from '../../../hooks/useHomeHierarchical'
import useStocks from '../../stocks/hooks/useStocks'

export default function useUsersData() {

    const {options : {all : homesOptions} } = useHomeHierarchical()
    const {options : stocks} = useStocks()
    const {data : projects} = useFetch(apis.projects.names)
    const {data : departments} = useFetch(apis.departments.all())
    
    const projectsOptions = useMemo(() => {
        return projects?.map(d => ({value : d?._id , text : d?.name}))
    } , [projects])

    const departmentOptions = useMemo(() => {
        return departments?.map(d => ({value : d?._id , text : d?.name_with_projects}))
    } , [departments])





  return {
     options : {
        homes : homesOptions,
        stocks,
        projects : projectsOptions,
        departments : departmentOptions,
        // test : testOptions
     }
  }
}
