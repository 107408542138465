import TableHeader from "./TableHeader.js";
import TableBody from "./TableBody.js";
import Table from "./Table.js";

const TableView = {
    Head : TableHeader,
    Body  : TableBody,
    Table
}

export default TableView

export {
    TableHeader,
    TableBody,
    Table
}
