import Breeding from "./breeding"
import Production from "./prod"

const LevelsCrud = {
    Breeding,
    Production
}
export default LevelsCrud

// const x = {
//     green_boxes : {
//         invoices_feed_amount : 0 ,
//         feed_consumption_in_ton : 0 ,
//         total_dead : 0,
//         productivity : 0
//     },
//     blue_boxes : {
//         cumulative_number_of_birds : 0 , //
//         total_production_birds : 0 ,
//         total_breeding_birds : 0,
//         total_produced_eggs : 0,
//         production_balance : 0,
//         feed_consumption_in_ton : 0 ,
//     },
//     home_details : {
//         name : "",
//         current_level_status : "",
//         current_birds_count : 0,
//         total_dead_count : 0,
//         feed_stock_in_ton : 0,
//         createdAt : "",
//         updatedAt : "",
//     }
// }
