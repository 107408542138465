import { _date, Icons, PageMeta, QuickView, Signboard, useFetch } from "../../config/imports";
import { homes_api } from "../../config/apis";
import { Link, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import "../../styles/levels.styles.scss";
import LevelsCrud from "./home_levels_crud";
import Pagination from "../../layout/pagination/Pagination";
import useHomeType from "./reports/hooks/useHomeType";
import Table from "../../layout/table/Table";
import useHomeBreadCrumbs from "./hooks/useHomeBreadCrumbs";

export default function HomeLevels(props){

    const {hasRoosterInfo  } = useHomeType()


    const {id} = useParams();



    useHomeBreadCrumbs()


    const deleteRef = useRef(),
            createRef = useRef(),
            updateRef = useRef(),
            activeRef = useRef()

    const api = homes_api.paginate_home_levels(id)

    const {data : home , isLoading : homeLoading , error : homeError , setData : setHomeData} = useFetch(homes_api.getHome(id))

    const {data : levels, error : levelsError , isLoading  : levelsLoading , setData : setLevels , setters , paginate , setRefresh} = useFetch( api )
  
    const signboard = {
        error : homeError || levelsError,
        loading : homeLoading || levelsLoading
    }

    const [homeType , setHomeType] = useState('')

    useEffect( ()=>{

        if(home) {
            if(home.home_type === 'breeding') setHomeType('Breeding')
            else setHomeType('Production')
        }

    } , [home] )


    const table = [
        { name : "#" , text : "#" , size : 1 },
        { name : "status" , text : "الحاله" , size : 2 },
        { name : "start_date" , text : "تاريخ البدء" , size : 3 , custom : d => _date(d.start_date).date() },
        { name : "end_date" , text : "تاريخ الانتهاء" , size : 3 , custom : d => _date(d.end_date).date() },
        { name : "chicken" , text : "  عدد الدجاج " , size : 3 },
         hasRoosterInfo &&  { name : "rooster" , text : " عدد الديوك " , size : 3 },
        { name : "strain" , text : "السلالة" , size : 2 , custom : item => item?.strain?.name },
        { name : "feed_type" , text : "انواع الاعلاف" , size : 2  , custom : d => d?.feed_type?.map(item => item?.name).join(' , ')},
        { name : "operator" , text : "المورد" , size : hasRoosterInfo ? 2 : 4  , custom : item => item?.operator?.name},
        { name : "settings" , text : "التعديلات" , size : hasRoosterInfo ? 3 :  4  , custom : item =>{
            return <div className="flex items-center">
                    <button  onClick={()=> activeRef.current.toggle(item)}>
                        {item.status === 'open' ?  <Icons.Lock /> : <Icons.ActiveLock />}
                    </button>
                    <button  onClick={()=> deleteRef.current.toggle(item)}><Icons.Delete /></button>
                    <button  onClick={()=> updateRef.current.toggle(item)}><Icons.Edit /></button>
            </div>
        }}
    ]

    return(
        <div className="home-levels bg-white p-4">
            {
                homeType && homeType === 'Breeding' ? (
                     <>
                         <QuickView ref={createRef} sized={true}><LevelsCrud.Breeding.Create hasRoosterInfo={hasRoosterInfo}   setRefresh={setRefresh}  setMainData={setLevels} /></QuickView>
                         <QuickView ref={updateRef} sized={true}><LevelsCrud.Breeding.Update hasRoosterInfo={hasRoosterInfo}   setRefresh={setRefresh}  setMainData={setLevels} /></QuickView>
                         <QuickView ref={deleteRef} sized={true}><LevelsCrud.Breeding.Delete  setRefresh={setRefresh}  setMainData={setLevels} /></QuickView>
                         <QuickView ref={activeRef} sized={true}><LevelsCrud.Breeding.Status  setRefresh={setRefresh}  setMainData={setLevels} /></QuickView>
                     </>
                ) : (
                    <>
                        <QuickView ref={createRef} sized={true}><LevelsCrud.Production.Create hasRoosterInfo={hasRoosterInfo}  setRefresh={setRefresh}   setMainData={setLevels} /></QuickView>
                        <QuickView ref={updateRef} sized={true}><LevelsCrud.Production.Update hasRoosterInfo={hasRoosterInfo}  setRefresh={setRefresh}   setMainData={setLevels} levels={levels} /></QuickView>
                        <QuickView ref={deleteRef} sized={true}><LevelsCrud.Production.Delete setRefresh={setRefresh}   setMainData={setLevels} /></QuickView>
                        <QuickView ref={activeRef} sized={true}><LevelsCrud.Production.Status setRefresh={setRefresh}   setMainData={setLevels} /></QuickView>
                    </>
                )
            }


            <PageMeta title={'عرض المراحل السابقة للبيت'} />

            <Signboard  {...signboard}/>

            <header className="row justify-content-between level-header">
               <div className="col flex items-center">
                <div className="col-auto ">
                        <Link to={'/home/'+ id + '/details'}  >
                            <Icons.Arrow className="scale-x-[-1] "  />
                        </Link>
                    </div>
                    <div className="col-auto">
                        <span>عرض المراحل السابقة للبيت</span>
                    </div>
               </div>
                <div className="col-auto">
                    <button className="add-btn" onClick={ e => createRef.current.toggle()}>
                        + إضافة مرحلة جديدة
                    </button>
                </div>
            </header>

         { paginate && <Pagination   paginate={paginate}  api={api} setData={setters.data} setLoading={setters.loading} search={""}  />}

         <Table table={table}  data={levels}  /> 

        </div>
    )
}
