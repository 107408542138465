import {useEffect, useState} from "react";
import {reports_api, weight_api} from "../../../../../config/apis";
import {createFormHandler, setInputsToDefault, updateFormHandler} from "../../../../../helpers/form.helper";
import {
    _date,
    EditForm, Grid,
    InputDate,
    InputNumber,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard, useFetch
} from "../../../../../config/imports";
import {useParams} from "react-router-dom";

export default function Update(props){

    const {id} = useParams();
    const {toggle , item , setMainData } = props


    // console.log({item})

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    // const  { data : homeData , error : reportError , isLoading : reportLoading , setData : setHomeData}  = useFetch(reports_api.home.eggs.updateReport(id))


    let signboard = {
        error : error ,
        success,
        loading : loading
    }
    let [data , setData] = useState({
        date : new Date(item.date).toISOString(),
        home : item.home._id,
        cartons : [...item.cartons.filter( carton => carton.hasOwnProperty('symbol') )]
    })


    // console.log(item)
    const handleInputChange = (e , cartonIndex , weight) => {
        setData( prev =>{
            const updatedCarton = JSON.parse(JSON.stringify(prev.cartons))
            updatedCarton[cartonIndex].weights[weight].amount = +e.target.value
            return {
                home : prev.home,
                date : prev.date,
                cartons : updatedCarton
            }
        } )
    };

    console.log({data})

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: reports_api.home.eggs.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        await updateFormHandler(create_props);
    };

    return(
        <>
            <PageMeta title={'تعديل تقرير بيض يومي'} originTitle={'تقارير البيض'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   تعديل تقرير بيض يومي</h3>
            </header>

            <Signboard {...signboard}/>
            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >
                <div className="data-table-head">
                    <InputDate value={data.date}   title={"التاريخ"}  name={'date'}  defaultValue={_date(data.date).forInputDate()}  />
                </div>
                <div className="overflow-x-auto">
                    {
                        data && data.cartons && data.cartons.length > 0 && data.cartons.map((carton , cartonIndex)=>{
                            return(
                                <Grid.FlexResponsive key={carton._id} className={'align-items-center data-row'}>
                                    <Grid.Span size={3}> {carton.name} </Grid.Span>
                                    {
                                        carton.weights && carton.weights.length > 0 && carton.weights.map((weight , index)=>{
                                            // console.log({weight})
                                            return <InputNumber
                                                key={weight.weight}
                                                handleInputChange={(e)=>handleInputChange(e , cartonIndex , index)}
                                                className={'span-3 px-1'}
                                                placeholder={'0'}
                                                title={weight.name}
                                                defaultValue={weight.amount}
                                                required={false}
                                            />
                                        })
                                    }
                                </Grid.FlexResponsive>
                            )
                        })
                    }

                </div>


            </EditForm>

        </>
    )
}
