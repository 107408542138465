import {apis} from "../../../config/apis";
import {useFetch} from "../../../config/imports";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';

import { Line } from 'react-chartjs-2';
import chartOptions from "./charts.config";


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);


export default function OperatorsCharts(props){

    const {data } = useFetch(apis.stats.operators())

    // const signboard = { error , loading : isLoading}

    const labels = data?.map(item => item.name)

    const chartData = {
        labels,
        datasets: [
            {
                fill: true,
                label: ' العملاء / الموردين',
                data: data?.map(item => item?.egg_ratio),
                borderColor: '#03C894',
                backgroundColor: '#CDF1E9',
            },
        ],
    };



    return(
        <>
            <header> العملاء والموردين  </header>
                <Line  data={chartData} options={chartOptions.operators} />
        </>
    )
}
