import {_date, QuickView, Signboard, TableHeader, useFetch , PageMeta} from "../../../config/imports";
import {apis} from "../../../config/apis";
import {Table} from "../../../layout/table";
import { useRef} from "react";
import OrdersCrud from "./home_orders_crud";
import {useParams} from "react-router-dom";
import { OrderStatusTypes } from "./common/order_status_types.common";
import useMassUnit from "../../../hooks/types/useMassUnit";

export default function FeedOrders(props){

    const {id} = useParams()
    const createRef = useRef()
    const updateRef = useRef()
    const deleteRef = useRef()
    const api = apis.orders.feed.paginateHome(id)
    const {data , error , isLoading , setData : setMainData , setters , paginate} = useFetch(api)
    const {data : feedTypes , isLoading : isFeedTypesLoading , error : feedTypeError  ,} = useFetch(apis.origins.feed.base())

    const  {MassTypesEnum} = useMassUnit()

    const signboard = {
        error :   error || feedTypeError ,
        loading : isLoading  || isFeedTypesLoading,
    }
    const buttons =[ { title : 'إضافة طلب جديد' , onclick : ()=>  createRef?.current?.toggle()  }]

    const table = [
        { name : '#' , size : 1  , text : '#'},
        { name : 'name' , size : 3  , text : 'الاسم'  , custom : ()=> 'طلب اعلاف'},
        { name : 'status' , size : 3  , text : 'الحالة' , custom : d =>OrderStatusTypes[d.status] ?? d?.ar_status},
        { name : 'unit' , size : 3 , text : 'وحدة القياس' , custom : (d)=>MassTypesEnum[d.unit ?? d.measuringUnit ?? d.measuring_unit]},
        { name : 'quantity' , size : 3 , text : 'الكمية'},
        { name : 'createdAt' , size : 4  , text : 'تاريخ الطلب' , custom : d => _date(d.createdAt).dmy()},
        { name : 'info' , size : 5  , text : 'معلومات إضافيه'},
        { name : '' , size : 1  , text : ''}
    ]

    return(
        <>
            <QuickView ref={createRef} ><OrdersCrud.Feed.Create  setMainData={setMainData}  types={feedTypes} /></QuickView>
            <QuickView ref={updateRef} ><OrdersCrud.Feed.Update  setMainData={setMainData}  types={feedTypes} /></QuickView>
            <QuickView ref={deleteRef} ><OrdersCrud.Feed.Delete  setMainData={setMainData}  types={feedTypes} /></QuickView>
            <PageMeta title={'طلبات الاغلاف'} />
            <Signboard {...signboard} />
            <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
            <Table table={table} data={data} className={'mt-5'} updateRef={updateRef} deleteRef={deleteRef} />
        </>
    )
}
