import { RouterLinks } from "../../components/LinkList";
import { useNavigator } from "../../config/imports";
import "../../styles/incubator.styles.scss";

const links = [
  // {
  //   name: "الفقاسات والحضانات",
  //   to: "hatcheries",
  // },
  // {
  //   name: "الطلبيات",
  //   to: "orders",
  // },
  {
    name: "عملاء او موردين أو مناديب",
    to: "clients",
  },
];

const incubatorsBaseDetails = {
  origin: "incubators",
  navigateTo: "hatcheries",
  title: "الفقاسات والحضانات",
  links,
};

const IncubatorIndex = () => {
  // useNavigator("incubators", "hatcheries");

  return <></>;
};

export default IncubatorIndex;

export { incubatorsBaseDetails as INCUBATORS };
