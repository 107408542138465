import { create } from "zustand";
import { DATA_LIMIT } from "../config/configs.env";



export const getStorePage = () => {
     return usePaginateStore.getState().page
}

export const getStoreSearch = () => {
    return usePaginateStore.getState().search
}

export const getStorePagesCount = () => {
    return usePaginateStore.getState().pagesCount
}

export const getStoreDebounce = () => {
    return usePaginateStore.getState().debounce
}

export const getStoreFirstRender = () => {
    return usePaginateStore.getState().firstRender
}











export const setStoreSearch = (value) => {
    usePaginateStore.getState().setSearch(value)
}

export const setStorePage = (value) => {
    usePaginateStore.getState().setPage(value)
}

export const setStorePagesCount = (value) => {
    usePaginateStore.getState().setPagesCount(value)
}

export const getStoreApi = () => {
    return `?limit=${DATA_LIMIT}&page=${getStorePage()}&search=${getStoreDebounce()}`
}

export const setStoreTotal = (value) => {
    usePaginateStore.getState().setTotal(value)
}



export const setStorePaginate = ({ page = 1 , search = "" , pagesCount = 1  })=>{
    setStorePage(page)
    setStoreSearch(search)
    setStorePagesCount(pagesCount)
}


const usePaginateStore = create((set) => ({

    firstRender : true ,

    page : 1,

    search : "",

    pagesCount : undefined,

    perPage : undefined,

    total : 0 ,

    debounce : "",


    limit : DATA_LIMIT,


    
    setPage : value => set(state => ({...state , page : value , firstRender : false})),


    setSearch : value => set(state => ({...state , search : value , firstRender : false})),


    setDebounce : value => set(state => ({...state , debounce : value , firstRender : false})),


    setPagesCount : value => set(state => ({...state , pagesCount : value , firstRender : true})),

    setPerPage : value => set(state => ({...state , perPage : value , firstRender : false})),

    setTotal : value => set(state => ({...state , total : value , firstRender  : true})),

}))




export default usePaginateStore