import {create} from 'zustand';

// export type ToastSeverity = 'success' | 'error' | 'info';

// interface ToastState {
//     message: string | null;
//     severity: ToastSeverity | null;
//     showMessage: (message: string, severity: ToastSeverity) => void;
//     hideMessage: () => void;
// }


export const showSuccessToast = (message) => {
    useToastStore.getState().showMessage(message, 'success');
}

export const showErrorToast = (message) => {
    useToastStore.getState().showMessage(message, 'error');
}

export const showInfoToast = (message) => {
    useToastStore.getState().showMessage(message, 'info');
}


export const clearToast = () => {
    useToastStore.getState().hideMessage();
}


export const useToastStore = create((set) => ({

    message: null,

    severity: null,

    showMessage: (message, severity) => set(state =>{
        setTimeout(() => { 
            set({ ...state , message: null, severity: null });
         }, 5000)
        return { ...state , message, severity };
    }),

    
    hideMessage: () => set({ message: null, severity: null }),

}));

