import InputSelect from "../../../../components/form/inputs/InputSelect";
import {InputText , InputNumber} from "../../../../config/imports";

const roadsInputs = [
    {name : 'name', title :  'أسم الطريق', input : InputText , size : 8},
    {name : 'count', title :  'عدد', input : InputNumber},
    { name :  'level' , title :  'المستوى', input :  InputSelect , options : [
        { value :  'مسفلتة' , text : 'مسفلتة' },
        { value :  'ممهد' , text : 'ممهد' },
        { value :  'أعمدة كهرباء' , text : 'أعمدة كهرباء' },
        { value :  'أعمدة أنارة' , text : 'أعمدة أنارة' },
    ]},
    {name : 'length', title :  'طول الطريق', input : InputNumber},
    {name : 'width', title :  'عرض الطريق', input : InputNumber},
    {name : 'area', title :  'مساحة الطريق', input : InputNumber},
    {name : 'value', title :  'قيمة الطريق', input : InputText},
    {name : 'location', title :  'موقعها', input : InputText},
    {name : 'account_name', title :  'أسم الحساب', input : InputText ,  size : 6},
    {name : 'account_symbol', title :  'رمز الحساب', input : InputText ,  size : 6},
]
export default roadsInputs
