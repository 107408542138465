import {_date, createFormHandler, deleteFormHandler, EditForm, Signboard} from "../../../../../config/imports";
import PageMeta from "../../../../../components/PageMeta";
import {apis} from "../../../../../config/apis";
import {useState} from "react";
import DeleteTable from "../../../../../layout/table/DeleteTable";
export default function Delete(props){

    const {setMainData, toggle , item , asset} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [confirmBtn, setConfirmBtn] = useState(true);



    let signboard = {
        error : error  ,
        success,
        loading : loading ,
    }


    const table = [
        {size : 4 , name : "name" , text :  'أسم الأرض' },
        {size : 4 , name : "length" , text :  'طول الأرض' },
        {size : 4 , name : 'width' , text :  'عرض الأرض' },
        {size : 4 , name : 'value' , text :  'قيمة الأرض',},
        {size : 4 , name : "createdAt" , text : "تاريخ الانشاء"   , custom : d => _date(d.createdAt).dmy() },
        {size : 4 , name : "updatedAt" , text : "تاريخ اخر تعديل"   , custom : d => _date(d.updatedAt).dmy() },
    ]

    const handleDelete = async () => {
        const delete_props = {
            url: apis.assets.asset(asset).delete() ,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        // console.log(delete_props)
        await deleteFormHandler(delete_props);
    };


 
    return(
        <>

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h4>حذف البيت ؟</h4>
            </header>
            <Signboard {...signboard} />

            <DeleteTable  table={table} data={[item]} />

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
