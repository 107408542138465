// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.boxes-base, .blue-box, .green-box {
  width: 250px !important;
  height: 100px;
  border-radius: 15px;
  align-items: center;
  display: flex;
  box-shadow: -1px 7px 38px rgba(0, 0, 0, 0.1);
  margin: 10px 5px;
}
.boxes-base *, .blue-box *, .green-box * {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}
.boxes-base h2, .blue-box h2, .green-box h2 {
  font-size: 18px;
}
.boxes-base h4, .blue-box h4, .green-box h4 {
  font-weight: normal;
  margin-bottom: 10px;
}

.content, .green-box .box-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.green-box {
  gap: 0;
  padding: 0;
  background-color: #41E481;
  width: 250px;
}
.green-box .box-content {
  width: 100%;
}

.blue-box {
  background-color: #40A9F4;
  position: relative;
  width: 180px !important;
  margin: 3px !important;
}
.blue-box * {
  padding: 0 !important;
}
.blue-box img {
  position: absolute;
  bottom: 5%;
  left: 5%;
  width: 50px;
  height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/styles/boxs.styles.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;EACA,4CAAA;EACA,gBAAA;AACF;AAAE;EACE,WAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;AAEJ;AAAE;EACE,eAAA;AAEJ;AAAE;EACE,mBAAA;EACA,mBAAA;AAEJ;;AAEA;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAGA;EACE,MAAA;EACA,UAAA;EAEA,yBAAA;EAEA,YAAA;AAFF;AAGE;EAEE,WAAA;AAFJ;;AAMA;EAEE,yBAAA;EACA,kBAAA;EACA,uBAAA;EACA,sBAAA;AAJF;AAKE;EACE,qBAAA;AAHJ;AAKE;EACE,kBAAA;EACA,UAAA;EACA,QAAA;EAEA,WAAA;EACA,YAAA;AAJJ","sourcesContent":[".boxes-base{\n  width: 250px !important;\n  height: 100px;\n  border-radius: 15px;\n  align-items: center;\n  display : flex;\n  box-shadow: -1px 7px 38px rgba(0, 0, 0, 0.1);\n  margin : 10px 5px ;\n  *{\n    color : #fff;\n    font-size: 16px;\n    font-weight: 500;\n    margin-bottom: 0;\n  }\n  h2{\n    font-size: 18px;\n  }\n  h4{\n    font-weight: normal;\n    margin-bottom: 10px;\n  }\n\n}\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n\n.green-box{\n  gap: 0;\n  padding: 0;\n  @extend .boxes-base;\n  background-color: #41E481;\n  //min-width : 100%;\n  width : 250px;\n  .box-content{\n    @extend .content;\n    width : 100%\n  }\n}\n\n.blue-box{\n  @extend .boxes-base;\n  background-color: #40A9F4;\n  position : relative;\n  width : 180px !important;\n  margin : 3px !important;\n  *{\n    padding : 0 !important;\n  }\n  img {\n    position:  absolute;\n    bottom : 5%;\n    left: 5%;\n    //opacity : 0.5;\n    width : 50px;\n    height : 50px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
