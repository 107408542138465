import {Icons, PageMeta, Signboard} from "../../config/imports";
import {paths} from "../../config/configs.env";
import {useEffect, useState} from "react";
import axios from "axios";
import axiosAuthParams from "../../helpers/axios/axios.auth.params";
export default function OrderFiles(props){
    const {item , toggle , userTypeEnum} = props
    const {files , type} = item
    const [downloading, setDownloading] = useState(false);
    const [error , setError] = useState(null)
    const [loading , setLoading] = useState(false)
    const [success , setSuccess] = useState(null)


    const signboard = {
        error : error,
        loading : loading || downloading
    }

    useEffect(() => {
        document.querySelector(('.modal-container-content')).style.width = 'fit-content'
    })

    const downloadHandler = async (e , fileName) => {
        if (downloading)  return;
        setDownloading(true);
        setError(null)
        setSuccess(null)
        setLoading(true)

        try{
            setDownloading(true);

            // Make a GET request to the server to download the image
            const response = await axios.get( paths.uploads + 'orders/' + fileName, {
                responseType: 'blob', // Specify the response type as blob to handle binary data
                headers: {
                    ...axiosAuthParams().headers
                }
            });

            // console.log({response})

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            setDownloading(false);
            setLoading(false);
        }catch(e){
            console.error('حدث خطأ عند تنفيذ الطلب', e.message);
            setError('حدث خطأ عند تنفيذ الطلب', e.message);
            setDownloading(false);
            setLoading(false);
        }

    }

    return(
        <>

            <PageMeta title={' عرض الوثائق  '} originTitle={'  تفاصيل الطلب '}/>
            <Signboard {...signboard} />

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>  عرض وثائق  { userTypeEnum[type]  }  </h3>
            </header>


           <div className="d-flex flex-wrap">
               {
                   files?.map((file , index) => {
                       return <div className='download-file-container my-1' key={index} >
                           <button  onClick={(e) => downloadHandler(e ,  file) } className={'download-btn'} ><Icons.Download className={'download-file'} /></button>
                            {/*<a  href={paths.uploads + '/orders/' + file}  key={index}  download={file}  ><Icons.Download className={'download-file'} /></a>*/}
                       </div>
                   })
               }
           </div>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col-12 text-center">
                        <button type="button" onClick={() => toggle()} className="close-btn">
                            الغاء
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}
