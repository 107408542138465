import FormInput from "./base/FormInput";
import Label from "./base/Label";

export default function InputDetail(props){
    const defaultClassName =( className ) =>{
        let defaultClassName = ' input-detail form-input form-group  col-12 col-sm-12 col-md-4 col-lg-4'
        if(!className) return defaultClassName
        else return ' form-input form-group ' + className
    }


    return (


        <div className={defaultClassName(props.className)}>
                        <Label {...props} />
                <div className="input">
                    {props.defaultValue ?? props.value ?? "بدون"}
                </div>
         </div>


    )
}

