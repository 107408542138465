import {Signboard, useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";
import {Table, TableBody} from "../../../layout/table";
import {useState} from "react";

import ImportantInfoForDepartment from "./ImportantInfoForDepartment";
import ProductionOfDepartmentByYears from "./ProductionOfDepartmentByYears"
import DepartmentDetailsBoxes from "./DepartmentDetailsBoxes";
import PageMeta from "../../../components/PageMeta";

const DepartmentDetails = ()=>{

    const [component , setComponent] = useState(<ImportantInfoForDepartment/>)


    const navHandler = (e , Component) => {


        const btns = document.querySelectorAll('.nav-btn');
        // remove active class
        btns.forEach((btn) => btn.classList.remove('active-nav-btn'))
        // add active class to this
        e.target.classList.add('active-nav-btn')

        setComponent(<Component/>)

    }


    return <div>
        <PageMeta title={'تفاصيل القسم'} />
        <DepartmentDetailsBoxes />

        <div className={'bg-white pt-3 pb-1 px-1'}>
            <div className="mt-3 mb-5">
                <button onClick={(e)=> navHandler(e , ImportantInfoForDepartment)} className="nav-btn active-nav-btn"  >تفاصيل مهمة للقسم  </button>
                <button onClick={(e)=> navHandler(e , ProductionOfDepartmentByYears)} className="nav-btn" >    إنتاج القسم حسب سنوات العمل  </button>
                <div className="nav-component-container my-3">
                    {component && component }
                </div>
            </div>
        </div>

    </div>
}
export default DepartmentDetails
