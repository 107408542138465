import {InputText , InputNumber, InputSelect} from "../../../../config/imports";

const landInputs = [
    { name : 'name', title :  'أسم الأرض', input : InputText},
    { name : 'characteristic', title :  'صفة الأرض', input : InputText},
    { name : 'location', title :  'موقعها', input : InputText},
    { name :  'level' , title :  'المستوى',   size : 3  ,  input :  InputSelect , options : [
        { value :  'زراعية' , text : 'زراعية' },
        { value :  'تجارية' , text : 'تجارية' },
        { value :  'مشروع' , text : 'مشروع' },
        { value :  'أدارة' , text : 'أدارة' },
    ]},
    { name : 'length',  size : 3  ,   title :  'طول الأرض', input : InputNumber},
    { name : 'width',   size : 3  ,  title :  'عرض الأرض', input : InputNumber},
    { name : 'value',  size : 3  , title :  'قيمة الأرض', input : InputNumber},
    { name : 'account_name', title :  'أسم الحساب', input : InputText , size : 6},
    { name : 'account_symbol', title :  'رمز الحساب', input : InputText , size : 6},
]
export default landInputs
