import React, { useRef } from 'react'
import { apis } from '../../../../config/apis';
import useFetch from '../../../../hooks/useFetch';
import PageMeta from '../../../../components/PageMeta';
import Signboard from '../../../../components/Signboard';
import QuickView from '../../../../components/QuickView';
import { TableHeader } from '../../../../components/TableView';
import { Table } from '../../../../layout/table';
import Helpers from '../../../../helpers/helpers';
import Crud from './crud';

export default function MachineIndex(props) {


    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();
  
  
    const api = apis.assets.asset("machine").paginate()
    
  
    const { data  , paginate , setters , alert } = useFetch(api);

    const buttons = [
        {
          title: "اضافة آله", 
          onclick: () => createRef?.current?.toggle(),
        },
      ];

    const table = [
        {size : 1 , name : "#" , text : "#"  },
        {size : 4 , name : "name" , text :  'أسم الآله' },
        {size : 4 , name : "characteristic" , text :  'صفة المبنى' },
        {size : 4 , name : "location" , text :  'موقعها',  },
        {size : 3 , name : "device_value" , text :  ' قيمة المعدة ' },
        {size : 3 , name : "supplier" , text :  'المورد للآله' },
        {size : 3 , name : "manufacturer_country" , text :  'البلد المصنع',  },
        {size : 2 , name : '' , text : '' , }
    ]
    
  return (
    <div className='base-card'>
    <PageMeta title="الآلات والمعدات" />
    <Signboard {...alert}/>

    <QuickView ref={createRef} ><Crud.Create setMainData={setters.data} asset={"machine"} /></QuickView>
    <QuickView ref={updateRef} ><Crud.Update setMainData={setters.data} asset={"machine"} /></QuickView>
    <QuickView ref={deleteRef} ><Crud.Delete setMainData={setters.data} asset={"machine"} /></QuickView>
    <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
    <div className="py-5"></div>
    <Table table={table} data={data}  updateRef={updateRef} deleteRef={deleteRef}  className="mt-3" />
  </div>
  )
}
