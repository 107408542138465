import React from 'react'
import PropTypes from 'prop-types'
import useEggsWarehouseStore from '../store/eggs_warehouse.store'
import SelectItem from './SelectItem'

function SelectItemGroup(props) {

    const {addToSelectedItems , removeFromSelectedItems , selected} = useEggsWarehouseStore(state => state)
    const {data , type , name , id} = props

  return (
    <div className="flex  flex-col">
    {
        data?.map(item => <SelectItem
             type={type}
             addToSelectedItems={addToSelectedItems}  
             removeFromSelectedItems={removeFromSelectedItems}
             key={item[id]}
             name={item[name]}
             _id={item[id]}
             isSelected = {selected.find(i => i._id === item[id]) ? true : false}
             />)
    }

</div>
  )
}

SelectItemGroup.propTypes = {
    data : PropTypes.array,
    type : PropTypes.string,
    name : PropTypes.string,
    id : PropTypes.string
}

export default SelectItemGroup


export const DepartmentsSelectItemGroup = props => <SelectItemGroup {...props} type="departments_ids" name="name_with_projects" id="_id"/> 
export const HomesSelectItemGroup = props => <SelectItemGroup {...props} type="homes_ids" name="home" id="home_id"/>
export const ProjectsSelectItemGroup = props => <SelectItemGroup {...props} type="projects_ids" name="name" id="_id"/>