import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams, Link } from "react-router-dom"
import FeedTab from './FeedTab'
import DrugsTab from './DrugsTab'
import MaintenanceTab from './MaintenanceTab'
import CartonsTab from './CartonsTab'
import DishesTab from './DishesTab'
import DetailsTab from './DetailsTab'
import OthersTab from './OthersTab'
import Helpers from '../../../helpers/helpers'
import useStock from '../useStock'
import { useParams } from 'react-router-dom'
import CreateTransfer from '../forms/CreateTransfer'
import QuickView from '../../../components/QuickView'
import { setBreadCrumbsStore } from '../../../components/breadcrumbs/store/breadcrumb.store'
import { homeBreadCrumb, warehousesBreadCrumb } from '../../../components/breadcrumbs/data/breadcrumbs.data'
import useStockBreadCrumbs from '../hooks/useStockBreadCrumbs'

const Tabs = {
    details : <DetailsTab/>,
    feed : <FeedTab />,
    drugs  : <DrugsTab />,
    maintenances : <MaintenanceTab />,
    cartons : <CartonsTab />,
    dishes: <DishesTab />,
    others : <OthersTab />
}

const tabNames = [...Object.keys(Tabs)]

const isAValidTab = (tab) => {
    return tabNames.includes(tab)
}



export default function TabsIndex() {

  const [searchParams ] = useSearchParams()
  const [Tab , setTab] = useState(  )
  



  useStockBreadCrumbs()

  useEffect(() => {
    const tab = searchParams.get('tab')
    setTab(Tabs[tab])
  } , [searchParams])


  useEffect(() => {
    const tab = searchParams.get('tab')
    if(isAValidTab(tab)) 
        return setTab(Tabs[tab])
    else 
        return   setTab(Tabs.details)
  } , [])


  const {id} = useParams()
  const feedRef = useRef()
  const drugRef = useRef()
  const maintenanceRef = useRef()
  const otherRef = useRef()
  const cartonRef = useRef()
  const dishRef = useRef()

  const refEnum = {
    feed : feedRef,
    drug : drugRef,
    maintenance : maintenanceRef,
    other : otherRef,
    carton : cartonRef,
    dish : dishRef,
  }
  useEffect(() => {
    const _tab = searchParams.get('tab')  
    const tab = isAValidTab(_tab) ? _tab : 'details'
    const links = [...document.querySelectorAll('.tabs-container a')]
    
    links.forEach((link) => {
        link.classList.remove('active-tab')
    })

    const active = document.querySelector(`[data-tab-name='${tab}']`) 
    
    active?.classList.add('active-tab')

    } , [ searchParams ])

    const  {  quantities , fetchQuantities , reFetch , setRefresh } =  useStock({
      location : "stock",
      category : "",
    })

    useEffect(() => {
      fetchQuantities({ category :  "", location_id : id, location :  "stock" })
    } , [reFetch])

    const blueBoxes = useMemo(() => {


      const boxesOrder = ["feed" , "drug" , "maintenance" , "other" , "carton" , "dish"]

      function customSort(a, b) {
        return boxesOrder.indexOf(a.category) - boxesOrder.indexOf(b.category);
      }
    
       
      const sortedData = quantities?.boxes?.sort(customSort);

      return sortedData?.map(q => {
        return<div key={q.category} className='col-2 '>
                <div className=" d-flex align-items-center justify-content-center flex-column   r-16 text-white " style={{ backgroundColor : "#40A9F4" }} >
                    <div className="py-2 text-center ">
                      <p className='m-0 text-white ' >{q.title}</p>
                      <p className='m-0 text-white ' >
                        { Helpers.Number.commaFormat(q.quantity.toFixed(2))  }
                        &nbsp;
                        <span style={{ fontSize : "12px" , color : "#fff"}}> { (q.ar_unit) ?? q.unit }  </span>
                      </p>
                    </div>
                    <div className="w-100 " style={{ backgroundColor : "#ffbb57" , borderRadius : "0 0 16px 16px" }}   >
                      <button onClick={ ()=> refEnum[q.category].current?.toggle()  }   className='p-2 px-3 no-button text-white text-center w-100' >  تحويل كمية  </button>
                    </div>
                </div>
        </div>
      })
  })





  return (
    <>

    <QuickView ref={feedRef}  className="overflown-popup"><CreateTransfer header="اعلاف" type="feed" setRefresh={setRefresh} /></QuickView>
    <QuickView ref={drugRef}  className="overflown-popup"><CreateTransfer type="drug" header="ادويه " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={maintenanceRef}  className="overflown-popup"><CreateTransfer type="maintenance" header="صيانه " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={dishRef}  className="overflown-popup"><CreateTransfer type="dish" header="اطباق " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={cartonRef}  className="overflown-popup"><CreateTransfer type="carton" header="كراتين " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={otherRef}  className="overflown-popup"><CreateTransfer type="other" header="اخرى " setRefresh={setRefresh} /></QuickView>


      
    <div className='tabs-container'>
        <Link className={"link-tab"}  data-tab-name="details" to="?tab=details" > التفاصيل  </Link>
        <Link className={"link-tab"}  data-tab-name="feed" to="?tab=feed" >  الاعلاف  </Link>
        <Link className={"link-tab"}  data-tab-name="drugs" to="?tab=drugs" >  الادويه  </Link>
        <Link className={"link-tab"}  data-tab-name="maintenances" to="?tab=maintenances" >  الصيانة  </Link>
        <Link className={"link-tab"}  data-tab-name="cartons" to="?tab=cartons" >  الكراتين  </Link>
        <Link className={"link-tab"}  data-tab-name="dishes" to="?tab=dishes" >  الاطباق  </Link>
        <Link className={"link-tab"}  data-tab-name="others" to="?tab=others" >  الاخرى  </Link>
     </div>


     
    <div className="row blue-box-container justify-content-center my-3">
            { blueBoxes  }
      </div>

    <div className=" my-3">
        { Tab }
    </div>





    </>
  )
}
