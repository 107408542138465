import {_date, deleteFormHandler, Signboard  , PageMeta} from "../../../../../config/imports";
import React, { useState} from "react";
import {apis} from "../../../../../config/apis";
import DeleteTable from "../../../../../layout/table/DeleteTable";
import { OrderStatusTypes } from "../../common/order_status_types.common";


export default function Delete(props){

    const {toggle , item , setMainData } = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);


    const table = [
        { name : '#' , size : 1  , text : '#'},
        { name : 'status' , size : 5  , text : 'الحالة' ,  custom : d =>OrderStatusTypes[d.status] ?? d?.ar_status },
        { name : 'quantity' , size : 8 , text : 'الكمية'},
        { name : 'createdAt' , size : 4  , text : 'تاريخ الطلب' , custom : d => _date(d.createdAt).dmy()},
        { name : '' , size : 1  , text : ''}
    ]

    const handleDelete = async () => {
        const delete_props = {
            url: apis.orders.maintenance.delete(),
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        // console.log(delete_props)
        await deleteFormHandler(delete_props);
    };

    return(
        <>
            <PageMeta title={ 'حذف طلب العلف ؟'} originTitle={'  طلبات الصيانه '} />

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>  حذف طلب العلف  ؟</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>

            <DeleteTable  table={table} data={[item]} />

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
