import { FormInput } from "../../config/imports";
const InputTextAsNumber = (props) => {
  const {
    className,
    handleInputChange,
    name,
    label,
    invalid,
    defaultValue,
    placeholder,
  } = props;

  return (
    <>
      <FormInput
        name={name}
        className={className}
        handleInputChange={handleInputChange}
        defaultValue={defaultValue}
        label={label}
        type={"text"}
        pattern="^[0-9]+$"
        titleInvalid={invalid || "Only numbers allowed"}
        placeholder={placeholder || label}
      />
    </>
  );
};

export default InputTextAsNumber;
