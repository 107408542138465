import React, { useState } from 'react';
import Signboard from '../../../components/Signboard';
import DeleteTable from '../../../layout/table/DeleteTable';
import { _date } from '../../../helpers/date.helpers';
import { apis } from '../../../config/apis';
import { deleteFormHandler } from '../../../helpers/form.helper';
import { refresh } from '../../../helpers/id.helper';




const invoiceTypesEnum  = {
  feedBuyInv : "feed",
  drugBuyInv : "farma",
  cartonBuyInv : "paper",
  dishBuyInv : "dish",
  maintenanceBuyingInv : "main",
  otherBuyingInv : "other",
}
export default function DeleteInvoice(props) {


  const { toggle , item  , setRefresh } = props


    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);


    const table = [
      {size : 6 , name : "created_by", text : 'اسم منشئ الفاتورة'  },
      {size : 6 , name : 'place_name' , text : 'مكان ادخال الفاتورة'  },
      // {size : 4 , name : 'department_name' , text : ' القسم'  },
      {size : 6 , name : 'classification' , text : 'تصنيف الفاتورة'  },
      {size : 6 , name : 'date' , text : 'تاريخ الفاتورة' , custom : d => _date(d.date).dmyt()   },
  ]

  const handleDelete = async () => {
    const delete_props = {
      url: apis.invoices.base(),
      id: item._id,
      setError,
      setSuccess,
      setLoading,
      setConfirmBtn,
      setRefresh,
      item: {
        ...item,
        _id: item._id + `?type=${invoiceTypesEnum[item?.__type]}`,
      },

      onSuccess: (data) => {
        setRefresh(refresh())
        toggle();
      },
    };

    await deleteFormHandler(delete_props);
  };
  

  return (
    <>
    

    <header className="w-fit m-auto px-4 text-center border-bottm-line ">
        <h3>  حذف فاتورة {props.item?.classification}  ({props.item?.invNumber})</h3>
    </header>
    <Signboard error={error} success={success} loading={loading}/>

    <DeleteTable  table={table} data={[item]} />


    <div className="col-12 col-md-12 mt-4">
        <div className="form-group row justify-content-between align-items-center">
            <div className="col">
                <button className="close-btn" onClick={() => toggle()}>
                    {confirmBtn ? "الغاء" : "اغلاق"}
                </button>
            </div>
            <div className="col d-flex justify-content-end">
                {confirmBtn && (
                    <button className="add-btn" onClick={handleDelete}>
                        نعم
                    </button>
                )}
            </div>
        </div>
    </div>
</>
  )
}
