import {Grid} from "../../config/imports";
import Icons from "../Icons";

export default function BrowserControls(props){
    const {tabToBeRendered , toggle , tabHandler , setTabToBeRendered , data } = props
    return(

        <Grid.FlexResponsive className={'align-items-center mt-4 justify-content-between '}>
            <div className="span-6">
                <button type="button" className="close-btn" onClick={toggle}>الغاء</button>
            </div>
            <div className="text-center">
                {
                    tabToBeRendered && tabToBeRendered > 1 &&
                    <button type={'button'}  style={{backgroundColor : "#0CC857"}} className="previous-tab btn  mx-2" onClick={()=>{
                        const index = tabToBeRendered - 1
                        const tab = document.querySelector(`.tab-container[data-index="${index}"]`)
                        tabHandler( undefined  , tab , index )
                        if(tabToBeRendered > 1) setTabToBeRendered(index)
                    }} ><Icons.WhiteArrow style={{transform : 'rotate(180deg)'  , stroke : '#fff'   }}/>
                    </button>
                }{
                tabToBeRendered && tabToBeRendered < data.length &&
                <button type={'button'}  style={{backgroundColor : "#0CC857"}} className="next-tab btn  mx-2"
                        onClick={() => {
                            const index = tabToBeRendered + 1
                            const tab = document.querySelector(`.tab-container[data-index="${index}"]`)
                            tabHandler(undefined, tab, index)
                            if (tabToBeRendered < data.length) setTabToBeRendered(index)
                        }}><Icons.WhiteArrow/>
                </button>
            }
            </div>
            <div className="col-3">
                { tabToBeRendered && tabToBeRendered === data.length  && <div className="span-6"><button className="add-btn">  حفظ </button></div> }
            </div>
        </Grid.FlexResponsive>
    )
}
