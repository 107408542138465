import { useEffect, useState } from "react";

import {
  setInputsToDefault,
  createFormHandler,
} from "../../../../../helpers/form.helper";

import PageMeta from "../../../../../components/PageMeta";

import {EditForm, InputNumber, InputText} from "../../../../../config/imports";
import { strain_api} from "../../../../../config/apis";
import useAlert from "../../../../../hooks/useAlert";

const FORM_HEADER = "اضافة سلالة جديدة";
const MAIN_TITLE = "قائمة سلالة الطيور";

const CreateStrain = (props) => {

  const {setMainData, toggle , setRefresh} = props;

  const [data, setData] = useState({
    name: "",
    breeding_age: "",
    production_age: "",
    dead_rate : "", 
    unbelievable : "", 
    ideal_conditions : "", 
    average_output : "", 
    medium_conditions : "", 
    lowest_production  : "" ,
    feedCons : "" 
  });



  const {Alert  , setters  } = useAlert();


  
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setters.error(null);
    const message = validatePercentages(data)
    
    if(message) return setters.error(message)

    setters.loading(true);

    const create_props = {
      url: strain_api.breeding.create(),
      data,
      setLoading : setters.loading,
      setError : setters.error,
      setSuccess : setters.success,
      setRefresh,
      setInputsToDefault,
      setData,
      FORM_HEADER: `الاضافة`,
      toggle,
    };

    // console.log({create_props})
    await createFormHandler(create_props);
  };
  // console.log(strain_api.breeding.create())

  const handleInputChange = (e) => {
    const {name, value, type} = e.target;
    setData((prev) => ({
      ...prev,
      [name]: type === "number" ? +value : value,
    }));
  };


  return (
    <div className="temp-form">
      <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE} />
      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
        <h3>{FORM_HEADER}</h3>
      </header>

      <Alert />

      <EditForm
          visible={props.showFooter === undefined}
          onSubmit={handleFormSubmit}
          toggle={toggle}
          onChange={handleInputChange}>
        <InputText name={'name'}   handleInputChange={handleInputChange} placeholder={'5'}  title={' الاسم'} value={data.name}/>
        <InputNumber name={'breeding_age'}   handleInputChange={handleInputChange} placeholder={'5'}  title={'  عمر مرحلة التربيه بالاسبوع'} value={data.breeding_age}/>
        <InputNumber name={'production_age'}   handleInputChange={handleInputChange} placeholder={'3'}  title={'  عمر مرحلة الانتاج بالاسبوع'} value={data.production_age}/>
        <><div className="section-title"> <span>  القيم الافتراضيه </span> </div></>
        <InputNumber className="col-4" name={'dead_rate'}   handleInputChange={handleInputChange} placeholder={''}  title={' نسبة النافق '} value={data.dead_rate}/>
        <InputNumber className="col-4" name={'feedCons'}   handleInputChange={handleInputChange} placeholder={''}  title={'  استهلاك الاعلاف بالجرام '} value={data.feedCons}/>
        <InputNumber className="col-4" name={'unbelievable'}   handleInputChange={handleInputChange} placeholder={''}  title={' غير معقول '} value={data.unbelievable}/>
        <InputNumber className="col-3" name={'ideal_conditions'}   handleInputChange={handleInputChange} placeholder={''}  title={' الظروف المثاليه '} value={data.ideal_conditions}/>
        <InputNumber className="col-3" name={'average_output'}   handleInputChange={handleInputChange} placeholder={''}  title={' متوسط الانتاج '} value={data.average_output}/>
        <InputNumber className="col-3" name={'medium_conditions'}   handleInputChange={handleInputChange} placeholder={''}  title={' الظروف المتوسطة'} value={data.medium_conditions}/>
        <InputNumber className="col-3" name={'lowest_production'}   handleInputChange={handleInputChange} placeholder={''}  title={' أدنى إنتاج '} value={data.lowest_production}/>

      </EditForm>
    </div>
  );
};

export default CreateStrain;


const validatePercentages =  (state)=>{
  const {
    name, breeding_age, production_age, lowest_production, medium_conditions , 
    dead_rate , unbelievable , ideal_conditions , average_output , feedCons} = state
  if (!name) 
      return  "الاسم مطلوب"
  else if (!breeding_age) 
      return  "عمر التربية مطلوب"
  else if (!production_age) 
      return  "عمر الإنتاج مطلوب"
  else if (lowest_production < 0) 
      return   "لا يمكن أن يكون الحد الأدنى للإنتاج أقل من صفر"
  else if (medium_conditions <= lowest_production) 
      return   "لا يمكن أن يكون الحد المتوسط للإنتاج أصغر من الحد الأدنى للإنتاج أو مساوي له"
  else if (average_output <= medium_conditions) 
      return   "لا يمكن أن يكون متوسط الإنتاج أقل من الظروف المتوسطه"
  else if (ideal_conditions <= average_output) 
      return   "لا يمكن أن يكون الظروف المثالية أقل من متوسط الإنتاج"
  else if (unbelievable <= ideal_conditions) 
      return   "لا يمكن أن يكون الغير معقول أقل من الحالة المثالية"
  else if (unbelievable > 100) 
      return   "لا يمكن أن يكون الغير معقول أكبر من 100"
  else if (dead_rate > 100) 
      return "لا يمكن أن يكون نسبة النفاق أكبر من 100"
  else if (dead_rate < 0) 
      return "لا يمكن أن يكون متوسط أستهلاك العلف أقل من الصفر"
  else if (feedCons < 0)
      return "لا يمكن أن يكون  استهلاك العلف أقل من الصفر"
    
}




