import "../../styles/operators.styles.scss"
import { QuickViewContainer, Signboard, TableHeader, useFetch, PageMeta, useRef, } from "../../config/imports";

import {operatorAPI} from "./operator.routes";
import CreateOperator from "./CreateOperator";
import UpdateOperator from "./UpdateOperator";
import DeleteOperator from "./DeleteOperator";
import React, { useEffect } from "react";
import { Table } from "../../layout/table";
import { initQuery } from "../../helpers/helpers";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, operatorsBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";


const Operators = () => {
    const api = operatorAPI.paginated


    // console.log("api from operators" , api);
    
    const { data, setData: setMainData, setters, paginate, alert } = useFetch(api)

    const createRef = useRef()
    const updateRef = useRef()
    const deleteRef = useRef()


    const button = [
        {
            title : "إضافة عملاء او موردين أو مناديب",
            onclick : ()=> createRef.current.toggle()
        }
    ]

    const views = [
        {
            ref : createRef,
            component : <CreateOperator setMainData={setMainData} />
        },
        {
            ref : updateRef,
            component : <UpdateOperator  setMainData={setMainData} />
        },
        {
            ref : deleteRef,
            component : <DeleteOperator setMainData={setMainData} />
        }
    ]

    
    const table = [
        {size : 1 , name : "#" , text : "#"  },
        {size : 4 , name : "name" , text :  'الاسم' },
        {size : 2 , name : "city" , text :  'المدينة' },
        {size : 3 , name : "phone" , text :  'رقم الهاتف' , skipComma : true },
        {size : 3 , name : "mobile" , text :  'الموبيل'   , skipComma : true },
        {size : 3 , name : "email" , text :  'الأميل' ,   },
        {size : 3 , name : "website" , text :  'الموقع' },
        {size : 3 , name : "more_info" , text :  'لمحة موجزة' },
        {size : 2 , name : '' , text : '' , }
    ]




    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            operatorsBreadCrumb,
        ])
  } , [])
    
    return(
        <>
            <div className="base-card bg-white p-3 my-5 operators-container">

                <PageMeta title={"الموردين / العملاء"} />
                
                <Signboard {...alert} />

                <QuickViewContainer views={views} />

                <TableHeader buttons={button}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />

                <Table table={table} data={data}  updateRef={updateRef} deleteRef={deleteRef}  className="mt-3" />



            </div>
       </>
    )
}

export default Operators;








