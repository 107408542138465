import React from 'react'
import InputOperatorInvoices from './InputOperatorInvoices'
import InputProjectInvoices from './InputProjectInvoices'
import { buyInvoiceTypes } from '../data/invoice-selectors.data'
import useSelectInvoiceStore from '../stores/select-invoice.store'
import { InputSelect } from '../../../config/imports'

export default function SellInvoiceSelectors() {


  const buyType = useSelectInvoiceStore(state => state.buyType)
  const setBuyType = useSelectInvoiceStore(state => state.setType)
  





  return (
    <div>
      <div className="row g-0">
        <div className="col-4">
          <InputProjectInvoices />
        </div>
        <div className="col-4">
        <InputSelect
          title={"نوع الفاتورة"}
          className={"col-12"}
          handleInputChange={(e) => setBuyType(e.target.value)}
          name="type"
          defaultValue={buyType}
          options={buyInvoiceTypes}
        />
        </div>
        <div className="col-4">
          <InputOperatorInvoices />
        </div>
      </div>
    </div>
  )
}
