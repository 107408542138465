import {useEffect, useRef, useState} from "react";
import {apis, employees_api, users_api} from "../../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../../helpers/form.helper";

import {
    EditForm, Grid, InputDate, InputNumber,
    InputSelect,
    InputText,
    InputTextArea, InputTextAsNumber,
    PageMeta,
    Signboard
} from "../../../config/imports";
import ArrayHelpers from "../../../helpers/array.helpers";
import Select from "../../../components/FormControls/Select";
import Icons from "../../../layout/Icons";
import postData from "../../../helpers/axios/axios.post";
import Browser from "../../../layout/browser/Browser";
import DisplayFileImagesGroup from "../../../components/images/DisplayFileImagesGroup";
import useUsersData from "../hooks/useUsersData";

export default function Create(props){
    const userImgRef = useRef(),
          userImgTitleRef = useRef() ,
          userImgFile = useRef();


    const { toggle  , roles , updateMain} = props;



    const { options  } = useUsersData()



    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        user_img : "",
        gender : '',
        username : "",
        birth_date : '',
        place : '',
        city : '',

        email : '',
        postal_code : '',
        phone : '',
        mobile : '',

        homes : [],
        projects : [],
        departments : [],
        stocks : [],

        password : '',
        confirm_password : '',
        roles : [],
        user_type : '',
    })


    // console.log({data})

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };


    // when update is done and success is true will reload main data again
    useEffect(()=>{
        if(success && success.length > 0)
            updateMain(true)
        else updateMain(false)
    } , [success])

    const handleFormSubmit = async (e) => {
        setLoading(true);
        setError('')
        setSuccess('')
        e.preventDefault();

        const {data: postedData, error, statusCode,} = await postData(users_api.create , data, {
            headers :{
                'Content-Type': 'multipart/form-data',
            }})
        setLoading(false)
        if (error)  return setError(error)
        setSuccess("تم اضافة المستخدم بنجاح")

    };



    const browserData = [
        {
            tab  : { title : " البيانات الشخصية" , size : 6},
            content :<>
                        <InputText name={'username'} handleInputChange={handleInputChange} title={'اسم المستخدم   '} placeholder={'@username'} defaultValue={data.username}/>
                        <InputDate name={'birth_date'} handleInputChange={handleInputChange} title={'تاريخ الميلاد'} defaultValue={data.birth_date}/>
                        <InputSelect name={'gender'}  handleInputChange={handleInputChange} title={'الجنس'} defaultValue={data.gender} options={[
                            { value : "ذكر" , text : 'ذكر' },
                            { value : "انثى" , text : 'انثى' },
                        ]}/>
                        <InputText className={'col-6'} name={'place'} handleInputChange={handleInputChange} title={' المكان '}   defaultValue={data.place}/>
                        <InputText className={'col-6'} name={'city'} handleInputChange={handleInputChange}   title={' المدينه '} defaultValue={data.city}/>
                    </>
        },
        {
            tab  : {title : " بيانات التواصل " , size : 5},
            content :<>
                        <InputText          className={'col-6'} name={'email'} handleInputChange={handleInputChange} title={'البريد الالكتروني'} defaultValue={data.email}/>
                        <InputTextAsNumber  className={'col-6'} name={'postal_code'} handleInputChange={handleInputChange} placeholder={'5640654'} label={' الرمز البريدي'} defaultValue={data.postal_code}/>
                        <InputTextAsNumber  className={'col-6'} name={'phone'} handleInputChange={handleInputChange} placeholder={'0552181341'} label={'رقم الهاتف'} defaultValue={data.phone}/>
                        <InputTextAsNumber  className={'col-6'} name={'mobile'} handleInputChange={handleInputChange} placeholder={'20134567890'} label={'رقم الجوال'} defaultValue={data.mobile}/>
                     </>
        },
        {
            tab  : {title : "بيانات الدخول" , size : 5},
            content :<>
                        <InputText className={'col-12'} name={'email'} handleInputChange={handleInputChange} title={'البريد الالكتروني'} defaultValue={data.email}/>
                        <InputText className={'col-6'} name={'password'} handleInputChange={handleInputChange} title={'كلمه المرور'} defaultValue={data.password}/>
                        <InputText className={'col-6'} name={'confirm_password'} handleInputChange={handleInputChange} title={'تاكيد كلمه المرور'} defaultValue={data.confirm_password}/>
                    </>
        },
        {
            tab  : {title : " الادوار" , size : 4},
            content :<>
                <div className="text-center">
                    <div className="flex justify-center">
                        <DisplayFileImagesGroup className="justify-center"  files={[data.user_img]} setData={setData} keyNAme={'user_img'} />
                    </div>
                    <div data-error="" className="form-group">
                        <label   className='choose-img-btn'  htmlFor="user_img">
                            <span>   اختر صورة للمستحدم</span>
                            <i  className="fa-solid fa-bold fa-upload"></i>
                        </label>
                        <input type="file" name="user_img" id="user_img" required placeholder="اختر صورة" accept="image/*"
                            onChange={(e)=> setData({...data , user_img : e.target.files[0]})}
                            className="hide-arrow form-control"
                        />
                    </div>
                </div>
                <InputSelect multiple={true} className={'col-6'} name={'roles'}  handleInputChange={handleInputChange} title={'صلاحيات البرنامج'} defaultValue={data.roles} options={roles} />
                <InputSelect  className={'col-6'} name={'user_type'}  handleInputChange={handleInputChange} title={'الصلاحيات الاداريه'} defaultValue={data.user_type} options={[
                    { value : "worker" , text : 'عامل بيت' },
                    { value : "warehouse_man" , text : 'أمين مستودع' },
                    { value : "project_supervisor" , text : 'مشرف مشروع' },
                    { value : "project_manager" , text : 'مدير مشروع' },
                    { value : "doctor" , text : 'الطبيب المشرف' },
                    { value : "farm_manager" , text : 'مدير مزرعه' },
                    { value : "projects_manager" , text : 'مدير مشاريع' },
                    { value : "delegate" , text : 'مندوب المشريات' },
                ]} />
            </>
        },
        {
            tab  : {title : " الافرع" , size : 4},
            content :<>
                <InputSelect multiple={true} className={'col-12'} name={'homes'}  handleInputChange={handleInputChange} title={'البيوت'} defaultValue={data.homes} options={options.homes} />
                <InputSelect multiple={true} className={'col-12'} name={'departments'}  handleInputChange={handleInputChange} title={'الاقسام '} defaultValue={data.departments} options={options.departments} />
                <InputSelect multiple={true} className={'col-12'} name={'projects'}  handleInputChange={handleInputChange} title={'المشاريع '} defaultValue={data.projects} options={options.projects} />
                <InputSelect multiple={true} className={'col-12'} name={'stocks'}  handleInputChange={handleInputChange} title={'المستودعات '} defaultValue={data.stocks} options={options.stocks} />
            </>
        },
    ]


    return(
        <>
            <PageMeta title={'إضافة مستخدم جديد'} originTitle={' المستخدمين '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto ">
                <h3>   إضافة مستخدم جديد</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm visible={false} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
                <Browser data={browserData} toggle={toggle} />
            </EditForm>

        </>
    )
}
