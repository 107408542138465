import {useState} from "react";

import {
    setInputsToDefault,
    createFormHandler,
} from "../../../../../helpers/form.helper";

import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";
import {strainAPI} from "../../../../origin/bird_breed/bird_breed.routes"

import InputText from "../../../../../components/FormControls/InputText";
import InputTextArea from "../../../../../components/FormControls/InputTextArea";
import FormFooter from "../../../../../components/FormControls/FormFooter";
import SelectOperator from "../../../../../components/Shared/SelectOperator";

const FORM_HEADER = "اضافة صنف علف";
const MAIN_TITLE = "قائمة اصناف الاعلاف";

const UpdateFeedClass = ({setMainData, toggle , item}) => {

    const [data, setData] = useState(item);
    let names = Object.keys(data);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // const create_props = {
        //     url: strainAPI.url,
        //     data,
        //     setLoading,
        //     setError,
        //     setSuccess,
        //     setMainData,
        //     setInputsToDefault,
        //     setData,
        //     FORM_HEADER,
        // };
        //
        // createFormHandler(create_props);
    };

    const handleInputChange = (e) => {
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const WeightUnits = [
        {
            name: "طن",
            _id: "'طن"
        }, {
            name: "كيلو",
            _id: "'كيلو"
        }, {
            name: "جرام",
            _id: "'جرام"
        },
    ]
    return (
        <div className="temp-form">
            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <form onSubmit={handleFormSubmit} method="post">
                <div className="row align-items-center">
                    <InputText defaultValue={data["name"]}  name={names[0]} title={"الاسم"} handleInputChange={handleInputChange} className={"col-12 col-md-6"}/>
                    <InputText name={names[1]} title={"وحدة القياس"} handleInputChange={handleInputChange} className={"col-12 col-md-6"}/>
                    <SelectOperator defaultValue={data["operator"]._id} handleInputChange={handleInputChange}  className={"col-12"}  />
                    <InputTextArea name={names[9]} handleInputChange={handleInputChange} title={"معلومات اضافية"} />
                    <FormFooter handleFormSubmit={handleFormSubmit} toggle={toggle} />
                </div>

            </form>
        </div>
    );
};

export default UpdateFeedClass;
