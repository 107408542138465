import React, { useState } from "react";

import { operatorAPI, originAPI } from "../origin.helpers";

import {PageMeta ,useFetch , Signboard  , Grid, Icons, QuickView, Table, TableHeader, useRef} from "../../../config/imports";

import OriginPackagingCrud from "./packaging_crud";


const Packaging = () => {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();

    const {data , error, setData: setMainData, isLoading,} = useFetch(originAPI.packaging);

    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);


    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }

    const sizes = {
        serial : 1,
        name : 3,
        operator : 4,
        measuringUnit : 3,
        types : 2,
        packetsNum : 3,
        order : 2,
        info : 4,
        settings : 1
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}>#</Grid.Span>,
        name:<Grid.Span size={sizes.name}> الاسم  </Grid.Span>,
        operator:<Grid.Span size={sizes.operator}>   المورد    </Grid.Span>,
        measuringUnit:<Grid.Span size={sizes.measuringUnit}>     وحدة القياس  </Grid.Span>,
        types:<Grid.Span size={sizes.types}>   نوع     </Grid.Span>,
        packetsNum:<Grid.Span size={sizes.packetsNum}>    عدد الرزمه  </Grid.Span>,
        order:<Grid.Span size={sizes.order}>   الطلبيه    </Grid.Span>,
        info:<Grid.Span size={sizes.info}>   تفاصيل    </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
    }

  return (
    <>

      <PageMeta title={"التعبئة والتغليف"} />
        <QuickView ref={createRef}><OriginPackagingCrud.Create  operators={operators}  setMainData={setMainData}/></QuickView>
        <QuickView ref={updateRef}><OriginPackagingCrud.Update  operators={operators} setMainData={setMainData}/></QuickView>
        <QuickView ref={deleteRef}><OriginPackagingCrud.Delete  operators={operators} setMainData={setMainData}/></QuickView>

        <Signboard {...signboard} />

        <TableHeader  buttons={ [
            {
                title: " اضافة نوع تعبئة جديد ",
                onclick: () => createRef.current.toggle()
            }
        ]}/>
        <div className="" style={{marginTop: '150px'}}></div>
        <Table.Header header={header}   />

        {
            data &&  data.length > 0  && data.map((item , index) => (
                <Grid.FlexResponsive  key={item._id} className={'align-items-center data-row'}>
                    <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                    <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
                    <Grid.Span size={sizes.operator}> {item.operator?.name ?? 'بدون'} </Grid.Span>
                    <Grid.Span size={sizes.measuringUnit}>{item.measuringUnit}</Grid.Span>
                    <Grid.Span size={sizes.types}> {item.types} </Grid.Span>
                    <Grid.Span size={sizes.packetsNum}> {item.packetsNum} </Grid.Span>
                    <Grid.Span size={sizes.order}> {item.order} </Grid.Span>
                    <Grid.Span size={sizes.info}> {item.info} </Grid.Span>
                    <Grid.Span size={sizes.settings} className='settings-buttons'>
                        <button  onClick={()=> updateRef.current.toggle(item)}><Icons.Edit /></button>
                        <button  onClick={()=> deleteRef.current.toggle(item)}><Icons.Delete /></button>
                    </Grid.Span>
                </Grid.FlexResponsive>
            ))
        }

    </>
  );
};

export default Packaging;
