import React, { useEffect } from 'react'
import useBreadCrumb from '../../../components/breadcrumbs/hooks/breadcrumb.hook'
import { useHomeBreadCrumbStore } from '../stores/home_breadcrumb.store'
import { useParams } from 'react-router-dom'
import { setStoreBreadCrumb } from '../../../components/breadcrumbs/store/breadcrumb.store'

export default function useHomeBreadCrumbs() {

    const {id} = useParams()

    const fetch = useHomeBreadCrumbStore(state => state.fetch)


    const crumbs = useHomeBreadCrumbStore(state => state.crumbs)

   


    useEffect(() => {
        fetch(id)
    } , [id])




    useEffect(() => {

      
        if(!crumbs || !crumbs.length) return
        else  setStoreBreadCrumb(crumbs)
    } , [crumbs])


}
