import {useMemo, useState} from "react";
import {apis} from "../../../../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../../../../helpers/form.helper";

import {
    EditForm, InputNumber,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../../config/imports";
import ArrayHelpers from "../../../../../helpers/array.helpers";
import {useParams} from "react-router-dom";
import InputMassUnit from "../../../../../components/Shared/InputMassUnit";

export default function Create(props){

    const {id : home_id } = useParams()

    const {setMainData, toggle , operators , types} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        home_id,
        type: "",
        unit: "",
        quantity: "",
        info: "",
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.orders.maintenance.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        // console.log(create_props.data)

        await createFormHandler(create_props);
    };

    
    const measuringUnits = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])

    return(
        <>
            <PageMeta title={'اضافة طلب صيانه'} originTitle={' طلبات الصيانه '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة طلب صيانه</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>

                <InputSelect  className={'col-4'}    name={'type'} title={'النوع '} defaultValue={data.type} nameKey={'name'}  options={types} />
                {/* <InputMassUnit name={'unit'} title={'وحدة القياس'} value={data.unit} setData={setData}  /> */}
                <InputSelect    name={'unit'} title={'وحدة القياس '} defaultValue={data.unit}  options={measuringUnits} />
                <InputNumber className={'col-4'}  name={'quantity'} title={'الكمية المطلوبة'} defaultValue={data.quantity} onChange={handleInputChange} />
                <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />
            </EditForm>

        </>
    )
}
