import React, { useEffect, useState } from 'react'
import Loading from '../components/Loading'
export const ERROR_MESSAGE_TIMEOUT = 5000
export const SUCCESS_MESSAGE_TIMEOUT = 2000
export default function useAlert(props) {

 const [Alert , setAlert] = useState({
    type : undefined,
    message : ""
 })

//  console.log({Alert})

 const Alerts = {
    success : (message)=> <div className='alert alert-success text-center w-fit m-auto'> {message} </div>,
    error : (message)=> <div className='alert alert-danger text-center w-fit m-auto'> {message} </div>,
    loading : ()=> <Loading />,
    undefined : () => null
 }


 
 
 useEffect(() => {
     // reset alert after 5 seconds when it is error
     if(Alert.type === "error"){
        setTimeout(()=> setAlert({type : undefined , message : ""}) , ERROR_MESSAGE_TIMEOUT)
     }
    //  reset alert after 2 seconds when it is success
    if(Alert.type === "success"){
        setTimeout(()=> setAlert({type : undefined , message : ""}) , SUCCESS_MESSAGE_TIMEOUT)
    }


 } , [Alert.type])
 



  return {
    Alert : ()=><div className="alert-container">
            { Alerts[Alert.type](Alert.message ?? "")  }
    </div>,

    
    setAlert,
    setters : {
        success : (message = " تمت العملية بنجاح ")=> setAlert({
            type : "success",
            message
        }),
        error : (message = " حدث خطأ ما ")=> setAlert({
            type : "error",
            message
        }),
        loading : (message = "")=> setAlert({
            type : "loading",
            message 
        }),
        reset : ()=> setAlert({ 
            type : undefined,
            message : ""
        })

    },
    states : {
        success : Alert.type === "success" ? Alert.message : false ,
        error : Alert.type === "error"  ? Alert.message : false,
        loading : Alert.type === "loading",
    },
    signboard : {
        success : Alert.type === "success" ? Alert.message : false ,
        error : Alert.type === "error"  ? Alert.message : false,
        loading : Alert.type === "loading",
    }   

  }
}
