import PageMeta from "../../../components/PageMeta";

import RouterLinks from "../../../components/LinkList";
import useNavigator from "../../../hooks/useNavigator";

const SupplyAndPackaging = () => {
    const FEED_LINKS = [
        {
            name: "قائمة شراء التعبئة و التغليف",
            path: "buy",
        },
        {
            name: "ارصدة المشارع",
            path: "balances",
        },
        {
            name: "قائمة تقارير خروج الاصناف",
            path: "export",
        },
    ];

    useNavigator("packaging", "buy");

    return (
        <>
            <PageMeta title={"الصيانة"} />

            {<RouterLinks underline={true} data={FEED_LINKS} />}
        </>
    );
};

export default SupplyAndPackaging;
