import CreateInitBalance from './CreateInitBalance';
import UpdateInitBalance from './UpdateInitBalance';
import DeleteInitBalance from './DeleteInitBalance';

const OpeningBalanceCrud = {
    Create : CreateInitBalance,
    Update : UpdateInitBalance,
    Delete : DeleteInitBalance
}
export default OpeningBalanceCrud
