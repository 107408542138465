import {_date,PageMeta,QuickView,Signboard,useFetch,} from "../../../config/imports";
import {useParams} from "react-router-dom";
import {departments_api} from "../../../config/apis";
import { useRef} from "react";
import {Table} from "../../../layout/table";
import FeedInvoiceCrud from "./feed_crud";
import Helpers from "../../../helpers/helpers";
import { TableHeader } from "../../../components/TableView";

const DepartmentHomeFeedInvoice = ()=>{

    const {id : DEPARTMENT_ID} = useParams();

    const createFeedRef = useRef()
    const updateFeedRef = useRef()
    const deleteFeedRef = useRef()

    const api = departments_api.feed_invoices.read(DEPARTMENT_ID)

    const {data : invoices , isLoading : feed_invoices_isLoading, error : feed_invoices_error, setData : setFeedInvoices ,paginate , setters , setRefresh} = useFetch(api)

    const {data : department_details , isLoading : department_details_isLoading, error : department_details_error} = useFetch(departments_api.details(DEPARTMENT_ID))

    // console.log({department_details})
    const tableContent = [
        { name : "#" , size : "1"  , text : "#" },
        { name : 'invNumber' , size : "2" , text : 'رقم الفاتورة' },
        { name : 'operator' , size : "3" , text : 'اسم العميل' , custom : item => item?.operator?.name },
        { name : 'date' , size : "3" , text : 'تاريخ الفاتورة' , custom : item => _date(item?.date).dmy() },
        { name : 'feed_type' , size : "3" , text : 'نوع العلف' , custom : item => item?.feed_type?.name },
        { name : 'measuring_unit' , size : "2" , text : '  وحدة القياس ' },
        { name : 'unit_price' , size : "3" , text : ' سعر الوحدة' },
        { name : 'total_quantity_from_homes' , size : "2" , text : ' الكمية' },
        { name : 'total_price' , size : "3" , text : ' السعر' },
        { name : "" , size : "1" , text : "" },
    ]



    const Signboard_data = {
        error : feed_invoices_error   || department_details_error,
        loading : feed_invoices_isLoading    || department_details_isLoading
    }

    const buttons=[{ title : '  شراء اعلاف للقسم' , onclick : ()=> createFeedRef.current.toggle() }]

    return <>
        <QuickView ref={createFeedRef} ><FeedInvoiceCrud.Create setRefresh={setRefresh} setMainData={setFeedInvoices} department={DEPARTMENT_ID} project={department_details?.project} /></QuickView>
        <QuickView ref={updateFeedRef} ><FeedInvoiceCrud.Update setRefresh={setRefresh} setMainData={setFeedInvoices} department={DEPARTMENT_ID} project={department_details?.project} /></QuickView>
        <QuickView ref={deleteFeedRef} ><FeedInvoiceCrud.Delete setRefresh={setRefresh} setMainData={setFeedInvoices} department={DEPARTMENT_ID} project={department_details?.project} /></QuickView>
        <PageMeta title="فواتير شراء العلف" />
        <Signboard {...Signboard_data} />
        <TableHeader title="فواتير شراء العلف"  buttons={buttons} api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate}  /> 
        <Table data={invoices} table={tableContent} updateRef={updateFeedRef} deleteRef={deleteFeedRef} />
    </>
   
}
export default DepartmentHomeFeedInvoice
