const InputText = (props) => {
  const {
    label,
    titleInvalid,
    pattern,
    className,
    handleInputChange,
    name,
    title,
    defaultValue,
    type,
    isReadonly = false,
    placeholder,
      disabled
  } = props

  // props.required = props.required || true
  return (
    <>
      <div className={className || "col-12 col-md-4 col-lg-4"}>
        <div data-error="" className="form-group">
          <label htmlFor={name} className={(props?.labelProps?.className) || ""} >{props?.labelProps?.content || label || title}</label>
          <input
            onClick={props.onClick && props.onClick}
            checked={props.checked&&props.checked}
            type={type}
            name={name}
            id={name}
            required={props.required === undefined || props.required}
            readOnly={isReadonly}
            placeholder={placeholder ?? label ?? title}
            value={defaultValue}
            onChange={handleInputChange}
            className={`form-control  ${props?.input?.className || "" }`}
            pattern={pattern || null}
            title={titleInvalid || null}
            inputMode={pattern ? "numeric" : null}
            disabled={disabled ? disabled : false}
            step='any'
          />
        </div>
      </div>
    </>
  );
};

export default InputText;
