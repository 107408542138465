import axios from "axios";
import axiosAuthParams from "./axios.auth.params";

export default async (url, payload , config = {} , signal) => {
  // payload.axiosAuthParams = axiosAuthParams;
  // config = {
  //   headers : {
  //     'Content-Type': 'multipart/form-data',
  //   }
  // }



  const configData = {
    headers: {
      ...axiosAuthParams().headers,
      ...config.headers,
    },
    signal : signal || null
  }
  try {
    const response = await axios.post(url, payload , configData);
    return {
      data: response.data.data || response.data,
      error: null,
      statusCode: response.data.statusCode || response.status,
      paginate :{
        pages: response?.data?.pages_count ,
        total : response?.data?.total_items ,
        total_per_page : response?.data?.items_per_current_page
      }
    };
  } catch (error) {
    if(error.response.data.jwt && error.response.data.jwt.user === null){
      localStorage.removeItem('user')
      window.location.href = '/login'
    }
    return {
      data: null,
      error: error.response.data.error,
      statusCode: error.response.status,
    };
  }
};
