import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";
import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";

import AddSpareExportReport from "./AddSpareExportReport";
import UpdateSpareExportReport from "./UpdateSpareExportReport";
import DeleteSpareExportReport from "./DeleteSpareExportReport";

const SpareClassExportingReports = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);

    const title = "قائمة تقارير خروج الاصناف"

    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "اضافة تقرير خروج",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "name", col: 2, title:"نوع التعبئة و التغليف"},
        {name: "type", col: 1, title: "الكمية"},
        {custom : itm => itm.operator.name , col: 2, title: "المستلم"},
        {name: "unitprice", col: 1, title: "المشروع"},
        {name: "total", col: 1, title: "القسم"},
        {name: "total", col: 1, title: "البيت"},
        {name: "total", col: 1, title: "التاريخ"},
        {name: "total", col: 1, title: "معلومات اضافية"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={title}/>

            <QuickView ref={createRef}>
                <AddSpareExportReport setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateSpareExportReport setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteSpareExportReport setMainData={setMainData}/>
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={title}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default SpareClassExportingReports;
