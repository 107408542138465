const all_units = [
    {
        unit: "m",
        factor: 1,
        en_name: "meter",
        ar_name: "متر",
        description: "متر",
        symbol: "m",
        system: "metric",
        type: "length",
        is_base_unit: true,
    },
    {
        unit: "cm",
        factor: 100,
        en_name: "centimeter",
        ar_name: "سم",
        description: "سم",
        symbol: "cm",
        system: "metric",
        type: "length",
        is_base_unit: false,
    },
    {
        unit: "mm",
        factor: 1000,
        en_name: "millimeter",
        ar_name: "ملم",
        description: "ملم",
        symbol: "mm",
        system: "metric",
        type: "length",
        is_base_unit: false,
    },
    {
        unit: "km",
        factor: 1000000,
        en_name: "kilometer",
        ar_name: "كيلومتر",
        description: "كيلومتر",
        symbol: "km",
        system: "metric",
        type: "length",
        is_base_unit: false,
    },
    {
        unit: "in",
        factor: 0.0254,
        en_name: "inch",
        ar_name: "بوصة",
        description: "إنش",
        symbol: "in",
        system: "metric",
        type: "length",
        is_base_unit: false,
    },
    {
        unit: "ft",
        factor: 0.3048,
        en_name: "foot",
        ar_name: "قدم",
        description: "فيت",
        symbol: "ft",
        system: "metric",
        type: "length",
        is_base_unit: false,
    },
    {
        unit: "Ton",
        factor: 1000,
        en_name: "ton",
        ar_name: "طن",
        description: "طن",
        symbol: "Ton",
        system: "metric",
        type: "mass",
        is_base_unit: false
    },
    {
        unit: "kg",
        factor: 1,
        en_name: "kilogram",
        ar_name: "كيلوجرام",
        description: "كيلوجرام",
        symbol: "kg",
        system: "metric",
        type: "mass",
        is_base_unit: true
    },
    {
        unit: "g",
        factor: 0.001,
        en_name: "gram",
        ar_name: "جرام",
        description: "جرام",
        symbol: "g",
        system: "metric",
        type: "mass",
        is_base_unit: false
    },
    {
        unit: "lb",
        factor: 0.453592,
        en_name: "pound",
        ar_name: "بوند",
        description: "بوند",
        symbol: "lb",
        system: "metric",
        type: "mass",
        is_base_unit: false
    },
    {
        unit: "oz",
        factor: 0.0283495,
        en_name: "ounce",
        ar_name: "اونصة",
        description: "اونصة",
        symbol: "oz",
        system: "metric",
        type: "mass",
        is_base_unit: false
    },
    {
        unit: "l",
        factor: 1,
        en_name: "liter",
        ar_name: "لتر",
        description: "لتر",
        symbol: "l",
        system: "metric",
        type: "volume",
        is_base_unit: true
    },
    {
        unit: "ml",
        factor: 0.001,
        en_name: "milliliter",
        ar_name: "ملي لتر",
        description: "ملي لتر",
        symbol: "ml",
        system: "metric",
        type: "volume",
        is_base_unit: false
    },
    {
        unit: "mile",
        factor: 1609.34,
        en_name: "mile",
        ar_name: "ميل",
        description: "mile",
        symbol: "mi",
        system: "imperial",
        type: "length",
        is_base_unit: false,
    },
    {
        unit: "yard",
        factor: 0.9144,
        en_name: "yard",
        ar_name: "يارد",
        description: "yard",
        symbol: "yd",
        system: "imperial",
        type: "length",
        is_base_unit: false,
    },

]
function convertUnit(value, fromUnit, toUnit) {
    const fromFactor = findUnitFactor(fromUnit);
    const toFactor = findUnitFactor(toUnit);
    if (fromFactor === null || toFactor === null) {
        // console.error('Invalid units provided');
        return null;
    }
    const result = ( value * fromFactor) / toFactor;
    return result;
}
function findUnitFactor(unit) {
    const unitInfo = all_units.find((u) => u.unit.toLocaleLowerCase() === unit?.toLocaleLowerCase());
    if (unitInfo) {
        return unitInfo.factor;
    }
    return null;
}

export const getUnitName = symbol => {
    // console.log(symbol)
    return all_units.find(u => 
        (u.symbol.toLocaleLowerCase() === symbol.toLocaleLowerCase())
        || (u.unit.toLocaleLowerCase() === symbol.toLocaleLowerCase())
     )?.ar_name
}

function getUnitsByType(type) {
    return all_units.filter(unit => unit.type === type);
}


export { convertUnit }
