import React, { useMemo } from 'react';
import BlueBox from './BlueBox';


export default function BlueBoxes({data , hasRoosterInfo}) {


  const values = useMemo(() => {

    return {
      feed_consumption :    data?.analysis?.feed_consumption ,
      start_level :    data?.analysis?.level_start_birds ,
      current_level :    data?.analysis?.current_level_birds ,
      total_dead :    data?.analysis?.total_dead_birds ,
      total_dead_chicken :    data?.analysis?.total_dead_chicken_count ,
      total_dead_rooster :    data?.analysis?.total_dead_rooster_count ,
    }


  }, [data])

  return data && data.analysis && (
    <div className='flex'>
            {!hasRoosterInfo &&   <BlueBox title={" استهلاك الاعلاف بالطن "} value={values.feed_consumption} />}
            <BlueBox title={"اجمالي طيور المرحله"} value={values.start_level} />
            <BlueBox title={"اجمالي الطيور الحالي"} value={values.current_level} />
            <BlueBox title={"اجمالي الطيور النافقة"} value={values.total_dead} />
            <BlueBox title={"اجمالي نافق الدجاج"} value={values.total_dead_chicken} />
            { hasRoosterInfo &&   <BlueBox title={"اجمالي نافق الديوك"} value={values.total_dead_rooster} /> }
    </div>
  )
}
