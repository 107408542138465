import React, { useEffect, useState } from 'react'
import useIndexApi from './api/useIndexApi'
import useIndexStore from './store/index.store'
import Signboard from '../../components/Signboard'
import { NavLink } from 'react-router-dom'
import { paths } from '../../config/configs.env'
import { cn } from '../../helpers/class_name.helpers'
import PageMeta from '../../components/PageMeta'
import { setIndexPageBreadCrumbs } from '../../components/breadcrumbs/store/breadcrumbs.default'

export default function Index() {


    useIndexApi()


    useEffect(() => {
      setIndexPageBreadCrumbs()
    } , [])



    const NAV_LINKS_DATA = useIndexStore(state => state.data)

    // // const loading = useIndexStore(state => state.loading)

    useEffect(()=>{
      if(NAV_LINKS_DATA){
        window.localStorage.setItem("NAV_LINKS_DATA" , JSON.stringify(NAV_LINKS_DATA))
      }
    } , [NAV_LINKS_DATA])

    const [stored] = useState(JSON.parse(window.localStorage.getItem("NAV_LINKS_DATA")) ?? [])

    const sideNavLinks = !!NAV_LINKS_DATA.length  ? NAV_LINKS_DATA  : stored


  return (
    <div className='h-screen flex flex-col items-center justify-center'>
        <PageMeta title={'الصفحه الرئيسية'} />
        <header className='text-center mb-[50px]'>
                <h1> الرئيسية </h1>
        </header>

        <ul className='flex gap-4 flex-wrap justify-center'>
              {sideNavLinks.map((link) => (
                <li  key={link.id} id={link.id}>
                  <NavLink to={link.path} className={cn(
                    "flex flex-col items-center justify-center px-2 min-w-[200px] h-[150px] bg-white rounded-lg",
                    "hover:shadow-lg  transition-all"
                  )}>
                    <div className="mb-2">
                      <img src={ paths.uploads  + link.home_icon} alt={''} width={'30px'} />
                    </div>
                    <div className="whitespace-nowrap text-[20px]" title={link.title}>
                      {link.title}
                    </div>
           
                  </NavLink>
                </li>
              ))}
            </ul>
        
    </div>
  )
}
