import {GreenBox, useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";
import Helpers from "../../../helpers/helpers";

export default function StatsGreenBoxes(){
    const {data} = useFetch(apis.stats.boxes())

    const green_data = [
        {img : '/icos/receipt.png' , title : 'كمية فواتير الاعلاف  ' , value : Helpers.Number.commaFormat( data?.all_feed_invs_amount ?? 0) },
        {img : '/icos/usage.png' , title : 'استهلاك الاعلاف بالطن' , value : Helpers.Number.commaFormat( data?.all_feed_reports_amount ?? 0) },
        {img : '/icos/chart.png' , title : 'عدد النافق الكلى' , value : Helpers.Number.commaFormat( data?.all_dead_birds ?? 0) },
        {img : '/icos/coin.png' , title : 'الانتاجية' , value : (data?.productivity?.toFixed(2) ?? 0)  + "% " ?? 0 + "% "},
    ]

    return(
    <>
        <div className="row green-box-container justify-content-center mt-5 mb-2 g-2">
            {
                green_data.map((data , idx) => <GreenBox key={idx} img={data.img} title={data.title} value={data.value}/> )
            }
        </div>

    </>
    )
}
