import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { reports_api } from "../../../../../config/apis";
import { _date, InputDate, InputNumber, InputSelect, InputTextArea } from "../../../../../config/imports";
import CreateForm from "../../../../../components/crud/CreateForm";
import useStockCartons from "../../../../stocks/hooks/useStockCartons";
import useDebounce from "../../../../../hooks/useDebounce";
import { useCartonReportQuantityPrice } from "../../hooks/useReportQuantityPrice";

export default function Create(props){

    const {id} = useParams();
    const {setRefresh, toggle} = props;

    const {options : subCategoriesOptions} = useStockCartons("carton")    

    const [state , setState] = useState({
        date : new Date().toISOString(),
    })


    const units = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])



    const createProps = {
        setState,
        state,
        api : reports_api.home.cartons.create(),
        toggle,
        header : 'اضافة تقرير كراتين  يومي',
        setRefresh,
        customState : (data) => {
            return {
                ...data,
                home : id,
                date : _date(data.date).toZeroUTC()
            }
        }
    }

    

    const debouncedConsumption = useDebounce(state.consumption)

    const {price , fetchPrice  , loading : loadingPrice } = useCartonReportQuantityPrice({
        unit : state.unit , consumption : debouncedConsumption , sub_category : state.sub_category 
    })

    useEffect(() => {
        fetchPrice()
    } , [state.unit , debouncedConsumption , state.sub_category])
    


   
    return(
        <>
            <CreateForm {...createProps}>
                <InputDate     className="col-6"  name='date'    title='التاريخ'  defaultValue={_date(state.date).forInputDate()} />
                <InputSelect   className="col-6"  name='sub_category'   options={subCategoriesOptions}  title='نوع الكراتين'   />
                <InputNumber   className="col-4"  name={'consumption'}      title={'الكمية'} />
                <InputSelect   className="col-4"  name='unit'   options={units}  title={'وحدة القياس'}   />
               <InputNumber className="col-4" disabled   placeholder={""}  labelProps={{ content : loadingPrice ?            
                    <div className="flex justify-between items-center w-full">
                        <span  > قيمة الاستهلاك </span>
                        <i className="fa-solid fa-spinner fa-spin mx-2" />
                     </div> : ' قيمة الاستهلاك  ' }}   defaultValue={price}/>
                <InputTextArea name='notes'   placeholder=' ملاحظات على التقرير ... '  title='ملاحظات' />
            </CreateForm>
    </>
    )
}
