import FeedCrud from "./feed_crud";
import EggsCrud from "./eggs_crud";
import DeadCrud from "./dead_crud";

const TotalReportsCrud = {
    Feed : FeedCrud,
    Eggs : EggsCrud,
    Dead : DeadCrud
}

export default TotalReportsCrud
