import {paths} from "../config/configs.env";
import {EditForm} from "../config/imports";
import {useEffect} from "react";

export default function ImageViewer(props){
    const {item  , toggle}= props
    const {src , alt , className , header} = item

    useEffect(() => {
        document.querySelector(('.modal-container-content')).style.width = 'fit-content'
    })

    return(
        <>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3> { header ? '  صورة  ' + header : ' الصورة'}</h3>
            </header>
            <EditForm visible={false} toggle={toggle} >
                <img  src={ paths.uploads + src}  alt={alt} className={className ?? 'img-preview'} />
                <div className="row mt-3 justify-content-center">
                    <div className="col-3">
                        <button type="button" onClick={() => toggle()} className="d-flex justify-content-center close-btn w-100">
                            الغاء
                        </button>
                    </div>
                </div>
            </EditForm>
        </>
    )
}
