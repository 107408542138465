import Search from "../layout/search/Search";
import "../styles/tableView.styles.scss";
import Pagination from "../layout/pagination/Pagination";
import React, { useState } from "react";
import Helpers from "../helpers/helpers";
import { key } from "../helpers/id.helper";
const TableHead = ({ head }) => {
  return (
    <>
      <div className="data-table-head d-flex">
        {head.map((item, index) => {
          return (
            <div
              className={`data-table-body-item col-${
                typeof item.col === "number" ? item.col : "auto"
              }${item.className ? " " + item.className : ""}`}
              key={index}>
              {item.tilte !== undefined || item.title !== null
                ? item.title
                : item.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

const TableView = ({ data, body, updateRef, deleteRef }) => {
  return (
    <>
      <div className="data-table table-responsive">
        <TableHead head={body} />
        {data && data.map((item, idx) => (
            <div className="data-table-body d-flex  align-items-center" key={key()}>
              {body.map((tb, indx) => {
                if (tb.name !== "settings") {
                  return (
                    <div className={`data-table-body-item col-${ typeof tb.col === "number" ? tb.col : "auto" }${tb.className ? " " + tb.className : ""}`} key={key()}>
                      {tb.custom ? tb.custom(item) : item[tb.name] || idx + 1}
                    </div>
                  );
                } else {
                  return (
                    <div className={`data-table-body-item col-${typeof tb.col === "number" ? tb.col : "auto"   }${tb.className ? " " + tb.className : ""}`} key={key()}>
                      <div className="data-table-body-item-btns row">
                        <button className="btn  col-6 " data-id={item._id} type="submit" onClick={() => updateRef.current.toggle(item) }>
                          <img src="/edit.png" alt="" />
                        </button>
                        <button className="btn  col-6" data-id={item._id} type="button" onClick={() =>  deleteRef.current.toggle(item) }>
                         <img src="/trash.png" alt="" />
                        </button>
                      </div>
                    </div>
                  )}
              })}
            </div>
          ))}
      </div>
    </>
  );
};

// delete forms components

const DeleteFormButtons = ({ confirmBtn, handleDelete, setState, state }) => {
  return (
    <div className="form-group row justify-content-between align-items-center">
      <div className="col">
        <button className="close-btn" type="button" onClick={() => setState(!state)}>
          {confirmBtn ? "الغاء" : "اغلاق"}
        </button>
      </div>
      <div className="col d-flex justify-content-end">
        {confirmBtn && (
          <button className="add-btn" type="button" onClick={handleDelete}>
            نعم
          </button>
        )}
      </div>
    </div>
  );
};
const TableHeader = ({ component, buttons, api, setData, setLoading, paginate }) => {
  const [search, setSearch] = useState('');

  const searchHandler = (value) => Helpers.search(value, setSearch);
  return (
    <>
      <header
        data-component={component ? component : "component"}
        className="d-flex justify-content-between align-items-center row fhd search-and-buttons-container">
        <div className="isc col-12 col-sm-12 col-lg-4">
          <Search api={api} setData={setData} setLoading={setLoading} searchHandler={searchHandler} />
        </div>
        <div className="adc col-lg-8 col-12 col-sm-12 col-md-12 d-flex justify-content-end">
          {buttons &&
            buttons.map((btn, index) => {
              return (
                <button
                    key={index}
                    className="btn-color mx-1 text-white add-btn"
                    onClick={btn.onclick}
                    disabled={btn.disabled}>
                    <i className="fa fa-plus" aria-hidden="true"></i>
                    &nbsp; {btn.title}
                </button>
              );
            })}
        </div>
      </header>
      {paginate && <Pagination paginate={paginate} api={api} setData={setData} setLoading={setLoading} search={search} />}
    </>
  );
};


export { TableView, TableHead, DeleteFormButtons, TableHeader };
