import {projectsAPI} from "../../../../projects.helper"

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    SuperUpdate,
    SuperDelete,
    SuperCreate,
    addColumn,
    InputDate,
    InputText,
    InputNumber,
    extractTableData
} from '../../../../../../config/imports';


const DrugsConsumptionReports = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
         {
            date : new Date(),
            name : "748422",
            consumption_quantity : 212,
            consumption_value: 4515,
            cost_per_bird : 159,
        }, {
            date : new Date(),
            name : "9955652",
            consumption_quantity : 105,
            consumption_value: 12,
            cost_per_bird : 203,
        },
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "اضافة تقرير استهلاك ادوية",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];

    // const table = [
    //     {name: "idx", col: 1, title: "م"},
    //     {name: "date",custom : itm =>  new Date(itm.date).toLocaleDateString("ar-EG") , col: 2, title: "تاريخ الفاتورة"},
    //     {name: "name", col: 2, title: "الاسم"},
    //     {name: "consumption_quantity", col: 2, title: "كمية الاستهلاك"},
    //     {name: "consumption_value", col: 2, title: "قيمة الكمية"},
    //     {name: "cost_per_bird", col: 2, title: "التكلفة علي الطير"},
    //     {name: "settings", col: 1, title: ""},
    // ];


    const table = [
        addColumn("", "م", ""),
        {
            gen: { name: "date", title: "تاريخ الفاتورة", elm: InputDate },
            table: {
                col: 1,
                custom: (itm) => new Date(itm.date).toLocaleDateString("ar-EG"),
            },
            create: { props: {}, value: "" },
            update: {},
        },
        addColumn("name", "الاسم", InputText, 2),
        addColumn("consumption_quantity", "كمية الاستهلاك", InputNumber,2),
        addColumn("consumption_value", "قيمة الكمية", InputNumber,2),
        addColumn("cost_per_bird", "التكلفة علي الطير", InputNumber,2),
        addColumn("settings", "", ""),
    ];

    const main_title = "قائمة تقارير الاستهلاك";

    const views =
        [
            {
                ref: createRef,
                component: <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"اضافة تقرير استهلاك ادوية"}
                    table={table}
                />
            },
            {
                ref: updateRef,
                component: <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={main_title}
                    formHeader={"تعديل تقرير استهلاك ادوية"}
                    table={table}

                />
            },
            {
                ref: deleteRef,
                component: <SuperDelete
                    api={""}
                    setMainData={setMainData}
                    table={table}
                />
            },
        ]


    return (
        <>
            <PageMeta title={main_title}/>


            <QuickViewContainer views={views}/>

            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={"قائمة تقارير الاستهلاك"}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef} />
        </>
    );
};

export default DrugsConsumptionReports;
