import {useState} from "react";
import {homes_api} from "../../../../config/apis";
import {createFormHandler, setInputsToDefault, updateFormHandler} from "../../../../helpers/form.helper";
import PageMeta from "../../../../components/PageMeta";
import {EditForm, InputNumber, InputText, InputTextArea, Signboard} from "../../../../config/imports";

export default function EditHome(props){

    const {toggle , item , setMainData , department} = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({
        name : item.name,
        maximum_feed_capacity : item.maximum_feed_capacity,
        maximum_birds_capacity: item.maximum_birds_capacity,
        maximum_drug_capacity : item?.maximum_drug_capacity,
        maximum_other_capacity : item?.maximum_other_capacity,
        maximum_maintenance_capacity : item?.maximum_maintenance_capacity,
        maximum_carton_capacity : item?.maximum_carton_capacity,
        maximum_dish_capacity : item?.maximum_dish_capacity,
        department  : item.department || department,
    })

    // console.log(data)
    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: homes_api.updateHomeFromDepartment(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };


    return(<>
        <PageMeta title={"تعديل  بيانات البيت " + item.name} originTitle={'بيوت القسم '}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>{"  تعديل  بيانات البيت    " + item.name}</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>

        <EditForm
            visible={props.showFooter === undefined ? true : false}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}>
            <InputText name={'name'} className={"col-8"}  placeholder={'h1'}  title={'اسم البيت'} defaultValue={data.name}/>
            <InputNumber name={'maximum_birds_capacity'}  placeholder={'56'}  title={'عدد الطيور الأعظمي'} defaultValue={data.maximum_birds_capacity}/>
            <InputNumber name={'maximum_feed_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من العلف بالطن '} defaultValue={data.maximum_feed_capacity}/>
            <InputNumber name={'maximum_drug_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الدواء بالقطعه '} defaultValue={data.maximum_drug_capacity}/>
            <InputNumber name={'maximum_other_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الاخرى بالقطعه '} defaultValue={data.maximum_other_capacity}/>
            <InputNumber name={'maximum_maintenance_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الصيانة بالقطعه '} defaultValue={data.maximum_maintenance_capacity}/>
            <InputNumber name={'maximum_carton_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الكرتون بالقطعه '} defaultValue={data.maximum_carton_capacity}/>
            <InputNumber name={'maximum_dish_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الطبق بالقطعه '} defaultValue={data.maximum_dish_capacity}/> 
        </EditForm>
    </> )
}
