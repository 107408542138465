import React, { useRef } from 'react'
import { apis } from '../../../../config/apis';
import useFetch from '../../../../hooks/useFetch';
import PageMeta from '../../../../components/PageMeta';
import Signboard from '../../../../components/Signboard';
import QuickView from '../../../../components/QuickView';
import { TableHeader } from '../../../../components/TableView';
import { Table } from '../../../../layout/table';
import Helpers from '../../../../helpers/helpers';
import LandsCrud from './crud';

export default function LandsIndex() {


  const createRef = useRef();
  const updateRef = useRef();
  const deleteRef = useRef();


  const api = apis.assets.asset("land").paginate()

  const { data  , paginate , setters , alert } = useFetch(api);
  

  const buttons = [
    {
      title: "اضافة ارض",
      onclick: () => createRef?.current?.toggle(),
    },
  ];


  const table = [
    {size : 1 , name : "#" , text : "#"  },
    {size : 3 , name : "name" , text :  'أسم الأرض' },
    {size : 3 , name : "characteristic" , text :  'صفة الأرض' },
    {size : 3 , name : "location" , text :  'موقعها',  },
    {size : 2 , name : "length" , text :  'طول الأرض' },
    {size : 2 , name : 'width' , text :  'عرض الأرض' },
    {size : 2 , name : 'value' , text :  'قيمة الأرض',},
    {size : 3 , name : 'account_name' , text :  'أسم الحساب' },
    {size : 3 , name : 'account_symbol' , text :  'رمز الحساب' },
    {size : 2 , name : '' , text : '' , }
  ]


  return (
    <div className=''>
      <PageMeta title="الارض" />
      <Signboard {...alert}/>

      <QuickView ref={createRef} ><LandsCrud.Create setMainData={setters.data}/></QuickView>
      <QuickView ref={updateRef} ><LandsCrud.Update setMainData={setters.data}/></QuickView>
      <QuickView ref={deleteRef} ><LandsCrud.Delete setMainData={setters.data}/></QuickView>
      <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
      <div className="py-5"></div>
      <Table table={table} data={data}  updateRef={updateRef} deleteRef={deleteRef}  className="mt-3"/>


    </div>
  )
}
