import React from 'react'
import { paths } from '../../../config/configs.env'

export default function PreviewReportImage(props) {


  const {item : file , toggle} = props


  return (
    <>
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto ">
            <h3> عرض  الصورة</h3>
        </header>
        <div  className=' p-[8px]  ' >
                <img src={ paths.uploads + '/reports/' +  file.filename} alt="img" className='w-full h-full object-cover rounded-md' />
            <button onClick={toggle} className="add-btn mt-4"> الغاء </button>
        </div>
    </>
  )
}
