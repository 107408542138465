import {
    PageMeta,
    createFormHandler,
    EditForm,
    Signboard,
    InputText,
    InputNumber,
    InputSelect
} from "../../../../config/imports";
import {useState} from "react";
import {apis} from "../../../../config/apis";

export default function CreateHatcheries (props){

    const {setMainData, toggle} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({})

    let signboard = {
        error : error  ,
        success,
        loading : loading ,
    }


    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: apis.levels.breeding.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.url})

        await createFormHandler(create_props);
    };


    console.log({data})
    return(
        <>
            <PageMeta title={'إضافة فقاسات او حاضنات'} originTitle={'الفقاسات والحضانات'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>إضافة فقاسات او حاضنات</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >

                <InputSelect name={'name'} title={'الاسم'} placeholder={'حاضنة'} value={data.name} options={[
                    { value : 'فقاسه' , text : 'فقاسه'},
                    { value : 'حاضنة' , text : 'حاضنة'},
                ]}/>

                <InputText name={'brand'} title={'الماركة'} placeholder={'Petersime'} value={data.brand}/>
                <InputText name={'area'} title={'المساحة'} placeholder={'4*4'} value={data.area}/>
                <InputText name={'location'} title={'موقعه '} placeholder={'غرفة الحاضنات'} value={data.location}/>

                <InputSelect name={'type'} title={'النوع'} placeholder={'type'} value={data.type}  options={[
                    { value : 'اطباق' , text : 'اطباق'},
                    { value : 'صندوق' , text : 'صندوق'},
                ]} />

                <InputNumber name={'trolley_count'} title={'عدد التروليات'} placeholder={'87454'} value={data.trolley_count}/>


                <InputNumber name={'dishes_per_trolley'} title={'عدد الأطباق بالتروليات'} placeholder={'453'} value={data.dishes_per_trolley}/>
                <InputNumber name={'eggs_per_dish'} title={'عدد البيض بالطبق'} placeholder={'30'} value={data.eggs_per_dish}/>
                <InputNumber name={'eggs_per_trolley'} title={'عدد البيض بالترولي'} placeholder={'63456'} value={data.eggs_per_trolley}/>
                <InputNumber className={'col-6'} name={'total_dishes'} title={'مجموع الأطباق'} placeholder={'450'} defaultValue={data.total_dishes}/>
                <InputNumber className={'col-6'} name={'total_eggs'} title={'مجموع البيض'} placeholder={'45045'} defaultValue={data.total_eggs}/>

            </EditForm>


        </>
    )
}
