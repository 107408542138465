import PageMeta from "../../../components/PageMeta";
import {TableHeader} from "../../../components/TableView";
import {_date, Grid, Icons, QuickView, Signboard, Table, useFetch} from "../../../config/imports";
import {invoices_api, packaging_api} from "../../../config/apis";
import {useRef, useState} from "react";
import {Link} from "react-router-dom";
import "../../../styles/billing.styles.scss"
import Create from "./Create";
import EditUnit from "./EditUnit";
import DeleteUnit from "./DeleteUnit";

export default function EggStorageUnitsIndex(){
    const deleteRef = useRef(),
          createRef = useRef(),
          updateRef = useRef()


    const  { data : units, error , isLoading  , setData }  = useFetch(packaging_api.all)
    const signboard = {
        error : error,
        loading : isLoading
    }
    const sizes = {
        serial : 1,
        name : 3,
        dishes_count : 3,
        egg_count : 4,
        total_egg_count : 4,
        dishes_type : 3,
        packaging_type : 2,
        settings : 2,
        details : 2

    }


    const header = {
        serial: <Grid.Span size={sizes.serial}></Grid.Span>,
        name: <Grid.Span size={sizes.name}>  الاسم  </Grid.Span>,
        dishes_count: <Grid.Span size={sizes.dishes_count }> عدد الاطباق </Grid.Span>,
        egg_count:<Grid.Span size={sizes.egg_count}>   عدد البيض في كل طبق  </Grid.Span>,
        total_egg_count: <Grid.Span size={sizes.total_egg_count}> عدد البيض في كل كرتونه </Grid.Span>,
        packaging_type:<Grid.Span size={sizes.packaging_type}>  نوع الكرتونة  </Grid.Span>,
        dishes_type:<Grid.Span size={sizes.dishes_type}>  نوع الطبق  </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
        details: <Grid.Span size={sizes.details}></Grid.Span>
    }



    return (
        <div className={'egg-storage-unit'}>


            <PageMeta title={'قائمة فواتير البيع'} />
            <TableHeader  buttons={ [
                {
                    title: "اضافة وحدة تخزين جديده",
                    onclick: () => createRef.current.toggle()
                }
            ]}/>
            <Signboard {...signboard}  />


            <QuickView ref={createRef}><Create setMainData={setData} /></QuickView>
            <QuickView ref={updateRef}><EditUnit setMainData={setData} /></QuickView>
            <QuickView ref={deleteRef}><DeleteUnit setMainData={setData} /></QuickView>



            <Table.Header header={header}  className={'sales-inv'} />
            {
                units && units.length > 0 && units.map((unit , index) => (
                    <Grid.FlexResponsive  key={unit._id} className={'align-items-center data-row'}>
                        <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                        <Grid.Span size={sizes.name}>{unit.name}</Grid.Span>
                        <Grid.Span size={sizes.dishes_count}>{ unit.dishes_count }</Grid.Span>
                        <Grid.Span size={sizes.egg_count}>{unit.egg_count}</Grid.Span>
                        <Grid.Span size={sizes.total_egg_count}>{unit.total_egg_count}</Grid.Span>
                        <Grid.Span size={sizes.packaging_type}>{unit.packaging_type}</Grid.Span>
                        <Grid.Span size={sizes.dishes_type}>{unit.dishes_type}</Grid.Span>
                        <Grid.Span size={sizes.settings} className='settings-buttons'>
                            <button  onClick={ () => deleteRef.current.toggle(unit)} > <Icons.Delete />  </button>
                            <button onClick={ () => updateRef.current.toggle(unit)} > <Icons.Edit />  </button>
                        </Grid.Span>
                        <Grid.Span size={sizes.details}>
                            <Link to={`/egg-storage-units/${unit._id}`} > تفاصيل <Icons.Arrow />  </Link>
                        </Grid.Span>

                    </Grid.FlexResponsive>
                ))
            }


        </div>
    )
}
