import { Route } from "react-router-dom"
import RouterHelpers from "../../../helpers/router.helper"
import Weight from "./Weight"


const origin_weights_routes = {
    router : (protect)=>{
        return  <Route path="weights" element={protect(<Weight />)} />
    }


}


export default origin_weights_routes