import { Route } from "react-router-dom"
import assets_data_routes from "./asset_data/assets_data.routes"
import bird_breed_routes from "./bird_breed/bird_breed.routes"
import Origin from "./index.origin"
import item_cards_routes from "./items_cards/item_cards.routes"
import origin_cards_routes from "./origin_cards/origin_cards.routes"
import origin_weights_routes from "./weights/weights.routes"
import egg_storage_units_routes from "./egg_storage_units/egg_storage_units_routes.routes"
import egg_dishes_routes from "./egg_dishes/egg_dishes.routes"

const origin_routes = {
    router : (protect)=>{

   
        return <Route path="origin" element={protect(<Origin />)} >
                    { origin_cards_routes.router(protect)   }
                    { item_cards_routes.router(protect)     }
                    { bird_breed_routes.router(protect)     }
                    { origin_weights_routes.router(protect) }
                    { assets_data_routes.router(protect)    }
                    { egg_storage_units_routes.router(protect) }
                    { egg_dishes_routes.router(protect) }
            </Route>

        
      

       
    }
}

export default origin_routes