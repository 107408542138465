import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom'; 

function useSetUrlQuery(basePath) {
  const navigate = useNavigate();

  const setUrlQuery = useCallback(
    (queryString) => {
      
      const newUrl = `${basePath}&${queryString}`;

      
      navigate(newUrl, { replace: true });
    },
    [navigate, basePath]
  );

  return setUrlQuery;
}

export default useSetUrlQuery;