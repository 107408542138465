import { Route } from "react-router-dom"
import Asset from "./Asset"



const assets_data_routes = {
    router : (protect)=>{
        return <Route path="asset-data" element={protect(<Asset />)} />
    }


}


export default assets_data_routes