import { useRef } from "react";
import { useParams } from "react-router-dom";
import { _date, PageMeta, QuickView, Signboard, TableHeader, useFetch } from "../../../../config/imports";
import ReportsCrud from "../reports_crud";
import { reports_api } from "../../../../config/apis";
import Helpers from "../../../../helpers/helpers";
import { TableHeader as ReportHeader } from "../../../../layout/table";
import ReportAccordion from "../components/ReportAccordion";
import "../reports.styles.scss";
import useStock from "../../../stocks/useStock";
import ReportMovementDetails from "../components/ReportMovementDetails";
import { categories } from "../reports.shared";
export default function DrugsDailyReportForProductionHome(props){
    const  createRef = useRef()

    const {id} = useParams();

    const api = reports_api.home.drugs.read(id)

    const  { data  , setData ,  alert , paginate , setters , setRefresh}  = useFetch(api);


 
 
    
    const table = [
        // {name : "#"  , text : "" , size : 1  },
        {size : 3 , name : "date" , text : "التاريخ"  , custom : d => _date(d.date).dmy() },
        {size : 4 , name : "last_updated_by" , text : " تعديل بواسطة"  },
        {size : 4 , name : "created_by" , text : " اضافة بواسطة"  },
        {size : 4 , name : "total_quantity" , text : "الكمية" , custom : d => Helpers.Number.commaFormat( d?.total_quantity ) },
        {size : 3 , name : "ar_unit" , text : "وحدة القياس"  },
        {size : 3 , name : "total_price" , text : "قمية الاستهلاك" , custom : d => Helpers.Number.commaFormat( d?.total_price ) },
        {size : 4 , name : "notes" , text : " ملاحظات"  },
    ]



    

    const buttons =  [
        {
            title: " اضافة تقرير ادوية  جديد",
            onclick: () => createRef.current.toggle()
        }
    ]

    return(
        <div className="report-container">
            <PageMeta title={'تقارير الادوية اليوميه'} />
            <Signboard {...alert}/>
            <QuickView ref={createRef}><ReportsCrud.Drug.Create   setRefresh={setRefresh} /></QuickView>
            <TableHeader  buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
            <ReportHeader table={table}  />
            <ReportAccordion 
                    MovementDetails={ReportMovementDetails}
                    data={data} table={table}   categories={categories} />
        </div>
    )
}
