import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";
import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";
import AddProjectBalance from "./AddProjectBalance";
import UpdateProjectBalance from "./UpdateProjectBalance";
import DeleteBalance from "./DeleteBalance";

const ProjectsBalances = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);

    const title = "قائمة ارصدة المشاريع"

    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "اضافة ارصدة مشروع",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "name", col: 1, title:"الاسم"},
        {name: "type", col: 2, title: "النوع"},
        {custom : itm => itm.operator.name , col: 2, title: "المورد"},
        {name: "unitprice", col: 2, title: "كمية الاستهلاك"},
        {name: "total", col: 1, title: "قيمة الكمية"},
        {name: "measuringUnit", col: 2, title: "معلومات اضافية"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={title}/>

            <QuickView ref={createRef}>
                <AddProjectBalance setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateProjectBalance setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteBalance setMainData={setMainData}/>
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={title}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default ProjectsBalances;
