import { create  } from "zustand"
import { refresh } from "../../../helpers/id.helper"


const useStockStore = create( (set) => ({
    refresh : "",
    transfers : [],
    setRefresh : ()=> set( state => ({...state , refresh : refresh()})),
    setTransfers : (data)=> set(state => ({...state , transfers : data})),
}))




export default useStockStore