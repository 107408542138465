import { Signboard, useFetch } from "../../config/imports";
import { apis } from "../../config/apis";
import OrderMonthDays from "./month_orders/OrderMonthDays";
import FeedOrderHome from "./month_orders/FeedOrderHome";
import MonthOrderHomeTotals from "./month_orders/MonthOrderHomeTotals";
import PageMeta from "../../components/PageMeta";
import { useEffect } from "react";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, monthlyRequestsBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";

export default function MonthlyConsumption(props){

    const {data , isLoading , error } = useFetch( apis.orders.month() )

    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            monthlyRequestsBreadCrumb,
        ])
    } , [])
    
    return(
        <>

                 {/* <Signboard loading={isLoading} error={error} /> */}
                 <div className={'d-flex month-consumption bg-white p-3 r-16 '}>
                 <PageMeta title={' طلبات الاعلف الشهريه'} />
                 {
                     data && data?.homes?.length > 0 &&  <div className="span-3 p-0">
                                    <div className="month-days">
                                        <OrderMonthDays days={ data?.homes[0]} />
                                    </div>
                                </div>
                 }
                 {
                     data && data.homes?.length > 0 && data?.homes?.map( (home , index) =>{
                        if(!home) return 
                        return <FeedOrderHome key={home.home_id + index} home={home} />
                     })
                 }
                 {
                     data && data?.day_sums?.length > 0 && <MonthOrderHomeTotals  totals={data?.day_sums}/>
                 }
             </div>

        </>
    )
}
