import { Icons, useFetch, PageMeta, QuickView } from "../../../config/imports";
import { packaging_api } from "../../../config/apis";
import { useRef } from "react";

import "../../../styles/billing.styles.scss";
import EditDetails from "./EditDetails";
import DeleteWeightDetails from "./DeleteWeightDetails";
import { NavLink, useParams } from "react-router-dom";
import Table from "../../../layout/table/Table";

export default function CartoonDetails(props){

    const deleteRef = useRef(),
        updateRef = useRef()

    const {id} = useParams();

    const  { data : details  , Alert}  = useFetch(packaging_api.carton(id))

    const table = [
        { name : "#" , size : 1  , text : "#" },
        { name : "boxes_count" , size :  2 , text : "عدد الاطباق" },
        { name : "eggs_count" , size : 3  , text : "عدد البيض" },
        { name : "total_cartoon" , size :  3 , text : "اجمالى الكراتين" },
        { name : "weight" , size :  4 , text : "وزن الكارتونه" },
        { name : "unit_size" , size :  3 , text : "وزن الوحدة" },
        { name : "production_percentage" , size : 3  , text : "نسبة الانتاج" },
        { name : "cartoon_price" , size :   3, text : "سعر الكرتونة" },
        { name : "settings" , size : 2  , text : "" },
    ]

    return<div className='storage-unit-details'>
        <QuickView ref={updateRef}><EditDetails /></QuickView>
        <QuickView ref={deleteRef}><DeleteWeightDetails /></QuickView>
        <PageMeta title={'تفاصيل الكرتونه'} />
        <Alert />
        <NavLink to={"/warehouses/origin/items-cards/cartons"} > <Icons.Arrows.Right /><span className="mx-2"> عودة لانشاء الكراتين </span></NavLink>
        <div className="mt-3"></div>
        <Table table={table} data={details} />
    </div>
}
