import {InputText} from "../../../../config/imports";

const toolsAndEquipmentsInputs = [
    { name : 'name', title :  'اسم الاله', input : InputText},
    { name : 'characteristic', title :  'صفة الاله', input : InputText},
    { name : 'production_per_day', title :  'الطاقة الإنتاجية في اليوم', input : InputText},
    { name : 'manufacturer_country', title :  'الطاقة الإنتاجية في اليوم', input : InputText},
    { name : 'supplier', title :  'المورد للاله', input : InputText},
    { name : 'device_value', title :  'قيمة المعدة', input : InputText},
    { name : 'inv_number', title :  'رقم الفاتورة', input : InputText , size : 3},
    { name : 'account_name', title :  'أسم الحساب', input : InputText , size : 3},
    { name : 'account_symbol', title :  'رمز الحساب', input : InputText , size : 3},
    { name : 'location', title :  'موقعها', input : InputText , size : 3},
]
export default toolsAndEquipmentsInputs
