
import salariesAPI from "./salaries.routes";
import {Link,} from "react-router-dom";
import React, { useEffect } from "react";
import { Icons, QuickView, useRef , useFetch , TableHeader, PageMeta, Signboard } from "../../config/imports";
import CreateGroupOvertime from "./CreateGroupOvertime";
import CreateEmployee from "./CreateEmployee";
import {apis, employees_api} from "../../config/apis";
import Table from "../../layout/table/Table"
import Pagination from "../../layout/pagination/Pagination";
import Helpers from "../../helpers/helpers";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, salariesBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";

const SalariesIndex = () => {

    const [loading , setLoading] = React.useState(false)

    

    const createGroupOvertimeRef = useRef()
    const createEmployeeRef = useRef()
    const api = apis.employees_details.read()


    const salaries_button = [
        {
            title : "عمل اضافى جماعى",
            onclick : () => createGroupOvertimeRef.current?.toggle()
        },
        {
            title : "إضافة موظف",
            onclick : () => createEmployeeRef.current?.toggle()
        }
    ]

    const {data  , isLoading: fetchEmps, error : fetchEmpsErr,  setData , paginate} = useFetch(api );

    const {data : options , error : fetchNamesErr , isLoading : empNamesLoading} = useFetch(apis.employees_details.names());

    const signboard =  {
        error : fetchNamesErr || fetchEmpsErr,
        loading : empNamesLoading || fetchEmps || loading
    }

    const table = [
        { name : "#" , size : '1' , text : "#" },
        { name : "ar_name" , size : '3' , text : "الاسم" },
        { name : "job_title" , size : '3' , text : "المهنة" },
        { name : "basic_salary" , size : '3' , text : "اساسي" },
        { name : "project" , size : '3' , text : "المشروع"  , custom : item => item?.project?.name ?? '' },
        { name : "department" , size : '3' , text : "القسم" , custom : item => item?.department?.name ?? '' },
        { name : "allowances" , size : '3' , text : "البدلات" },
        { name : "total_salary" , size : '3' , text : "المستحق" },
        { name : "settings" , size : '1' , text : "" , custom : item => <Link to={'/salaries/'+item._id} className="flex items-center"><span className="mx-2"> تفاصيل </span> <Icons.Arrow /></Link>}
    ]

    useEffect(()=>{
            setBreadCrumbsStore([
                homeBreadCrumb,
                salariesBreadCrumb,
            ])
    } , [])

    return (
        <div className="salaries-container bg-white r-16 p-3 my-5 base-card">
            <Signboard {...signboard} />
            <PageMeta title="الرواتب / الاجور" />
            <QuickView  ref={createGroupOvertimeRef}  ><CreateGroupOvertime options={options}/></QuickView>
            <QuickView  ref={createEmployeeRef} sized={true} ><CreateEmployee  setMainData={setData}  /></QuickView>
            <TableHeader paginate={paginate}  buttons={salaries_button} api={api} setData={setData} setLoading={setLoading}   />

            <Table table={table} data={data} />

        </div>
    );
}

export default SalariesIndex;
