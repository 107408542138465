import { create } from "zustand";




export const setStoreBreadCrumb = (data) => {
    useBreadCrumbStore.getState().setCrumbs(data)
}


export const setBreadCrumbsStore = (data) => {
    useBreadCrumbStore.getState().setCrumbs(data)
}



export const useBreadCrumbStore = create((set) => ({
    crumbs : [],
    setCrumbs : (data) => set((state) => ({ ...state , crumbs : data })),
}))