import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete"
const Crud =  {
    Create,
    Update ,
    Delete

}
export default Crud;
