import "../../../styles/base-card.css";
import LinkList from "../../../components/LinkList";

import useNavigator from "../../../hooks/useNavigator";

const ItemCard = () => {
  const ITEMS_CARDS_LINKS = [
    {
      name: "الاعلاف",
      path: "feed",
    },
    {
      name: "الادوية",
      path: "drug",
    },
    // {
    //   name: "التعبئة والتغليف",
    //   path: "packaging",
    // },
    {
      name: "الصيانة",
      path: "maintenance",
    },
    {
      name: "الاخرى",
      path: "others",
    },
    {
      name: "الكراتين",
      path: "cartons",
    },
    {
      name: "الاطباق",
      path: "dishes",
    },
  ];

  useNavigator("items-cards", "feed");

  return (
    <div className="base-card">
      <div className="ilc mt-2 bg-white px-3 py-3 r-16">
        <div className="icbc">
          <div className="base-card-links-container">
            {<LinkList data={ITEMS_CARDS_LINKS} underline={true} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
