const requestsBaseDetails = {
    origin: "requests",
    navigateTo: "base",
    title: "الطلبات",
    links: [
        {
            to: "base",
            name: "الطلبات",
        },
        {
            to: "month-orders",
            name: "الطلبات الشهريه",
        },
    ],
}
export default function RequestsBase(props){



    return(
        <>    </>
    )
}

export {
    requestsBaseDetails
}
