import React, {useState} from "react";
import {deleteFormHandler, Grid, Signboard, Table} from "../../../config/imports";
import PageMeta from "../../../components/PageMeta";
import { homes_api, packaging_api } from "../../../config/apis";

export default function DeleteUnit(props){
    const {toggle , item , setMainData , setRefresh} = props
console.log({item})
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);


    const sizes = {
        name : 5,
        dishes_count : 5,
        egg_count : 5,
        total_egg_count : 5,
    }


    const header = {
        name:<Grid.Span size={sizes.name}>  الاسم  </Grid.Span>,
        dishes_count: <Grid.Span size={sizes.dishes_count }> عدد الاطباق </Grid.Span>,
        egg_count:<Grid.Span size={sizes.egg_count}>   عدد البيض في كل طبق  </Grid.Span>,
        total_egg_count: <Grid.Span size={sizes.total_egg_count}> عدد البيض في كل كرتونه </Grid.Span>,
    }

    const handleDelete = async () => {
        const delete_props = {
            url: packaging_api.create,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setRefresh,
            item,
        };

        await deleteFormHandler(delete_props);
    };


    return<div className='storage-unit-details'>
        <PageMeta title={'  حذف الوزن ' + item.size} originTitle={'تفاصيل الاوزان'} />

        <header className="w-fit m-auto px-4 text-center border-bottm-line ">
            <h3>حذف وحدة التخزين  {item.name} ؟</h3>
        </header>
        <Signboard error={error} success={success} loading={loading}/>


        <Table.Header header={header}  className={'delete-header'}  />


        <Grid.FlexResponsive   className={'align-items-center data-row'}>
            <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
            <Grid.Span size={sizes.dishes_count}>{ item.dishes_count }</Grid.Span>
            <Grid.Span size={sizes.egg_count}>{item.egg_count}</Grid.Span>
            <Grid.Span size={sizes.total_egg_count}>{item.total_egg_count}</Grid.Span>
        </Grid.FlexResponsive>



        <div className="col-12 col-md-12 mt-4">
            <div className="form-group row justify-content-between align-items-center">
                <div className="col">
                    <button className="close-btn" onClick={() => toggle()}>
                        {confirmBtn ? "الغاء" : "اغلاق"}
                    </button>
                </div>
                <div className="col d-flex justify-content-end">
                    {confirmBtn && (
                        <button className="add-btn" onClick={handleDelete}>
                            نعم
                        </button>
                    )}
                </div>
            </div>
        </div>

    </div>
}
