import {TableHeader, PageMeta, Grid, _date, useFetch, Signboard, Icons} from "../../config/imports";
import {Link} from "react-router-dom";
import "../../styles/orders.styles.scss"
import {apis, request_api} from "../../config/apis";
import {Table} from "../../layout/table";
import { useEffect } from "react";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, requestsBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";
// const { Flex , Span  } = Grid

export default function RequestIndex() {

    const api = apis.orders.paginate()
    const { data , isLoading, error : requestError , setters , paginate} = useFetch(api)

    const signboard = { error : requestError, loading : isLoading }

    // console.log({data})

    const table = [
        { name : '#' , size : 1  , text : '#'},
        { name : 'name' , size : 3  , text : 'النوع'},
        { name : 'project' , size : 3  , text : 'المشروع' ,  custom : d => d?.project?.name ?? d?.project},
        { name : 'department' , size : 4  , text : 'القسم' , custom : d => d?.department?.name ?? d?.department},
        { name : 'created_by' , size : 4  , text : 'اسم مرسل الطلب' , custom : d => d?.created_by?.username ?? d?.created_by},
        { name : 'status' , size : 3  , text : 'الحالة' , custom : d =>{
                switch(d.status){
                    case 'completed': return <span className="status-done">مكتمل</span> ;
                    case 'approved': return <span className="status-done">مكتمل</span> ;
                    case 'pending': return   <span className="status-pending">قيد المراجعه</span> ;
                    case 'rejected': return  <span className="status-rejected">مرفوض</span> ;
                    case 'under_purchase': return <span className="status-pending">قيد الشراء</span> ;
                    case 'current': return <span className="status-waiting">  بإنتظار الاجراء </span> ;
                    default: return <span className="text-secondary">{d?.ar_status}</span>
                }
            }},
        { name : 'createdAt' , size : 3  , text : 'تاريخ الطلب' , custom : d => _date(d.createdAt).dmy()},
        { name : '' , size : 1  , text : '' , custom : (d)=>  <Link to={'/request-details/' + d._id } className="!flex items-center" > تفاصيل <Icons.SmallArrow />  </Link>  },
    ]


    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            requestsBreadCrumb,
        ])
    } , [])




    return (
        <div className='request base-card bg-white r-16 mt-5 p-3'>
            <TableHeader api={api} setData={setters.data} setLoading={setters.loading} paginate={paginate} />
            <PageMeta title={'الطلبات'}/>
            <Signboard {...signboard}/>
            <Table table={table} data={data} />
        </div>
    );
}
