import { accio } from "./axios.get";
import  putData  from "./axios.put";
import postData from "./axios.post"
import deleteData from "./axios.delete"
import axiosAuthParams from "./axios.auth.params";


const Axios = {
    get : accio,   
    put : putData,
    post : postData,
    delete : deleteData,
    file : {
        put : async (url , data) => {
            return Axios.put(url , data , {
                headers : {
                    ...axiosAuthParams().headers,
                    'Content-Type': 'multipart/form-data',
                }
            })
        },
        post : async (url , data) => {
           return  Axios.post(url , data , {
                header : {
                    ...axiosAuthParams().headers,
                    'Content-Type': 'multipart/form-data',
                }
            })
        }
    }
    
}


export default Axios