import React, { useEffect, useState } from 'react'
import Axios from '../../../helpers/axios';

export default function InputImage(props) {
//   const [files, setFiles] = useState([]);
//   const [uploaded, setUploaded] = useState([]);
  const [loading, setLoading] = useState(false);
  const [src, setSrc] = useState({
    src : null,
    name : ""
  });



  useEffect(() => {
    if (props.defaultValue) {
      setSrc({
        src : props.defaultValue,
        name : src.name ?? props.name
      })
      props.setState(pre=>{
        return {...pre , [props.name] : props.defaultValue}
      })

    }
  }, [props.defaultValue])

useEffect(() => {

    let e = {
        target : {
            name : props.name,
            value : src?.src ?? "",
            type : 'image',
        }
    }

    props.handleInputChange(e)
} , [src])



const uploadFile = async (file) =>{
  setLoading(true)
  let _result;
  try {
    const response = await Axios.file.post(props.folder , {file : file} )
    // setLoading(false)
    if(!response.data || response.statusCode !== 201)
      return _result = ({ data : null , error : response.error})
    else 
    return _result = ({ data : response.data , error : null})

  } catch (error) {
    console.log({
      error : error.message,
      message : "error while uploading files"
    })
    return _result = ({ data : null , error : error.message})
  }
}



const fileChangeHandler = async (e) =>{
    setLoading(true)
    const file = e.target.files[0]
    const {error , data} = await uploadFile(file)
    setLoading(false)
    if(error) return setSrc(null)
    if(data && data.file.mimetype.split('/')[0] !== 'image') return setSrc({
        src : "/src/assets/images/icos/file.png",
        name : data.file.filename
    })
    setSrc({
        src : data.url,
        name : data.file.filename
    })
}









  return (
    <div className={props.className ? props.className  + ' input-image form-input  form-group ' : "col-6 col-md-6 form-group input-image form-input"}>
                <div className="image">
                    <input type="file" name="" id="file-image" onChange={fileChangeHandler} />
                    <label htmlFor="file-image">➕</label>
                    {src?.src && <div className='image-preview'>
                        <img src={src.src} alt={src.name} title={src.name} />
                        <p dir='ltr'>{src.name}</p>
                    </div> }
                    {src?.src && <button className='clear' onClick={() => setSrc(null)}>❌</button>}
                </div>
    </div>
  )
}
