import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import useWarehouseAccordionItemData from './hooks/useEggsWarehouseAccrodionItemData'
import useEggsWarehouseStore from './store/eggs_warehouse.store'
import EggsWarehouseSelectors, { DepartmentsSelectors, ProjectsSelectors } from './EggsWarehouseSelectors'
import { apis } from '../../config/apis'
import EggsWarehouseSelector from './EggsWarehouseSelector'
import Accordion from '../ui/accordion/Accordion'

function EggsWarehouseAccordionItem(props) {
   const {api} = props
   const { data : componentData , Alert } =  useWarehouseAccordionItemData(api)

   const counts = useEggsWarehouseStore(state => state.formatted)


   const AccordionHeader =  <div className='bg-[#fcaa30] text-white px-2  w-full h-full flex items-center'> {props.title}  ({counts[props.type].length})  </div>

   const data = useMemo(() => {

    return  props.callback ? props.callback(componentData) : componentData

   }, [componentData , props.callback])


    return (
      <Accordion isOpen={props.isOpen || false }   header={AccordionHeader }>
          <Alert />
          <div className="ml-4"> 
              <EggsWarehouseSelectors data={data && data}   id={props.id} name={props.name}  type={props.type}  />
          </div>
      </Accordion>
    )
}

EggsWarehouseAccordionItem.propTypes = {
    api : PropTypes.string.isRequired,
    isOpen : PropTypes.bool,
    title : PropTypes.string.isRequired,
    type : PropTypes.string,
    callback : PropTypes.func,
    name : PropTypes.string,
    id : PropTypes.string
}

export default EggsWarehouseAccordionItem




 const ProjectsAccordionItem = props => <EggsWarehouseAccordionItem {...props} api={apis.projects.names} type="projects_ids"  title='المشاريع'    />
 const DepartmentsAccordionItem = props => <EggsWarehouseAccordionItem {...props} api={apis.departments.all()} type="departments_ids" name="name_with_projects" id="_id" title='الاقســام'    />
 const HomesAccordionItem = props => <EggsWarehouseAccordionItem {...props} api={apis.homes.homes_details()+ "?place_type=home"} type="homes_ids" name="home" id="home_id" title='البيوت'    />


 ProjectsAccordionItem.propTypes  = EggsWarehouseAccordionItem.propTypes
 DepartmentsAccordionItem.propTypes = EggsWarehouseAccordionItem.propTypes
 HomesAccordionItem.propTypes = EggsWarehouseAccordionItem.propTypes


 export { ProjectsAccordionItem , DepartmentsAccordionItem , HomesAccordionItem }