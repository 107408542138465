const _date = (date) => {

    // if(!date || date.toString() === 'Invalid Date') return '-';

    

    const $date = new Date(date) ;

    // console.log($date)
    // if(!$date || $date.toString() === 'Invalid Date') return '-';


    const day = $date.getDate().toString().padStart(2, '0');
    const month = ($date.getMonth() + 1).toString().padStart(2, '0');
    const year = $date.getFullYear();

    const hour = $date.getHours().toString().padStart(2, '0');
    const minute = $date.getMinutes().toString().padStart(2,0);

    const arabicMonths = ['يناير', 'فبراير', 'مارس', 'ابريل', 'مايو', 'يونيو', 'يوليو', 'اغسطس', 'سبتمبر', 'اكتوبر', 'نوفمبر', 'ديسمبر'];

    return {
        format : (format = 'yyyy-mm-dd') => {
            let formatted_date = ''
            switch (format && format.toLowerCase()){
                case 'mm-dd-yyyy' : formatted_date = `${month}-${day}-${year}`
                    break;
                case 'yyyy-mm-dd' : formatted_date = `${year}-${month}-${day}`
                    break;
                default :  formatted_date = `${year}-${month}-${day}`
                    break
            }
            return formatted_date
        },

        forInputDate : () => {
            return _date($date).format('yyyy-mm-dd')
        },
        dmyt : () => {
            return _date($date).dmy() + ' -  ( ' + _date($date).time() + ' ) '
        },
        dmy : () => {
            return _date($date).format('dd-mm-yyyy')
        },

        simple : (type = 'datetime') =>{
            switch (type && type.toLowerCase()) {
                case 'date' : return $date.toLocaleDateString()
                case 'time' : return `${hour}:${minute}`
                case 'datetime' : return $date.toLocaleDateString() + ' - ' + `${hour}:${minute}`
                default :  return $date.toLocaleDateString() + ' - ' + `${hour}:${minute}`
            }
        },
        dateAndTime:()=> {
            return _date($date).dmy() + " - ( " +   _date($date).time()   + " ) "
        },
        date:()=> _date($date).simple('date'),
        time:()=> _date($date).simple('time'),
        local : () => {
            return `${day} ${arabicMonths[month - 1]} ${year}`
        },
        toZeroUTC : () => {
            return  toISODateStringWithMidnightUTC($date)
        },
        // toISODateStringWithMidnightUTC : () => {
        //     return _date($date).toISODateStringWithMidnightUTC()
        // },
       
    }

}


export {
    _date
}


function toISODateStringWithMidnightUTC(date) {
    const dateCopy = new Date(date.getTime());
    dateCopy.setHours(0, 0, 0, 0);
    const utcDate = new Date(dateCopy.getTime() - (dateCopy.getTimezoneOffset() * 60000));
    return utcDate.toISOString();
  }