import { useState } from "react";
import {
  constructCreateStateDataFromTable,
  destructureTable,
} from "../config/imports";

const useCreateStates = (props) => {
  const { body } = destructureTable(props);

  const createStateData = constructCreateStateDataFromTable(body);

  const formattedObject = createStateData.reduce((acc, cur) => {
    acc[cur.name] = cur.value;
    return acc;
  }, {});

  const [data, setData] = useState({
    ...formattedObject,
  });

  return { data, setData };
};

export default useCreateStates;
