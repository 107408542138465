import {useEffect, useMemo, useState} from "react";
import {
    _date,Grid,
    PageMeta,
    Signboard, useFetch
} from "../../../../../config/imports";
import {useParams} from "react-router-dom";
import { key } from "../../../../../helpers/id.helper";
import Helpers from "../../../../../helpers/helpers";

export default function View(props){

    const {id} = useParams();

    const {toggle , item , setMainData } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    let signboard = {
        error : error ,
        success,
        loading : loading
    }
    let [data , setData] = useState({
        date : new Date(item.date).toISOString(),
        home : item.home._id,
        cartons : [...item.cartons.filter( carton => carton.hasOwnProperty('symbol') )]
    })

    const weightNames = useMemo(()=>{
        return data?.cartons[0]?.weights?.map( w => w.name )
    }, [data])

    const getWeightAmounts = ( id ) => {
        const amounts = []
        item?.cartons.forEach( carton => {
            carton?.weights.forEach( (weight , idx) => {
                if(weight.weight === id) return amounts.push({
                    amount : +weight?.amount,
                    eggs : +weight?.amount * +carton?.total_egg_count
                })
                else return
            })
        })
        return {
            amounts : amounts?.map( w => w.amount ).reduce((a , b) => a + b , 0) ,
            eggs : amounts?.map( w => w.eggs ).reduce((a , b) => a + b , 0),
            over_360 : () =>  getWeightAmounts(id).eggs / 360
        }
    }


    const weightTotals = useMemo(()=>{
          const _weights =  data?.cartons[0]?.weights?.map( w => ({name : w.name , id : w.weight , amount : 0}) );
          _weights.forEach( (weight , idx) => {
            _weights[idx].amount = getWeightAmounts(weight.id).amounts
            _weights[idx].eggs = getWeightAmounts(weight.id).eggs
            _weights[idx].standard = getWeightAmounts(weight.id).over_360()
          })
        return  _weights
    } , [data])

    const calcCartonWeights = ( weights ) => {
            return weights?.map( w => w.amount ).reduce((a , b) => a + b , 0)
    }


    const cartonTotals = useMemo(()=>{
            const _cartons = []
            const cartons =  data?.cartons;
            cartons.forEach( carton => {
                _cartons.push( {
                    _id : carton.carton,
                    name : carton.name, 
                    total : calcCartonWeights(carton.weights),
                })
            })
            return {
                cartons : _cartons ,
                weights : weightTotals?.map(w => w.amount)?.reduce((a , b) => a + b , 0),
                weightEggs : weightTotals?.map(w => w.eggs)?.reduce((a , b) => a + b , 0),
                standardCartons : weightTotals?.map(w => w.standard)?.reduce((a , b) => a + b , 0)
            }
        } , [data])
        
  

    return(
<>
    <PageMeta title={'تعديل تقرير بيض يومي'} originTitle={'تقارير البيض'}/>

    <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3>    عرض تقرير البيض يوم ( { _date(data.date).dmy() })</h3>
    </header>
        <Signboard {...signboard}/>
            
        <div className="data-table-head d-flex py-3">
            <p className="col-2 px-0">التاريخ</p>
            <p className="px-0">{_date(data.date).dmy()}</p>
        </div>
        <div className="view-data-row relative">

            <div className="overflow-x-auto"  >
                <Grid.FlexResponsive  className={'align-items-center'}>
                    <Grid.Span size={4}>الاوزان</Grid.Span>
                    {
                        weightNames?.map( name => <Grid.Span size={3} className="data-row"  key={key()} >{name}</Grid.Span> )
                    }
                </Grid.FlexResponsive>
                {
                    data && data.cartons && data.cartons.length > 0 && data.cartons.map((carton , cartonIndex)=>{
                        return(
                            <Grid.FlexResponsive key={carton._id ?? key()} className={'align-items-center  carton-row'}>
                                <Grid.Span size={4} className="data-row"> {carton.name} </Grid.Span>
                                <Grid.Span className="carton-total" size={2}> { (cartonTotals?.cartons?.filter( c => c._id == carton.carton )[0]?.total ) ?? 0 } </Grid.Span>
                                {/* <Grid.Span className="carton-total" size={3} >{ cartonTotals[0]?.weights }</Grid.Span> */}
                                { 
                                    carton.weights && carton.weights.length > 0 && carton.weights.map((weight , index)=>{
                                        return <Grid.Span size={3} key={key()} className="data-row"  >{weight.amount}</Grid.Span>
                                    })
                                }
                            </Grid.FlexResponsive>
                        )
                    })
                }
                <Grid.FlexResponsive  className={'align-items-center  carton-row '}>
                    <Grid.Span size={4} className="bg-secondary-100 data-row ">اجمالي الاوزان</Grid.Span>
                    <Grid.Span className="carton-total" size={2}> { Helpers.Number.commaFormat( cartonTotals?.weights )  }  </Grid.Span>
                    { weightTotals?.map( weight => <Grid.Span size={3} className="bg-secondary-100 data-row"  key={key()} >{weight?.amount}</Grid.Span> ) }
                </Grid.FlexResponsive>
                <Grid.FlexResponsive  className={'align-items-center  carton-row '}>
                    <Grid.Span size={4} className="bg-secondary-200 data-row ">اجمالي البيض</Grid.Span>
                    <Grid.Span className="carton-total" size={2}> { Helpers.Number.commaFormat( cartonTotals?.weightEggs ) }  </Grid.Span>
                    { weightTotals?.map( weight => <Grid.Span size={3}  className="bg-secondary-200 data-row"  key={key()} >{ Helpers.Number.commaFormat(weight?.eggs) }</Grid.Span> ) }
                </Grid.FlexResponsive>
                <Grid.FlexResponsive  className={'align-items-center  carton-row'}>
                    <Grid.Span size={4} className="bg-secondary-300 data-row "> الكرتون المتداول</Grid.Span>
                    <Grid.Span className="carton-total" size={2}> {Helpers.Number.commaFormat( cartonTotals?.standardCartons)}  </Grid.Span>
                    { weightTotals?.map( weight => <Grid.Span size={3}  className="bg-secondary-300 data-row"  key={key()} >{  weight?.standard.toFixed(2) }</Grid.Span> ) }
                </Grid.FlexResponsive>
            </div> 
        </div>

        <button onClick={toggle} className="add-btn my-3"> الغاء </button>

</>
    )
}











