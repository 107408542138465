import '../../styles/projects.styles.scss';
import { useEffect, useRef, useState } from "react";
import { Icons, PageMeta, postData, QuickView, Signboard, useFetch } from '../../config/imports';
import { projects_api, stock_api } from "../../config/apis";
import { Link, NavLink } from "react-router-dom";
import ProjectCrud from "./project_crud";
import "../../styles/projects.styles.scss";
import { setProjectsBreadCrumbs } from '../../components/breadcrumbs/store/breadcrumbs.default';
// import Location from "../../components/Location";


export default function ProjectsBase(){

    // const  { setLocation } = useBreadCrumb()
    // setLocation([
    //     { href : '/' , text : 'الرئيسية' },
    // ])



    useEffect(() => {
        setProjectsBreadCrumbs.index()
    } , [])

    const createRef = useRef(),
        editRef = useRef(),
        deleteRef = useRef(),
        colorRef = useRef();
    // const addProjectRef = useRef()

    const {
        data : stocks ,
        error : stocks_error ,
        isLoading : stocks_isLoading
    } = useFetch(stock_api.base)

    let {
        data : projects ,
        error : projects_error ,
        isLoading : projects_isLoading,
        setData : setProjects ,
    } = useFetch(projects_api.read)


    const [errorStatus , setErrorStatus] = useState('')
    const [successStatus , setSuccessStatus] = useState('')
    const [loading , setloading] = useState(false)

    const signboard = {
        error : projects_error || stocks_error || errorStatus,
        loading : projects_isLoading || stocks_isLoading || loading,
        success : successStatus
    }

    let [fav , setFav] = useState( [])
    let [notFav , setNotFav] = useState([])



    useEffect(() => {
        // console.log(homes)
        if(projects && projects.length > 0){
            setFav(projects.filter(p => p.is_favorite))
            setNotFav(projects.filter(p => !p.is_favorite))
        }
    } , [projects])



    const favoriteStateHandler = async (home , e)=>{
        setloading(true)
        setErrorStatus('')
        //     find the index of this home
        const pIndex = projects.findIndex(h => h._id === home._id)
        const ToBeChanged = projects.filter(h => h._id === home._id)[0]

        if(ToBeChanged){

            const {data , error } = await postData(projects_api.toggleFavorite() , { project_id : ToBeChanged._id }  )
            setloading(false)
            // console.log({data , error})
            if(!data) return setErrorStatus('لم يتم وضع المشروع في المفضله')

            ToBeChanged.is_favorite = !ToBeChanged.is_favorite
            projects[pIndex] = ToBeChanged
            projects = [...projects]
            setProjects(projects)
        }
    }

    const lockStateHandler = (project , e)=>{
        const projectIndex = projects.findIndex(p => p._id === project._id)
        const ToBeChanged = projects.filter(p => p._id === project._id)[0]
        if(ToBeChanged){
            ToBeChanged.is_locked = !ToBeChanged.is_locked
            projects[projectIndex] = ToBeChanged
            projects = [...projects]
            setProjects(projects)
        }
    }


    const menuHandler = (e)=>{

        const TIME_TO_CLOSE_MENU = 10000

        const menuId = e.target.dataset.menuId
        // document.querySelectorAll('.cube-menu').forEach(el => el.classList.remove("active-menu"))
        if(menuId)
           document.getElementById(menuId)?.classList.toggle("active-menu")


        setTimeout(()=>{
           document.getElementById(menuId)?.classList.toggle("active-menu")
        } , TIME_TO_CLOSE_MENU)
    }

	return(
       <div className='py-3'>

           <div className="projects-base bg-white px-4 py-2 r-16 mt-3">
               <PageMeta title={'المشاريع'} />
               <Signboard {...signboard}/>


               <QuickView className="overflown-popup"  ref={createRef}><ProjectCrud.Create  setMainData={setProjects} /></QuickView>
               <QuickView ref={editRef}><ProjectCrud.Edit   setMainData={setProjects} /></QuickView>
               <QuickView ref={deleteRef}><ProjectCrud.Delete  setMainData={setProjects} /></QuickView>
               <QuickView ref={colorRef}><ProjectCrud.Color homes={projects} setHomes={setProjects} /></QuickView>


               {
                   fav && fav.length > 0 && (
                       <div className="">
                           <h4 className='d-flex  align-items-center mt-4'>
                               <img src="/icos/star.png" alt="star icon"/>
                               <span className='mx-2 d-inline-block'> المفضلة </span>
                           </h4>
                           <div className="d-flex flex-wrap ">
                               {
                                   fav.map(item => (
                                       <div className="round-cube" key={item._id} id={'home-'+item._id}>
                                           <div className="cube-menu" id={'menu' + item._id}>
                                               <div className='menu-item edit-cube flex'>
                                                   <button onClick={ () => editRef.current.toggle(item) }>
                                                       تعديل
                                                       <Icons.Edit/>
                                                   </button>
                                               </div>
                                               <div className='menu-item delete-cube flex'>
                                                   <button onClick={ () => deleteRef.current.toggle(item) }>
                                                       حذف
                                                       <Icons.Delete/>
                                                   </button>
                                               </div>
                                               <div className='menu-item delete-cube flex'>
                                                   <button onClick={ () => colorRef.current.toggle(item) }>
                                                       تغيير اللون
                                                       <Icons.Color/>
                                                   </button>
                                               </div>
                                           </div>

                                           <div className="data-container" style={{backgroundColor: item.color ?? "#41e481"}}>
                                               <header className='header-menu flex justify-between'>
                                                   <button type='button' data-menu-id={'menu' +item._id}
                                                           onClick={menuHandler}
                                                   >...</button>
                                                 <Link to={`/project/eggs-warehouse/${item._id}`}>
                                                    <img src="/icos/eggs-warehouse.png" className='w-[25px]  relative top-2' alt="eye"/>
                                                </Link>
                                               </header>
                                               <div className="cube-name">
                                                   <NavLink to={`/projects/${item._id}/departments`} >
                                                       {item.name}
                                                   </NavLink>
                                               </div>
                                               <div className="d-flex justify-end">
                                                   {/* <button onClick={ (e ) => lockStateHandler(item , e) } >
                                                       <img src={`/icos/${item.is_locked ? 'locked' : 'lock'}.png`} alt=""/>
                                                   </button> */}
                                                   <button onClick={ (e ) => favoriteStateHandler(item , e) } >
                                                       <img src="/icos/fav-star.png" alt=""/>
                                                   </button>
                                               </div>
                                           </div>
                                           <div className=" text-center">
                                               {
                                                   `مشروع ${new Date(item.createdAt).getFullYear()}`
                                               }
                                           </div>

                                       </div>
                                   ))
                               }
                           </div>
                       </div>
                   )
               }
               {
                   notFav && (
                       <div className="not-fav-section flex-wrap">
                           <h4 className='d-flex  align-items-center mt-4 '>
                               <img src="/icos/category.png" alt="category icon"/>
                               <span className='mx-2 d-inline-block'> كل المشاريع </span>
                           </h4>
                           <div className="d-flex flex-wrap">
                               <div className="round-cube" >
                                   <button onClick={ ()=>  createRef.current.toggle() }>
                                       <div className="data-container add-cube">
                                           <div className="flex flex-col justify-center items-center">
                                               <img src="/icos/add-square.png" alt="add"/>
                                               <p className="mt-3">اضافة مشروع</p>
                                           </div>
                                       </div>
                                   </button >
                               </div>
                               {
                                   notFav.map(item => (
                                       <div className="round-cube" key={item._id} id={'home-'+item._id}>
                                           <div className="cube-menu" id={'menu' + item._id}>
                                               <div className='menu-item edit-cube flex'>
                                                   <button onClick={ () => editRef.current.toggle(item) }>
                                                       تعديل
                                                       <Icons.Edit/>
                                                   </button>
                                               </div>
                                               <div className='menu-item delete-cube flex'>
                                                   <button onClick={ () => deleteRef.current.toggle(item) }>
                                                       حذف
                                                       <Icons.Delete/>
                                                   </button>
                                               </div>
                                               <div className='menu-item delete-cube'>
                                                   <button onClick={ () => colorRef.current.toggle(item) } className='flex'>
                                                       تغيير اللون
                                                       <Icons.Color/>
                                                   </button>
                                               </div>
                                           </div>
                                           <div className="data-container" style={{backgroundColor: item.color ?? "#41e481"}}>
                                               <header className='header-menu flex justify-between'>
                                                   <button type='button' data-menu-id={'menu' +item._id}
                                                           onClick={menuHandler}
                                                   >...</button>

                                                <Link to={`/project/eggs-warehouse/${item._id}`}>
                                                    <img src="/icos/eggs-warehouse.png" className='w-[25px]  relative top-2' alt="eye"/>
                                                </Link>
                                               </header>
                                               <div className="cube-name">
                                                   <NavLink to={`/projects/${item._id}/departments`} >
                                                       { item.name}
                                                   </NavLink>
                                               </div>
                                               <div className="d-flex justify-end">
                                                   {/* <button onClick={ (e ) => lockStateHandler(item , e) } >
                                                       <img src={`/icos/${item.is_locked ? 'locked' : 'lock'}.png`} alt=""/>
                                                   </button> */}
                                                   <button onClick={ (e ) => favoriteStateHandler(item , e) } >
                                                       <img src="/icos/outline-star.png" alt=""/>
                                                   </button>
                                               </div>
                                           </div>
                                           <div className=" text-center">
                                               {`مشروع ${new Date(item.createdAt).getFullYear()}`}
                                           </div>
                                       </div>
                                   ))
                               }
                           </div>
                       </div>
                   )
               }
           </div>



       </div>
    )
}
