// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.auth-container {
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.auth-container input {
  border: 1px solid #ccc;
  padding: 8px 20px;
}
.auth-container .auth-form-container {
  background-color: #fff;
  border-radius: 16px;
  margin: 40px;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.05);
  width: 500px;
  padding: 50px;
}
.auth-container .auth-form-container .login-logo {
  text-align: center;
  margin-bottom: 50px;
}
.auth-container .auth-form-container .login-logo svg {
  width: 80px;
  height: 80px;
}
.auth-container .auth-form-container .password-container {
  position: relative;
}
.auth-container .auth-form-container .password-container button {
  border: none;
  outline: none;
  background-color: transparent;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 70%;
  left: 10px;
  transform: translateY(-50%);
}
.auth-container .auth-form-container .password-container button img {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/styles/auth.styles.scss"],"names":[],"mappings":"AAGA;EACI,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;AAKE;EACE,sBAAA;EACA,iBAAA;AAHJ;AAME;EACE,sBAAA;EACA,mBAAA;EACA,YAAA;EACA,2CAAA;EACA,YAAA;EACA,aAAA;AAJJ;AAMI;EACE,kBAAA;EACA,mBAAA;AAJN;AAKM;EACI,WAAA;EACA,YAAA;AAHV;AAOI;EACE,kBAAA;AALN;AAMM;EACE,YAAA;EACA,aAAA;EACA,6BAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,2BAAA;AAJR;AAMQ;EACE,WAAA;AAJV","sourcesContent":["//*{\n//  outline: 1px solid teal;\n//}\n.auth-container{\n    height: 90vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n\n  input{\n    border : 1px solid #ccc;\n    padding: 8px 20px;\n  }\n\n  .auth-form-container{\n    background-color: #fff;\n    border-radius: 16px;\n    margin: 40px;\n    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.05);\n    width: 500px;\n    padding : 50px;\n\n    .login-logo{\n      text-align: center;\n      margin-bottom: 50px;\n      svg{\n          width: 80px ;\n          height : 80px;\n      }\n    }\n\n    .password-container{\n      position: relative;\n      button{\n        border: none;\n        outline: none;\n        background-color: transparent;\n        width: 35px;\n        height: 35px;\n        position: absolute;\n        top: 70%;\n        left: 10px;\n        transform: translateY(-50%);\n\n        img {\n          width: 100%;\n        }\n      }\n    }\n\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
