import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {apis} from "../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../helpers/form.helper";
import {
    EditForm,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../config/imports";

import Icons from "../../layout/Icons";
import axiosPut from "../../helpers/axios/axios.put";

export default function AcceptOrder(props){
    const { toggle  , updateMain  } = props;


    const {id : order_id } = useParams()

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let [data , setData] = useState({
        comment : '',
        files : [],
    })

    useEffect(()=>{
        if(success && success.length > 0)
            updateMain(true)
        else updateMain(false)
    } , [success])

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };


    const handleRejection = async () => {
        setLoading(true);

        // ⚠️ please note ⚠️
        // ⭕ when sending data to server the data.files is renamed to be *files[]* not as we made it files ⭕
        const { data : putData , error  } = await axiosPut(apis.orders.reject(order_id) , {...data} , {
            headers :{
                'Content-Type': 'multipart/form-data',
            }
        })
        setLoading(false);
        if(error) return setError(error)
        setSuccess('تم رفض الطلب');
    }

    const handleAccept = async () => {

        setLoading(true);

        // ⚠️ please note ⚠️
        // ⭕ when sending data to server the data.files is renamed to be *files[]* not as we made it files ⭕
        const { data : putData , error } = await axiosPut(apis.orders.accept(order_id) , {...data} , {
            headers :{
                'Content-Type': 'multipart/form-data',
            }
        })
        setLoading(false);
        if(error) return setError(error)
        setSuccess('تمت الموافقه على الطلب');

        // console.log({putData})

    }

    const handleFileClick = (e  , index) => {
        const _files = [...data.files]
        const file =  _files[index]
        data.files = _files.filter( f => f !== file)
        setData({...data})
    }



    const handleFileChange = (e) => {
        const files = e.target.files;
        if(!files || !files.length ) return
        data.files = [...data.files , ...files]
        setData({...data})
    }


    console.log({data})
    return(
        <>

            <PageMeta title={' اعتماد  / رفض الطلب '} originTitle={'  تفاصيل الطلب '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>  اعتماد  / رفض الطلب</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm visible={false} toggle={toggle} onChange={handleInputChange}>

                <>

                    <InputTextArea name="comment" handleInputChange={handleInputChange}  value={data.comment} title="التعليق" placeholder="سبب الاعتماد / الرفض..."  />
                    <p> الوثائق </p>
                    <div className="files-container">
                        {
                            data && data.files.map( (file , index) => (
                                <div key={index} className="file-container" onClick={ (e)=> handleFileClick(e , index) } title={file?.name}>
                                    <div className="file-icon"><Icons.File /></div>
                                    <span>{file?.name}</span>
                                </div>
                            ))
                        }

                        <label className="add-file file-container" htmlFor="file">

                            {/*<Icons.Plus /> */}
                            <svg width="70" height="71" viewBox="0 0 70 71" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M40.94 51.1106C41.0514 52.1233 41.1764 53.2612 41.2988 54.3752M48.3603 47.5549C49.1929 48.3874 50.1283 49.3229 51.0442 50.2388M51.7238 40.3269C52.7364 40.4382 53.8743 40.5634 54.9883 40.6858M30.0573 18.276C29.9456 17.2639 29.8212 16.126 29.6993 15.0113M22.6374 21.832C21.8049 20.9994 20.8697 20.0643 19.9538 19.1483M19.2739 29.0594C18.2618 28.9478 17.1239 28.8233 16.0091 28.7015" stroke="#1093F1" strokeWidth="3" strokeLinecap="round"/>
                                <path d="M32.9505 47.508L25.895 54.5635C23.0612 57.3973 18.4664 57.3973 15.6325 54.5635C12.7986 51.7296 12.7986 47.1348 15.6325 44.3009L24.6122 35.3212C27.094 32.8395 30.9261 32.531 33.7436 34.3961" stroke="#9FD4F9" strokeWidth="3" strokeLinecap="round"/>
                                <path d="M36.7018 23.2312L43.6611 16.2719C46.495 13.438 51.0898 13.438 53.9236 16.2719C56.7575 19.1058 56.7575 23.7005 53.9236 26.5344L44.9439 35.5141C42.4392 38.0188 38.5592 38.3098 35.735 36.3871" stroke="#9FD4F9" strokeWidth="3" strokeLinecap="round"/>
                            </svg>
                            <span>ارفاق مرفق</span>
                        </label>

                        <input type="file" name={'file'} id="file" accept=".jpg, .jpeg, .png, .gif, .pdf" multiple  onChange={handleFileChange} />

                    </div>



                </>






            <div className="d-flex align-items-center justify-content-between">
                <button type='button' className='close-btn' onClick={() => toggle()} > الغاء </button>
                <button type='button' className={'custom-btn reject-btn'} onClick={ handleRejection }> رفض الطلب  </button>
                <button type='button' className={'add-btn'} onClick={ handleAccept}> اعتماد الطلب  </button>
            </div>
            </EditForm>



        </>
    )
}
