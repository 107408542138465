import { useState, useEffect, useCallback } from "react";
import { accio } from "../helpers/axios/axios.get";
import useAlert from "./useAlert";
import { showErrorToast, useToastStore } from "../stores/toast.store";

const useFetch = (nodeUrl, params) => {
  
  const [data, setData] = useState(null);
  const [paginate, setPaginate] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(null);
  const { setters, Alert } = useAlert();
  const [query, setQuery] = useState({});

  const fetchData = useCallback(async () => {
    // console.log("fetch data from use fetch" , nodeUrl);
    try {
      if (!nodeUrl) return;
      setIsLoading(true);
      setters.loading();

      const { data, error, statusCode, paginate } = await accio(nodeUrl, params);
      if (error) {
        setData(null);
        setPaginate({});
        setError(error);
        setters.error(error);
        setIsLoading(false);

        showErrorToast(error?.message || error);

        return;
      }
      setData(data);
      setPaginate(paginate);
      setError(null);
      setIsLoading(false);
      setters.success("تم تحميل البيانات بنجاح");
    } catch (error) {
      setData(null);
      setPaginate({});
      setError(error.message);
      setIsLoading(false);
      setters.error(error.message);
    } finally {
      setters.reset();
    }
  }, [nodeUrl, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (refresh === null || refresh === undefined) return;
    else fetchData();
  }, [refresh]);

  return {
    data,
    isLoading,
    error,
    paginate,
    setRefresh,
    setData,
    setters: {
      data: setData,
      error: setError,
      loading: setIsLoading,
      refresh: setRefresh,
      paginate: setPaginate,
    },
    Alert,
    setAlert: setters,
    alert: {
      loading: isLoading,
      error,
    },
  };
};

export default useFetch;
