import PageMeta from "../../../components/PageMeta";
import {
    EditForm,
    InputNumber,
    InputText,
    Signboard,
    setInputsToDefault,
    updateFormHandler
} from "../../../config/imports";
import {useState} from "react";
import {packaging_api} from "../../../config/apis";

export default function EditUnit(props){


    const {toggle , item , setMainData} = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");



    const [data, setData] = useState({
        symbol : item.symbol,
        name : item.name,
        dishes_count : item.dishes_count,
        egg_count : item.egg_count,
        total_egg_count : item.total_egg_count,
        packaging_type : item.packaging_type,
        dishes_type : item.dishes_type,
    })

    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();


        const update_props = {
            url: packaging_api.update(item._id),
            data : {
                ...data,
                total_egg_count : +data.egg_count * +data.dishes_count
            },
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };


    return<div className='storage-unit-details'>

        <PageMeta title={"تعديل تفاصيل الوزن" + item.size} originTitle={'تفاصيل الاوزان'}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>{"تعديل وحدة التخزين   " + item.name}</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>


        <EditForm
            visible={true}
            onSubmit={handleFormSubmit}
            toggle={toggle}>
            {
                data && (
                    <>
                        <InputNumber name={'symbol'} handleInputChange={handleInputChange}  placeholder={'0'}  title={'الرمز'} defaultValue={data.symbol}/>
                        <InputText name={'name'} handleInputChange={handleInputChange} placeholder={'0'}  title={'الاسم'} defaultValue={data.name}/>
                        <InputNumber name={'dishes_count'} handleInputChange={handleInputChange}   placeholder={'0'} title={'عدد الاطباق'} defaultValue={data.dishes_count}/>
                        <InputNumber name={'egg_count'} handleInputChange={handleInputChange}  className={'col-3'}  placeholder={'0'}  title={'عدد البيض فى كل طبق'} defaultValue={data.egg_count}/>
                        <InputNumber name={'total_egg_count'} handleInputChange={handleInputChange} className={'col-3'}   placeholder={'0'}  title={'عدد البيض  فى الكرتونة'} defaultValue={+data.egg_count * +data.dishes_count}/>
                        <InputText name={'packaging_type'} handleInputChange={handleInputChange}  className={'col-3'}  placeholder={'0'}  title={'نوع الكرتونة  '} defaultValue={data.packaging_type}/>
                        <InputText name={'dishes_type'} handleInputChange={handleInputChange} className={'col-3'}   placeholder={'0'}  title={'  نوع الطبق'} defaultValue={data.dishes_type}/>
                    </>
                )
            }
        </EditForm>


    </div>

}
