import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { apis, reports_api } from "../../../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../../../helpers/form.helper";
import { _date, EditForm, InputDate, InputNumber, InputSelect, InputTextArea, PageMeta, Signboard, useFetch } from "../../../../../config/imports";
import useMassUnit from "../../../../../hooks/types/useMassUnit";
import { getUnitName } from "../../../../../helpers/converters/units.converter";
import { useFeedReportQuantityPrice } from "../../hooks/useReportQuantityPrice";
import useDebounce from "../../../../../hooks/useDebounce";

export default function Create(props){

    const {id} = useParams();
    const {setMainData, toggle , setRefresh } = props;
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    // const {data : formData} = useFetch(apis.reports_api.home.feed.formDetails(id))

    const {data : types} = useFetch(apis.homes.feedTypes(id))


    const options = useMemo(() => {
        if(!types || types === null || types.length === 0 || types === undefined) return 
        else return types?.map(d => ({text : d.name , value : d._id}))
    } , [types])


    const {options : massOptions} = useMassUnit()

    const [data , setData] = useState({
        home : id,
        date : new Date().toISOString(),
        sub_category : "",
        consumption : '',      
        unit : "",
        notes : '',
    })

    
    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };


    const debouncedConsumption = useDebounce(data.consumption)

    const {price , fetchPrice  , loading : loadingPrice } = useFeedReportQuantityPrice({
        unit : data.unit , consumption : debouncedConsumption , sub_category : data.sub_category 
    })

    useEffect(() => {
        fetchPrice()
    } , [data.unit , debouncedConsumption , data.sub_category])
    

    const unitTitle = useMemo(() => {
            let _unit = !data.unit || data.unit.length === "" ?  "kg" : data.unit
            return  "بال" + getUnitName(_unit)
    } , [data])


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: reports_api.home.feed.create(),
            data : {
                ...data,
                date : _date(data.date).toZeroUTC(),
                consumption_value :  price, 
            },
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=> {},
            setInputsToDefault,
            setRefresh,
            setData,
            FORM_HEADER: `الاضافة`,
        };


        // console.log(create_props.data);
        await createFormHandler(create_props);
    };

    return(
       <>
           <PageMeta title={'اضافة تقرير علف يومي'} originTitle={'تقارير العلف'}/>
           <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
               <h3>اضافة تقرير علف يومي</h3>
           </header>
           <Signboard error={error} success={success} loading={loading}/>
           <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
               <InputDate className="col-6"  name={'date'}    defaultValue={_date(data.date).forInputDate()} placeholder={'B'}  title={'التاريخ'} value={data.date}/>
               <InputSelect className="col-6"  name={'sub_category'}    options={options}  title={'نوع العلف'} defaultValue={data.sub_category}  />
               <InputNumber className="col-4"  name={'consumption'}     placeholder={'10'}  title={' الكميه  '  } value={data.consumption}/>
               <InputSelect className="col-4"  name={'unit'}    options={massOptions}  title={'وحدة القياس'} defaultValue={data.unit}  />
               <InputNumber className="col-4" disabled   placeholder={""}  labelProps={{ content : loadingPrice ?
                              <div className="flex justify-between items-center w-full">
                                <span  > قيمة الاستهلاك </span>
                                <i className="fa-solid fa-spinner fa-spin mx-2" />
                           </div>
                 : ' قيمة الاستهلاك  ' }}   defaultValue={price}/>
               <InputTextArea name='notes'    placeholder={' ملاحظات على تقارير الاعلاف... '}  title={'ملاحظات'} value={data.notes}/>
           </EditForm>
       </>
    )
}
