import { Route } from "react-router-dom";
import { INCUBATORS } from "./IncubatorIndex";
import MainContainer from "../../components/MainContainer";
import IncubatorsHatcheriesIndex from "./hatcheries/IncubatorsHatcheriesIndex";
import IncubatorOrdersIndex from "./orders/IncubatorOrdersIndex";
import IncubatorsClientsIndex from "./clients/IncubatorsClientsIndex";

const incubators_routes = {
  router: (protect) => {
    return (
      <Route
        path="/incubators"
        element={<MainContainer details={INCUBATORS} />}>
        <Route
          path="hatcheries"
          element={protect(<IncubatorsHatcheriesIndex />)}
        />
        <Route path="orders" element={protect(<IncubatorOrdersIndex />)} />
        <Route path="clients" element={protect(<IncubatorsClientsIndex />)} />
      </Route>
    );
  },
};

export default incubators_routes;
