import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import PageMeta from "./PageMeta";
import useNavigator from "../hooks/useNavigator";

const MainContainer = (props) => {
  const { id } = useParams();

  let origin = "";

  if (id) origin = id;
  else origin = props.details.origin;

  useNavigator( origin , props.details.navigateTo)

  return (
    <>
      <PageMeta title={props.details.title} originTitle={props.details.title} />
      <div className="main-links-container ">
        {props.details.links.map((link, idx) => {
          return (
            <NavLink to={link.to} key={idx}>
              {link.name}{" "}
            </NavLink>
          );
        })}
      </div>
      <Outlet />
    </>
  );
};

export default MainContainer;
