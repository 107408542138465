import React from 'react'
import DisplayUploadedImage from './DisplayUploadedImage'
import DisplayFileImage from './DisplayFileImage'
import { paths } from '../../config/configs.env'

export default function DisplayUploadedImagesGroup(props) {




  const handleDelete = (file) =>{
        
    const _imgs = props.images.filter((img)=> img !== file )
    
    props.setData(pre =>{ return { ...pre, [props.keyNAme] : _imgs } })
    
}


  return (
    <div className='file-img-group upload-img-group'>
        {props?.images.map((img , index) =>{
            if(typeof img === 'object') return <DisplayFileImage key={index} file={img}  handleDelete={handleDelete} />
            return <DisplayUploadedImage key={index} src={ paths.uploads + "/" + props.src + "/" + img } alt={img?.alt ?? ""} />
        })}
    </div>
  )
}
