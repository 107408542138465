import { useEffect, useState } from "react";
import { InputNumber } from "../../../config/imports";

export default function TotalFromHomesInput(props){
    const {data , setData , className} = props
    const [ state , setState  ] = useState()
    const [error , setError] = useState(null)


    useEffect(()=>{

        if(data){
            const _totalFromHomes = +(data.total_quantity_from_homes ) ?? 0
            const _stockAmount =+( data?.stock_quantity) ?? 0
            const _total = _totalFromHomes + _stockAmount
            if(_total  !==  data?.quantity && _total != 0){
                  if(setError) setError('  اجمالي الكميه  لا يساوي  الكميه المدخله من البيوت والمستودع ')
            }else {
                setError(null)
            }

            setState((data.total_quantity_from_homes??0) + (data?.stock_quantity ?? 0))    
        }

    } , [data])


    // useEffect(()=>{
    //     if(error){
    //         setTimeout(()=>setError(null) , 3000)
    //     }
    // } , [error])

    


    return(
        <div className={`stick-error-to-input ${ className ?? 'col-6'} px-0 ` + (error === "الكميات غير متطابقه" ? 'input-has-error ' : '')}>
                <InputNumber 
                    className={'col-12'} 
                    name={'total_quantity_from_homes'} 
                    title={'الاجمالي من البيوت والمستودع'  } 
                    disabled={true}  
                    defaultValue={state} 
                />
             {error &&  <small className="text-danger px-3 error-output">{error}</small>}
        </div>
    )
}
