import { Route } from "react-router-dom"

const RouterHelpers = {
    build(path  , Element , key){
        return <Route path={path} element={Element} key={key} />
    },
    makeRoutes(routes){
        return routes.map(({path , Element , sub} , index) => {
            if (!sub) return this.build(path , Element  , index)
            const subRoutes = this.makeRoutes(sub)
            return <Route path={path} element={Element} key={index} > {subRoutes} </Route>
        })
    }

}


export default RouterHelpers