import { useEffect } from "react";
import { company } from "../config/configs.env";

const COMPANY_NAME = company.name || process.env.REACT_APP_COMPANY_NAME;

const SEPARATOR = " | ";

const BASE_NAME = COMPANY_NAME + SEPARATOR;

const PageMeta = ({ title, originTitle }) => {
  useEffect(() => {
    document.title = BASE_NAME + title;

    return () => {
      document.title = BASE_NAME + originTitle || BASE_NAME + title;
    };
  }, []);

};

export default PageMeta;
