import PageMeta from "../../../../../components/PageMeta";

import RouterLinks from "../../../../../components/LinkList";
import useNavigator from "../../../../../hooks/useNavigator";

const HousesMaintenanceReports = () => {
    const HousesMaintenanceReports_Links = [
        {
            name: "قائمة فواتير شراء صيانة بيت",
            path: "maintenance-invoices",
        },
        {
            name: "قائمة حركة الصيانة",
            path: "maintenance-operations",
        },

    ];

    useNavigator("maintenance", "maintenance-invoices");

    return (
        <>
            <PageMeta title={"صيانة وقطع غيار"} />

            {<RouterLinks underline={true} data={HousesMaintenanceReports_Links} />}
        </>
    );
};

export default HousesMaintenanceReports;
