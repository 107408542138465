import {PageMeta, Signboard, useFetch, _date, Icons, TableHeader, QuickView,} from "../../../config/imports";
import React , {useRef} from "react";
import {projectsAPI} from "../../Projects/projects.helper";
import {Table} from "../../../layout/table";
import HatcheriesCRUD from "./hatcheries_crud";

const IncubatorsHatcheriesIndex = () => {
  const createIncRef = useRef();
  const updateRef = useRef();
  const deleteRef = useRef();
  const hatcheryRef = useRef();
  const incubatorRef = useRef();

  const { data , setData: setMainData, error, isLoading} = useFetch(projectsAPI.feed);

  const buttons = [
    {
      title: "الإيداع بالفقاسة",
      onclick: () => hatcheryRef?.current?.toggle(),
    },
    {
      title: "الإيداع بالحضانة",
      onclick: () =>  incubatorRef?.current?.toggle(),
    },
    {
      title: "إضافة فقاسات او الحاضنات",
      onclick: () => createIncRef?.current?.toggle(),
    },
  ];

  const mock_api_data = [
    {
      id: 1,
      name: "الحاضناة 1", // الاسم
      brand: "Petersime", //  الماركة
      location: "غرفة الحاضنات", // الموقع
      trolleys_type: "اطباق", // نوع الترولي
      trolleys_count: "5", // عدد التروليات
      dishes_per_trolley: "10", // عدد الاطباق في الترولي
      eggs_per_box: "100", // عدد البيض في الطبق
      eggs_per_trolley: "1000", // عدد البيض في الترولي
      boxes_count: "500", // عدد الاطباق
      total_eggs: "100000", // مجموع البيض
    },
    {
      id: 2,
      name: "الحاضناة 2",
      brand: "Peters",
      location: "جنوة الحاضناة",
      trolleys_type: "صندوق",
      trolleys_count: "5",
      dishes_per_trolley: "10",
      eggs_per_box: "100",
      eggs_per_trolley: "1000",
      total_box: "500",
      total_eggs: "100000",
    },
  ];

  const table = [
    {size : 1 , name : "#" , text : "#"  },
    {size : 2 , name : 'name' , text : 'الاسم '  },
    {size : 2 , name : "brand" , text : "الموقع"  },
    {size : 2 , name : "location" , text : "نوع الترولي"  },
    {size : 2 , name : "trolleys_type" , text : "عدد التروليات" , },
    {size : 3 , name : 'dishes_per_trolley' , text : 'عدد الاطباق في الترولي' },
    {size : 3 , name :'eggs_per_box' , text : 'عدد البيض في الطبق ' ,},
    {size : 2 , name : 'eggs_per_trolley' , text : 'عدد البيض في الترولي' },
    {size : 2 , name : 'boxes_count' , text : 'عدد الاطباق' },
    {size : 3 , name : 'total_eggs' , text : 'مجموع البيض' },
    {size : 2 , name : '' , text : '' , }
  ]




  return (
    <div className="bg-white base-card r-16 p-3 incubator">
      <QuickView ref={createIncRef} ><HatcheriesCRUD.Create/></QuickView>
      <QuickView ref={incubatorRef} ><HatcheriesCRUD.Incubator/></QuickView>
      <QuickView ref={hatcheryRef} ><HatcheriesCRUD.Hatchery/></QuickView>
      <TableHeader buttons={buttons} />
      <PageMeta title="الفقاسات والحضانات" />
      <Signboard />
      <Table table={table} data={mock_api_data}  updateRef={updateRef} deleteRef={deleteRef} className={'mt-5'} />
    </div>
  );
};

export default IncubatorsHatcheriesIndex;
