import FormModal from "./FormModal";

const TableHead = ({ head }) => {
  return (
    <>
      <div className="data-table-head row">
        {head.map((item, index) => {
          return (
            <div
              className={`data-table-body-item col-${
                typeof item.col === "number" ? item.col : "auto"
              }${item.className ? " " + item.className : ""}`}
              key={index}>
              {item.tilte !== undefined || item.title !== null
                ? item.title
                : item.name}
            </div>
          );
        })}
      </div>
    </>
  );
};

const TableData = ({
  data,
  body,
  setSelectedComponent,
  setUpdateModalState,
  setDeleteModalState,
  deleteModalsate,
  updateModalState,
  updateRef,
  deleteRef,
}) => {
  return (
    <>
      <div className="data-table">
        <TableHead head={body} />
        {data &&
          data.map((item, idx) => (
            <div className="data-table-body row align-items-center" key={idx}>
              {body.map((tb, indx) => {
                if (tb.name !== "settings") {
                  return (
                    <div
                      className={`data-table-body-item col-${
                        typeof tb.col === "number" ? tb.col : "auto"
                      }${tb.className ? " " + tb.className : ""}`}
                      key={indx}>
                      {tb.custom ? tb.custom(item) : item[tb.name] || idx + 1}
                    </div>
                  );
                } else {
                  return (
                    <div
                      className={`data-table-body-item col-${
                        typeof tb.col === "number" ? tb.col : "auto"
                      }${tb.className ? " " + tb.className : ""}`}
                      key={"idx"}>
                      <div className="data-table-body-item-btns row">
                        <button
                          className="btn  col-6 "
                          data-id={item._id}
                          type="submit"
                          onClick={() => {
                            // setSelectedComponent(item);

                            if (updateRef === null || updateRef === undefined)
                              return setUpdateModalState(!updateModalState);
                            updateRef.current.select(item);
                            updateRef.current.toggle();
                          }}>
                          <img src="/edit.png" alt="" />
                        </button>
                        <button
                          className="btn  col-6"
                          data-id={item._id}
                          type="button"
                          onClick={() => {
                            setSelectedComponent(item);

                            if (deleteRef === null || deleteRef === undefined)
                              return setDeleteModalState(!deleteModalsate);
                            deleteRef.current.select(item);
                            deleteRef.current.toggle();
                          }}>
                          <img src="/trash.png" alt="" />
                        </button>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          ))}
      </div>
    </>
  );
};

// delete forms components

const DeleteFormButtons = ({ confirmBtn, handleDelete, setState, state }) => {
  return (
    <div className="form-group row justify-content-between align-items-center">
      <div className="col">
        <button
          className="close-btn"
          type="button"
          onClick={() => setState(!state)}>
          {confirmBtn ? "الغاء" : "اغلاق"}
        </button>
      </div>
      <div className="col d-flex justify-content-end">
        {confirmBtn && (
          <button className="add-btn" type="button" onClick={handleDelete}>
            نعم
          </button>
        )}
      </div>
    </div>
  );
};

const TableHeader = ({
  title,
  setCreateModalState,
  createModalState,
  component,
  buttons,
}) => {
  // console.log(buttons);
  return (
    <>
      <header
        data-component={component || ""}
        className="d-flex justify-content-between align-items-center fhd">
        <div className="isc">
          <input
            type="search"
            name="search"
            placeholder="البحث في القائمة"
            className="search-box "
            id=""
          />
          <i className="fa-solid fa-magnifying-glass"></i>
        </div>
        <div className="adc">
          {/* <button
            className="btn-color text-white add-btn"
            onClick={() => setCreateModalState(!createModalState)}
          >
            <i className="fa fa-plus" aria-hidden="true"></i>
            &nbsp; {title}
          </button> */}

          {buttons === undefined || buttons === null ? (
            <button
              className="btn-color text-white add-btn"
              onClick={() => setCreateModalState(!createModalState)}>
              <i className="fa fa-plus" aria-hidden="true"></i>
              &nbsp; {title}
            </button>
          ) : (
            buttons.map((btn, index) => {
              return (
                <button
                  key={index}
                  className="btn-color mx-1 text-white add-btn"
                  onClick={btn.onclick}
                  disabled={btn.disabled}>
                  <i className="fa fa-plus" aria-hidden="true"></i>
                  &nbsp; {btn.title}
                </button>
              );
            })
          )}
        </div>
      </header>
    </>
  );
};

export { TableData, TableHead, DeleteFormButtons, TableHeader };
