import React, { useEffect, useRef } from 'react'
import useStock from '../useStock'
import QuickView from '../../../components/QuickView'
import PageMeta from '../../../components/PageMeta'
import { TableHeader } from '../../../components/TableView'
import { Table } from '../../../layout/table'
import CreateTransfer from '../forms/CreateTransfer'
import { useParams } from 'react-router-dom'

export default function DrugsTab() {
  const { id } = useParams()
  const createRef = useRef()
  const {  Alert  ,fetchQuantities , setRefresh  , table , quantities , quantitiesTable } =  useStock()

  useEffect(() => {
    fetchQuantities({ category :  "drug", location_id : id, location :  "stock" })
  } , [])
  return (
    <>
         {/* <QuickView ref={createRef}  className="overflown-popup"><CreateTransfer type="drugs" header="ادويه " setRefresh={setRefresh} /></QuickView> */}
          <div className='page base-card'>
            <Alert />
            <PageMeta title=" الادويه " />
            <TableHeader 
            // buttons={[{ title : "إضافة حركه الادوية جديده" , onclick : () => createRef.current.toggle() }]}
             />
             <Table data={quantities?.totals_by_category_and_subcategory} table={quantitiesTable} />

        </div>
    </>
  )
}
