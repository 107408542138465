import {Signboard, useFetch} from "../../config/imports";
import {apis} from "../../config/apis";
import {Table} from "../../layout/table";

export default function FarmProductionByWorkYears(props){

    const {data , error , isLoading} = useFetch(apis.stats.farmByYears())

    const signboard = { error , loading : isLoading}

    const table = [
        // { name : '#' , size : 1  , text : '#'},
        { name : 'year' , size : 2  , text : 'العام'},
        { name : 'all_feed_reports_amount' , size : 3  , text : 'استهلاك العلف ' },
        { name : 'all_feed_invs_amount' , size : 4  , text : ' كمية فواتير الاعلاف'},
        { name : 'feed_inv_count' , size : 4  , text : 'قيمة فواتير الأعلاف'},
        { name : 'all_dead_birds' , size : 2  , text : 'النافق'},
        { name : 'totaled_birds' , size : 2 , text : 'التراكمي'},
        { name : 'egg_balance' , size : 2  , text : 'البيض المنتج' , custom : item => item?.egg_balance?.value ?? 0 },
        { name : 'production_balance' , size : 2  , text : 'رصيد الانتاج' , custom : item => item?.production_balance.value ?? 0 },
        { name : 'productivity' , size : 2 , text : 'نسبة الانتاج' , custom : item => item?.productivity ? item?.productivity.toFixed(2).toString() + '%' :  '0%' },
        // { name : '' , size : 1  , text : ''}
    ]


    return(
        <div className={'bg-white'}>
            <Signboard {...signboard} />
            <Table table={table} data={data} />

        </div>
    )
}

