import {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {_date, Grid, Icons, PageMeta, QuickView, Signboard, useFetch} from "../../../../../config/imports";
import ReportsCrud from "../../../../home_details/reports/reports_crud";
import TotalReportsCrud from "./reports_crud";
import {reports_api} from "../../../../../config/apis";

export default function Feed(props){

    const {id} = useParams();


    const createRef = useRef(),
        updateRef = useRef()

    const {data : reports , isLoading : loadingReports , error : reportsError , setData : setReports } = useFetch(reports_api.department.dead.read(id))


    const signboard = {
        loading : loadingReports,
        error : reportsError,
    }

    const sizes = {
        serial: 1,
        date: 4,
        createdAt: 8,
        updatedAt: 8,
        settings: 3
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}></Grid.Span>,
        date: <Grid.Span size={sizes.date}> التاريخ </Grid.Span>,
        createdAt: <Grid.Span size={sizes.createdAt}> تاريخ الاضافه </Grid.Span>,
        updatedAt: <Grid.Span size={sizes.updatedAt}> تاريخ اخر تعديل </Grid.Span>,
        settings: <Grid.Span size={sizes.settings}> </Grid.Span>,
    }

    return(
        <>
            <PageMeta title={'تقارير النافق الشامله'} />
            <Signboard {...signboard} />

            <Grid.Flex className={'align-items-center justify-content-between'}>
                <div className="isc col-12 col-sm-12 col-lg-4">
                    <input
                        type="search"
                        name="search"
                        placeholder="البحث في القائمة"
                        className="search-box "
                        id=""
                    />
                    <i className="fa-solid fa-magnifying-glass"></i>
                </div>
                <button className="add-btn" onClick={ ()=> createRef.current.toggle() }> + اضافة تقرير الناقق الشامل </button>
            </Grid.Flex>

            <QuickView ref={createRef}><TotalReportsCrud.Dead.Create   setMainData={setReports}/></QuickView>
            <QuickView ref={updateRef}><TotalReportsCrud.Dead.Update   setMainData={setReports}/></QuickView>

            <Grid.FlexResponsive className={'align-items-center data-table-head mt-0'}>
                <Grid.Span size={sizes.serial}>#</Grid.Span>
                <Grid.Span size={sizes.date}> التاريخ  </Grid.Span>
                <Grid.Span size={sizes.createdAt}>   تاريخ الاضافه  </Grid.Span>
                <Grid.Span size={sizes.updatedAt}>  تاريخ اخر تعديل   </Grid.Span>
                <Grid.Span size={sizes.settings}> </Grid.Span>
            </Grid.FlexResponsive>

            {
                reports &&  reports.length > 0  && reports.map((item , index) => (
                    <Grid.FlexResponsive  key={item._id} className={'align-items-center data-row'}>
                        <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                        <Grid.Span size={sizes.date}>{_date(item.date).dmy()}</Grid.Span>
                        <Grid.Span size={sizes.createdAt}>{ _date(item.createdAt).dmy()}</Grid.Span>
                        <Grid.Span size={sizes.updatedAt}>{ _date(item.updatedAt).dmy()}</Grid.Span>
                    </Grid.FlexResponsive>
                ))
            }
        </>
    )
}
