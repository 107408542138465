import Create from "./Create";
import Delete from "./Delete";
import Edit from "./Edit";
import EditColor from "./EditColor";

const StocksCrud = {
    Create: Create,
    Edit: Edit,
    Delete: Delete,
    Color : EditColor
}
export default StocksCrud
