import
{
    PageMeta,
    EditForm,
    InputTextArea,
    InputText,
    InputDate, useState, Signboard,
    createFormHandler,
    setInputsToDefault, useParams, InputNumber
}
    from "../../config/imports"
import {useEffect} from "react";

const ProfileCreateForms = (props) => {
const {id} = useParams()
    const {  api ,  toggle, header , type , updateMain }  = props
    const FORM_HEADER = header;

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        employee_id : id,
        name : ''  ,
        hours : '' ,
        start_date : '' ,
        end_date : '' ,
        info : '' ,
    })


    useEffect(()=>{
        if(success && success.length > 0)
            updateMain(true)
        else updateMain(false)
    } , [success])

    const handleSubmit =  async (e) => {
        setLoading(true)
        e.preventDefault()

        const create_props = {
            data ,
            setError,
            setSuccess,
            setLoading,
            FORM_HEADER,
            setInputsToDefault,
            url : api ,
            setData,
        }

        // console.log(create_props.data)
        await createFormHandler(create_props)

    }
    const handleChange = e => {
        const {name , value } = e.target
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    return (
        <>
            <PageMeta title={FORM_HEADER} originTitle={FORM_HEADER}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <div className="my-3">
                <EditForm onSubmit={handleSubmit} onChange={handleChange} toggle={toggle}  visible={true}>
                    { type === 'vacation' ? <InputText  name={"name"}  className={"col-12"} title={props.title} /> :  <InputText  name={"name"}  className={"col-6"} title={props.title} />}
                    {type !== 'vacation' ?    <InputNumber  name={"hours"} className={"col-6"}  title={'عدد الساعات'} /> : <></>  }
                    <InputDate  name={"start_date"} className={"col-6"} title={"تاريخ البدئ"} />
                    <InputDate  name={"end_date"} className={"col-6"} title={"تاريخ الانتهاء"} />
                    <InputTextArea  name={"info"} className={"col-12"} title={"معلومات اضافية"} />
                </EditForm>
            </div>

        </>
    );
}

export default ProfileCreateForms
