// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formated-input-date {
  margin-top: 10px !important;
}
.formated-input-date input[type=date] {
  position: relative;
  height: 38px;
}
.formated-input-date input[type=date]:before {
  position: absolute;
  top: 3px;
  left: 3px;
  content: attr(data-date);
  display: inline-block;
  color: black;
}
.formated-input-date input[type=date]::-webkit-datetime-edit, .formated-input-date input[type=date]::-webkit-inner-spin-button, .formated-input-date input[type=date]::-webkit-clear-button {
  display: none;
}
.formated-input-date input[type=date]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 7px;
  right: 10px;
  color: black;
  opacity: 1;
}
.formated-input-date input[type=date] {
  position: relative;
  display: block;
}
.formated-input-date input[type=date]::after {
  content: attr(data-formatted-date);
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(0%, -50%);
  display: inline-block;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/styles/inputDate.styles.scss"],"names":[],"mappings":"AAAA;EA4BI,2BAAA;AA1BJ;AAAA;EACI,kBAAA;EAEA,YAAA;AACJ;AAGE;EACE,kBAAA;EACA,QAAA;EAAU,SAAA;EACV,wBAAA;EACA,qBAAA;EACA,YAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AAIE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;AAFJ;AAOI;EAGE,kBAAA;EACA,cAAA;AAPN;AAQM;EACE,kCAAA;EACA,kBAAA;EACA,QAAA;EAAU,SAAA;EAAW,8BAAA;EAErB,qBAAA;EACA,YAAA;AALR","sourcesContent":[".formated-input-date{\n\ninput[type='date'] {\n    position: relative;\n    // width: 150px; \n    height: 38px;\n    // color: white;\n  }\n  \n  input[type='date']:before {\n    position: absolute;\n    top: 3px; left: 3px;\n    content: attr(data-date);\n    display: inline-block;\n    color: black;\n  }\n  \n  input[type='date']::-webkit-datetime-edit, input[type='date']::-webkit-inner-spin-button, input[type='date']::-webkit-clear-button {\n    display: none;\n  }\n  \n  input[type='date']::-webkit-calendar-picker-indicator {\n    position: absolute;\n    top: 7px;\n    right: 10px;\n    color: black;\n    opacity: 1;\n  }\n    margin-top: 10px !important;\n    \n    // background-color: #fff000;\n    input[type='date'] {\n      // background-color: #fff000;\n  \n      position: relative;\n      display: block;\n      &::after{\n        content: attr(data-formatted-date);\n        position: absolute;\n        top: 50%; right: 5%; transform: translate(0%, -50%);\n        \n        display: inline-block;\n        color: black;\n  \n      }\n    }\n  \n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
