import FormInput from "./base/FormInput";
import Label from "./base/Label";

export default function InputTextarea(props){
    const {name ,  className , title , placeholder , id , handleInputChange , value , defaultValue , required , disabled , readOnly} = props
    // <FormInput {...props} type={'textarea'} className={ className ?? 'col-12' } />
    return <div className={className ?? "col-12 my-2"}>

        {/* <label htmlFor="{id ?? name ?? title}"> {title ?? placeholder ?? id} </label> */}
        <Label {...props} />

        <textarea
                        name={name ?? id ?? title}
                        className={` form-control mt-2`}
                        title={title ?? placeholder ?? id}
                        id={id ?? name ?? title}
                        placeholder={placeholder ?? title ?? id ?? name}
                        onChange={handleInputChange}
                        rows={4}
                        defaultValue={defaultValue}
                        value={value }
                        readOnly={readOnly ?? false}
                        disabled={disabled ?? false}
        ></textarea>
    </div>
}
