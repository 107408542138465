import {createFormHandler, EditForm, InputText, Signboard , PageMeta, InputSelect, InputNumber, updateFormHandler} from "../../../../../config/imports";
import {apis} from "../../../../../config/apis";
import {useState} from "react";
import DisplayUploadedImage from '../../../../../components/images/DisplayUploadedImage';
import DisplayUploadedImagesGroup from '../../../../../components/images/DisplayUploadedImagesGroup';
import ArrayHelpers from "../../../../../helpers/array.helpers";
import origin_cards_tables from "../../assests_forms";
import Browser from "../../../../../layout/browser/Browser";
import Inputs from "../../../../../components/form/inputs/inputs.exporter";
import Helpers from "../../../../../helpers/helpers";
export default function Update (props){

    const {setMainData, toggle , item , asset} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [data , setData] = useState({...item})

    let signboard = { error  , success, loading  }

    const calculatedValues = ()=>{
        return {
            home_area : +(data.home_width * data.home_height) ?? 0,
            cages_count : Helpers.validateNumber((data.birds_count / data.birds_per_cage)) ?? 0,
            cages_per_battery : Helpers.validateNumber(data.cages_count / data.battery_line_count) ?? 0,
            lines_per_battery : Helpers.validateNumber(data.cages_per_battery / data.battery_line_count) ?? 0,
            battery_side_number : Helpers.validateNumber(data.lines_per_battery / 2) ?? 0,
            cage_area_cm : Helpers.validateNumber(data.cage_width_cm * data.cage_height_cm) ?? 0
        }
    }


    const handleInputChange = (e) => {

        const { name , value , type } = e.target;

        data[name] = type  === 'number' ? +value : value
        setData({...data})
        
        // second call to get the current values and pass it to the calculate method
       
        setData({
            ...data,
            ...calculatedValues()
        })
      

    
    };

            



    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.assets.asset(asset).update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };



    
    const browserData = [
        {
            tab  : { title : "بيانات البيت" , size : 4},
            content :<>
                    <Inputs.Text   name="name" handleInputChange={handleInputChange} title="أسم وكود البيت" placeholder="أسم وكود البيت" value={data.name} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="side_height" handleInputChange={handleInputChange} title="ارتفاع الجوانب" placeholder="0" value={data.side_height} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="center_height" handleInputChange={handleInputChange} title="أرتفاع السنتر" placeholder="0" value={data.center_height} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="home_height" handleInputChange={handleInputChange} title="طول البيت" placeholder="0" value={data.home_height} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="home_width" handleInputChange={handleInputChange} title="عرض البيت" placeholder="0" value={data.home_width} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="home_area"  handleInputChange={handleInputChange} title="مساحة البيت" placeholder="0" value={data.home_area} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="collars_lines_count" handleInputChange={handleInputChange} title="عدد خطوط الكولارات" placeholder="0" value={data.collars_lines_count} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="collars_height" handleInputChange={handleInputChange} title="طول الكولارات" placeholder="0" value={data.collars_height} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="collars_width" handleInputChange={handleInputChange} title="عرض الكولات" placeholder="0" value={data.collars_width} className="col-12 col-md-4 col-lg-4" /> 
            </>
        },
        {
            tab  : { title : "  بيانات الطيور " , size : 4},
            content :<>
                <Inputs.Number name="birds_count" handleInputChange={handleInputChange} title="عدد الطيور" placeholder="0" value={data?.birds_count} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number name="birds_per_cage" handleInputChange={handleInputChange} title="عدد الطيور في القفص" placeholder="0" value={data?.birds_per_cage} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number name="cages_count"  title="عدد الأقفاص" placeholder="0" value={data?.cages_count} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Text   name="battery_type" handleInputChange={handleInputChange} title="نوع البطاريات" value={data?.battery_type} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number name="batteries_count" handleInputChange={handleInputChange} title="عدد البطاريات" placeholder="0" value={data?.batteries_count} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number name="battery_line_count" handleInputChange={handleInputChange} title="عدد صفوف البطارية" placeholder="0" value={data?.battery_line_count} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number name="cages_per_battery"  title="عدد الاقفاص في البطارية" placeholder="0" value={data?.cages_per_battery} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number name="lines_per_battery"  title="عدد الصف في البطارية" placeholder="0" value={data?.lines_per_battery} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number name="battery_side_number"  title="عدد الجنب البطارية" placeholder="0" value={data?.battery_side_number} className="col-12 col-md-4 col-lg-4" />
            </>
        },
        {
            tab  : { title : " بيانات القفص " , size : 4},
            content :<>
                    <Inputs.Number name="cage_length_cm" handleInputChange={handleInputChange} title="طول القفص سم" placeholder="0" value={data?.cage_length_cm} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="cage_width_cm" handleInputChange={handleInputChange} title="عرض القفص سم" placeholder="0" value={data?.cage_width_cm} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="cage_height_cm" handleInputChange={handleInputChange} title="أرتفاع القفص سم" placeholder="0" value={data?.cage_height_cm} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="cage_area_cm" handleInputChange={handleInputChange} title="مساحة القفص سم" placeholder="0" value={data?.cage_area_cm} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="water_tanks_count" handleInputChange={handleInputChange} title="عدد خزانات الماء" placeholder="0" value={data?.water_tanks_count} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="water_tanks_capacity" handleInputChange={handleInputChange} title="سعة خزانات الماء" placeholder="0" value={data?.water_tanks_capacity} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="drug_tanks_count" handleInputChange={handleInputChange} title="عدد خزانات الدواء" placeholder="0" value={data?.drug_tanks_count} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="drug_tanks_capacity" handleInputChange={handleInputChange} title="سعة خزانات الدواء" placeholder="0" value={data?.drug_tanks_capacity} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="watering_tanks_count" handleInputChange={handleInputChange} title="عدد خزانات المشارب" placeholder="0" value={data?.watering_tanks_count} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="watering_tanks_per_cage" handleInputChange={handleInputChange} title="عدد المشارب في القفص" placeholder="0" value={data?.watering_tanks_per_cage} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="highest_birds_count" handleInputChange={handleInputChange} title="عدد الطيور الأعلى" placeholder="0" value={data?.highest_birds_count} className="col-12 col-md-4 col-lg-4" />
                    <Inputs.Number name="ideal_birds_count" handleInputChange={handleInputChange} title="عدد الطيور المثالي" placeholder="0" value={data?.ideal_birds_count} className="col-12 col-md-4 col-lg-4" />
            </>
        },
        {
            tab  : { title : " بيانات السبله " , size : 4},
            content :<>
                 
                <Inputs.Number  name="sepal_lines_count" handleInputChange={handleInputChange} title="عدد سور السبلة" placeholder="0" value={data?.sepal_lines_count} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number  name="sepal_lines_length_cm" handleInputChange={handleInputChange} title="طول سير السبلة سم" placeholder="0" value={data?.sepal_lines_length_cm} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number  name="sepal_lines_width_cm" handleInputChange={handleInputChange} title="عرض سير السبلة سم" placeholder="0" value={data?.sepal_lines_width_cm} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Text  name="eggs_collection_line_type" handleInputChange={handleInputChange} title="نوع سير جمع البيض" value={data?.eggs_collection_line_type} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number  name="eggs_collection_line_length" handleInputChange={handleInputChange} title="طول سير جمع البيض" placeholder="0" value={data?.eggs_collection_line_length} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number  name="eggs_collection_line_width" handleInputChange={handleInputChange} title="عرض سير جمع البيض" placeholder="0" value={data?.eggs_collection_line_width} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number  name="sepal_pull_dynamo_count" handleInputChange={handleInputChange} title="عدد دينمو سحب السبلة" placeholder="0" value={data?.sepal_pull_dynamo_count} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Text  name="sepal_pull_dynamo_powerline" handleInputChange={handleInputChange} title="خط كهرباء دينمو سحب السبلة" value={data?.sepal_pull_dynamo_powerline} className="col-12 col-md-4 col-lg-4" />
                <Inputs.Number  name="sepal_pull_dynamo_torque" handleInputChange={handleInputChange} title="قوة عزم دينمو سحب السبلة" placeholder="0" value={data?.sepal_pull_dynamo_torque} className="col-12 col-md-4 col-lg-4" />

            </>
        },
        {
            tab  : { title : " بيانات لوحة التحكم " , size : 4},
            content :<>
                    <Inputs.Number name="environment_control_panel_count" handleInputChange={handleInputChange}  title="عدد لوحة التحكم في البيئة" placeholder="0" value={data?.environment_control_panel_count} className="col-3" />
                    <Inputs.Number name="feed_control_panel_count" handleInputChange={handleInputChange}  title="عدد لوحة التحكم في العلف" placeholder="0" value={data?.feed_control_panel_count} className="col-3" />
                    <Inputs.Number name="feed_read_control_panel_count" handleInputChange={handleInputChange}  title="عدد لوحة قراءة الاعلاف" placeholder="0" value={data?.feed_read_control_panel_count} className="col-3" />
                    <Inputs.Number name="power_control_panel_count" handleInputChange={handleInputChange}  title="عدد لوحة تحكم الكهرباء" placeholder="0" value={data?.power_control_panel_count} className="col-3" />

            </>
        },
        {
            tab  : { title : " قيمة البيت و الحساب " , size : 4},
            content :<>
                    <Inputs.Number name="value" handleInputChange={handleInputChange}  title="قيمة البيت" placeholder="0" value={data?.value} className="col-6" />
                    <Inputs.Number name="value_of_tools_and_equipments" handleInputChange={handleInputChange}  title="قيمة الأدوات والمعدات" placeholder="0" value={data?.value_of_tools_and_equipments} className="col-6" />
                    <Inputs.Text name="account_name" handleInputChange={handleInputChange}  title="أسم الحساب" value={data?.account_name} className="col-12 col-md-6 col-lg-6" />
                    <Inputs.Text name="account_symbol" handleInputChange={handleInputChange}  title="رمز الحساب" value={data?.account_symbol} className="col-12 col-md-6 col-lg-6" />
            </>
        },
       
    ]



    return(
        <>
    
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3> تعديل بيانات البيت</h3>
        </header>

        <Signboard {...signboard}/>

        <EditForm
            visible={false}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}
        >
                <Browser data={browserData} toggle={toggle} />
                    

        </EditForm>



        </>
    )
}
