import "../styles/boxs.styles.scss"
const BlueBox = (props) => {
    const { img , title , value} = props
    return (
        <div className="blue-box row">
            <div className="col-12 blue-box-content box-content text-center">
                <p>{title}</p>
                <p>{value}</p>
            </div>
            {img && <img src={img} alt=""/>}
        </div>
    )
}

export default BlueBox