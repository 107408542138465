import { useEffect, useState } from "react";
import { accio } from "../helpers/axios/axios.get";
import { uuid } from "../helpers/id.helper";
import { getRefreshStore, setRefreshStore } from "../stores/refresh.store";

export default function useFetcher(api) {
  const [loading , setLoading] = useState(false);
  const [data , setData] = useState(null);
  const [error , setError] = useState(null);
  const [refresh , setRefresh] = useState(null);
  const [paginate , setPaginate] = useState({});



  const controller = new AbortController();
  const signal = controller.signal;


  const fetch = async () => {
      setLoading(true);
      setError(null);
       const response = await accio(api , {}  , signal );
       setLoading(false);

      if(response.error) {
        setError(response.error);
        return
      }


       setData(response.data);

       setPaginate(response.paginate);

       
       return response
  }



  useEffect(() => {

    fetch();

    return () => controller.abort();

  }, [api]);




  useEffect(() => {
    if(refresh)
      fetch();


    return () => controller.abort();


  } , [refresh]);



  useEffect(() => {

    if(getRefreshStore())
        fetch();


  } , [getRefreshStore()]);





  return {
     data,
     loading,
     error,
     paginate,
     refresh,
     setRefresh
  }

  

}


