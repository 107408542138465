import React from 'react'
import useSelectInvoiceStore from '../stores/select-invoice.store'
import { projectTypesOptions, typeOptions, whereToEnterOptions } from '../data/invoice-selectors.data'
import InputStockInvoices from './InputStockInvoices'
import InputProjectInvoices from './InputProjectInvoices'
import InputDepartmentInvoices from './InputDepartmentInvoices'
import { InputSelect } from '../../../config/imports'

export default function BuyInvoiceSelectors() {



  const setWhereToEnter = useSelectInvoiceStore(state => state.setWhereToEnter)
  const whereToEnter = useSelectInvoiceStore(state => state.whereToEnter)


  const setType = useSelectInvoiceStore(state => state.setType)
  const type = useSelectInvoiceStore(state => state.type)



  return (
    <>
    <div className="row g-0">
      <div className="col-6">
        <InputSelect
          title={"مكان ادخال الفاتورة"}
          className={"col-12"}
          defaultValue={whereToEnter}
          handleInputChange={(e) => setWhereToEnter(e.target.value)}
          name="whereToEnter"
          options={whereToEnterOptions}
        />
      </div>
      <div className="col-6">
        <InputSelect
          title={"نوع الفاتورة"}
          className={"col-12"}
          handleInputChange={(e) => setType(e.target.value)}
          name="type"
          defaultValue={type}
          options={
            whereToEnter === "stock" ? typeOptions : projectTypesOptions
          }
        />
      </div>
    </div>
    {whereToEnter === "stock" ? (
      <InputStockInvoices />
    ) : (
      <div className="row g-0">
        <div className="col-6">
          <InputProjectInvoices />
        </div>
        <div className="col-6">
          <InputDepartmentInvoices />
        </div>
      </div>
    )}
  </>
  )
}
