import {useState} from "react";
import {apis} from "../../../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../../../helpers/form.helper";

import {
    EditForm,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";
import postData from "../../../../helpers/axios/axios.post";
import {originAPI} from "../../origin.helpers";
import {errorHandler} from "../../../../helpers/error.helper";
import DisplayFileImagesGroup from "../../../../components/images/DisplayFileImagesGroup";

export default function Create(props){

    const {setMainData, toggle , operators} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name: '',
        types: '',
        operator: '',
        img : '',
        info: '',
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const {
            data: postedData,
            error,
            statusCode,
        } = await postData(originAPI.maintenance, data  , {
            headers :{
                'Content-Type': 'multipart/form-data',
            }
        });
        if (error) {
            setLoading(false);
            setSuccess("");
            return setError(errorHandler(error));
        }
        if (statusCode === 201 && postedData) {
            setLoading(false);
            setSuccess("تم اضافة الصيانة بنجاح");
            setMainData((prevState) => [...prevState, postedData]);
            setInputsToDefault();
            setData({
                name: '',
                types: '',
                operator: '',
                img : '',
                info: '',
            });
        }





    };
    return(
        <>
            <PageMeta title={' اضافة نوع صيانه جديده  '} originTitle={' الادوية'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة نوع صيانه جديده</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>


              <>
                    <div className="text-center">
                        <div className="w-fit m-auto col-3">
                                <DisplayFileImagesGroup files={[data.img]} setData={setData} keyNAme={'img'} />
                        </div>
                        <div data-error="" className="form-group">
                                <label   className='choose-img-btn'  htmlFor="img">
                                    <span>   اختر صورة </span><i className="fa-solid fa-bold fa-upload"></i>
                                </label>
                                <input
                                    type="file"
                                    name="img"
                                    id="img"
                                    required
                                    placeholder="اختر صورة"
                                    accept="image/*"
                                    onChange={(e)=> setData({...data , img : e.target.files[0]})}
                                    className="hide-arrow form-control d-none"
                                />
                        </div>
                    </div>
              </>
                

                <InputText className={'col-6'} name={'name'} title={'الاسم'} value={data.value} />
                <InputSelect className={'col-3'} name={'operator'} title={'المورد'} value={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />
                <InputSelect  className={'col-3'} name={'types'} title={'النوع'} value={data.types}  options={[
                    {_id : "تركي" , name : "تركي" },
                ]}
                />

                <InputTextArea name={'info'} title={'التفاصيل'} value={data.info} />


            </EditForm>

        </>
    )
}
