import { useState, useEffect, useRef } from "react";
import aPost from "../helpers/axios/axios.post";

const useFetch = (url, payload) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  // Create refs to store previous values of url and payload
  const prevUrl = useRef(null);
  const prevPayload = useRef(null);

  useEffect(() => {
    // Check if url and payload have changed
    if (url === prevUrl.current && payload === prevPayload.current) return;

    // Update refs with current values
    prevUrl.current = url;
    prevPayload.current = payload;

    if (!url || !payload) return;

    const controller = new AbortController();
    const signal = controller.signal;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const { data, error, statusCode } = await aPost(url, payload, { signal });
        if (error) {
          setIsLoading(false);
          setData(null);
          throw new Error(error);
        }
        setData(data);
        setError(null);
      } catch (error) {
        if (error.name !== 'AbortError') {
          setData(null);
          setError(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, [url, payload]);

  return { data, isLoading, error };
};

export const usePostFetch = useFetch;

export default useFetch;
