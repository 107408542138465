import React, { useEffect, useMemo, useRef , useState } from 'react'
import { useParams } from 'react-router-dom'
import useStock from '../../stocks/useStock'
import PageMeta from '../../../components/PageMeta'
import { Table } from '../../../layout/table'
import Helpers from '../../../helpers/helpers'
import QuickView from '../../../components/QuickView'
import CreateHomeTransfer from './CreateHomeTransfer'
import useUnits from '../../../hooks/useUnits'
import { _date } from '../../../helpers/date.helpers'
import HomeMovementDetails from './HomeMovementDetails'
import Accordion from '../../../components/ui/accordion/Accordion'
import useHomeBreadCrumbs from '../hooks/useHomeBreadCrumbs'

export default function HomeTransfers() {
    const {id} = useParams()
    const detailsRef = useRef()
    const feedRef = useRef()
    const drugRef = useRef()
    const maintenanceRef = useRef()
    const otherRef = useRef()
    const cartonRef = useRef()
    const dishRef = useRef()
  
    const refEnum = {
      feed : feedRef,
      drug : drugRef,
      maintenance : maintenanceRef,
      other : otherRef,
      carton : cartonRef,
      dish : dishRef,
    }

  const { getUnitName }  = useUnits()


  useHomeBreadCrumbs()


  const { transfers , Alert  , CategoriesFilter  , categories , fetchQuantities , quantities , setRefresh , reFetch   }  = useStock(
        {
            location : "home",
            location_id : id,
            category : "",
        }
    ) 


    useEffect(() => {
        fetchQuantities({ category : "", location_id : id, location : "home" })
    }, [reFetch])


    const table = [
      { name : "#"  , text : "" , size : 1  },
      { name : "ar_type" , text : "نوع الحركه" , size : 2 },
      { name : "from" , text : "من" , size : 4 },
      { name : "to" , text : "الي" , size : 3 },
      { name : "category" , text : "الصنف" , size : 2 , custom : d => categories[d?.category] },
      { name : "sub_category" , text : "الفئة" , size : 2 },
      { name : "quantity" , text : "الكمية" , size : 2 },
      { name : "unit" , text : "الوحده" , size : 2  , custom : d => getUnitName(d?.unit)  },
      { name : "date" , text : "التاريخ" , size : 3 , custom :d =>  <span style={{ fontSize : "13px"}}> { _date(d?.date).dmy()} </span>  },
      { name : "movements" , text : "تفاصيل" , size : 2  , custom : d => {
        return <button onClick={() => detailsRef.current?.toggle(d)}><i className="fa fa-eye " /></button>
      }},
   ]

  const blueBoxes = useMemo(() => {
    
    return quantities?.boxes?.map(q => {
      return <div key={q.category} className='col-2 '>
              <div className=" d-flex align-items-center justify-content-center flex-column   r-16 text-white " style={{ backgroundColor : "#40A9F4" }} >
                  <div className="py-2 text-center ">
                    <p className='m-0 text-white ' >{q.title}</p>
                    <p className='m-0 text-white ' >{ Helpers.Number.commaFormat(q.quantity.toFixed(2))  }</p>
                  </div>
                  <div className="w-100 " style={{ backgroundColor : "#ffbb57" , borderRadius : "0 0 16px 16px" }}   >
                    <button onClick={ ()=> refEnum[q.category].current?.toggle()  }   className='p-2 px-3 no-button text-white text-center w-100' >  تحويل كمية  </button>
                  </div>
              </div>
      </div>
    })
})



  return (

    <>

    <div className="row blue-box-container justify-content-center my-3">
         { blueBoxes }
    </div>
    <QuickView ref={detailsRef}  className="overflown-popup"><HomeMovementDetails  categories={categories} /></QuickView>
    <QuickView ref={feedRef}  className="overflown-popup"><CreateHomeTransfer header="اعلاف" type="feed" setRefresh={setRefresh} /></QuickView>
    <QuickView ref={drugRef}  className="overflown-popup"><CreateHomeTransfer type="drug" header="ادويه " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={maintenanceRef}  className="overflown-popup"><CreateHomeTransfer type="maintenance" header="صيانه " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={dishRef}  className="overflown-popup"><CreateHomeTransfer type="dish" header="اطباق " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={cartonRef}  className="overflown-popup"><CreateHomeTransfer type="carton" header="كراتين " setRefresh={setRefresh} /></QuickView>
    <QuickView ref={otherRef}  className="overflown-popup"><CreateHomeTransfer type="other" header="اخرى " setRefresh={setRefresh} /></QuickView>
    <div className='page'>  
        <div className="row align-items-center">
            <div className="col-9">
                <h3>  تحركات البيت  </h3>
            </div>
            <div className="col-3">
                <CategoriesFilter />
            </div>
        </div>
        <PageMeta title="التحويلات" />
        <Alert />
        <Table data={transfers} table={table} />
    </div>
    </>
  )
}
