import React, { useMemo, useRef } from 'react'
import { useParams } from 'react-router-dom'
import useUnits from '../../hooks/useUnits'
import { apis } from '../../config/apis'
import useFetch from '../../hooks/useFetch'
import OtherApprovalDetails from './pages/OtherApprovalDetails'
import DeadApprovalDetails from './pages/DeadApprovalDetails'
import EggsApprovalDetails from './pages/EggsApprovalDetails'
import { paths } from '../../config/configs.env'
import QuickView from '../../components/QuickView'
import PreviewReportImage from './components/PreviewReportImage'
import { BsFiletypePdf } from 'react-icons/bs'



export default function ApprovalDetails() {


  const { id } = useParams()

  const { getUnitName } = useUnits()
  
  const previewRef = useRef()

  const { data , Alert , setRefresh , isLoading } = useFetch(apis.approvals.details(id))



  const reportType = useMemo(() => {
    return data && data.details?.length > 0 && data?.details[0]?.en_type
  }, [data])



  const files = useMemo(() => {
    return data && data?.details?.length > 0 && data?.details[0]?.files
  }, [data])



  const reportsEnum = {
    deadDailyReport :     <DeadApprovalDetails  data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />,
    eggDailyReport :      <EggsApprovalDetails  data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />,
    cartonDailyReport :   <OtherApprovalDetails data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />,
    feedDailyReport :     <OtherApprovalDetails data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />,
    dishDailyReport :     <OtherApprovalDetails data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />,
    drugDailyReport :     <OtherApprovalDetails data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />,
    otherDailyReport :    <OtherApprovalDetails data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />,
    maintenanceReport :   <OtherApprovalDetails data={data?.details} getUnitName={getUnitName} setRefresh={setRefresh} Alert={Alert}  />
  }
  


  if( isLoading ) return <Alert />

  return (
    <>
    <QuickView ref={previewRef} ><PreviewReportImage /></QuickView>
      { reportsEnum[reportType] }

      
      <div className="page">
         <h1 className='text-[20px] font-[500]'>  المرفقات  </h1>

        <div className="flex gap-4 flex-wrap">
          {
              files.map(file =>{

              if(!file.mimetype.startsWith("image")) {
                return <div key={file._id} className='w-[150px] h-[200px] p-[8px] rounded-md shadow-sm bg-[#f5f5f5] relative group'>

                  <a href={ paths.uploads + '/reports/' +  file.filename} target="_blank" rel="noreferrer" className='w-full h-full object-cover rounded-md ' download={file.filename} >
                    <BsFiletypePdf className='text-[50px] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2' />
                   </a>
                </div>
              }


              return  <div key={file._id} className='w-[150px] h-[200px] cursor-pointer  p-[8px] rounded-md shadow-sm bg-[#f5f5f5] relative group' onClick={() => previewRef.current.toggle(file)} >

                  <img src={ paths.uploads + '/reports/' +  file.filename} alt="img" className='w-full h-full object-cover rounded-md' />
                
              </div>
              })
            }
        </div>
      </div>

    </>
    
  )
}

