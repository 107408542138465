// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ilc {
  /*position: relative;*/
}
.icbc .custom-buttons-container {
  position: absolute;
  top: 90px;
  right: 20px;
  /* left: 20px; */
}

.icbc header.fhd {
  position: absolute;
  top: 20px;
  left: 50%;
  width: 95%;
  transform: translateX(-50%);
}

.icbc .data-table {
  margin-top: 160px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/base-card.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,UAAU;EACV,2BAA2B;AAC7B;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".ilc {\n  /*position: relative;*/\n}\n.icbc .custom-buttons-container {\n  position: absolute;\n  top: 90px;\n  right: 20px;\n  /* left: 20px; */\n}\n\n.icbc header.fhd {\n  position: absolute;\n  top: 20px;\n  left: 50%;\n  width: 95%;\n  transform: translateX(-50%);\n}\n\n.icbc .data-table {\n  margin-top: 160px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
