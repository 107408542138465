import React, { useState } from "react";
import { originAPI } from "../../../origin.helpers";
import remove from "../../../../../helpers/axios/axios.delete";
import Signboard from "../../../../../components/Signboard";

const DeleteFeed = ({ toggle, item, setFeedData }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(true);

  const handleDelete = async () => {
    setLoading(true);

    const ITEM_TO_DELETED = item._id;

    const { data, error, statusCode } = await remove(
      originAPI.feed + "/" + ITEM_TO_DELETED
    );

    console.log(data, error, statusCode);
    if (error) {
      setLoading(false);
      setError(error);
      setSuccess("");
      setConfirmBtn(true);
    } else if (statusCode === 202) {
      setLoading(false);
      setSuccess("تم حذف العنصر بنجاح");
      setConfirmBtn(false);
      setFeedData((prev) => {
        const newData = prev.filter((item) => item._id !== ITEM_TO_DELETED);
        return newData;
      });
    }
  };

  return (
    <div className="form-delete">
      <header className="w-fit m-auto px-4 text-center border-bottm-line ">
        <h3>هل انت متاكد من حذف هذا العنصر ؟</h3>
      </header>
      <Signboard error={error} success={success} loading={loading} />

      <div className="delete-item-body px-5 my-5 mt-3">
        <div className="data-table-head row">
          <div className="data-table-head-item col-3">الاسم</div>
          <div className="data-table-head-item col-3">المورد</div>
          <div className="data-table-head-item col-3">وحدة القياس</div>
          <div className="data-table-head-item col-3">تفاصيل اضافية</div>
        </div>

        <div className="data-table-body row">
          <div className="data-table-body-item col-3" title={item.name}>
            {item.name}
          </div>
          <div className="data-table-body-item col-3" title={"operator"}>
            {item.operator && item.operator.name}
          </div>
          <div
            className="data-table-body-item col-3"
            title={item.measuringUnit}>
            {item.measuringUnit}
          </div>
          <div className="data-table-body-item col-3" title={item.info}>
            {item.info}
          </div>
        </div>
      </div>

      <div className="col-12 col-md-12 mt-4">
        <div className="form-group row justify-content-between align-items-center">
          <div className="col">
            <button className="close-btn" onClick={() => toggle()}>
              {confirmBtn ? "الغاء" : "اغلاق"}
            </button>
          </div>
          <div className="col d-flex justify-content-end">
            {confirmBtn && (
              <button className="add-btn" onClick={handleDelete}>
                نعم
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFeed;
