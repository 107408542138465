import React, { useState } from 'react'
import PageMeta from '../../../components/PageMeta'
import Signboard from '../../../components/Signboard'
import { createFormHandler, EditForm, InputTextArea, useParams } from '../../../config/imports';
import { apis } from '../../../config/apis';
import DisplayFileImagesGroup from '../../../components/images/DisplayFileImagesGroup';

export default function ApproveReport(props) {
    const {id} = useParams()
    const {toggle , setRefresh , item} = props
    const [data, setData] = useState({
        files : []
    });

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");



    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };


    const handleFormSubmit = async (e) => {
      
        setLoading(true);
      
        e.preventDefault();

        const create_props = {
            url: apis.approvals.approve(id),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=> {},
            setRefresh,
            setData,
            setInputsToDefault : () => {},
            FORM_HEADER: `الموافقه`,
            hasFiles : true
        };

        // console.log({data : create_props.data})

        await createFormHandler(create_props);
    };


    const addFiles = (e) => {
        const { files} = e.target;
        setData((prev) => {
            return ({
                ...prev,
                files: [...prev.files , ...files]
            })
        });
    }




  return (
    <>
        
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>  الموافقه / الرفض على  التقارير </h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>

        <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
    
                <InputTextArea  name="notes" title="ملاحظات" value={data.notes}  />
                  <>
                    <div data-error="" className="form-group">
                            <label   className='choose-img-btn '  htmlFor="files">
                                <span>   ارفق صور </span>
                                <i  className="fa-solid fa-bold fa-upload"></i>
                            </label>
                            <input type="file" multiple name="files" id="files"  placeholder="اختر صورة" accept="image/*" onChange={addFiles} className="hide-arrow form-control !hidden" />
                        </div>
                        <div className="flex w-full">
                                <DisplayFileImagesGroup  files={data.files} setData={setData} keyName={'files'} />
                        </div>
                  </>
        </EditForm>

    </>
  )
}
