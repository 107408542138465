
export default function axiosAuthParams()  {
const user = JSON.parse(localStorage.getItem("user"));
const config = {}
    config.headers = {
        "Content-Type": "application/json",
        "auth": user && user.token && `Bearer ${user.token}`,
    }


return config
}