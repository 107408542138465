import { useState } from "react";

import {
    setInputsToDefault, createFormHandler,
} from "../../../../../helpers/form.helper";

import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";
import { strainAPI } from "../../../../origin/bird_breed/bird_breed.routes";

import InputText from "../../../../../components/FormControls/InputText";
import useFetch from "../../../../../hooks/useFetch";
import { operatorAPI } from "../../../../operators/operator.routes";
import InputNumber from "../../../../../components/FormControls/InputNumber";
import InputTextArea from "../../../../../components/FormControls/InputTextArea";
import SelectOperator from "../../../../../components/Shared/SelectOperator";
import EditForm from "../../../../../components/FormControls/EditForm";

const FORM_HEADER = "طلب شراء اعلاف";
const MAIN_TITLE = "قائمة شراء الاعلاف";

const UpdateOrderFeedInvoice = ({setMainData, toggle , item}) => {


    const [data, setData] = useState(item);
    const {data: ops} = useFetch(operatorAPI.url)
    let names = Object.keys(data);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // console.log(data)
        const create_props = {
            url: strainAPI.url,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER,
        };

        createFormHandler(create_props);
    };

    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setData((prevState) => ({
            ...prevState, [e.target.name]: e.target.value,
        }));
    };
    const WeightUnits = [{
        name: "طن", _id: "'طن"
    }, {
        name: "كيلو", _id: "'كيلو"
    }, {
        name: "جرام", _id: "'جرام"
    },]
    return (<div className="temp-form">
        <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
            <h3>{FORM_HEADER}</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>

        <EditForm toggle={toggle} onSubmit={handleFormSubmit} onChange={handleInputChange}>
            <InputText title={"الاسم"}   />
            <SelectOperator defaultValue={data.operator._id} />
            <InputText title={"التصنيف"}   />
            <InputText title={"نوع العلف"}   />
            <InputText title={"وحدة القياس"}   />
            <InputNumber title={"الكمية المطلوبة"} />
            <InputTextArea name={names[9]} title={"ملاحظات"}  />
        </EditForm>

    </div>);
};

export default UpdateOrderFeedInvoice;
