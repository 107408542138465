import { useCallback, useEffect, useMemo, useState } from 'react'
import useAlert from '../../../hooks/useAlert'
import axiosPost from '../../../helpers/axios/axios.post'
import { apis } from '../../../config/apis'
import { InputSelect } from '../../../config/imports'

export default function useInvoicesApi(query) {
    const {Alert , setters , states , signboard} = useAlert()
    const [data , setData] = useState(null)
    const [error , setError] = useState(null)

    const fetchForm = useCallback( async () => {
            setters.loading()
            const { error  , data} = await axiosPost(apis.invoices.form() , query)
            setters.reset()
            if(error)
             {
                setError(error)
                return setters.error( error )
             }
            setters.reset()
            setData(data)
            setError(null)
      })  



    useEffect(() => {
           if(query) 
            fetchForm(query)
    } , [])



    const ordersOptions = useMemo(() => {
        return data?.all_orders?.map( o => ({ text : o.order_number , value : o._id  }) )
    })

    const OrdersElement = (props) => {
      // const {defaultValue , handleInputChange} = props
      return  <InputSelect 
                  handleInputChange={props?.handleInputChange} name="orders" 
                  title="الطلبات" options={ordersOptions}  defaultValue={props?.defaultValue ?? []}  
                  className="col-12" multiple={true} 
       />
    } 



  
  return {
      formApis : apis,
      Alert, error , data,
      setters , 
      loading : states.loading,
      success : states.success,
      error : states.error,
      signboard,
      ordersOptions,
      OrdersElement
  }
}