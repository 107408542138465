import { paths } from "../../../config/configs.env";
import BirdBreed from "./BirdBreed";
import Breeding from "./Breeding";
import Production from "./Production";
import { Route } from "react-router-dom";

// const base

export const strainAPI = {
  url: paths.api + "/strain",
  breeding: paths.api + "/strain/breeding",
  production: paths.api + "/strain/production",
  productionAge: paths.api + "/strain/production",
  putProductionAge: paths.api + "/strain/productionage",
};


const bird_breed_routes = {
  router : (protect)=>{
    return <Route path="bird-breed" element={protect(<BirdBreed />)} >
              <Route path="breeding" element={protect(<Breeding />)} />
              <Route path="production" element={protect(<Production />)} />
           </Route>
  }
}




export default bird_breed_routes