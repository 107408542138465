import React from 'react'

export default function PageNumber(props) {
    const {pageNumber , currentPage , clickHandler} = props
 
    return (
        <div 
            className={`page-number ${currentPage == pageNumber ? 'active-page-number' : ''}`} 
            onClick={(e)=>clickHandler(e , pageNumber)}
        >
            {pageNumber}
        </div>
    )
}
