const Loading = () => {
  return (
    <div className="loading-container">
      <img
        src="/loading.gif"
        width={"100px"}
        style={{ margin: "auto", display: "block" }}
        alt="loading"
      />
    </div>
  );
};

export default Loading;
