import {Grid} from "../../config/imports";
import React, {useEffect, useState} from 'react'
import ArrayHelpers from "../../helpers/array.helpers"
const Header = (props) => {
    const {header , className, names , style} = props

    let [data , setData] = useState([])

    useEffect(()=>{
        if(header){
            setData(header)
            if(names){
                const keys = names.trim().split(" ").filter( item => item.length > 0 )
                const filteredData = getValuesByKeys(header , keys)
                setData(filteredData)
            }
        }
    },[header])


    function getValuesByKeys(obj, keyList) {
        const result = {};
        keyList.forEach(key => {
            if (key in obj)
                result[key] = obj[key];
        });
        return result;
    }


    return <Grid.FlexResponsive style={style}  className={` data-table-head mt-5 ${className ?? ''} `} >
            {
                data&&Object.values(data).length > 0 && Object.values(data).map((item, index) => {
                    return React.cloneElement(item, {key: index})
                })
            }
        </Grid.FlexResponsive>
}


const Table = { Header }

export default Table

export {
    Header
}
