import CartonCrud from "./carton";
import DeadCrud from "./dead"
import DishCrud from "./dish";
import DrugCrud from "./drugs";
import EggsCrud from "./eggs";
import FeedCrud from "./feed";
import MaintenanceCrud from "./maintenance";
import OtherCrud from "./other";

const ReportsCrud = {
    Dead : {...DeadCrud},
    Eggs : {...EggsCrud},
    Feed : {...FeedCrud},
    Drug : {...DrugCrud},
    Maintenance : {...MaintenanceCrud},
    Others : {...OtherCrud},
    Cartons : {...CartonCrud},
    Dishes : {...DishCrud},
}

export default ReportsCrud
