import InputSelect from "../../../../components/form/inputs/InputSelect";
import {InputText} from "../../../../config/imports";

const toolsAndEquipmentsInputs = [
    { name : 'name', title :  'اسم الآله', input : InputText},
    { name : 'characteristic', title :  'صفة الآله', input : InputText},
    { name :  'level' , title :  'المستوى', input :  InputSelect , options : [
        { value :  'معدات خفيقة' , text : 'معدات خفيقة' },
        { value :  'معدات ثقيلة' , text : 'معدات ثقيلة' },
        { value :  'الآلات مكتبية' , text : 'الآلات مكتبية' },
        { value :  'الآلات تجميع' , text : 'الآلات تجميع' },
]},
    { name : 'production_per_day', title :  'الطاقة الإنتاجية في اليوم', input : InputText},
    { name : 'manufacturer_country', title :  'البلد المصنع   ', input : InputText},
    { name : 'supplier', title :  'المورد للآله', input : InputText},
    { name : 'device_value', title :  'قيمة المعدة', input : InputText},
    { name : 'inv_number', title :  'رقم الفاتورة', input : InputText , size : 4},
    { name : 'account_name', title :  'اسم الحساب', input : InputText , size : 4},
    { name : 'account_symbol', title :  'رمز الحساب', input : InputText , size : 6},
    { name : 'location', title :  'موقعها', input : InputText , size : 6},
]
export default toolsAndEquipmentsInputs
