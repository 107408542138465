import React, { useEffect, useState } from 'react'
import { useSearchParams, Link } from "react-router-dom"
import EggsWarehouse from './EggsWarehouse'
import Projects from './components/Projects'
import Departments from './components/Departments'
import Homes from './components/Homes'
import useEggsWarehouseStore from './store/eggs_warehouse.store'



const DEFAULT_TAB = 'projects'


const Tabs = {
  projects : <Projects/>,
  departments : <Departments />,
  homes : <Homes />
}

const tabNames = [...Object.keys(Tabs)]


const isAValidTab = (tab) => {
    return tabNames.includes(tab)
}





export default function EggsWarehouseTabs() {


  const [searchParams ] = useSearchParams()
  const [Tab , setTab] = useState( <EggsWarehouse /> )
  const selected  = useEggsWarehouseStore(state => state.selected)


  console.log({selected})


  useEffect(() => {
    const tab = searchParams.get('tab')
    setTab(Tabs[tab])
  } , [searchParams])





  useEffect(() => {
    const _tab = searchParams.get('tab')  
    const tab = isAValidTab(_tab) ? _tab : DEFAULT_TAB
    // console.log(tab);
    const links = [...document.querySelectorAll('.tabs-container a')]
    
    links.forEach((link) => {
        link.classList.remove('active-tab')
    })

    const active = document.querySelector(`[data-tab-name='${tab}']`) 
    
    active?.classList.add('active-tab')

    } , [ searchParams ])



  useEffect(() => {
    const tab = searchParams.get('tab')
    if(isAValidTab(tab)) 
        return setTab(Tabs[tab])
    else 
        return  setTab(Tabs[DEFAULT_TAB])
  } , [])









  return (
    <>

      <div className='tabs-container'>
          <Link className={"link-tab"}  data-tab-name="projects" to="?tab=projects" > المشاريع  </Link>
          <Link className={"link-tab"}  data-tab-name="departments" to="?tab=departments" > الاقسام  </Link>
          <Link className={"link-tab"}  data-tab-name="homes" to="?tab=homes" > البيوت  </Link>
      </div>

      <div className=" my-3">
        { Tab }
      </div>


      <div className="w-full bg-white page">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Est harum enim quos ducimus eius molestiae sunt pariatur, tempora explicabo voluptatum fugiat? Esse autem magnam velit! Neque ipsam ab maiores officia cupiditate! Optio error sint molestias, in, sapiente soluta fugiat numquam illum earum, id commodi exercitationem harum dignissimos vero. Voluptatum, ratione earum! Ipsa deserunt eveniet quasi necessitatibus! Dolores voluptatum aliquid quis tempora! Incidunt, totam et! Asperiores voluptates ipsum sint ipsam eligendi, praesentium voluptatibus officia sequi eum beatae quam architecto numquam ullam, expedita magnam necessitatibus totam facilis voluptas error maxime perspiciatis id magni doloribus? Officiis voluptates eius iure exercitationem quod nesciunt quas.
      </div>

    </>
  )
}

