import React, { useCallback, useEffect } from 'react'
import ArrayHelpers from '../../helpers/array.helpers'
import PageNumber from './PageNumber'
import Icons from '../Icons'
import useFetch from '../../hooks/useFetch'
import { accio } from '../../helpers/axios/axios.get'
import Helpers from '../../helpers/helpers'
import usePaginateStore, { getStoreApi, setStorePagesCount, setStoreTotal } from '../../stores/paginate.store'
const MINIMUM_PAGES_TO_DISPLAY = 3

const  Pagination = (props) => {

  const {paginate , api , setData , setLoading , search } = props
  const pagesCount = usePaginateStore((state) => state.pagesCount)
  const perPage = usePaginateStore((state) => state.perPage)
  const total = usePaginateStore((state) => state.total)


  const currentPage = usePaginateStore((state) => state.page)

  const setCurrentPage = usePaginateStore((state) => state.setPage)


  const firstRender = usePaginateStore((state) => state.firstRender)



    
  useEffect(() => {

    setStorePagesCount(paginate?.pages)
    setStoreTotal(paginate?.total)
    
  } , [paginate] )

  
  const [pagesToBeRendered , setPagesToBeRendered] = React.useState([])

  let [state , setState ] = React.useState({ total  : 0 , total_per_page  : 0, pages  : 0, current_page  : 0 })

  let [_pages , setPages] = React.useState(ArrayHelpers.range(state.pages))


const clickHandler = (e , page) => setCurrentPage(page)


// console.log({pagesCount})

useEffect(() => {


    if(firstRender) return

      // pages count is zero 
    if(pagesCount === 0){

      
      const _state = {
        total : 0 ,
        total_per_page : 0 ,
        pages : 0 ,
        current_page :1 ,
    }

    setState(_state)

    _pages = ArrayHelpers.range(_state.pages)

    setPages(_pages)

    setLoading(false)

    setPagesToBeRendered(buttonsToBeRendered())

    }else {
        // pages count is bigger than 0 


        const _state = {
            total : total ,
            total_per_page : perPage ,
            pages : pagesCount ,
            current_page : currentPage || 1 ,
        }

        setState(_state)

        _pages = ArrayHelpers.range(_state.pages)
  
        setPages(_pages)
  
        setLoading(false)
  
  
        setPagesToBeRendered(buttonsToBeRendered())
  


    }

      // console.log("pagesCount" , pagesCount);


    
} , [pagesCount])

useEffect(() => {

    // console.log(paginate);

      if (!paginate || Object.keys(paginate).length === 0) return  

      const _state = {
          total : paginate.total ,
          total_per_page : paginate.total_per_page ,
          pages : paginate.pages ,
          current_page : currentPage || 1 ,
      }

      setState(_state)

      _pages = ArrayHelpers.range(_state.pages)

      setPages(_pages)

      setLoading(false)


      setPagesToBeRendered(buttonsToBeRendered())


  } , [paginate])


  const fetchData = useCallback(async () => {


  
      setLoading(true)
  
      const query = api + getStoreApi()

      const {data , paginate} = await accio(query)

      // console.log("data from pagination" , paginate);
  
      state = {
          total : paginate.total ,
          total_per_page : paginate.total_per_page ,
          pages : paginate.pages ,
          current_page : currentPage ,
      }
  
      setState(state)
  
      _pages = ArrayHelpers.range(state.pages)
  
      setPages(_pages)
  
      setLoading(false )
  
      if(!data) return console.log('no data to paginate')
  
      setData(data)


  } , [search , currentPage])


  
  useEffect(() => {
    if(firstRender) return
    else {
      fetchData()
  
      setPagesToBeRendered(buttonsToBeRendered())

    }
    
  } , [currentPage , search])


  useEffect(() => {
    if(firstRender) return
    else {

      setPagesToBeRendered(buttonsToBeRendered())
    }

  },[_pages])



  const buttonsToBeRendered = () => {

      const lastPage = state.pages

      const dots = "..."

      let buttons = []

      const defaultCondition = (currentPage) => {
  
        if(_pages.indexOf(currentPage) + 1 == 3) return [1  , currentPage - 1 , currentPage , currentPage + 1 , dots , lastPage] // [1 , 2, (3), 4, ..., pages_count]
        return  [1 , dots , currentPage - 1 , currentPage , currentPage + 1 , dots , lastPage]
      }

      if(_pages.length <= MINIMUM_PAGES_TO_DISPLAY )  return buttons = _pages

    
      switch(currentPage){
        case 1              : {
              // console.log("case 1");
          return  buttons = [1 , currentPage + 1  , dots , lastPage]
        }

        case 2              : {
              // console.log("case 1");
          return  buttons = [currentPage - 1 , currentPage , currentPage + 1   , dots , lastPage]
        }

        case lastPage - 2   : {
              // console.log("case 1");
          return  buttons = [1 , dots , currentPage - 1 , currentPage  , lastPage - 1 , lastPage]
        }

        case lastPage - 1   : {
              // console.log("case 1");
          return  buttons = [1 , dots ,currentPage -1 , currentPage , lastPage]
        }

        case lastPage       : {
              // console.log("case 1");
          return  buttons = [1 , dots ,lastPage - 1 , lastPage]
        }

        default             : {
              // console.log("case 1");
          return  buttons = defaultCondition(currentPage)
        }
      }



  }


  return (
    <div className="pagination-container">

        {
          state.total_per_page && state.total ? ( <div className="pagination-text"> جاري عرض  {state.total_per_page}  من اجمالي  {state.total}</div>) : ""
        }

        <div className="pagination-buttons">

         {
           currentPage > 1 &&  <div className="previous-page page-arrow" onClick={() => setCurrentPage(currentPage - 1)} >
                                    <Icons.Arrow className='reverse' />
                               </div>
         }
            {
              pagesToBeRendered && pagesToBeRendered.length > 0 && pagesToBeRendered.map((page , index)=>{
                return page !== "..." ?
                      <PageNumber key={index} pageNumber={page} currentPage={currentPage} clickHandler={clickHandler} /> : 
                      <div className="page-number" key={index}>...</div>
              })
            }
        {
          currentPage <= _pages.length - 1 &&    <div className="next-page page-arrow" onClick={() => setCurrentPage(currentPage + 1)}>
                                                    <Icons.Arrow />
                                                   </div>
        }
        </div>

    </div>
  )
}




export default Pagination