import React, { useEffect } from 'react';
import { useToastStore } from '../../../stores/toast.store';
import { toast, ToastContainer } from 'react-toastify';

const ToastMessage  = () => {
  
  const { message, severity, hideMessage } = useToastStore();
  
  useEffect(() => {
    if (message) {
      switch (severity) {
        case 'success': toast.success(message); break;
        case 'error': toast.error(message); break;
        case 'info': toast.info(message); break;
        default: toast(message); break;
      }
    }

  }, [message, severity, hideMessage]);

return <ToastContainer  
            position="bottom-left" 
            autoClose={5000} // 5 seconds 
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={true}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            className={"w-fit min-w-[300px]"}
         />;
};

export default ToastMessage;