import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";

import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";
import CreateOrderSparepartsInvoice from "./CreateOrderSparepartsInvoice";
import UpdateSparepartsInvoice from "./UpdateOrdersSparepartsInvoice";
import DeleteOrderReport from "./DeleteOrderReport";

const OrdersSpareparts = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);

    const title = "قائمة شراء قطع الغيار"

    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "اضافة فاتورة قطع الغيار",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "date", col: 2, custom : itm => new Date(itm.createdAt).toLocaleDateString("ar-EG") ,  title: "تاريخ الفاتورة"},
        {name: "invNumber", col: 1, title: "رقم القاتورة"},
        {name: "type", col: 2, title: "الاسم"},
        {name: "quantity", col: 1, title: "الكمية"},
        {name: "unitprice", col: 1, title: "سعر الوحده"},
        {name: "total", col: 1, title: "السعر الاجمالي"},
        {name: "measuringUnit", col: 2, title: "معلومات اضافية"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={title}/>

            <QuickView ref={createRef}>
                <CreateOrderSparepartsInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateSparepartsInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteOrderReport setMainData={setMainData}/>
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={title}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default OrdersSpareparts;
