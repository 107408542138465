import SellInvoiceComponent from './SellInvoiceComponent'
import BuyInvoiceComponent from './BuyInvoiceComponent'



// got mixed with sell and buy words.
// i know they are opposite what it's mean but going to fix it in the future (✿◡‿◡).
const SelectInvoice = {
    Sell : SellInvoiceComponent,
    Buy : BuyInvoiceComponent
}

export default SelectInvoice
