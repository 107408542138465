import {useEffect, useState} from "react";
import { stock_api as stocks_api} from "../../../config/apis";
import { setInputsToDefault, updateFormHandler} from "../../../helpers/form.helper";
import {EditForm, InputText, PageMeta , Signboard} from "../../../config/imports";

export default function EditHome(props){

    const {toggle , item , setMainData , } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({
        name : item.name,
    })

    // console.log(data)
    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: stocks_api.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData :  () => setMainData(pre =>{
                let index = pre.findIndex(i => i._id === item._id)
                if(index === -1) return pre
                pre[index] = {
                    ...item,
                    stock : data.stock?._id,
                    name : data.name
                }
                return [...pre]
            }),
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };
    useEffect(() => {
        document.querySelector('.modal-container-content').style.width = 'fit-content';
    })

    return(<>
        <PageMeta title={"تعديل  بيانات  " + item.name} originTitle={'المستودعات'}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>{"  تعديل  بيانات     " + item.name}</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>

        <EditForm
            visible={props.showFooter === undefined}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}>
            <InputText name={'name'} handleInputChange={handleInputChange} className={'col-12'}  placeholder={'h1'}  title={'اسم البيت'} defaultValue={data.name}/>
        </EditForm>
    </> )
}
