import axios from "axios";
import axiosAuthParams from "./axios.auth.params";

export const accio = async (url , params ={} , signal = undefined ) => {

  try {
    const response = await axios.get(url , {
       ...axiosAuthParams(),
       params,
       signal : signal && undefined
    } );
    return {
      data:
        response.data.data === undefined || response.data.data === null
          ? response.data
          : response.data.data,
      error: null,
      statusCode: response.status,
      paginate :{
        pages: response.data.pages_count ,
        total : response.data.total_items ,
        total_per_page : response.data.items_per_current_page
      }
    };
  } catch (error) {
    if(error.response.data.jwt && error.response.data.jwt.user === null){
      localStorage.removeItem('user')
      window.location.href = '/login'
    }
    return {
      data: null,
      error: error.response.data.error,
      statusCode: error.response.status,
    };
  }
};
