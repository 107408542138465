import React from 'react'
import PropTypes from 'prop-types'
import EggsWarehouseStatGroup from './EggsWarehouseStatGroup'

function EggsWarehouseBoxes(props) {
  const {data} = props

  return <EggsWarehouseStatGroup data={data} />
  
}

EggsWarehouseBoxes.propTypes = {
    data : PropTypes.array
}

export default EggsWarehouseBoxes
