import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import {useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";
import chartOptions from "./charts.config";
ChartJS.register(ArcElement, Tooltip, Legend);


export default function BirdsCountCharts(props){
const {data} = useFetch(apis.stats.birds())


    const dataToDisplay = {
       production_birds_count : data?.totaled_production_birds_ratio,
       breeding_birds_count :  data?.totaled_breeding_birds_ratio,
       total_birds_count :data?.totaled_production_birds_ratio + data?.totaled_breeding_birds_ratio,
    }


    const chartData = {
        datasets: [
            {
                label: 'عدد الطيور',
                data: Object.values(dataToDisplay),
                backgroundColor: [
                    '#0097F5',
                    '#F4A825',
                    '#E74C3C',
                ],
                borderWidth: 0,
            },
        ],
    };

    return(
        <div className={'birds-chart'}>
            <header>اعداد الطيور</header>
            <div className="d-flex my-2 align-items-center">
                <div className="col-6 d-flex align-items-center flex-wrap">
                    <span className={'chart-indicator'} style={{ backgroundColor : '#0097F5' }}></span>
                    <span>  عدد طيو الانتاج  </span>
                </div>
                <div className="col-6 d-flex align-items-center">
                    <span className={'chart-indicator'} style={{backgroundColor : '#F4A825'}} ></span>
                    <span>  عدد طيور التربيه  </span>
                </div>
            </div>
            <div className="d-flex my-2">
                <div className="col-8 d-flex align-items-center">
                    <span className={'chart-indicator'} style={{ backgroundColor : '#E74C3C' }} ></span>
                    <span>  عدد الطيور التراكمي   </span>
                </div>
            </div>
<hr/>
            <div className="center-container">
                <Doughnut data={chartData} options={chartOptions.birds}  />
            </div>

        </div>
    )
}
