import React from 'react'
import PropTypes from 'prop-types'

function SelectItem(props) {
const {name , _id , addToSelectedItems , removeFromSelectedItems , isSelected , type} = props

  return (
    <div className={`
        px-2 w-full py-[5px] flex items-center justify-center text-center  bg-[#41e481] rounded-md text-[14px]   cursor-pointer m-2 group text-white
      transition-all relative
        ${isSelected ?  "bg-[#41e481]" :  "bg-[#949494]"}
    `}>

     <span className='h-[35px] text-white text-end  w-full block leading-[35px]' >{name} </span>



        {
            isSelected ? (
                <div 
                onClick={()=> removeFromSelectedItems(_id , type , name)}
                className='
                absolute top-1/2  left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:!opacity-[1] 
                 bg-gray-700/30  w-full h-full rounded-lg  transition-all text-white text-[30px] '>-</div>
        
        
            ) : (
                <div 
                onClick={()=> addToSelectedItems(_id , type , name)}
                className='
                absolute top-1/2  left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 group-hover:!opacity-[1] 
                 bg-gray-700/30  w-full h-full rounded-lg  transition-all text-white text-[30px] '>+</div>
        
        

            )
        }
   




    </div>
  )
}

SelectItem.propTypes = {
    name : PropTypes.string,
    _id : PropTypes.string,
    addToSelectedItems : PropTypes.func,
    isSelected : PropTypes.bool,
    removeFromSelectedItems : PropTypes.func,
    type : PropTypes.string

}

export default SelectItem
