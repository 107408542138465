import React, { useEffect } from "react";
import useSelectInvoiceStore from "../stores/select-invoice.store";
import { InputSelect } from "../../../config/imports";

export default function InputOperatorInvoices() {


  const fetchOperators = useSelectInvoiceStore((state) => state.fetchOperators);
  const operators = useSelectInvoiceStore((state) => state.operators);
  const operator = useSelectInvoiceStore((state) => state.operator);
  const setOperator = useSelectInvoiceStore((state) => state.setOperator);



  useEffect(() => {
    fetchOperators()
  }, []);

 
  

  return (
    <InputSelect
      title={"اختر المورد"}
      className={"col-12"}
      handleInputChange={(e) => setOperator(e.target.value)}
      name="type"
      defaultValue={operator}
      options={operators}
    />
  );
}
