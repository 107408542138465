import {
    createFormHandler,
    EditForm,
    Signboard,
    InputText,
    InputNumber,
    Icons
} from "../../../../../config/imports";
import {useState} from "react";
import {apis} from "../../../../../config/apis";


import ArrayHelpers from "../../../../../helpers/array.helpers";
import origin_cards_tables from "../../assests_forms";
import Browser from "../../../../../layout/browser/Browser";

export default function Create (props){

    const {setMainData, toggle , asset} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [data , setData] = useState({
        rooms : []
    })
    // const [rooms, setData] = useState([]);

    let signboard = {
        error   ,
        success,
        loading ,
    }


    const handleInputChange = (e) => {


        const { name , value , type } = e.target;
        console.log({ name , value , type})
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };
    


const handleFormSubmit = async (e) => {

        e.preventDefault();
        const create_props = {
            url: apis.assets.asset(asset).create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setData,
            setMainData  ,
        };
        // console.log(create_props.data)

        await createFormHandler(create_props);
};

const handleRoom = (e , id)=>{
        const { name , value , type } = e.target
        const _room = data.rooms.find(room => room.id === id)
        _room[name] = type === 'number' ? +value : value
        _room.area = _room.length * _room.width
        setData({
            ...data,
            rooms : [...data.rooms]
        })
}

    const addRoom = ()=>{
        setData({
            ...data,
            rooms : [...data.rooms ,  {
                id : `id-${Date.now()}`,
                name : '',
                ac_count : "",
                doors_count : "",
                length : "",
                width : "",
                area : ""
            }]
        })
    }

    const deleteRoom = (id)=>{
        console.log('deleting...')
        console.log(id)
        const _rooms = data.rooms.filter(room => room.id !== id)
        setData({
            ...data,
            rooms : [..._rooms]
        })
        
    }

 

   
    const browserData = [
        {
            tab  : { title : "بيانات المبنى" , size : 12},
            content :<>
                 { ArrayHelpers.simpleInputsGenerator(origin_cards_tables[asset] , data , handleInputChange ) }

            </>
        },
        {
            tab  : { title : " بيانات الغرف" , size : 12},
            content :<>
                <div className="row g-1">
                    <InputNumber  className={'col-12'} name={"rooms_count"}  defaultValue={data.rooms_count} placeholder={'2'}  title={"عدد الغرف"} handleInputChange={handleInputChange} />
                </div>
                {data.rooms  && data.rooms.map(({ id , name , ac_count , doors_count , length , width , area } , index) =>{
                    console.log({
                            name : data.rooms[index].name,
                            ac_count : data.rooms[index].ac_count,
                            doors_count : data.rooms[index].doors_count,
                            length : data.rooms[index].length,
                            width : data.rooms[index].width,
                            area : data.rooms[index].area,
                    })  
                    return   <div className="builing-room row g-1" key={id}>
                    <InputText    className={'span-9'} name={"name"}  defaultValue={data.rooms[index].name} placeholder={'الغرفة'}  title={"أسم الغرفة"} handleInputChange={(e)=>handleRoom(e , id)} />
                    <InputNumber  className={'span-3'} name={"ac_count"}  defaultValue={data.rooms[index].ac_count} placeholder={'3'}  title={"عدد التكييفات"} handleInputChange={(e)=>handleRoom(e , id)} />
                    <InputNumber  className={'span-3'} name={"doors_count"}  defaultValue={data.rooms[index].doors_count} placeholder={'2'}  title={" عدد الابواب "} handleInputChange={(e)=>handleRoom(e , id)} />
                    <InputNumber  className={'span-3'} name={"length"}  defaultValue={data.rooms[index].length} placeholder={'2'}  title={"طول الغرفة"} handleInputChange={(e)=>handleRoom(e , id)} />
                    <InputNumber  className={'span-3'} name={"width"}  defaultValue={data.rooms[index].width} placeholder={'3'}  title={"عرض الغرفة"} handleInputChange={(e)=>handleRoom(e , id)} />
                    <InputNumber  className={'span-3'} name={"area"} disabled={true}  defaultValue={data.rooms[index].area} placeholder={'6'}  title={"مساحة الغرفة"} handleInputChange={(e)=>handleRoom(e , id)} />
                    <button type="button" onClick={ () => deleteRoom(id)} className="custom-btn  w-fit m-auto bg-transparent"><Icons.Delete /></button>
                </div>

                }
                ) }
                <div type="button" className="custom-btn bg-success w-fit m-auto" onClick={addRoom}> إضافة غرفة </div>
            </>
        },
       
    ]



    return(
        <>
          
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>إضافة مبنى</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm
                visible={false}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >
                   
             <Browser data={browserData} toggle={toggle} />

            </EditForm>


        </>
    )
}
