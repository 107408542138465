import Label from "./Label";
import Input from "./Input";
import "../../../../styles/formInputs.styles.scss";

export default function FormInput(props){
    const defaultClassName =( className ) =>{
        let defaultClassName = 'form-input form-group  col-12 col-sm-12 col-md-4 col-lg-4'
        if(!className) return defaultClassName
        else return ' form-input form-group ' + className
    }
    return (
        <div
            className={defaultClassName(props.className)}
        >
            <Label {...props} />
            <Input {...props} />
        </div>
    )
}
