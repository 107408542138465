import "../../styles/stocks.scss";
import QuickView from "../../components/QuickView";
import Signboard from "../../components/Signboard";
import PageMeta from "../../components/PageMeta";
import { useEffect, useRef, useState, } from "react";
import useFetch from "../../hooks/useFetch";
import { stocksAPI } from "./stocks.routes";
import { stock_api } from "../../config/apis";
import { Icons, postData } from "../../config/imports";
import { Link, NavLink } from "react-router-dom";
import StocksCrud from "./stocks_crud";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, warehousesBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";

const StockIndex = () => {

    const createRef = useRef(),
        editRef = useRef(),
        deleteRef = useRef(),
        colorRef = useRef();

    const BUTTONS =  [
        {
            title : "عرض وحدات التخزين",
            onclick : () => console.log("show storage units"),

        }
    ]

    let {data: stocks, isLoading, error : fetchError, setData : setStocks} = useFetch( stocksAPI.url)

    // console.log({stocks})

    let [fav , setFav] = useState( [])
    let [notFav , setNotFav] = useState([])
    const [loading , setLoading] = useState(false)
    const [errorStatus , setErrorStatus] = useState('')

    const signboard = {
        error : fetchError || errorStatus,
        loading : isLoading  || loading,
    }


    useEffect(() => {
        // console.log(homes)
        if(stocks && stocks.length > 0){
            setFav(stocks.filter(p => p.is_favorite))
            setNotFav(stocks.filter(p => !p.is_favorite))
        }
    } , [stocks])
    // console.log(stocks)
    const favoriteStateHandler = async (home , e)=>{
        setLoading(true)
        setErrorStatus('')
        //     find the index of this home
        const pIndex = stocks.findIndex(h => h._id === home._id)
        const ToBeChanged = stocks.filter(h => h._id === home._id)[0]
        console.log(ToBeChanged)
        if(ToBeChanged){

            const {data , error } = await postData(stock_api.toggleFavorite() , { stock_id : ToBeChanged._id }  )
            console.log({data , error})
            setLoading(false)
            // console.log({data , error})
            if(error) return setErrorStatus(error)

            ToBeChanged.is_favorite = !ToBeChanged.is_favorite
            stocks[pIndex] = ToBeChanged
            stocks = [...stocks]
            setStocks(stocks)
        }
    }

    const lockStateHandler = (project , e)=>{
        const projectIndex = stocks.findIndex(p => p._id === project._id)
        const ToBeChanged = stocks.filter(p => p._id === project._id)[0]
        if(ToBeChanged){
            ToBeChanged.is_locked = !ToBeChanged.is_locked
            stocks[projectIndex] = ToBeChanged
            stocks = [...stocks]
            setStocks(stocks)
        }
    }



    const menuHandler = (e)=>{
        const TIME_TO_CLOSE_MENU = 10000


        const menuId = e.target.dataset.menuId
        // document.querySelectorAll('.cube-menu').forEach(el => el.classList.remove("active-menu"))
        if(menuId)
           document.getElementById(menuId)?.classList.toggle("active-menu")


        setTimeout(()=>{
           document.getElementById(menuId)?.classList.toggle("active-menu")
        } , TIME_TO_CLOSE_MENU)
    }





    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            warehousesBreadCrumb,
        ])
    } , [])











    return (

    <div className="stocks-container bg-white r-16 p-3">

        <PageMeta title={'المستودعات'} />

        <Signboard {...signboard}/>

        <QuickView ref={createRef}><StocksCrud.Create  setMainData={setStocks} /></QuickView>
        <QuickView ref={editRef}><StocksCrud.Edit   setMainData={setStocks} /></QuickView>
        <QuickView ref={deleteRef}><StocksCrud.Delete  setMainData={setStocks} /></QuickView>
        <QuickView ref={colorRef}><StocksCrud.Color homes={stocks} setHomes={setStocks} /></QuickView>

      <div className="d-flex justify-content-end">
          <Link to={'/billing-management'} className='add-btn'> عرض وحدات التخزين </Link>
      </div>

        {
            fav && fav.length > 0 && (
                <div className="">
                    <h4 className='d-flex  align-items-center mt-4'>
                        <img src="/icos/star.png" alt="star icon"/>
                        <span className='mx-2 d-inline-block'> المفضلة </span>
                    </h4>
                    <div className="d-flex flex-wrap ">
                        {
                            fav.map(item => (
                                <div className="round-cube" key={item._id} id={'home-'+item._id}>
                                    <div className="cube-menu" id={'menu' + item._id}>
                                        <div className='menu-item edit-cube flex'>
                                            <button onClick={ () => editRef.current.toggle(item) }>
                                                تعديل
                                                <Icons.Edit/>
                                            </button>
                                        </div>
                                        <div className='menu-item delete-cube flex'>
                                            <button onClick={ () => deleteRef.current.toggle(item) }>
                                                حذف
                                                <Icons.Delete/>
                                            </button>
                                        </div>
                                        <div className='menu-item delete-cube flex'>
                                            <button onClick={ () => colorRef.current.toggle(item) }>
                                                تغيير اللون
                                                <Icons.Color/>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="data-container" style={{backgroundColor: item.color ?? "#0CC857"}}>
                                        <header className='header-menu'>
                                            <button type='button' data-menu-id={'menu' +item._id}
                                                    onClick={menuHandler}
                                            >...</button>
                                        </header>
                                        <div className="cube-name">
                                            <NavLink to={`/stocks/${item._id}?tab=`}>
                                                {item.name}
                                            </NavLink>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button onClick={ (e ) => lockStateHandler(item , e) } >
                                                <img src={`/icos/${item.is_locked ? 'locked' : 'lock'}.png`} alt=""/>
                                                {/*locked*/}
                                            </button>
                                            <button onClick={ (e ) => favoriteStateHandler(item , e) } >
                                                <img src="/icos/fav-star.png" alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className=" text-center">
                                        {
                                            `مستودع ${new Date(item.createdAt).getFullYear()}`
                                        }
                                    </div>

                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }
        {
            notFav && (
                <div className="not-fav-section flex-wrap">
                    <h4 className='d-flex  align-items-center mt-4'>
                        <img src="/icos/category.png" alt="category icon"/>
                        <span className='mx-2 d-inline-block'> كل المستودعات </span>
                    </h4>
                    <div className="d-flex flex-wrap">
                        <div className="round-cube" >
                            <button onClick={ ()=>  createRef.current.toggle() }>
                                <div className="data-container add-cube">
                                    <div className="flex flex-col items-center justify-center">
                                        <img src="/icos/add-square.png" alt="add"/>
                                        <p className="mt-3">اضافة مستودع</p>
                                    </div>
                                </div>
                            </button >
                        </div>
                        {
                            notFav.map(item => (
                                <div className="round-cube" key={item._id} id={'home-'+item._id}>
                                    <div className="cube-menu" id={'menu' + item._id}>
                                        <div className='menu-item edit-cube flex'>
                                            <button onClick={ () => editRef.current.toggle(item) }>
                                                تعديل
                                                <Icons.Edit/>
                                            </button>
                                        </div>
                                        <div className='menu-item delete-cube flex'>
                                            <button onClick={ () => deleteRef.current.toggle(item) }>
                                                حذف
                                                <Icons.Delete/>
                                            </button>
                                        </div>
                                        <div className='menu-item delete-cube'>
                                            <button onClick={ () => colorRef.current.toggle(item) } className='flex'>
                                                تغيير اللون
                                                <Icons.Color/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="data-container" style={{backgroundColor: item.color ?? "#0CC857"}}>
                                        <header className='header-menu'>
                                            <button type='button' data-menu-id={'menu' +item._id}
                                                    onClick={menuHandler}
                                            >...</button>
                                        </header>
                                        <div className="cube-name">
                                        <NavLink to={`/stocks/${item._id}?tab=details`}>
                                                { item.name}
                                            </NavLink>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            <button onClick={ (e ) => lockStateHandler(item , e) } >
                                                <img src={`/icos/${item.is_locked ? 'locked' : 'lock'}.png`} alt=""/>
                                            </button>
                                            <button onClick={ (e ) => favoriteStateHandler(item , e) } >
                                                <img src="/icos/outline-star.png" alt=""/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className=" text-center">
                                        {`مستودع ${new Date(item.createdAt).getFullYear()}`}
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            )
        }
    </div>





            

  );
};

export default StockIndex;
