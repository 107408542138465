import {
    useFetch,
    PageMeta,
    Signboard,
    useParams,
    NavLink, RightArrow, LeftArrow, TableView, QuickView, useRef, _date, Grid, Table
} from "../../../config/imports"
import salariesAPI from "../salaries.routes";
import ProfileCreateForms from "../ProfileCreateForms";
import {apis, employees_api} from "../../../config/apis";
import {paths} from "../../../config/configs.env";
import UserOperations from "./UserOperations";
import React, {useEffect, useState} from "react";
import EmployeesCrud from "../employees_crud";
import {accio} from "../../../helpers/axios/axios.get";



const SalaryProfile = () => {

    let { id } = useParams();

    let {data : emp , isLoading : fetchLoading, error : fetchErr , setData : setEmp} = useFetch( apis.employees.profile(id));

    const [isDataAdded , setIsDataAdded] = useState(false)


    const addWorkTaskRef = useRef();
    const addRequestVacationRef = useRef();
    const addAdditionalWorkRef = useRef() ,
        deductionRef  = useRef(),
        feedbackRef = useRef();

    const refreshEmp = async () => {
        const {data : newEmp} = await accio( apis.employees.profile(id));
        emp = {...newEmp}
        setEmp(emp)
        setIsDataAdded(false)
    }



    // console.log({isDataAdded})
    useEffect( ()=>{
        if(isDataAdded)
             refreshEmp()
    } , [isDataAdded])



    const sizes = {
        serial : 1,
        type : 4,
        name : 5,
        start_date : 4,
        end_date : 4,
        info : 5,
        settings : 2
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}>#</Grid.Span>,
        type:<Grid.Span size={sizes.type}> نوع الطلب او الاضافة  </Grid.Span>,
        name:<Grid.Span size={sizes.name}> اسم الطلب او الاضافة  </Grid.Span>,
        start_date:<Grid.Span size={sizes.start_date}>   تاريخ البداية    </Grid.Span>,
        end_date:<Grid.Span size={sizes.end_date}>   تاريخ الانتهاء     </Grid.Span>,
        info:<Grid.Span size={sizes.info}>   معلومات اضافية    </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
    }


    console.log({emp})

    return (
        <>


                <div className="salary-profile-container bg-white p-3 rounded my-5">



                    <Signboard error={fetchErr} loading={fetchLoading}/>
                    <header className="my-3 row">
                     <div className="col-1">
                         <NavLink to="/salaries"> <RightArrow/></NavLink>
                     </div>
                        <div className="col-11">
                            <h4 className="text-center">
                                بيانات الموظف
                            </h4>
                        </div>
                    </header>
                    {emp  && (
                        <>
                            <QuickView ref={addAdditionalWorkRef}>
                                <ProfileCreateForms updateMain={setIsDataAdded} setMainData={setEmp} api={ employees_api.extraWork() } header={"اضافة عمل اضافى"} origins={emp?.main_info?.ar_name} type={'extraWork'} title={"اسم العمل الاضافى"}/>
                            </QuickView>
                            <QuickView ref={addWorkTaskRef}>
                                <ProfileCreateForms updateMain={setIsDataAdded} setMainData={setEmp} api={employees_api.jobAssignment()} header={"انشاء  مهمة عمل"} origins={emp?.main_info?.ar_name} type={'job'} title={"اسم المهمة"}/>
                            </QuickView>

                            <QuickView ref={addRequestVacationRef}>
                                <ProfileCreateForms updateMain={setIsDataAdded} setMainData={setEmp} api={employees_api.vacation()} header={"انشاء اجازة"} origins={emp?.main_info?.ar_name} type={"vacation"} title={"اسم الاجازة"}/>
                            </QuickView>

                            <QuickView ref={feedbackRef}><EmployeesCrud.Eval  updateMain={setIsDataAdded} mainTitle={emp?.main_info?.ar_name} /></QuickView>

                            <QuickView ref={deductionRef}><EmployeesCrud.Deduction  updateMain={setIsDataAdded} mainTitle={emp?.main_info?.ar_name} /></QuickView>

                            <PageMeta title={emp?.main_info.ar_name} originTitle={"الرواتب والاجور"}/>

                        <div className=" px-2">
                            <div className="row additionals">
                                <div className="col-8 ">

                                    <div className="row align-items-center basic-profile-info">
                                        <div className="col-3">
                                            {
                                                emp?.main_info?.personal_img ? (
                                                    <img src={paths.uploads + 'employees/' +  emp?.main_info?.personal_img} alt="" width='170px' height={'170px'} style={{borderRadius : '50%' , objectFit : 'contain' , boxShadow : "5px 5px 5px #ccc" }}/>
                                                ) : (
                                                    <div className="profile-img-placeholder">
                                                        <span>
                                                            {emp?.main_info?.ar_name[0]}
                                                            &nbsp;
                                                            {emp?.main_info?.ar_name[1]}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className="col-9">
                                            <div className="mb-2">
                                                <span> {emp?.main_info?.ar_name}   </span>
                                                <span className="">"{emp?.main_info?.en_name}"</span>
                                            </div>
                                            <div className="mb-2">
                                                <span> الوظيفـــــــــة : </span>
                                                <span className="mx-10">{emp?.main_info?.job_title} </span>
                                            </div>
                                            <div className="mb-2">
                                                <span> الجنسيــــــــــة :  </span>
                                                <span className="mx-10">{emp?.main_info?.nationality} </span>
                                            </div>
                                            <div className="mb-2">
                                                <span> رقم الموظـف   : </span>
                                                <span className="mx-10">{emp?.main_info?.employee_id} </span>
                                            </div>

                                        </div>

                                        <div className="profile-buttons d-flex mt-3 gap-2">
                                            <div className="add-btn py-2 px-4" onClick={ () => addAdditionalWorkRef.current?.toggle()}>
                                                +  عمل اضافى
                                            </div>
                                            <div className="add-btn py-2 px-4" onClick={ () => addWorkTaskRef.current?.toggle() } >
                                                +  مهمة عمل
                                            </div>
                                            <div className="add-btn py-2 px-4" onClick={ () => addRequestVacationRef.current?.toggle()}>
                                                + انشاء اجازة
                                            </div>
                                            <div className="add-btn py-2 px-4" onClick={ () => feedbackRef.current?.toggle() } >
                                                +   تقييم الاداء
                                            </div>
                                            <div className="add-btn py-2 px-4" onClick={ () => deductionRef.current?.toggle() }>
                                                +  الحسميات
                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className="col-4">
                                    <div className="salary-box">
                                        <h5>
                                            الراتب الاساسى
                                        </h5>
                                      <div className="row align-items-center my-3">
                                          <div className="col-7">
                                              <h3>{ emp?.work_info?.basic_salary } ريال</h3>
                                          </div>
                                          <div className="col-5 work-hours">
                                              <p>عدد الساعات</p>
                                              <p>{ emp?.work_info?.work_hours ?? 0 }</p>
                                          </div>
                                      </div>
                                        <div className="salary-box-footer row text-start">
                                            <div className="col-auto">
                                                <span>مشروع : </span>
                                                <span>{  emp?.work_info?.project}</span>
                                            </div>
                                            <div className="col-auto">
                                            <span>القسم : </span>
                                            <span>{  emp?.work_info?.department ??  emp?.work_info?.project }</span>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-12 col-lg-6 col-md-6  my-5">

                                     <div className="p-3 pb-0 salary-shadow salary-border">
                                         <header>
                                             <h5>البدلات الأساسية و المكتسبة و المكافأة</h5>
                                         </header>

                                         <div className="row p-3">
                                             <div className="col-12 row salary-under-border align-items-center py-3">
                                                 <div className="col-4">
                                                     <p>بدل طبيعة عمل</p>
                                                     <p> { emp?.allowances_info?.work_nature_allowance || 0 } </p>
                                                 </div>
                                                 <div className="col-4">
                                                     <p> بدل تحفيز</p>
                                                     <p> { emp?.allowances_info?.incentive_allowance || 0 } </p>
                                                 </div>
                                                 <div className="col-4">
                                                     <p> بدل أعاشة</p>
                                                     <p> { emp?.allowances_info?.subsistence_allowance || 0 } </p>
                                                 </div>
                                             </div>
                                             <div className="col-12 row salary-under-border align-items-center py-3">
                                                 <div className="col-4 ">
                                                     <p>بدل فني </p>
                                                     <p> { emp?.allowances_info?.technical_allowance || 0 } </p>
                                                 </div>
                                                 <div className="col-4">
                                                     <p> إدارة</p>
                                                     <p> { emp?.allowances_info?.management_allowance || 0 } </p>
                                                 </div>
                                                 <div className="col-4">
                                                     <p> أشراف</p>
                                                     <p> { emp?.allowances_info?.supervision_allowance || 0 } </p>
                                                 </div>
                                             </div>
                                             <div className="col-12 row align-items-center py-3">
                                                 <div className="col-4">
                                                     <p>دورية </p>
                                                     <p> { emp?.allowances_info?.periodic_allowance || 0 } </p>
                                                 </div>
                                                 <div className="col-4">
                                                     <p>تمييز </p>
                                                     <p> { emp?.allowances_info?.distinction_allowance || 0 } </p>
                                                 </div>

                                             </div>
                                         </div>

                                     </div>

                                </div>
                                <div className="col-12 col-sm-12 col-lg-6 col-md-6  my-5">
                                   <div className="p-3 pb-0 salary-shadow salary-border h-100">
                                       <header>
                                           <h5>بيانات اضافية</h5>
                                       </header>

                                       <div className="row p-3">
                                           <div className="col-12 row salary-under-border align-items-center py-3">
                                               <div className="col-4">
                                                   <p> تاريخ اخر اجازة</p>
                                                   <p> { emp?.more_info?.last_vacation_start_date ?  _date(emp?.more_info?.last_vacation_start_date).dmy()  :  'بدون' } </p>
                                               </div>

                                               <div className="col-3">
                                                   <p> مدة اخر اجازة</p>
                                                   <p> { emp?.more_info?.last_vacation_duration ?? 0 } </p>
                                               </div>
                                               <div className="col-5">
                                                   <p>  بداية العمل بعد الاجازة </p>
                                                   <p> { emp?.more_info?.back_to_work_after_vacation ?  _date(emp?.more_info?.back_to_work_after_vacation).dmy()  :  'بدون'} </p>
                                               </div>

                                              </div>

                                           <div className="col-12 row salary-under-border align-items-center py-3">
                                               <div className="col-5 ">
                                                   <p> تاريخ استحقاق الاجازة </p>
                                                   <p> {  emp?.more_info?.next_vacation_start_date ?  _date(emp?.more_info?.next_vacation_start_date).dmy()  :  'بدون' } </p>
                                               </div>
                                               <div className="col-4">
                                                   <p>  مقاس الملابس</p>
                                                   <p> {emp?.more_info?.clothing_size ?? '-' } </p>
                                               </div>
                                               <div className="col-3">
                                                   <p> مقاس الحذاء</p>
                                                   <p> { emp?.more_info?.shoes_size ?? '-' } </p>
                                               </div>
                                           </div>

                                       </div>
                                   </div>
                                </div>
                            </div>

                            <div className="profile-data-footer">
                                <div className="row">
                                    <div className="col-6">
                                        <h4>تحركات المستخدم</h4>
                                    </div>
                                    <div className="col-6 row align-self-end justify-content-end">
                                        <NavLink to={`/salaries/${ id}/operations`} className='profile-link w-fit'>
                                            عرض الكل  <LeftArrow />
                                        </NavLink>
                                    </div>
                                    <Table.Header header={header}   />
                                    {
                                        emp?.operations?.length > 0  && emp?.operations.map ((item , index) => {
                                            return(
                                                <Grid.FlexResponsive  key={index} className={'align-items-center data-row'}>
                                                    <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                                                    <Grid.Span size={sizes.type}>{item.type}</Grid.Span>
                                                    <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
                                                    <Grid.Span size={sizes.start_date}> { item.start_date ? _date(item.start_date).dmy() : '-'} </Grid.Span>
                                                    <Grid.Span size={sizes.end_date}> { item.end_date ? _date(item.end_date).dmy() : '-'} </Grid.Span>
                                                    <Grid.Span size={sizes.info}> {item.info} </Grid.Span>
                                                </Grid.FlexResponsive>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        </>
                    ) }
                </div>
        </>
    );
}

export default SalaryProfile;
