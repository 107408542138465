import PageMeta from "../../../components/PageMeta";

import RouterLinks from "../../../components/LinkList";
import useNavigator from "../../../hooks/useNavigator";

const Feed = () => {
  const FEED_LINKS = [
    {
      name: "قائمة شراء علف",
      path: "buy",
    },
    {
      name: "أصناف الاعلاف",
      path: "class",
    },
    {
      name: "قائمة تقارير خروج الاصناف",
      path: "list",
    },
  ];

  useNavigator("feed", "buy");

  return (
    <>
      <PageMeta title={"الصيانة"} />

      {<RouterLinks underline={true} data={FEED_LINKS} />}
    </>
  );
};

export default Feed;
