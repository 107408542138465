

import React from "react";
import {
  addColumn, extractTableData,
  InputDate,
  InputNumber,
  InputText, PageMeta, QuickViewContainer, Signboard,
  SuperCreate, SuperDelete,
  SuperUpdate, TableHeader, TableView,
  useFetch,
  useRef
} from "../../../config/imports";
import {projectsAPI} from "../../Projects/projects.helper";


const IncubatorOrdersIndex = () => {
  const {
    // data,
    setData: setMainData,
    error,
    isLoading
  } = useFetch(projectsAPI.feed);
  const data = [
    {
      customer_name: "محمد احمد ابن طلال",
      rep_name: "محمد احمد ابن طلال",
      mobile_phone: "554809660077 /\n" +
          "554809660077",
      birds_count: 684512,
      production_houses_count: 684512,
      production_bird_count: 684512,
      order_quantity: 684512,
      previous_order_quantity: 684512,
      obligation: 684512,
      required_quantity: 965,
      receive_date: new Date(),
      order_number : 465

    },
    {
      customer_name: "احمد احمد ابن طلال",
      rep_name: "علي احمد ابن طلال",
      mobile_phone: "554809660077 /\n" +
          "554809660077",
      birds_count: 8520,
      production_houses_count: 125,
      production_bird_count: 25,
      order_quantity: 754,
      previous_order_quantity: 963,
      obligation: 123,
      required_quantity: 123,
      receive_date: new Date(),
      order_number : 45115

    }
  ]

  const createRef = useRef();
  const updateRef = useRef();
  const deleteRef = useRef();


  const buttons = [
    {
      title: "إضافة طلبية",
      onclick: () => {
        createRef.current.toggle();
      },
    }
  ];

  // const table = [
  //     {name: "idx", col: 1, title: "م"},
  //     {name: "date",custom : itm =>  new Date(itm.date).toLocaleDateString("ar-EG") , col: 2, title: "تاريخ الفاتورة"},
  //     {name: "name", col: 2, title: "الاسم"},
  //     {name: "consumption_quantity", col: 2, title: "كمية الاستهلاك"},
  //     {name: "consumption_value", col: 2, title: "قيمة الكمية"},
  //     {name: "cost_per_bird", col: 2, title: "التكلفة علي الطير"},
  //     {name: "settings", col: 1, title: ""},
  // ];


  const table = [
    addColumn("", "م", ""),
    addColumn("customer_name", "أسم العميل", InputText,2),
    addColumn("rep_name", "أسم المندوب", InputText),
    addColumn("mobile_phone", "رقم الهاتف والجوال", InputNumber),
    addColumn("birds_count", "عدد طيور التربية", InputNumber),
    addColumn("production_houses_count", "عدد بيوت الأنتاج", InputNumber),
    addColumn("production_bird_count", "عدد طيور الأنتاج", InputNumber),
    addColumn("order_quantity", "كمية الطلب", InputNumber),
    addColumn("previous_order_quantity", "كمية الطلبات السابقة", InputNumber),
    addColumn("obligation", "المديونية", InputNumber),
    addColumn("required_quantity", "الكمية المطلوبة", InputNumber),
    {
      gen: {name: "receive_date", title: "تاريخ  الأستلام", elm: InputDate},
      table: {col: 1, custom: itm => new Date(itm.receive_date).toLocaleDateString("ar-EG")},
      create:{props: {}, value: ''},
      update: {}
    },
    addColumn("order_number", "رقم الطلبية", InputNumber),
    addColumn("settings", "", ""),
  ];

  const main_title = "الطلبيات";

  const views =
      [
        {
          ref: createRef,
          component: <SuperCreate
              setMainData={setMainData}
              api={projectsAPI}
              mainTitle={main_title}
              formHeader={"إضافة طلبية"}
              table={table}
          />
        },
        {
          ref: updateRef,
          component: <SuperUpdate
              setMainData={setMainData}
              api={"projectsAPI"}
              mainTitle={main_title}
              formHeader={"تعديل   طلبية"}
              table={table}

          />
        },
        {
          ref: deleteRef,
          component: <SuperDelete
              api={""}
              setMainData={setMainData}
              table={table}
          />
        },
      ]


  return (
      <>
        <PageMeta title={main_title}/>


        <QuickViewContainer views={views}/>

        <Signboard error={error} loading={isLoading}/>

        <TableHeader
            title={"قائمة تقارير الاستهلاك"}
            buttons={buttons}
        />


        <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
      </>
  );
};

export default IncubatorOrdersIndex;
