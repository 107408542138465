import salariesAPI from "../../salaries/salaries.routes";
import {Link, NavLink, useParams} from "react-router-dom";
import React, {useEffect, useMemo, useState} from "react";
import {Grid, Icons, QuickView, useRef ,
    TableHeader,
    PageMeta,
    Signboard,
    useFetch,
} from "../../../config/imports";
import CreateGroupOvertime from "./CreateGroupOvertime";
import CreateEmployee from "./CreateEmployee";

import {apis} from "../../../config/apis";
import {Table} from "../../../layout/table";
import Helpers from "../../../helpers/helpers";


const DepartmentSalaries = () => {

    const {id : department_id} = useParams()
    const createGroupOvertimeRef = useRef()
    const createEmployeeRef = useRef()

    const api = apis.employees_details.department_employees_paginated(department_id);

   
    const salaries_button = [
        {
            title : "عمل اضافى جماعى",
            onclick : () => createGroupOvertimeRef.current.toggle()
        },
        {
            title : "إضافة موظف",
            onclick : () => createEmployeeRef.current.toggle()
        }
    ]


    const {data  , paginate  , setters ,alert} = useFetch( api );


    const options = useMemo(() => {
        return data?.map( emp =>{
            return {
                value : emp._id,
                text : emp.ar_name
            }
        })
    })
    


    const table = [
        {name : '#' , size : 1  , text : '#'},
        {name : "ar_name" , size : 3  , text : 'الاسم'},
        {name : "job_title" , size : 3  , text : 'المهنه'},
        {name : "basic_salary" , size : 3  , text : 'الاساسي'},
        {name : "project" , size : 3  , text : 'المشروع'},
        {name : "department" , size : 3  , text : 'القسم'},
        {name : "allowances" , size : 3  , text : 'البدلات'},
        {name : "total_salary" , size : 3  , text : 'المستحق'},
        {name : "settings" , size : 1  , text : ''},
    ]


    return (
        <div className="salaries-container bg-white r-16 p-3 my-5 base-card">

            <Signboard {...alert} />

            <PageMeta title="الرواتب / الاجور" />

            <QuickView  ref={createGroupOvertimeRef} ><CreateGroupOvertime  options={options}/></QuickView>

            <QuickView  ref={createEmployeeRef} ><CreateEmployee department={department_id} setMainData={setters.data} /></QuickView>

            <TableHeader  buttons={salaries_button} api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate}  />

            <Table table={table} data={data}  />
        </div>
    );
}

export default DepartmentSalaries;
