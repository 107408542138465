import { create } from "zustand";
import { refresh, uuid } from "../helpers/id.helper";





export const setRefreshStore = () => {

    useRefreshStore.getState().setRefresh()


    setTimeout(() => { 
        useRefreshStore.getState().setRefresh(null)
     }, 3000)

}


export const getRefreshStore = () => {
    return useRefreshStore.getState().refresh
}

const useRefreshStore = create((set) => ({

    refresh: null,

    setRefresh: (refresh) => set((state) =>{
            return  { ...state, refresh : refresh ||  uuid() }
    }),


}));




export default useRefreshStore