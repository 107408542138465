import { RouterLinks } from "../../../../components/LinkList";
import  useNavigator from "../../../../hooks/useNavigator";
import  "../../../../styles/base-card.css";
const ProjectsReportIndex = () => {
    const ITEMS_CARDS_LINKS = [
        {
            name: "استهلاك الاعلاف",
            path: "feed",
        },
        {
            name: "مذكرات الطيور",
            path: "birds-notes",
        },
        {
            name: "استهلاك الادوية",
            path: "drug",
        },
        {
            name: "صيانة و قطع غيار",
            path: "maintenance",
        },
        {
            name: "تعبئة و تغليف",
            path: "packaging",
        },
        {
            name: "مصروفات اخري",
            path: "others",
        },
    ];

    useNavigator("reports", "feed");

    return (
        <div>{<RouterLinks data={ITEMS_CARDS_LINKS} />}</div>
        // <div className="base-card">
        //   <div className="ilc mt-2 bg-white px-3 py-3 r-16">
        //     <div className="icbc">
        //       <div className="base-card-links-container">
        //       </div>
        //     </div>
        //   </div>
        // </div>
    );
};

export default ProjectsReportIndex;

// warehouse-details / index /

// ProjectsReportIndex > index > [feed , drug , ...]
// requests > index > [feed , drug , ...]
