import EggsDailyReportForProductionHome from "./EggsDailyReportForProductionHome";
import FeedDailyReportForProductionHome from "./FeedDailyReportForProductionHome";
import DeadDailyReportForProductionHome from "./DeadDailyReportForProductionHome";
import OtherDailyReportForProductionHome from "./OtherDailyReportForProductionHome";
import MaintenanceDailyReportForProductionHome from "./MaintenanceDailyReportForProductionHome";
import CartonsDailyReportForProductionHome from "./CartonsDailyReportForProductionHome";
import DishesDailyReportForProductionHome from "./DishesDailyReportForProductionHome";
import DrugsDailyReportForProductionHome from "./DrugsDailyReportForProductionHome";

const ProductionReports = {
    Eggs : EggsDailyReportForProductionHome,
    Feed : FeedDailyReportForProductionHome,
    Dead : DeadDailyReportForProductionHome,
    Other : OtherDailyReportForProductionHome,
    Maintenance : MaintenanceDailyReportForProductionHome,
    Cartons : CartonsDailyReportForProductionHome,
    Dishes : DishesDailyReportForProductionHome,
    Drugs : DrugsDailyReportForProductionHome
}
export default ProductionReports
