import axios from "axios";
import axiosAuthParams from "./axios.auth.params";

export default async (url, payload , config = {}) => {



  const configData = {
    headers: {
      ...axiosAuthParams().headers,
      ...config.headers,
    }
  }


  try {
    const response = await axios.put(url, payload , configData );

    return {
      data: response.data.data || response.data ,
      error: null,
      statusCode: response.data.statusCode || response.status,
      message: response.data.massage,
    };
  } catch (error) {
    if(error.response.data.jwt && error.response.data.jwt.user === null){
      localStorage.removeItem('user')
      window.location.href = '/login'
    }
    return {
      data: null,
      error: error.response.data.error,
      statusCode: error.response.status,
    };
  }
};
