// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-container [data-accordion] .data-table-head {
  background-color: #eee;
}
.report-container [data-accordion-header] > .data-row {
  background: linear-gradient(0deg, #ccc, #eee);
}`, "",{"version":3,"sources":["webpack://./src/pages/home_details/reports/reports.styles.scss"],"names":[],"mappings":"AAGQ;EACI,sBAAA;AAFZ;AAUQ;EACI,6CAAA;AARZ","sourcesContent":["\n.report-container{\n    [data-accordion]{\n        .data-table-head{\n            background-color: #eee;\n        }\n    }\n\n\n\n\n    [data-accordion-header]{\n        &>.data-row{\n            background: linear-gradient(0deg, #ccc, #eee);\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
