import React, { useEffect, useState } from 'react'
import { useNavigate , useLocation} from "react-router-dom"
import InvoicesIndex from './InvoicesIndex'
import InvoicesList from './invoices_list/InvoicesList'
export default function Invoices() {


  
  const [component , setComponent] = useState(<InvoicesIndex/>)
  const [hash , setHash] = useState("")
  const navigate  = useNavigate()
  const location = useLocation();
  
  const baseUrl = "/invoices"
  const hasEnum = {
    // "" : <Settings/>,
    "" : <InvoicesIndex/>,
    "#list" : <InvoicesList/>,
  }


  const toggler = (hash) => {
      const tabs = document.querySelectorAll('.link-tab');
      // remove active class
      tabs.forEach((btn) => btn.classList.remove('active-tab'))
      // add active class to this
      document.querySelector(`.link-tab[data-hash="${hash}"]`)?.classList?.add('active-tab')
    }
  
  
  
  
    useEffect(()=>{
      setComponent(hasEnum[location.hash])
      setHash(location.hash)
      navigate( baseUrl + location.hash)
      toggler(location.hash)
    } , [])
  
    useEffect(()=>{
  
      setComponent(hasEnum[location.hash])
      setHash(location.hash)
      navigate( baseUrl + location.hash)
      toggler(location.hash)
      
    } , [location.hash])
  
  
  
  
    const navHandler = ( Component , hash) => {
  
  
      toggler(hash)
  
      setComponent(<Component/>)
      setHash(hash)
      navigate( baseUrl + hash)
  
  }
  




  return (
    <>
      
    <div className='tabs-container'>
        <button type='button' onClick={(e)=> navHandler( InvoicesIndex , "" )} data-hash={""} className="link-tab icon-container"  >  الفواتير   </button>
        <button type='button' onClick={(e)=> navHandler( InvoicesList  , "#list")} data-hash={"#list"} className="link-tab icon-container"  >  سجل الفواتير   </button>
     </div>

        <div className=" my-3">
          {component && component }
        </div>
   

    </>
  )
}
