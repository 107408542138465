import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from './Page'
import PagesContainer from './PagesContainer'

function Pagination(props) {

  const [page , setPage] = useState(1)

  const [pagesCount] = useState(10)


  return (
       <>
            <PagesContainer  setPage={setPage} pagesCount={pagesCount} currentPage={page} />
       </>
  )
}

Pagination.propTypes = {}

export default Pagination
