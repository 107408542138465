import { FiEye } from "react-icons/fi"; 
import { MdDeleteOutline } from "react-icons/md"; 
import {_date, Grid, Icons, NavLink, PageMeta, QuickView, TableHeader, useFetch, useRef} from "../../../config/imports";
import React, { useEffect } from "react";
import {paths} from "../../../config/configs.env";
import {originAPI} from "../../origin/origin.helpers";
import {invoices_api} from "../../../config/apis";
import Signboard from "../../../components/Signboard";
import { Table } from "../../../layout/table";
import { setBreadCrumbsStore } from "../../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, invoicesBreadCrumb, invoicesListBreadCrumb } from "../../../components/breadcrumbs/data/breadcrumbs.data";
import PreviewInvoice from "../components/PreviewInvoice";
import { AiFillEye } from "react-icons/ai";
import DeleteInvoice from "../components/DeleteInvoice";

export default function InvoicesList(props){
    const updateRef = useRef();
    const api = invoices_api.paginate()
    
    
    const {data,  alert , setters , paginate  ,  setRefresh } = useFetch(api);
    
    
    
    const ref = useRef()
    const deleteRef = useRef();


    const detailsHandler = (data) => {
       ref.current?.toggle(data)
    }
    

    const deleteHandler = (data) => {
        deleteRef.current?.toggle(data)
    }





    const table = [
      {size : 4 , name : "invNumber" , text : "#"  },
      {size : 4 , name : "created_by", text : 'اسم منشئ الفاتورة'  },
      {size : 4 , name : 'place_name' , text : 'مكان ادخال الفاتورة'  },
      // {size : 4 , name : 'department_name' , text : 'المستودع / المشروع'  },
      {size : 3 , name : 'classification' , text : 'تصنيف الفاتورة'  },
      {size : 2 , name : 'quantity' , text : 'الكمية'  },
      {size : 2 , name : 'ar_unit' , text : 'الوحدة'  },
      {size : 2 , name : 'total_price' , text : 'الاجمالي'  },
        {size : 3 , name : 'actions' , text : 'الاعدادت' , custom : d => {
            return (
              <div className="flex items-center gap-2">
                <div
                  className="cursor-pointer"
                  onClick={() => detailsHandler(d)}>
                     <FiEye className="text-[20px]" />
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => deleteHandler(d)}>
                     <MdDeleteOutline className="text-[20px]" />
                </div>
              </div>
            );
      
          }  },
        // {size : 2 , name : '' , text : '' , }
    ]
 
    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            invoicesBreadCrumb,
            invoicesListBreadCrumb,
        ])
  } , [])

    return(
        <>
        
         <QuickView ref={ref} ><PreviewInvoice  /></QuickView>
         <QuickView ref={deleteRef} ><DeleteInvoice setRefresh={setRefresh} /></QuickView>
        
        <div className="my-3">
        <NavLink to={"/invoices"} > 
                <Icons.Arrows.Right />
                <span className="mx-2"> عودة لانشاء الفواتير </span>
        </NavLink>
        </div>
        <div className="bg-white base-card p-3 r-16">
            <PageMeta title={"سجل الفواتير"} />
            <Signboard {...alert} />
            <TableHeader buttons={[]}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
            <Table table={table} data={data}   />
        </div>
        </>
    )
}








  