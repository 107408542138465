import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";

import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";
import CreateOrderFeedInvoice from "./CreateOrderFeedInvoice";
import UpdateOrderFeedInvoice from "./UpdateOrderFeedInvoice";
import DeleteOrderFeedInvoice from "./DeleteOrderFeedInvoice";

const BuyFeed = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);


    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "طلب علف",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "name", col: 2, title: "الاسم"},
        {name: "operator", custom : _item => _item.operator.name  , col: 2, title: "العميل / المورد"},
        {name: "date",   col: 1, title: "التصنيف"},
        {name: "type", col: 1, title: "نوع العلف"},
        {name: "measuringUnit", col: 1, title: "وحدة القياس"},
        {name: "unitprice", col: 1, title: "الكمية المطلوبة"},
        {name: "quantity", col: 2, title: "معلومات اضافية"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={"قائمة شراء الاعلاف"}/>

            <QuickView ref={createRef}>
                <CreateOrderFeedInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateOrderFeedInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteOrderFeedInvoice setMainData={setMainData} />
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={"اضافة فاتورة اعلاف"}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default BuyFeed;
