import
{
    PageMeta,
    EditForm,
    InputTextArea,
    InputText,
    InputDate, useState, Signboard,
    createFormHandler,
    setInputsToDefault, InputSelect
}
    from "../../config/imports"

import {employees_api} from "../../config/apis";


const CreateGroupOvertime = (props) => {

    const {   toggle, options  }  = props

    const FORM_HEADER = 'اضافة عمل اضافى جماعى';

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        start_date : '' ,
        end_date : '' ,
        info : '' ,
        name : "",
        employees : ''
    })

    const handleSubmit =  async (e) => {

        e.preventDefault()

        setLoading(true)

        const create_props = {
            data ,
            setError,
            setSuccess,
            setLoading,
            FORM_HEADER,
            setInputsToDefault,
            url : employees_api.extraWorkGroup(),
            setData,
        }

        // console.log(create_props.data)
        await createFormHandler(create_props)

    }
    const handleChange = e => {
        const {name , value } = e.target
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }


    return (
        <>

            <PageMeta title={FORM_HEADER} originTitle={"الرواتب / الاجور"}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>
            <div className="my-3">
                <EditForm onSubmit={handleSubmit} onChange={handleChange} toggle={toggle} visible={true} >
                    <InputSelect className={'col-12 my-3'} options={options}  multiple={true} nameKey={'ar_name'} name={"employees"}  title={"تحديد الاشخاص فى العمل الاضافى"} />
                    <InputText  name={"name"}  title={"اسم العمل الاضافى"} />
                    <InputDate  name={"start_date"}  title={"تاريخ البدئ"} />
                    <InputDate  name={"end_date"}  title={"تاريخ الانتهاء"} />
                    <InputTextArea  name={"info"} className={"col-12"} title={"معلومات اضافية"} />
                </EditForm>
            </div>


        </>
    );
}

export default CreateGroupOvertime
