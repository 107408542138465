import {paths} from "../../config/configs.env";


export const stocksAPI = {
    url : paths.api + "/stock",
}








const stock_routes = {
    router : (protect)=>{

   
        // return <Route path="stocks/:id" element={protect(<Origin />)} >

        //  </Route>

        
      

       
    }
}

export default stock_routes