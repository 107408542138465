import React, { useEffect, useState } from "react"
import { apis } from "../../../config/apis"
import { InputSelect } from "../../../config/imports"
import { accio } from "../../../helpers/axios/axios.get"

 const useStockHomes = (id , type = "stock")=>{
    // const {data : homes} = useFetch(apis.warehouses.homes(id))

    // const homesOptions = useMemo(() => {
    //     return homes?.map(home => ({ text : home.name , value : home._id }))
    // } , [homes])

    // return {
    //     options : homesOptions,
    //     Input : useMemo(()=>{
    //         return (props) =>{
    //             return <InputSelect  {...props} options={homesOptions}  />
    //         }
    //     } , [homesOptions])
    // }

    // console.log({id , type});


    const [options , setOptions] = useState([])
    let   [Input , setInput] = useState(<></>);

    useEffect(() => {
        
      if(type === "home") {
        setOptions([])
        setInput(<></>)
        return 
      }

     else {
        const fetch = async ()=>{
            const response = await accio(apis.warehouses.homes(id))
            const {data , error} = response
            if(error) {
              setOptions([])
              setInput(<></>)
              return
            }

            // console.log(data);
            setOptions(data?.map(home => ({value : home?._id , text : home?.name})))
            setInput( <InputSelect  options={options}  />  )
        }
  
        fetch()
     }
   
    } , [type])






    return { 

      Input : (props) => React.cloneElement(Input , {options , ...props}),
      options

    }




}


export default useStockHomes