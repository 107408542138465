import InputSelect from "../FormControls/InputSelect";
import useFetch from "../../hooks/useFetch";
import {operatorAPI} from "../../pages/operators/operator.routes";


const SelectOperator = ({handleInputChange, className, defaultValue , title ="اختر المورد"}) => {
    const {data: ops} = useFetch(operatorAPI.url)
    return (
        <>
            <InputSelect options={ops} name="operator" title={title}
                         defaultValue={defaultValue || ""}
                         handleInputChange={handleInputChange} className={className || "my-3 col-11 col-md-4"}/>
        </>
    )

}

export default SelectOperator;