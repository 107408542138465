import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { apis } from '../../config/apis';
import axiosAuthParams from '../../helpers/axios/axios.auth.params';
import Signboard from '../Signboard';

/**
 * 
 *  {
        projects_ids : [],
        departments_ids : [],
        homes_ids : [],
        start_date : '',
        end_date : '',
    },
 */

export default function useEggsWarehouseComponentData(filter , deps = []) {

    const [loading , setLoading] = useState(false)

    const [data , setData] = useState({
        totals_egg_process : [],
        egg_info_in_array : {
           date : '',
           cartons : []
        }
      })


    useEffect(() => {

        // console.log("findig");

        const controller = new AbortController();
        const signal = controller.signal;
        const fetch = async () => {
          setLoading(true)
          try {
              const response = await axios.post(apis.eggs_warehouse.all(),{ ...filter , } , {
                  signal : signal ,
                  ...axiosAuthParams()
              } );
              setLoading(false)
              setData(response.data.data)
  
            } catch (error) {
                setLoading(false)
                if (axios.isCancel(error)) {
                    console.log('Request canceled', error.message);
                } else {
                    console.error('Error fetching data:', error);
                }
            }
  
        }
  

        fetch()
  
  
        return () => {
  
          controller.abort();
        };
  
  
      } , [...deps])
 





  return{
    loading , 
    data , 
    Alert : ()=> <Signboard loading={loading} />
  }
}
