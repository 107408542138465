import React, { useState } from 'react'
import { _date, Icons, InputDate } from '../../../config/imports'
import { objectHasValues } from '../../../helpers/helpers'
import { cn } from '../../../helpers/class_name.helpers'
const TOGGLE_TIMEOUT_DELAY = 500

export default function DateFilter(props) {
    const {setQuery} = props
    const [toggle, setToggle] = useState(false)
    const [state , setState] = useState({
        start_date : "" ,
        end_date : ""
    })



    const toZeroUtc = date => _date(date).toZeroUTC()


    const onChange = (e) => {
        const { name , value } = e.target;
        if (value) {
            setState((prev) => ({
                ...prev,
                [name]: new Date(value).toISOString(),
            }));
            // setQuery(pre => ({...pre , [name] : toZeroUtc(value)}))
        }
    }

    const resetAndClose = () => {
        setState({ start_date : "" , end_date : "" })
        setQuery(pre => ({...pre , start_date : "" , end_date : ""}))
        setTimeout(()=>setToggle(!toggle),TOGGLE_TIMEOUT_DELAY)
    }


    const activeClass = toggle ?  "max-h-[300px] p-4 shadow-sm border-2 opacity-100" :  "!max-h-[0px] !p-0 !shadow-none !border-0 opacity-0"




    const sendDateToQuery = () => {
        if(!objectHasValues(state))  return

        props.setLoading(true)

        setQuery(pre => ({...pre , start_date : toZeroUtc(state.start_date) , end_date : toZeroUtc(state.end_date)}))
        
        setTimeout(() => { setToggle(false) }, 1000)
    }


  return (
    <div className='w-full relative'>
        {/* <span className='mb-2 block'>
            اختر المدة 
        </span> */}
        <button className='border-[#ced4da]/[0.7] border-2 w-full py-[7px] flex items-center justify-between px-3 rounded' onClick={()=>setToggle(!toggle)} > 
               
                {
                state.start_date && state.end_date ? 
                <span className='text-[#000] text-[14px] '> {_date(state.start_date).dmy() + " / " + _date(state.end_date).dmy() }</span>
                
                :  <span className='text-[#999] '>   من  , الى  </span>
            }
                
                  
        
                <span className={`scale-[.7] transition-all  ${!toggle ?  "rotate-[270deg]" : "rotate-[90deg]"}`}>
                    <Icons.Arrow  />
                </span>
         </button>
        {
                <div className={`rounded  absolute top-[105%] border-2 overflow-hidden transition-all  right-0 bg-white z-[1000]  ${ activeClass }  `}>
                        <div className="flex">
                            <div className="flex-1">
                                <InputDate className="w-full" title="من" handleInputChange={onChange} name="start_date" defaultValue={_date(state.start_date).forInputDate()} />
                            </div>
                            <div className="flex-1 mr-2">
                                <InputDate className="w-full" title="الي" handleInputChange={onChange} name="end_date" defaultValue={_date(state.end_date).forInputDate()} />
                            </div>
                        </div>
                        <div className="flex justify-between mt-3">
                            <button 
                            className={cn(
                                 objectHasValues(state) ? "add-btn" : "add-btn !bg-[#ccc] ",
                            )} 
                            onClick={sendDateToQuery}
                            > تطبيق </button>
                            <button className='close-btn' onClick={resetAndClose}> الغاء </button>
                        </div>
                        <div className="absolute left-[25px] top-[10px]">
                            <button className='text-[20px]' onClick={()=>setToggle(!toggle)} >
                                <Icons.Collapse />
                            </button>
                        </div>
                </div>
     
        }
    </div>
  )
}
