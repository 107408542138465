
const options =  {
    // responsive: true,
    y : {
        beginAtZero : true,
        min : 0,
        max : 100
    },
    barThickness : 20
}




const chartOptions = {
    weights : { ...options },
    balance : {
            plotOptions: {
                radialBar: {
                    size: undefined,
                    inverseOrder: false,
                    startAngle: -90,
                    endAngle: 270,
                    offsetX: 0,
                    offsetY: 0,
                    strokeLinejoin : "round",
                    strokeLinecap : "round",
                    hollow: {
                        margin: 5,
                        size: "50%",
                        background: "transparent",
                        image: undefined,
                        imageWidth: 50,
                        imageHeight: 50,
                        imageOffsetX: 0,
                        imageOffsetY: 0,
                        imageClipped: true,
                        position: "front",
                        dropShadow: {
                            enabled: false,
                            top: 0,
                            left: 0,
                            blur: 3,
                            opacity: 0.5
                        }
                    },
                    track: {
                        show: true,
                        startAngle: undefined,
                        endAngle: undefined,
                        background: "#f2f2f2",
                        strokeWidth: "97%",
                        strokeLinejoin : "round",
                        strokeLinecap : "round",
                        opacity: 1,
                        margin: 5,

                    },
                    dataLabels: {
                        show: false,
                    }
                }
            },


    },
    operators : {
        ...options.y
    }

}

export default chartOptions
