import React from 'react'
import PropTypes from 'prop-types'
import EggsWarehouseStat from './EggsWarehouseStat'
import { key } from '../../helpers/id.helper'

function EggsWarehouseStatGroup(props) {
    const { data } = props
    return (
        <div className='flex'>
            {
                data?.map( (stat , index) => <EggsWarehouseStat key={key()}  amount={stat.amount} name={stat.ar_process}  />)
            }
        </div>
    )
}

EggsWarehouseStatGroup.propTypes = {
    data : PropTypes.array
}

export default EggsWarehouseStatGroup
