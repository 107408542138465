import React, { useEffect } from "react";
import useSelectInvoiceStore from "../stores/select-invoice.store";
import { InputSelect } from "../../../config/imports";

export default function InputProjectInvoices() {
  const fetchProjects = useSelectInvoiceStore((state) => state.fetchProjects);
  const projects = useSelectInvoiceStore((state) => state.projects);
  const setProject = useSelectInvoiceStore((state) => state.setProject);
  const project = useSelectInvoiceStore((state) => state.project);

  useEffect(() => {
    fetchProjects();
  }, []);

  return (
    <InputSelect
      title={"اختر المشروع"}
      className={"col-12"}
      handleInputChange={(e) => setProject(e.target.value)}
      name="type"
      defaultValue={project}
      options={projects}
    />
  );
}
