import useFetch from "../../../hooks/useFetch";
import { operatorAPI, originAPI } from "../origin.helpers";
import {Grid, Icons, QuickView, Signboard, Table, TableHeader, useRef} from "../../../config/imports";
import PageMeta from "../../../components/PageMeta";
import React from "react";
import OriginDrugCrud from "./drug_crud";

const Drug = () => {
    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();



    const { data: drugs, error, isLoading, setData : setDrugs } = useFetch(originAPI.drug);

    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }
    const sizes = {
        serial : 1,
        name : 4,
        measuringUnit : 3,
        classification : 3,
        operator : 4,
        types : 4,
        info : 3,
        settings : 2
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}>#</Grid.Span>,
        name:<Grid.Span size={sizes.name}> الاسم  </Grid.Span>,
        measuringUnit:<Grid.Span size={sizes.measuringUnit}>   وحدة القياس  </Grid.Span>,
        classification:<Grid.Span size={sizes.classification}>   التصنيف    </Grid.Span>,
        types:<Grid.Span size={sizes.types}>   نوع الدواء    </Grid.Span>,
        operator:<Grid.Span size={sizes.operator}>   المورد    </Grid.Span>,
        info:<Grid.Span size={sizes.info}>   تفاصيل    </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
    }


    return (
    <>
      <PageMeta title={"الادوية"} />
        <QuickView ref={createRef}><OriginDrugCrud.Create  operators={operators}  setMainData={setDrugs}/></QuickView>
        <QuickView ref={updateRef}><OriginDrugCrud.Update  operators={operators} setMainData={setDrugs}/></QuickView>
        <QuickView ref={deleteRef}><OriginDrugCrud.Delete  operators={operators} setMainData={setDrugs}/></QuickView>
        <Signboard {...signboard} />
        <TableHeader  buttons={ [
            {
                title: " اضافة نوع دواء جديد ",
                onclick: () => createRef.current.toggle()
            }
        ]}/>
        <div className="" style={{marginTop: '150px'}}></div>
        <Table.Header header={header}   />
        {
            drugs &&  drugs.length > 0  && drugs.map((item , index) => (
                <Grid.FlexResponsive  key={index} className={'align-items-center data-row'}>
                    <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                    <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
                    <Grid.Span size={sizes.measuringUnit}>{item.measuringUnit}</Grid.Span>
                    <Grid.Span size={sizes.classification}> {item.classification} </Grid.Span>
                    <Grid.Span size={sizes.types}> {item.types} </Grid.Span>
                    <Grid.Span size={sizes.operator}> {item.operator?.name ?? 'بدون'} </Grid.Span>
                    <Grid.Span size={sizes.info}> {item.info} </Grid.Span>
                    <Grid.Span size={sizes.settings} className='settings-buttons'>
                        <button  onClick={()=> updateRef.current.toggle(item)}><Icons.Edit /></button>
                        <button  onClick={()=> deleteRef.current.toggle(item)}><Icons.Delete /></button>
                    </Grid.Span>
                </Grid.FlexResponsive>
            ))
        }


    </>
  );
};

export default Drug;
