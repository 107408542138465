import Grid from "../grid/index.js";

export default function TableHeader(props){
    const {table , className} = props

    const headData = table?.map(t =>{
        return { size : t.size , text : t.text }
    })

    return(
        <>
            <Grid.Head className={className}>
                {headData?.map( (item , index) => {
                    return (
                        <Grid.Span key={index} size={item.size}>{item.text}</Grid.Span>
                    )
                })}
            </Grid.Head>
        </>
    )
}

