import { useEffect, useState } from "react";
import {
    _date,
    createFormHandler,
    EditForm, InputDate,
    InputNumber, InputText, InputTextArea,
    PageMeta,
    setInputsToDefault,
    Signboard
} from "../../../config/imports";
import { apis } from "../../../config/apis";
import FeedTypeSelectInput from "../shared/FeedTypeSelectInput";
import MeasuringUnitInput from "../shared/MeasuringUnitInput";
import UnitPriceInput from "../shared/UnitPriceInput";
import AmountInput from "../shared/AmountInput";
import SharedHomesInputs from "../shared/SharedHomesInputs";
import TotalFromHomesInput from "../shared/TotalFromHomesInput";
import TotalPriceInput from "../shared/TotalPriceInput";
import OperatorsInput from "../shared/OperatorsInput";
import InvoiceId from "../shared/InvoiceId";
import useInvoicesApi from "./useInvoicesApi";
import useAlert from "../../../hooks/useAlert";
import InputTax from "../shared/InputTax";

export default function BuyWarehouseFeedInvoice(props){

    const {
        project , department,
        setMainData, toggle ,
        setRefresh,
        invoiceFor,
        stockName , stockId 
    } = props

    // const [Alert , setters]= useAlert()



    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    // const project = '64fd0c1953b4852b68cd00c6'
    // const department = '64fd0c1953b4852b68cd00c8'

    // const { data : formData  , error : formError } =  apis.invoices.form.data( 'type=buy&invoice=feed&place=project' )
    const {  data : formData , error : formError , Alert , setters , OrdersElement  } = useInvoicesApi( 
        { type : "buy" , invoice : "feed" , place : "stock" , place_id : stockId } )

   
  
    useEffect(()=>{
            if(error || success){
                setTimeout(() => {
                    setError("");
                    setSuccess("");
                }, 2000);
            }
    } , [error , success ])


    const signboard = {
         loading,
        error,
        success,
    }



    const [data , setData] = useState({
        // home_id,
        invNumber : "",
        date : new Date().toISOString(),
        total_price : 0,
        isChecked : false,
        total_quantity_from_homes : 0,
        project,
        department,
        stock : formData?.storage_info?.location_id,
        stock_quantity : "",
        invoice_type : "stock",
        orders : [],
    })

    

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));

    };


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.invoices.buy.feed.create(),
            data : {
                ...data ,
                stock : stockId ?? formData?.storage_info?.location_id,
                stock_quantity : data?.quantity
            } ,
            setLoading,
            setError,
            setSuccess,
            setRefresh,
            setMainData ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
  
        // console.log({data : create_props.data})
        await createFormHandler(create_props);
    };

    return(
        <div className={''}>
            <PageMeta title={'فاتورة شراء اعلاف'} originTitle={'ادارة الفواتير'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>فاتورة شراء اعلاف لمستودع</h3>
            </header>

            <Signboard {...signboard}/>
            <Alert />
            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>

                <InvoiceId setData={setData} type={"feedBuyInv"}   className="col-3" value={formData?.inv_number} />

                <InputText name="invoice_number" className="col-3"   title=" رقم الفاتورة" defaultValue={data.invoice_number} handleInputChange={handleInputChange} />

                <OperatorsInput className={'col-3'} data={data} setData={setData} />

                <InputDate className="col-3"  name="date" label="تاريخ الفاتورة" defaultValue={_date(data.date).forInputDate()} handleInputChange={handleInputChange} />

                <FeedTypeSelectInput data={data} setData={setData} />

                <MeasuringUnitInput data={data} setData={setData} />

                <UnitPriceInput data={data} setData={setData} />

                <AmountInput data={data} setData={setData} /> 

                <OrdersElement defaultValue={data.orders}  />
                
                <InputText name={"stock"}  title={"اسم المستودع"} defaultValue={stockName ?? "المستودع الخاص بالمشروع"}  disabled={true} className={"col-4"} />
               
                {/* <>
                    <div className={"stick-error-to-input bb-2  px-0 col-4  " + (error === "الكميات غير متطابقه" ? 'input-has-error ' : '')}>
                            <InputNumber name="stock_quantity"  handleInputChange={handleInputChange}   title={"الكميه"} defaultValue={data.stock_quantity}   className={"w-100"}  />
                            <small className="text-danger px-0 py-0 error-output">{
                             data.quantity && data.stock_quantity && ( ( data.stock_quantity ?? 0) !==  (data.quantity ?? 0)) ?  "الكميات غير متطابقه" : ""
                                }
                            </small>
                        </div>
                </> */}
               
                <TotalPriceInput className="col-3" data={data} setData={setData} />

                <InputTax className="col-5"  data={data} setData={setData} />

                <InputTextArea name="more_info" title="ملاحظات" value={data.more_info} handleInputChange={handleInputChange} />

            </EditForm>

        </div>
    )
}
