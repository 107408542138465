const InputTextArea = ({
  handleInputChange,
  name,
  title,
  defaultValue,
  rows = 4,
  placeholder,
}) => {
  return (
    <>
      <div className="my-3 col-12 col-md-12">
        <div className="form-group" data-error="">
          <label htmlFor={name}>{title}</label>
          <textarea
            name={name}
            id={name}
            rows={rows}
            onChange={handleInputChange}
            placeholder={placeholder || title}
            defaultValue={defaultValue || ""}
            className="form-control mt-2"></textarea>
        </div>
      </div>
    </>
  );
};

export default InputTextArea;
