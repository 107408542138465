const GreenBox = (props) => {
    const { img , title , value} = props
    return (
        <div className="green-box gap-0 g-0">
                <div className="col-2">
                    <div className="green-box-img text-center w-[50px]">
                        {img ? <img src={img} className="w-[40px] h-[40px] block"  alt=""/> : ''}
                    </div>
                </div>
                <div className="col-10">
                    <div className="green-box-content box-content text-center">
                        <p>{title}</p>
                        <p>{value}</p>
                    </div>
                </div>
        </div>
    )
}

export default GreenBox
