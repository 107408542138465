import MainContainer from "../../components/MainContainer"
import HomeDetails from "./HomeDetails"
import HomeDetailsIndex, { homeDetails } from "./HomeDetailsIndex"
import HomeEggsWarehouse from "./HomeEggsWarehouse"
import HomeLevels from "./HomeLevels"
import HomeReports from "./HomeReports"
import CartonsOrders from "./orders/CartonsOrders"
import DishesOrders from "./orders/DishesOrders"
import DrugsOrders from "./orders/DrugsOrders"
import FeedOrders from "./orders/FeedOrders"
import HomeOrdersIndex from "./orders/HomeOrdersIndex"
import MaintenanceOrders from "./orders/MaintenanceOrders"
import OthersOrders from "./orders/OthersOrders"
import PackagingOrders from "./orders/PackagingOrders"
import ProductionReports from "./reports/production"
import HomeTransfers from "./transfers/HomeTransfers"
import { Route } from "react-router-dom"


const home_routes = {
    router : (protectComponent)=>{


        return  <Route path="home/:id" element={protectComponent( <MainContainer details={homeDetails} /> )}>
                    <Route index element={protectComponent(<HomeDetailsIndex />)} />
                    <Route path="details">
                        <Route index element={protectComponent(<HomeDetails />)} />
                        <Route path="stages" element={protectComponent(<HomeLevels />)} />
                    </Route>
                    <Route path="reports" element={protectComponent(<HomeReports />)}>
                        <Route path="feed" element={protectComponent( <ProductionReports.Feed /> )} />
                        <Route path="eggs" element={protectComponent( <ProductionReports.Eggs /> )} />
                        <Route path="dead" element={protectComponent( <ProductionReports.Dead /> )} />
                        <Route path="drugs" element={protectComponent( <ProductionReports.Drugs /> )} />
                        <Route path="maintenance" element={protectComponent( <ProductionReports.Maintenance /> )} />
                        <Route path="other" element={protectComponent( <ProductionReports.Other /> )} />
                        <Route path="cartons" element={protectComponent( <ProductionReports.Cartons /> )} />
                        <Route path="dishes" element={protectComponent( <ProductionReports.Dishes /> )} />
                    </Route>
                    <Route path="orders" element={protectComponent(<HomeOrdersIndex />)}>
                        <Route path="feed" element={protectComponent(<FeedOrders />)} />
                        <Route path="packaging" element={protectComponent(<PackagingOrders />)} />
                        <Route path="maintenance" element={protectComponent(<MaintenanceOrders />)} />
                        <Route path="drugs" element={protectComponent(<DrugsOrders />)} />
                        <Route path="other" element={protectComponent(<OthersOrders />)} />
                        <Route path="cartons" element={protectComponent(<CartonsOrders />)} />
                        <Route path="dishes" element={protectComponent(<DishesOrders />)} />
                    </Route>
                    <Route path="transfers" element={protectComponent(<HomeTransfers />)}/>
                    <Route path="eggs-warehouse" element={protectComponent(<HomeEggsWarehouse />)}/>
        </Route>

        
      

       
    }
}

export default home_routes