import {Grid, PageMeta, Signboard, TableHeader, useFetch} from "../../../../config/imports";
import {departments_api} from "../../../../config/apis";
import {useParams} from "react-router-dom";
import {useState} from "react";
import "../../../../styles/reports.styles.scss"

export default function DailyHomeReport(props){

    const {id : homeId} = useParams();

    // let {
    //     data : reports ,
    //     isLoading : reportsLoading,
    //     error : reportErrors,
    //     setData : setReports
    // } = useFetch(departments_api.homes(homeId))


    // const signboard = {
    //     error : reportErrors ,
    //     loading : reportsLoading
    // }
    let [reports , setReports] = useState([
        {
            home : homeId ,
            date : "2021-02-04",
            died_chicken : 1,
            died_cock : 1,
            day_age : 1,
            week_age : 1,
            month_age : 1,
            year_age : 1,
            week: 1,
        }
    ])
    const sizes = {
        serial : 1,
        date : 2,
        week : 1,
        chicken_count : 1,
        dead_chicken_count : 1,
        dead_chicken_percentage : 1,
        cock_count : 1,
        dead_cock_count : 1,
        dead_cock_percentage : 1,
        birds_feed_consumption : 3,
        total_feed_consumption : 3,
        feed_consumption_value_for_birds :3,
        total_feed_consumption_value : 3
    }

    return(
        <div className={'home-reports'}>
            <PageMeta title="التقرير اليومي" />
            {/*<Signboard {...signboard} />*/}
            <TableHeader  buttons={[
                { title : 'إضافة تقرير يومي' , onclick : () => {
                        console.log('add new report') }}
            ]} />

            <div className="mt-3">&nbsp;</div>
            <Grid.Flex className={'mt-5 data-table-head reports-header'}>
                <Grid.Span size={sizes.serial}></Grid.Span>
                <Grid.Span size={sizes.date}> التاريخ </Grid.Span>
                <Grid.Span size={sizes.week}>الأسبوع</Grid.Span>
                <Grid.Span size={sizes.chicken_count}> عدد الدجاج</Grid.Span>
                <Grid.Span size={sizes.dead_chicken_count}> النافق دجاج  </Grid.Span>
                <Grid.Span size={sizes.dead_chicken_percentage}>نسبة النافق</Grid.Span>
                <Grid.Span size={sizes.cock_count}> عدد الديوك </Grid.Span>
                <Grid.Span size={sizes.dead_cock_count}>نافق الديوك</Grid.Span>
                <Grid.Span size={sizes.dead_cock_percentage}> نسبة النافق</Grid.Span>
                <Grid.Span size={sizes.birds_feed_consumption}>  أستهلاك العلف للطير</Grid.Span>
                <Grid.Span size={sizes.total_feed_consumption}>   مجموع أستهلاك العلف</Grid.Span>
                <Grid.Span size={sizes.feed_consumption_value_for_birds}>      قيمة أستهلاك العلف للطير  </Grid.Span>
                <Grid.Span size={sizes.total_feed_consumption_value}>      قيمة مجموع أستهلاك العلف  </Grid.Span>
            </Grid.Flex>
        </div>
    )
}
