import {useEffect, useMemo} from "react";

export default function OrderMonthDays(props){

    const {days} = props

    const monthDays = useMemo(() => {
        if (!days?.month_data) return []; // Return an empty array if month_data is not available

        return days.month_data.map((d) => (
            <div className='month-day txt' key={d.timestamp}>
                {d.timestamp}
            </div>
        ));
    }, [days]); // Memoize the result based on the 'days' variable

    return <div className='b1'>
        <div className="month-days-header">
            <div className='h-40 txt  bb-0 bt-0 dim' key={'home'}>البيت</div>
            <div className='h-40 txt  bb-0 dim' key={'consumption'}>الاستهلاك للطير</div>
            <div className='h-40 txt  bb-0 dim' key={'birds'}>عدد الطيور</div>
            <div className='h-40 txt  bb-0 dim' key={'size'}>حجم السايلو</div>
        </div>
        {monthDays}
    </div>;


}
