import livingInputs from "./livingInputs";
import housingInputs from "./housingInputs";
import landInputs from "./landInputs";
import vehiclesInputs from "./vehiclesInputs";
import roadsInputs from "./roadsInputs";
import toolsAndEquipmentsInputs from "./toolsAndEquipmentsInputs";
import buildingsInputs from "./buildingsInputs";

const origin_cards_tables = {
    building: buildingsInputs,
    "poultry-house": housingInputs,
    land: landInputs,
    biological: livingInputs,
    car: vehiclesInputs,
    road: roadsInputs,
    machine: toolsAndEquipmentsInputs,
}

export default origin_cards_tables
