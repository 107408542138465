import {InputNumber, InputText} from "../../../../config/imports";

const livingInputs= [
    {name : 'name', title :  'الاسم', input :  InputText},
    {name : 'quantity', title :  'عدد الأصل', input :  InputNumber},
    {name : 'location', title :  'موقعها', input :   InputText },
    {name : 'characteristic', title :  'صفة الأصل', input :  InputNumber , size : 6},
    {name : 'value', title :  ' قيمة الأصل', input :  InputNumber , size : 6},
]
export default livingInputs
