import '../../styles/stats.styles.scss'
import StatsGreenBoxes from "./components/StatsGreenBoxes";
import StatsCharts from "./components";
import PageMeta from "../../components/PageMeta";
import {useEffect, useState} from "react";
import FarmProductionByWorkYears from "./FarmProductionByWorkYears";
import StatsImportantInfo from "./StatsImportantInfo";
import { setStatsBreadCrumbs } from '../../components/breadcrumbs/store/breadcrumbs.default';
export default function StatsIndex(props){


    const [component , setComponent] = useState(<StatsImportantInfo/>)

    const navHandler = (e , Component) => {

        const {component} = e.target.dataset

        const btns = document.querySelectorAll('.nav-btn');
        // remove active class
        btns.forEach((btn) => btn.classList.remove('active-nav-btn'))
        // add active class to this
        e.target.classList.add('active-nav-btn')

        setComponent(<Component/>)

    }


    useEffect(() => {
        setStatsBreadCrumbs()
    } , [])

    return(
        <div className="stats-index">
            <PageMeta title="الإحصائيات" />
            <div className="my-3">
                <StatsGreenBoxes />
            </div>
            <div className="row">
                <div className="col-8 my-3">
                    <div className="stats-component">
                        <StatsCharts.Weights />
                    </div>
                </div>
                <div className="col-4 my-3">
                    <div className="stats-component">
                        <StatsCharts.Birds />
                    </div>
                </div>

                <div className="col-8 my-3">
                    <div className="stats-component">
                        <StatsCharts.Operators />
                    </div>
                </div>
                <div className="col-4 my-3">
                    <div className="stats-component">
                        <StatsCharts.Balances />
                    </div>
                </div>
            </div>

            <div className="mt-3 mb-5">
                <button onClick={(e)=> navHandler(e , StatsImportantInfo)} className="nav-btn active-nav-btn"  >تفاصيل مهمة  </button>
                <button onClick={(e)=> navHandler(e , FarmProductionByWorkYears)} className="nav-btn" >    إنتاج المزرعة حسب سنوات العمل  </button>
                <div className="nav-component-container my-3">
                    {component && component }
                </div>
            </div>


        </div>
    )
}
