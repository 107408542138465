import React, { useState } from 'react'
import HomeEggsWarehouse from './HomeEggsWarehouse';
import HomeStorageDetails from './HomeStorageDetails';
import { cn } from '../../../helpers/class_name.helpers';




export default function HomeDetailsTabs({data , hasRoosterInfo}) {


    const [component , setComponent] = useState(<HomeStorageDetails />)


      
  const navHandler = (e , Component) => {
      const target = e.target
      document.querySelectorAll('.nav-btn').forEach(btn => btn.classList.remove('active-nav-btn'))
      target.classList.add('active-nav-btn')
      setComponent(<Component/>)

  }



  return data && data.home && (
    <>
           {hasRoosterInfo &&  <button onClick={(e) => navHandler(e,HomeEggsWarehouse)}   className={cn(
            'nav-btn',
            hasRoosterInfo &&  "active-nav-btn"
           )}  > مستودع البيض الخاص بالمرحله  </button> }

            <button onClick={(e) => navHandler(e , HomeStorageDetails)} className={cn(
              "nav-btn",
              !hasRoosterInfo &&  "active-nav-btn"
              )}>    تفاصيل مستودعات البيت  </button>
            <div className="nav-component-container my-3">
              {
                React.cloneElement(component , {data : data})
              }
            </div>
    </>
  )
}
