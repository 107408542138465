import React, { useEffect, useState } from 'react';
import { useBreadCrumbStore } from '../store/breadcrumb.store';
import BreadCrumb from './BreadCrumb';
import BreadCrumbItem from './BreadCrumbItem';

export default function BreadCrumbs() {
  const crumbs = useBreadCrumbStore(state => state.crumbs);

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);


  return (
    <div className="flex items-center">
      {crumbs.map((c, i) => {
        const delayStyle = { animationDelay: `${i * 0.2}s` };

        return (
          <div
            key={i}
            className={`breadcrumb-item ${loaded ? 'breadcrumb-item-loaded' : ''}`}
            style={delayStyle}
          >
            {i === crumbs.length - 1 ? (
              <BreadCrumbItem name={c.name} to={c.to}  />
            ) : (
              <BreadCrumb name={c.name} to={c.to} />
            )}
          </div>
        );
      })}
    </div>
  );
}
