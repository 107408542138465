import ArrayHelpers from "../../helpers/array.helpers";
import {useEffect, useRef, useState} from "react";
import "../../styles/select.styles.scss";


const InputSelect = (props) => {


    const selectOptionsRef = useRef(),
        selectInputRef = useRef(),
        selectRef = useRef()

    const {options : data , name , multiple , defaultValue , value , nameKey , handleInputChange } = props

    const [absoluteOptions , setAbsoluteOptions] = useState([])
    let   [options , setOptions] = useState([])
    let   [selectedOptions , setSelectedOptions] = useState([])
    let   [selectValue , setSelectValue] = useState([])

    useEffect(()=>{
        if(data){
            const optionsForSelect  =   [...ArrayHelpers.optionsV2(data , nameKey ?? name)]
            setAbsoluteOptions( optionsForSelect  )
            setOptions( optionsForSelect  )
        }
    } , [data])


    useEffect(()=>{

        if (defaultValue){
            if(multiple){
                const defaultOptions = absoluteOptions?.filter( opt => defaultValue.includes(opt.value))
                if(!defaultOptions && defaultOptions.length === 0) return
                selectedOptions = []
                selectedOptions.push(...defaultOptions)
                setSelectedOptions( selectedOptions )
                options = [...remainingOfSelected()]
                setOptions(options)
            }else {
                // find value in the options
                const option = absoluteOptions?.filter( opt => opt.value === defaultValue)[0]
                if(!option) return // console.warn("the value " + defaultValue + " of " + name + " does not exist on the options" + " ~ [seada 😌]")
                else {
                    selectedOptions = []
                    selectedOptions.push({value : option?.value , text : option?.text})
                    setSelectedOptions( selectedOptions )
                    options = [...remainingOfSelected()]
                    setOptions(options)
                }
            }
        }


    } , [defaultValue , absoluteOptions ])


    useEffect(()=>{
        if(selectedOptions && selectedOptions.length > 0){
            selectInputRef?.current?.setAttribute('placeholder' , '')
        }else{
            selectInputRef?.current?.setAttribute('placeholder' , 'اخنر من القائمة')
        }
    } , [selectedOptions])



    const closeAllOptionsList = ()=>{
        [...document.querySelectorAll('.__select-options')].forEach(sel =>{
            sel.classList.remove('__select-options-active')
        })
    }

    const remainingOfSelected = ()=>{
        const selectedOptionsIds = selectedOptions?.map(opt => opt.value)
        return absoluteOptions.filter(opt => !selectedOptionsIds.includes(opt.value))
    }

    useEffect(()=>{
        window.onclick = e =>{
            const elm =  e.target
            const isOptions = elm.closest('.__select-options')
            const isInput = elm.closest('.input')
            if(!isOptions && !isInput)  return closeAllOptionsList()
        }
    })

    const FireChangeEvent = ()=>{

        if (multiple) {
            const selectedValues = selectedOptions?.map(opt => opt.value) || [];
            selectValue = [...selectedValues]
            setSelectValue(selectValue)
            selectRef.current.value = selectedValues;
        } else {
            selectValue = selectedOptions[0]?.value ?? ''
            setSelectValue(setSelectValue)
            selectRef.current.value = selectedOptions[0]?.value ?? ""
        }


        selectRef.current?.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }));


    }






    const handleDataSelect = (e)=>{
        const {value} = e.target.dataset
        const text = e.target.textContent.trim()

        if(multiple){
            selectedOptions.push({value , text})
            setSelectedOptions([...selectedOptions])
            // filter options that is not selected
            options = [...remainingOfSelected()]
            setOptions(options)
            FireChangeEvent()
            // setMultipleValue()
        }else {
            selectedOptions = [{value , text}]
            setSelectedOptions([...selectedOptions])
            closeAllOptionsList()
            FireChangeEvent()
        }
    }

    const handleInput = (e)=>{
        closeAllOptionsList()
        selectOptionsRef.current?.classList.add('__select-options-active')
        // check if selected the find intersection then render  remaining options
        options = [...remainingOfSelected()]
        setOptions(options)

    }

    const handleSearch = e =>{
        const {value} = e.target
        // console.log("hello");
        // console.log(option);
        // console.log({absoluteOptions})
        const visibleOptions = absoluteOptions.filter(option => option.text.toString().toLowerCase().includes(value))
        // need to filter selected options
        const selectedOptionsIds = selectedOptions?.map(opt => opt.value)
        const filteredOpts = visibleOptions.filter(opt => !selectedOptionsIds.includes(opt.value))
        options = [...filteredOpts]
        setOptions(options)
    }



    const handleDeleteSelectedOption = e =>{
        const id = e.target.id
        selectedOptions = selectedOptions.filter(opt => opt.value !== id)
        setSelectedOptions([...selectedOptions])
        FireChangeEvent()
    }

    const onChangeHandler = (e) => {
        const {name} = e.target
        e.target.value = selectValue  // does not work !

        const event = {
            target : {
                name ,
                value : selectValue,
                type : 'select',
                event : e
            }
        }

        handleInputChange(event)

    }


    return (
        <div className={`${props.className ?? 'col-12 col-md-4 col-lg-4'}`}>
            <div className={`_select `}>
                {
                    !props.hideLabel && <p>{props.label ?? props.title ?? props.name ?? 'اختر من القائمه' }</p>
                }
              
                <div className="__select">
                    <div  className={'input'}>
                        <div className="selected-options">
                            {
                                selectedOptions?.map(({value , text} , index) =>{
                                    return <span className="__selected-option" key={index}  onClick={handleDeleteSelectedOption} id={value}>{text}</span>
                                })
                            }
                            <input type='text'  onChange={handleSearch} onClick={handleInput} onFocus={handleInput} ref={selectInputRef}  placeholder='اختر من القائمة' className={'select-input-search'}/>
                        </div>
                    </div>
                </div>
                <div className="__select-options" ref={selectOptionsRef}>
                    {
                        options?.map(({value , text} , index) => <div className='__select-option' onClick={handleDataSelect} key={index} data-value={value}> {text} </div>)
                    }
                </div>
                <select value={value}
                        defaultValue={defaultValue}
                        onChange={onChangeHandler}
                        data-value={JSON.stringify(defaultValue ?? value)}
                        name={name} multiple={multiple ?? false}
                        ref={selectRef}
                        data-selected={''}
                        className="!hidden"
                >
                    <option value=''>اختر من القائمة</option>
                    {
                        options && options?.map((item) =>  <option  key={item.value} value={item.value}>{item.text}</option>)
                    }
                </select>
            </div>
        </div>
    )
}

export default InputSelect

