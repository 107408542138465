import React, { useEffect, useState } from 'react'
import { apis } from '../../config/apis'
import useFetch from '../../hooks/useFetch'
import PageMeta from '../../components/PageMeta'
import { Table } from '../../layout/table'
import { _date } from '../../helpers/date.helpers'
import Icons from '../../layout/Icons'
import { Link, useSearchParams } from 'react-router-dom'
import HomesFilter from './components/HomesFilter'
import DepartmentsFilter from './components/DepartmentsFilter'
import ProjectsFilter from './components/ProjectsFilter'
import DateFilter from './components/DateFilter'
import useSetUrlQuery from '../../hooks/useSetUrlQuery'
import { TableHeader } from '../../components/TableView'
import { setBreadCrumbsStore } from '../../components/breadcrumbs/store/breadcrumb.store'
import { approvalsBreadCrumb, homeBreadCrumb } from '../../components/breadcrumbs/data/breadcrumbs.data'


export default function Approvals() {
    const api = apis.approvals.paginatedApprovals()
    
    // const  setUrlQuery   = useSetUrlQuery("/approvals?tab=reports")

    // const [searchParams] = useSearchParams()

    const [query , setQuery] = useState({
      home : "",
      department : "",
      project : "",
      start_date : "",
      end_date : "",
      week_age : "",
    })



    const {data , Alert , setters , paginate } = useFetch(api   , query)



    // useEffect(() => {
    //     let queryString = ""
    //     const _query = Object.entries(query)

    //     // console.log({_query})

    //     _query.forEach(([key , value]) =>{
    //         if(value === "" || value === null || value === undefined) return
    //         else return  queryString += `&${key}=${value}`
    //     })
      
   
    //     // console.log(queryString);
    //     setUrlQuery(queryString.slice(1))

    // } , [query])







    // useEffect(() => {
    //   // setRefresh()
    //   const _tab = searchParams.get("tab")
    //   const _start_date = searchParams.get("start_date")
    //   const _end_date = searchParams.get("end_date")
    //   const _home = searchParams.get("home")
    //   const _department = searchParams.get("department")
    //   const _project = searchParams.get("project")

    //   // setQuery(pre => ({...pre , home : _home , department : _department , project : _project , start_date : _start_date , end_date : _end_date}))


    //   console.log({_tab , _start_date , _end_date , _home , _department , _project})

    //   // console.log({_query})

    // } , [searchParams])



    useEffect(()=>{
      setBreadCrumbsStore([
          homeBreadCrumb,
          approvalsBreadCrumb,
      ])
    } , [])



    const table = [
      {size : 1 , name : "#" , text : "#"  },
      {size : 2 , name : "ar_type" , text : "النوع"  },
      {size : 3 , name : "home" , text : "البيت"  },
      {size : 3 , name : "department" , text : "القسم"  },
      {size : 3 , name : "project" , text : "المشروع"  },
      {size : 3 , name : "date" , text : "تاريخ الانشاء"  , custom : d => _date(d.date).dmy()   },
      {size : 3 , name : "created_by" , text : "اُنشئ بواسطة"  },
      {size : 3 , name : "last_updated_by" , text : "تم التحديث بواسطة"  },
      {size : 3 , name : "date" , text : ""  , custom : d => {
         return <Link to={"/approvals/" + d._id} className='flex items-center'>
                  <span className='ml-2'> تفاصيل  </span>
                  <div className='scale-[.9]'>
                    <Icons.Arrow />
                  </div>
         </Link>
      }  },

    ]

  return (
    <div className='page'>
      <PageMeta title={"التقارير"}  />
      <TableHeader buttons={[]}    api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate}  />
      <div className="row items-center ">
        <div className="col-3"><ProjectsFilter setQuery={setQuery} /> </div>
        <div className="col-3"><DepartmentsFilter setQuery={setQuery} /> </div>
        <div className="col-3"><HomesFilter setQuery={setQuery} /> </div>
        <div className="col-3 relative top-[-4px]"><DateFilter setQuery={setQuery} /> </div>
      </div>
      <Alert />
      <Table data={data}  table={table} />
    </div>
  )
}
