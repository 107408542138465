import "../../styles/home.styles.scss";
import { useParams } from "react-router-dom";
import GreenBoxes from "./components/GreenBoxes";
import BlueBoxes from "./components/BlueBoxes";
import HomeDetailsTable from "./components/HomeDetailsTable";
import HomeDetailsTabs from "./components/HomeDetailsTabs";
import PageMeta from "../../components/PageMeta";
import { usePostFetch } from "../../hooks/usePost";
import { apis } from "../../config/apis";
import useHomeDetailsData from "./hooks/useHomeDetailsData";
import useHomeType from "./reports/hooks/useHomeType";
import useHomeBreadCrumbs from "./hooks/useHomeBreadCrumbs";

export default function HomeDetails(props){

const {id} = useParams();

const  {  data , Alert }  = useHomeDetailsData()

const {hasRoosterInfo} = useHomeType()


useHomeBreadCrumbs()



return(
    <>
        <PageMeta title={'تفاصيل البيت'} />
    
        <Alert />

        <div className="w-[90%] m-auto ">
            <GreenBoxes  hasRoosterInfo={hasRoosterInfo}   data={data} />
        </div>
        <div className="w-[80%] m-auto ">
            <BlueBoxes hasRoosterInfo={hasRoosterInfo}   data={data}  />
        </div>

        <HomeDetailsTable data={data} />
        
        <HomeDetailsTabs  hasRoosterInfo={hasRoosterInfo} data={data} />



    </>
)
}
