import {useState} from "react";
import {apis, reports_api, weight_api} from "../../../../config/apis";
import {setInputsToDefault, updateFormHandler} from "../../../../helpers/form.helper";
import {
    EditForm, InputNumber,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../config/imports";

import ArrayHelpers from "../../../../helpers/array.helpers";
import InputMassUnit from "../../../../components/Shared/InputMassUnit";

export default function Update(props){

    const {toggle , item , setMainData , operators } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name: item.name,
        measuringUnit: item.measuringUnit,
        types: item.types,
        operator: item.operator?._id,
        packetsNum: item.packetsNum,
        order: item.order,
        classification: item.classification,
        info: item.info,
    })

    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.origins.packaging.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
        console.log(update_props.data)


        await updateFormHandler(update_props);
    };
    // console.log({item})
    return(
        <>
  

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>  تعديل نوع التعبئه والتغليف</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>


                <InputText name={'name'} title={'الاسم'} defaultValue={data.name} />
                <InputSelect name={'operator'} title={'المورد '} defaultValue={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />
                <InputMassUnit setData={setData} value={data.measuringUnit}/>


                <InputSelect  name={'types'} title={'النوع '} defaultValue={data.types}  options={[
                    { _id : "Free" , name : "Free" },
                    { _id : "S" , name : "S" },
                    { _id : "XXL" , name : "XXL" },
                ]} />

                <InputNumber name={'packetsNum'} title={'عدد الرزمة'} defaultValue={data.packetsNum} onChange={handleInputChange} />
                <InputNumber name={'order'} title={'الطبيلة'} defaultValue={data.order} onChange={handleInputChange} />
                <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />

            </EditForm>
        </>
    )
}
