import React from 'react'
import PropTypes from 'prop-types'
 function ScrollX(prop) {
    const {className , children} = prop
  return (
    <div className={' scroll-x ' + (className ?? "")}>
        {children}
    </div>
  )
}

ScrollX.propTypes = {
    children : PropTypes.any,
    className : PropTypes.string
}


export default ScrollX


