import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete";
// import DepositInIncubation from "./DepositInIncubation";
const ClientsCrud = {
  Create,
  Update,
  Delete,
};
export default ClientsCrud;
