import {useState} from "react";

import {
    setInputsToDefault,
    createFormHandler,
} from "../../../../../helpers/form.helper";

import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";
import {strainAPI} from "../../../../origin/bird_breed/bird_breed.routes"

import InputText from "../../../../../components/FormControls/InputText";
import useFetch from "../../../../../hooks/useFetch";
import {operatorAPI} from "../../../../operators/operator.routes";
import EditForm from "../../../../../components/FormControls/EditForm";
import InputNumber from "../../../../../components/FormControls/InputNumber";
import SelectOperator from "../../../../../components/Shared/SelectOperator";
import InputDate from "../../../../../components/FormControls/InputDate";
import InputTextArea from "../../../../../components/FormControls/InputTextArea";
import InputSelect from "../../../../../components/FormControls/InputSelect";

const FORM_HEADER = "اضافة تقرير خروج";
const MAIN_TITLE = "قائمة تقارير الخروج";

const UpdateDrugsExportReport = ({setMainData, item , toggle}) => {


    const [data, setData] = useState(item);
    const {data: ops} = useFetch(operatorAPI.url)
    let names = Object.keys(data);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const create_props = {
            url: strainAPI.url,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER,
        };

        createFormHandler(create_props);
    };

    const handleInputChange = (e) => {

        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    return (

        <div className="temp-form">
            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>

           <Signboard error={error} success={success} loading={loading}/>


            <EditForm  onSubmit ={handleFormSubmit} toggle={toggle}   onChange={handleInputChange}>
                <InputText  title={"المشروع"} />
                <InputText title={"القسم"} />
                <InputSelect title={"البيت"} options={ops}  />
                <InputNumber title={"نوع الدواء"} />
                <InputNumber title={"الكمية"} />
                <SelectOperator title={"المستلم"} defaultValue={data.operator._id}  />
                <InputTextArea title={"معلومات اضافية"} />
            </EditForm>

        </div>
    );
};

export default UpdateDrugsExportReport;
