import {
    createFormHandler,
    EditForm, InputCheckBox,
    InputText,
    PageMeta,
    setInputsToDefault,
    Signboard,
    useFetch
} from "../../config/imports";
import {useEffect, useState} from "react";
import {roles_api, users_api} from "../../config/apis";

export default function CreateRolesForm(props) {

    const  {setMainData , toggle , permissions , setRefresh } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let [ inputPermissions , setInputPermissions ] = useState([{}])
    const [roleName , setRoleName] = useState("")


    let [permissionsIds , setPermissionsIds] = useState([])


    useEffect(() => {

        if(permissions)
        {
            inputPermissions = [...permissions].map((permission) => {
                return {
                    name : permission.resources,
                    actions : [...permission.actions]
                }
            })
            setInputPermissions([...inputPermissions])
        }

    } , [permissions])


    const signboard = {
        error :   error,
        loading :   loading,
        success : success
    }
    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: roles_api.role,
            data: {
                role : roleName,
                permissions : permissionsIds.map(permission => permission._id)
            },
            setLoading,
            setError,
            setSuccess,
            // setMainData : ()=>{},
            setRefresh,
            setInputsToDefault,
            setData: setPermissionsIds,
            FORM_HEADER: `الاضافة`,
        };

        await createFormHandler(create_props);

    }


    const handleCheckBox = (e) => {
        const {name, checked , id} = e.target;

        if (checked) {
            permissionsIds = [...permissionsIds, {_id : id , name}]
            setPermissionsIds( [...permissionsIds])
        } else {
            permissionsIds = [...permissionsIds.filter(permission => permission._id !== id)]
            setPermissionsIds( [...permissionsIds] )

        }
        console.log(permissionsIds)
    }


    return (
        <>
            <PageMeta title={'انشاء دور'} originTitle={'الادوار'}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>{'انشاء دور'}</h3>
            </header>
            <Signboard {...signboard}/>

            {/*<EditForm onSubmit={handleFormSubmit}   toggle={toggle} onChange={handleInputChange}> />*/}

            <form action="" onSubmit={handleFormSubmit}>


                {/*<InputText label={'اسم الدور'}  title={'اسم الدور'} name={'name'} onchange={handleInputChange}/>*/}

                <div className="col-12 col-md-12 col-lg-12">
                    <div data-error="" className="form-group">
                        <label htmlFor='role'>اسم الدور</label>
                        <input
                                type='text'
                                name='role'
                                id='role'
                                required
                                placeholder='مدير مشروع'
                                value={roleName}
                                onChange={ (e) => setRoleName(e.target.value)  }
                                className="form-control"
                                title='اسم الدور'
                        />
                    </div>
                </div>
                <>
                    {
                        inputPermissions && inputPermissions?.map((permission , index) => {
                            return (
                                <div className='row mx-2 align-items-center' key={permission._id ?? index }>
                                    <div className="col-6"> {permission.name}</div>
                                    <div className="col-6">
                                        <div className="row align-items-center" key={permission._id} >
                                            { permission?.actions &&  permission?.actions?.map((action) => {
                                                return (
                                                        <div dir='ltr' className='col-3  crud-control' key={action._id}>
                                                            <div><label htmlFor={action._id}> {action.action} </label></div>
                                                            <div><input onChange={handleCheckBox} name={action.name} type={'checkbox'} id={action._id} /></div>
                                                        </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <hr className="my-0" />
                                </div>
                            )
                        })
                    }
                </>



            </form>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button onClick={() => toggle() } className="close-btn">
                            الغاء
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        <button className="add-btn" onClick={handleFormSubmit}>
                            اضافة
                        </button>
                    </div>
                </div>
            </div>
            {/*</EditForm>*/}
            </>
    )

}



