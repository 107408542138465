import LinkList from "../../../components/LinkList";
import { RouterLinks } from "../../../components/LinkList";
import useNavigator from "../../../hooks/useNavigator";
import "../../../styles/base-card.css";
const ItemCards = () => {
  const ITEMS_CARDS_LINKS = [
    {
      name: "الاعلاف",
      path: "feed",
    },
    {
      name: "التعبئة والتغليف",
      path: "packaging",
    },
    {
      name: "الادوية",
      path: "drugs",
    },
    {
      name: "قطع الغيار",
      path: "maintenance",
    },
  ];

  useNavigator("item-cards", "feed");

  return (
    <div>{<RouterLinks data={ITEMS_CARDS_LINKS} />}</div>
    // <div className="base-card">
    //   <div className="ilc mt-2 bg-white px-3 py-3 r-16">
    //     <div className="icbc">
    //       <div className="base-card-links-container">
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ItemCards;

// warehouse-details / index /

// ItemCards > index > [feed , drug , ...]
// requests > index > [feed , drug , ...]
