import Create from './Create';
import Update from './Update';
import Delete from './Delete';
import Status from './Status';

const Breeding = {
    Create,
    Update,
    Delete,
    Status
}
export default Breeding
