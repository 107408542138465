import React from 'react'
import PropTypes from 'prop-types'
import EggsWarehouseSelector from './EggsWarehouseSelector'
import useEggsWarehouseStore from './store/eggs_warehouse.store'
function EggsWarehouseSelectors(props) {
  const {addToSelectedItems , removeFromSelectedItems , selected} = useEggsWarehouseStore(state => state)
    const {data , type , name , id} = props

  return (
    <div className="flex  flex-col">
    {
        data?.map(item => <EggsWarehouseSelector
             type={type}
             addToSelectedItems={addToSelectedItems}  
             removeFromSelectedItems={removeFromSelectedItems}
             key={item[id]}
             name={item[name]}
             _id={item[id]}
             isSelected = {selected.find(i => i._id === item[id]) ? true : false}
             />)
    }

    </div>
  )

}


EggsWarehouseSelectors.propTypes = {
  data : PropTypes.array,
  type : PropTypes.string,
  name : PropTypes.string,
  id : PropTypes.string
}


export default EggsWarehouseSelectors


export const DepartmentsSelectors = props => <EggsWarehouseSelectors {...props} type="departments_ids" name="name_with_projects" id="_id"/> 
export const HomesSelectors = props => <EggsWarehouseSelectors {...props} type="homes_ids" name="home" id="home_id"/>
export const ProjectsSelectors = props => <EggsWarehouseSelectors {...props} type="projects_ids" name="name" id="_id"/>