import {Signboard, useFetch} from "../../config/imports";
import {apis, homes_api} from "../../config/apis";
import {Table, TableBody} from "../../layout/table";
import {useParams} from "react-router-dom";

export default function StatsImportantInfo(props){

    const {id} =  useParams()
    const {data , error , isLoading} = useFetch(apis.stats.importantStats())

    const signboard = { error , loading : isLoading}

    const table = [
        // { name : '#' , size : 1  , text : '#'},
        { name : 'tittle' , size : 4  , text : 'المرحله'},
        { name : 'all_feed_reports_amount' , size : 3  , text : 'استهلاك العلف ' },
        // { name : 'all_feed_invs_amount' , size : 4  , text : ' كمية فواتير الاعلاف'},
        // { name : 'feed_inv_count' , size : 3  , text : 'قيمة فواتير الأعلاف'},
        { name : 'all_dead_birds' , size : 3  , text : 'النافق'},
        { name : 'totaled_birds' , size : 3 , text : 'التراكمي'},
        { name : 'production_balance' , size : 3  , text : 'البيض المنتج' ,},
        { name : 'production_balance' , size : 4  , text : 'رصيد الانتاج' ,  },
        { name : 'productivity' , size : 4 , text : 'نسبة الانتاج'  },
        // { name : '' , size : 1  , text : ''}
    ]

    return(
        <div className={'bg-white'}>
            <Signboard {...signboard} />
            {data && <Table table={table} data={[data?.opining_levels]} />}
            {data && data?.closed_levels && <TableBody table={table} data={[data?.closed_levels]} />}
            {data && data?.sum_of_levels && <TableBody table={table} data={[data?.sum_of_levels]} />}
        </div>
    )
}
