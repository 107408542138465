import { useState } from 'react'
import { useAuthContext } from './useAuthContext'
import {auth_api} from "../config/apis";


// const geoLocationData = async () => {
//     try {
//         const response = await fetch("http://ip-api.com/json/")
//         const json = await response.json()
//         const { country , region , city , query : ip ,  lat , lon  , timezone , isp , as } = json
//         return { geo : { country , region , city ,  ip ,  lat , lon  , timezone , isp , as }  , error : null}
//     }catch(ex){
//         return { error : ex.message , geo : null }
//     }
// }


const getUserDeviceData = () => {
    return {  device : { browserName : navigator.userAgent , platform : navigator.platform }  };
}




export const useLogin = () => {
    const [error, setError] = useState(null)
    const [isLoading, setIsLoading] = useState(null)
    const { dispatch } = useAuthContext()





    const login = async (email, password) => {
        setIsLoading(true)
        setError(null)

       // const {geo}  = await geoLocationData()
        const {device} =  getUserDeviceData()
        const response = await fetch(auth_api.login, {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({ email, password ,
                // geo ,
                device })
        })
        // console.log(response)
        const json = await response.json()

        if (!response.ok) {
            setIsLoading(false)
            setError(json.error)
        }
        if (response.ok) {
            // save the user to local storage
            localStorage.setItem('user', JSON.stringify(json.data))


            // update the auth context
            dispatch({type: 'LOGIN', payload: json.data})

            // update loading state
            setIsLoading(false)
        }else{
            setIsLoading(false)
            setError(json.error)
        }


    }

    return { login, isLoading, error , setError }
}























