import {useState} from "react";

import {
    setInputsToDefault,
    createFormHandler,
} from "../../../../../helpers/form.helper";

import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";
import {strainAPI} from "../../../../origin/bird_breed/bird_breed.routes"

import InputText from "../../../../../components/FormControls/InputText";
import useFetch from "../../../../../hooks/useFetch";
import {operatorAPI} from "../../../../operators/operator.routes";
import EditForm from "../../../../../components/FormControls/EditForm";
import InputNumber from "../../../../../components/FormControls/InputNumber";
import SelectOperator from "../../../../../components/Shared/SelectOperator";
import InputDate from "../../../../../components/FormControls/InputDate";
import InputTextArea from "../../../../../components/FormControls/InputTextArea";

const FORM_HEADER = "اضافة فاتورة ادوية و تحصينات";
const MAIN_TITLE = "قائمة قائمة شراء ادوية و تحصينات";

const AddDrugsInvoice = ({setMainData, toggle}) => {


    const [data, setData] = useState({
        packNo: 0,
        unitprice: 0,
        quantity: 0,
        operator: "",
        date: "",
        type: "",
        measuringUnit: "",
        total: 0,
        totalforhome: 0,
        home: [],
        info: "معلومات"
    });
    let names = Object.keys(data);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const create_props = {
            url: strainAPI.url,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER,
        };

        createFormHandler(create_props);
    };

    const handleInputChange = (e) => {

        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const WeightUnits = [
        {
            name: "طن",
            _id: "'طن"
        }, {
            name: "كيلو",
            _id: "'كيلو"
        }, {
            name: "جرام",
            _id: "'جرام"
        },
    ]
    return (
        <div className="temp-form">
            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>


            <EditForm  onSubmit ={handleFormSubmit} toggle={toggle}   onChange={handleInputChange}>
                <SelectOperator  />
                <InputText  title={"التصنيف"} />
                <InputText title={"نوع الدواء"} />
                <InputNumber title={"وحدة القياس"} />
                <InputNumber title={"وحدة القياس"} />
                <InputDate title={"تاريخ الفاتورة"} />
                <InputNumber className={"col-12 col-md-3"}   name={names[2]} title={"الكمية"} />
                <InputNumber className={"col-12 col-md-3"}  name={names[1]} title={"سعر الوحدة"} />
                <InputNumber className={"col-12 col-md-3"}  title={"السعر الاجمالي"}  defaultValue={data["quantity"] * data["unitprice"] }  isReadonly={true}  />
                <InputDate className={"col-12 col-md-3"}  title={"تاريخ انتهاء الصنف"} />
                <InputTextArea title={"معلومات اضافية"} />
            </EditForm>

        </div>
    );
};

export default AddDrugsInvoice;
