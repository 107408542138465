const destructureTable = (table) => {

    return {
        header :  table.slice(0,1) ,
        body :  table.slice(1,-1),
        footer :  table.slice(-1)
    }

}
const destructTableWithSelectedNames = (table , names)=>{
    // TODO : fix this
    //  if we pass a name the not exists in the table it will throw an error
    let {header , body , footer} = destructureTable(table)
    if(!names) return { header , body , footer , table : [...header , ...body , ...footer] }
    body = names.map( name =>{
        return body.filter(elm =>  elm.gen.name=== name)[0]
    } )
    return { header , body , footer , table : [...header , ...body , ...footer] }
}

const constructCreateStateDataFromTable = (table) => {
    return table.map((obj)=>{
        return {  name : obj.gen.name,  value :  obj.create.value }
    })
}



const constructUpdateStateDataFromTable = (table , data) => {

    const entries = table.map((obj)=>{
        return {
            name : obj.gen.name,
            value :  data[obj.gen.name]
        }
    })

    return entries.reduce((acc, cur) => {
        acc[cur.name] = cur.value;
        return acc;
    }, {});
}

const extractCreateData = (table_data , data) => {

    return table_data.map((item , index) => {
        return{
            elm : item.gen.custom || item.gen.elm ,
            props : {
                name : item.gen?.name ,
                title : item.gen.title ,
                label : item.create.props.label || item.gen.title  ,
                value : (() => {
                    if(item.create.props.customValue)
                        return item.create.props.customValue(data)
                    else
                        return data[item.gen?.name]
                })() ,
                defaultValue : (()=> {
                    if (item.create.props.customValue)
                        return item.create.props.customValue(data)
                    else
                        return data[item.gen.name]
                })(),
                placeholder : item.create.props.placeholder || item.gen.title ,
                className : `col-12 col-sm-12 col-md-${item.create.props.md || 4} col-lg-${item.create.props.lg || 4} ${item.create.props.className || ''} `,
                options : item.gen.options ,
                required : item.create.props.required || true,
                ...item.create.props
            }
        }
    })
}


const extractTableData = (table_data) => {

    // to be updated later
    // const {body} =  destructureTable(table_data)

    return  table_data.map((item , index) => {
        return{
            name : item.gen.name ,
            title : item.gen.title ,
            col : item.table.col ,
            custom : item.table.custom && item.table.custom ,
            className : item.table.className && item.table.className ,
        }
    })
}

const addColumn = (name , title , element , width=1 , props ={} , ...other )=>{

    return {
        gen: {name :name , title: title , elm : element },
        table: {col : width},
        create: {props: props, value: ''},
        update: {}
    }
}
const addToTable= (table , index , ...item)=>{
    table.splice(index, 0, ...item)
    return table
}

const sortArrayManually = (arr , indexes) => {
    let sortedArr = [];
    indexes.forEach(idx => {
        sortedArr.push(arr[idx - 1])
    })
    return sortedArr;
}
export  {
    destructureTable,
    constructCreateStateDataFromTable,
    extractCreateData,
    extractTableData,
    constructUpdateStateDataFromTable,
    addColumn,
    destructTableWithSelectedNames,
    addToTable,
    sortArrayManually
}
