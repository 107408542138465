import {TableHeader} from "../../../components/TableView";
import PageMeta from "../../../components/PageMeta";
import {_date, Grid, QuickView, Signboard, useFetch} from "../../../config/imports";
import {apis, projects_api, weight_api} from "../../../config/apis";
import {useEffect, useRef, useState} from "react";
import {accio} from "../../../helpers/axios/axios.get";

export default function WeightBalancesIndex(){
const initCreateRef = useRef()

    const [data , setData] = useState({
        year : new Date().getFullYear()
    })
    let [balances , setBalances] = useState([])
    const [loading , setLoading] = useState(false)
    const [success , setSuccess] = useState("")
    const [error , setError] = useState("")




    const  { data : balancesData , error : balanceError , isLoading , setData : setBalancesData }  = useFetch(apis.origins.weightBalance.all( new Date().getFullYear() ))

    const  { data : years , error : yearsError , isLoading : yearsIsLoading }  = useFetch(apis.origins.weightBalance.years())

    const {data : weights ,  error : weightsError , isLoading : weightsIsLoading }  = useFetch(weight_api.all)


const signboard = {
    error : error || yearsError || weightsError || balanceError,
    loading : isLoading || yearsIsLoading || weightsIsLoading || loading,
}



    // console.log({years})

    useEffect(()=>{


        if(balancesData){

            let formattedData = []
        //     we need to loop through the data of the balance and return each of them as { name : name of the section , and weights as an array of weights objects }
            balancesData.map(balance => {
                formattedData.push({
                    name : balance.name,
                    weights : Object.keys(balance).map(key => {
                        if(key !== 'name'){
                            return {
                                name : key,
                                weight : balance[key]
                            }
                        }
                    }).filter(weight => weight && weight)
                })


            })

            balances = [...formattedData]
            setBalances(formattedData)

        }

    } , [balancesData])


    const yearHandler = async  (e)=>{

        setBalances([])
        setLoading(true)
        const {value : year} = e.target
        if(!year) {
            setLoading(false)
            return setBalances([])
        }
        const {data , error} = await accio(apis.origins.weightBalance.all(year))

        setLoading(false)

        if(error) return setError(error)

        setBalancesData(data)



        // setProject(value)
    }


    return (
        <div>
            {/*<QuickView ref={initCreateRef}><InitBalanceCrud.Create years={years} /></QuickView>*/}

            <PageMeta title={'ارصدة الاوزان'} />

            <Signboard {...signboard} />

            <select name="year"  id="" onChange={ yearHandler } defaultValue={data.year}  className="form-select project-selector">
                <option value="">اختر العام</option>
                    {
                        years && years.map(year => <option key={year} value={year}> عام {year}</option>)
                    }
            </select>
            {
                balances && balances.length > 0 ? (
               <>
                   <TableHeader  />

                   <div className="mt-5"></div>
                   <div className="overflow-auto">
                       <Grid.FlexResponsive  className="data-table-head mt-5">
                           <Grid.Span size={4} >الأسم</Grid.Span>
                               {
                                   weights && weights.length > 0  && weights.map((item , index) =>{
                                       return <Grid.Span size={2} key={index} > {item.name} </Grid.Span>
                                   })
                               }
                           </Grid.FlexResponsive>

                           {
                               balances && balances.length > 0  && balances.map((item , index) =>{
                                   return (
                                       <Grid.FlexResponsive key={index} className="data-row">
                                           <Grid.Span size={4} >{item.name}</Grid.Span>
                                           {
                                               item?.weights?.map((weight , weightIndex) => {
                                                   return <Grid.Span size={2} key={weightIndex} > {weight.weight} </Grid.Span>
                                               })
                                           }
                                       </Grid.FlexResponsive>
                               )
                           })
                       }
                   </div>

               </>
                ) : (
                    <div className='text-center p-5 mt-5'>
                        <img src="/pages/select-project.png" alt="please select a project"/>
                        <h2 className='mt-4'>قم أولا بتحديد العام لعرض البيانات</h2>
                    </div>
                )
            }





        </div>
    )
}
