import { useState } from "react";
import { apis, feed_types_api, operators_api, strain_api } from "../../../../config/apis";

import { useParams } from "react-router-dom";
import { EditForm, InputDate, InputNumber, PageMeta, Signboard, useFetch, createFormHandler, setInputsToDefault, InputSelect } from "../../../../config/imports";

export default function Create(props){

    const {id} = useParams();

    const {setMainData, toggle , setRefresh , hasRoosterInfo  } = props;

    const [data , setData] = useState({
        home : id,
        operator : "",
        start_date : "",
        end_date : "",
        strain : "",
        feed_type : [],
        chicken : "",
        chicken_unit_price : "",
        rooster : "",
        rooster_unit_price : "",
        totaled_chicken_price : "",
        totaled_rooster_price : "",
        totaled_bird : "",
        totaled_bird_price : "",
        feed_average_price : "",
    })

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const {data : operators , isLoading : operatorsLoading , error : operatorsError } = useFetch(operators_api.names)
    const {data : strain , isLoading : strainLoading , error : strainError } = useFetch(strain_api.base)
    const {data : feed , isLoading : feedLoading , error : feedError } = useFetch(feed_types_api.base)

    let signboard = {
        error : error  || operatorsError || strainError || feedError,
        success,
        loading : loading  || operatorsLoading || strainLoading || feedLoading,
    }

    const handleInputChange = (e) => {
            const { name , value , type } = e.target;
            data[name] = type  === 'number' ? +value : value
            setData({
                ...data
            })
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: apis.levels.breeding.create(),
            data : {
                ...data,
                totaled_chicken_price :data.chicken_unit_price * data.chicken,
                totaled_rooster_price : data.rooster * data.rooster_unit_price,
                totaled_bird : data.chicken + data.rooster,
                totaled_bird_price : (data.rooster * data.rooster_unit_price ) + (data.chicken * data.chicken_unit_price)
            },
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=> {},
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.url})

        await createFormHandler(create_props);
    };






    return(
        <>
            <PageMeta title={'انشاء مرحلة تربية جديدة للبيت'} originTitle={'مراحل البيت '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>انشاء مرحلة تربية جديدة للبيت</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange} >
                <InputSelect   value={data.operator} name={'operator'} options={ operators &&  operators.map( operator => ({  name : operator.name , _id : operator._id })) } title={'المورد'} />
                <InputSelect   value={data.strain} name={'strain'} options={strain &&  strain.map( strain => ({  name : strain.name , _id : strain._id })) } title={'السلاله'} />
                <InputSelect   defaultValue={data.feed_type} multiple={true}  name={'feed_type'} options={feed &&  feed.map( feed => ({  name : feed.name , _id : feed._id })) } title={'نوع العلف'} />
                <InputNumber   value={data.feed_average_price} name={'feed_average_price'} title={' متوسط سعر العلف'} />
                <InputDate     value={data.start_date} name={'start_date'} title={'تاريخ البدء'} />
                <InputDate     value={data.end_date} name={'end_date'} title={'تاريخ الانتهاء'} />
                <InputNumber   value={data.chicken} name={'chicken'} title={'عدد الدجاج '} />
                <InputNumber   value={data.chicken_unit_price} name={'chicken_unit_price'} title={'سعر وحدة الدجاج  '} />
                <InputNumber   defaultValue={data.chicken_unit_price * data.chicken} name={'totaled_chicken_price'} title={'اجمالي سعر الدجاج'} />
                {
                    hasRoosterInfo &&  (
                        <>
                            <InputNumber   value={data.rooster} name={'rooster'} title={'عدد الديوك'} />
                            <InputNumber   value={data.rooster_unit_price} name={'rooster_unit_price'} title={'سعر وحده الديوك  '} />
                            <InputNumber   defaultValue={ data.rooster * data.rooster_unit_price} name={'totaled_rooster_price'} title={'اجمالي سعر الديوك'} />
                        </>
                        
                    ) 
                }

                <InputNumber   defaultValue={data.chicken + data.rooster} name={'totaled_bird'} title={'اجمالي  الطيور'} className={'col-6'} />
                <InputNumber   defaultValue={ (data.rooster * data.rooster_unit_price ) + (data.chicken * data.chicken_unit_price)} name={'totaled_bird_price'} title={'اجمالي  سعر الطيور'} className={'col-6'} />

            </EditForm>

        </>
    )
}
