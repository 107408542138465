import React, { useEffect } from "react";
import useSelectInvoiceStore from "../stores/select-invoice.store";
import { InputSelect } from "../../../config/imports";

export default function InputDepartmentInvoices() {

  const setDepartment = useSelectInvoiceStore((state) => state.setDepartment);
  const department = useSelectInvoiceStore((state) => state.department);
  const departments = useSelectInvoiceStore((state) => state.departments);
  const selectedProjectDepartments = useSelectInvoiceStore((state) => state.selectedProjectDepartments);
  const project = useSelectInvoiceStore((state) => state.project);


  useEffect(() => {
    selectedProjectDepartments()
  }, [project]);

 
  

  return (
    <InputSelect
      title={"اختر القسم"}
      className={"col-12"}
      handleInputChange={(e) => setDepartment(e.target.value)}
      name="type"
      defaultValue={department}
      options={departments}
    />
  );
}
