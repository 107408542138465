import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Table } from '../../../layout/table'
import { _date } from '../../../helpers/date.helpers'
import Helpers from '../../../helpers/helpers'
import QuickView from '../../../components/QuickView'
import ApprovalsMovementDetails from '../components/ApprovalsMovementDetails'
import ApprovalsHeader from '../components/ApprovalsHeader'
import ApprovalReturnLink from '../components/ApprovalReturnLink'
import PageMeta from '../../../components/PageMeta'

function DeadApprovalDetails(props) {

    const {getUnitName , data , setRefresh , Alert} = props

    const detailsRef = useRef()


    const headerTable =  [
        {size : 3 , name : "date" , text : "التاريخ"  , custom : d => _date(d.date).dmy() },
        {size : 4 , name : "last_updated_by" , text : " تعديل بواسطة"  },
        {size : 4 , name : "created_by" , text : " اضافة بواسطة"  },
        {size : 4 , name : "total_quantity" , text : "الكمية" , custom : d => Helpers.Number.commaFormat( d?.total_quantity ) },
        {size : 4 , name : "ar_unit" , text : "وحدة القياس"  },
        {size : 4 , name : "notes" , text : " ملاحظات"  },
        {size : 1 , name : "" , text : ""  },
    ]




    const bodyTable = [
        {size : 2 , name : "age" , text : "عمر الطير"  , custom : d => Helpers.Number.commaFormat( d?.age ?? "-") },
        {size : 2 , name : "chicken" , text : " عدد الدجاج  " , custom : d => Helpers.Number.commaFormat( d?.chicken )  },
        {size : 2 , name : "dead_chicken" , text : "نافق الدجاج"  , custom : d => Helpers.Number.commaFormat( d?.dead_chicken ) },
        {size : 2 , name : "dead_chicken_percentage" , text : " نسبة النافق"  , custom : d => Helpers.Number.commaFormat( d?.dead_chicken_percentage?.toFixed(2) ) + " %" },
        {size : 2 , name : "rooster" , text : " عدد الديوك  "  , custom : d => Helpers.Number.commaFormat( d?.rooster ) },
        {size : 2 , name : "dead_rooster" , text : "نافق الديوك"  , custom : d => Helpers.Number.commaFormat( d?.dead_rooster ) },
        {size : 2 , name : "dead_rooster_percentage" , text : " نسبة النافق"  , custom : d => Helpers.Number.commaFormat( d?.dead_rooster_percentage?.toFixed(2) ) + " %" },
        {size : 3 , name : "total_birds" , text : " اجمالي الطيور"  , custom : d => Helpers.Number.commaFormat( d?.total_birds ) },
        {size : 3 , name : "total_dead" , text : " اجمالي النافق"  , custom : d => Helpers.Number.commaFormat( d?.total_dead) },
        {size : 3 , name : "total_dead_percentage" , text : " نسبة النافق"  , custom : d => Helpers.Number.commaFormat( d?.total_dead_percentage?.toFixed(2) ) + " %" },
    ]

    

  return (
    <>

        <PageMeta title={'تفاصيل الموافقة'} />

        <ApprovalReturnLink />

        <QuickView ref={detailsRef}  className="overflown-popup"><ApprovalsMovementDetails getUnitName={getUnitName}   /></QuickView>

        <div className="page my-3">
            
            <ApprovalsHeader setRefresh={setRefresh} type={"تقرير النافق"}  />
            
            <Alert />

            <Table data={data} table={headerTable} />
            
        </div>

        <div className="page my-3">

            <h1 className='text-[20px] font-[500]'>  التفاصيل  </h1>

            <Alert />

            <Table data={data[0]?.movements} table={bodyTable} />

        </div>


    </>
  )
}

DeadApprovalDetails.propTypes = {
    data : PropTypes.array,
    getUnitName : PropTypes.func,
    setRefresh : PropTypes.func,
    Alert : PropTypes.any
}

export default DeadApprovalDetails
