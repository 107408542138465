import { useEffect, useMemo, useState } from "react";
import { apis, invoices_api } from "../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../helpers/form.helper";
import { _date, EditForm, InputDate, InputNumber, InputText, PageMeta, Signboard, useFetch } from "../../../config/imports";
import useProjects from "../../../hooks/origins/useProjects";

export default function BuyInvoice(props){

    const { setMainData , toggle , invoice , setRefresh } = props
    const { getName } = useProjects()
    const {data : taxData} = useFetch(apis.preferences.tax())
    const [connectedWeights , setConnectedWeights] = useState([])
    const {data : connected } = useFetch(apis.invoices.sell.connectedWeights(invoice.buy_inv_project_id))

    useEffect(()=>{
        if(connected){
            setConnectedWeights(connected)
        }
    }, [connected])

    const onWeightChange = (id , value)=>{
        setConnectedWeights(pre =>{
            return pre.map(weight => {
                if(weight._id === id){
                    weight.amount = +value
                }
                return weight
            })
        })
    }



    const [data ,setData] = useState({
        invNumber : '',
        date : new Date().toISOString(),
        project : invoice.buy_inv_project_id,
        department : invoice.buy_inv_department_id,
        home : invoice.buy_inv_home_id,
        operator : invoice.buy_inv_operator_id,
    })





    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");



    const handleInputChange = (e  , cIndex , wIndex) => {
        const {name, value, type} = e.target;

        if(name === 'date'){
            data.date = new Date(value).toISOString()
            setData({...data})
        }else if (name === 'invNumber'){
            data.invNumber = value
        }
    
    };


    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const create_props = {
            url: invoices_api.buy.create(),
            data : {
                ...data,
                date : new Date(data.date).toISOString(),
                total_price : totalPrice,
                items : connectedWeights.map(w => {
                    return {
                        _id : w._id,
                        amount : w.amount,
                        is_connected : w.is_connected,
                        carton : w.carton,
                        price : w.price,
                    }
                })
                .filter(w => w.amount && w.amount > 0),
            },
            setLoading,
            setError,
            setSuccess,
            setMainData : () => {},
            setInputsToDefault,
            setRefresh,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        // 
        
        await createFormHandler(create_props);
    };





    const totalPrice = useMemo(() => {
        let total = 0
        connectedWeights.forEach(carton => {
            total += carton?.price * carton?.amount
        })
        return total
    }, [connectedWeights])
    

    const priceAfterTax = useMemo(() => {
        const percentage = taxData?.tax_percentage / 100
        const discount = 1 + percentage
        const _total_price = totalPrice
        const price  =  (_total_price * discount).toFixed(2)
        return isNaN(price) ? 0 : price
      } , [connectedWeights ,totalPrice ])




    return(
            <div className={'buy-invoice'}>
                <PageMeta title={'اضافه فاتورة بيع بيض'} originTitle={'الفواتير'}/>
                <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                    <h3> اضافه فاتورة بيع بيض </h3>
                </header>
                <Signboard loading={loading} error={error} success={success}/>
                <EditForm
                    visible={props.showFooter === undefined}
                    onSubmit={handleFormSubmit}
                    toggle={toggle}
                    onChange={handleInputChange}
                >
                   <>
                       <InputText className={"col-4"}    value={data.invNumber} name={'invNumber'} handleInputChange={handleInputChange}  placeholder={'0'} title={'رقم الفاتورة'} />
                       <InputDate className={"col-4"}    defaultValue={_date(data.date).forInputDate()} name={'date'} handleInputChange={handleInputChange}  placeholder={'0'} title={'تاريخ الفاتورة'} />
                       <div className="col-4">
                          <label htmlFor=""> المشروع </label>
                          <input type="text" disabled readOnly defaultValue={getName(invoice.buy_inv_project_id)} />
                       </div>
                       <hr/>
                    <div className="flex items-center flex-nowrap overflow-x-auto gap-3">
                       {
                        connectedWeights?.map((weight)=>{
                            return <div key={weight._id} className='min-w-[100px] relative py-4' >
                                    <InputNumber  required={false}  value={weight.amount} placeholder={'0'}   title={weight.name} handleInputChange={(e)=> onWeightChange(weight._id , e.target.value)}  className={'w-full'} />
                                    <span className="absolute bottom-[10px] right-0 text-[12px] text-[#999]">سعر الوزن :  {weight.price}</span>
                            </div>
                        })
                       }
                    </div>
                        <div className="flex items-center mt-4">
                                <div className="col-4 flex flex-col ">
                                    <label htmlFor=""> الاجمالي </label>
                                    <span className="block border-[1px]  border-[#cdcdcd9a] mt-2 rounded-md p-2 px-[12px]">{totalPrice}</span>
                                </div>
                                <div className="col-4 flex flex-col ">
                                    <label htmlFor=""> قيمة الضريبه </label>
                                    <span className="block border-[1px]  border-[#cdcdcd9a] mt-2 rounded-md p-2 px-[12px]">{taxData?.tax_percentage}%</span>
                                </div>
                                <div className="col-4 flex flex-col ">
                                    <label htmlFor="">    الاجمالي  بعد الضريبه</label>
                                    <span className="block border-[1px]  border-[#cdcdcd9a] mt-2 rounded-md p-2 px-[12px]">{priceAfterTax}</span>
                                </div>
                        </div>
                   </>
                </EditForm>
            </div>
    )
}