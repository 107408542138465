import { deleteFormHandler, Signboard } from "../../../../config/imports";
import React, { useState } from "react";
import { apis } from "../../../../config/apis";
import DeleteTable from "../../../../layout/table/DeleteTable";
import { getUnitName } from "../../../../helpers/converters/units.converter";

export default function Delete(props){

    const {toggle , item , setRefresh } = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const table = [
        { size : 5 , name : "name" , text :  'الاسم' },
        { size : 5 , name : "measuringUnit" , text :  'وحدة القياس' , custom : d => getUnitName(d.measuringUnit)  },
        { size : 5 , name : "operator" , text :  'المورد' ,  custom : d=> d?.operator?.name  ?? "بدون"},
        { size : 6 , name : "info" , text :  'تفاصيل' },
    ]


    const handleDelete = async () => {
        const delete_props = {
            url: apis.origins.others.delete(),
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setRefresh,
            item,
        };

        // console.log(delete_props)
        await deleteFormHandler(delete_props);
    };
    return(
        <>

        <header className="w-fit m-auto px-4 text-center border-bottm-line ">
            <h3>  حذف   الاخرى   ؟</h3>
        </header>
        <Signboard error={error} success={success} loading={loading}/>

        <DeleteTable  table={table} data={[item]} />

        <div className="col-12 col-md-12 mt-4">
            <div className="form-group row justify-content-between align-items-center">
                <div className="col">
                    <button className="close-btn" onClick={() => toggle()}>
                        {confirmBtn ? "الغاء" : "اغلاق"}
                    </button>
                </div>
                <div className="col d-flex justify-content-end">
                    {confirmBtn && (
                        <button className="add-btn" onClick={handleDelete}>
                            نعم
                        </button>
                    )}
                </div>
            </div>
        </div>
    </>
    )
}
