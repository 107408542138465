import {projectsAPI} from "../../../../projects.helper"

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    PageMeta,
    Signboard,
    QuickView,
    SuperCreate,
    InputDate,
    addColumn,
    InputNumber,
    InputText,
    extractTableData,
    SuperUpdate,
    SuperDelete
} from '../../../../../../config/imports';


const DrugsAndFortificationsMovementReports = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            date: new Date(),
            inv_number: 10,
            type: "15515",
            buy_quantity: 485,
            buy_value: 23,
            consumption_quantity: 135,
            consumption_value: 7789,
            remaining_quantity: 963,
            quantity_value: 105,

        }, {
            date: new Date(),
            inv_number: 1,
            type: "963258",
            buy_quantity: 23,
            buy_value: 15,
            consumption_quantity: 10,
            consumption_value: 65,
            remaining_quantity: 20,
            quantity_value: 75,
        }
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "طلب شراء ادوية",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];


    const table = [
        addColumn("", "م", ''),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        addColumn('type', 'نوع العلف', InputText),
        addColumn('buy_quantity', 'كمية الشراء', InputNumber),
        addColumn('buy_value', 'قيمة الشراء', InputNumber),
        addColumn('consumption_quantity', 'كمية الاستهلاك', InputNumber, 2),
        addColumn('consumption_value', 'قيمة الاستهلاك', InputNumber, 2),
        addColumn('remaining_quantity', 'الكمية المتبقية', InputNumber),
        addColumn('quantity_value', 'قيمة الكمية', InputNumber),
        addColumn("settings", "", ''),

    ];


    const pageTitle = "قائمة حركة الادوية و التحصينات";
    return (
        <>
            <PageMeta title={pageTitle}/>

            <QuickView ref={createRef}>
                <SuperCreate table={table} formHeader={"اضافة فاتورة ادوية"} mainTitle={pageTitle}
                             api={"abdo"} setMainData={setMainData}/>

            </QuickView>

            <QuickView ref={updateRef}>
                <SuperUpdate
                    table={table}
                    formHeader={"تعديل فاتورة ادوية"}
                    mainTitle={pageTitle}
                    api={"abdo"}
                    setMainData={setMainData}/>

            </QuickView>

            <QuickView ref={deleteRef}>
                <SuperDelete api={""} setMainData={setMainData} table={table}/>
            </QuickView>
            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={pageTitle}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};


export default DrugsAndFortificationsMovementReports;
