import React, { useEffect, useState } from 'react'
import { useSearchParams, Link } from "react-router-dom"
import Approvals from './Approvals'
import ApprovedReports from './pages/ApprovedReports'



const DEFAULT_TAB = 'reports'


const Tabs = {
  reports : <Approvals/>,
  approved : <ApprovedReports />
}

const tabNames = [...Object.keys(Tabs)]



const isAValidTab = (tab) => {
    return tabNames.includes(tab)
}





export default function ApprovalsTabs() {


  const [searchParams ] = useSearchParams()
  const [Tab , setTab] = useState( )
  

  useEffect(() => {
    const tab = searchParams.get('tab')
    setTab(Tabs[tab])
  } , [searchParams])





  useEffect(() => {
    const _tab = searchParams.get('tab')  
    const tab = isAValidTab(_tab) ? _tab : DEFAULT_TAB
    // console.log(tab);
    const links = [...document.querySelectorAll('.tabs-container a')]
    
    links.forEach((link) => {
        link.classList.remove('active-tab')
    })

    const active = document.querySelector(`[data-tab-name='${tab}']`) 
    
    active?.classList.add('active-tab')

    } , [ searchParams ])



  useEffect(() => {
    const tab = searchParams.get('tab')
    if(isAValidTab(tab)) 
        return setTab(Tabs[tab])
    else 
        return  setTab(Tabs[DEFAULT_TAB])
  } , [])




  return (
    <>

      <div className='tabs-container'>
          <Link className={"link-tab"}  data-tab-name="reports" to="?tab=reports" > التقارير  </Link>
          <Link className={"link-tab"}  data-tab-name="approved" to="?tab=approved" >   تقارير سابقة</Link>
      </div>

      <div className=" my-3">
        { Tab }
      </div>


    </>
  )
}

