import PageMeta from "../../../components/PageMeta";
import {
    _date,
    Grid,
    Signboard,
    TableHeader,
    useFetch,
    Icons,
    QuickView,
    SuperDelete,
    Table
} from "../../../config/imports";
import "../../../styles/billing.styles.scss"
import {invoices_api} from "../../../config/apis";
import {useEffect, useRef, useState} from "react";
import {YEARS} from "../../../config/configs.env";
import DeleteSalesInvoice from "./DeleteSalesInvoice";


export default function SalesInvoicesIndex(){
    const deleteRef = useRef()

    const sizes = {
        serial : 1,
        id : 3,
        date : 3,
        total_from_box : 5,
        total_price : 3,
        createdAt : 3,
        updatedAt : 3,
        settings : 2
    }


    const  { data , error , isLoading  , setData}  = useFetch(invoices_api.custom("feedbuy"))

    let [invoices , setInvoices] = useState([])


    const invoicesHandler = (e)=>{
        const {value} = e.target

        const selectedInvoicesWithYear = []
        data.forEach(invoice => {
            const invoiceYear = new Date(invoice.date).getFullYear()
            if(invoiceYear){
                if( invoiceYear === +value){
                    selectedInvoicesWithYear.push(invoice)
                }
            }
        })
        if(selectedInvoicesWithYear.length > 0)
            setInvoices([...selectedInvoicesWithYear])
        else
            setInvoices([])

    }
    const signboard = {
        error : error,
        loading : isLoading
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}></Grid.Span>,
        id:<Grid.Span size={sizes.id}>  رقم الفاتورة </Grid.Span>,
        date: <Grid.Span size={sizes.date }> التاريخ </Grid.Span>,
        total_from_box:<Grid.Span size={sizes.total_from_box}>  الكمية الكلية من الكرتونة </Grid.Span>,
        total_price: <Grid.Span size={sizes.total_price}>السعر الاجمالى</Grid.Span>,
        createdAt:<Grid.Span size={sizes.createdAt}>تاريخ الاضافة</Grid.Span>,
        updatedAt: <Grid.Span size={sizes.updatedAt}> تاريخ اخر تعديل </Grid.Span>
    }

    return (

        <div className='billing-management-container'>

            <QuickView ref={deleteRef}>
                <DeleteSalesInvoice  setMainData={setData} header={header} />
            </QuickView>

            <PageMeta title={'قائمة فواتير البيع'} />
            <TableHeader />
            <Signboard {...signboard}/>
            <select name="project"  id="" onChange={ invoicesHandler }   className="form-select project-selector">
                <option  value="" key={Math.random()}> اختر سنة الفواتير</option>
                    {
                        YEARS().map(year => {
                            return <option value={year} key={year}> فواتير سنة {year} </option>
                        })
                    }
            </select>


            <Table.Header header={header}  className={'sales-inv'} />
            {
                invoices && invoices.length > 0 && invoices.map((invoice , index) => (
                    <Grid.FlexResponsive  key={index} className={'align-items-center data-row'}>
                        <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                        <Grid.Span size={sizes.id}>{invoice.invNumber}</Grid.Span>
                        <Grid.Span size={sizes.date}>{ _date(invoice.date).simple('date') }</Grid.Span>
                        <Grid.Span size={sizes.total_from_box}>{invoice.total_from_box || invoice.total_amount}</Grid.Span>
                        <Grid.Span size={sizes.total_price}>{invoice.total_price}</Grid.Span>
                        <Grid.Span size={sizes.createdAt}>{_date(invoice.createdAt).simple('date')}</Grid.Span>
                        <Grid.Span size={sizes.updatedAt}>{_date(invoice.updatedAt).simple('date')}</Grid.Span>
                        <Grid.Span size={sizes.updatedAt} className='settings-buttons'>
                            <button> <Icons.Excel />  </button>
                            <button  onClick={ () => deleteRef.current.toggle(invoice)} > <Icons.Delete />  </button>
                            <button > <Icons.Edit />  </button>
                        </Grid.Span>
                    </Grid.FlexResponsive>
                ))
            }
        </div>
    )
}
