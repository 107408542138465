export default function Tab(props){
    const {tabHandler , index , title , size } = props
    return(
        <div className={ size ? 'span-' + size : "span-5"}>
            <div className={index === 1 ? "tab-container active-tab" : "tab-container"} onClick={e => tabHandler(e , undefined , undefined)} data-index={index}>
                <div className="tab-link"></div>
                <div className="tab-title">{title}</div>
            </div>
        </div>
    )
}
