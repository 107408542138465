import { useEffect } from 'react'
import useIndexStore from '../store/index.store'

export default function useIndexApi() {

    const fetch = useIndexStore(state => state.fetch)
    


   useEffect(() => {
       fetch()
   } , []) 

}
