import React, { useEffect, useState } from 'react'
import { setBreadCrumbsStore } from '../../components/breadcrumbs/store/breadcrumb.store'
import { homeBreadCrumb, preferencesBreadCrumb } from '../../components/breadcrumbs/data/breadcrumbs.data'
import useFetch from '../../hooks/useFetch'
import { apis } from '../../config/apis'
import { InputNumber, PageMeta, updateFormHandler } from '../../config/imports'
import { CiEdit } from "react-icons/ci"
import { IoSaveOutline } from "react-icons/io5"
import { IoClose } from "react-icons/io5"
import { accio } from '../../helpers/axios/axios.get'
import { showErrorToast } from '../../stores/toast.store'
import { Spinner } from 'react-bootstrap'

export default function Preferences() {

    const [loading , setLoading] = useState(false)

    const {data : taxData , isLoading : loadingTax} = useFetch(apis.preferences.tax())

    const [editing , setEditing] = useState(false)

    const [tax , setTax] = useState()

    useEffect(() => {
        setBreadCrumbsStore([
            homeBreadCrumb,
            preferencesBreadCrumb
        ])
    }, [])


    useEffect(() => {

        if(taxData)
            setTax(taxData?.tax_percentage)

    }, [taxData])



    const editHandler = async ()=>{
        setEditing(true)
    };


  const onChangeHandler = (e)=>{
    setTax(e.target.value)
  }


  const saveHandler = async ()=>{
    if(!tax) return showErrorToast("من فضلك ادخل الضريبة")

    if(tax < 0 || tax > 100) return showErrorToast("الضريبة يجب ان تكون بين 0 - 100")

    if(!editing) return 

    if(loading) return

    setLoading(true)

    setEditing(false)
         
    const update_props = {
        url: apis.preferences.updateTax(taxData?._id),
        data : {
            tax_percentage : +tax
        },
        setLoading,
        setError : ()=>{},
        setSuccess : ()=>{},
        setMainData : ()=>{},
        setData : ()=>{},
        FORM_HEADER: `تعديل`,
    };




    await updateFormHandler(update_props);



  }


  const cancelHandler = async ()=>{
    setLoading(true)
    setEditing(false)
    const {data} = await accio(apis.preferences.tax())
    setLoading(false)
    setTax(data?.tax_percentage)
  }






  return (
    <>
        <PageMeta title={'الاعدادت'} />
        <div className='page mb-2'>
            <header className=''><h1 className='text-[20px] font-[500]'>   الاعدادت   </h1></header>
        </div>
        <div className='page'>
            
            <div className="flex flex-col w-[400px]">
            <div className="flex items-center w-full justify-between">
                    <span>
                        الضريبة
                        { loadingTax  && <Spinner  className='animate-spin mx-2 text-[#fcaa30] font-[400]' size='sm'   />}
                        { loading  && <Spinner  className='animate-spin mx-2 text-[#fcaa30] font-[400]' size='sm'   />}

                    </span>
                    {
                        editing ? <div className="flex gap-2">
                                <button onClick={saveHandler}  className='border-[1px] p-2 rounded-md'>
                                    <IoSaveOutline  className='text-white' />
                                </button>
                                <button onClick={cancelHandler}  className='border-[1px] p-2 rounded-md'>
                                    <IoClose  className='text-white' />
                                </button>

                        </div>  :  <button onClick={editHandler}  className='border-[1px] p-2 rounded-md' >
                            <CiEdit className='text-white'  />
                        </button>
                    } 
                
            </div>
                <InputNumber
                    name={'tax_percentage'} 
                    title={'نسبة الضريبة'} 
                    defaultValue={tax ?? 0}
                    disabled={!editing}
                    labelProps={{className : "hidden"}}
                    className="w-full"
                    handleInputChange={onChangeHandler}
                />
            </div>
        </div>

        
    </>
  )
}
