export default function FeedOrderHome(props){
    const {home} = props
    return(
        <div className='month-home'>
                <div className="text-center  month-home-header dim">
                    <div className="h-40 bb-0 bt-0 br-0 bl-0 txt justify-content-center">
                        {home?.home}
                    </div>
                    <div className="d-flex h-120 bb-0 br-0 bl-0">
                        <div className="col-4 p-0 ">
                            <div className="b h-40 txt bt-0 bb-0 bl-0 br-0">
                                {home.feed_consumption}
                            </div>
                            <div className="b h-40 bl-0 bb-0 txt br-0">
                                { home.month_beards ?? 0 }
                            </div>
                            <div className="b h-40 bl-0 bb-0  txt br-0"></div>
                        </div>
                        <div className="col-4 p-0">
                            <div className="b h-80 bb-0 bt-0 bl-0  txt">
                                الكميات المطلوبة
                            </div>
                            <div className="b h-40  bb-0 bl-0 txt"></div>
                        </div>
                        <div className="col-4 p-0">
                            <div className="b h-80  bl-0 bb-0 bt-0 txt">رصيد السايلو</div>

                            <div className="b h-40 bl-0 bb-0  txt">
                                {home.silo_balance ?? 25}
                            </div>

                        </div>
                    </div>


                </div>

                {
                    home && home?.month_data?.map( (d , index) => (
                         <div className="month-home-row d-flex" key={index}>
                             <div className="col-4 txt">
                                 { d.consumption }
                             </div>
                             <div className="col-4 txt">
                                 {d.quantities_required}
                             </div>
                             <div className="col-4 txt">
                                 { d.silo_balance}
                             </div>

                    </div>
                    ))
                }



        </div>
    )
}
