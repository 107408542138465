import PageMeta from "../../../../../components/PageMeta";

import RouterLinks from "../../../../../components/LinkList";
import useNavigator from "../../../../../hooks/useNavigator";

const PackagingInvoiceReports = () => {
    const PackagingInvoiceReports_Links = [
        {
            name: "قائمة فواتير شراء التعبة و التغليف",
            path: "packaging-invoices",
        },
        {
            name: "ارصدة المشاريع",
            path: "packaging-balances",
        },
        {
            name: "قائمة التقارير",
            path: "maintenance-reports",
        },

    ];

    useNavigator("packaging", "packaging-invoices");

    return (
        <>
            <PageMeta title={"تعبئة و تغليف"} />

            {<RouterLinks underline={true} data={PackagingInvoiceReports_Links} />}
        </>
    );
};

export default PackagingInvoiceReports;
