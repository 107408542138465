import Icons from "../../../layout/Icons";


export default function InputSearch(props){
    return(
        <div className='input-search'>
            <input  {...props} type="search" placeholder={props.placeholder  ?? 'ابحث هنا...'}  />
            <Icons.Search />
        </div>
    )
}
