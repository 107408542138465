// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home .home-details .buttons {
  display: flex;
  justify-content: flex-end;
}
.home .home-details .buttons button {
  padding: 8px 30px;
  margin: 0 5px;
}
.home .home-details .data-table-head {
  font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/styles/home.styles.scss"],"names":[],"mappings":"AAII;EAEE,aAAA;EACA,yBAAA;AAJN;AAKM;EACE,iBAAA;EACA,aAAA;AAHR;AAMI;EACE,eAAA;AAJN","sourcesContent":[".home{\n\n\n  .home-details{\n    .buttons{\n      //font-size: 15px;\n      display: flex;\n      justify-content: flex-end;\n      button{\n        padding :8px 30px;\n        margin: 0 5px;\n      }\n    }\n    .data-table-head{\n      font-size: 14px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
