import React from 'react';
import PropTypes from 'prop-types';
import { IoIosSearch } from "react-icons/io";
import { IoIosClose } from "react-icons/io";
import usePaginateStore from '../../stores/paginate.store';

function SearchInput(props) {

    // const { search , setSearch } = props

    const setSearch = usePaginateStore(state => state.setSearch)
    
    const search = usePaginateStore(state => state.search)




  return (
      <div className="page my-5">
                <div className='relative bg-[#f2f2f2] rounded-[25px] w-[400px]  py-1 flex  items-center  '>
                        
                        <div className="w-[30px] h-[30px]  mr-4 cursor-pointer !text-[28px]">

                                { !search && <IoIosSearch className=' opacity-40' /> }

                                { search && <IoIosClose className=' opacity-40 ' onClick={() => setSearch("")}  />}
                                
                        </div>


                        <input type="text" value={search} onChange={(e)=>setSearch(e.target.value)} placeholder='البحث في القائمه'
                               className=' w-full h-full rounded-[5px]  bg-transparent outline-none border-none  p-0 text-[16px] text-[#333] px-3 ' />


                </div>
      </div>
  )
}

SearchInput.propTypes = {
    setSearch : PropTypes.func,
    search : PropTypes.string
}

export default SearchInput
