import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { reports_api } from "../../../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../../../helpers/form.helper";
import { _date, EditForm, InputDate, InputSelect, InputTextArea, PageMeta, Signboard } from "../../../../../config/imports";
import useStockMaintenances from "../../../../stocks/hooks/useStockMaintenances";

export default function Update(props){

    const {id} = useParams();
    const {setMainData, toggle , item} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    

    const { options : maintenancesOptions }  = useStockMaintenances("maintenance")

    const [data , setData] = useState({
        date : new Date().toISOString(),
        home : id,
        maintenance_type : item.maintenance_type,
        notes : item.notes,
        unit : item.unit
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const units = useMemo(() => {
        return [
            { text : "قطعه" , value : "قطعه" },
        ]
    } , [])


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: reports_api.home.maintenances.create(),
            data : {
                ...data,
                date : new Date(data.date).toISOString()
            },
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };


        // console.log(create_props.data);
        await createFormHandler(create_props);
    };


    return(
        <>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3> تعديل  تقرير الصيانه  يومي</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>
            <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
                <InputDate   name={'date'}  handleInputChange={handleInputChange}  defaultValue={_date(data.date).forInputDate()} placeholder={'B'}  title={'التاريخ'} value={data.date}/>
                <InputSelect   name={'maintenance_type'}  handleInputChange={handleInputChange}  options={maintenancesOptions}  title={'نوع الصيانه'} defaultValue={data.maintenance_type}  />
                <InputSelect   name={'unit'}  handleInputChange={handleInputChange}  options={units}  title={'وحدة القياس'} defaultValue={data.unit}  />
                <InputTextArea name='notes'   handleInputChange={handleInputChange} placeholder={' ملاحظات على تقارير الاعلاف... '}  title={'ملاحظات'} value={data.notes}/>
            </EditForm>
        </>
     )
}
