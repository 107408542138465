import {
    _date,
    addColumn, Icons, InputHidden,
    QuickView,
    Signboard,
    SuperDelete,
    SuperUpdate,
    TableHeader,
    useFetch
} from "../../config/imports";
import {apis, roles_api, users_api} from "../../config/apis";
import React, {useEffect, useRef} from "react";
import '../../styles/user.styles.scss'
import CreateRolesForm from "./CreateRolesForm";
import UpdateRoleForm from "./UpdateRoleForm";
import LockRoleForm from "./LockRoleForm";
import {Table} from "../../layout/table";
import PageMeta from "../../components/PageMeta";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, usersRolesBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";

export default function Roles() {


    const createRoleRef = useRef();
    const lockRoleRef = useRef();
    const deleteRoleRef = useRef()
    const updateRoleRef = useRef()

    const  {
        data : roles , error : roles_error , isLoading : roles_isLoading , setData : set_roles,setRefresh
    } = useFetch(apis.authorization.roles())
    // console.log({roles})


    const  { data : permissions , error : permissions_error , isLoading : permissions_isLoading , setData : set_permissions } = useFetch(apis.authorization.permissions_form())

    
    const button = [
        {title : "انشاء دور"  , onclick : () => createRoleRef.current.toggle()}
    ]

    const signboard = {
        error :  roles_error || permissions_error,
        loading : roles_isLoading || permissions_isLoading
    }

    const rolesTable = [
        addColumn('' , 'م' , undefined , 1),
        addColumn('role' , "اسم الدور" , InputHidden , 2),
        addColumn('settings' , '' , undefined , 3),
    ]


    const table = [
        { size  : 1 , name : "#" ,  text : "#"} ,
        { size  : 4 , name : "role" ,  text : "اسم الدور"},
        { size  : 16 , name : "permissions" ,  text : "الصلاحيات" , custom : item =>{
            if(item?.permissions?.length  === 0) return  <div className='not-active-status' > بدون </div>
            return item.permissions && item.permissions.length >0 && item.permissions.map(permission => {
                return <div className='active-status d-inline-block m-2' key={permission._id}>{permission.name}</div>
            })
            }},
        { size  : 3 , name : '' ,  text : '' , custom : item =>{
              return  <div className={'settings-buttons'}>
                    <button  onClick={()=> deleteRoleRef.current?.toggle(item)}><Icons.Delete /></button>
                    <button  onClick={()=> updateRoleRef.current?.toggle(item)}><Icons.Edit /></button>
                    <button  onClick={()=> lockRoleRef.current?.toggle(item)}>{item.is_active ?  <Icons.Lock /> :    <Icons.ActiveLock />}</button>
                </div>
        }},
    ]



    useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            usersRolesBreadCrumb,
        ])
  } , [])


    return (
        <div className="user-base role-base bg-white r-16 base-card py-3 px-2">
            <TableHeader buttons={button} />
            <Signboard {...signboard} />
            <PageMeta title={'الادوار'}/>
            <QuickView ref={createRoleRef} sized={true} >
                <CreateRolesForm setRefresh={setRefresh} setMainData={set_roles}  permissions={permissions} />
            </QuickView>
            <QuickView ref={updateRoleRef}  className="sized-height"  sized={true} >
                <UpdateRoleForm setRefresh={setRefresh} setMainData={set_roles}  permissions={permissions} />
            </QuickView>
            <QuickView ref={deleteRoleRef}>
                 <SuperDelete setMainData={set_roles} names={['role']} table={rolesTable} setRefresh={setRefresh} api={roles_api.role}/> </QuickView>
            <QuickView ref={lockRoleRef}>
                <LockRoleForm   setMainData={set_roles}   />
            </QuickView>

            <Table table={table} data={roles} className={'mt-3'}/>
        </div>
    )
}
