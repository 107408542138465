import TableHeader from "./TableHeader.js";
import TableBody from "./TableBody.js";
import Skeleton from "./Skeleton.js";
import SkeletonGroup from "./SkeletonGroup.js";

export default function Table(props){
    let  {table , data , updateRef , deleteRef , className} = props

    // data = null

    return(
        <div className={"Table " + (className ?? "")}>
            <TableHeader table={table} />
           {
              data ? 
               <TableBody table={table} data={data} updateRef={updateRef} deleteRef={deleteRef}/> : 
               <SkeletonGroup />
           }
        </div>
    )
}
