import React, { useState } from "react";
import { operatorAPI, originAPI } from "../origin.helpers";
import { paths } from "../../../config/configs.env";
import Signboard from "../../../components/Signboard";
import {TableHeader ,useFetch  , Grid, Icons, QuickView,  useRef , PageMeta} from "../../../config/imports";
import OriginMaintenanceCrud from "./maintenance_crud";
import { Table } from "../../../layout/table";

const Maintenance = () => {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();



    const {data, error, setData: setMainData, isLoading} = useFetch(originAPI.maintenance);

    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }


        const table = [
          { size : 1 , name : "#" , text : "#"  },
          { size : 4 , name : "name" , text :  'الاسم' },
          { size : 4 , name : "operator" , text :  'المورد' ,  custom : d=> d?.operator?.name  ?? "بدون"},
          { size : 3 , name : "types" , text :  'نوع الصيانه' },
          { size : 3 , name : "img" , text :  'الصورة' , custom : d=>{
                return   <a href={paths.uploads + d.img} target='_blank'>
                            <img src={paths.thumbnails + d.img} alt={d.name} width={40} />
                        </a>
          } },
          { size : 6 , name : "info" , text :  'تفاصيل' },
          { size : 2 , name : '' , text : '' , }
        ]


  return ( <>

      <PageMeta title={"الصيانة"} />

        <QuickView ref={createRef}><OriginMaintenanceCrud.Create  operators={operators}  setMainData={setMainData}/></QuickView>
        <QuickView ref={updateRef}><OriginMaintenanceCrud.Update  operators={operators} setMainData={setMainData}/></QuickView>
        <QuickView ref={deleteRef}><OriginMaintenanceCrud.Delete  operators={operators} setMainData={setMainData}/></QuickView>

        <Signboard {...signboard} />
        <TableHeader  buttons={ [
            {
                title: " اضافة نوع صيانه جديد ",
                onclick: () => createRef.current.toggle()
            }
        ]}/>

        <div className="" style={{marginTop: '150px'}}></div>
     
        <Table table={table} data={data}  updateRef={updateRef} deleteRef={deleteRef}  className="mt-3" />
    </>
  );
};

export default Maintenance;
