import FormInput from "./FormInput";

const InputDate = (props) => {
    const {name, title, handleInputChange, defaultValue, className} = props
    return (
        <>
           <FormInput {...props} handleInputChange={handleInputChange} name={name} className={className } defaultValue={defaultValue} title={title} type={"date"}  />
        </>

    );
}

export default InputDate;
