

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    QuickView,
    SuperCreate,
    addColumn,
    InputDate,
    InputNumber,
    InputText,
    extractTableData,
    InputTextArea, SuperUpdate, SuperDelete
} from '../../../../../../config/imports';
import {projectsAPI} from "../../../../projects.helper";

const ProjectsPackingReportsList = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            inv_number : 5151151,
            date: new Date(),
            total_boxes_quantity: "748422",
            total_boxes_price: 212,
            total_plates: 4515,
            total_plates_price: 159,
        }, {
            inv_number : 5151151,
            date: new Date(),
            total_boxes_quantity: "15",
            total_boxes_price: 105,
            total_plates: 8985,
            total_plates_price: 15,
        }
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "طلب تعبئة و تغليف",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];

    const main_title = "قائمة الفواتير";


    const table = [
        addColumn("", "م", ''),
        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('total_boxes_quantity', 'اجمالي : عدد الكرتون', InputNumber, 2),
        addColumn('total_boxes_price', 'اجمالي : مبلغ الكرتون', InputNumber , 2),
        addColumn('total_plates', 'اجمالي : عدد الاطباق', InputNumber , 2),
        addColumn('total_plates_price', 'اجمالي : مبلغ الاطباق', InputNumber, 2),
        addColumn("settings", "", ''),
    ];
    return (
        <>
            <PageMeta title={main_title}/>


            <QuickView ref={createRef}>
                <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"اضافة طلب تعبئة و تغليف"}
                    table={table}
                />
            </QuickView>

            <QuickView ref={updateRef}>
                <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={main_title}
                    formHeader={"تعديل طلب تعبئة و تغليف"}
                    table={table}

                />
            </QuickView>

            <QuickView ref={deleteRef}>
                <SuperDelete
                    api={""}
                    setMainData={setMainData}
                    table={table}
                />
            </QuickView>
            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={main_title}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};

export default ProjectsPackingReportsList;
