import React from 'react'
import PropTypes from 'prop-types'
import Helpers from '../../helpers/helpers'

function EggsWarehouseStat(props) {
  const { amount , name } = props
  return (
    <div className='flex-1 p-2 '>
            <div className='text-[32px] text-white flex  flex-col  items-center justify-center  bg-[#41E481]  text-center p-2 py-2   rounded-[10px] '>
             <div className="flex items-center">
                <span className=' text-white'>  {Helpers.Number.commaFormat( amount || 0 )}</span>
                <span className='text-[14px] mx-2 text-white'>كرتونه</span>
             </div>
             <span className='text-[18px] text-white pb-2'>{name}</span>
            </div>
    
    </div>
  )
}

EggsWarehouseStat.propTypes = {
    amount : PropTypes.number,
    name : PropTypes.string,
}

export default EggsWarehouseStat
