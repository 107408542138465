// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.origin-cards .card-index a {
  margin: 0;
  padding: 10px 15px;
}
.origin-cards .card-index .pagination-container {
  margin: 0;
}
.origin-cards .pagination-container {
  margin: 0;
}
.origin-cards .builing-room {
  position: relative;
  margin: 0;
  padding: 0;
}
.origin-cards .builing-room [class^=span-] {
  padding-top: 0;
  padding-bottom: 0;
}
.origin-cards .builing-room:hover {
  background-color: #f9f9f9;
}
.origin-cards .builing-room button {
  position: absolute;
  background: transparent !important;
  top: 60%;
  left: -30px;
  transform: translateY(-50%);
}`, "",{"version":3,"sources":["webpack://./src/styles/orgin.cards.styles.scss"],"names":[],"mappings":"AAGQ;EACI,SAAA;EACA,kBAAA;AAFZ;AAIQ;EACI,SAAA;AAFZ;AAQI;EACI,SAAA;AANR;AAUI;EACI,kBAAA;EACA,SAAA;EACA,UAAA;AARR;AASQ;EACG,cAAA;EACA,iBAAA;AAPX;AASQ;EACI,yBAAA;AAPZ;AAUQ;EACI,kBAAA;EACA,kCAAA;EACA,QAAA;EACA,WAAA;EACA,2BAAA;AARZ","sourcesContent":[".origin-cards{\n\n    .card-index{\n        a{\n            margin: 0;\n            padding: 10px 15px;\n        }\n        .pagination-container{\n            margin: 0;\n        }\n    }\n\n\n\n    .pagination-container{\n        margin:  0; \n\n       \n    }\n    .builing-room {\n        position: relative;\n        margin: 0;\n        padding: 0;\n        [class^=\"span-\"] {\n           padding-top: 0;\n           padding-bottom: 0;\n        }\n        &:hover{\n            background-color: #f9f9f9;\n            // opacity: 0.8;\n        }\n        button {\n            position: absolute;\n            background: transparent !important;\n            top: 60%;\n            left: -30px;\n            transform: translateY(-50%);\n\n        }\n\n    }\n\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
