import {useState} from "react";
import PageMeta from "../../../../components/PageMeta";
import {
    EditForm,
    InputNumber,
    InputText,
    InputTextArea,
    setInputsToDefault,
    Signboard, updateFormHandler
} from "../../../../config/imports";
import {weight_api} from "../../../../config/apis";

export default function UpdateWeight(props){

    const {toggle , item , setMainData } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name :item.name,
        weight :item.weight,
        info :item.info,
    })
    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };




    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: weight_api.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
        console.log(update_props)


        await updateFormHandler(update_props);
    };
    return(
        <>
            <PageMeta title={"تعديل تفاصيل الوزن" + item.name} originTitle={'تفاصيل الاوزان'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>{"تعديل  الوزن   " + item.name}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined ? true : false}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InputText name={'name'}  className={'col-6'} handleInputChange={handleInputChange}  placeholder={'B'}  title={'الرمز'} defaultValue={data.name}/>
                <InputNumber name={'weight'}  className={'col-6'} handleInputChange={handleInputChange} placeholder={'100'}  title={'الوزن'} defaultValue={data.weight}/>
                <InputTextArea name={'info'}  className={'col-12'} handleInputChange={handleInputChange}   placeholder={'0'} title={'معلومات اضافيه'} defaultValue={data.info}/>
            </EditForm>

        </>
    )
}
