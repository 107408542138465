import Tab from "./Tab";
import TabContent from "./TabContent";
import {Grid, useState} from "../../config/imports";
import Icons from "../Icons";
import BrowserControls from "./BrowserControls";

export default function Browser(props){

    const {data , toggle} = props

    const [tabToBeRendered , setTabToBeRendered] = useState(1)

    const tabHandler = (e , activeTab ,activeIndex) =>{
        const tab =  activeTab ?? e.target.closest('.tab-container')
        const index = activeIndex ?? parseInt(tab.dataset.index)
        tab.classList.toggle('active-tab')
        const tabs = [...document.querySelectorAll('.tab-container')]
        setTabToBeRendered(index)
        // لازم نضيف 1 لان الانديكس بيبدأ من 1 مش من الصفر
        const tabIndex = tabs.indexOf(tab) + 1
        if(tabIndex === 0) {
            tabs.forEach(t => t.classList.remove('active-tab'))
            tab.classList.add('active-tab')
        }else{
            tabs.forEach(t => t.classList.remove('active-tab'))
            const tabsToBeActivated = tabs.slice(0 , tabIndex)
            tabsToBeActivated.forEach(t => t.classList.add('active-tab'))
        }
    }


    return(
        <div className="browser-container">

                <div className="tab-links row  g-2 ">
                    {
                        data && data.length > 0 && data.map((item , index) =>
                            <Tab
                                key={index}
                                index={index + 1 } // index has to be more than zero, if index == 0 the first tab will not render for no reason
                                tabHandler={tabHandler}
                                title={item.tab.title}
                                size={item.tab.size}
                        />)
                    }
                </div>
               <div className="data-tabs-container" >
                   {
                       data && data.length > 0 && data.map((item , index) =>
                           <TabContent
                               key={index}
                               index={index + 1 } // index has to be more than zero, if index == 0 the first tab will not render for no reason
                               tabToBeRendered={tabToBeRendered}
                               content={item.content}
                       />)
                   }
               </div>

                    <BrowserControls
                        tabToBeRendered={tabToBeRendered}
                        data={data}
                        toggle={toggle}
                        tabHandler={tabHandler}
                        setTabToBeRendered={setTabToBeRendered}
                    />

        </div>
    )
}

