import {useEffect, useState} from "react";
import {apis} from "../../../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../../../helpers/form.helper";

import {
    _date,
    EditForm, InputDate, InputNumber,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard, useFetch
} from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";
import {accio} from "../../../../helpers/axios/axios.get";
import Grid from "../../../../layout/grid";

export default function CreateInitBalance(props){

    const {setMainData, toggle  , years} = props;


    // const {data : initData, error : initErr , isLoading : initLoading , setData : setInitData } = useFetch(apis.balances.init.form(new Date().getFullYear()));

    const  [initData, setInitData] = useState(null)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let [data, setData] = useState({
        // year :
        year : '',
        weights : []
    })

    useEffect(() => {
        if(initData){
            setData({
                year : initData.year,
                weights : initData.weights
            })
        }
    } , [initData])




    const handleInputChange = async (e , idx) => {

        const {name , value} = e.target

        const weight = data.weights[idx]

        weight[name] = +value

        setData({...data})

    };

    // console.log({data})

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.balances.init.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        await createFormHandler(create_props);
    };

    const datePicker = async (e) => {
        const {value : year} = e.target;
        setLoading(true)
        if(!year) {
            setLoading(false)
            setData({year : "", weights : []})
            return setError('من فضلك اختر العام المطلوب')
        }

        setData({ year  , weights : []})

        const {data , error} = await accio(apis.balances.init.form(year))

        setLoading(false)

        if(!error) return setInitData(data)

        setData({year : "", weights : []})

        return setError(error)
    }

    return(
        <div className={'opening-balance-form'}>
            <PageMeta title={' اضافه الرصيد الافتتاحي  '} originTitle={'الارصدة الافتتاحيه'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافه الرصيد الافتتاحي</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
<>
                <InputSelect name="year" title="اختر العام" defaultValue={data.year} value={data.year} handleInputChange={datePicker}
                    options={
                            years && years.map(year => {
                                return { _id : year , name : year}
                        })}
                />

                <Grid.Flex  className="data-table-head">
                    <Grid.Span size={8} > الوزن </Grid.Span>
                    <Grid.Span size={8} >الكمية </Grid.Span>
                    <Grid.Span size={8} >السعر</Grid.Span>
                </Grid.Flex>


    {
       data && data.weights?.length > 0 && data.weights?.map((item , index) => {
            return (
                <Grid.Flex key={index} className="data-row align-items-center">
                    <Grid.Span size={8} > {item.name} </Grid.Span>
                    <Grid.Span size={8} ><InputNumber required={false} handleInputChange={(e) => handleInputChange(e , index)} name={'amount'} placeholder='0' value={item.amount} className='w-100' />  </Grid.Span>
                    <Grid.Span size={8} ><InputNumber required={false} handleInputChange={(e) => handleInputChange(e , index)} name={'price'} placeholder='0' value={item.price} className='w-100' /></Grid.Span>
                </Grid.Flex>
            )
        })
    }

</>



            </EditForm>

        </div>
    )
}
