import {
    _date,
    createFormHandler, EditForm, Grid, InputDate,
    PageMeta,
    setInputsToDefault,
    Signboard,
    useFetch
} from "../../../../../../../config/imports";
import {departments_api, reports_api} from "../../../../../../../config/apis";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import "../../../../../../../styles/reports.styles.scss"

export default function Create(props){
    // get the id of the department
    const {id} = useParams();
    const {setMainData, toggle} = props;

    // get homes of a department
    const {data : reports , isLoading : reportsLoading , error : reportsError} = useFetch(reports_api.department.feed.form(id))


    // create state for date of each home
    let [data , setData] = useState({
        date : new Date().toISOString(),
        homes: [],
        createdAt: new Date().toISOString(),
        updatedAt : new Date().toISOString(),
    })
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let signboard = {
        error :  error || reportsError,
        success  ,
        loading : loading || reportsLoading,
    }

    console.log({reports})
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: reports_api.home.feed.createReportForDepartment(),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.data})

        await createFormHandler(create_props);
    };




    useEffect(()=>{
        if(reports && reports.homes){
            setData({
                ...data,
                homes : reports.homes
            })
        }
    } , [reports])

        console.log({data})



    const handleInputChange = (e , changeData) => {
        const {homeIndex } = changeData
        const {value} = e.target

    //  get home to be changed
        const home = data.homes[homeIndex]
        home.date = new Date(value).toISOString()
        data = {...data}
        setData(data)
    }


    const datePicker = e=>{
        const {value} = e.target
        data.date = new Date(value).toISOString()
        data.homes = [...data.homes.map(home =>{
            return {
                ...home,
                date : new Date(value).toISOString()
            }
        })]
        setData({...data})
    }
    return(
        <div className={'total-feed-report'}>

            <PageMeta title={'اضافة تقرير اعلاف شامل يومي'} originTitle={' الشامله تقارير الاعلاف'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   اضافة تقرير اعلاف شامل يومي</h3>
            </header>

            <Signboard {...signboard}/>


            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >
                <>
                   <Grid.Flex className='data-table-head'>
                       <InputDate title={'اختر التاريخ'}  handleInputChange={datePicker} defaultValue={_date(data.date).forInputDate()} />

                   </Grid.Flex>
                    {
                        data && data.homes.length > 0 && data.homes.map( (home , homeIndex) => {
                            return (
                                <Grid.Flex key={home.home} className={'data-row'}>
                                    <Grid.Span size={6} > {home.name} </Grid.Span>
                                    <Grid.Span size={16} >
                                      <div className="span-5">
                                          {_date(home.date).dmy()}
                                      </div>
                                        <div className={ home.status === "غير مسجلة"  ? "span-3 mx-5 not-active-status" : "span-3 mx-5 active-status"}>
                                            {home.status}
                                        </div>
                                    </Grid.Span>
                                </Grid.Flex>
                            )
                        })
                    }


                </>
            </EditForm>


        </div>
    )
}
