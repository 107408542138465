import "../../styles/error.styles.scss"
import {NavLink, useRouteError} from "react-router-dom";
const ErrorHandler = ()=>{
    const error = useRouteError()

    console.log(error)

    return <div className='error-handler'>

        <h3>حدث خطأ ما</h3>
        <img src="/pages/500.svg"  alt="error pic"/>

        <NavLink to="/" >العودة للصفحة الرئيسية</NavLink>
        <p> {error.message} </p>


    </div>
}
export default ErrorHandler