import { useEffect } from 'react'
import useBreadCrumb from '../../../components/breadcrumbs/hooks/breadcrumb.hook'
import { useParams } from 'react-router-dom'
import { homeBreadCrumb, warehousesBreadCrumb } from '../../../components/breadcrumbs/data/breadcrumbs.data'
import { setBreadCrumbsStore } from '../../../components/breadcrumbs/store/breadcrumb.store'

export default function useStockBreadCrumbs() {


    const { id } = useParams()

    const {data : crumbs}  = useBreadCrumb({id , name : "stock"})

   

    useEffect(() => {
        if(!crumbs || !crumbs.length) return


        const stockBreadCrumb = crumbs.filter(crumb => crumb.model === "stock" || crumb.section === "stock")[0]

        
        const _crumbs = [
            homeBreadCrumb,
            warehousesBreadCrumb,
            {name : stockBreadCrumb.name  , to : `/stocks/${stockBreadCrumb.id}?tab=details` }
        ]

        setBreadCrumbsStore(_crumbs)

        
    } , [crumbs])


    


 
}
