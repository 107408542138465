import CreateWeight from "./CreateWeight"
import UpdateWeight from "./UpdateWeight"
import DeleteWeight from "./DeleteWeight"

const WeightCrud = {
    Create : CreateWeight,
    Update : UpdateWeight,
    Delete : DeleteWeight
}

export default WeightCrud

// export {
//     CreateWeight,
//     UpdateWeight,
//     DeleteWeight
// }
