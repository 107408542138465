import PageMeta from "../../../components/PageMeta";
import {
    EditForm,
    InputNumber,
    InputText,
    Signboard,
    setInputsToDefault,
    updateFormHandler,
    useFetch
} from "../../../config/imports";
import { useEffect, useMemo, useState } from "react";
import { apis, packaging_api } from "../../../config/apis";
import InputSelect from "../../../components/form/inputs/InputSelect";
import { cn } from "../../../helpers/class_name.helpers";
import Browser from "../../../layout/browser/Browser";

export default function EditUnit(props){


    const {toggle , item , setRefresh} = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const {data : dishes} = useFetch( apis.dishes.all() )

    const {data : weights} = useFetch( apis.cartonWeights.connectedWeights(item._id))




    const dishesOptions = useMemo(() => {
        return dishes?.map((dish) => {
            return {value : dish._id , text : dish.name + "-" + dish.type};
        })
    })

    const [data, setData] = useState({
        symbol : item.symbol,
        name : item.name,
        dishes_count : item.dishes_count,
        egg_count : item.egg_count,
        total_egg_count : item.total_egg_count,
        packaging_type : item.packaging_type,
        dishes_type : item.dishes_type,
        dish : item.dish,
        safe_count : item.safe_count,
        weights : item.weights ?? []
    })




    useEffect(() => {
        if(weights) {
            setData((prev) => ({
                ...prev,
                weights : weights.filter((weight) => weight.is_connected )?.map((weight) => weight._id)
            }))
        }
    } , [weights])


  


    const toggleWeight = (weightId) => {
        setData((prev) => {
            const isExists = prev?.weights?.includes(weightId);
            if(isExists) return {
                ...prev,
                weights : prev?.weights?.filter((weight) => weight !== weightId)
            };
            return {
                ...prev,
                weights : [...prev?.weights , weightId]
            }
        } )
    }




    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };



    const handleDishSelect = (e) => {
        
        const {value} = e.target;
        if(!value) {
            setData((prev) => ({
                ...prev,
                dish : '',
                egg_count : "",
                dishes_type : ""
            }))
        }else{
            setData((prev) => ({
                ...prev,
                dish : value
            }))
        }
        

        const dish = dishes.find(dish => dish._id === value)
        if(!dish) return

        setData((prev) => ({
            ...prev,
            egg_count : dish.eggs_in_dish,
            dishes_type : dish.type
        }))
        
    }

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();


        const update_props = {
            url: packaging_api.update(item._id),
            data : {
                ...data,
                total_egg_count : +data.egg_count * +data.dishes_count,
                weights : data.weights.map( weight =>({
                    _id : weight,
                    is_connected : weights.filter(w => w._id === weight)[0].is_connected
                })),
            },
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=>{},
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };

        // console.log(update_props.data);
        
        await updateFormHandler(update_props);
    };









    const browserData = [
        {
            tab  : { title : "بيانات الكرتونه" , size : 12},
            content :<>
                    <InputNumber handleInputChange={handleInputChange}  name={'symbol'}   className="col-3" placeholder={'0'}  title={'الرمز'} defaultValue={data.symbol}/>
                    <InputText   handleInputChange={handleInputChange}  name={'name'}  className="col-5" placeholder={'0'}  title={'الاسم'} defaultValue={data.name}/>
                    <InputText   handleInputChange={handleInputChange}  name={'packaging_type'}   className={'col-4'}  placeholder={'كرتون مقوى'}  title={'نوع الكرتونة  '} defaultValue={data.packaging_type}/>
                    <>
                    <InputSelect name={'dish'} handleInputChange={handleDishSelect} className={'col-4'} options={dishesOptions}   placeholder={'0'}  title={'الطبق'} value={data.dish}/>
                    </>
                    <InputText   handleInputChange={handleInputChange}  name={'dishes_type'} disabled className={'col-4'}   placeholder={'0'}  title={'  نوع الطبق'} defaultValue={data.dishes_type}/>
                    <InputNumber handleInputChange={handleInputChange}  name={'egg_count'} disabled  className={'col-4'}  placeholder={'0'}  title={'عدد البيض فى كل طبق'} defaultValue={data.egg_count}/>
                    <InputNumber handleInputChange={handleInputChange}  name={'dishes_count'}    className="col-4" placeholder={'0'} title={'عدد الاطباق'} defaultValue={data.dishes_count}/>
                    <InputNumber handleInputChange={handleInputChange}  name={'safe_count'}    className="col-4" placeholder={'0'} title={'عدد اطباق الحمايه'} defaultValue={data.safe_count}/>
                    <InputNumber handleInputChange={handleInputChange}  name={'total_egg_count'}   className={'col-4'} placeholder={'0'}  title={'عدد البيض  فى الكرتونة'} defaultValue={+data.egg_count * +data.dishes_count}/>
            </>
        },
      
        {
            tab  : { title : "اختر الاوزان" , size : 12},
            content :<div className="flex gap-3 flex-wrap">
                {
                    weights?.map((weight) => {

                        return <div 
                        key={weight._id} 
                        className={cn(
                            "px-3 py-1 border-[1px] rounded-[5px] cursor-pointer",
                            data?.weights?.filter((w)=> w === weight._id).length > 0 && "bg-green-500 border-green-500 text-white",
                        )} 
                        onClick={() => toggleWeight(weight._id)}
                        >
                                {weight.name}
                        </div>
                    })
                }
            </div>
        },
      
       
    ]













    return<div className='storage-unit-details'>

        <PageMeta title={"تعديل تفاصيل الوزن" + item.name} originTitle={'تفاصيل الاوزان'}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>{"تعديل     " + item?.name}</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>


        <EditForm
            visible={false}
            onSubmit={handleFormSubmit}
            onChange={handleInputChange}
            toggle={toggle}>
   
                {/* <InputNumber name={'symbol'}   className="col-3" placeholder={'0'}  title={'الرمز'} defaultValue={data.symbol}/>
                <InputText   name={'name'}  className="col-5" placeholder={'0'}  title={'الاسم'} defaultValue={data.name}/>
                <InputText   name={'packaging_type'}   className={'col-4'}  placeholder={'كرتون مقوى'}  title={'نوع الكرتونة'}  defaultValue={data.packaging_type}/>
                <>
                    <InputSelect name={'dish'} handleInputChange={handleDishSelect} className={'col-4'} options={dishesOptions}   placeholder={'0'}  title={'الطبق'} defaultValue={data.dish}/>
                </>
                <InputText   name={'dishes_type'} disabled className={'col-4'}   placeholder={'0'}  title={'  نوع الطبق'} defaultValue={data.dishes_type}/>
                <InputNumber name={'egg_count'} disabled  className={'col-4'}  placeholder={'0'}  title={'عدد البيض فى كل طبق'} defaultValue={data.egg_count}/>
                <InputNumber name={'dishes_count'}    className="col-4" placeholder={'0'} title={'عدد الاطباق'} defaultValue={data.dishes_count}/>
                <InputNumber name={'safe_count'}    className="col-4" placeholder={'0'} title={'عدد اطباق الحمايه'} defaultValue={data.safe_count}/>
                <InputNumber name={'total_egg_count'}   className={'col-4'} placeholder={'0'}  title={'عدد البيض  فى الكرتونة'} defaultValue={+data.egg_count * +data.dishes_count}/> */}
             <Browser data={browserData} toggle={toggle} />

        </EditForm>


    </div>

}
