import {InputText , InputNumber} from "../../../../config/imports"
import Helpers from "../../../../helpers/helpers";

const buildingsInputs = [
        { name :  'name' , title :  'أسم المبنى', input :  InputText},
        { name :  'characteristic' , title :  'صفة المبنى', input :  InputText},
        { name :  'location' , title :  'موقعها', input :  InputText},
        { name :  'height' , title :  'طول المبنى', input :  InputNumber},
        { name :  'width' , title :  'عرض المبنى', input :  InputNumber},
        { name :  'area' , title :  'مساحة المبنى', input :  InputNumber , customValue : (data)=> {
                data.area =  Helpers.validateNumber(data?.width * data?.height)
                const input = document.getElementById('area')
                if(input) input.value = data.area
        }},
        { name :  'room_height' , title :  'طول الغرفة', input :  InputNumber},
        { name :  'room_width' , title :  'عرض الغرفة', input :  InputNumber},
        { name :  'room_area' , title :  'مساحة الغرفة', input :  InputNumber , customValue : (data)=> {
                data.room_area =  Helpers.validateNumber(data?.room_width * data?.room_height)
                const input = document.getElementById('room_area')
                if(input) input.value = data.room_area
        }},
        { name :  'floors_count' , title :  'عدد إدوار المبنى', input :  InputNumber , size : 3},
        { name :  'rooms_count' , title :  'عدد الغرف', input :  InputNumber , size : 3},
        { name :  'doors_count' , title :  'عدد الأبواب', input :  InputNumber , size : 3},
        { name :  'conditioners_count' , title :  'عدد المكيفات', input :  InputNumber , size : 3},
        { name :  'value' , title :  'قيمة المبنى', input :  InputText},
        { name :  'account_name' , title :  'أسم الحساب', input :  InputText},
        { name :  'account_symbol' , title :  'رمز الحساب', input :  InputText},
]

    export default buildingsInputs
