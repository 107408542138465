import React from 'react';
import BreadCrumbItem from './BreadCrumbItem';
import BreadCrumbArrow from './BreadCrumbArrow';

export default function BreadCrumb({ name, to, className, style }) {
  return (
    <div className="flex items-center">
      <BreadCrumbItem name={name} to={to} className={className} style={style} />
      <BreadCrumbArrow />
    </div>
  );
}
