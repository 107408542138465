import {_date, Grid, Icons, PageMeta, QuickView, Signboard, TableHeader, useFetch} from "../../../../../config/imports";
import {useRef, useState} from "react";
import {useParams} from "react-router-dom";
import {departments_api} from "../../../../../config/apis";
import TotalReportsCrud from "./reports_crud";

export default function Eggs(props){

    const createRef = useRef(),
        updateRef = useRef()

    const {id} = useParams();
    console.log({id})

    const {data , isLoading : loading , error , setData } = useFetch(departments_api.reports.eggs.init(id))

    const signboard = {
        loading, error
    }

    const sizes = {
        serial: 1,
        date: 4,
        createdAt: 8,
        updatedAt: 8,
        settings: 3
    }


    return(
            <div className='department-reports'>
                <PageMeta title={'تقارير البيض الشامله'} />

                <Grid.Flex className={'align-items-center justify-content-between'}>
                    <div className="isc col-12 col-sm-12 col-lg-4">
                        <input
                            type="search"
                            name="search"
                            placeholder="البحث في القائمة"
                            className="search-box "
                            id=""
                        />
                        <i className="fa-solid fa-magnifying-glass"></i>
                    </div>
                    <button className="add-btn" onClick={ ()=> createRef.current.toggle() }> + اضافة تقرير بيض شامل </button>
                </Grid.Flex>

                <Signboard {...signboard}/>


                <QuickView ref={createRef}><TotalReportsCrud.Eggs.Create   setMainData={setData}/></QuickView>
                <QuickView ref={updateRef}><TotalReportsCrud.Eggs.Update   setMainData={setData}/></QuickView>



                <Grid.FlexResponsive className={'align-items-center data-table-head mt-0'}>
                    <Grid.Span size={sizes.serial}>#</Grid.Span>
                    <Grid.Span size={sizes.date}> التاريخ  </Grid.Span>
                    <Grid.Span size={sizes.createdAt}>   تاريخ الاضافه  </Grid.Span>
                    <Grid.Span size={sizes.updatedAt}>  تاريخ اخر تعديل   </Grid.Span>
                    <Grid.Span size={sizes.settings}> </Grid.Span>
                </Grid.FlexResponsive>

                {
                    data &&  data.length > 0  && data.map((item , index) => (
                        <Grid.FlexResponsive  key={item._id} className={'align-items-center data-row'}>
                            <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                            <Grid.Span size={sizes.date}>{_date(item.date).dmy()}</Grid.Span>
                            <Grid.Span size={sizes.createdAt}>{ _date(item.createdAt).dmy()}</Grid.Span>
                            <Grid.Span size={sizes.updatedAt}>{ _date(item.updatedAt).dmy()}</Grid.Span>
                            {/*<Grid.Span size={sizes.settings} className='settings-buttons'>*/}
                            {/*    <button  onClick={()=> updateRef.current.toggle(item)}><Icons.Edit /></button>*/}
                            {/*</Grid.Span>*/}
                        </Grid.FlexResponsive>
                    ))
                }
            </div>
    )
}
