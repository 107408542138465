import PageMeta from "../../../../../components/PageMeta";

import RouterLinks from "../../../../../components/LinkList";
import useNavigator from "../../../../../hooks/useNavigator";

const OtherProjectsReports = () => {
    const OtherProjectsReports_Links = [
        {
            name: "قائمة فواتير شراء المصروفات الاخري",
            path: "other-buy-invoices",
        },
        {
            name: "قائمة تقارير حركة المصروفات الأخرى",
            path: "other-invoices",
        }
    ];

    useNavigator("others", "other-buy-invoices");

    return (
        <>
            <PageMeta title={"مصروفات اخري"} />

            {<RouterLinks underline={true} data={OtherProjectsReports_Links} />}
        </>
    );
};

export default OtherProjectsReports;
