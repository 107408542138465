import React, { useEffect, useState } from 'react'

export default function DisplayUploadedImage(props) {
    const [src, setSrc] = useState(null);

    useEffect(() => {
        if(props.src) 
                return setSrc(props.src)

    } , [props.src])

  return (
    <>
        {
            <div className='uploaded-img file-img'>
                <img src={src} alt={props.alt ?? ''} />
            </div>
        }
    </>
  )
}
