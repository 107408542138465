import "./styles/NotFondPage.css";
import { NavLink } from "react-router-dom";

const NotFondPage = () => {
  return (
    <div className="notFond">
      <div id="error-page">
        <div className="content">
          <h2 className="header" data-text="404">
            404
          </h2>
          <h4 data-text="عفوا! الصفحة غير موجودة">عفوا! الصفحة غير موجودة</h4>
          <p>
            عذرا ، الصفحة التي تبحث عنها غير موجودة. إذا كنت تعتقد أن شيئًا ما
            معطل ، فأبلغ عن مشكلة.
          </p>
          <div className="btns">
            <NavLink to="/">return home</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFondPage;
