import React from "react";
import { operatorAPI, originAPI } from "../origin.helpers";
import Signboard from "../../../components/Signboard";
import { TableHeader, useFetch, QuickView, useRef, PageMeta } from "../../../config/imports";
import { Table } from "../../../layout/table";
import OriginOthersCrud from "./others_crud";
import { getUnitName } from "../../../helpers/converters/units.converter";

const Others = () => {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();

    const {data, error, setData: setMainData, isLoading , setRefresh} = useFetch(originAPI.other);

    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }


    const table = [
        { size : 1 , name : "#" , text : "#"  },
        { size : 5 , name : "name" , text :  'الاسم' },
        { size : 5 , name : "measuringUnit" , text :  'وحدة القياس' , custom : d => getUnitName(d.measuringUnit)  },
        { size : 5 , name : "operator" , text :  'المورد' ,  custom : d=> d?.operator?.name  ?? "بدون"},
        { size : 6 , name : "info" , text :  'تفاصيل' },
        { size : 2 , name : '' , text : '' , }
    ]


  return ( <>

        <PageMeta title={"الاخرى"} />
        <QuickView ref={createRef}><OriginOthersCrud.Create  operators={operators}  setRefresh={setRefresh}/></QuickView>
        <QuickView ref={updateRef}><OriginOthersCrud.Update  operators={operators} setRefresh={setRefresh}/></QuickView>
        <QuickView ref={deleteRef}><OriginOthersCrud.Delete  operators={operators} setRefresh={setRefresh}/></QuickView>
        <Signboard {...signboard} />
        <TableHeader  buttons={ [ { title: " اضافة نوع  اخرى  جديد ", onclick: () => createRef.current.toggle() } ]}/>
        <div className="" style={{marginTop: '150px'}}></div>
        <Table table={table} data={data}  updateRef={updateRef} deleteRef={deleteRef}  className="mt-3" />
    </>
  );
};

export default Others;
