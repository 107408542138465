// hooks
import { useParams, NavLink } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import usePost from "../hooks/usePost";
import useNavigator from "../hooks/useNavigator";
import { useRef, useState } from "react";
import useScript from "../hooks/useScript";
import useCreateStates from "../hooks/useCreateStates";
import useUpdateStates from "../hooks/useUpdateStates";
// import apis from "../config/apis"
// components
import PageMeta from "../components/PageMeta";
import Signboard from "../components/Signboard";
import { TableView, TableHeader } from "../components/TableView";
import LeftArrow from "../components/FontAwesomeComponents/LeftArrow";
import RightArrow from "../components/FontAwesomeComponents/RightArrow";
import QuickView from "../components/QuickView";
import EditForm from "../components/FormControls/EditForm";
import FormInput from "../components/FormControls/FormInput";
import InputText from "../components/FormControls/InputText";
import InputNumber from "../components/FormControls/InputNumber";
import InputDate from "../components/FormControls/InputDate";
import InputSelect from "../components/FormControls/InputSelect";
import InputTextArea from "../components/FormControls/InputTextArea";
import InputTextAsNumber from "../components/FormControls/InputTextAsNumber";
import InputCheckBox from "../components/FormControls/InputCheckBox";
import InputHidden from "../components/FormControls/InputHidden";
import QuickViewContainer from "../components/QuickViewContainer";
import SuperCreate from "../components/supers/SuperCreate";
import SuperUpdate from "../components/supers/SuperUpdate";
import SuperDelete from "../components/supers/SuperDelete";
import {TableData} from '../components/TableComponent'
import {RouterLinks} from "../components/LinkList";
import GreenBox from "../components/GreenBox"
import BlueBox from "../components/BlueBox"
import  Grid ,  {Flex , Row , Span , Column} from '../layout/grid/index'
import Icons , {Excel , Edit , Delete} from '../layout/Icons'
import Table , {Header} from "../components/table/Table"

// axios functions
import postData from "../helpers/axios/axios.post";
import { accio as getData } from "../helpers/axios/axios.get";
import updateData from "../helpers/axios/axios.put";
import removeData from "../helpers/axios/axios.delete";





// helpers

import {
  createFormHandler,
  deleteFormHandler,
  updateFormHandler,
  setInputsToDefault,
} from "../helpers/form.helper";
import {
  destructureTable ,
  constructCreateStateDataFromTable ,
  extractCreateData,
  extractTableData,
  constructUpdateStateDataFromTable,
  addColumn,
    destructTableWithSelectedNames,
    addToTable,
  sortArrayManually

} from "../helpers/table.helper";

import { createInputGenerator } from "../helpers/generators";

import {_date} from "../helpers/date.helpers"



export {
  useParams,
  useFetch,
  usePost,
  useNavigator,
  useRef,
  useState,
  useScript,
  useCreateStates,
  useUpdateStates,




  PageMeta,
  Signboard,
  TableHeader,
  TableView,
  NavLink,
  LeftArrow,
  RightArrow,
  QuickView,
  QuickViewContainer,
  TableData,
    GreenBox,
    BlueBox,
  RouterLinks,
  Grid  ,Flex , Row , Span , Column,
    // supers
  SuperCreate,
  SuperUpdate,
  SuperDelete,
  Icons, Excel , Edit , Delete,
  Table , Header,
  // From
  EditForm,
  InputText,
  InputNumber,
  InputDate,
  InputSelect,
  InputTextArea,
  FormInput,
  InputTextAsNumber,
  InputCheckBox,
    InputHidden,
  postData,
  getData,
  updateData,
  removeData,

  //   helpers
  createFormHandler,
  deleteFormHandler,
  updateFormHandler,
  setInputsToDefault,
  destructureTable,
  constructCreateStateDataFromTable,
  extractCreateData,
  extractTableData,
  addColumn,
  constructUpdateStateDataFromTable,
  destructTableWithSelectedNames,
  addToTable,
  sortArrayManually,
  _date,



//  generators

  createInputGenerator
};
