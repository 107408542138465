export const classificationOptions =  [
  { _id: "buy", name: "فاتورة شراء" },
  { _id: "sell", name: "فاتورة بيع" },
]

export const whereToEnterOptions = [
  {_id: "project", name: "مشروع"},
  {_id: "stock", name: "مستودع"},
]

export const typeOptions = [
  {_id: "feed", name: "شراء اعلاف"},
  {_id: "drug", name: "شراء ادوية"},
  {_id: "maintenance", name: "فاتورة صيانة"},
  {_id: "other", name: "مصروفات اخرى"},
  {_id: "carton", name: "كراتين"},
  {_id: "dish", name: "اطباق"},
]

export const projectTypesOptions = [
  {_id: "feed", name: "شراء اعلاف"}
]

export const buyInvoiceTypes = [
  {_id: "eggs", name: "بيع بيض"}
]



