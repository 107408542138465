import {InputNumber} from "../../../config/imports";
import Helpers from "../../../helpers/helpers";

export default function UnitPriceInput(props){
    const {data , setData , defaultValue} = props

    const handleInputChange = (e) => {
        const { value} = e.target;
        setData( { ...data , unit_price : +value , total_price : Helpers.validateNumber( data.unit_price *  (+value))})
    };

    return(
        <>
            <InputNumber className={'col-3'} name={'unit_price'} title={'سعر الوحدة'} handleInputChange={handleInputChange} value={data.unit_price} defaultValue={defaultValue} />
        </>
    )
}
