import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    QuickView,
    SuperCreate,
    addColumn,
    InputDate,
    InputNumber,
    InputText,
    extractTableData,
    InputTextArea, SuperUpdate, SuperDelete
} from '../../../../../../config/imports';
import {projectsAPI} from "../../../../projects.helper";
import SelectOperator from "../../../../../../components/Shared/SelectOperator";

const PackagingProjectsBalancesReports = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            name: "الاسم الاول",
            type: 5151151,
            operator: {
                _id  : "641211cb22dd3064a8c72138",
                name : "همو سعدة"
            },
            consumption_quantity: 4515,
            consumption_value: 159,
            extra: '5515'
        }, {
            name: "الاسم الثاني",
            type: 5151151,
            operator: {
                _id  : "64121b6e7786271d4c3a77c9",
                name : "ahmed ayman"
            },
            consumption_quantity: 8985,
            consumption_value: 15,
            extra: '3245'
        }
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "اضافة ارصدة المشاريع",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];

    const main_title = "قائمة ارصدة المشاريع";


    const table = [
        addColumn("", "م", ''),
        addColumn('name', 'الاسم', InputText, 2),
        addColumn('type', 'النوع', InputText, 1),
        // addColumn('operator', 'المورد', SelectOperator),
        {
            gen: {name: "operator", title: 'المورد', elm: SelectOperator},
            table: {
                col: 1,
                custom : itm => itm.operator.name
            },
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('consumption_quantity', 'كمية الاستهلاك', InputNumber, 2),
        addColumn('consumption_value', 'قيمة الاستهلاك', InputNumber, 2),
        addColumn('extra', 'معلومات اضافية', InputTextArea, 2),
        addColumn("settings", "", ''),
    ];
    return (
        <>
            <PageMeta title={main_title}/>


            <QuickView ref={createRef}>
                <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"اضافة ارصدة للمشروع"}
                    table={table}
                />
            </QuickView>

            <QuickView ref={updateRef}>
                <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={main_title}
                    formHeader={"تعديل  ارصدة للمشروع"}
                    table={table}

                />
            </QuickView>

            <QuickView ref={deleteRef}>
                <SuperDelete
                    api={""}
                    setMainData={setMainData}
                    table={table}
                />
            </QuickView>
            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={main_title}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};

export default PackagingProjectsBalancesReports;
