import livingInputs from "./livingInputs";
import housingInputs from "./housingInputs";
import landInputs from "./landInputs";
import vehiclesInputs from "./vehiclesInputs";
import roadsInputs from "./roadsInputs";
import toolsAndEquipmentsInputs from "./toolsAndEquipmentsInputs";
import buildingsInputs from "./buildingsInputs";

const origin_cards_tables = {
    buildings: buildingsInputs,
    housing: housingInputs,
    land: landInputs,
    living: livingInputs,
    vehicles: vehiclesInputs,
    roads: roadsInputs,
    toolsAndEquipments: toolsAndEquipmentsInputs,
}

export default origin_cards_tables
