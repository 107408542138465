import { useState } from "react"
import useMassUnit from "../../../hooks/types/useMassUnit"
import useStockCartons from "./useStockCartons"
import useStockDishes from "./useStockDishes"
import useStockDrugs from "./useStockDrugs"
import useStockFeed from "./useStockFeed"
import useStockHomes from "./useStockHomes"
import useStockMaintenances from "./useStockMaintenances"
import useStocks from "./useStocks"
import useStockOthers from "./useStockOthers"

const useStockTransferForm = (id , type , location = "stock")=>{

    const [data , setData] = useState({
        from : "",
        to : "",
        category : "feed",
        sub_category : "",
        consumption : 0,
        date : new Date().toISOString(),
        _from : "stock",
        _to : "home",
        location : "",
        location_id : "" ,
        destination_type : "",
        destination_id : "" ,
        unit : "",
        consumed_type : "transfer"
    })


    const dataToSubmit = ()=>{
        return {
            location : data?._from === "stock" ? "stock" : "home",
            location_id : data?.location_id,
            destination_type : data?._to === "stock" ? "stock" : "home",
            destination_id : data?.destination_id,
            unit : data?.unit,
            consumption : data?.consumption,
            sub_category : data?.sub_category,
            consumed_type : data?.consumed_type
        }
    }



    const  submitDataType = {
        feed : { ...dataToSubmit() , category : "feed" },
        drug : { ...dataToSubmit() , category : "drug" },
        maintenance : { ...dataToSubmit() , category : "maintenance" },
        other : { ...dataToSubmit() , category : "other" },
        carton : { ...dataToSubmit() , category : "carton" },
        dish : { ...dataToSubmit() , category : "dish" },
    }







    const {Input : InputFeedType} = useStockFeed(type)
    const {Input : InputUnit } = useMassUnit(type)
    const {Input : InputStocks , options : StockOptions} = useStocks(type)
    const {Input : InputHomes , options : HomesOptions} = useStockHomes(id , location)

    const {Input : InputDrugsType} = useStockDrugs(type)

    const {Input : InputMaintenancesType} = useStockMaintenances(type)

    const {Input : InputCartons} = useStockCartons(type)

    const {Input : InputDishes} = useStockDishes(type)

    const {Input : InputOthers} = useStockOthers(type)
    


    const InputType = {
        feed : InputFeedType,
        drug : InputDrugsType,
        maintenance : InputMaintenancesType,
        carton : InputCartons,
        dish : InputDishes,
        other : InputOthers
    }

    return {
        InputFeedType,
        InputUnit,
        InputStocks,
        InputHomes,
        Inputs : {
            FeedType :   InputFeedType,
            Unit : InputUnit,
            Stocks : InputStocks,
            Homes : InputHomes,
            DrugsType : InputDrugsType,
            MaintenancesType : InputMaintenancesType
        },
        data ,
        setData ,
        dataToSubmit,
        submitDataType,
        submitData : {
            // feed : { ...dataToSubmit() , category : "feed" },
            // drugs : { ...dataToSubmit() , category : "drug" },
            // maintenances : { ...dataToSubmit() , category : "maintenances" },
            // others : { ...dataToSubmit() , category : "others" },
            // cartons : { ...dataToSubmit() , category : "cartons" },
            // dishes : { ...dataToSubmit() , category : "dishes" },
            ...submitDataType
        },
        InputType,
        options : {
            stocks : StockOptions,
            homes : HomesOptions,
        }
    }

}


export default useStockTransferForm