import PageMeta from "../../../components/PageMeta";
import React, {useState} from "react";
import {deleteFormHandler} from "../../../helpers/form.helper";
import {Grid, Signboard, Table, TableData} from "../../../config/imports";

export default function DeleteWeightDetails(props){
    const {toggle , item , setMainData} = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);



    const sizes = {
        size :3,
        boxes_count : 3,
        eggs_count :3,
        total_cartoon :4,
        unit_size :3,
        cartoon_price :3,
        production_percentage : 3


    }
    const header = {
        size:<Grid.Span size={sizes.size}>  الوزن  </Grid.Span>,
        boxes_count: <Grid.Span size={sizes.boxes_count }> عدد الاطباق </Grid.Span>,
        eggs_count:<Grid.Span size={sizes.eggs_count}>   عدد البيض     </Grid.Span>,
        total_cartoon: <Grid.Span size={sizes.total_cartoon}>اجمالى الكراتين</Grid.Span>,
        unit_size: <Grid.Span size={sizes.unit_size}> وزن الوحدة  </Grid.Span>,
        production_percentage: <Grid.Span size={sizes.production_percentage}> وزن الوحدة  </Grid.Span>,
        cartoon_price: <Grid.Span size={sizes.cartoon_price}> سعر الكرتونة</Grid.Span>,
    }

    const handleDelete = async () => {
        const delete_props = {
            // url: api,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        await deleteFormHandler(delete_props);
    };

    return<div className='storage-unit-details '>
        <PageMeta title={'  حذف الوزن ' + item.size} originTitle={'تفاصيل الاوزان'} />

        <header className="w-fit m-auto px-4 text-center border-bottm-line ">
            <h3>هل انت متاكد من حذف هذا الوزن ؟</h3>
        </header>
        <Signboard error={error} success={success} loading={loading}/>
        <Table.Header header={header}  className={'delete-header'}  />


        <Grid.FlexResponsive   className={'align-items-center data-row'}>
            <Grid.Span size={sizes.size}>{item.size}</Grid.Span>
            <Grid.Span size={sizes.boxes_count}>{ item.boxes_count }</Grid.Span>
            <Grid.Span size={sizes.eggs_count}>{item.eggs_count}</Grid.Span>
            <Grid.Span size={sizes.total_cartoon}>{item.total_cartoon}</Grid.Span>
            <Grid.Span size={sizes.production_percentage}>{item.production_percentage}</Grid.Span>
            <Grid.Span size={sizes.unit_size}>{item.unit_size}</Grid.Span>
            <Grid.Span size={sizes.cartoon_price}>{item.cartoon_price}</Grid.Span>
        </Grid.FlexResponsive>



        <div className="col-12 col-md-12 mt-4">
            <div className="form-group row justify-content-between align-items-center">
                <div className="col">
                    <button className="close-btn" onClick={() => toggle()}>
                        {confirmBtn ? "الغاء" : "اغلاق"}
                    </button>
                </div>
                <div className="col d-flex justify-content-end">
                    {confirmBtn && (
                        <button className="add-btn" onClick={handleDelete}>
                            نعم
                        </button>
                    )}
                </div>
            </div>
        </div>

    </div>
}

