import { create } from "zustand";
import { uuid } from "../../../helpers/id.helper";


const formatAfterUpdate = (data) => {
    let _obj = {
        homes_ids : [],
        departments_ids : [],
        projects_ids : []
    }


    data.forEach(item => {
        _obj[item.type] = [..._obj[item.type] , item._id]
    })
    

    return _obj


}


const useEggsWarehouseStore = create((set) => ({
    selected : [],
    formatted : {
        projects_ids : [],
        departments_ids : [],
        homes_ids : []
    },
    submit : uuid(),

    setSubmit : ()=> set(state => ({...state , submit : uuid()})),

    setSelected : (selected) => set({ selected }),
    clearFilter : () => set((state) => ({ 
        ...state , 
        selected : [] ,
        submit : uuid(),
        formatted : {
                projects_ids : [],
                departments_ids : [],
                homes_ids : []
        }
 })),

    addToSelectedItems : (id , type , name) => set((state) =>{

        const doesItemExist = state.selected.find(item => item._id === id)
        if(doesItemExist) return

        // console.log({d :  formatAfterUpdate()});
        const newData =  [...state.selected , {_id : id , type , name}]

        return { selected : newData , formatted : formatAfterUpdate(newData) }
        
    }),

    removeFromSelectedItems : (id) => set((state) => {
        const newData = state.selected.filter(item => item._id !== id)
        return ({ selected : newData , formatted : formatAfterUpdate(newData) })
    }),


}))


export default useEggsWarehouseStore