import InputSelect from "../../../../components/form/inputs/InputSelect";
import {InputText} from "../../../../config/imports";

const vehiclesInputs = [
    {name : 'name', title :  'الاسم', input :   InputText},
    {name : 'characteristic', title :  'صفة السيارة' , input :  InputText},
    {name : 'model', title :  'الموديل' , input :  InputText},
    { name :  'level' , title :  'المستوى', input :  InputSelect , options : [
            { value :  'نقل خفيف' , text : 'نقل خفيف' },
            { value :  'نقل ثقيل' , text : 'نقل ثقيل' },
            { value :  'نقل خصوصي' , text : 'نقل خصوصي' },
            { value :  'نقل خدمة' , text : 'نقل خدمة' },
    ]},
    {name : 'color', title :  'اللون', input :   InputText},
    {name : 'plate_number', title :  'رقم اللوحة' , input :  InputText},
    {name : 'structure_number', title :  'رقم الهيكل' , input :  InputText},
    {name : 'device_value', title :  'قيمة المعدة' , input :  InputText , size : 4},
    {name : 'account_name', title :  'أسم الحساب' , input :  InputText , size : 4},
    {name : 'account_symbol', title :  'رمز الحساب' , input :  InputText , size : 6},
    {name : 'location', title :  'موقعها', input :   InputText , size : 6},
]

export default vehiclesInputs
