import { useState} from "react";
import {_date, PageMeta } from "../../../../config/imports";
import ReportsOfDepartment from "./reports";

export default function TotalHomeReport(props) {

    const [component, setComponent] = useState(<ReportsOfDepartment.Feed/>)



    const navHandler = (e) => {

            const {component} = e.target.dataset

            const btns = document.querySelectorAll('.line-nav-btn');
            // remove active class
            btns.forEach((btn) => btn.classList.remove('active-nav-btn'))
            // add active class to this
            e.target.classList.add('active-nav-btn')
            //
            const NoComponent = <h1> no component found with {component} name  </h1>
            //
            if(!ReportsOfDepartment[component]) return setComponent(NoComponent)
            //
            const Component = ReportsOfDepartment[component]
            //
            setComponent(<Component/>)


    }

    return(
            <>

                <div className=' bg-white r-16 p-2'>
                    <PageMeta title={'  التقارير الشامله'} />


                        <button onClick={navHandler} className="line-nav-btn active-nav-btn" data-component='Feed' >  اعلاف </button>
                        <button onClick={navHandler} className="line-nav-btn" data-component='Dead'> نافق </button>
                        <button onClick={navHandler} className="line-nav-btn" data-component='Eggs'> بيض </button>


                    <div className="nav-component-container my-3">
                        {component && component }
                    </div>

                    {/*<Table.Header header={header}  className={'mt-0'}  />*/}


                </div>

            </>
    )
}
