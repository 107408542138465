import { useEffect, useState } from "react";

import {
  setParent,
  hasDataChanged,
  updateFormHandler, setInputsToDefault,
} from "../../../../../helpers/form.helper";

import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";
import { strainAPI } from "../../bird_breed.routes";
import {_date} from "../../../../../helpers/date.helpers";
import {strain_api, weight_api} from "../../../../../config/apis";
import {EditForm, InputDate, InputNumber, InputText} from "../../../../../config/imports";
const FORM_HEADER = "تعديل بيانات السلالة";

const UpdateStrain = (props) => {
      
  const {toggle , item , setMainData , setRefresh  } = props

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");


  const [data , setData] = useState({
    name : item.name,
    breeding_age : item.breeding_age,
    production_age : item.production_age,
  })





  const handleInputChange = (e) => {
    const {name, value , type} = e.target;
    setData((prev) => ({
      ...prev,
      [name]: type === "number" ? +value : value,
    }));
  };


  const handleFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const update_props = {
      url: strain_api.breeding.update(item._id),
      data,
      setLoading,
      setError,
      setSuccess,
      setRefresh,
      setMainData : ()=>{},
      setInputsToDefault,
      setData,
      FORM_HEADER: `تعديل`,
    };
    // console.log(update_props)


    await updateFormHandler(update_props);
  };
  return (
    <div className="temp-form">
      <PageMeta title={"تعديل بيانات " + item.name} originTitle={FORM_HEADER} />

      <header className="text-center border-bottm-line w-fit mx-auto px-5 pb-3 mb-5">
        <h3>تعديل بيانات {item.name}</h3>
      </header>

      <Signboard error={error} success={success} loading={loading} />

      <EditForm
          visible={props.showFooter === undefined}
          onSubmit={handleFormSubmit}
          toggle={toggle}
          onChange={handleInputChange}>
        <InputText name={'name'}  className={'col-6'}  handleInputChange={handleInputChange} placeholder={'5'}  title={' الاسم'} defaultValue={data.name}/>
        <InputNumber name={'breeding_age'}   className={'col-6'} handleInputChange={handleInputChange} placeholder={'5'}  title={' عمر مرحلة التربيه'} defaultValue={data.breeding_age}/>
        {/*<InputNumber name={'production_age'}   handleInputChange={handleInputChange} placeholder={'3'}  title={' عمر مرحلة الانتاج'} defaultValue={data.production_age}/>*/}
      </EditForm>
    </div>
  );
};

export default UpdateStrain;
