

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    QuickView,
    SuperCreate,
    addColumn,
    InputDate,
    InputNumber,
    InputText,
    extractTableData,
    InputTextArea, SuperUpdate, SuperDelete
} from '../../../../../../config/imports';
import {projectsAPI} from "../../../../projects.helper";

const PackagingInvoiceReportsList = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            date: new Date(),
            name: "748422",
            inv_number : 5151151,
            quantity: 212,
            unit_price: 4515,
            total_price: 159,
            extra: ''
        }, {
            date: new Date(),
            name: "15",
            inv_number : 5151151,
            quantity: 105,
            unit_price: 8985,
            total_price: 15,
            extra: ''
        }
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "اضافة فاتورة تعبئة و تغليف",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];

    const main_title = "قائمة فواتير شراء التعبة و التغليف";


    const table = [
        addColumn("", "م", ''),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        addColumn('name', 'الاسم', InputText, 2),
        addColumn('quantity', 'الكمية', InputNumber),
        addColumn('unit_price', 'سعر الوحدة', InputNumber),
        addColumn('total_price', 'السعر الاجمالي', InputNumber, 2),
        addColumn('extra', 'معلومات اضافية', InputTextArea, 2),
        addColumn("settings", "", ''),
    ];
    return (
        <>
            <PageMeta title={main_title}/>


            <QuickView ref={createRef}>
                <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"اضافة فاتورة تعبئة و تغليف"}
                    table={table}
                />
            </QuickView>

            <QuickView ref={updateRef}>
                <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={main_title}
                    formHeader={"تعديل فاتورة تعبئة و تغليف"}
                    table={table}

                />
            </QuickView>

            <Signboard error={error} loading={isLoading}/>
            <QuickView ref={deleteRef}>
                <SuperDelete
                    api={""}
                    setMainData={setMainData}
                    table={table}
                />
            </QuickView>
            <TableHeader
                title={main_title}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};

export default PackagingInvoiceReportsList;
