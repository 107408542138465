import React, {useState} from "react";
import {apis, reports_api, weight_api} from "../../../../config/apis";
import {PageMeta ,_date, deleteFormHandler, Grid, Signboard, Table} from "../../../../config/imports";

export default function Delete(props){

    const {toggle , item , setMainData , setRefresh } = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const sizes = {


        chicken : 6,
        rooster : 6,
        start_date : 6,
        end_date : 6
    }
    const header = {

        chicken:<Grid.Span size={sizes.chicken}>   الدجاج  </Grid.Span>,
        rooster:<Grid.Span size={sizes.rooster}>   الديوك  </Grid.Span>,
        start_date:<Grid.Span size={sizes.start_date}> تاريخ البدء  </Grid.Span>,
        end_date:<Grid.Span size={sizes.end_date}> تاريخ الانتهاء  </Grid.Span>
    }

    const handleDelete = async () => {
        const delete_props = {
            url: apis.levels.delete(),
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData : ()=>{},
            setRefresh ,
            item,
        };

        console.log(delete_props)
        await deleteFormHandler(delete_props);
    };

    return(
        <>
            <PageMeta title={'هل انت متأكد من حذف هذه المرحله ؟'} originTitle={' مراحل البيت '} />

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>هل انت متأكد من حذف هذه المرحله ؟</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>


            <Table.Header header={header}  className={'delete-header'}  />

            <Grid.FlexResponsive   className={'align-items-center data-row'}>
                <Grid.Span size={sizes.chicken}>{item.chicken}</Grid.Span>
                <Grid.Span size={sizes.rooster}>{item.rooster}</Grid.Span>
                <Grid.Span size={sizes.start_date}>{_date(item.start_date).dmy()}</Grid.Span>
                <Grid.Span size={sizes.end_date}>{_date(item.end_date).dmy()}</Grid.Span>
            </Grid.FlexResponsive>
            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
