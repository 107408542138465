import React, { useRef } from 'react'
import { apis } from '../../../config/apis';
import Signboard from '../../../components/Signboard';
import PageMeta from '../../../components/PageMeta';
import QuickView from '../../../components/QuickView';
import { Table } from '../../../layout/table';
import Helpers from '../../../helpers/helpers';
import Crud from './crud';
import useFetch from '../../../hooks/useFetch';
import { TableHeader } from '../../../components/TableView';
import { _date } from '../../../helpers/date.helpers';

export default function EggDishesIndex() {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();
  
  
    const api = apis.dishes.paginate()
    
  
    const { data  , paginate , setters , alert } = useFetch(api);


    const buttons = [
        {
          title: "إضافة طبق جديد",
          onclick: () => createRef?.current?.toggle(),
        },
      ];

    const table = [
        {size : 1 , name : "#" , text : "#"  },
        {size : 6 , name : "name" , text :  'أسم الطبق' },
        {size : 4 , name : "eggs_in_dish" , text :  'عدد البيض في الطبق' },
        {size : 6 , name : "type" , text :  'نوع الطبق',  },
        {size : 4 , name : "createdAt" , text :  '  تاريخ الانشاء   ' , custom : d => _date(d.createdAt).dmy() },
        {size : 2 , name : '' , text : ''  }
    ]
    
  return (
    <div className='base-card bg-white r-16 p-2 mt-2'>
    <PageMeta title="الاطباق" />
    <Signboard {...alert}/>
    <QuickView ref={createRef} ><Crud.Create setMainData={setters.data}  /></QuickView>
    <QuickView ref={updateRef} ><Crud.Update setMainData={setters.data}  /></QuickView>
    <QuickView ref={deleteRef} ><Crud.Delete setMainData={setters.data}  /></QuickView>
    {/* <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} /> */}
    <div style={{marginTop: "150px"}}></div>
    <TableHeader buttons={buttons}   />
    <Table table={table} data={data}  updateRef={updateRef} deleteRef={deleteRef}  className="mt-3" />
  </div>
  )
}
