import feedOrdersCrud from './feed_crud';
import drugsOrderCrud from "./drugs_crud";
import maintenanceOrdersCrud from "./maintenance_crud";
import packagingOrdersCrud from "./packaging_crud";
import otherOrdersCrud from "./other_crud";
import cartonOrdersCrud from './carton_crud';
import dishesOrdersCrud from './dish_crud';

const OrdersCrud = {
    Feed: {...feedOrdersCrud},
    Drug: {...drugsOrderCrud},
    Maintenance : { ...maintenanceOrdersCrud},
    Packaging: {...packagingOrdersCrud},
    Other : {...otherOrdersCrud},
    Cartons : {...cartonOrdersCrud},
    Dishes : {...dishesOrdersCrud}
}
export default OrdersCrud
