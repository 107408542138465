import React from 'react'
import Skeleton from './Skeleton'

export default function SkeletonGroup() {
  return (
    <>
            <Skeleton animationDelay={".0s"}   />
                <hr className='my-[7px] bg-[#fdfdfd] block' />
            <Skeleton animationDelay={".5s"}   />
                <hr className='my-[7px] bg-[#fdfdfd] block' />
            <Skeleton animationDelay={"1s"}   />
                <hr className='my-[7px] bg-[#fdfdfd] block' />
            <Skeleton animationDelay={"1.5s"}   />
                <hr className='my-[7px] bg-[#fdfdfd] block' />
            <Skeleton animationDelay={"2s"}   />
                <hr className='my-[7px] bg-[#fdfdfd] block' />
            <Skeleton animationDelay={"2.5s"}   />
                <hr className='my-[7px] bg-[#fdfdfd] block' />
    </>
  )
}
