import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";
import CreateFeed from "../../../../origin/items_cards/forms/create/CreateFeed";
import UpdateFeed from "../../../../origin/items_cards/forms/update/UpdateFeed";
import DeleteFeed from "../../../../origin/items_cards/forms/delete/DeleteFeed";
import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";
import CreateFeedInvoice from "./CreateSupplementInvoice";
import UpdateSupplementInvoice from "./UpdateSupplementInvoice";
import DeleteSupplementInvoice from "./DeleteSupplementInvoice";
import CreateSupplementInvoice from "./CreateSupplementInvoice";

const BuySupplements = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);

    const title = "قائمة شراء التعبئة و التغليف"

    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "اضافة فاتورة التعبئة و التغليف",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "date", col: 2, custom : itm => new Date(itm.createdAt).toLocaleDateString("ar-EG") ,  title: "تاريخ الفاتورة"},
        {name: "invNumber", col: 1, title: "رقم القاتورة"},
        {name: "type", col: 1, title: "الاسم"},
        {name: "quantity", col: 1, title: "الكمية"},
        {name: "unitprice", col: 1, title: "سعر الوحده"},
        {name: "total", col: 1, title: "السعر الاجمالي"},
        {name: "measuringUnit", col: 2, title: "معلومات اضافية"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={title}/>

            <QuickView ref={createRef}>
                <CreateSupplementInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateSupplementInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteSupplementInvoice setMainData={setMainData}/>
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={title}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default BuySupplements;
