import {
  PageMeta,
  createFormHandler,
  EditForm,
  Signboard,
  InputText,
  InputNumber,
  InputSelect,
  postData,
} from "../../../../config/imports";
import { useState } from "react";
import { apis } from "../../../../config/apis";

export default function Create(props) {
  const { setMainData, toggle } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const [data, setData] = useState({});

  let signboard = {
    error: error,
    success,
    loading: loading,
  };

  const handleInputChange = (e) => {
    const { name, value, type } = e.target;
    data[name] = type === "number" ? +value : value;
    setData({
      ...data,
    });
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const create_props = {
        url: apis.incubator.client.create(),
        data ,
        setLoading,
        setError,
        setSuccess,
        setMainData,
        setData,
        FORM_HEADER: `الاضافة`,
    };

    await createFormHandler(create_props);

  };

  return (
    <>

      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3>إضافة عملاء صوص</h3>
      </header>

      <Signboard {...signboard} />

      <EditForm
        visible={true}
        onSubmit={handleFormSubmit}
        toggle={toggle}
        onChange={handleInputChange}>
        <InputText   name="name" title="الاسم" value={data.name} className={'col-6'} placeholder={'محمود احمد'} />
        <InputText   name="delegate" title="اسم المندوب" value={data.delegate} className={'col-6'} placeholder={'احمد السيد'} />
        <InputText   name="phone" title="رقم الهاتف" value={data.phone} placeholder={'01234567890'}  />
        <InputText   name="mobile" title="رقم الجوال" value={data.mobile} placeholder={'01123456789'} />
        <InputNumber name="breeding_eggs_houses" title="عدد بيوت التربية" value={data.breeding_eggs_houses} placeholder={'30'} />
        <InputNumber name="breeding_birds_houses" title="عدد طيور التربية" value={data.breeding_birds_houses} placeholder={'1500'} />
        <InputNumber name="production_eggs_houses" title="عدد بيوت الانتاج" value={data.production_eggs_houses} placeholder={'20'} />
        <InputNumber name="production_birds_houses" title="عدد طيور الانتاج" value={data.production_birds_houses} placeholder={'300'} />
        <InputNumber name="order_quantity" title="كمية الطلبيه" value={data.order_quantity} placeholder={'100'} />
        <InputNumber name="previous_order_quantity" title="كمية الطلبيه السابقه" value={data.previous_order_quantity} placeholder={'50'} />
        <InputNumber name="indebtedness" title="المديونيه" value={data.indebtedness} placeholder={'1500'} />
      </EditForm>
    </>
  );
}
