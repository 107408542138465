import React, { useState } from "react";

import { operatorAPI, originAPI } from "../origin.helpers";
import {
    Grid, Icons, useFetch,
    QuickView,
    Table, TableHeader, useRef, PageMeta, Signboard
} from "../../../config/imports";

import OriginFeedCrud from "./feed_crud";




const Feed = () => {

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();



    const { data: feed,error,isLoading, setData: setFeedData, } = useFetch(originAPI.feed);
    const { data: operators  , isLoading :  operatorLoading , error : operatorError} = useFetch(operatorAPI.url);

    const signboard =  {
        error : error || operatorError,
        loading : isLoading || operatorLoading
    }

    const sizes = {
        serial : 1,
        name : 5,
        measuringUnit : 4,
        operator : 6,
        info : 5,
        settings : 3
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}>#</Grid.Span>,
        date:<Grid.Span size={sizes.name}> الاسم  </Grid.Span>,
        measuringUnit:<Grid.Span size={sizes.measuringUnit}>   وحدة القياس  </Grid.Span>,
        operator:<Grid.Span size={sizes.operator}>   المورد    </Grid.Span>,
        info:<Grid.Span size={sizes.info}>   تفاصيل    </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
    }

return (
    <>

        <QuickView ref={createRef}><OriginFeedCrud.Create  operators={operators}  setMainData={setFeedData}/></QuickView>
        <QuickView ref={updateRef}><OriginFeedCrud.Update  operators={operators} setMainData={setFeedData}/></QuickView>
        <QuickView ref={deleteRef}><OriginFeedCrud.Delete  operators={operators} setMainData={setFeedData}/></QuickView>

        <Signboard {...signboard}  />
        <div className=' mt-5 bg-white r-16 p-2'  >
            <PageMeta title={'تقارير العلف اليوميه'} />
            {/*<Signboard {...signboard}/>*/}
            <TableHeader  buttons={ [
                {
                    title: " اضافة نوع علف جديد",
                    onclick: () => createRef.current.toggle()
                }
            ]}/>


            <div className="" style={{marginTop: '100px'}}></div>
            <Table.Header header={header}   />

            {
                feed &&  feed.length > 0  && feed.map((item , index) => (
                    <Grid.FlexResponsive  key={item._id} className={'align-items-center data-row'}>
                        <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                        <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
                        <Grid.Span size={sizes.measuringUnit}>{item.measuringUnit}</Grid.Span>
                        <Grid.Span size={sizes.operator}> {item.operator?.name ?? 'بدون'} </Grid.Span>
                        <Grid.Span size={sizes.info}> {item.info} </Grid.Span>
                        <Grid.Span size={sizes.settings} className='settings-buttons'>
                            <button  onClick={()=> updateRef.current.toggle(item)}><Icons.Edit /></button>
                            <button  onClick={()=> deleteRef.current.toggle(item)}><Icons.Delete /></button>
                        </Grid.Span>
                    </Grid.FlexResponsive>
                ))
            }

        </div>

    </>
)

};

export default Feed;
