import Create from "./Create";
import Update from "./Update";
import Delete from "./Delete"
// import DepositInIncubation from "./DepositInIncubation";
const HatcheriesCrud =  {
    Create,
    Update ,
    Delete

}
export default HatcheriesCrud;
