import {useState} from "react";
import {apis} from "../../../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../../../helpers/form.helper";

import {
    EditForm, InputNumber,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";
import InputMassUnit from "../../../../components/Shared/InputMassUnit";

export default function Create(props){

    const {setMainData, toggle , operators} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name: "",
        measuringUnit: "",
        types: "",
        operator: "",
        packetsNum: "",
        order: "",
        classification: "",
        info: "",
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.origins.packaging.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=> setMainData(pre =>{
                return [
                    ...pre,
                    {
                        ...data,
                        operator : operators.filter(o => o._id === data.operator)[0]
                    }
                ]
            }),
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        console.log(create_props.data)

        await createFormHandler(create_props);
    };
    return(
        <>
            <PageMeta title={' اضافة نوع تعبئه جديد  '} originTitle={' التعبئة والتغليف'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة نوع تعبئه جديد</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>

               <InputText name={'name'} title={'الاسم'} value={data.name} />
                <InputSelect name={'operator'} title={'المورد '} value={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />
                <InputMassUnit setData={setData} value={data.measuringUnit} />

                <InputSelect  name={'types'} title={'النوع'} value={data.types}  options={[
                    { _id : "Free" , name : "Free" },
                    { _id : "S" , name : "S" },
                    { _id : "XXL" , name : "XXL" },
                ]} />

                <InputNumber name={'packetsNum'} title={'عدد الرزمة'} value={data.packetsNum} onChange={handleInputChange} />
                <InputNumber name={'order'} title={'الطبيلة'} value={data.order} onChange={handleInputChange} />
                <InputTextArea name={'info'} title={'التفاصيل'} value={data.info} />


            </EditForm>

        </>
    )
}
