const BASE_URL = {
  dev: process.env.REACT_APP_DEV_SERVER_URL,
  prod: process.env.REACT_APP_PROD_SERVER_URL,
};

const CURRENT_ENV =
  process.env.REACT_APP_CURRENT_ENV === "development"
    ? BASE_URL.dev
    : BASE_URL.prod;

const company = {
  name: process.env.REACT_APP_COMPANY_NAME || "alsabbar",
};

const paths = {
  api: CURRENT_ENV + "/api/v1", // http://localhost:3000/api/v1
  uploads: CURRENT_ENV + "/uploads/", // http://localhost:3000/api/v1/uploads/
  thumbnails: CURRENT_ENV + "/uploads/thumbnails/", // http://localhost:3000/api/v1/uploads/thumbnails/,
  base : CURRENT_ENV + "/",
};
// years used in invoices to select invoices per year from dropdown from 2000 to 2050
const YEARS =  (end = +process.env.REACT_APP_DEFAULT_END_YEAR || 2015 ,
                start= +process.env.REACT_APP_DEFAULT_START_YEAR  || 2024 ) =>{
  const years = []
  for(let i = start; i <= +end; i++)
     years.push(i);

  return years
}

const DATA_LIMIT = 10

export { paths, company , YEARS , DATA_LIMIT};
