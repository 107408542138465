import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import EggsWarehouseComponent from '../../components/eggs_warehouse/EggsWarehouseComponent'
import PageMeta from '../../components/PageMeta'
import useEggsWarehouseStore from '../../components/eggs_warehouse/store/eggs_warehouse.store'
import { DepartmentsAccordionItem, HomesAccordionItem } from '../../components/eggs_warehouse/EggsWarehouseAccordionItem'

export default function ProjectEggsWarehouse() {

    const id = useParams().id


    const counts = useEggsWarehouseStore(state => state.formatted)
    const onSubmit = useEggsWarehouseStore(state => state.setSubmit)
    const submit = useEggsWarehouseStore(state => state.submit)
    const clear = useEggsWarehouseStore(state => state.clearProjectFilter)
    const filter = useEggsWarehouseStore(state => state.formatted)
    const setFilter = useEggsWarehouseStore(state => state.setFilter)
    const rendered = useEggsWarehouseStore(state => state.rendered)




   const projectDepartments = departments =>{
      return departments?.filter( d => d.project_id === id  )
   }

   const projectHomes = homes =>{
      const data = homes?.filter( h => h.project_id === id  )
      return data
   }


   useEffect(() => {
      setFilter({projects_ids : [id] , departments_ids : [], homes_ids : []})
   }, [id])


    const { projects_ids , departments_ids , homes_ids } = counts



    const resetAndClear = () => {
         clear(id)
         onSubmit()
    }


  return rendered  && (
     <>
        <PageMeta title="مستودع البيض" />

        <div className="flex">

               <div className="flex-1">
                  <EggsWarehouseComponent filter={ filter } deps={[id , submit]}   />
               </div>
               <div className="w-[350px] px-2 h-screen">
                  <div className="bg-white h-full py-4 relative">

                  <div className="text-center">
                     <h3 className='text-[#fcaa30] text-2xl '> البحث</h3>
                     <hr />
                  </div>
                  <DepartmentsAccordionItem   isOpen={true}   callback={ projectDepartments }     />
                  <HomesAccordionItem   isOpen={true}  callback={ projectHomes } />
                  <div className="bg-gradient-to-t flex  from-[#fff] justify-center  to-[#fff]/80  absolute bottom-0 w-full ">
                     <button 
                        onClick={onSubmit}
                        className=' shadow-lg bg-[#40a9f4] text-white text-center justify-center  
                        flex items-center px-2 py-2 w-[120px] text-[14px]  rounded-md  my-3 ml-3'>
                        اختر البيانات
                        ({projects_ids.length + departments_ids.length + homes_ids.length})
                     </button>
                     <button 
                        onClick={resetAndClear}
                        className=' shadow-lg bg-[#e46960] text-white text-center justify-center   ml-3
                        flex items-center px-2 py-2 w-[120px] text-[14px]  rounded-md  my-3'>
                           احذف الاختيارات
                     </button>
                  </div>
                  </div>
               </div>
        </div>
     </>
  )
}
