import React from 'react'
import Accordion from '../../../components/ui/accordion/Accordion'
import { DepartmentsSelectItemGroup, HomesSelectItemGroup, ProjectsSelectItemGroup } from './SelectItemGroup'
import useFetch from '../../../hooks/useFetch'
import { apis } from '../../../config/apis'
import useEggsWarehouseStore from '../store/eggs_warehouse.store'

export default function Selectors() {

  const {data : projects , Alert : ProjectsAlert } = useFetch(apis.projects.names)
  const {data : departments , Alert : DepartmentAlert } = useFetch(apis.departments.all())
  const {data : homes  , Alert : HomesAlert} = useFetch(apis.homes.homes_details()+ "?place_type=home")
  const counts = useEggsWarehouseStore(state => state.formatted)

  return (
    <div className=''>

      <Accordion isOpen={true}   header={<div className='bg-[#fcaa30] text-white px-2  w-full h-full flex items-center'> المشاريع  ({counts.projects_ids.length}) </div>}>
              <ProjectsAlert />
              <div className="ml-4"> <ProjectsSelectItemGroup data={projects && projects} />   </div>
      </Accordion>
      <Accordion  header={<div className='bg-[#fcaa30] text-white px-2  w-full h-full flex items-center'> الاقســام  ({counts.departments_ids.length})</div>}>
              <DepartmentAlert />
              <div className="ml-4"> <DepartmentsSelectItemGroup data={ departments && departments } />   </div>
      </Accordion>
      <Accordion  header={<div className='bg-[#fcaa30] text-white px-2  w-full h-full flex items-center'> البيـــــوت   ({counts.homes_ids.length})</div>}>
              <HomesAlert />
              <div className="ml-4"> <HomesSelectItemGroup data={homes && homes} />   </div>
      </Accordion>
    </div>
  )
}
