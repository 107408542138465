import { showErrorToast, showSuccessToast } from "../stores/toast.store";
import remove from "./axios/axios.delete";
import postData from "./axios/axios.post";
import updateData from "./axios/axios.put";
import { errorHandler } from "./error.helper";
import { refresh } from "./id.helper";

let FORM_PARENT = "";
let FORM_INPUTS = [];

const validateForm = (data) => {
  let result = {};
  const validationErrors = [];

  if (data === undefined || data === null) {
    return (result = {
      isValid: false,
      validationErrors: [
        {
          error: "لا توجد بياانات للتحقق منها",
          selector: "error",
        },
      ],
    });
  } else {
    data.forEach((d) => {
      if (
        d.required &&
        (d.value === "" ||
          d.length === 0 ||
          d.value === null ||
          d.value === undefined ||
          isNaN(d.value))
      ) {
        validationErrors.push({
          error: `يجب ملئ الحقل`,
          selector: d.name,
        });
        return (result = {
          isValid: false,
          validationErrors: [...validationErrors],
        });
      } else if (typeof d.value !== d.type) {
        validationErrors.push({
          error: `نوع البيان  غير صحيح  , مطلوب ${
            d.type === "string" ? "نص" : "رقم"
          } و الممُدخل ${typeof d.value === "string" ? "نص" : "رقم"}`,
          selector: d.name,
        });
        result = {
          isValid: false,
          validationErrors: [...validationErrors],
        };
      }
    });
  }

  if (validationErrors.length === 0) {
    return (result = {
      isValid: true,
      validationErrors: [],
      formatedData: ((data) => {
        let formatedData = {};
        data.forEach((d) => {
          formatedData[d.name] = d.value;
        });
        return formatedData;
      })(data),
    });
  } else {
    return (result = {
      isValid: false,
      validationErrors: [...validationErrors],
    });
  }
};

const setParent = (parent) => {
  FORM_PARENT = document.querySelector(parent);
  FORM_INPUTS = [...FORM_PARENT.querySelectorAll("[name]")];
};

const checkIfInputErrors = () => {
  FORM_INPUTS.forEach((input) => {
    input.classList.remove("error-input");
    input.closest("div").classList.remove("error-input-parent");
    input.closest("div").setAttribute("data-error", "");
  });
};

const markInputWithError = (errors) => {
  errors.forEach((error) => {
    let input = FORM_PARENT.querySelector(`[name=${error.selector}]`);

    input.classList.add("error-input");
    input.closest("div").classList.add("error-input-parent");
    input.closest("div").setAttribute("data-error", error.error);
  });
};

const catchInputsWithErrors = (errors) => {
  checkIfInputErrors();
  markInputWithError(errors);
};

const setInputsToDefault = () => {
  // console.log(FORM_INPUTS , FORM_PARENT)
  if (FORM_INPUTS === '' || FORM_PARENT === '' ) {
    document.querySelector("[type='reset']")?.click()
      return
  }


  FORM_INPUTS.forEach((input) => {
    if (input.tagName.toLowerCase() === "input") input.value = "";
    else if (input.tagName.toLowerCase() === "textarea") input.value = "";
    else if (input.tagName.toLowerCase() === "select")
      input.value = input.options[0].value;
    else input.value = "";
  });
};

const syncInputFielsErrorsChanges = (body) => {
  if (body === undefined || body === null) return;
  const { isValid, validationErrors: errors } = validateForm(body);

  if (!isValid) {
    FORM_INPUTS.forEach((input) => {
      input.addEventListener("change", () => {
        catchInputsWithErrors(errors);
        // markInputWithError(errors);
      });
    });
  }
};

function hasDataChanged(obj1, obj2, keysToCompare) {
  if (keysToCompare === undefined || keysToCompare === null)
    keysToCompare = Object.keys(obj2);

  for (const key of keysToCompare) {
    if (obj1[key] !== obj2[key]) {
      return true;
    }
  }
  return false;
}

// delete forms handler

// import remove from "../../../../../helpers/axios/axios.delete";

const deleteFormHandler = async (props) => {
  props.setLoading(true);
  const ITEM_TO_DELETED = props.item._id;

  const { data, error, statusCode } = await remove(
    props.url + "/" + ITEM_TO_DELETED
  );
  if (error) {
    props.setLoading(false);
    props.setError(error);
    props.setSuccess("");
    props.setConfirmBtn(true);
    showErrorToast(error);
    if (props.onError) props.onError(error);
  } else if (statusCode === 202 || statusCode === 200 || statusCode === 201 || statusCode === 204)  {
    props.setLoading(false);
    props.setSuccess("تم حذف العنصر بنجاح");
    showSuccessToast("تم حذف العنصر بنجاح");
    props.setConfirmBtn(false);

    if (props.onSuccess) props.onSuccess(data);



    if(props.setRefresh){
      props.setRefresh( refresh() )
    }else{
      if(!props.setMainData)  return
      props.setMainData((prev) => {
        const newData = prev.filter((item) => item._id !== ITEM_TO_DELETED);
        return newData;
      });
    }
    
  }
};

const createFormHandler = async (props) => {
  const { setError,setLoading,setSuccess,setMainData, setInputsToDefault, setData, data,url, FORM_HEADER,config , setRefresh , toggle } = props
    setLoading(true);
    setError(null);
    // setSuccess("");


  const isMultiPart = props.hasFiles || false

 
   let _config = {}
   if(isMultiPart){
      _config = {
        ...config,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
   } else {
      _config = config
   }
   

    const {
      data: postedData,
      error,
      statusCode,
    } = await postData(url, data , _config);

    if (error) {
      setLoading(false);
      setSuccess("");
      showErrorToast(error);
      return setError(errorHandler(error));
    }else if (statusCode === 202 || statusCode === 201 || statusCode === 200 || postedData) {

      setLoading(false);
      setSuccess(`تمت ${FORM_HEADER ?? 'الاضافة بنجاح'}`);
      showSuccessToast(`تمت ${FORM_HEADER ?? 'الاضافة بنجاح'}`);
      setMainData &&  setMainData(  (prevState) => {
        if(prevState && prevState.length > 0) return [...prevState, postedData]
        else return [postedData]
      });
      if(setRefresh){
        setRefresh( refresh() )
      }else{
        setData(
          Object.keys(data).reduce((acc, curr) => {
            acc[curr] = "";
            return acc;
          }, {})
        );
      }

      setTimeout(() => {
        if(toggle)
            toggle()
      }, 3000);
      if(setInputsToDefault)
        setInputsToDefault();
      
    }
};
const updateFormHandler = async (props) => {
  const {
          setMainData,
          setLoading,
          setError,
          setSuccess,
          setIsDataChanged,
          setRefresh,
          data,
          url,
  } = props

  setLoading(true);
  setIsDataChanged && setIsDataChanged(true);
  setError("");
  setSuccess("");

  // console.log(REQUEST_BODY);
  const {
    data: updates,
    error,
    statusCode,
  } = await updateData(url, data);

  if (error) {
    setLoading(false);
    setIsDataChanged && setIsDataChanged(false);
    setSuccess("");
    showErrorToast(error);
    return setError(errorHandler(error));
  }

  if (statusCode === 202 || statusCode === 201 || statusCode === 200 || updates.data || updates.data.data) {

    setLoading(false);
    setError("");
    setSuccess("تم تعديل البيانات بنجاح");
    showSuccessToast("تم تعديل البيانات بنجاح");
    setMainData((prevState) => {
      const itemIndex = prevState.findIndex(
        (item) => {
          if (updates.data) return item._id === updates.data._id
          else return  item._id === updates._id
        }
      );
      prevState[itemIndex] = updates.data || updates;
      return [...prevState];
    });

    if(setRefresh){
      setRefresh( refresh() )
    }

    setIsDataChanged && setIsDataChanged(false);
  }
};


const handleInputChange = ({e , setData}) => {
  // console.log(e.target.value)
  setData((prevState) => ({
    ...prevState, [e.target.name]: e.target.value,
  }));
};


export {
  validateForm,
  checkIfInputErrors,
  markInputWithError,
  catchInputsWithErrors,
  setParent,
  setInputsToDefault,
  syncInputFielsErrorsChanges,
  hasDataChanged,
  deleteFormHandler,
  createFormHandler,
  updateFormHandler,
  handleInputChange,
};
