import { useEffect } from "react";
import LinkList from "../../components/LinkList";

import useNavigator from "../../hooks/useNavigator";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, originCardsBreadCrumb, originsBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";

const data = [
  {
    name: "إحصائيات الاصول",
    path: "asset-data",
  },
  {
    name: "كروت الاصول",
    path: "origin-cards",
  },
  {
    name: "كروت الاصناف",
    path: "items-cards",
  },
  {
    name: "قائمة سلالة الطيور",
    path: "bird-breed",
  },
  {
    name: "الاوزان",
    path: "weights",
  },
  // {
  //   name: "الكراتين",
  //   path: "cartoons",
  // },
  // {
  //   name: "الاطباق",
  //   path: "egg-dishes",
  // },
];



const Origin = () => {

  useNavigator("origin", "asset-data");

  useEffect(()=>{
    setBreadCrumbsStore([
        homeBreadCrumb,
        originsBreadCrumb,
  ])

} , [])




  return (
    <>
      <div className="">
        <div className="custom-nav-links-container">
          {/* the right nav [ | بيانات الاصول| كروت الاصول | قائمة سلالة الطيور] */}
          <LinkList data={data} />
        </div>
      </div>
    </>
  );
};

export default Origin;
