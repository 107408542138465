import { useMemo, useState } from "react";
import { apis } from "../../../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../../../helpers/form.helper";
import { EditForm, InputNumber, InputSelect, InputTextArea, PageMeta, Signboard } from "../../../../../config/imports";
import { useParams } from "react-router-dom";
import useStockCartons from "../../../../stocks/hooks/useStockCartons";

export default function Create(props){

    const {id : home_id } = useParams()

    const {setRefresh, toggle , operators , types} = props;

    const {  options   } = useStockCartons("carton")


    
    const measuringUnits = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        home_id,
        type: "",
        unit: "",
        quantity: "",
        info: "",
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.orders.cartons.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=>{}, 
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        // console.log(create_props.data)

        await createFormHandler(create_props);
    };
    return(
        <>
      
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة طلب كراتين</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={ true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InputSelect    name={'type'} title={'النوع '} defaultValue={data.type}  options={options} />
                <InputSelect    name={'unit'} title={'وحدة القياس '} defaultValue={data.unit}  options={measuringUnits} />
                {/* <InputMassUnit name={'unit'} title={'وحدة القياس'} value={data.unit} setData={setData}   /> */}
                <InputNumber   name={'quantity'} title={'الكمية المطلوبة'} defaultValue={data.quantity} onChange={handleInputChange} />
                <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />
            </EditForm>

        </>
    )
}
