import {InputText , InputNumber} from "../../../../config/imports";

const landInputs = [
    { name : 'name', title :  'أسم الأرض', input : InputText},
    { name : 'characteristic', title :  'صفة الأرض', input : InputText},
    { name : 'location', title :  'موقعها', input : InputText},
    { name : 'length', title :  'طول الأرض', input : InputNumber},
    { name : 'width', title :  'عرض الأرض', input : InputNumber},
    { name : 'value', title :  'قيمة الأرض', input : InputNumber},
    { name : 'account_name', title :  'أسم الحساب', input : InputText , size : 6},
    { name : 'account_symbol', title :  'رمز الحساب', input : InputText , size : 6},
]
export default landInputs
