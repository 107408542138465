import React from 'react'
import PropTypes from 'prop-types'

function Page(props) {
  const { page, setPage, currentPage } = props

  return (
    <div
        className={
            `
                w-[30px] h-[30px] flex items-center justify-center text-center   rounded-[50%] cursor-pointer
                ${ page === currentPage ? "bg-[#0CC857] text-white shadow-lg" : "bg-transparent text-black" }
            `
        }
        onClick={()=> setPage(page)}
    >
      {page}
    </div>
  )
}

Page.propTypes = {
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
}

export default Page
