import React from "react";
import ComponentsContainer from "./ComponentsContainer";
import SideNav from "./SideNav";
import "../styles/mainlayout.scss";
import BreadCrumbs from "../components/breadcrumbs/components/BreadCrumbs";

const MainLayout = () => {


  return (
    
    <div className="main-layout-comp">
      <div className="">
        <SideNav />
      </div>
          <div className="app-comp">
            <div className="px-4 py-3  w-full">
              <BreadCrumbs />
            </div>
            <ComponentsContainer />
          </div>
    </div>
   
  );
};

export default MainLayout;
