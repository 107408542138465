// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-levels .level-header {
  align-items: center;
}
.home-levels .level-header .ico {
  transform: rotate(180deg);
  height: 30px;
  width: 30px;
  text-align: center;
  border-radius: 50%;
  background-color: #F4F4F4;
}`, "",{"version":3,"sources":["webpack://./src/styles/levels.styles.scss"],"names":[],"mappings":"AAIE;EAIE,mBAAA;AANJ;AASO;EACE,yBAAA;EACA,YAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;AAPT","sourcesContent":[".home-levels{\n  //*{\n  //  outline : 1px solid teal;\n  //}\n  .level-header{\n    //transform : 'rotate(180deg)' , backgroundColor : '#F4F4F4' ,\n    //height : 30 , width : 30 , textAlign : 'center' , borderRadius : '50%'\n    //\n    align-items : center;\n\n\n       .ico{\n         transform : rotate(180deg);\n         height : 30px;\n         width : 30px;\n         text-align : center;\n         border-radius : 50%;\n         background-color : #F4F4F4;\n       }\n\n\n\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
