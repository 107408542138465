export default function PricesIndex(props){

    const PRICES_INDEX_LINKS = [
        {name: "الاسعار الحاليه",  path: "current", },
        {name: "الااسعار السابقة ",  path: "previous", },
    ];


    return(
    <>  hello index</>
    )
}

const pricesDetails = {

    origin: "prices",
    navigateTo: "current",
    title: "الاسعار",
    links: [
        {to: "current",name: "الاسعار الحاليه"},
        {to: "previous",name: " الاسعار السابقة"},
    ],
};
export {
    pricesDetails
}
