import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";
import InputText from "../../../../../components/FormControls/InputText";
import InputSelect from "../../../../../components/FormControls/InputSelect";
import InputDate from "../../../../../components/FormControls/InputDate";
import InputNumber from "../../../../../components/FormControls/InputNumber";
import InputTextArea from "../../../../../components/FormControls/InputTextArea";
import {useState} from "react";
import useFetch from "../../../../../hooks/useFetch";
import {operatorAPI} from "../../../../operators/operator.routes";
import {strainAPI} from "../../../../origin/bird_breed/bird_breed.routes";
import {createFormHandler, setInputsToDefault} from "../../../../../helpers/form.helper";
import SelectOperator from "../../../../../components/Shared/SelectOperator";

const FORM_HEADER = "تعديل فاتورة شراء";
const MAIN_TITLE = "قائمة شراء الاعلاف";

const UpdateFeedInvoice = ({toggle , item, setMainData}) => {

    const [data, setData] = useState(item);
    const {data: ops} = useFetch(operatorAPI.url)
    let names = Object.keys(data);
    // console.log(data)
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: strainAPI.url,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER,
        };

        createFormHandler(create_props);
    };

    const handleInputChange = (e) => {

        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const WeightUnits = [
        {
            name: "طن",
            _id: "'طن"
        }, {
            name: "كيلو",
            _id: "'كيلو"
        }, {
            name: "جرام",
            _id: "'جرام"
        },
    ]

    return (
        <div className="temp-form">
            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <form onSubmit={handleFormSubmit} method="post">
                <div className="row align-items-center">
                    <InputText name={names[0]} title={"رقم الفاتورة"} handleInputChange={handleInputChange} defaultValue={data[0]} />

                    <SelectOperator  defaultValue={data["operator"]._id} handleInputChange={handleInputChange}  />
                    <InputDate title={"التاريخ"} name={names[2]} handleInputChange={handleInputChange} defaultValue={data[0]} />
                    <InputText title={"نورع العلف"} name={names[3]} handleInputChange={handleInputChange} defaultValue={data[0]}
                               className={"col-12 col-md-3 col-lg-3"}/>
                    <InputSelect options={WeightUnits} name={names[4]} title={"وحدة العلف"}
                                 handleInputChange={handleInputChange} defaultValue={data["measuringUnit"]}  className={"col-12 col-md-3 col-lg-3"}/>
                    <InputNumber title={"سعر الوحدة"} name={names[5]} handleInputChange={handleInputChange} defaultValue={data[0]}
                                 className={"col-12 col-md-3 col-lg-3"}/>
                    <InputNumber title={"الكمية"} name={names[6]} handleInputChange={handleInputChange} defaultValue={data[0]}
                                 className={"col-12 col-md-3 col-lg-3"}/>
                    <InputNumber title={"اجمالي المبلغ"} name={names[7]} handleInputChange={handleInputChange} defaultValue={data[0]}
                                 className={"col-12"}/>
                    <InputSelect options={ops} title={"اختر المستودع"} name={names[8]}
                                 handleInputChange={handleInputChange} defaultValue={data["operator"]._id}  className={"col-12"}/>
                    <InputTextArea name={names[9]} handleInputChange={handleInputChange} defaultValue={data[0]}  title={"ملاحظات"}/>
                    <div className="col-12 col-md-12 mt-4">
                        <div className="form-group row justify-content-between align-items-center">
                            <div className="col">
                                <button type="button" onClick={() => toggle()} className="close-btn">
                                    الغاء
                                </button>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <button className="add-btn" onClick={handleFormSubmit}>
                                    اضافة
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </div>

    );

}

export default UpdateFeedInvoice;