import {useEffect, useRef, useState} from "react";
import {apis, reports_api, users_api, weight_api} from "../../../config/apis";
import {setInputsToDefault, updateFormHandler} from "../../../helpers/form.helper";
import {
    _date,
    EditForm, Grid, InputDate, InputNumber,
    InputSelect,
    InputText,
    InputTextArea, InputTextAsNumber,
    PageMeta,
    Signboard, updateData
} from "../../../config/imports";

import ArrayHelpers from "../../../helpers/array.helpers";
import postData from "../../../helpers/axios/axios.post";
import Select from "../../../components/FormControls/Select";
import Icons from "../../../layout/Icons";
import {paths} from "../../../config/configs.env";
import Browser from "../../../layout/browser/Browser";
import useUsersData from "../hooks/useUsersData";

export default function Update(props){

    const userImgRef = useRef(),
        userImgTitleRef = useRef() ,
        userImgFile = useRef();


    const { toggle  , roles , updateMain , item} = props;

    const { options  } = useUsersData()

    const [tabToBeRendered , setTabToBeRendered] = useState(1)

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({
        _id : item._id,
        // user_img : item.user_img,
        gender : item.gender,
        username : item.username,
        birth_date : item.birth_date,
        place : item.place,
        city : item.city,

        postal_code : item.postal_code,
        phone : item.phone,
        mobile : item.mobile,

        homes : item.homes,
        projects : item.projects,
        departments : item.departments,
        stocks : item.stocks,

        roles : [...item?.roles.map(role => role._id)],
        user_type : item.user_type,
    })

    // console.log({img : data.user_img})
    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };



    // when update is done and success is true will reload main data again
    useEffect(()=>{
        if(success && success.length > 0)
            updateMain(true)
        else updateMain(false)
    } , [success])

    const handleFormSubmit = async (e) => {
        setLoading(true);
        setError('')
        setSuccess('')
        e.preventDefault();

        const {data: postedData, error, statusCode, message} =
            await updateData(users_api.update  ,  {...data})

        setLoading(false)
        if (error)  return setError(error)
        setSuccess('تم تعديل المستخدم بنجاح')

    };



    const handleUserImage = e =>{
        let input = e.target ? e.target : e

        setData({
            ...data ,
            user_img : e.target.files[0]
        })

        let reader = new FileReader()
        reader.readAsDataURL(input.files[0])
        reader.onload = () => {
            userImgRef.current.src = reader.result
            userImgTitleRef.current.textContent = input.files[0].name ?? 'اختر الصورة'
        }
    }


    const browserData = [
        {
            tab  : { title : " البيانات الشخصية" , size : 6},
            content :<>
                <InputText name={'username'} handleInputChange={handleInputChange} title={'اسم المستخدم   '} defaultValue={data.username}/>
                <InputDate name={'birth_date'} handleInputChange={handleInputChange}title={'  تاريخ الميلاد'} defaultValue={_date(data.birth_date).forInputDate()}/>
                <Select name={'gender'}  handleInputChange={handleInputChange} title={'الجنس'} defaultValue={data.gender} options={[
                    { value : "ذكر" , text : 'ذكر' },
                    { value : "انثى" , text : 'انثى' },
                ]}/>
                <InputText className={'col-6'} name={'place'} handleInputChange={handleInputChange} title={' المكان '}   defaultValue={data.place}/>
                <InputText className={'col-6'} name={'city'} handleInputChange={handleInputChange}   title={' المدينه '} defaultValue={data.city}/>

            </>
        },
        {
            tab  : {title : " بيانات التواصل " , size : 6},
            content :<>


                <InputTextAsNumber  className={'col-4'} name={'postal_code'} handleInputChange={handleInputChange} placeholder={'5640654'} label={' الرمز البريدي'} defaultValue={data.postal_code}/>
                <InputTextAsNumber  className={'col-4'} name={'phone'} handleInputChange={handleInputChange} placeholder={'0552181341'} label={'رقم الهاتف'} defaultValue={data.phone}/>
                <InputTextAsNumber  className={'col-4'} name={'mobile'} handleInputChange={handleInputChange} placeholder={'20134567890'} label={'رقم الجوال'} defaultValue={data.mobile}/>


            </>
        },
        {
            tab  : {title : "الادوار" , size : 6},
            content :<>
                <InputSelect multiple={true} className={'col-6'} name={'roles'}  handleInputChange={handleInputChange} title={'الدور'} defaultValue={data.roles} options={roles} />
                <InputSelect  className={'col-6'} name={'user_type'}  handleInputChange={handleInputChange} title={'الصلاحيات الاداريه'} defaultValue={data.user_type} options={[
                    { value : "worker" , text : 'عامل بيت' },
                    { value : "warehouse_man" , text : 'أمين مستودع' },
                    { value : "project_supervisor" , text : 'مشرف مشروع' },
                    { value : "project_manager" , text : 'مدير مشروع' },
                    { value : "doctor" , text : 'الطبيب المشرف' },
                    { value : "farm_manager" , text : 'مدير مزرعه' },
                    { value : "projects_manager" , text : 'مدير مشاريع' },
                    { value : "delegate" , text : 'مندوب المشريات' },
                ]} />
            </>
        },
        {
            tab  : {title : " الافرع" , size : 6},
            content :<>
                <InputSelect multiple={true} className={'col-12'} name={'homes'}  handleInputChange={handleInputChange} title={'البيوت'} defaultValue={data.homes} options={options.homes} />
                <InputSelect multiple={true} className={'col-12'} name={'departments'}  handleInputChange={handleInputChange} title={'الاقسام '} defaultValue={data.departments} options={options.departments} />
                <InputSelect multiple={true} className={'col-12'} name={'projects'}  handleInputChange={handleInputChange} title={'المشاريع '} defaultValue={data.projects} options={options.projects} />
                <InputSelect multiple={true} className={'col-12'} name={'stocks'}  handleInputChange={handleInputChange} title={'المستودعات '} defaultValue={data.stocks} options={options.stocks} />
            </>
        },
    ]


    return(
        <>
            <PageMeta title={'تعديل المستخدم ' + item.username} originTitle={' المستخدمين '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   تعديل المستخدم {item.username}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm visible={false} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
                <Browser data={browserData} toggle={toggle} />
            </EditForm>
        </>
    )
}
