import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
    _date,
    createFormHandler, EditForm, Grid, Icons, InputDate, InputNumber, InputSelect,
    PageMeta,
    setInputsToDefault,
    Signboard,
    useFetch
} from "../../../../../../../config/imports";
import {departments_api, packaging_api, reports_api, weight_api} from "../../../../../../../config/apis";
import "../../../../../../../styles/reports.styles.scss"

export default function Create(props){

    const {id} = useParams();
    const {setMainData, toggle} = props;


    const {data : departmentReports , setData  : setDepData , alert} = useFetch(departments_api.reports.eggs.form(id))


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let [data , setData] = useState({
        date : new Date(),
        homes : [],
        createdAt : new Date(),
        updatedAt : new Date(),
    })


    let signboard = {
        error :  error  || alert.error,
        success  ,
        loading : loading  || alert.loading,
    }

    console.log({data})


     useEffect(()=>{
         if (departmentReports && departmentReports.length > 0)
             setData({
                 date : data.date,
                 homes : [...departmentReports],
                 createdAt : new Date().toISOString(),
                 updatedAt : new Date().toISOString(),
             })
     } , [departmentReports])

 
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: reports_api.home.eggs.createDepartmentReport(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=> setMainData("refresh-api"),
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.data})

        await createFormHandler(create_props);
    };


    const handleInputChange = (e , changeData) => {
        const { homeIndex , cartonIndex , weightIndex} = changeData

        const targetedHome  = data.homes[homeIndex]
        const targetedCarton = targetedHome.cartons[cartonIndex]
        const targetedWeight = targetedCarton.weights[weightIndex]
        targetedWeight.amount = +e.target.value

        data = {...data}

        setData(data)

    }

    return(
        <>
            <PageMeta title={'اضافة تقرير بيض شامل يومي'} originTitle={' الشامله تقارير البيض'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   اضافة تقرير بيض شامل يومي</h3>
            </header>

            <Signboard {...signboard}/>
{
    departmentReports &&  <EditForm
    visible={props.showFooter === undefined}
    onSubmit={handleFormSubmit}
    toggle={toggle}
>
    <div className="inputs-container" style={{ overflowY : 'auto' }}>
    <div className="row align-items-center ">
    <InputDate
        title={'تاريخ تسجيل التقرير'}
        className={'col-6'}
        name={data.date}
        handleInputChange={e => {
            data  = { 
                ...data,
                homes : [...data.homes.map(home =>{
                    return {
                        ...home,
                        date : new Date(e.target.value).toISOString()
                    }
                })],
                date :new Date(e.target.value).toISOString() }
            setData({...data})
        } }
        defaultValue={_date(data.date).forInputDate()}
    />
    <div className="formatted-date col-6 mt-4 text-start">
        {_date(data.date).local()}
    </div>
    </div>
        {
            departmentReports && departmentReports.length > 0 && departmentReports.map((home , homeIndex) => {
                  return (<div style={{borderBottom : '1px solid #d5d5d5'}} key={homeIndex}>
                          <Grid.Span size={8} key={home.home} style={{marginTop :0 }}>  {home.home_name } </Grid.Span>

                            {
                                home.cartons && home.cartons.length > 0 && home.cartons.map((carton , cartonIndex) => {
                                    return (
                                        <Grid.FlexResponsive key={cartonIndex} className={'align-items-center report-row'}  >
                                            <Grid.Span size={4} className={'d-flex align-items-center gap-2 justify-content-center'} style={{marginTop :20}}> {carton.name} </Grid.Span>
                                                {
                                                    carton.weights && carton.weights.length > 0 && carton.weights.map((weight , weightIndex)=>{
                                                        return <InputNumber
                                                            key={weightIndex}
                                                            handleInputChange={(e)=>handleInputChange(e , {homeIndex , cartonIndex , weightIndex})}
                                                            className={'span-3 input px-1 mb0 '}
                                                            placeholder={'0'}
                                                            title={weight.name}
                                                            defaultValue={weight.amount === 0 ? '' : weight.amount}
                                                            required={false}
                                                        />
                                                    })
                                                }
                                        </Grid.FlexResponsive>
                                    )
                                })

                            }

                      </div>

                         )
            })
        }


    </div>


</EditForm>
}
           
        </>
    )
}
