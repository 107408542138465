import LinkList from "../../../components/LinkList";
import useNavigator from "../../../hooks/useNavigator";

const BirdBreed = () => {
  const links = [
    {
      name: "تربية",
      path: "breeding",
    },
    {
      name: "انتاج",
      path: "production",
    },
  ];
  useNavigator("bird-breed", "breeding");

  return (
    <div className="base-card">
      <div className="ilc mt-2 bg-white px-3 py-3 r-16">
        <div className="icbc">
          <div className="base-card-links-container">
            {<LinkList data={links} underline={true} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirdBreed;
