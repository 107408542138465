import {_date, deleteFormHandler, Grid, Signboard  , PageMeta} from "../../../config/imports";
import React, {useEffect, useState} from "react";
import {apis, reports_api, users_api, weight_api} from "../../../config/apis";
import Table from "./../../../layout/table/Table"
import DeleteTable from "../../../layout/table/DeleteTable";


export default function Delete(props){

    const {toggle , item , setMainData , updateMain} = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);


    useEffect(()=>{
        if(success && success.length > 0)
            updateMain(true)
        else updateMain(false)
    } , [success])

    const table = [
        { size : 5 , name : 'username' , text : 'اسم المستخدم' },
        { size : 6 , name : 'email' , text : 'البريد الالكتروني' },
        { size : 8 , name : 'roles' , text : 'الادوار' , custom : item =>{
           return(
                 item.roles?.map(role => {
                    return <span key={role._id} className={'role-name'}>{role.role}</span>
                })
               )
            } },
        { size : '' , name : '' , text : '' },
    ]

    const handleDelete = async () => {
        const delete_props = {
            url: users_api.delete ,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        // console.log(delete_props)
        await deleteFormHandler(delete_props);
    };

    return(
        <>
            <PageMeta title={  'حذف المستخدم   ' + item.username + "؟"} originTitle={'  المستخدمين '} />

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>  حذف   المستخدم   {item.username} ؟</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>

            <DeleteTable  table={table} data={[item]} />

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
