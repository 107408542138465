import {useState} from "react";
import {constructUpdateStateDataFromTable, destructureTable} from "../config/imports";

const useUpdateStates = (props) => {

    const {table , item} = props

    const { body } = destructureTable(table)

    const formattedUpdateData = constructUpdateStateDataFromTable(body , item)

    const [data , setData] = useState({
        ...formattedUpdateData
    })

    return {data  , setData}

}

export default useUpdateStates