import {_date, deleteFormHandler, Grid, Signboard, Table} from "../../../../config/imports";
import React, {useState} from "react";
import {apis, reports_api, weight_api} from "../../../../config/apis";
import PageMeta from "../../../../components/PageMeta";

export default function DeleteInitBalance(props){

    const {toggle , item , setMainData} = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const sizes = {
        year : 5,
        createdAt : 6,
        updatedAt : 8,
    }
    const header = {
        date:<Grid.Span size={sizes.year}> العام  </Grid.Span>,
        createdAt:<Grid.Span size={sizes.createdAt}>    تاريخ الانشاء  </Grid.Span>,
        updatedAt:<Grid.Span size={sizes.updatedAt}>   تاريخ احر تعديل    </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
    }

    const handleDelete = async () => {
        const delete_props = {
            url: apis.balances.init.delete() ,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        // console.log(delete_props)
        await deleteFormHandler(delete_props);
    };

    return(
        <>
            <PageMeta title={  'حذف   الارصده الافتتاحيه لعام ؟' + item.year } originTitle={' الارصده الافتتاحيه'} />

            <header className="w-fit m-auto px-4 text-center border-bottm-line  mb-3">
                <h3>  حذف   الارصده الافتتاحيه لعام   {item.year} ؟</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>


            <Table.Header header={header}  className={'delete-header'}  />

            <Grid.FlexResponsive   className={'align-items-center data-row'}>
                <Grid.Span size={sizes.year}>{item.year}</Grid.Span>
                <Grid.Span size={sizes.createdAt}>{_date(item.createdAt).dmy()}</Grid.Span>
                <Grid.Span size={sizes.updatedAt}>{_date(item.updatedAt).dmy()}</Grid.Span>
            </Grid.FlexResponsive>
            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
