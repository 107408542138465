import React, {useRef} from "react";
import "../../../styles/origins.styles.scss"
import {QuickView, Signboard, TableHeader, useFetch} from "../../../config/imports";
import PageMeta from "../../../components/PageMeta";
import {apis} from "../../../config/apis";
import CreateOriginAsset from "./CreateOriginAsset";
const Asset = () => {

  const createRef = useRef()

  const {data , isLoading , error ,  Alert} = useFetch(apis.assets.all())
  

  return <div className="assets-data base-card p-2 mt-2">
  
    <PageMeta title="بيانات الاصول" />
    <Alert />


      <div className="d-flex flex-wrap">
            {
              data?.map((item , index) => (
                  <div className="asset-card" key={index}>
                        <div className="asset-card-value">
                            <span>{item.sum.toLocaleString('en-US', { style: 'decimal', useGrouping: true})}</span>
                        </div>
                        <div className="asset-card-title">
                            <span>{item.ar_type}</span>
                        </div>
                  </div>
              ))
            }

      </div>


  </div>;
};

export default Asset;
