import React, { useState } from 'react'
import useFetcher from './useFetcher'
import { DATA_LIMIT } from '../config/configs.env'
import SearchInput from '../components/search/SearchInput'
import usePaginateStore from '../stores/paginate.store'

export default function usePaginate(api) {

    const search = usePaginateStore(state => state.search)
    const page = usePaginateStore(state => state.page)
    
    const [query, setQuery] = useState({
        search: search,
        page: page,
        limit: DATA_LIMIT
    })



 


}
