import React from 'react'
const selectWithNames = (elements , names) => {
    if(!elements || !names) return []
    names = names.split(' ')
    let result = []
    elements.map(elm => {
        let obj = {}
        for (const name of names){
            if(elm[name] !== '' && elm[name] !== null && elm[name] !== undefined)
                obj[name] = elm[name]
        }
        result.push(obj)
    })
    return result
}

const ArrayHelpers = {
    selectWithNames,
    makeOptionsForInputSelect : (data , name) => {
        const options = []
        data&& data.forEach(elm => {
            options.push( {  _id : elm._id , name :elm[name] ?? elm.name })
        })
        return options
    },
    optionsV2 : (data , name) => {
        const options = []
        data&& data.forEach(elm => {
            options.push( {  value : elm.value ?? elm._id , text :elm[name] ?? elm.text ?? elm.name })
        })
        return options
    },
    range : (length) => {
        let result = []
        for(let i = 0 ; i < length ; i++){
            result.push(i + 1)
        }
        return result
    },
    simpleInputsGenerator : (array , data , handleInputChange ) => {
      return array?.map(elm => {

          const { name , title , placeholder, input : Input , size , customValue } = elm





          return (
              <Input
                  {...elm}
                  name={name}
                  className={size ?  `col-${size}` : 'col-12 col-md-4 col-lg-4'  }
                  title={title}
                  key={name}
                //   value= {  data[name] }
                  placeholder={placeholder}
                  handleInputChange={handleInputChange}
                  defaultValue={ data[name] }
              />
          )

        })

    }

}

export default  ArrayHelpers


