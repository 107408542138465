import "../../../../../styles/custom.grid.css";
import React, { useCallback, useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { strainAPI } from "../../bird_breed.routes";
import Signboard from "../../../../../components/Signboard";
import updateData from "../../../../../helpers/axios/axios.put";
import { EditForm, Grid, InputNumber } from "../../../../../config/imports";
import { strain_api } from "../../../../../config/apis";
import ArrayHelpers from "../../../../../helpers/array.helpers";
import { accio } from "../../../../../helpers/axios/axios.get";
import { key } from "../../../../../helpers/id.helper";

const ProductionDetails = (props) => {
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  let [details , setDetails] = useState([])

  let [updatesData , setUpdatesData] = useState([])

  const {data, error: fetchingErr, isLoading , setData : setStrain} = useFetch(strainAPI.productionAge + "/" + props.item._id);

  useEffect(() => {
    if(!data) return
    details = [...data.classification]
    setDetails(details)
  }, [data]);


  const handleInputChange = (e, props) => {

      const { value } = e.target;
      
      const { key, index } = props;
      // Create a copy of the details array
      const updatedDetails = [...details];
      // Create a copy of the item to be updated
      const updatedItem = { ...updatedDetails[index] };
      // Update the specific key of the item
      updatedItem[key] = +value;
      // Update the copy of the details array with the updated item
      updatedDetails[index] = updatedItem;

      // Find if the item is already in updatesData to update it
      const updateIndex = updatesData.findIndex(item => item._id === updatedItem._id);

      if (updateIndex >= 0) {
        // Update the existing item in updatesData array
        const updatedUpdatesData = [...updatesData];
        updatedUpdatesData[updateIndex] = updatedItem;
        setUpdatesData(updatedUpdatesData);
      } else {
        // Add the updated item to updatesData array
        const updatedUpdatesData = [...updatesData, updatedItem];
        setUpdatesData(updatedUpdatesData);
      }

      // Update the state with the new details array
      setDetails(updatedDetails);
    // } , 1000)
  };

  const handlePagination = useCallback(async(page) => {
    setLoading(true)
    const {data : pageData , error} = await accio(strainAPI.productionAge + "/" + props.item._id + "?page=" + page)
    setLoading(false)
    if(error) return setError(error)
    setStrain( {...pageData})
  })


  const handleSubmit = async (e) => {
    setLoading(true)
    e.preventDefault()

    // console.log(productionDetails.classification)
    const {data , message} = await updateData(strain_api.classifications(props.item._id) , {classification : updatesData})
    setLoading(false)
    //
    if(!data) return setError(message)
    setSuccess("تم تعديل البيانات بنجاح")

  }


  return (
    <div className="breeding-details ">
      <header className="w-fit m-auto pb-3 px-4 text-center border-bottm-line ">
        <h3>تفاصيل السلالة</h3>
      </header>
      <Signboard error={error || fetchingErr} loading={loading || isLoading} success={success} />
      <div className="row my-4 justify-content-center">
             <div className="col-12 col-md-6 col-lg-6 d-flex">
               <span className="mx-2">نوع السلالة :</span>
               <strong>{data?.name}</strong>
             </div>
             <div className="col-12 col-md-6 col-lg-6 d-flex">
               <span className="mx-1">عمر السلالة :</span>
               <strong>{data?.production_age}</strong>
             </div>
           </div>
      <hr/>
      <EditForm
          visible={props.showFooter === undefined}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          toggle={props.toggle}
      >
        <>
          <Grid.FlexResponsive className={'overflow-auto'}>
              <div className="production-age-names">
                <p> الاسبوع  </p>
                <p> غير معقول  </p>
                <p> الظروف المثالية  </p>
                <p>  متوسط الإنتاج </p>
                <p>  الظروف المتوسطة </p>
                <p> أدنى إنتاج  </p>
                <p>  استهلاك الاعلاف  </p>
                <p>   نسبة النافق </p>
              </div>
                {
                  details && details.length > 0 && details.map((item , index) => {
                        return  <div className={'d-flex flex-column  span-4 production-age-col p-0'} key={item._id ?? key()}>
                                <Grid.Span> {item.age} </Grid.Span>
                                <InputNumber className={'w-100'} name={'unbelievable'} defaultValue={item.unbelievable} handleInputChange={(e) => handleInputChange(e , {key : "unbelievable" , index})} />
                                <InputNumber className={'w-100'} name={'ideal_conditions'} defaultValue={item.ideal_conditions} handleInputChange={(e) => handleInputChange(e ,  {key : "ideal_conditions" , index})} />
                                <InputNumber className={'w-100'} name={'average_output'} defaultValue={item.average_output} handleInputChange={(e) => handleInputChange(e ,{key : "average_output" , index})} />
                                <InputNumber className={'w-100'} name={'medium_conditions'} defaultValue={item.medium_conditions} handleInputChange={(e) => handleInputChange(e , {key : "medium_conditions" , index})} />
                                <InputNumber className={'w-100'} name={'lowest_production'} defaultValue={item.lowest_production} handleInputChange={(e) => handleInputChange(e , {key : "lowest_production" , index})} />
                                <InputNumber className={'w-100'} name={'feedCons'} defaultValue={item.feedCons} handleInputChange={(e) => handleInputChange(e , {key : "feedCons" , index})} />
                                <InputNumber className={'w-100'} name={'dead_rate'} defaultValue={item.dead_rate} handleInputChange={(e) => handleInputChange(e , {key : "dead_rate" , index})} />
                            </div>
                  })
                }
            </Grid.FlexResponsive>


            <div className="text-center">
                {
                    data && ArrayHelpers.range(data.pages).map((page , index) => {
                        return <button  type='button' key={index} className="btn btn-primary mx-1" onClick={() => handlePagination(index + 1 )}>{index + 1}</button>
                    })
                }
            </div>
        
        </>

      </EditForm>


    </div>
  );
};

export default ProductionDetails;
