

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    QuickView,
    SuperCreate,
    addColumn,
    InputDate,
    InputNumber,
    InputText,
    extractTableData,
    InputTextArea, SuperUpdate, SuperDelete
} from '../../../../../../config/imports';
import {projectsAPI} from "../../../../projects.helper";
import React from "react";

const ProjectsOthersExpensesInvoices = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            date: new Date(),
            inv_number : 5151151,
            name: "المرحلة الحالية",
            quantity: 212,
            unit_price :  4515,
            total_price: 159,
            extra : ""
        }, {
            inv_number : 5151151,
            date: new Date(),
            name: "المرحلة الافتتاحية",
            quantity: 105,
            unit_price :  8985,
            total_price: 15,
            extra : ""
        }
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "إضافة فاتورة مصروفات أخرى",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];

    const main_title = "قائمة فواتير شراء المصروفات الاخري";


    const table = [
        addColumn("", "م", ''),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        addColumn('name', 'الأسم', InputText, 2),
        addColumn('quantity', 'الكمية', InputNumber , 1),
        addColumn('unit_price', 'سعر الوحدة', InputNumber , 2),
        {
            gen: {name: 'total_price', title: "إجمالي المبلغ", elm: InputNumber},
            table: {col : 2},
            create: {props: { customValue: (item) => item.quantity * item.unit_price}},
        },
        addColumn('extra', 'معلومات إضافية', InputTextArea),
        addColumn("settings", "", ''),
    ];
    return (
        <>
            <PageMeta title={main_title}/>


            <QuickView ref={createRef}>
                <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"إضافة فاتورة مصروفات أخرى"}
                    table={table}
                />
            </QuickView>

            <QuickView ref={updateRef}>
                <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={main_title}
                    formHeader={"تعديل  فاتورة مصروفات أخرى"}
                    table={table}

                />
            </QuickView>

            <QuickView ref={deleteRef}>
                <SuperDelete
                    api={""}
                    setMainData={setMainData}
                    table={table}
                    // names = { ['name' , 'age' ]}
                />
            </QuickView>
            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={main_title}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};

export default ProjectsOthersExpensesInvoices;
