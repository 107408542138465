import {useEffect, useRef, useState} from "react";

export default function Span(props){
    const {size, className, children} = props
    const [delay , setDelay] = useState(1000)
    useEffect(()=>{
        if(!document.querySelector('.tooltip-content')){
            const tooltipDiv = document.createElement('pre');
            if(!tooltipDiv) return 
            tooltipDiv.classList.add('tooltip-content');
            document.body.appendChild(tooltipDiv);
        }
    })


    const resetTooltip = ()=>{
        const tooltipDiv = document.querySelector('.tooltip-content');
        if(!tooltipDiv) return
        tooltipDiv.innerHTML = ''
        tooltipDiv.style.display = 'none';
    }

    useEffect(()=>{
        window.onmousemove = (e)=>{
                const isSpan = e.target.closest(".Span") ? true : false
                if(!isSpan)  return resetTooltip()
                
        }

        window.onmouseleave = (e)=>{
            resetTooltip()
        }

        window.onmouseenter = (e)=>{
            resetTooltip()
        }


    })





        const onMouseEnter = (e) => {
            setDelay(0)
            if(e.target.closest(".no-tooltip")) return
                const tooltipDiv = document.querySelector('.tooltip-content');
                if(!tooltipDiv) return
                tooltipDiv.innerHTML = e.target.closest(".Span") ? e.target.closest(".Span").innerHTML : ''
                tooltipDiv.style.display = 'block';
                
        }
        const onMouseMove = (e) => {
            setDelay(0)
            if(e.target.closest(".no-tooltip")) return
            const tooltipDiv = document.querySelector('.tooltip-content');
            if(!tooltipDiv) return
            setDelay(1000)
                tooltipDiv.style.display = 'block';
                tooltipDiv.style.top = `${e.clientY - 50}px`; 
                tooltipDiv.style.left = `${e.clientX + 50}px`;
        }
        const onMouseLeave = (e) => {
            const tooltipDiv = document.querySelector('.tooltip-content');
            tooltipDiv.innerHTML = ''
            tooltipDiv.style.display = 'none';
            tooltipDiv.style.top = `0px`; 
            tooltipDiv.style.left = `0px`;
        }


    return <div {...props}  className={`Span span-${size} ${className ?? ''}`} 
                onMouseMove={onMouseMove} 
                onMouseEnter={onMouseEnter} 
                onMouseLeave={onMouseLeave}
            >
            {children}
    </div>
}
