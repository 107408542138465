import {useEffect, useState} from "react";
import {setInputsToDefault} from "../../../helpers/form.helper";
import {PageMeta, postData, Signboard} from "../../../config/imports";
import FormFooter from "../../../components/FormControls/FormFooter";
import putData from "../../../helpers/axios/axios.put"
import {homes_api, stock_api} from "../../../config/apis";
export default function EditHomeColor(props){
    let { homes, setHomes, toggle, item } = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [color, setColor] = useState(item.color);




    useEffect(()=>{
        const selector = '#home-' + item._id + " .data-container";
        const elm = document.querySelector(selector)
        if(!elm) return
        elm.style.background = color

        // const homeIndex = homes.findIndex(h => h._id === item._id)
        // const homeToBeChanged = homes.filter(h => h._id === item._id)[0]
        // if(homeToBeChanged){
        //     homeToBeChanged.color = color
        //     homes[homeIndex] = homeToBeChanged
        //     homes = [...homes]
        //     setHomes(homes)
        // }
    } , [color])

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        //    send put request to the home id with the new color

        const res = await postData(
            stock_api.color(item._id),
            {
                stock_id: item._id,
                color
            }
        )
        setLoading(false)
        if(res.statusCode !== 201) return setError(res.message)
        setSuccess('تم تغيير لون المشروع')

    };


    useEffect(() => {
        document.querySelector('.modal-container-content').style.width = 'fit-content';
    })


    return(
        <>
            <PageMeta title={'تغيير لون البيت ' + item.name} originTitle={'تغيير لون البيت ' + item.name}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>{'تغيير لون المستودع ' + item.name}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>
            <form
                onSubmit={handleFormSubmit}
               >

             <div className="row align-items-center">
                 <div className="col-4">
                     <label htmlFor="color">اختر اللون</label>
                 </div>
                 <div className="col-8">
                     <input type="color" id="color" name="color" value={color} onChange={e => setColor(e.target.value)} className='form-control'/>
                 </div>
             </div>
                <FormFooter toggle={toggle}/>
            </form>
        </>
    )
}
