import { paths } from "../../config/configs.env";
const warehouseDetails = {
  origin: "warehouse-details",
  navigateTo: "item-cards",
  title: "تفاصيل المستودعات",
  links: [
    {
      to: "item-cards",
      name: "كروت الصنف",
    },
    {
      to: "orders",
      name: "الطلبيات",
    },
  ],
};
const url = paths.api + "/warehouse-details";

export const wareHouseDetailsAPI = {
  url,
  feed: paths.api + "/origin/feed",
  drug: paths.api + "/drug",
  packaging: url + "/packaging",
  maintenance: url + "/maintenance",
};

export const operatorAPI = {
  url: paths.api + "/operator/selected",
};

export { warehouseDetails };
