import GadwalTable from "gadwal";

export default function Table(props) {
  return <GadwalTable 
         {...props} 
         bodyProps={{className : "hover:bg-[#F9FAFB] border-b-[1px] h-[55px]  "}}
         headerProps={{className : "bg-[#F9FAFB]  sticky top-0 "}}
         stripped={false}
  />
}
