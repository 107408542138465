
export default function Input(props){
    const {
        name , type  , title ,
        placeholder , id , handleInputChange ,
        value , defaultValue , required ,
        readOnly , disabled
    } = props

 

    return <>
            <input
                type={type}
                name={name ?? id ?? title}
                className={`form-control`}
                title={title ?? placeholder ?? id}
                id={id ?? name ?? title}
                placeholder={placeholder ?? title ?? id ?? name}
                onChange={handleInputChange}
                required={required ?? true}
                defaultValue={defaultValue}
                value={value}
                readOnly={readOnly ?? false}
                disabled={disabled ?? false}
            />
    </>

}
