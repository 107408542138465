import { useMemo } from 'react'
import PropTypes from 'prop-types'

function Map(props) {
  
  const {data  , map} = props

    const content = useMemo(() => {

        return data?.map( (item , index) => map(item , index) )
        
    } , [props.data])

  return content
}

Map.propTypes = {
  data : PropTypes.array,
  map : PropTypes.func
}

export default Map
