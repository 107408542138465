import React from 'react'
import { Link } from 'react-router-dom'
import Icons from '../../../layout/Icons'

function ApprovalReturnLink(props) {
  return (
    <div className="flex mt-[30px]">
        <Link to={"/approvals?tab=reports&"} className="flex items-center mb-2">
        <Icons.Arrows.Right  />
        <span className='mx-2 my-2 '>العوده للتقارير</span>
        </Link>
    </div>

  )
}

ApprovalReturnLink.propTypes = {}

export default ApprovalReturnLink
