import React from 'react'
import PageMeta from '../../../components/PageMeta'
import useFetch from '../../../hooks/useFetch'
import { apis } from '../../../config/apis'
import { HomesSelectItemGroup } from './SelectItemGroup'

export default function Homes() {
  const {data : homes , Alert} = useFetch(apis.homes.homes_details()+ "?place_type=home")


  return (
    <>
     <Alert />
     <PageMeta title="اختر البيت" />
     <HomesSelectItemGroup data={homes && homes} />
    </>
  )
}
