import {useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";
import Chart from 'react-apexcharts'
import chartOptions from "./charts.config";
import {useEffect, useState} from "react";

export default function BalancesCharts(props){

    const {data} = useFetch(apis.stats.balance())
    let [chartData , setChartData] = useState([0,0,0,0])

    useEffect(()=>{
        if(data){
            const chartDataObj = [
                {   ratio :data?.first_opening_balance.ratio  , fill : "#B2392E" },
                {   ratio :data?.inv_balance.ratio  , fill : "#F4A825" },
                {   ratio :data?.production_balance.ratio  , fill : "#0097F5" },
                {   ratio :data?.actual_inventory.ratio  , fill : "#26D07C" },
            ]

            chartData = chartDataObj?.map(data => data.ratio)
            setChartData([...chartData])
        }
    } , [data])


    return(
        <div >
            <header> الأرصدة والاستهلاك </header>
            <div className="d-flex my-2 align-items-center">
                <div className="col-6 d-flex align-items-center flex-wrap">
                    <span className={'chart-indicator'} style={{ backgroundColor : 'rgba(0, 143, 251, 0.85)' }}></span>
                    <span>  الرصيد الافتتاحي </span>
                </div>
                <div className="col-6 d-flex align-items-center">
                    <span className={'chart-indicator'} style={{ backgroundColor : 'rgba(0, 227, 150, 0.85)' }} ></span>
                    <span>   سحب الفواتير </span>
                </div>
            </div>
            <div className="d-flex my-2">
                    <div className="col-6 d-flex align-items-center">
                        <span className={'chart-indicator'} style={{backgroundColor : 'rgba(254, 176, 25, 0.85)'}} ></span>
                        <span>   رصيد الإنتاج</span>
                    </div>
                    <div className="col-6 d-flex align-items-center">
                        <span className={'chart-indicator'} style={{ backgroundColor : 'rgba(255, 69, 96, 0.85)' }} ></span>
                        <span>   المخزون الفعلي</span>
                    </div>
            </div>
            <hr/>
            <div className="center-container">
                
{/* 
                    <Chart
                        options={chartOptions.balance}
                        series={chartData}
                        type="radialBar"
                        width="400"
                    /> */}
                

            </div>

        </div>
    )
}
