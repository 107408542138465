import { useRef } from "react";
import { useParams } from "react-router-dom";
import { _date, PageMeta, QuickView, Signboard, TableHeader, useFetch } from "../../../../config/imports";
import ReportsCrud from "../reports_crud";
import { reports_api } from "../../../../config/apis";
import Helpers from "../../../../helpers/helpers";
import ReportAccordion from "../components/ReportAccordion";
import { categories } from "../reports.shared";
import ReportMovementDetails from "../components/ReportMovementDetails";
import { TableHeader as ReportHeader } from "../../../../layout/table";
export default function FeedDailyReportForProductionHome(props){
    const  createRef = useRef(),
        updateRef = useRef();

    const {id} = useParams();

    const api = reports_api.home.feed.reports(id)

    const  { data  , setData ,  alert , paginate , setters  ,setRefresh}  = useFetch(api);

    const table = [
        {size : 3 , name : "date" , text : "التاريخ"  , custom : d => _date(d.date).dmy() },
        {size : 3 , name : "last_updated_by" , text : "  اخر تعديل بواسطة "   },
        {size : 2 , name : "total_quantity" , text : "الكمية" , custom : d => Helpers.Number.commaFormat( d?.total_quantity ) },
        {size : 3 , name : "ar_unit" , text : "وحدة القياس"  },
        {size : 4 , name : "reference_feed_consumption" , text : "استهلاك السلاله (جم)" , custom : d => Helpers.Number.commaFormat( d?.reference_feed_consumption ) },
        {size : 3 , name : "total_price" , text : "قمية الاستهلاك" , custom : d => Helpers.Number.commaFormat( d?.total_price ) },
        // {size : 3 , name : "notes" , text : " الانتاج"  },
        {size : 6 , name : "notes" , text : " ملاحظات"  },
    ]

    const buttons =  [
        {
            title: " اضافة تقرير علف جديد"      ,
            onclick: () => createRef.current.toggle()
        }
    ]





    return(
        <>
            <PageMeta title={'تقارير العلف اليوميه'} />
            <Signboard {...alert}/>
            <QuickView ref={createRef}><ReportsCrud.Feed.Create setRefresh={setRefresh}   setMainData={setData}/></QuickView>
            <TableHeader  buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />

            <ReportHeader table={table}  />
            <ReportAccordion 
            MovementDetails={ReportMovementDetails }
            data={data} table={table}   categories={categories} />
            
        </>
    )
}
