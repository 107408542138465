import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Span from '../../layout/grid/Span'
import Map from '../Map'
import { displayWeightNamesMap, eggsTableMap, totalWeightsMap } from './eggs_warehouse.helpers'
import ScrollX from '../../layout/ScrollX'

function EggsWarehouseTable(props) {


  
  const {data , rest} = props
   

  const weightNames = useMemo(() => {
      return data?.cartons[0]?.weights.map(w => w.name)
  } , [data])


  return (
    <ScrollX>
      <h3 className='text-[#fcaa30] mb-3'> تفاصيل الكراتين </h3>
      <div className="flex   ">
          <Span size={5}  className="bg-[#fcaa30] text-white"   >اسم الكارتون / الاوزان</Span>
          <Map data={weightNames} map={displayWeightNamesMap} />
      </div>
      <div className="flex flex-col">
          <Map data={data?.cartons} map={eggsTableMap} />
      </div>
      <div className="flex bg-[#41e481]/90 text-white ">
          <Span size={5}  className="text-white"> الاجمالي  </Span>
          <Map data={weightNames} map={totalWeightsMap(rest)} />
      </div>
    </ScrollX>

)
}

EggsWarehouseTable.propTypes = {
  data : PropTypes.object.isRequired ,
  rest : PropTypes.object.isRequired
}

export default EggsWarehouseTable
