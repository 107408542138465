import React from "react";
import { PageMeta,QuickView,Signboard,TableHeader,useFetch,useRef,} from "../../../config/imports";
import { apis } from "../../../config/apis";
import Helpers from "../../../helpers/helpers";
import { Table } from "../../../layout/table";
import ClientsCrud from "./crud";

const IncubatorsClientsIndex = () => {
  const createRef = useRef();
  const updateRef = useRef();
  const deleteRef = useRef();
  const api = apis.incubator.client.paginate();
  const { data, alert, paginate, setters } = useFetch(api);

  const buttons = [
    {
      title: "إضافة عملاء صوص",
      onclick: () => {
        createRef.current.toggle();
      },
    },
  ];

  const table = [
    { size: 0, name: "#", text: "#" },
    { size: 2, name: "name", text: "الاسم" },
    { size: 2, name: "delegate", text: "اسم المندوب" },
    { size: 2, name: "phone", text: "رقم الهاتف" },
    { size: 2, name: "mobile", text: "رقم الجوال" },
    { size: 2, name: "breeding_eggs_houses", text: "عدد بيوت التربية" },
    { size: 2, name: "breeding_birds_houses", text: "عدد طيور التربية" },
    { size: 2, name: "production_eggs_houses", text: "عدد بيوت الأنتاج" },
    { size: 2, name: "production_birds_houses", text: "عدد طيور الأنتاج" },
    { size: 2, name: "order_quantity", text: " كمية الطلبيه" },
    { size: 2, name: "previous_order_quantity", text: "كمية الطلبيه السابقه" },
    { size: 2, name: "indebtedness", text: "المديونيه" },
    { size: 1, name: "", text: "" },
  ];


  return (
    <div className="bg-white base-card r-16 p-3 incubator mb-5">
      <PageMeta title={"عملاء الصوص"} />
      <Signboard {...alert} />
      <QuickView ref={createRef}><ClientsCrud.Create setMainData={setters.data} /></QuickView>
      <QuickView ref={updateRef}><ClientsCrud.Update setMainData={setters.data} /></QuickView>
      <QuickView ref={deleteRef}><ClientsCrud.Delete setMainData={setters.data} /></QuickView>
      <TableHeader buttons={buttons} api={api} setData={setters.data} setLoading={setters.loading} paginate={paginate}/>
      <Table table={table} data={data} updateRef={updateRef}deleteRef={deleteRef} />
    </div>
  );
};

export default IncubatorsClientsIndex;
