import { create } from "zustand";
import { accio } from "../../../helpers/axios/axios.get";
import { apis } from "../../../config/apis";
import { showErrorToast } from "../../../stores/toast.store";



const useIndexStore = create((set) => ({
    loading : false,
    data : [],
    error : null,
    setLoading(loading){
        set(state => ({...state , loading : loading ?? !state.loading}))
    },
    setData(data){
        set(state => ({...state , data , loading : false}))
    },
    setError(error){
        set(state => ({...state , error , loading : false}))
    },
    async fetch(){
        set(state => ({...state , loading : true}))
        const response = await accio(apis.sideNav())
        
        set(state => ({...state , loading : false}))
        if(response.error) return showErrorToast(response.error)
        set(state => ({...state , data : response.data  , loading : false}))
    }
}));





export default useIndexStore