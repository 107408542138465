import React, { useMemo } from 'react';
import Span from '../../../layout/grid/Span';
import Map from '../../../components/Map';
import { displayWeightNamesMap, eggsTableMap, totalWeightsMap } from '../eggs_warehouse.helpers';
import ScrollX from '../../../layout/ScrollX';

/**
 *  // data { date : Date , cartons : [ {
 *          carton_total_egg_count : number ,
 *           name : string ,
 *          symbol : string ,
 *          weights : [ { _id : string , name : string , amount : number } ]
 *   } ] }
 * 
 * 
 */

export default function EggsTableContent(props) {
    const {data , rest} = props
   

    const weightNames = useMemo(() => {
        return data?.cartons[0]?.weights.map(w => w.name)
    } , [data])


    return (
        <ScrollX>

            <h3 className='text-[#fcaa30] mb-3'> تفاصيل الكراتين </h3>

            <div className="flex bg-[#fcaa30] text-white ">
                <Span size={5}  className="text-white"   >اسم الكارتون / الاوزان</Span>
                <Map data={weightNames} map={displayWeightNamesMap} />
            </div>

            <div className="flex flex-col">
                <Map data={data?.cartons} map={eggsTableMap} />
            </div>

            <div className="flex bg-[#41e481]/90 text-white ">
                <Span size={5}  className="text-white"> الاجمالي  </Span>
                <Map data={weightNames} map={totalWeightsMap(rest)} />
            </div>

        </ScrollX>
    )
}
