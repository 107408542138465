import {InputNumber, InputText, useFetch} from "../../../config/imports";
import {projects_api} from "../../../config/apis";
import {useEffect, useMemo, useState} from "react";
import Helpers from "../../../helpers/helpers";
import CheckEqualInput from "./CheckEqualInput";
import { convertUnit } from "../../../helpers/converters/units.converter";

export default function SharedHomesInputs(props){

    const {project , department, data , setData , setError , update , storages} = props

    let [homes , setHomes] = useState({})
    const [updateState, setUpdateState] = useState(false);



    useEffect(()=>{
        
        if(update){
            setUpdateState(true)
            if(data?.home.length > 0)
                setHomes(data.home)
        }
        else
        {
            // setHomes([])
            setUpdateState(false)
        }


    } , [ data.home , updateState ])






    const {data : projects , error : projectsError , isLoading : projectsLoading} = useFetch(projects_api.names)

    const homesInputs = useMemo(() => {

        if(updateState && homes){
            return homes.map(home => {
                return { home_id : home?.home_id , name : home?.name , quantity : home?.quantity }
            } )
            
        }



        if (!projects) return []; // renders nothing if data is not available
        //   we need to filter home and departments to get the homes for the form
        const targetProject = projects.filter( p => p._id === project)[0]
        const targetHomesOfDepartment = targetProject?.departments?.filter( d => d._id === department)[0]?.homes
        if (!targetHomesOfDepartment) return [];
        // create state for homes
        homes = targetHomesOfDepartment?.map( h => ({
            home_id : h._id , name : h.name , quantity  : ''
        }))
        setHomes(homes)


        return   targetHomesOfDepartment.map( h => ({
            _id : h._id , name : h.name , quantity  : ''
        }))

    } , [ projects ])

    useEffect(()=>{
        setData({...data , home : homes})
    } , [ homesInputs ])



    useEffect(()=>{
         if(updateState)
            setData({...data , home : homesInputs})
        else return

    } , [homesInputs , updateState])



    const handleInputChange = (e , index) => {
        setError(null)
        const { value, type} = e.target;
        // console.log( homes[index])

        if(!data.isChecked){
            homes[index].quantity = type === "number" ? +value : value
            setHomes(homes)
            const summedAmounts = homes.reduce((sum , home) => sum + +home.quantity ?? 0 , 0)
            data.total_quantity_from_homes = Helpers.validateNumber(summedAmounts)

            // if(data.total_quantity_from_homes !== data.quantity){
            //     console.log('not equal')
            //    setError('الاجمالي من البيوت لا يساوي الكميه')
            // }
            // set state of the main form
            setData({...data , home : homes})
        }


    };


    return(
        <div className={'w-100 f-flex flex-wrap shared-homes'}>

            {
                homesInputs.length === 0 ? (
                    <>
                        <div className="alert alert-danger text-center mt-3 ">
                            لا يوجد بيوت داخل هذا القسم
                        </div>
                    </>
                ) : (
                    <>
                        <div className="section-title my-3"><span> البيوت</span></div>
                        {/* <CheckEqualInput data={data} setData={setData} /> */}
                        {
                            homesInputs &&   homesInputs.map((home , index) => {
                                // const storage = 
                                const _home =  props?.storages?.filter( h => h.location_id === home._id )[0]
                                const _storage = (_home?.maximum_capacity) - (_home?.quantity)
                                const storage = convertUnit(_storage, _home?.main_unit  , data?.unit ?? "ton" )?.toFixed(2)

                                return(
                                    <div className={'row p-0 '} key={index}>
                                        <InputText   className={'col-6 '}  disabled={true} required={false} placeholder={'0'} defaultValue={homes[index]?.name} />
                                        <div className="col-6">
                                            <InputNumber className={'w-100'} required={false}  placeholder={'0'} defaultValue={homes[index]?.quantity} handleInputChange={(e)=>handleInputChange(e , index)} />
                                            <small className="mt--1"> الكميه المتاحه للتخزين : { Helpers.Number.commaFormat(storage)  } </small>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </>
                )
            }
        </div>
    )
}
