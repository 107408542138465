import React, {useState} from "react";
import {_date, deleteFormHandler, Grid, Signboard, Table} from "../../../../config/imports";
import PageMeta from "../../../../components/PageMeta";
import {weight_api} from "../../../../config/apis";

export default function DeleteWeight(props){
    const {toggle , item , setMainData} = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const sizes = {
        name : 4,
        weight : 4,
        createdAt : 5,
        updatedAt : 5,
    }


    const header = {
        name:<Grid.Span size={sizes.name}>  الرمز  </Grid.Span>,
        weight: <Grid.Span size={sizes.weight }>  الوزن </Grid.Span>,
        createdAt: <Grid.Span size={sizes.createdAt}>  تاريخ الاضافة </Grid.Span>,
        updatedAt:<Grid.Span size={sizes.updatedAt}>  تاريخ  اخر تعديل   </Grid.Span>,
    }


    const handleDelete = async () => {
        const delete_props = {
            url: weight_api.base,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        console.log(delete_props)
        await deleteFormHandler(delete_props);
    };

    return(
        <>
            <PageMeta title={'  حذف الوزن ' + item.name} originTitle={' الاوزان'} />

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>حذف الوزن  {item.name} ؟</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>


            <Table.Header header={header}  className={'delete-header'}  />


            <Grid.FlexResponsive   className={'align-items-center data-row'}>

                <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
                <Grid.Span size={sizes.weight}>{ item.weight }</Grid.Span>
                <Grid.Span size={sizes.createdAt}>{_date(item.createdAt).simple('date')}</Grid.Span>
                <Grid.Span size={sizes.updatedAt}>{_date(item.updatedAt).simple('date')}</Grid.Span>

            </Grid.FlexResponsive>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
