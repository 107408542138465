import { errorHandler } from "../helpers/error.helper";
import { ERROR_MESSAGE_TIMEOUT, SUCCESS_MESSAGE_TIMEOUT } from "../hooks/useAlert";
import Loading from "./Loading";
import {useEffect, useState} from "react";

const Signboard = ({ loading, error, success }) => {


  const [errorState, setErrorState] = useState(false);
  const [successState, setSuccessState] = useState(false);


  useEffect(() => {
    if(error && error?.length !== 0 ) {
      setTimeout(() => {
         setErrorState(true);
         setTimeout(()=>{
           setErrorState(false);
         } , ERROR_MESSAGE_TIMEOUT )
      }, );

    }

    if(success) {
      setTimeout(() => {
        setSuccessState(true);
        setTimeout(()=>{
          setSuccessState(false);
          document.querySelector(".close-btn")?.click();
         } , SUCCESS_MESSAGE_TIMEOUT )
      },);
    }

  } , [error, success])

  return (
    <>
      {loading && <Loading />}
      {errorState && error?.length !== 0  && (
        <p className="error-alert alert alert-danger  mt-4 form-err-container">
          {errorHandler(error)}
        </p>
      )}
      {successState && (
        <p className="success-alert alert alert-success mt-4  form-success-container">
          {success}
        </p>
      )}
    </>
  );
};

export default Signboard;
