import React, { useRef } from 'react'
import { _date } from '../../../../helpers/date.helpers'
import { Table } from '../../../../layout/table'
import { useParams } from 'react-router-dom'
import QuickView from '../../../../components/QuickView'
import ReportMovementDetails from './ReportMovementDetails'
import Helpers from '../../../../helpers/helpers'
export default function ReportAccordionBody(props) {
  const {data , getUnitName , categories , MovementDetails}  = props
  
    const {id} = useParams();
    const updateRef = useRef()
    const deleteRef = useRef()

    const detailsRef = useRef()
    




    // data-table-head

    const table = [
        { name : "#"  , text : "" , size : 1  },
        { name : "ar_type" , text : "نوع الحركه" , size : 2 },
        { name : "from" , text : "من" , size : 3 },
        { name : "to" , text : "الي" , size : 3 },
        // { name : "category" , text : "الصنف" , size : 2 , custom : d => categories[d?.category] },
        { name : "sub_category" , text : "الفئة" , size : 2 },
        { name : "quantity" , text : "الكمية" , size : 2 },
        { name : "ar_unit" , text : "وحدة القياس" , size : 3   },
        { name : "date" , text : "التاريخ" , size : 3 , custom :d =>  <span style={{ fontSize : "13px"}}> { _date(d?.date).dmy()} </span>  },
        {size : 3 , name : "total_price" , text : "قمية الاستهلاك" , custom : d => Helpers.Number.commaFormat( d?.total_price ) },
        { name : "movements" , text : "تفاصيل" , size : 2  , custom : d => {
          return <button onClick={() => detailsRef.current?.toggle(d)}><i className="fa fa-eye " /></button>
        }},
     ]



  return (
      <div >

             <QuickView ref={detailsRef}  className="overflown-popup"><MovementDetails  categories={categories} /></QuickView>

              {data && <Table  data={data} table={table} />}
      </div>
  )
}
