import React from 'react'
import DIsplayFileImage from './DisplayFileImage'
import { cn } from '../../helpers/class_name.helpers'
import { key } from '../../helpers/id.helper'

export default function DisplayFileImagesGroup(props) {

    // required props files , setState , keyNAme ?? props.keyName 

    const handleDelete = (file) =>{
        
        const _imgs = props.files.filter((img)=> img !== file )
        
        props.setData(pre =>{ return { ...pre, [props.keyNAme ?? props.keyName] : _imgs } })
        
    }




    if(props?.files?.length === 0) return null
  return (
    <div className={cn( 'file-img-group', props.className )}>
        { props?.files?.map((file , index) =>{
            return  <DIsplayFileImage key={((file?.lastModified) + (file?.size)).toString() } file={file} handleDelete={handleDelete}/>
        })}
    </div>
  )
}
