import React, {useState} from "react";
import {deleteFormHandler} from "../../../../helpers/form.helper";
import {_date, Grid, Signboard, Table, TableData} from "../../../../config/imports";
import {homes_api} from "../../../../config/apis";

export default function DeleteHome(props){
    const {toggle , item , setMainData ,i } = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);


    const handleDelete = async () => {
        const delete_props = {
            url: homes_api.delete,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };
        await deleteFormHandler(delete_props);
    };

    const sizes = {
        name :5,
        createdAt : 5,

    }
    const header = {
        name:<Grid.Span size={sizes.name}>  الاسم  </Grid.Span>,
        createdAt: <Grid.Span size={sizes.createdAt }> تاريخ الانشاء  </Grid.Span>,
    }





    return(
        <div className="form-delete">

        <header className="w-fit m-auto px-4 text-center border-bottm-line ">
            <h3>هل انت متاكد من حذف البيت {item.name} ؟</h3>
        </header>
        <Signboard error={error} success={success} loading={loading}/>
            <Table.Header header={header}  className={'delete-header'}  />
            <Grid.FlexResponsive   className={'align-items-center data-row'}>
                <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
                <Grid.Span size={sizes.createdAt}>{ _date(item.createdAt).simple() }</Grid.Span>
            </Grid.FlexResponsive>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>


        </div> )
}
