import {Link, useParams} from "react-router-dom";
import {_date, Grid, Icons, PageMeta, QuickView, Signboard, useFetch} from "../../config/imports";
import {apis, request_api} from "../../config/apis";
import "../../styles/orders.styles.scss"
import {Table} from "../../layout/table";
import {useEffect, useRef, useState} from "react";
import AcceptOrder from "./AcceptOrder";
import {paths} from "../../config/configs.env";
import OrderFiles from "../Invoices/OrderFiles";
import {accio} from "../../helpers/axios/axios.get";



export default function RequestDetails(props){
        const {id} = useParams();

        const acceptRef = useRef(),
         filesRef = useRef()

        const [isDataAdded , setIsDataAdded] = useState(false)

        const {data , isLoading, error : requestError , setData : setDetails} = useFetch(apis.orders.details(id))

        const refreshDetails = async () => {
            const {data : newDetails} = await accio(apis.orders.details(id));
            setDetails(newDetails)
            setIsDataAdded(false)
        }



        useEffect( ()=>{
                if(isDataAdded)
                        refreshDetails()
        } , [isDataAdded])

        const signboard = {error : requestError, loading : isLoading}

        const userTypeEnum = {
                worker : 'عامل بيت' ,
                warehouse_man : 'أمين مستودع' ,
                project_supervisor : 'مشرف مشروع' ,
                project_manager : 'مدير مشروع' ,
                doctor : 'الطبيب المشرف' ,
                farm_manager : 'مدير مزرعه' ,
                projects_manager : 'مدير مشاريع' ,
                delegate : 'مندوب المشريات' ,
        }
        const orderStatusEnum = {
                pending : 'قيد المراجعه' ,
                approved : 'مكتمل' ,
                completed : 'مكتمل' ,
                rejected : 'مرفوض' ,
                under_purchase : 'قيد الشراء'
        }
        const statusLabelEnum = {
                 completed:<span className="status-done">مكتمل</span>,
                 approved:<span className="status-done">مكتمل</span>,
                 pending:<span className="status-pending">قيد المراجعه</span>,
                 rejected:<span className="status-rejected">مرفوض</span>,
                 under_purchase:<span className="status-pending">قيد الشراء</span>,
                 current:  <span className="status-waiting">  بإنتظار الاجراء </span> ,

}



        const sizes = {
                serial : 1,
                name : 4 ,
                project : 3 ,
                department : 3 ,
                request_sender : 5,
                date : 3 ,
                status : 3 ,
                settings : 2,
                //
                stage : 4,
                action_performer : 4,
                action : 3,
                date_time : 4,
                info : 3,
                files : 6
        }







        const table = [
                // { name : '#' , size : 1  , text : '#'},
                { name : 'name' , size : 3  , text : 'النوع'    },
                { name : 'project' , size : 5  , text : 'المشروع' ,  custom : d => d?.project?.name ?? d?.project},
                { name : 'department' , size : 4  , text : 'القسم' , custom : d => d?.department?.name ?? d?.department},
                { name : 'created_by' , size : 4  , text : 'اسم مرسل الطلب' , custom : d => d?.created_by?.username ?? d?.created_by},
                { name : 'status' , size : 4  , text : 'الحالة' , custom : d =>{
                                switch(d.status){
                                        case 'completed': return <span className="status-done">مكتمل</span> ;
                                        case 'approved': return <span className="status-done">مكتمل</span> ;
                                        case 'pending': return   <span className="status-pending">قيد المراجعه</span> ;
                                        case 'rejected': return  <span className="status-rejected">مرفوض</span> ;
                                        case 'under_purchase': return <span className="status-pending">قيد الشراء</span> ;
                                        case 'current': return <span className="status-waiting">  بإنتظار الاجراء </span> ;
                                        default: return <span className="text-secondary">{d?.ar_status}</span> //[todo]
                                }
                }},
                { name : 'createdAt' , size : 3  , text : 'تاريخ الطلب' , custom : d => _date(d.createdAt).dmy()},
                { name : '' , size : 1  , text : ''   },
        ]







        return<div className='request'>
                <PageMeta title={'تفاصيل الطلب'}/>
                <Signboard {...signboard}/>
                <QuickView ref={acceptRef} ><AcceptOrder updateMain={setIsDataAdded} /></QuickView>
                <QuickView ref={filesRef} ><OrderFiles userTypeEnum={userTypeEnum} /></QuickView>

                <div className="text-center w-fit m-auto mt-3 mb-0 bg-white r-16 p-3 px-5">
                        <h4>تفاصيل الطلب</h4>
                </div>

                <div className="bg-white my-3 mt-5 r-16">
                        { data && <Table table={table} data={[data]}/>}
                </div>

                <div className="bg-white r-16 p-3">
                     <div className="d-flex align-items-center justify-content-between">
                        <h6 className='header'> مراحل اعتماد الطلب </h6>
                        <button className="add-btn" onClick={()=> acceptRef?.current?.toggle()} >  اعتماد  / رفض الطلب </button>
                     </div>

                        <Grid.Flex className={'mt-5 request-head round-with-shadow'}>
                                {/*<Grid.Span size={sizes.serial}></Grid.Span>*/}
                                <Grid.Span size={sizes.stage}>المرحلة </Grid.Span>
                                <Grid.Span size={sizes.action_performer}> مؤدي الاجراء </Grid.Span>
                                <Grid.Span size={sizes.action}>الاجراء </Grid.Span>
                                <Grid.Span size={sizes.date_time }>التاريخ والوقت</Grid.Span>
                                <Grid.Span size={sizes.info}>التعليقات </Grid.Span>
                                <Grid.Span size={sizes.files}> المرفقات</Grid.Span>
                        </Grid.Flex>

                        <Grid.Flex className="stages-container">

                                        {
                                            data && data.order_levels.map((level , index) => {
                                                    return  <Grid.Flex  key={index} className={'request-row round-with-border align-items-center'}>
                                                                    { orderStatusEnum[level.status] === 'قيد المراجعه' ? (
                                                                        <div className='status-container'>
                                                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <rect x="0.5" y="0.695312" width="20" height="20" rx="10" fill="#3786FB"/>
                                                                                        <path d="M5.5 11.8077L8 14.0299L15.5 7.36328" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                </svg>
                                                                        </div>
                                                                    ) : (
                                                                        <div className='status-container'>
                                                                                <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                        <rect x="0.5" y="0.695312" width="20" height="20" rx="10" fill="#0CC857"/>
                                                                                        <path d="M5.5 11.8077L8 14.0299L15.5 7.36328" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                                                </svg>
                                                                        </div>
                                                                    )}
                                                            {/*<Grid.Span size={sizes.serial}></Grid.Span>*/}
                                                            <Grid.Span size={sizes.stage}>  اعتماد   {userTypeEnum[level.user_type]}  </Grid.Span>
                                                            <Grid.Span size={ sizes.action_performer } className="flex"> 
                                                                 <Icons.RequestUser  /> 
                                                                 <span className="mx-2"> {userTypeEnum[level.user_type]}</span>
                                                                  </Grid.Span>
                                                            <Grid.Span size={sizes.action}>{ statusLabelEnum[level.status] }
                                                            </Grid.Span>
                                                            <Grid.Span size={sizes.date_time }>
                                                        {
                                                           level.date ?
                                                                        <>
                                                                            {_date(level.date).dmy()} -  ({_date(level.date).time()})
                                                                        </>
                                                                 :
                                                                        <>
                                                                            {_date(data.createdAt).dmy()} - ({_date(data.createdAt).time()})
                                                                        </>
                                                        }
                                                            </Grid.Span>
                                                            <Grid.Span size={sizes.info}> {level.info}</Grid.Span>
                                                            <Grid.Span size={sizes.files} className={'settings-buttons'}>
                                                                    <div className="d-flex flex-wrap">
                                                                            { level?.files?.length > 0 &&  <Icons.File  className={'cursor-pointer'} onClick={() => filesRef?.current?.toggle({files: level.files , type : level.user_type } ) } /> }
                                                                            {/*{*/}
                                                                            {/*    level?.files?.map((file , index) => {*/}
                                                                            {/*        return <div className='download-file-container my-1' key={index} >*/}
                                                                            {/*                <a href={paths.api + '/' + file}   download  ><Icons.Download className={'download-file'} /></a>*/}
                                                                            {/*        </div>*/}
                                                                            {/*    })*/}
                                                                            {/*}*/}
                                                                    </div>
                                                            </Grid.Span>


                                                    </Grid.Flex>
                                            })
                                        }


                        </Grid.Flex>

                </div>







        </div>


}
