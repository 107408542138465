import {useRef} from "react";
import {_date, QuickView, Signboard, TableHeader, useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";
import OrdersCrud from "./home_orders_crud";
import PageMeta from "../../../components/PageMeta";
import {Table} from "../../../layout/table";
import {useParams} from "react-router-dom";
import { OrderStatusTypes } from "./common/order_status_types.common";
import useMassUnit from "../../../hooks/types/useMassUnit";

export default function MaintenanceOrders(props){

    const {id} = useParams()
    const createRef = useRef()
    const updateRef = useRef()
    const deleteRef = useRef()
    const api = apis.orders.maintenance.paginateHome(id)
    const {data , error , isLoading , setData : setMainData , paginate , setters} = useFetch(api)
    // const  {MassTypesEnum} = useMassUnit()

    // const { data  : operators  , isLoading : isOperatorLoading , error  : operatorError} = useFetch(operators_api.names)

    const {data : maintenances , isLoading : maintenanceLoading , error : maintenanceError} = useFetch(apis.origins.maintenance.base())

    // console.log({maintenances})

    const signboard = {
        error :   error || maintenanceError ,
        loading : isLoading  || maintenanceLoading,
    }
    const buttons =[ { title : 'إضافة طلب جديد' , onclick : ()=>  createRef?.current?.toggle()  }]

    const table = [
        { name : '#' , size : 1  , text : '#'},
        { name : 'name' , size : 3  , text : 'الاسم'  , custom : ()=> 'طلب صيانه'},
        { name : 'status' , size : 3  , text : 'الحالة' , custom : d =>OrderStatusTypes[d.status] ?? d?.ar_status},
        // { name : 'unit' , size : 3 , text : 'وحدة القياس' , custom : (d)=>MassTypesEnum[d.unit ?? d.measuringUnit ?? d.measuring_unit]},
        { name : 'quantity' , size : 3 , text : 'الكمية'},
        { name : 'createdAt' , size : 4  , text : 'تاريخ الطلب' , custom : d => _date(d.createdAt).dmy()},
        { name : 'info' , size : 5  , text : 'معلومات إضافيه'},
        { name : '' , size : 1  , text : ''}
    ]
    return(
         <>
             <QuickView ref={createRef} ><OrdersCrud.Maintenance.Create  setMainData={setMainData}  types={maintenances} /></QuickView>
             <QuickView ref={updateRef} ><OrdersCrud.Maintenance.Update  setMainData={setMainData}  types={maintenances} /></QuickView>
             <QuickView ref={deleteRef} ><OrdersCrud.Maintenance.Delete  setMainData={setMainData}  types={maintenances} /></QuickView>
             <PageMeta title={'طلبات الصيانه'} />
             <Signboard {...signboard} />
             <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
             <Table table={table} data={data} className={'mt-5'} updateRef={updateRef} deleteRef={deleteRef} />
         </>
    )
}
