import {Signboard, useFetch} from "../../../config/imports";
import {apis, departments_api} from "../../../config/apis";
import {useParams} from "react-router-dom";
import {Table} from "../../../layout/table";

export default function ProductionOfDepartmentByYears(props){

    const {id} = useParams()
    const {data , error , isLoading} = useFetch(departments_api.stats.years(id))

    const signboard = { error , loading : isLoading}

    const table = [
        // { name : '#' , size : 1  , text : '#'},
        { name : 'year' , size : 2  , text : 'العام'},
        { name : 'all_feed_reports_amount' , size : 3  , text : 'استهلاك العلف ' },
        { name : 'all_feed_invs_amount' , size : 4  , text : ' كمية فواتير الاعلاف'},
        { name : 'feed_inv_count' , size : 4  , text : 'قيمة فواتير الأعلاف'},
        { name : 'all_dead_birds' , size : 2  , text : 'النافق'},
        { name : 'totaled_birds' , size : 2 , text : 'التراكمي'},
        { name : 'egg_balance' , size : 2  , text : 'البيض المنتج' , custom : item => item?.egg_balance?.value ?? 0 },
        { name : 'production_balance' , size : 2  , text : 'رصيد الانتاج' , custom : item => item?.production_balance.value ?? 0 },
        { name : 'productivity' , size : 2 , text : 'نسبة الانتاج' , custom : item => item?.productivity ? item?.productivity.toFixed(2).toString() + '%' :  '0%' },
        // { name : '' , size : 1  , text : ''}
    ]
    return(
        <>
            <div className={'bg-white'}>
                <Signboard {...signboard} />
                <Table table={table} data={data} />

            </div>

        </>
    )
}
