import {
    destructureTable,
    extractCreateData,
} from "../config/imports";

const createInputGenerator = (table_data , data) => {

    const {body} = destructureTable(table_data)



    const create_data = extractCreateData(body , data)

    return  create_data.map((item , index) => {
        const {elm :Element , props} = item
        if(typeof Element === 'function'){
            return(
                <Element {...props}   key={index}/>
            )
        }else {
            return Element
        }
        // console.log(props)

    })


}

export {
    createInputGenerator
}

//we can create super component from the generators

// create component view
