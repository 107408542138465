


import PageMeta from "../../../components/PageMeta";

import RouterLinks from "../../../components/LinkList";
import useNavigator from "../../../hooks/useNavigator";

const DrugsAndImmunity = () => {
    const Links = [
        {
            name: "قائمة شراء ادوية و تحصينات",
            path: "buy",
        },
        {
            name: "اصناف ادوية وتحصينات",
            path: "class",
        },
        {
            name: "قائمة تقارير خروج الاصناف",
            path: "export",
        },
    ];

    useNavigator("drugs", "buy");

    return (
        <>
            <PageMeta title={"الصيانة"} />

            {<RouterLinks underline={true} data={Links} />}
        </>
    );
};

export default DrugsAndImmunity;
