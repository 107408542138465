import {createFormHandler, EditForm, InputText, Signboard , PageMeta, InputSelect, InputNumber, updateFormHandler, Icons} from "../../../../../config/imports";
import {apis} from "../../../../../config/apis";
import {useState} from "react";
import DisplayUploadedImage from '../../../../../components/images/DisplayUploadedImage';
import DisplayUploadedImagesGroup from '../../../../../components/images/DisplayUploadedImagesGroup';
import ArrayHelpers from "../../../../../helpers/array.helpers";
import origin_cards_tables from "../../assests_forms";
import Browser from "../../../../../layout/browser/Browser";
export default function Update(props){

    const {setMainData, toggle , item , asset} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({...item})

    let signboard = { error  ,success,loading  }

    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };



    const handleRoom = (e ,  index)=>{
        const { name , value , type } = e.target
        const _room = data.rooms[index]
        _room[name] = type === 'number' ? +value : value
        _room.area = _room.length * _room.width
        setData({
            ...data,
            rooms : [...data.rooms]
        })
    }

    const addRoom = ()=>{
        setData({
            ...data,
            rooms : [...data.rooms ,  {
                id : `id-${Date.now()}`,
                name : '',
                ac_count : "",
                doors_count : "",
                length : "",
                width : "",
                area : ""
            }]
        })
    }

    const deleteRoom = (index)=>{
        // remove element from array with its index
        //  and return the new array without removed element

        const _rooms = data.rooms.splice(index, 1);
        setData({
            ...data,
            rooms : [..._rooms]
        })
        
    }

 


    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.assets.asset(asset).update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setData,
            FORM_HEADER: `تعديل`,
        };

        // console.log(update_props.data)
        await updateFormHandler(update_props);
    };

    const browserData = [
        {
            tab  : { title : "بيانات المبنى" , size : 12},
            content :<>
                 { ArrayHelpers.simpleInputsGenerator(origin_cards_tables[asset] , data , handleInputChange ) }
            </>
        },
        {
            tab  : { title : " بيانات الغرف" , size : 12},
            content :<>
                <div className="row g-1">
                    <InputNumber  className={'col-12'} name={"rooms_count"}  defaultValue={data.rooms_count} placeholder={'2'}  title={"عدد الغرف"} handleInputChange={handleInputChange} />
                </div>
                {data.rooms  && data.rooms.map(({ id , name , ac_count , doors_count , length , width , area } , index) =>{
                    return   <div className="builing-room row g-1" key={index}>
                    <InputText    className={'span-9'} name={"name"}  defaultValue={data.rooms[index].name} placeholder={'الغرفة'}  title={"أسم الغرفة"} handleInputChange={(e)=>handleRoom(e , index)} />
                    <InputNumber  className={'span-3'} name={"ac_count"}  defaultValue={data.rooms[index].ac_count} placeholder={'3'}  title={"عدد التكييفات"} handleInputChange={(e)=>handleRoom(e , index)} />
                    <InputNumber  className={'span-3'} name={"doors_count"}  defaultValue={data.rooms[index].doors_count} placeholder={'2'}  title={" عدد الابواب "} handleInputChange={(e)=>handleRoom(e , index)} />
                    <InputNumber  className={'span-3'} name={"length"}  defaultValue={data.rooms[index].length} placeholder={'2'}  title={"طول الغرفة"} handleInputChange={(e)=>handleRoom(e , index)} />
                    <InputNumber  className={'span-3'} name={"width"}  defaultValue={data.rooms[index].width} placeholder={'3'}  title={"عرض الغرفة"} handleInputChange={(e)=>handleRoom(e , index)} />
                    <InputNumber  className={'span-3'}  name={"area"}  defaultValue={data.rooms[index].area} disabled={true} placeholder={'6'}  title={"مساحة الغرفة"} handleInputChange={(e)=>handleRoom(e , index)} />
                    <button type="button" onClick={ () => deleteRoom(index)} className="custom-btn  w-fit m-auto bg-transparent"><Icons.Delete /></button>
                </div>

                }
                ) }
                <div type="button" className="custom-btn bg-success w-fit m-auto" onClick={addRoom}> إضافة غرفة </div>
            </>
        },
       
    ]


    return(
        <>
    
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3> تعديل بيانات المبنى</h3>
        </header>

        <Signboard {...signboard}/>

        <EditForm visible={false} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange} >
             <Browser data={browserData} toggle={toggle} />
        </EditForm>



        </>
    )
}
