// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home-reports .reports-header {
  font-size: 14px;
}

.department-reports .not-editable input {
  pointer-events: none;
  border: none;
  outline: none;
  background: transparent;
}

.total-feed-report form .Span {
  align-items: center;
  display: flex;
}
.total-feed-report form .Span label {
  display: none;
}
.total-feed-report form .Span input {
  display: block;
  width: 100%;
}

.view-data-row {
  position: relative;
}

.cartons-total-container {
  position: absolute;
  left: 0;
  top: 0;
}

.carton-row .carton-total {
  position: absolute;
  left: -40px;
  background: #fff;
  z-index: 1000;
  width: 80px;
  text-align: left;
  border-bottom: 1px solid #efefef;
}`, "",{"version":3,"sources":["webpack://./src/styles/reports.styles.scss"],"names":[],"mappings":"AAKE;EACE,eAAA;AAJJ;;AAeM;EACE,oBAAA;EACA,YAAA;EACA,aAAA;EACA,uBAAA;AAZR;;AA2BI;EACE,mBAAA;EACA,aAAA;AAxBN;AAyBM;EACE,aAAA;AAvBR;AAyBM;EACE,cAAA;EACA,WAAA;AAvBR;;AA6BA;EACE,kBAAA;AA1BF;;AA6BA;EAEE,kBAAA;EACA,OAAA;EACA,MAAA;AA3BF;;AA6CE;EACE,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;EACA,WAAA;EACA,gBAAA;EACA,gCAAA;AA1CJ","sourcesContent":[".home-reports{\n\n  //*{\n  //  //outline: 1px solid teal;\n  //}\n  .reports-header{\n    font-size: 14px;\n  }\n}\n\n.department-reports{\n\n  .report-row{\n    //outline: 1px solid teal;\n  }\n  .not-editable{\n\n      input {\n        pointer-events: none;\n        border : none;\n        outline: none;\n        background: transparent;\n      }\n\n\n  }\n}\n\n.total-feed-report{\n\n  //*{\n  //  outline: 1px solid teal;\n  //}\n\n  form{\n\n    .Span{\n      align-items: center;\n      display: flex;\n      label{\n        display : none\n      }\n      input{\n        display : block;\n        width : 100%\n      }\n    }\n  }\n}\n\n.view-data-row{\n  position: relative;\n}\n\n.cartons-total-container{\n\n  position: absolute;\n  left: 0;\n  top: 0;\n  // .carton-total{\n  //   // height: 80px;\n  //   display: flex;\n  //   align-items: center;\n  //   justify-content: center;\n  //   padding: 10px;\n  //   height: 40px;\n  //   margin: 10px 0;\n    \n  // }\n\n\n}\n\n.carton-row{\n  // position: relative;\n\n  .carton-total{\n    position: absolute;\n    left: -40px;\n    background: #fff;\n    z-index: 1000;\n    width: 80px;\n    text-align: left;\n    border-bottom: 1px solid #efefef;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
