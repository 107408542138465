import React, { useMemo } from 'react';
import GreenBox from './GreenBox';


export default function GreenBoxes({data , hasRoosterInfo}) {



  const values = useMemo(() => {

    return {
      feed_consumption :    data?.analysis?.feed_consumption ,
      total_cartons :    data?.analysis?.total_carton ,
      standard_cartons :    data?.analysis?.standard_carton ,
      total_eggs :    data?.analysis?.total_eggs ,
    }


  }, [data])




  return hasRoosterInfo && data && data.analysis && (
      <div className='flex'>
              <GreenBox title={"  استهلاك الاعلاف بالطن"} value={values.feed_consumption} src={'/icos/receipt.png'} />
              <GreenBox title={"  اجمالي الكراتين المُنتجه"} value={values.total_cartons} src={'/icos/coin.png'} />
              <GreenBox title={"  اجمالي الكرتون المتداول"} value={values.standard_cartons} src={'/icos/chart.png'} />
              <GreenBox title={"  اجمالي عدد البيض"}    value={values.total_eggs} src={'/icos/usage.png'} />
      </div>
  )

}