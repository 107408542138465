import {InputText , InputNumber} from "../../../../config/imports";
import Helpers from "../../../../helpers/helpers";


const housingInputs = [
    {name : 'name', title :  'أسم وكود البيت', input : InputText},
    {name : 'side_height', title :  'أرتفاع الجوانب', input : InputNumber , placeholder : 0},
    {name : 'center_height', title :  'أرتفاع السنتر', input : InputNumber , placeholder : 0},
    {name : 'home_height', title :  'طول البيت', input : InputNumber , placeholder : 0},
    {name : 'home_width', title :  'عرض البيت', input : InputNumber , placeholder : 0},
    {name : 'home_area', title :  'مساحة البيت', input : InputNumber , placeholder : 0 , customValue : (data)=> {
        data.home_area =  Helpers.validateNumber(data?.home_width * data?.home_height)
            const input = document.getElementById('home_area')
            if(input) input.value = data.home_area
    }},

    {name : 'collars_lines_count', title :  'عدد خطوط الكولارات', input : InputNumber , placeholder : 0},
    {name : 'collars_height', title :  'طول الكولارات', input : InputNumber , placeholder : 0},
    {name : 'collars_width', title :  'عرض الكولات', input : InputNumber , placeholder : 0},
    {name : 'birds_count', title :  'عدد الطيور', input : InputNumber , placeholder : 0},
    {name : 'birds_per_cage', title :  'عدد الطيور في القفص', input : InputNumber , placeholder : 0},
    {name : 'cages_count', title :  'عدد الأقفاص', input : InputNumber , placeholder : 0 , customValue : (data)=> {
        data.cages_count =  Helpers.validateNumber(data?.birds_count / data?.birds_per_cage)
            const input = document.getElementById('cages_count')
            if(input) input.value =  data.cages_count.toFixed(2)
    }},
    {name : 'battery_type', title :  'نوع البطاريات', input : InputText},
    {name : 'batteries_count', title :  'عدد البطاريات', input : InputNumber , placeholder : 0},
    {name : 'battery_line_count', title :  'عدد صفوف البطارية', input : InputNumber , placeholder : 0},
    {name : 'cages_per_battery', title :  'عدد الاقفاص في البطارية', input : InputNumber , placeholder : 0  , customValue : (data)=> {
        data.cages_per_battery = Helpers.validateNumber( data?.cages_count / data?.batteries_count )
            const input = document.getElementById('cages_per_battery')
            if(input) input.value = data.cages_per_battery.toFixed(2)
    }},
    {name : 'lines_per_battery', title :  'عدد الصف في البطارية', input : InputNumber , placeholder : 0 , customValue : (data)=> {
        data.lines_per_battery =  Helpers.validateNumber(data?.cages_per_battery / data?.battery_line_count)
            const input = document.getElementById('lines_per_battery')
            if(input) input.value = data.lines_per_battery.toFixed(2)
    }},
    {name : 'battery_side_number', title :  'عدد الجنب البطارية', input : InputNumber , placeholder : 0 , customValue : (data)=> {
        data.battery_side_number = Helpers.validateNumber( data?.lines_per_battery / 2)
            const input = document.getElementById('battery_side_number')
            if(input) input.value = data.battery_side_number.toFixed(2)
    }},
    {name : 'cage_length_cm', title :  'طول القفص سم', input : InputNumber , placeholder : 0},
    {name : 'cage_width_cm', title :  'عرض القفص سم', input : InputNumber , placeholder : 0},
    {name : 'cage_height_cm', title :  'أرتفاع القفص سم', input : InputNumber , placeholder : 0},
    {name : 'cage_area_cm', title :  'مساحة القفص سم', input : InputNumber , placeholder : 0 , customValue : (data)=> {
        data.cage_area_cm = Helpers.validateNumber( data?.cage_length_cm * data?.cage_width_cm)
            const input = document.getElementById('cage_area_cm')
            if(input) input.value = data.cage_area_cm.toFixed(2)
    }},
    {name : 'water_tanks_count', title :  'عدد خزانات الماء', input : InputNumber , placeholder : 0},
    {name : 'water_tanks_capacity', title :  'سعة خزانات الماء', input : InputNumber , placeholder : 0},
    {name : 'drug_tanks_count', title :  'عدد خزانات الدواء', input : InputNumber , placeholder : 0},
    {name : 'drug_tanks_capacity', title :  'سعة خزانات الدواء', input : InputNumber , placeholder : 0},
    {name : 'watering_tanks_count', title :  'عدد خزانات المشارب', input : InputNumber , placeholder : 0},
    {name : 'watering_tanks_per_cage', title :  'عدد المشارب في القفص', input : InputNumber , placeholder : 0},
    {name : 'highest_birds_count', title :  'عدد الطيور الأعلى', input : InputNumber , placeholder : 0},
    {name : 'ideal_birds_count', title :  'عدد الطيور المثالي', input : InputNumber , placeholder : 0},
    {name : 'sepal_lines_count', title :  'عدد سور السبلة', input : InputNumber , placeholder : 0},
    {name : 'sepal_lines_length_cm', title :  'طول سير السبلة سم', input : InputNumber , placeholder : 0},
    {name : 'sepal_lines_width_cm', title :  'عرض سير السبلة سم', input : InputNumber , placeholder : 0},
    {name : 'eggs_collection_line_type', title :  'نوع سير جمع البيض', input : InputText},
    {name : 'eggs_collection_line_length', title :  'طول سير جمع البيض', input : InputNumber , placeholder : 0},
    {name : 'eggs_collection_line_width', title :  'عرض سير جمع البيض', input : InputNumber , placeholder : 0},
    {name : 'sepal_pull_dynamo_count', title :  'عدد دينمو سحب السبلة', input : InputNumber , placeholder : 0},
    {name : 'sepal_pull_dynamo_powerline', title :  'خط كهرباء دينمو سحب السبلة', input : InputText},
    {name : 'sepal_pull_dynamo_torque', title :  'قوة عزم دينمو سحب السبلة', input : InputNumber , placeholder : 0},
    {name : 'environment_control_panel_count', title :  'عدد لوحة التحكم في البيئة', input : InputNumber , placeholder : 0},
    {name : 'feed_control_panel_count', title :  'عدد لوحة التحكم في العلف', input : InputNumber , placeholder : 0},
    {name : 'feed_read_control_panel_count', title :  'عدد لوحة قراءة الاعلاف', input : InputNumber , placeholder : 0},
    {name : 'power_control_panel_count', title :  'عدد لوحة تحكم الكهرباء', input : InputNumber , placeholder : 0},
    {name : 'value', title :  'قيمة البيت', input : InputNumber , placeholder : 0},
    {name : "value_of_tools_and_equipments", title :  'قيمة الأدوات والمعدات', input : InputNumber , placeholder : 0},
    {name : 'account_name', title :  'أسم الحساب', input : InputText , size : 6},
    {name : 'account_symbol', title :  'رمز الحساب', input : InputText , size : 6},
]

export default housingInputs
