import { useState } from "react";
import { apis } from "../../../../config/apis";
import { createFormHandler } from "../../../../helpers/form.helper";
import Signboard from "../../../../components/Signboard";
import { EditForm } from "../../../../config/imports";
import InputText from "../../../../components/form/inputs/InputText";
import InputNumber from "../../../../components/form/inputs/InputNumber";


export default function Create (props){

    const { setMainData, toggle , asset } = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [data , setData] = useState({
        name : "",
        eggs_in_dish : "",
        type : ""
    })

    let signboard = {
        error   ,
        success,
        loading ,
    }


    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };


    const handleFormSubmit = async (e) => {

        e.preventDefault();
        const create_props = {
            url: apis.dishes.create(),
            data,
            setLoading,
            setError,
            setSuccess,
            setData,
            setMainData  ,
        };

        await createFormHandler(create_props);
    };

 

    return(
        <>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3> إضافة طبق</h3>
            </header>
            <Signboard {...signboard}/>
            <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
                <InputText name="name" title="اسم الطبق" className="col-12"  placeholder="طبق  4 * 3"  value={data.name} />
                <InputNumber name="eggs_in_dish" title="عدد البيض في كل طبق" className="col-6" placeholder="12" value={data.eggs_in_dish} />
                <InputText name="type" title="نوع الطبق" className="col-6" placeholder="ورق مقوى"  value={data.type} />
            </EditForm>
        </>
    )
}
