import {
    deleteFormHandler,
    TableView,
    Signboard,
    useState
} from "../../config/imports";

import {operatorAPI} from "./operator.routes";

const DeleteOperator = (props)=>{

    const {toggle, item, setMainData} = props;

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const body = [
        {title: "الاسم", col: 3, name : "name" },
        {title: "النوع", col: 3, name: "type"},
        {title: "رقم الهاتف", col: 3, name : "phone"},
        {title: "لمحة موجزة", col: 3, name : "notes"},
    ];


    const handleDelete = async () => {
        const delete_props = {
            url: operatorAPI.base,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        await deleteFormHandler(delete_props);
    };

    return (
        <div className="form-delete">
            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>هل انت متاكد من حذف هذا العنصر ؟</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>

            <TableView data={[item]} body={body}/>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>

        </div>
    )

}

export default DeleteOperator;