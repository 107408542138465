import React from 'react'
import PageMeta from '../../../components/PageMeta'
import useFetch from '../../../hooks/useFetch'
import { apis } from '../../../config/apis'
import { ProjectsSelectItemGroup } from './SelectItemGroup'

export default function Projects() {

  const {data : projects , Alert} = useFetch(apis.projects.names)


  return (

    <>
    <Alert />
    <PageMeta title="اختر المشروع" />
    <ProjectsSelectItemGroup data={projects && projects} />
    </>
  )
}
