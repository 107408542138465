// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.incubator .add-btn {
  padding: 10px 30px;
}`, "",{"version":3,"sources":["webpack://./src/styles/incubator.styles.scss"],"names":[],"mappings":"AACE;EACE,kBAAA;AAAJ","sourcesContent":[".incubator {\n  .add-btn {\n    padding: 10px 30px;\n  }\n  //.data-table {\n  //  margin-top: 80px;\n  //}\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
