import {GreenBox, useFetch} from "../../../config/imports";
import {apis, departments_api} from "../../../config/apis";
import {useParams} from "react-router-dom";

export default function DepartmentDetailsBoxes(props){
    const {id} = useParams()
    const {data} = useFetch(departments_api.stats.green_boxes(id))

    const green_data = [
        {img : '/icos/receipt.png' , title : 'كمية فواتير الاعلاف  ' , value : data?.all_feed_invs_amount ?? 0},
        {img : '/icos/usage.png' , title : 'استهلاك الاعلاف بالطن' , value : data?.all_feed_reports_amount ?? 0},
        {img : '/icos/chart.png' , title : 'عدد النافق الكلى' , value : data?.all_dead_birds ?? 0},
        {img : '/icos/coin.png' , title : 'الانتاجية' , value : data?.productivity ? data?.productivity?.toFixed(2).toString()  + "%" :  "0 %"},
    ]

    return(
        <>
            <div className="row green-box-container justify-content-center mt-5 mb-2 g-2">
                {
                    green_data.map((data , idx) => <GreenBox key={idx} img={data.img} title={data.title} value={data.value}/> )
                }
            </div>

        </>
    )
}
