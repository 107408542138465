export default function TabContent(props){
    const {tabToBeRendered , index , content} = props
    return(
        <>
            {
                tabToBeRendered && tabToBeRendered === index && (
                    <div className="tab-content">
                        <div className="row mt-2">
                            { content }
                        </div>
                    </div>
                )
            }
        </>
    )
}
