import {_date, QuickView, Signboard, TableHeader, useFetch} from "../../../config/imports";
import OrdersCrud from "./home_orders_crud";
import PageMeta from "../../../components/PageMeta";
import {Table} from "../../../layout/table";
import {useRef} from "react";
import {apis} from "../../../config/apis";
import {useParams} from "react-router-dom";
import { OrderStatusTypes } from "./common/order_status_types.common";

export default function CartonsOrders(props){

    const {id} = useParams()
    const createRef = useRef()
    const updateRef = useRef()
    const deleteRef = useRef()
    const api = apis.orders.cartons.paginateHome(id)
    const {data , error , isLoading , setData : setMainData , paginate , setters , setRefresh} = useFetch(api)

    const signboard = { error , loading : isLoading}
    const buttons =[ { title : 'إضافة طلب جديد' , onclick : ()=>  createRef?.current?.toggle()  }]
    const table = [
        { name : '#' , size : 1  , text : '#'},
        { name : 'name' , size : 3  , text : 'الاسم'  , custom : ()=> 'طلب كارتون'},
        { name : 'status' , size : 3  , text : 'الحالة' , custom : d =>OrderStatusTypes[d.status] ?? d?.ar_status},
        // { name : 'unit' , size : 3 , text : 'وحدة القياس' , custom : (d)=> d.unit ?? d.measuringUnit ?? d.measuring_unit},
        { name : 'quantity' , size : 3 , text : 'الكمية'},
        { name : 'createdAt' , size : 4  , text : 'تاريخ الطلب' , custom : d => _date(d.createdAt).dmy()},
        { name : 'info' , size : 8  , text : 'معلومات إضافيه'},
        { name : '' , size : 1  , text : ''}
    ]

    return(
        <>
            <QuickView ref={createRef} ><OrdersCrud.Cartons.Create  setRefresh={setRefresh}  /></QuickView>
            <QuickView ref={updateRef} ><OrdersCrud.Cartons.Update  setRefresh={setRefresh}  /></QuickView>
            <QuickView ref={deleteRef} ><OrdersCrud.Cartons.Delete  setRefresh={setRefresh}  /></QuickView>
            <PageMeta title={' طلبات الكراتين'} />
            <Signboard {...signboard} />
            <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />

            <Table table={table} data={data} className={'mt-5'} updateRef={updateRef} deleteRef={deleteRef} />

        </>
    )
}
