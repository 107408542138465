import { DATA_LIMIT } from "../../config/configs.env.js";
import Helpers from "../../helpers/helpers.js";
import usePaginateStore from "../../stores/paginate.store.js";
import Grid from "../grid/index.js";
import Icons from '../Icons';

export default function TableBody(props){
    const {table , data , updateRef , deleteRef} = props


    const page = usePaginateStore(state => state.page)
    const limit = usePaginateStore(state => state.limit) || DATA_LIMIT
    const total = usePaginateStore(state => state.total) 
     
    const bodyData = (item , index)=>{

        return table?.map(t =>{
            const settingsContent = (t)=>{
              
                const serial =  (total - ((page - 1) * limit + index + 1) + 1) 

                if(t.name === '#') return serial >= 0 ? serial : ''

                else if (t.name === '⚙' || t.name === '') {
                    if(t.custom)   return  t.custom(item)
                    else {
                     return<>
                         <div className={'settings-buttons'}>
                            {updateRef && <button onClick={()=>updateRef?.current?.toggle(item)}><Icons.Edit /></button> }
                            {deleteRef && <button onClick={()=>deleteRef?.current?.toggle(item)}><Icons.Delete /></button> }
                         </div>
                      </>

                    }
                }
                else {
                    if(t.custom)   return t.custom(item)
                    return   data[index][t.name]
                }
            }
            return {
                ...t,
                size : t.size ,
                content : settingsContent(t),
            }
        })

    }

    return(
        <>
            {
                data && data?.length > 0 && data?.map( (item , index) =>{
                    return(
                        <Grid.DataRow key={index}>
                            {
                                bodyData(item , index)?.map( (row , rowIndex) => {
                                    const commaFormatted = row?.skipComma ?  row.content : Helpers.Number.formatIfNumber(row.content)
                                    return (
                                        <Grid.Span key={rowIndex}  size={row.size}>{
                                            // row.skipComma ?  row.content : Helpers.Number.formatIfNumber(row.content)
                                            commaFormatted
                                        }</Grid.Span>
                                    )
                                })
                            }
                        </Grid.DataRow>
                    )
                } )
            }
        </>
    )
}
