import React, { useState } from 'react'
import { apis, departments_api } from '../../../../config/apis';
import Signboard from '../../../../components/Signboard';
import { EditForm, InputDate, InputText, InputTextArea, _date, createFormHandler } from '../../../../config/imports';
import OperatorsInput from '../../../Invoices/shared/OperatorsInput';
import FeedTypeSelectInput from '../../../Invoices/shared/FeedTypeSelectInput';
import MeasuringUnitInput from '../../../Invoices/shared/MeasuringUnitInput';
import UnitPriceInput from '../../../Invoices/shared/UnitPriceInput';
import AmountInput from '../../../Invoices/shared/AmountInput';
import SharedHomesInputs from '../../../Invoices/shared/SharedHomesInputs';
import TotalFromHomesInput from '../../../Invoices/shared/TotalFromHomesInput';
import TotalPriceInput from '../../../Invoices/shared/TotalPriceInput';
import InvoiceId from '../../../Invoices/shared/InvoiceId';
import InputTax from '../../../Invoices/shared/InputTax';

export default function Create(props) {
    const {
        project , department,
        setMainData, toggle , setRefresh
    } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const signboard = { loading , error , success, } 
    
    const [data , setData] = useState({
        // home_id,
        date : new Date().toISOString(),
        total_price : 0,
        isChecked : false,
        total_from_homes : 0,
        project : "",
        department : ""
    })



    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));

    };


    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: departments_api.feed_invoices.create(department),
            data  : {
                ...data ,
                total_from_homes : +data.total_quantity_from_homes
            } ,
            setLoading,
            setError,
            setSuccess,
            setRefresh,
            // setMainData  : ()=> setMainData('refresh-api'),
            setData,
            FORM_HEADER: `الاضافة`,
        };
        console.log(create_props.data)

        await createFormHandler(create_props);
    };

  return (
    <div>
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>شراء اعلاف للقسم </h3>
        </header>
        <Signboard {...signboard}/>
        <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                {/* <InputText name="invNumber" title=" رقم الفاتورة" value={data.invNumber} handleInputChange={handleInputChange} /> */}

                <InvoiceId setData={setData} type={"feedBuyInv"}  />

                <OperatorsInput className={'col-4'} data={data} setData={setData} />

                <InputDate  name="date" label="تاريخ الفاتورة" defaultValue={_date(data.date).forInputDate()} handleInputChange={handleInputChange} />

                <FeedTypeSelectInput data={data} setData={setData} />

                <MeasuringUnitInput data={data} setData={setData} />

                <UnitPriceInput data={data} setData={setData} />

                <AmountInput data={data} setData={setData} />
                
                <SharedHomesInputs project={project}  setError={setError} department={department} data={data} setData={setData} /> 
                
                <TotalFromHomesInput className={'col-3'} data={data} setData={setData} />
                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Voluptate, quaerat?</p>
                <InputTax data={data} setData={setData} />

                <TotalPriceInput className={'col-3'}  data={data} setData={setData} />

                <InputTextArea name="more_info" title="ملاحظات" value={data.more_info} handleInputChange={handleInputChange} />
            </EditForm>

    </div>
  )
}
