import "../../../../../styles/custom.grid.css";
import React, { useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import { strainAPI } from "../../bird_breed.routes";
import Signboard from "../../../../../components/Signboard";
import updateData from "../../../../../helpers/axios/axios.put";
import { EditForm, InputNumber } from "../../../../../config/imports";
import { strain_api } from "../../../../../config/apis";
import ArrayHelpers from "../../../../../helpers/array.helpers";
import { accio } from "../../../../../helpers/axios/axios.get";
import { refresh } from "../../../../../helpers/id.helper";

const BreedingDetails = (props) => {
  const {setRefresh} = props
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);


  const { data, error: fetchingErr, isLoading, setData : setStrain } = useFetch(strainAPI.breeding + "/" + props.item._id);

  const [state , setState] = useState({})


  useEffect(()=>{
    if(!data) return
      setState( {
          name : data?.name,
          breeding_age : data?.breeding_age,
          class : data?.classification?.map( d => ({ _id : d._id , feedCons : d.feedCons , dead_rate : d.dead_rate , age : d.age }) )
        })
  } , [data])



const handleInputChange = (e , index) => {
  const {name , value} = e.target
  const newClasses = [...state.class]
  newClasses[index][name] = +value
  // state.class = newClasses
  setState((prev) => ({...prev , class : newClasses}))
}


const handleSubmit = async (e) => {
  setLoading(true)
  e.preventDefault()

  const {data , message} = await updateData(
          strain_api.classifications(props.item._id) ,
          {classification : state.class}
    )
  setLoading(false)

  if(!data) return setError(message)
  setSuccess("تم تعديل البيانات بنجاح")

  setRefresh(refresh())

}


    const handlePagination = async (page) => {
        setLoading(true)
        const {data : pageData , error} = await accio(strainAPI.breeding + "/" + props.item._id + "?page=" + page)
        // console.log(pageData)
        setLoading(false)
        if(error) return setError(error)
        setStrain( {...pageData})
    }



  return (
    <div className="breeding-details">
      <header className="w-fit m-auto pb-3 px-4 text-center border-bottm-line ">
        <h3 >تفاصيل السلالة</h3>
      </header>
      <Signboard error={error || fetchingErr} loading={loading || isLoading} success={success} />


   <EditForm visible={true} onChange={handleInputChange} onSubmit={handleSubmit} toggle={props.toggle} >

         <>
           <div className="row my-4 justify-content-center">
             <div className="col-12 col-md-6 col-lg-6 d-flex">
               <span className="mx-2">نوع السلالة :</span>
               <strong>{state.name}</strong>
             </div>
             <div className="col-12 col-md-6 col-lg-6 d-flex">
               <span className="mx-1">عمر السلالة :</span>
               <strong>{state.breeding_age}</strong>
             </div>
           </div>
           <>
             <div className="d-flex" style={{ overflowX: "auto", position: "relative", }}>
               <div className="span-4" key={"names"}>
                 <p> الاسبوع </p>
                 <p className={'mt-5'}> استهلاك الاعلاف </p>
                 <p className={'mt-5'}> نسبة النافق </p>
               </div>
               { state?.class?.map((item, index) => {
                 return (
                     <div className={'span-3'} key={item._id}>
                       <p className={'mb-0'}> {item.age} </p>
                       <InputNumber  name={"feedCons"} className={'w-100'} defaultValue={item.feedCons} handleInputChange={(e)=>handleInputChange(e , index)}  />
                       <InputNumber  name={"dead_rate"} className={'w-100'} defaultValue={item.dead_rate} handleInputChange={(e)=>handleInputChange(e , index)}  />
                     </div>
                 );
               })}
             </div>
           </>
         </>
     
               <>         
                  <div className="text-center">
                      {
                          data && ArrayHelpers.range(data.pages).map((page , index) => {
                              return <button  type='button' key={index} className="btn btn-primary mx-1 p-1 px-2" onClick={() => handlePagination(index + 1 )}>{index + 1}</button>
                          })
                      }
                  </div>
               </>
   </EditForm>

    </div>
  );
};

export default BreedingDetails;
