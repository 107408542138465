import {createFormHandler, EditForm, InputText, Signboard , PageMeta, InputSelect, InputNumber, updateFormHandler} from "../../../../../config/imports";
import {apis} from "../../../../../config/apis";
import {useState} from "react";
import DisplayUploadedImage from '../../../../../components/images/DisplayUploadedImage';
import DisplayUploadedImagesGroup from '../../../../../components/images/DisplayUploadedImagesGroup';
import Axios from "../../../../../helpers/axios";
export default function DepositInHatchery (props){

    const {setMainData, toggle , item} = props;



    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({
        ...item
    })

    let signboard = {
        error : error  ,
        success,
        loading : loading ,
    }

    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        setError('')
        setSuccess('')

        setLoading(true)
    
        const {data: postedData, error, statusCode }  =  await Axios.file.put(apis.origins.incubators.update(item._id), data)



        setLoading(false)
        // console.log({postedData})
        if (error || !postedData)   return setError(error)
        
        setSuccess("تم  التعديل بنجاح")
        setMainData("refresh-api")
        
    };


    console.log({data})

    return(
        <>
            <PageMeta title={'إضافة فقاسه'} originTitle={'الفقاسات'}/>

<header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
    <h3> تعديل بيانات الفقاسه</h3>
</header>

<Signboard {...signboard}/>

<EditForm
    visible={true}
    onSubmit={handleFormSubmit}
    toggle={toggle}
    onChange={handleInputChange}
>

    <InputText name={'brand'} title={'الماركة'} placeholder={'Petersime'} defaultValue={data.brand}/>
    <InputText name={'area'} title={'المساحة'} placeholder={'4*4'} defaultValue={data.area}/>
    <InputText name={'location'} title={'موقعه '} placeholder={'غرفة الحاضنات'} defaultValue={data.location}/>

    <InputSelect name={'trolleys_type'} title={'النوع'}  defaultValue={data.trolleys_type}  options={[
        { value : 'اطباق' , text : 'اطباق'},
        { value : 'صندوق' , text : 'صندوق'},
    ]} />

    <InputNumber name={'trolleys_type_count'} title={'عدد النوع'} placeholder={'87454'} defaultValue={data.trolleys_type_count}/>
    <InputNumber name={'trolleys_type_eggs'} title={'عدد  البيض في النوع'} placeholder={'453'} defaultValue={data.trolleys_type_eggs}/>
    <InputNumber name={'trolleys_count'} title={'عدد التروليات'} placeholder={'87454'} defaultValue={data.trolleys_count}/>
    <InputNumber name={'total_eggs'} title={'مجموع البيض '} placeholder={'63456'} defaultValue={data.total_eggs}/>
    <>
        <div className="col-4 col-md-4 ">
            <div data-error="" className="form-group">
                <label htmlFor="images"> صورة  الفاتورة	</label>
                <input
                    type="file"
                    name="images"
                    id="images"
                    required
                    placeholder="اختر صورة"
                    accept="image/*"
                    multiple
                    onChange={(e) =>
                        setData((prevState) => ({
                            ...prevState,
                            images: [ ...prevState.images, ...e.target.files ], 
                        }))
                    }
                    className="hide-arrow form-control"
                />
            </div>
        </div>
    </>
    <>
            <DisplayUploadedImagesGroup images={data.images} src={"incubator"}  keyNAme={'images'} setData={setData} />         
    </>                
</EditForm>



        </>
    )
}
