import FormInput from "./FormInput";

const InputText = ({
  className,
  placeholder,
  handleInputChange,
  name,
  title,
  defaultValue,
  isReadonly,
  label,
  disabled
}) => {
  return (
    <>
      <FormInput
        name={name}
        className={className}
        handleInputChange={handleInputChange}
        defaultValue={defaultValue}
        isReadonly={isReadonly}
        title={title}
        type={"text"}
        placeholder={placeholder || label || title}
        disabled={disabled}
      />
    </>
  );
};

export default InputText;
