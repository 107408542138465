import { useEffect } from "react";

import { useLocation, useNavigate } from "react-router-dom";

const useNavigator = function (base, pathToNavigate) {
  const navigate = useNavigate();

  const location = useLocation();

  const pathName = [...new Set(location.pathname.split("/"))].at(-1);

  useEffect(() => {
    if (pathName === base) return navigate(pathToNavigate , { replace : true });
    else return;
  }, [pathName]);

};

export default useNavigator;
