import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";
import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";

import AddDrugClass from "./AddDrugClass";
import UpdateDrugClass from "./UpdateDrugClass";
import DeleteExportReport from "./DeleteDrugClass";
import DeleteDrugClass from "./DeleteDrugClass";

const DrugClass = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);

    const title = "اصناف ادوية و تحصينات"

    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "اضافة صنف ادوية جديد",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "name", col: 2, title:"الاسم"},
        {name: "total", col: 2, title: "معلومات اضافية"},
        {name: "total", custom :  itm => new Date(itm.createdAt).toLocaleDateString("ar-EG") , col: 3, title: "تاريخ الاضافة"},
        {custom : itm => new Date(itm.updatedAt).toLocaleDateString("ar-EG") , col: 3, title: "تاريخ اخر تعديل"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={title}/>

            <QuickView ref={createRef}>
                <AddDrugClass setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateDrugClass setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteDrugClass setMainData={setMainData}/>
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={title}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default DrugClass;
