const pattern = {
    chars : 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ' ,
    numbers : '0123456789',
    mixed : 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
}

const genRandomNumber = (length = 20)=> Math.floor(Math.random() * length)

const _gen = (length = 10 , type = 'mixed') =>{
    const chars =  pattern[type] ;
    let id = ''
    for (let i = 0; i < length; i++) {
        id += chars[Math.floor(Math.random() * chars?.length)];
    }
    return id
}

export const nanoId = (length = 10) => _gen(length)

export const uuid = () =>  _gen(8) + "-" + _gen(4) + "-" + _gen(4) + "-" + _gen(4) + "-" + _gen(12)

export const mixedId = (length = 10) =>  _gen(length , "mixed")

export const charsId = (length = 10) => _gen(length , "chars")

export const numbersId = (length = 10) => +(_gen(length , "numbers"))

export const key = (length = 10) => _gen(length , "mixed")

export const id = (length = 10) => _gen(length)

export const refresh = (length = 20) => _gen(length , "mixed")






