import {useEffect, useState} from "react";
import {
    createFormHandler,
    EditForm,

    InputText,

    PageMeta, setInputsToDefault,
    Signboard
} from "../../../config/imports";
import {homes_api, projects_api} from "../../../config/apis";
import {InputSelect, useFetch} from "../../../config/imports";
import ArrayHelpers from "../../../helpers/array.helpers";
import {stock_api} from "../../../config/apis";
import HomesCrud from "../../homes/homes/crud_of_homes";
import ProjectCrud from "./index";

export default function CreateHome(props){

    const {setMainData, toggle } = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const signboard = {

        error :   error,
        loading :   loading,
        success : success
    }

    const [data , setData] = useState({
        name : '',
    })
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // console.log(data)
        const create_props = {
            url: stock_api.base,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };


        await createFormHandler(create_props);
    };



    useEffect(() => {
        document.querySelector('.modal-container-content').style.width = 'fit-content';
    })

    return(<>
        <PageMeta title={'إضافة مستودع جديد'} originTitle={'المستودعات'}/>


        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>   إضافة مستودع جديد  </h3>
        </header>

        <Signboard {...signboard}/>

        <EditForm
            visible={props.showFooter === undefined}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}>
            <InputText    className={'col-12'} value={data.name}   name={'name'}  placeholder={'p1'}  title={'اسم المستودع'} />
        </EditForm>

    </> )
}
