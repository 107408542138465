import React, { memo, useMemo } from 'react'
import Accordion from '../../../../components/ui/accordion/Accordion'
import ReportAccordionHeader from './ReportAccordionHeader'
import ReportAccordionBody from './ReportAccordionBody'

function ReportAccordion(props) {
    const {data  , categories , MovementDetails}  = props
    return   data && data?.map( d =>{
        return <Accordion key={d?._id} header={<ReportAccordionHeader data={d} table={props.table} />} >
              <ReportAccordionBody MovementDetails={MovementDetails}   data={d?.movements}  categories={categories}  />
        </Accordion>
  })
}



export default ReportAccordion 