import React, {useState} from "react";
import {TableData, TableHead} from "../../../../../components/TableComponent";
import Signboard from "../../../../../components/Signboard";
import {strainAPI} from "../../../../origin/bird_breed/bird_breed.routes";
import {deleteFormHandler} from "../../../../../helpers/form.helper";

const DeleteOrderReport = ({toggle, item, setMainData}) => {
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const body = [
        {title: "تاريخ الفاتورة", col: 3, custom : itm => new Date(itm.createdAt).toLocaleDateString("ar-EG") },
        {title: "الاسم", col: 3, name : "name" },
        {title: "الكمية", col: 3, name: "quantity"},
        {title: "الاجمالي", col: 3, name: "total"},
    ];

    const handleDelete = async () => {
        const delete_props = {
            url: strainAPI.url,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        deleteFormHandler(delete_props);
    };

    return (
        <div className="form-delete">
            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>هل انت متاكد من حذف هذا العنصر ؟</h3>

            </header>
            <Signboard error={error} success={success} loading={loading}/>

            <div className="delete-item-body px-1 my-5 mt-3">
                <TableData data={[item]} body={body}/>
            </div>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteOrderReport;
