import React from 'react';
import FormFooter from "./FormFooter";

class EditForm extends React.Component {

    
    render() {
        const childs = Array.isArray(this.props.children) ?  this.props.children.filter(child => child) : this.props.children // remove [empty , null , undefined] children
        
        return (
            <form onSubmit={this.props.onSubmit} method="post" style={this.props.style}>
                <div className="row align-items-center">
                    {React.Children.map(childs, (child) => {
                              if(typeof child !== 'object')
                              return <p className='text-center alert alert-warning'> ⚠️   please provide a valid child to the form,  trying to render  <span className="text-danger">  {typeof child} : {child} </span>  ⚠️ </p>  
                        return React.cloneElement(child, { handleInputChange: this.props.onChange });
                    })}
                    {(this.props.visible ?? true) && (
                        <FormFooter toggle={this.props.toggle} handleFormSubmit={this.props.onSubmit} />
                    )}
                </div>
            </form>
        );
    }
}

export default EditForm;
