import {InputSelect, useFetch} from "../../../config/imports";
import {apis} from "../../../config/apis";

export default function FeedTypeSelectInput(props){
    const {data , setData , defaultValue} = props


    const {data : types} = useFetch( apis.origins.feed.base())

    const handleInputChange = (e) => {
        const { value} = e.target;
        setData( { ...data , sub_category : value})
    };
    return(
        <>
            <InputSelect
                className={'col-3'}
                handleInputChange={handleInputChange}
                name={'sub_category'} 
                title={'النوع '}
                defaultValue={defaultValue}
                nameKey={'name'}
                options={types}
            />

        </>
    )
}
