import {RouterLinks} from "../../../components/LinkList";
import {useNavigator} from "../../../config/imports";

const DepartmentReports = ()=>{

    const REPORT_INDEX_LINKS = [
        // {name: "التقارير اليومية",  path: "daily", },
        {name: " التقارير الشاملة ",  path: "total", },
    ];

    useNavigator( "reports" ,"total")

    return (
        <div>
            <RouterLinks data={REPORT_INDEX_LINKS} />
        </div>
    )
}
export default DepartmentReports
