import Helpers from "../../../helpers/helpers"

const BlueBox = ({title , value})=>{


    return (
      <div className='p-2 flex-1'>
            <div className="bg-[#40A9F4] text-white rounded-[15px] px-2 py-4  flex items-center justify-center flex-col shadow-lg">
                  <h3 className='text-[18px] whitespace-nowrap'> {title}</h3>
                  <p className='text-white text-[18px] mb-0'>
                    {Helpers.Number.commaFormat(value ||  0)}
                  </p>
            </div>
      </div>
    )
  
  
}



export default BlueBox