import React, { useState } from "react";
import { TableData } from "../../../../../components/TableComponent";
import Signboard from "../../../../../components/Signboard";
import { strainAPI } from "../../bird_breed.routes";
import { deleteFormHandler } from "../../../../../helpers/form.helper";

const DeleteStrain = ({ toggle, item, setMainData , setRefresh  }) => {
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmBtn, setConfirmBtn] = useState(true);

  const body = [
    { title: "اسم السلالة", col: 3, name: "name" },
    { title: "عمر مرحلة التربية", col: 3, name: "breedingAge" },
    { title: "عمر مرحلة الانتاج", col: 3, name: "productionAge" },
  ];

  const handleDelete = async () => {
    const delete_props = {
      url: strainAPI.url,
      id: item._id,
      setError,
      setSuccess,
      setLoading,
      setConfirmBtn,
      setMainData,
      setRefresh,
      item,
    };

    deleteFormHandler(delete_props);
  };

  return (
    <div className="form-delete">
      <header className="w-fit m-auto  text-center border-bottm-line ">
        <h3>هل انت متاكد من حذف هذا العنصر ؟</h3>
      </header>
        <p className="text-danger text-center">
          سيتم حذف هذة السلالة من مرحلتي التربية والانتاج
        </p>
      <Signboard error={error} success={success} loading={loading} />

      <div className="delete-item-body px-1 mb-5 ">
        <TableData data={[item]} body={body} />
      </div>

      <div className="col-12 col-md-12 mt-4">
        <div className="form-group row justify-content-between align-items-center">
          <div className="col">
            <button className="close-btn" onClick={() => toggle()}>
              {confirmBtn ? "الغاء" : "اغلاق"}
            </button>
          </div>
          <div className="col d-flex justify-content-end">
            {confirmBtn && (
              <button className="add-btn" onClick={handleDelete}>
                نعم
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteStrain;
