import axios from "axios";
import axiosAuthParams from "./axios.auth.params";

export default async (url) => {
  try {
    const response = await axios.delete(url , axiosAuthParams());
    return {
      data: response.data.data,
      error: null,
      statusCode: response.data.statusCode || response.status,
    };
  } catch (error) {
    if(error.response.data.jwt && error.response.data.jwt.user === null){
      localStorage.removeItem('user')
      window.location.href = '/login'
    }
    return {
      data: null,
      error: error.response.data.error,
      statusCode: error.response.status,
    };
  }
};
