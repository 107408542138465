import FormInput from "./FormInput";
import {useEffect} from "react";

const InputNumber = (props) => {
const {className , handleInputChange , name, title , defaultValue, isReadonly , disabled} = props

    useEffect(()=>{
        let inputNumberElms = [...document.querySelectorAll('input[type="number"]')]
    //     loop through all input number elements to prevent increase/decrease value when scrolling with mouse wheel
        inputNumberElms.forEach(elm =>
            elm.addEventListener('wheel', (e) =>  e.preventDefault() )
        )

    })
    return (

        <>
            <FormInput {...props} step='any'  placeholder={props.placeholder} name={name} className={className} handleInputChange={handleInputChange} disabled={disabled} defaultValue={defaultValue}   isReadonly={isReadonly}  title={title} type={"number"} />
        </>

    );

}

export default InputNumber;
