import PageMeta from "../../../components/PageMeta";

import RouterLinks from "../../../components/LinkList";
import useNavigator from "../../../hooks/useNavigator";

const Spareparts = () => {
    const FEED_LINKS = [
        {
            name: "قائمة شراء قطع الغيار",
            path: "buy",
        },
        {
            name: "قائمة تقارير خروج الاصناف",
            path: "export",
        },
    ];

    useNavigator("maintenance", "buy");

    return (
        <>
            <PageMeta title={"الصيانة"} />

            {<RouterLinks underline={true} data={FEED_LINKS} />}
        </>
    );
};

export default Spareparts;
