import {createFormHandler, EditForm, Signboard} from "../../../../config/imports";
import PageMeta from "../../../../components/PageMeta";
import {apis} from "../../../../config/apis";
import {useState} from "react";
export default function DepositInIncubation (props){

    const {setMainData, toggle} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({
        // data
    })

    let signboard = {
        error : error  ,
        success,
        loading : loading ,
    }


    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: apis.levels.breeding.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.url})

        await createFormHandler(create_props);
    };

    return(
        <>
            <PageMeta title={' الإيداع الحضانه'} originTitle={'الفقاسات والحضانات'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3> الإيداع الحضانه</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >
                DepositInIncubation
            </EditForm>



        </>
    )
}
