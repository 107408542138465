import React, {useState} from "react";
import {
    deleteFormHandler,
    destructTableWithSelectedNames,
    TableData,
    Signboard,
    extractTableData, Grid
} from "../../config/imports";


const SuperDelete =  (props)=>{

    const {toggle , item , setMainData , names , table , api , setRefresh } = props
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);


    // the following function will show only selected names
    const {body : selectedDataTable} = destructTableWithSelectedNames(table , names)

    // now we need tp translate the table to TableData to understand it in form { name : 'name' , title : 'name'}
    const data_row = extractTableData( selectedDataTable )


    const handleDelete = async () => {
        const delete_props = {
            url: api,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
            setRefresh,
        };

        await deleteFormHandler(delete_props);
    };

 return(
     <>
         <div className="form-delete">
             <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                 <h3>هل انت متاكد من حذف هذا العنصر ؟</h3>
             </header>
             <Signboard error={error} success={success} loading={loading}/>

             <div className="delete-item-body px-1 my-5 mt-3">
                 <TableData data={[item]} body={data_row}/>
             </div>

             <div className="col-12 col-md-12 mt-4">
                 <div className="form-group row justify-content-between align-items-center">
                     <div className="col">
                         <button className="close-btn" onClick={() => toggle()}>
                             {confirmBtn ? "الغاء" : "اغلاق"}
                         </button>
                     </div>
                     <div className="col d-flex justify-content-end">
                         {confirmBtn && (
                             <button className="add-btn" onClick={handleDelete}>
                                 نعم
                             </button>
                         )}
                     </div>
                 </div>
             </div>


         </div>

     </>
 )
}

export default SuperDelete;
