import {Grid, Icons, Signboard, Table, useFetch, PageMeta, QuickView} from "../../../config/imports";
import {invoices_api, packaging_api} from "../../../config/apis";
import {useRef, useState} from "react";

import "../../../styles/billing.styles.scss"
import Create from "./Create";
import EditDetails from "./EditDetails";
import DeleteWeightDetails from "./DeleteWeightDetails";
import {useParams} from "react-router-dom";

export default function CartoonDetails(props){

    const deleteRef = useRef(),
        updateRef = useRef()

    const {id} = useParams();

    const  { data : details , error : detailsError , isLoading : detailsLoading  , setData}  = useFetch(packaging_api.carton(id))


    console.log({details})
    const signboard = {
        error : detailsError,
        loading : detailsLoading
    }


    const sizes = {
        size :2,
        boxes_count : 3,
        eggs_count :3,
        total_cartoon :3,
        unit_size :4,
        production_percentage :3,
        cartoon_price :3,
        settings :2,

    }
    const header = {
        size:<Grid.Span size={sizes.size}>  الاوزان  </Grid.Span>,
        boxes_count: <Grid.Span size={sizes.boxes_count }> عدد الاطباق </Grid.Span>,
        eggs_count:<Grid.Span size={sizes.eggs_count}>   عدد البيض     </Grid.Span>,
        total_cartoon: <Grid.Span size={sizes.total_cartoon}>اجمالى الكراتين</Grid.Span>,
        unit_size: <Grid.Span size={sizes.unit_size}> وزن الوحدة  </Grid.Span>,
        production_percentage: <Grid.Span size={sizes.production_percentage}>نسبة الانتاج</Grid.Span>,
        cartoon_type:<Grid.Span size={sizes.cartoon_price}>سعر الكرتونة </Grid.Span>,
        settings: <Grid.Span size={sizes.settings}></Grid.Span>
    }

    return<div className='storage-unit-details'>

        <QuickView ref={updateRef}>
            <EditDetails />
        </QuickView>
        <QuickView ref={deleteRef}>
            <DeleteWeightDetails />
        </QuickView>

        <PageMeta title={'قائمة فواتير البيع'} />

        <Signboard {...signboard}  />

        <Table.Header header={header}   />


        {
            details && details.length > 0 && details.map((unit , index) => (
                <Grid.FlexResponsive  key={unit.size} className={'align-items-center data-row'}>
                    <Grid.Span size={sizes.size}>{unit.size}</Grid.Span>
                    <Grid.Span size={sizes.boxes_count}>{ unit.boxes_count }</Grid.Span>
                    <Grid.Span size={sizes.eggs_count}>{unit.eggs_count}</Grid.Span>
                    <Grid.Span size={sizes.total_cartoon}>{unit.total_cartoon}</Grid.Span>
                    <Grid.Span size={sizes.unit_size}>{unit.unit_size}</Grid.Span>
                    <Grid.Span size={sizes.production_percentage}>{unit.production_percentage}</Grid.Span>
                    <Grid.Span size={sizes.cartoon_price}>{unit.cartoon_price}</Grid.Span>
                    {/*<Grid.Span size={sizes.settings} className='settings-buttons'>*/}
                    {/*    <button  onClick={ () => deleteRef.current.toggle(unit)} > <Icons.Delete />  </button>*/}
                    {/*    <button  onClick={ () => updateRef.current.toggle(unit)} > <Icons.Edit />  </button>*/}
                    {/*</Grid.Span>*/}
                </Grid.FlexResponsive>
            ))
        }





    </div>
}
