import TableHeader from "./TableHeader.js";
import TableBody from "./TableBody.js";

export default function DeleteTable(props){
    const {table , data , updateRef , deleteRef , className} = props
    return(
        <div className={"Table " + className ?? ""}>
            <TableHeader table={table} className={'delete-header'} />
            <TableBody table={table} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </div>
    )
}
