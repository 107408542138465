import React, { useState } from "react";
import {
  TableData,
  TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";

import FormModal from "../../../../../components/FormModal";

import PageMeta from "../../../../../components/PageMeta";

// import CreateStrain from "./forms/create/CreateStrain";
// import UpdateStrain from "./forms/update/UpdateStrain";
// import DeleteMaintenance from "./forms/delete/DeleteMaintenance";

import Signboard from "../../../../../components/Signboard";

// import { strainAPI } from "./birdbreed.routes";
// import DeleteStrain from "./forms/delete/DeleteStrain";
import { wareHouseDetailsAPI } from "../../../warehouseDetails.helper";

import CreateFeed from "../../../../origin/bird_breed/forms/create/CreateFeed";
import QuickView from "../../../../../components/QuickView";
import CreateFeedInvoice from "../buy/CreateFeedInvoice";
import UpdateFeedInvoice from "../buy/UpdateFeedInvoice";
import DeleteFeedInvoice from "../buy/DeleteFeedInvoice";
import AddClassReport from "./AddClassReport";
import {TableView} from "../../../../../components/TableView";
import UpdateFeedClass from "./UpdateClassReport";
import {originAPI} from "../../../../origin/origin.helpers";
import DeleteClassReport from "./DeleteClassReport";
import UpdateClassReport from "./UpdateClassReport";

const ReportList = () => {
  const {
    data: feedData,
    error,
    setData: setMainData,
    isLoading,
    success
  } = useFetch(originAPI.feed);

  // console.log(operators);

  // const [createModalState, setCreateModalState] = useState(false);


  const createRef = React.createRef();
  const updateRef = React.createRef();
  const deleteRef = React.createRef();


  const buttons = [
    {
      title: "اضافة تقرير خروج",
      onclick: () => createRef.current.toggle()  ,
    },
  ];

  const table = [
    { name: "idx", col: 1, title: "م" },
    { name: "name", col: 3, title: "اللسم" },
    { name: "info", col: 3, title: "معلومات اضافية" },
    {
      name: "createdAt",
      col: 2,
      title: "تاريخ الاضافه",
      custom: (item) => {
        return new Date(new Date(item.createdAt)).toLocaleDateString("ar-EG");
      },
    },
    {
      name: "updatedAt",
      col: 2,
      title: "تاريخ اخر تعديل",

      custom: (item) => {
        return new Date(new Date(item.updatedAt)).toLocaleDateString("ar-EG");
      },
    },
    { name: "settings", col: 1, title: "" },
  ];
const pageTitle = "قائمة تقارير خروج الاصناف";
  return (
      <>
        <PageMeta title={pageTitle} />

        <QuickView ref={createRef}>
          <AddClassReport setMainData={setMainData}/>
        </QuickView>

        <QuickView ref={updateRef}>
          <UpdateClassReport setMainData={setMainData}/>
        </QuickView>

        <QuickView ref={deleteRef}>
          <DeleteClassReport setMainData={setMainData} />
        </QuickView>

        <Signboard error={error} loading={isLoading} success={success} />

        <TableHeader
            title={pageTitle}
            component={"breeding"}
            buttons={buttons}
        />

        <TableView  data={feedData} body={table} deleteRef={deleteRef} updateRef={updateRef} />
      </>
  );
};

export default ReportList;
