import React from 'react'
import PageMeta from '../../components/PageMeta'
import EggsWarehouseComponent from '../../components/eggs_warehouse/EggsWarehouseComponent'
import { useParams } from 'react-router-dom'
import useHomeBreadCrumbs from './hooks/useHomeBreadCrumbs'

export default function HomeEggsWarehouse() {
    
    const id = useParams().id

    const filter = {
        homes_ids : [id],
    }



    useHomeBreadCrumbs()
    
  return (
    <>
    
        <PageMeta title="مستودع البيض" />

        <div className="flex">
               <div className="flex-1">
                  <EggsWarehouseComponent filter={filter} deps={[id]}   />
               </div>
        </div>
    
    </>
  )
}
