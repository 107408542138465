import React, { useMemo } from 'react'
import { apis } from '../../config/apis'
import useFetch from '../useFetch'
import { InputSelect } from '../../config/imports'

export default function useMassUnit() {

    const {data} = useFetch(apis.units.mass.all())

    const MassEnum = useMemo(() => {
        const obj = {}
        data?.map((unit) => {
             obj[unit?.unit] = unit?.ar_name
        })
        return obj
    })

    const options = useMemo(() => {
        return data?.map((item) => ({ text : item?.ar_name , value : item?.unit}))
    } , [data])
    
    
    return {
      MassTypesEnum : MassEnum,
      Input : useMemo(()=>(props) => 
            <InputSelect  {...props} options={options} />
       , [options]),
      options 
  }
  
}
