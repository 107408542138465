import PageMeta from "../../../components/PageMeta";
import {setInputsToDefault} from "../../../helpers/form.helper";
import {createInputGenerator, EditForm, InputNumber, InputText, Signboard} from "../../../config/imports";
import {useState} from "react";

export default function EditDetails(props){

    const {toggle , item , setMainData} = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data, setData] = useState({
        size : item.size,
        boxes_count : item.boxes_count,
        eggs_count : item.eggs_count,
        total_cartoon : item.total_cartoon,
        unit_size : item.unit_size,
        production_percentage : item.production_percentage,
        cartoon_price : item.cartoon_price,
    })

    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        // setLoading(true);
        // e.preventDefault();
        //
        // if(others&&others.hasImage) others.hasImage({data , setData})
        //
        // const update_props = {
        //     url: api,
        //     data,
        //     setLoading,
        //     setError,
        //     setSuccess,
        //     setMainData,
        //     setInputsToDefault,
        //     setData,
        //     FORM_HEADER: `تعديل`,
        // };
        // if(others && others.formatSuperUpdateData) others.formatSuperUpdateData(update_props)
        // console.log(update_props)
        //
        // // await updateFormHandler(update_props);
    };

    return<div className='storage-unit-details'>

        <PageMeta title={"تعديل تفاصيل الوزن" + item.size} originTitle={'تفاصيل الاوزان'}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>{" تعديل تفاصيل الوزن " + item.size}</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>


        <EditForm
            visible={true}
            onSubmit={handleFormSubmit}
            toggle={toggle}>
            {
                data && (
                    <>
                        <InputText name={'size'} handleInputChange={handleInputChange}  placeholder={'0'}  title={'الاوزان'} defaultValue={data.size}  />
                        <InputNumber name={'boxes_count'} handleInputChange={handleInputChange} placeholder={'0'}  title={'عدد الاطباق'} defaultValue={data.boxes_count}  />
                        <InputNumber name={'eggs_count'} handleInputChange={handleInputChange}   placeholder={'0'} title={'عدد البيض'} defaultValue={data.eggs_count}  />
                        <InputNumber name={'total_cartoon'} handleInputChange={handleInputChange} className={'col-3'} placeholder={'0'}  title={'اجمالى الكرتين'} defaultValue={data.total_cartoon}  />
                        <InputNumber name={'unit_size'} handleInputChange={handleInputChange} className={'col-3'} placeholder={'0'}  title={'وزن الوحدة'} defaultValue={data.unit_size}  />
                        <InputNumber name={'production_percentage'} handleInputChange={handleInputChange} className={'col-3'} placeholder={'0'}  title={'نسبة الانتاج'} defaultValue={data.production_percentage}  />
                        <InputNumber name={'cartoon_price'} handleInputChange={handleInputChange} className={'col-3'} placeholder={'0'}  title={'سعر الكرتونة '} defaultValue={data.cartoon_price}  />

                    </>
        )
            }
        </EditForm>


    </div>
}
