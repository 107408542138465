import useFetch from "../../../hooks/useFetch"

const useWarehouseAccordionItemData = (api) => {

    const {data  , Alert , ...other  } = useFetch(api)



    return {data , Alert , ...other}

}



export default useWarehouseAccordionItemData