import { useEffect, useState } from "react";
import useFetch from "../../../../../hooks/useFetch";
import postData from "../../../../../helpers/axios/axios.post";
import { originAPI, operatorAPI } from "../../../origin.helpers";

import {
  validateForm,
  checkIfInputErrors,
  catchInputsWithErrors,
  setParent,
  setInputsToDefault,
} from "../../../../../helpers/form.helper";
import { errorHandler } from "../../../../../helpers/error.helper";
import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";

const CreateFeed = ({ toggle, setFeedData, operators }) => {
  const [data, setData] = useState({
    name: "",
    measuringUnit: "",
    operator: "",
    info: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");



  const handleFormSubmit = async (e) => {
    setLoading(true);
    setError("");
    setSuccess("");

    const {
      data: postedData,
      error,
      statusCode,
    } = await postData(originAPI.feed, data);
    if (error) {
      setLoading(false);
      setSuccess("");
      return setError(errorHandler(error));
    }
    if (statusCode === 201 && postedData) {
      setLoading(false);
      setSuccess("تم اضافة نوع علف جديد بنجاح");
      setFeedData((prevState) => [...prevState, postedData]);
      setInputsToDefault();
      setData({ name: "", measuringUnit: "", info: "", operator: "" });
    }
  };

  // make it to change while changing not after change

  // syncInputFielsErrorsChanges(BODY_TO_VALIDATE);

  return (
    <div className="temp-form">
      <PageMeta title={"اضافة نوع علف جديد"} originTitle={"الاعلاف"} />
      <header
        className="text-center border-bottm-line "
        style={{
          width: "fit-content",
          margin: "0px auto 60px ",
          padding: "10px 20px",
        }}>
        <h3>اضافة نوع علف جديد</h3>
      </header>
      <Signboard error={  error } loading={loading} success={success} />
      <div className="row align-items-center">
        <div className="my-3 col-12 col-md-6">
          <div data-error="" className="form-group">
            <label htmlFor="name">اسم العلف</label>
            <input
              type="text"
              name="name"
              id="name"
              required
              placeholder="اسم العلف"
              value={data.name}
              onChange={(e) =>
                setData((prevState) => ({ ...prevState, name: e.target.value }))
              }
              className="form-control"
            />
          </div>
        </div>
        <div className="my-3 col-12 col-md-6">
          <div className="form-group" data-error="">
            <label htmlFor="measuringUnit">وحدة القياس</label>
            <select
              name="measuringUnit"
              id="measuringUnit"
              onChange={(e) =>
                setData((prevState) => ({
                  ...prevState,
                  measuringUnit: e.target.value,
                }))
              }
              required
              // value={data.measuringUnit}

              defaultValue={""}
              className="form-select">
              <option value="">اختر وحدة القياس</option>
              <option value="طن">طن</option>
              <option value="كيلو">كيلو</option>
              <option value="جرام">جرام</option>
            </select>
          </div>
        </div>

        <div className="my-3 col-12 col-md-12">
          <div className="form-group" data-error="">
            <label htmlFor="operator">المورد</label>
            <select
              name="operator"
              id="operator"
              required
              defaultValue={""}
              onChange={(e) =>
                setData((prevState) => ({
                  ...prevState,
                  operator: e.target.value,
                }))
              }
              className="form-select py-2">
              {/* <option value="djshfjdshf">محمود</option>
              <option value="dfbnsaxncb">محمد</option> */}
              <option value="">اختر المورد</option>
              {operators &&
                operators.map((operator) => (
                  <option value={operator._id} key={operator._id}>
                    {operator.name}
                  </option>
                ))}
            </select>
          </div>
        </div>

        <div className="my-3 col-12 col-md-12">
          <div className="form-group" data-error="">
            <label htmlFor="info">معلومات اضافيه</label>
            <textarea
              name="info"
              id="info"
              rows={4}
              placeholder="معلومات اضافيه عن العلف"
              onChange={(e) =>
                setData((prevState) => ({ ...prevState, info: e.target.value }))
              }
              required
              defaultValue={data.info}
              className="form-control"></textarea>
          </div>
        </div>

        <div className="col-12 col-md-12 mt-4">
          <div className="form-group row justify-content-between align-items-center">
            <div className="col">
              <button onClick={() => toggle()} className="close-btn">
                الغاء
              </button>
            </div>
            <div className="col d-flex justify-content-end">
              <button className="add-btn" onClick={handleFormSubmit}>
                اضافة
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFeed;
