import { useMemo } from "react"
import useFetch from "../../../hooks/useFetch"
import { InputSelect } from "../../../config/imports"
import { apis } from "../../../config/apis"

 const useStocks = ()=>{
    const {data : stocks} = useFetch( apis.warehouses.base  )
    const options = useMemo(() => {
        return stocks?.map(d => ({value : d?._id , text : d?.name}))
    } , [stocks])
    

    return { 
      options,
      Input : useMemo(()=>{
        return (props) =>{
            return <InputSelect  {...props} options={options} />
          }
      } , [options])
    }
}



export default useStocks