import '../../../styles/toggle.styles.scss';
export default function CheckEqualInput(props){
 const {data , setData}  = props
    const handleInputChange = e=>{
        const checked = e.target.checked
        data.isChecked = checked
        if(checked){
            let _homes = data.home
            const homeCount = _homes.length
            _homes.forEach(home=>{
                home.quantity = +( data.quantity / homeCount).toFixed(2)
            })
            setData({...data , home : _homes , total_quantity_from_homes : data.quantity })
        }
    }


    return(
        <div className={'d-flex justify-content-between'}>


            <h2> توزيع الكميه بالتساوي على البيوت ؟</h2>
            <ul className="tg-list" dir="ltr">
                <li className="tg-list-item">
                    <input className="tgl tgl-ios" id="cb2" onChange={handleInputChange} type="checkbox"/>
                    <label className="tgl-btn" htmlFor="cb2"></label>
                </li>
            </ul>


        </div>
    )
}
