import Helpers from "../../../helpers/helpers"

const GreenBox = ({title , value, src})=>{


    return (
      <div className='p-2 flex-1'>
          <div className="flex bg-[#41E481]  rounded-[15px] px-3 py-4 shadow-lg">
            <div className="w-[50px]">
              <img src={src}  />
            </div>
  
            <div className="flex-1  flex items-center justify-center flex-col ">
                    <h3 className='text-[18px]  text-white'> {title}</h3>
                    <p className='text-white text-[18px] mb-0'>
                      {Helpers.Number.commaFormat(value ||  0)}
                    </p>
              </div>
  
          </div>
      </div>
    )
  
  
  }



export default GreenBox