import BuyFeedInvoice from './BuyFeedInvoice';
import BuyDrugsInvoice from './BuyDrugsInvoice';
import MaintenanceInvoice from './MaintenanceInvoice';
import OtherInvoice from './OtherInvoice';
import BuyCartonsInvoice from './BuyCartonsInvoice';
import BuyDishesInvoice from './BuyDishesInvoice';
import BuyWarehouseFeedInvoice from './BuyWarehouseFeedInvoice';

const BuyInvoices = {
    Feed : BuyFeedInvoice,
    Drugs  : BuyDrugsInvoice,
    Maintenance : MaintenanceInvoice,
    Other : OtherInvoice,
    Cartons : BuyCartonsInvoice,
    Dishes : BuyDishesInvoice,
    WFeed : BuyWarehouseFeedInvoice
}
export default BuyInvoices
