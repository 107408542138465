import React from 'react'
import { TableBody } from '../../../../layout/table'

export default function ReportAccordionHeader(props) {
// console.log(props.table)
 
  return  props.data && (
    <div className="bg-[#f3f3f3]  w-full bg-gradient-to-t from-[#ddd] to-[#eee]"  >
      <TableBody data={[props?.data]}  table={props?.table}  />
    </div>
  )

}

