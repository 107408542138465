import { useState } from "react";
import { apis } from "../../../../config/apis";
import Signboard from "../../../../components/Signboard";
import DeleteTable from "../../../../layout/table/DeleteTable";
import { _date } from "../../../../helpers/date.helpers";
import { deleteFormHandler } from "../../../../helpers/form.helper";

export default function Delete(props){

    const {setMainData, toggle , item , asset} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [confirmBtn, setConfirmBtn] = useState(true);



    let signboard = { error : error  , success, loading : loading  }

    const table = [
        {size : 6 , name : "name" , text :  'أسم الطبق' },
        {size : 4 , name : "eggs_in_dish" , text :  'عدد البيض في الطبق' },
        {size : 6 , name : "type" , text :  'نوع الطبق',  },
        {size : 4 , name : "createdAt" , text :  '  تاريخ الانشاء   ' },
    ]

    const handleDelete = async () => {
        const delete_props = {
            url: apis.dishes.delete() ,
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };

        // console.log(delete_props)
        await deleteFormHandler(delete_props);
    };


 
    return(
        <>

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h4>حذف الطبق ؟</h4>
            </header>
            <Signboard {...signboard} />

            <DeleteTable  table={table} data={[item]} />

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
