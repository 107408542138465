import React from 'react'
import PageMeta from '../../../components/PageMeta'
import useFetch from '../../../hooks/useFetch'
import { apis } from '../../../config/apis'
import { DepartmentsSelectItemGroup } from './SelectItemGroup'

export default function Departments() {

    const {data : departments , Alert} = useFetch(apis.departments.all())

  return (
    <>
    <Alert />
     <PageMeta title="اختر القسم" />
     <DepartmentsSelectItemGroup data={departments && departments} />
    </>
  )
}
