const FormFooter = ({toggle}) => {

    return (
        <>
            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button type="button" onClick={() => toggle()} className="close-btn">
                            الغاء
                        </button>
                    </div>
                    <div className="col">
                        <button type="reset">
                            reset
                        </button>
                    </div>

                    <div className="col d-flex justify-content-end">
                        <button className="add-btn" type="submit">
                            تاكيد
                        </button>
                    </div>
                </div>
            </div>
        </>

    );
}

export default FormFooter;