import LinkList from "../../../components/LinkList";
import { RouterLinks } from "../../../components/LinkList";
import useNavigator from "../../../hooks/useNavigator";
import "../../../styles/base-card.css";
const Orders = () => {
    const ITEMS_CARDS_LINKS = [
        {
            name: "الاعلاف",
            path: "feed",
        },
        {
            name: "التعبئة والتغليف",
            path: "packaging",
        },
        {
            name: "الادوية",
            path: "drugs",
        },
        {
            name: "قطع الغيار",
            path: "maintenance",
        },
    ];

    useNavigator("orders", "feed");

    return (
        <div>{<RouterLinks data={ITEMS_CARDS_LINKS} />}</div>
    );
};

export default Orders;


