// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stocks-container .add-warehose-btn,
.stocks-container .warehouse-card {
  all: unset;
  width: 150px;
  height: 150px;
  border-radius: 16px;
  background-color: #D5D6D9;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  fill: #676A73;
}
.stocks-container .add-warehose-btn:hover,
.stocks-container .warehouse-card:hover {
  box-shadow: 2px 2px 5px #676A73;
}
.stocks-container .add-warehose-btn path,
.stocks-container .warehouse-card path {
  fill: #676A73 !important;
}
.stocks-container .add-warehose-btn span,
.stocks-container .warehouse-card span {
  display: inline-block;
  margin-top: 10px;
  font-weight: 600;
}
.stocks-container .page-content .fa-plus {
  outline: 2px solid #676A73;
  color: #676A73 !important;
  padding: 5px;
  border-radius: 5px;
}
.stocks-container .warehouse-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
.stocks-container .warehouse-card h5 {
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/styles/stocks.scss"],"names":[],"mappings":"AACA;;EAEE,UAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,eAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EAIA,aAAA;AAHF;AAAE;;EACE,+BAAA;AAGJ;AAAI;;EACI,wBAAA;AAGR;AADE;;EACE,qBAAA;EACA,gBAAA;EACA,gBAAA;AAIJ;AACE;EACE,0BAAA;EACA,yBAAA;EACA,YAAA;EACA,kBAAA;AACJ;AAKE;EAEA,aAAA;EACE,sBAAA;EACA,uBAAA;EACA,qBAAA;AAJJ;AAMI;EACE,cAAA;AAJN","sourcesContent":[".stocks-container{\n.add-warehose-btn ,\n.warehouse-card{\n  all: unset;\n  width: 150px;\n  height: 150px;\n  border-radius: 16px;\n  background-color: #D5D6D9;\n  cursor: pointer;\n  transition: .3s;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  &:hover{\n    box-shadow: 2px 2px 5px #676A73\n  }\n  fill: #676A73;\n    path {\n        fill: #676A73 !important;\n    }\n  span{\n    display: inline-block;\n    margin-top: 10px;\n    font-weight: 600;\n  }\n}\n  .page-content{\n\n  .fa-plus{\n    outline: 2px solid #676A73;\n    color: #676A73 !important;\n    padding: 5px;\n    border-radius: 5px;\n  }\n\n  }\n\n\n  .warehouse-card{\n\n  display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-content: center;\n\n    h5{\n      color: #ffffff;\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
