import { useMemo } from 'react'
import { apis } from '../../config/apis'
import useFetch from '../useFetch'
import { InputSelect } from '../../config/imports'
import { Group } from '../../helpers/helpers'

export default function useProjects() {

    const {data : projects} = useFetch(apis.projects.names)
 

    const options = useMemo(() => {
        if(!projects) return []
        return [...projects.map(d => ({value : d?._id , text : d?.name}))]
    }, [projects])


    const groupedProjects = useMemo(() => {
        if(!projects) return 
        return Group(projects).byId
    }, [projects])


    const getById = (id) => {
        if(!projects || !id) return undefined
        return groupedProjects[id]
    }


    const getName = (id) => {
        if(!projects || !id) return undefined
        return getById(id)?.name
    }


    const InputProjects = useMemo(() => (props) => 
        <InputSelect  {...props} options={options} className="w-full" />
    , [options])


  return {
    options,
    getName,
    getById,
    Input : InputProjects
  }
}
