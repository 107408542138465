import {projectsAPI} from "../../../../projects.helper"

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    SuperCreate,
    SuperUpdate,
    SuperDelete,
    extractTableData,
    addColumn,
    InputDate,
    InputText,
    InputNumber, InputTextArea
} from '../../../../../../config/imports';
import SelectOperator from "../../../../../../components/Shared/SelectOperator";

const BirdsNotesMenu = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            birds_count: 1504,
            unit_price: 1,
            value: 23,
            male_no: 33,
            male_unit_price: 44,
            male_value: 55,
            total_no: 66,
            total_value: 48,
            inv_number: 112,
            operator: 48,
            created_at: new Date(),
            updated_at: new Date(),
            extra: 50

        }, {
            birds_count: 1504,
            unit_price: 15,
            value: 545,
            male_no: 54,
            male_unit_price: 21,
            male_value: 215,
            total_no: 48,
            total_value: 48,
            inv_number: 112,
            operator: 48,
            created_at: new Date(),
            updated_at: new Date(),
            extra: 48

        }
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "اضافة مذكرة ادخال جديدة",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];

    const table = [
        addColumn("", "م", ""),
        addColumn("birds_count", "عدد الدجاج", InputNumber),
        addColumn("unit_price", "سعر الوحدة", InputNumber),
        addColumn("value", "القيمة", InputNumber),
        addColumn("male_no", "عدد الديوك", InputNumber),
        addColumn("male_unit_price", "سعر الوحده", InputNumber),
        addColumn("male_value", "القيمة", InputNumber),
        addColumn("total_no", "العدد الكلي", InputNumber),
        addColumn("total_value", "اجمالي القيمة", InputNumber),
        addColumn("inv_number", "رقم الفاتورة", InputNumber),
        addColumn("operator", "العميل او المورد", SelectOperator),
        {
            gen: {name: "created_at", title: "تاريخ الاضافة", elm: InputDate},
            table: {
                col: 1,
                custom: (itm) => new Date(itm.created_at).toLocaleDateString("ar-EG"),
            }, create: {props: {}, value: ""},
            update: {},
        },
        {
            gen: {name: "updated_at", title: "تاريخ اخر تعديل", elm: InputDate},
            table: {
                col: 1,
                custom: (itm) => new Date(itm.updated_at).toLocaleDateString("ar-EG"),
            },
            create: {props: {}, value: ""},
            update: {},
        },
        addColumn("extra", "معلومات اضافية", InputTextArea),
        addColumn("settings", "", ""),
    ];


    const main_title = "قائمة مدخلات الطيور";

    const views = [
        {
            ref: createRef,
            component: (
                <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"اضافة مذكرة ادخال جديدة"}
                    table={table}
                />
            ),
        },
        {
            ref: updateRef,
            component: (
                <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={main_title}
                    formHeader={"تعديل مذكرة ادخال جديدة"}
                    table={table}
                />
            ),
        },
        {
            ref: deleteRef,
            component: <SuperDelete api={""} setMainData={setMainData} table={table}/>,
        },
    ];


    return (
        <>
            <PageMeta title={main_title}/>


            <QuickViewContainer views={views}/>

            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={"قائمة مدخلات الطيور"}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};

export default BirdsNotesMenu;
