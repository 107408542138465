import { NavLink, useParams } from "react-router-dom";
import { useFetch, PageMeta, Signboard, Icons, QuickView, postData } from "../../../config/imports";
import { homes_api } from "../../../config/apis";
import { useEffect, useRef, useState } from "react";
import "../../../styles/projects.styles.scss";
import HomesCrud from "./crud_of_homes";
import useBreadCrumb from "../../../components/breadcrumbs/hooks/breadcrumb.hook";
import { setProjectsBreadCrumbs } from "../../../components/breadcrumbs/store/breadcrumbs.default";




const DepartmentHomes = ()=>{




    const createRef = useRef(),
        editRef = useRef(),
        deleteRef = useRef(),
        colorRef = useRef();

    const {id} = useParams();



    const {data : crumbs}  = useBreadCrumb({id , name : "department"})

   

    useEffect(() => {
        if(!crumbs || !crumbs.length) return
        const projectCrumb = crumbs.filter(crumb => crumb.model === "project" || crumb.section === "project")[0]
        const departmentCrumb = crumbs.filter(crumb => crumb.model === "department" || crumb.section === "department")[0]

        const _crumbs = [
            {name : projectCrumb.name , to : `/projects/${projectCrumb.id}/departments`},
            // TODO : check why name is not returning
            {name : departmentCrumb.name ?? "القسم" , to : `/departments/${departmentCrumb.id}/homes/base`},
            {name : "البيوت" , to : `/departments/${departmentCrumb.id}/homes/base` }
        ]

        setProjectsBreadCrumbs.departmentHomes(_crumbs)
    } , [crumbs])





    let {
        data : homes ,
        isLoading : homes_isLoading,
        error : homes_error,
        setData : setHomes
    } = useFetch(homes_api.homes(id))




    let [favHomes , setFavHomes] = useState( [])
    let [notFavHome , setNotFavHome] = useState([])
    const [loading , setloading] = useState(false)
    const [errorStatus , setErrorStatus] = useState('')
    const [successStatus , setSuccessStatus] = useState('')



    const signboard = {
        error : homes_error || errorStatus ,
        loading : homes_isLoading || loading,
    }
    // console.log({
    //     favHomes , notFavHome
    // })
    useEffect(() => {
        // console.log(homes)
        if(homes && homes.length > 0){
            setFavHomes(homes.filter(home => home.is_favorite))
            setNotFavHome(homes.filter(home => !home.is_favorite))
        }
    } , [homes])



    const favoriteStateHandler = async (home , e)=>{
        setErrorStatus('')
    //     find the index of this home
        const homeIndex = homes.findIndex(h => h._id === home._id)
        const homeToBeChanged = homes.filter(h => h._id === home._id)[0]

        if(homeToBeChanged){
            const {data , error } = await postData(homes_api.toggleFavorite() , { home_id : homeToBeChanged._id }  )
            setloading(false)
            // console.log({data , error})
            if(!data) return setErrorStatus(error)

            homeToBeChanged.is_favorite = !homeToBeChanged.is_favorite
            homes[homeIndex] = homeToBeChanged
            homes = [...homes]
            setHomes(homes)
        }
    }
    const lockStateHandler = async (home , e)=>{
        const homeIndex = homes.findIndex(h => h._id === home._id)
        const homeToBeChanged = homes.filter(h => h._id === home._id)[0]
        if(homeToBeChanged){

            homeToBeChanged.is_locked = !homeToBeChanged.is_locked
            homes[homeIndex] = homeToBeChanged
            homes = [...homes]
            setHomes(homes)
        }
    }
const homeMenuHandler = (e)=>{

    const TIME_TO_CLOSE_MENU = 10000

    const menuId = e.target.dataset.menuId
    // document.querySelectorAll('.cube-menu').forEach(el => el.classList.remove("active-menu"))
    if(menuId)
        document.getElementById(menuId)?.classList.toggle("active-menu")


    setTimeout(()=>{
       document.getElementById(menuId)?.classList.toggle("active-menu")
    } , TIME_TO_CLOSE_MENU)
}

// const changeColorHandler = (e)=>{
//     console.log("changing  color")
// }




    return<>

        {/*<Location links={*/}
        {/*    [*/}
        {/*        {href : "/" , text : 'الرئيسية' },*/}
        {/*        {href : "/projects" , text : 'المشاريع' },*/}
        {/*     ]*/}
        {/*}*/}
        {/*/>*/}

        <div className='homes'>



            <QuickView ref={createRef}><HomesCrud.Create department={id} setMainData={setHomes} /></QuickView>
            <QuickView ref={editRef}><HomesCrud.Edit department={id}  setMainData={setHomes} /></QuickView>
            <QuickView ref={deleteRef}><HomesCrud.Delete  setMainData={setHomes} /></QuickView>
            <QuickView ref={colorRef}><HomesCrud.Color homes={homes} setHomes={setHomes} /></QuickView>


            <PageMeta title="البيوت" />
            <Signboard {...signboard} />

            {
                favHomes && favHomes.length > 0 && (
                    <div className="">
                        <h4 className='d-flex  align-items-center mt-4'>
                            <img src="/icos/star.png" alt="star icon"/>
                            <span className='mx-2 d-inline-block'> المفضلة </span>
                        </h4>
                        <div className="d-flex flex-wrap ">
                            {
                                favHomes.map(home => (
                                    <div className="round-cube" key={home._id} id={'home-'+home._id}>
                                        <div className="cube-menu" id={'menu' + home._id}>
                                            <div className='menu-item edit-cube flex'>
                                                <button onClick={ () => editRef.current.toggle(home) }>
                                                    تعديل
                                                    <Icons.Edit/>
                                                </button>
                                            </div>
                                            <div className='menu-item delete-cube flex'>
                                                <button onClick={ () => deleteRef.current.toggle(home) }>
                                                    حذف
                                                    <Icons.Delete/>
                                                </button>
                                            </div>
                                            <div className='menu-item delete-cube flex'>
                                                <button onClick={ () => colorRef.current.toggle(home) }>
                                                    تغيير اللون
                                                    <Icons.Color/>
                                                </button>
                                            </div>
                                        </div>

                                        <div className="data-container" style={{backgroundColor: home.color ?? "#41e481"}}>
                                            <header className='header-menu'>
                                                <button type='button' data-menu-id={'menu' +home._id}
                                                        onClick={homeMenuHandler}
                                                >...</button>
                                            </header>
                                            <div className="cube-name">
                                                <NavLink to={`/home/${home._id}/details`} key={home._id}>
                                                    {home.name}
                                                </NavLink>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <button onClick={ (e ) => lockStateHandler(home , e) } >
                                                    <img src={`/icos/${home.is_locked ? 'locked' : 'lock'}.png`} alt=""/>
                                                    {/*locked*/}
                                                </button>
                                                <button onClick={ (e ) => favoriteStateHandler(home , e) } >
                                                    <img src="/icos/fav-star.png" alt=""/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className=" text-center">
                                            {
                                                `بيت ${new Date(home.createdAt).getFullYear()}`
                                            }
                                        </div>

                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }

            {/*  projects list  */}
            {
                notFavHome && (
                    <div className="not-fav-section flex-wrap">
                        <h4 className='d-flex  align-items-center mt-4'>
                            <img src="/icos/category.png" alt="category icon"/>
                            <span className='mx-2 d-inline-block'> كل البيوت </span>
                        </h4>
                        <div className="d-flex flex-wrap">
                            <div className="round-cube" >
                                <button onClick={ ()=>  createRef.current.toggle() }>
                                    <div className="data-container add-cube">
                                        <div className="flex items-center justify-center flex-col">
                                            <img src="/icos/add-square.png" alt="add"/>
                                            <p className='mt-3'>اضافة بيت</p>
                                        </div>
                                    </div>
                                </button >
                            </div>
                            {
                                notFavHome.map(home => (
                                    <div className="round-cube" key={home._id} id={'home-'+home._id}>
                                        <div className="cube-menu" id={'menu' + home._id}>
                                            <div className='menu-item edit-cube flex'>
                                                <button onClick={ () => editRef.current.toggle(home) }>
                                                    تعديل
                                                    <Icons.Edit/>
                                                </button>
                                            </div>
                                            <div className='menu-item delete-cube flex'>
                                                <button onClick={ () => deleteRef.current.toggle(home) }>
                                                    حذف
                                                    <Icons.Delete/>
                                                </button>
                                            </div>
                                            <div className='menu-item delete-cube'>
                                                <button onClick={ () => colorRef.current.toggle(home) } className='flex'>
                                                    تغيير اللون
                                                    <Icons.Color/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className="data-container" style={{backgroundColor: home.color ?? "#41e481"}}>
                                            <header className='header-menu'>
                                                <button type='button' data-menu-id={'menu' +home._id}
                                                        onClick={homeMenuHandler}
                                                >...</button>
                                            </header>
                                            <div className="cube-name">
                                                <NavLink to={`/home/${home._id}/details`} >
                                                    { home.name}
                                                </NavLink>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                <button onClick={ (e ) => lockStateHandler(home , e) } >
                                                    <img src={`/icos/${home.is_locked ? 'locked' : 'lock'}.png`} alt=""/>
                                                </button>
                                                <button onClick={ (e ) => favoriteStateHandler(home , e) } >
                                                    <img src="/icos/outline-star.png" alt=""/>
                                                </button>
                                            </div>
                                        </div>
                                        <div className=" text-center">
                                            {`بيت ${new Date(home.createdAt).getFullYear()}`}
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                )
            }






        </div>

    </>

}
export default DepartmentHomes