import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";
import CreateFeed from "../../../../origin/items_cards/forms/create/CreateFeed";
import UpdateFeed from "../../../../origin/items_cards/forms/update/UpdateFeed";
import DeleteFeed from "../../../../origin/items_cards/forms/delete/DeleteFeed";
import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";
import CreateFeedInvoice from "./CreateFeedInvoice";
import UpdateFeedInvoice from "./UpdateFeedInvoice";
import DeleteFeedInvoice from "./DeleteFeedInvoice";

const BuyFeed = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);


    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "اضافة فاتورة اعلاف",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "invNumber", col: 1, title: "رقم القاتورة"},
        {name: "operator", custom : _item => _item.operator.name  , col: 1, title: "العميل / المورد"},
        {name: "date",   col: 1, title: "التاريخ"},
        {name: "type", col: 1, title: "نوع العلف"},
        {name: "measuringUnit", col: 1, title: "وحدة القياس"},
        {name: "unitprice", col: 1, title: "سعر الوحده"},
        {name: "quantity", col: 1, title: "الكمية"},
        {name: "total", col: 1, title: "اجمالي السعر"},
        {name: "totalforhome", col: 1, title: "feed cons"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={"قائمة شراء الاعلاف"}/>

            <QuickView ref={createRef}>
                <CreateFeedInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateFeedInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteFeedInvoice setMainData={setMainData} />
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={"اضافة فاتورة اعلاف"}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default BuyFeed;
