import {
    createFormHandler,
    createInputGenerator,
    EditForm,
    PageMeta,
    setInputsToDefault,
    Signboard, updateFormHandler,
    useCreateStates, useScript, useUpdateStates,
} from "../../config/imports";
import {useEffect, useState} from "react";
import $ from 'jquery';
import {forEach} from "react-bootstrap/ElementChildren";
import objectHelpers from "../../helpers/object.helpers"
const SuperUpdate = (props) => {
    const {script , table, setMainData, mainTitle, formHeader, api, toggle, item = {} , others} = props;

    const MAIN_TITLE = mainTitle;

    const FORM_HEADER = formHeader;

    if(others && others.formatData){
        others.formatData(item)
    }
    const {data, setData} = useUpdateStates({table, item});
    // console.log(data)
    // console.log(objectHelpers.reformDate(data))

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        // if(others&&others.hasImage) others.hasImage({data , setData})
        // if(others && others.formatSuperUpdateData) others.formatSuperUpdateData({data , setData})
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        if(others&&others.hasImage) others.hasImage({data , setData})

        const update_props = {
            url: api,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
        if(others && others.formatSuperUpdateData) others.formatSuperUpdateData(update_props)
        console.log(update_props)

        // await updateFormHandler(update_props);
    };
    useScript( script  );
    let modifiedData
useEffect(()=>{
    if(data){
        console.log(data)
        data._id = item._id || item.id
        modifiedData = {...objectHelpers.formatDateForInput(data)}
        setData({...modifiedData})
    }
},[])

    useEffect(() => {
      if(others && others.useEffectScript)
          others.useEffectScript(item)
    })
    return (
        <>
            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>{FORM_HEADER}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                {createInputGenerator(table, data)}

            </EditForm>
        </>
    );
};

export default SuperUpdate;
