import { InputSelect, PageMeta, QuickViewContainer, Signboard, useFetch, useState } from "../../config/imports";
import "../../styles/invoices.styles.scss";
import { useEffect, useMemo, useRef } from "react";
import { invoices_api, operators_api, projects_api, stock_api } from "../../config/apis";
import BuyInvoice from "./buy/BuyInvoice";
import BuyInvoices from "./buy_invoices";
import SelectInvoice from "./select_invoices_components";
import SmallInvoicesList from "./SmallInvoicesList";
import { hasValue, hasValues } from "../../helpers/helpers";
import { setBreadCrumbsStore } from "../../components/breadcrumbs/store/breadcrumb.store";
import { homeBreadCrumb, invoicesBreadCrumb } from "../../components/breadcrumbs/data/breadcrumbs.data";

const InvoicesIndex = () => {

    const warehouseFeedRef = useRef();
    const warehouseDrugRef = useRef();
    const warehouseMaintenanceRef = useRef();
    const warehouseOtherRef = useRef();
    const projectFeedRef = useRef();
    const projectDrugRef = useRef();
    const projectMaintenanceRef = useRef();
    const projectOtherRef = useRef();
    const buyEggRef = useRef();
    const warehouseDishesRef = useRef();
    const warehouseCartonsRef = useRef();

    const [isCreateInvDisabled , setIsCreateInvDisabled ] = useState(true);
    const [invClassification, setInvClassification] = useState('')


    
  useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            invoicesBreadCrumb,
        ])
  } , [])





    const [baseInv, setBaseInv] = useState({
        classification: "",
        type: "شراء اعلاف",
        whereToEnter: "",
        project_id: "",
        warehouse_id: "",
        department_id: "",
        buy_inv_project_id : '',
    })

    const { data : stock_names, isLoading : stock_names_isLoading,error : stock_names_error } = useFetch( stock_api.base );

    const { data : operators_options,    isLoading : operators_options_isLoading,error : operators_options_error,} = useFetch( operators_api.names );

    const { data : projects_options,    isLoading : projects_options_isLoading,    error : projects_options_error, } = useFetch( projects_api.names );


    const { data : invoices , alert  , setRefresh : refreshInvoices , isLoading : loadingInvoices} = useFetch(invoices_api.invoices());


    const SignboardProps = {
        error : stock_names_error  || projects_options_error  || operators_options_error,
        loading :  stock_names_isLoading || projects_options_isLoading || operators_options_isLoading,
    }


    useEffect(() => {

        if(baseInv.classification === 'فاتورة شراء'){
            
            if(!hasValue(baseInv.whereToEnter))  setIsCreateInvDisabled(true);
            else setIsCreateInvDisabled(false);
            if(baseInv.whereToEnter === 'مشروع'){
                if(
                    !hasValue(baseInv.project_id) ||
                    !hasValue(baseInv.department_id) ||
                    !hasValue(baseInv.classification) ||
                    !hasValue(baseInv.type) || 
                    !hasValue(baseInv.whereToEnter)
                  ) {
                    // console.log({
                    //     project_id : hasValue(baseInv.project_id),
                    //     department_id : hasValue(baseInv.department_id),
                    //     classification : hasValue(baseInv.classification),
                    //     type : hasValue(baseInv.type),
                    //     whereToEnter : hasValue(baseInv.whereToEnter),
                    // })
                      setIsCreateInvDisabled(true);
                      return
                  }
            } else if(baseInv.whereToEnter === 'مستودع'){

                if(!hasValue(baseInv.warehouse_id) ||
                 !hasValue(baseInv.classification) ||
                  !hasValue(baseInv.type) ||
                   !hasValue(baseInv.whereToEnter)){
                    return  setIsCreateInvDisabled(true);
                    
                }
            }



         }else {
                const isDisabled = !hasValues([baseInv.classification , baseInv.buy_inv_operator_id, baseInv.buy_inv_type , baseInv.buy_inv_project_id])
                // console.log({isDisabled , baseInv})
                setIsCreateInvDisabled(isDisabled)


         }






    } , [baseInv]);

    


    const [projectDepartments,setProjectDepartments] = useState([])


    const selectedProjectDepartment = (_id) => {
        // console.log(baseInv.classification === "فاتورة بيع");
        if(baseInv.classification === "فاتورة بيع") return {
            departments : "" ,
            project : "" ,
        }
         const selectedProject =
             projects_options && projects_options
                 .filter( project => project._id === _id.toString() )[0]
        return {
            departments : selectedProject.departments ,
            project : selectedProject,
         }
    }
    // console.log({baseInv})

    const handlers = {
        shared : {
            change : (e) => {
                const {name, value} = e.target;
                // console.log({name , value})
                setBaseInv({...baseInv, [name]: value});
            },
            whereToEnter : (e) => {
                
                const { name, value } = e.target;
            
                setBaseInv({...baseInv, [name]: value});

            },
            warehouseChange : (e) => {
                const { value } = e.target;
                baseInv.warehouse_id = value
                setBaseInv({ ...baseInv });
                if(
                    baseInv.warehouse_id !== '' &&
                    baseInv.warehouse_id.length !== 0
                ){
                    setIsCreateInvDisabled(false);
                }else {
                    setIsCreateInvDisabled(true);
                }
            },
            invTypeChange : (e) => {
                const {value} = e.target;
                // console.log(value)
                if (value !== '' || value == null) {
                    setBaseInv({...baseInv, classification: value});
                    return setInvClassification(value);
                }
                else return setInvClassification(value);

            },
            create :  () => {

                    // console.log(baseInv)

                    

                    const warehouseSellInv = {
                        classification: baseInv.classification,
                        type: baseInv.type,
                        whereToEnter: baseInv.whereToEnter,
                        warehouse_id: baseInv.warehouse_id,
                    }

                    const projectSellInv = {
                        classification: baseInv.classification,
                        type: baseInv.type,
                        whereToEnter: baseInv.whereToEnter,
                        project_id: baseInv.project_id,
                        department_id: baseInv.department_id,
                        currentDepartmentHomes : baseInv.currentDepartmentHomes,
                    }

                    const buyInv = {
                        classification : baseInv.classification,
                        type : baseInv.buy_inv_type,
                    }


                   if(baseInv.classification === "فاتورة شراء"){
                       if(baseInv.whereToEnter === 'مشروع'){

                         

                           switch (projectSellInv.type) {

                            //    case 'شراء ادوية': return projectDrugRef.current.toggle();

                               case 'شراء اعلاف': return projectFeedRef.current.toggle();

                            //    case 'فاتورة صيانة': return projectMaintenanceRef.current.toggle();

                            //    case 'مصروفات اخرى': return projectOtherRef.current.toggle();

                               default: return projectFeedRef.current.toggle();

                           }
                       }else{
                           switch (warehouseSellInv.type) {

                               case 'شراء ادوية': return warehouseDrugRef.current.toggle();

                               case 'شراء اعلاف': return warehouseFeedRef.current.toggle();

                               case 'فاتورة صيانة': return warehouseMaintenanceRef.current.toggle();

                               case 'مصروفات اخرى': return warehouseOtherRef.current.toggle();

                               case "كراتين" : return warehouseCartonsRef.current.toggle();

                               case "اطباق" : return warehouseDishesRef.current.toggle();

                               default: return warehouseFeedRef.current.toggle();
                           }
                       }
                   }else {
                       switch (buyInv.type) {

                           case  "فاتورة بيض" : return buyEggRef.current.toggle();

                           default : return buyEggRef.current.toggle();

                       }

                   }
            }

        },
        sell : {
            project :   (e) => {
                const {value , name} = e.target;
                setBaseInv( prev =>{
                    return {
                        ...prev,
                        [name]: value
                    }
                })
                const {departments} = selectedProjectDepartment(value)
                setProjectDepartments(departments)
            },
            department : (e) => {
                const {value} = e.target;

                const {project : currentProject} = selectedProjectDepartment(baseInv.project_id)

                const selectedDepartment = currentProject.departments.filter( dep => dep._id === value )[0]

                const currentDepartmentHomes = selectedDepartment && selectedDepartment.homes

                baseInv.currentDepartmentHomes = currentDepartmentHomes || []
                baseInv.department_id = value

                setBaseInv({ ...baseInv })

                if(
                    baseInv.department_id !== '' &&
                    baseInv.department_id.length !== 0
                ){

                    setIsCreateInvDisabled(false);
                }else {
                    setIsCreateInvDisabled(true);
                }


            }

        },
        buy : {
            department : (e) => {

                const {value : department_id} = e.target;

                const { project  } = selectedProjectDepartment(baseInv.buy_inv_project_id)

                const homes = project.departments.filter( dep => dep._id === department_id )[0].homes

                baseInv.buy_inv_department_id = department_id

                baseInv.buy_inv_homes = homes || []

                setBaseInv({ ...baseInv })

            } ,
            home : e =>{
                const {value} = e.target;

                baseInv.buy_inv_home_id = value

                setBaseInv({ ...baseInv })

                const  {
                    buy_inv_project_id : project_id ,
                    buy_inv_department_id  : department_id ,
                    buy_inv_home_id : home_id
                } = baseInv
                if(
                    project_id !== '' &&
                    project_id.length !== 0 &&
                    department_id !== '' &&
                    department_id.length !== 0 &&
                    home_id !== '' &&
                    home_id.length !== 0
                ){
                    setIsCreateInvDisabled(false);
                }else {
                    setIsCreateInvDisabled(true);
                }

            }
        }
    }


    const stockName = useMemo(()=>{
        return stock_names?.filter( stock => stock._id === baseInv.warehouse_id )[0]?.name
    } , [ baseInv.warehouse_id ])


    const views = [
        {
            ref: warehouseFeedRef,
            component : <BuyInvoices.WFeed setRefresh={refreshInvoices} warehouse={baseInv.warehouse_id} stockId={baseInv.warehouse_id} stockName={stockName}    />
        },
        {
            ref : warehouseMaintenanceRef ,
            component :<BuyInvoices.Maintenance setRefresh={refreshInvoices}  warehouse={baseInv.warehouse_id} invoiceFor={'warehouse'} stockName={stockName}  stockId={baseInv.warehouse_id}  />
        },
        {
            ref : warehouseDrugRef ,
            component : <BuyInvoices.Drugs setRefresh={refreshInvoices}  warehouse={baseInv.warehouse_id} invoiceFor={'warehouse'}    stockName={stockName } stockId={baseInv.warehouse_id}  />
        },
        {
            ref : warehouseOtherRef ,
            component : <BuyInvoices.Other setRefresh={refreshInvoices}  warehouse={baseInv.warehouse_id} department={baseInv.department_id}  stockName={stockName } stockId={baseInv.warehouse_id}  />
        },
        {
            ref : warehouseCartonsRef ,
            component : <BuyInvoices.Cartons setRefresh={refreshInvoices}  warehouse={baseInv.warehouse_id} department={baseInv.department_id}  stockName={stockName } stockId={baseInv.warehouse_id}  />
        },
        {
            ref : warehouseDishesRef ,
            component : <BuyInvoices.Dishes setRefresh={refreshInvoices}  warehouse={baseInv.warehouse_id} department={baseInv.department_id}  stockName={stockName } stockId={baseInv.warehouse_id}  />
        },
        {
            ref : projectFeedRef ,
            component : <BuyInvoices.Feed  setRefresh={refreshInvoices}   project={baseInv.project_id} department={baseInv.department_id} invoiceFor={'project'}/>
        },
        {
            ref : projectMaintenanceRef ,
            component : <BuyInvoices.Maintenance   setRefresh={refreshInvoices}  project={baseInv.project_id} department={baseInv.department_id}  />
        },
        {
            ref : projectDrugRef ,
            component : <BuyInvoices.Drugs   setRefresh={refreshInvoices}  project={baseInv.project_id} department={baseInv.department_id}  />
        },
        {
            ref : projectOtherRef ,
            component : <BuyInvoices.Other   setRefresh={refreshInvoices}  project={baseInv.project_id} department={baseInv.department_id}  />
        },
        {
            ref : buyEggRef ,
            component : <BuyInvoice invoice={baseInv} setRefresh={refreshInvoices} />
        }

    ]



    return (
        <div className="invoices bg-white r-16 mt-3">

        <QuickViewContainer views={views}  sized={true}/>

        <Signboard {...SignboardProps} />

        <PageMeta title="إدارة الفواتير"/>

        <div className="form-container">
            <header>
                <h3> انشاء فاتورة </h3>
                <p>حدد مكان ونوع الفتورة ثم انشاء فاتورتك </p>
            </header>

        {/* TODO : needs refresh after submitting invoice */}
            <div className="row index-inv-container">
                <InputSelect
                    title={"تصنيف الفاتورة"}
                    handleInputChange={handlers.shared.invTypeChange}
                    className={"col-12 mb-3 col-md-12 col-lg-12 col-sm-12"}
                    name="classification"
                    options={[
                        {_id: "فاتورة شراء", name: "فاتورة شراء"},
                        {_id: "فاتورة بيع", name: "فاتورة بيع"},
                    ]}
                />
            </div>

            {
                invClassification === "فاتورة شراء" && (
                    <SelectInvoice.Sell handlers={handlers} baseInv={baseInv} projects={projects_options} departments={projectDepartments} warehouses={stock_names}/>
                )
            }
            {
                invClassification === "فاتورة بيع" &&(
                    <SelectInvoice.Buy handlers={handlers} baseInv={baseInv} projects={projects_options} departments={projectDepartments} operators={operators_options}/>
                )
            }

            <div className="row justify-content-center mt-3">
                <div className="col-12 col-sm-12 col-lg-3 col-md-4 flex justify-center">
                    <button className="add-btn whitespace-nowrap" disabled={isCreateInvDisabled} onClick={handlers.shared.create}>
                         انشاء فاتورة 
                         {
                            baseInv.classification == "فاتورة شراء" && <span className=" text-white"> شراء</span>
                         }
                         {
                            // baseInv.type === "buy" && <span className=" text-white"> بيع</span>
                            // console.log({baseInv})
                            baseInv.classification == "فاتورة بيع" && <span className=" text-white"> بيع</span>
                         }
                    </button>
                </div>
            </div>
        </div>
            <div className="invoices-table"><SmallInvoicesList data={invoices}  setRefresh={refreshInvoices} loading={loadingInvoices} /></div>
        </div>
    );
};export default InvoicesIndex;