const OrderStatusTypes = {
     'completed':  <span className="status-done">مكتمل</span> ,
     'approved':  <span className="status-done">مكتمل</span> ,
     'pending':    <span className="status-pending">قيد المراجعه</span> ,
     'rejected':   <span className="status-rejected">مرفوض</span> ,
     'under_purchase':  <span className="status-pending">قيد الشراء</span> ,
}

export {
    OrderStatusTypes
}