import Create from './Create';
import Update from './Update';
import Delete from './Delete';

const drugsOrderCrud = {
    Create,
    Update,
    Delete
}
export default drugsOrderCrud
