import React from 'react'
import Table from '../../../layout/table/Table';



// {
//   "_id": "66505b3e332bd68fb1db2e57",
//   "location": "home",
//   "location_id": "66505b3e332bd68fb1db2e56",
//   "maximum_feed_capacity": 100000000,
//   "default_feed_unit": "Ton",
//   "maximum_drug_capacity": 100000000,
//   "default_drug_unit": "Piece",
//   "maximum_other_capacity": 100000000,
//   "default_other_unit": "Piece",
//   "maximum_maintenance_capacity": 100000000,
//   "default_maintenance_unit": "Piece",
//   "maximum_carton_capacity": 100000000,
//   "default_carton_unit": "Piece",
//   "maximum_dish_capacity": 100000000,
//   "default_dish_unit": "Piece",
//   "maximum_birds_capacity": 20000,
//   "createdAt": "2024-05-24T09:17:50.712Z",
//   "updatedAt": "2024-05-24T09:17:50.712Z"
// }




export default function HomeStorageDetails({data}) {



  const table = [
    { name : "maximum_feed_capacity" , text : "اقصى استيعاب للأعلاف" , size : 4 },
    { name : "default_feed_unit" , text : "وحدة الأعلاف" , size : 4   , custom : d =>   d?.default_feed_unit_ar_unit || d?.default_feed_unit  },
    { name : "maximum_drug_capacity" , text : "  اقصى استيعاب للأدويه" , size : 4 },
    { name : "default_drug_unit" , text : "وحدة الدواء" , size : 4   , custom : d =>   d?.default_drug_unit_ar_unit || d?.default_drug_unit  },
    { name : "maximum_other_capacity" , text : "   اقصى استيعاب للمواد الاخرى" , size : 4 },
    { name : "default_other_unit" , text : "وحدة المواد الاخرى" , size : 4   , custom : d =>   d?.default_other_unit_ar_unit || d?.default_other_unit  },
    // { name : "maximum_birds_capacity" , text : "  اقصى استيعاب للطيور" , size : 4 },

  ]

  const table2 = [
    { name : "maximum_maintenance_capacity" , text : " اقصى استيعاب للصيانة" , size : 4 },
    { name : "default_maintenance_unit" , text : "وحدة الصيانة" , size : 4   , custom : d =>   d?.default_maintenance_unit_ar_unit || d?.default_maintenance_unit  },
    { name : "maximum_carton_capacity" , text : " اقصى استيعاب للكراتين " , size : 4 },
    { name : "default_carton_unit" , text : "وحدة الكرتون" , size : 4   , custom : d =>   d?.default_carton_unit_ar_unit || d?.default_carton_unit  },
    { name : "maximum_dish_capacity" , text : "  اقصى استيعاب للأطباق" , size : 4 },
    { name : "default_dish_unit" , text : "وحدة الاطباق" , size : 4   , custom : d =>   d?.default_dish_unit_ar_unit || d?.default_dish_unit  },
  ]


  return data && data.storage && (
    <>
    <div className="page   ">


      <Table table={table} data={[data.storage]} />

      <Table table={table2} data={[data.storage]} />

    </div>
    
    </>
  )
}
