// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-table::-webkit-scrollbar {
  width: 10px;
}
.data-table::-webkit-scrollbar-track {
  background-color: #f4f4f4;
  border-radius: 100px;
}
.data-table::-webkit-scrollbar-thumb {
  background-color: #999CA1;
  border-radius: 100px;
}`, "",{"version":3,"sources":["webpack://./src/styles/tableView.styles.scss"],"names":[],"mappings":"AAEI;EACE,WAAA;AADN;AAGI;EACE,yBAAA;EACA,oBAAA;AADN;AAGI;EACE,yBAAA;EACA,oBAAA;AADN","sourcesContent":[".data-table{\n\n    &::-webkit-scrollbar {\n      width: 10px;\n    }\n    &::-webkit-scrollbar-track {\n      background-color: #f4f4f4;\n      border-radius: 100px;\n    }\n    &::-webkit-scrollbar-thumb {\n      background-color: #999CA1;\n      border-radius: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
