import { create } from "zustand";
import { uuid } from "../../../helpers/id.helper";


const formatAfterUpdate = (data) => {
    let _obj = {
        homes_ids : [],
        departments_ids : [],
        projects_ids : []
    }

    data.forEach(item => {
        _obj[item.type] = [..._obj[item.type] , item._id]
    })
    
    return _obj

}








const useEggsWarehouseStore = create((set) => ({
    rendered : false,
    selected : [],
    formatted : {
        projects_ids : [],
        departments_ids : [],
        homes_ids : []
    },
    submit : uuid(),

    setSubmit : ()=> set(state => ({...state , submit : uuid()})),

    setFilter : filter => set (state =>{
        return { ...state , formatted : filter , rendered : true }
    }),


    setSelected : (selected) => set({ selected }),
    clearFilter : () => set((state) => ({ 
        ...state , 
        selected : [] ,
        submit : uuid(),
        formatted : {
                projects_ids : [],
                departments_ids : [],
                homes_ids : []
        }
 })),

    addToSelectedItems : (id , type , name) => set((state) =>{

        const doesItemExist = state.selected.find(item => item._id === id)

        if(doesItemExist) return

        // console.log({d :  formatAfterUpdate()});

        const newData =  [...state.selected , {_id : id , type , name}]

        return { selected : newData , formatted : formatAfterUpdate(newData) }
        
    }),

    removeFromSelectedItems : (id) => set((state) => {
        const newData = state.selected.filter(item => item._id !== id)
        return ({ selected : newData , formatted : formatAfterUpdate(newData) })
    }),



    clearProjectFilter : (project_id) => set((state) => {

 
        return ({ ...state , selected : [] , formatted :  { 
            projects_ids : [project_id],
            departments_ids : [],
            homes_ids : []
        } })


    }),
    clearDepartmentFilter : (department_id) => set((state) => {
 
        return ({ ...state , selected : [] , formatted :  { 
            projects_ids : [],
            departments_ids : [department_id],
            homes_ids : []
        } })
    }),


    addToSelectedProjectedItems : (id , type , name) => set((state) =>{

        const doesItemExist = state.selected.find(item => item._id === id)

        if(doesItemExist) return

        // console.log({d :  formatAfterUpdate()});

        const newData =  [...state.selected , {_id : id , type , name}]


        const newFormatted = {
            ...formatAfterUpdate(newData),
            projects_ids : []
        }

        console.log("newFormatted project" , newFormatted);

        return { selected : newData , formatted : newFormatted }
        
    }),


    addToSelectedDepartmentItems : (id , type , name) => set((state) =>{

        const doesItemExist = state.selected.find(item => item._id === id)

        if(doesItemExist) return

        // console.log({d :  formatAfterUpdate()});

        const newData =  [...state.selected , {_id : id , type , name}]


        const newFormatted = {
            ...formatAfterUpdate(newData),
            projects_ids : [],
            departments_ids : []
        }


        return { selected : newData , formatted : newFormatted }
        
    }),










}))


export default useEggsWarehouseStore