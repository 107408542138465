import React, { useEffect, useRef } from 'react'
import useStock from '../useStock'
import QuickView from '../../../components/QuickView'
import CreateTransfer from '../forms/CreateTransfer'
import PageMeta from '../../../components/PageMeta'
import { TableHeader } from '../../../components/TableView'
import { Table } from '../../../layout/table'
import { useParams } from 'react-router-dom'
export default function FeedTab() {
  const { id } = useParams()
  const createRef = useRef()
  const {   Alert   , setRefresh   ,fetchQuantities  , quantities , quantitiesTable } =  useStock()
  // const {data} = usePost( apis.storages.quantities() , {
  //     category : "feed",
  //     location_id : useParams().id  ,
  //     location : "stock"
  // }  )

  // console.log({data})
  useEffect(() => {
    fetchQuantities({ category :  "feed", location_id : id, location :  "stock" })
  } , [])
  return (
    <>
         <QuickView ref={createRef}  className="overflown-popup"><CreateTransfer header="اعلاف" type="feed" setRefresh={setRefresh} /></QuickView>
          <div className='page base-card'>
          <Alert />
          <PageMeta title=" الاعلاف " />
          <TableHeader 
          // buttons={[{ title : "إضافة حركه اعلاف جديده" , onclick : () => createRef.current.toggle() }]}
           />
          <Table data={quantities?.totals_by_category_and_subcategory} table={quantitiesTable} />
        </div>
    </>
  )
}
