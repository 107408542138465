import {PageMeta, Signboard, useFetch, _date, Icons, TableHeader, QuickView,} from "../../../../config/imports";
import React, {useMemo, useRef} from "react";
import {Table} from "../../../../layout/table";

import {apis} from "../../../../config/apis";
import Helpers from "../../../../helpers/helpers";
import IncubatorsCrud from "./crud";
import HatcheriesCrud from "./crud";

const HatcheriesIndex = () => {
  const createRef = useRef();
  const updateRef = useRef();
  const deleteRef = useRef();

  const api = apis.origins.incubators.paginate()

  const { data  ,  error, isLoading , paginate , setters } = useFetch(api );

  const signboard = {
    error ,
    loading : isLoading
  }


  const incubatorsData = useMemo(()=>{
    if(typeof data === 'string') return []
    return data?.filter((item)=>{
      return item.type === 'hatchery'
    })
  } , [data])


  const buttons = [
    {
      title: "إضافة فقاسه",
      onclick: () => createRef?.current?.toggle(),
    },
  ];


  const table = [
    {size : 1 , name : "#" , text : "#"  },
    {size : 2 , name : "brand" , text : "الماركه"  },
    {size : 2 , name : "area" , text : "المساحه"  },
    {size : 2 , name : "location" , text : "الموقع"  },
    {size : 3 , name : "trolleys_type" , text : "نوع التروليات" , },
    {size : 2 , name : 'trolleys_type_count' , text : '  عدد النوع ' },
    {size : 4 , name : 'trolleys_type_eggs' , text : 'عدد البيض في النوع ' ,},
    {size : 3 , name : 'trolleys_count' , text : 'عدد  التروليات ' },
    {size : 3 , name : 'total_eggs' , text : 'مجموع البيض' },
    {size : 2 , name : '' , text : '' , }
  ]


  return (
    <div className="bg-white r-16 p-3 incubator mb-5">
      <PageMeta title=" الفقاسات" />
      <QuickView ref={createRef} ><HatcheriesCrud.Create setMainData={setters.data}/></QuickView>
      <QuickView ref={updateRef} ><HatcheriesCrud.Update setMainData={setters.data}/></QuickView>
      <QuickView ref={deleteRef} ><HatcheriesCrud.Delete setMainData={setters.data}/></QuickView>
      <TableHeader buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />
      <Signboard {...signboard}/>
      <div className="pt-5"></div>
      <Table table={table} data={incubatorsData}  updateRef={updateRef} deleteRef={deleteRef} className={'mt-5'} />
    </div>
  );
};

export default HatcheriesIndex;
