// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  position: relative;
}
.Span:last-child {
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/styles/grid.styles.scss"],"names":[],"mappings":"AAOE;EACE,gBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;AANJ;AAOI;EACI,iBAAA;AALR","sourcesContent":[".Span{\n  //background-color: #fff000;\n}\n//.data-row{\n//  *{\n//    //outline: 1px solid teal;\n//  }\n  .Span{\n    overflow : hidden;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    position: relative;\n    &:last-child{\n        overflow: visible;\n    }\n\n  }\n\n//}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
