import { NavLink, Outlet } from "react-router-dom";

const LinkList = ({ underline, data, setComponent , className }) => {
  // const handleLinkClick = (e, component) => {
  //   const parent = e.target.closest(".custom-buttons-container");
  //   const childrens = parent.querySelectorAll("a");
  //   childrens.forEach((child) => {
  //     child.setAttribute("class", "");
  //   });
  //   e.target.classList.add("active");

  //   // setComponent(component);
  // };

  const links = data.map((btn) => (
    <NavLink
      // onClick={(e) => handleLinkClick(e, btn.component)}
      to={btn.path}
      key={btn.name}
      title={btn.name}>
      {btn.name}
    </NavLink>
  ));

  return (
    <>
      <div
        className={
          underline && underline === true
            ? "custom-underline-buttons custom-buttons-container " + className ?? ""
            : " custom-buttons-container " + className ?? ""
        }>
        {links}
      </div>

      <Outlet />
    </>
  );
};

const RouterLinks = ({ underline, data, setComponent }) => {
  // const handleLinkClick = (e, component) => {
  //   const parent = e.target.closest(".custom-buttons-container");
  //   const childrens = parent.querySelectorAll("a");
  //   childrens.forEach((child) => {
  //     child.setAttribute("class", "");
  //   });
  //   e.target.classList.add("active");

  //   // setComponent(component);
  // };

  const links = data.map((btn) => (
    <NavLink
      // onClick={(e) => handleLinkClick(e, btn.component)}
      to={btn.path}
      key={btn.name}
      title={btn.name}>
      {btn.name}
    </NavLink>
  ));

  return (
    <>
      <div
        className={
          underline && underline === true
            ? "custom-underline-buttons custom-buttons-container"
            : " custom-buttons-container"
        }>
        {links}
      </div>


      <div className="base-card">
      <div className="ilc mt-2 bg-white px-3 py-3 r-16">
        <div className="icbc">
          <div className="base-card-links-container">
      <Outlet />
            {/* {<RouterLinks data={ITEMS_CARDS_LINKS} />} */}
          </div>
        </div>
      </div>
    </div>
    </>
  );
};








export default LinkList;
export {
  RouterLinks
}