import {paths} from '../../config/configs.env';



const salariesAPI = {
    url : paths.api + '/salaries',
    employees : paths.api + '/employees',
    salaries : paths.api + '/employees/salary',
    profile : (id) => salariesAPI.url + '/employee/info/' + id,
    all : () =>  salariesAPI.employees + '/landingPage'
}


export default salariesAPI;
