import React, { useEffect } from "react";
import useSelectInvoiceStore from "../stores/select-invoice.store";
import { InputSelect } from "../../../config/imports";

export default function InputStockInvoices() {


  const fetchStocks = useSelectInvoiceStore((state) => state.fetchStocks);
  const stocks = useSelectInvoiceStore((state) => state.stocks);
  const stock = useSelectInvoiceStore((state) => state.stock);
  const setStock = useSelectInvoiceStore((state) => state.setStock);



  useEffect(() => {
    fetchStocks()
  }, []);

 
  

  return (
    <InputSelect
      title={"اختر المستودع"}
      className={"col-12"}
      handleInputChange={(e) => setStock(e.target.value)}
      name="type"
      defaultValue={stock}
      options={stocks}
    />
  );
}
