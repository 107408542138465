import RouterHelpers from "../../../helpers/router.helper"
import Feed from "../items_cards/Feed"
import Packaging from "../items_cards/Packaging"
import Drug from "../items_cards/Drug"
import Maintenance from "../items_cards/Maintenance"
import ItemCard from "../items_cards/ItemCard"
import { Route } from "react-router-dom"
import EggStorageUnitsIndex from "./EggStorageUnitsIndex"
import CartoonDetails from "./CartoonDetails"

const egg_storage_units_routes = {
    router : (protect)=>{
        return <Route path="cartoons"  >
                    <Route index element={protect(<EggStorageUnitsIndex />)} />
                    <Route path="details/:id" element={protect(<CartoonDetails />)} />
                    {/* <Route path="feed" element={protect(<Feed />)} />
                    <Route path="drug" element={protect(<Drug />)} />
                    <Route path="packaging" element={protect(<Packaging />)} />
                    <Route path="maintenance" element={protect(<Maintenance />)} /> */}
               </Route>
    }
}


export default egg_storage_units_routes