import {useState} from "react";
import {apis, reports_api, weight_api} from "../../../../config/apis";
import {setInputsToDefault, updateFormHandler} from "../../../../helpers/form.helper";
import {
    EditForm, InputNumber,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../config/imports";

import ArrayHelpers from "../../../../helpers/array.helpers";
import Grid from "../../../../layout/grid";

export default function UpdateInitBalance(props){

    const {toggle , item , setMainData , operators } = props

    const  [initData, setInitData] = useState(null)
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let [data, setData] = useState({
        // year :
        year : item.year,
        weights : item.weights
    })


    console.log({id : item._id})
    const handleInputChange = async (e , idx) => {

        const {name , value} = e.target

        const weight = data.weights[idx]

        weight[name] = +value

        setData({...data})

    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.balances.init.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
        console.log(update_props.data)


        await updateFormHandler(update_props);
    };

    // console.log({data})
    // console.log({item})
    return(
        <div className="opening-balance-form">
            <PageMeta title={` تعديل الرصيد الافتتاحي لعام ${item.year}  ؟  `} originTitle={'الارصدة الافتتاحيه'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>تعديل الرصيد الافتتاحي لعام {item.year}  ؟</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <>
                    <Grid.Flex  className="data-table-head">
                        <Grid.Span size={8} > الوزن </Grid.Span>
                        <Grid.Span size={8} >السعر</Grid.Span>
                        <Grid.Span size={8} >الكمية </Grid.Span>
                    </Grid.Flex>
                    {
                        data && data.weights?.length > 0 && data.weights?.map((item , index) => {
                            return (
                                <Grid.Flex key={index} className="data-row align-items-center">
                                    <Grid.Span size={8} > {item?.weight.name} </Grid.Span>
                                    <Grid.Span size={8} ><InputNumber required={false} handleInputChange={(e) => handleInputChange(e , index)} name={'price'} placeholder='0' defaultValue={item.price} className='w-100' /></Grid.Span>
                                    <Grid.Span size={8} ><InputNumber required={false} handleInputChange={(e) => handleInputChange(e , index)} name={'amount'} placeholder='0' defaultValue={item.amount} className='w-100' />  </Grid.Span>
                                </Grid.Flex>
                            )
                        })
                    }
                </>
            </EditForm>
        </div>
    )
}
