import PageMeta from "../../../../../components/PageMeta";

import RouterLinks from "../../../../../components/LinkList";
import useNavigator from "../../../../../hooks/useNavigator";

const BirdsDrugConsumption = () => {
    const DRUGS_CONSUMPTION_LINKS = [
        {
            name: "قائمة فواتير شراء الادوية و التحصينات",
            path: "drugs-invoices",
        },
        {
            name: "تقارير الاستهلاك",
            path: "drugs-consumption",
        },
        {
            name: "قائمة حركة الادوية و التحصينات",
            path: "drugs-transportation",
        },
    ];

    useNavigator("drug", "drugs-invoices");

    return (
        <>
            <PageMeta title={"استهلاك الادوية"} />

            {<RouterLinks underline={true} data={DRUGS_CONSUMPTION_LINKS} />}
        </>
    );
};

export default BirdsDrugConsumption;
