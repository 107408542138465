import {
    createFormHandler,
    createInputGenerator,
    EditForm,
    PageMeta,
    setInputsToDefault,
    Signboard,
    useCreateStates, useScript,
} from "../../config/imports";
import {useEffect, useState} from "react";

const SuperCreate = (props) => {
    const {table, setMainData, script, mainTitle, formHeader, api, toggle, others} = props;
    //
    console.log()
    // console.log(props)
    //
    const MAIN_TITLE = mainTitle;

    const FORM_HEADER = formHeader;
    const {data, setData} = useCreateStates(table);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // console.log(data)

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: api,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData: others && others.setCustomMainData ? others.setCustomMainData(setMainData, data) : setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        if (others && others.formatSuperCreateData)
            others.formatSuperCreateData(create_props)

        if (others && others.hasImage)
            others.hasImage({form: e.target, params: create_props})

        console.log(create_props.data)

        await createFormHandler(create_props);
    };


    useScript(script)

    return (
        <>

            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>{FORM_HEADER}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter == undefined ? true : false}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                {createInputGenerator(table, data)}
            </EditForm>
        </>
    );
};

export default SuperCreate;
