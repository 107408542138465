import React, { useEffect, useRef } from 'react'
import useStock from '../useStock'
import QuickView from '../../../components/QuickView'
import PageMeta from '../../../components/PageMeta'
import { TableHeader } from '../../../components/TableView'
import { Table } from '../../../layout/table'
import CreateTransfer from '../forms/CreateTransfer'
import { useParams } from 'react-router-dom'
export default function DishesTab() {
  const { id } = useParams()
  const createRef = useRef()
  const {   Alert   , setRefresh   ,fetchQuantities  , quantities , quantitiesTable } =  useStock()

  useEffect(() => {
    fetchQuantities({ category :  "dish", location_id : id, location :  "stock" })
  } , [])
  
  return (
    <>
         <QuickView ref={createRef}  className="overflown-popup"><CreateTransfer type="dishes" header="اطباق " setRefresh={setRefresh} /></QuickView>
          <div className='page base-card'>
            <Alert />
            <PageMeta title=" الاطباق " />
            <TableHeader
            //  buttons={[{ title : "إضافة حركه اطباق جديده" , onclick : () => createRef.current.toggle() }]}
              />
                <Table data={quantities?.totals_by_category_and_subcategory} table={quantitiesTable} />

        </div>
    </>
  )
}
