import
{
    PageMeta,
    EditForm,
    InputTextArea,
    InputDate, useState, Signboard,
    createFormHandler,
    setInputsToDefault, useParams, InputNumber
} from "../../../config/imports"
import {employees_api} from "../../../config/apis";
import {useEffect} from "react";

const ProfileCreateForms = (props) => {
    const {id} = useParams()
    const { toggle  , mainTitle , updateMain }  = props


    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        employee_id : id,
        points : ''  ,
        date : '' ,
        info : '' ,
    })

    useEffect(()=>{
        if(success && success.length > 0)
            updateMain(true)
        else updateMain(false)
    } , [success])

    const handleSubmit =  async (e) => {
        setLoading(true)
        e.preventDefault()

        const create_props = {
            data ,
            setError,
            setSuccess,
            setLoading,
            FORM_HEADER : "اضافة التقييم",
            setInputsToDefault,
            url : employees_api.eval(id) ,
            setData,
        }

        // console.log(create_props.data)
        await createFormHandler(create_props)

    }
    const handleChange = e => {
        const {name , value } = e.target
        setData((prevState) => ({
            ...prevState,
            [name]: value
        }))
    }

    return (
        <>
            <PageMeta title={'تقييم الاداء'} originTitle={mainTitle}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{'تقييم الاداء'}</h3>
            </header>
            <Signboard error={error } success={success} loading={loading}/>
            <div className="my-3">
                <EditForm onSubmit={handleSubmit} onChange={handleChange} toggle={toggle}  visible={true}>
                    <InputNumber  name={"points"} className={"col-6"}  title={'النقاط المكتسبه'} />
                    <InputDate  name={"date"} className={"col-6"} title={"تاريخ التقييم"} />
                    <InputTextArea  name={"info"} className={"col-12"} title={"معلومات اضافية"} />
                </EditForm>
            </div>
        </>
    );
}

export default ProfileCreateForms
