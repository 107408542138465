import { create } from 'zustand'
import { apis, operators_api } from '../../../config/apis';
import { accio } from '../../../helpers/axios/axios.get';
import { showErrorToast } from '../../../stores/toast.store';
import { objectHasValues } from '../../../helpers/helpers';



export const useSelectInvoiceStore = create((set) => ({
  classification: "sell",
  whereToEnter : "stock",
  type :  "feed",
  projects : [],
  project : "",
  department : "",
  departments : [],
  stocks : [],
  stock : "",
  operator : "",
  operators : [],
  buyType : "eggs",

  setOperator : (value) => set((state) => ({ ...state, operator: value })),

  setBuyType : (value) => set((state) => ({ ...state, buyType: value })),

  setStock : (value) => set((state) => ({ ...state, stock: value })),

  setDepartment : (value) => set((state) => ({ ...state, department: value })),

  setProject : (value) => set((state) => ({ ...state, project: value })),

  setProjects : (value) => set((state) => ({ ...state, projects: value })),


  fetchProjects  : async () => {
    const response = await accio(apis.projects.names)
    if (response.data) {
        set((state) => ({ ...state, projects: response?.data?.map(project => ({
          _id: project._id,
          name: project.name,
          departments: project.departments
        })) }))
    }else {
      showErrorToast(response.error)
    }
  },


  fetchStocks : async () => {

    const response = await accio(apis.warehouses.base)
    if (response.data) {
        set((state) => ({ ...state, stocks: response?.data?.map(stock => ({
          _id: stock._id,
          name: stock.name
        })) }))
    }else {
      showErrorToast(response.error)
    }

  },
  fetchOperators : async () => {

    const response = await accio(operators_api.names)
    if (response.data) {
        set((state) => ({ ...state, operators: response?.data?.map(stock => ({
          _id: stock._id,
          name: stock.name
        })) }))
    }else {
      showErrorToast(response.error)
    }

  },
  selectedProjectDepartments : () => {
    const _project = useSelectInvoiceStore.getState().project;
    if (!_project) return []

    const _projects = useSelectInvoiceStore.getState().projects
    const _projectIndex = _projects.findIndex(p => p._id === _project)
    if (_projectIndex === -1) return []
    return set((state) => ({ ...state, departments: _projects[_projectIndex]?.departments?.map(dep => ({ _id: dep._id, name: dep.name })) }))
    
  },
  setType : (value) => set((state) => ({ ...state, type: value })),
  localeType: () => {
    const _type = useSelectInvoiceStore.getState().type;
    if (_type === "feed") return "شراء اعلاف";
    else if (_type === "drug") return "شراء ادوية";
    else if (_type === "maintenance") return "فاتورة صيانة";
    else if (_type === "other") return "مصروفات اخرى";
    else if (_type === "carton") return "كراتين";
    else if (_type === "dish") return "اطباق";
  },



  setWhereToEnter : (value) => set((state) => ({ ...state, whereToEnter: value , type :  "feed" })),
  localeWhereToEnter: () => {
    const _whereToEnter = useSelectInvoiceStore.getState().whereToEnter;
    if (_whereToEnter === "stock") return "مستودع";
    else return "مشروع";
  },

  setClassification: (value) =>
    set((state) => ({ ...state, classification: value })),
  localeClassification: () => {
    const _classification = useSelectInvoiceStore.getState().classification;
    if (_classification === "sell") return "فاتورة بيع";
    else return "فاتورة شراء";
  },

  hasAllSelected: () => {
    const _project = useSelectInvoiceStore.getState().project;
    const _department = useSelectInvoiceStore.getState().department;
    const _type = useSelectInvoiceStore.getState().type;
    const _whereToEnter = useSelectInvoiceStore.getState().whereToEnter;
    const _stock = useSelectInvoiceStore.getState().stock;
    const _classification = useSelectInvoiceStore.getState().classification;


     if(_classification !== "" && _classification === "buy"){
        if(_whereToEnter === "stock"){
          return objectHasValues({ _classification ,  _type, _whereToEnter , _stock })
        }else {
          return objectHasValues({ _classification ,  _type, _whereToEnter , _project , _department })
        }
     }else if(_classification !== "" && _classification === "sell"){
        const _operator = useSelectInvoiceStore.getState().operator;
        const _buyType = useSelectInvoiceStore.getState().buyType;
        return objectHasValues({ _classification ,  _buyType , _project  , _operator  })
     }

    
  }


}));


export default useSelectInvoiceStore