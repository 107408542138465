import React, { useEffect, useState } from 'react'

export default function DisplayFileImage(props) {
    const [src, setSrc] = useState(null);
    
    useEffect(() => {
        if(props.file && props.file instanceof File) 
            setSrc(URL.createObjectURL(props.file))
        else
            setSrc(null)
    } , [props.file])

    const handleClick = (e) => {
        props.handleDelete(props.file)
    }

  return (
    <>
      { src &&  <div className='file-img' onClick={handleClick} data-title={props.file.name} >
                      <img src={src ?? ''} alt={props?.file?.name} />
                </div> 
      }
    </>
  )
}
