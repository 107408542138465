export default function Label(props) {
    const {
        name  , title , placeholder , id
    } = props

    return <>
            <label htmlFor={id ?? name ?? title ?? placeholder}>
                {title}
            </label>
    </>
}