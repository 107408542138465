import React from "react";
import {QuickView} from "../config/imports";
import Helpers from "../helpers/helpers";
import { nanoId } from "../helpers/id.helper";

const QuickViewContainer = props =>{

    const {views , sized} = props


    return <>
        {views &&
                views.map((view , index) => {
                    return(
                        <React.Fragment key={ nanoId() }>
                            <QuickView  ref={view.ref}  sized={sized}>
                                {view.component}
                            </QuickView>
                        </React.Fragment>
                    )
                })
        }
    </>
}


export default QuickViewContainer;