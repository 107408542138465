import CreateHome from "./CreateHome";
import DeleteHome from "./DeleteHome";
import EditHome from "./EditHome";
import EditHomeColor from "./EditHomeColor";

const HomesCrud = {
    Create: CreateHome,
    Edit: EditHome,
    Delete: DeleteHome,
    Color : EditHomeColor
}
export default HomesCrud
