import { useEffect, useMemo, useRef, useState } from "react";
import { reports_api } from "../../../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../../../helpers/form.helper";
import { useParams } from "react-router-dom";
import { _date, EditForm, Grid, InputDate, InputNumber, PageMeta, Signboard, useFetch } from "../../../../../config/imports";
import Map from "../../../../../components/Map";

export default function Create(props){
  const dateRef = useRef();
  const { id } = useParams();
  const { setMainData, toggle, setRefresh } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  const {
    data: homeData,
    error: reportError,
    isLoading: reportLoading,
    setData: setHomeData,
  } = useFetch(reports_api.home.eggs.getHomeEggsReportsForm(id));


  let signboard = {
    error: error || reportError,
    success,
    loading: loading || reportLoading,
  };
  let [data, setData] = useState({});

  useEffect(() => {
    if (homeData) {
      setData({
        date: homeData && _date(new Date(homeData?.date)).forInputDate(),
        home: homeData && homeData?.home,
        cartons: homeData && homeData?.cartons,
      });
    }
  }, [homeData]);

  const weightNames = useMemo(() => {
    return homeData?.cartons[0]?.weights?.map((w) => ({
      name: w.name,
      id: w.weight,
    }));
  }, [homeData]);

  // 

  const handleInputChange = (e, cartonIndex, weight) => {
    setData((prev) => {
      const updatedCarton = prev.cartons;
      updatedCarton[cartonIndex].weights[weight].amount = +e.target.value;
      return {
        home: prev.home,
        date: prev.date,
        cartons: updatedCarton,
      };
    });
  };

  const handleFormSubmit = async (e) => {
    // 
    e.preventDefault();

    const create_props = {
      url: reports_api.home.eggs.createEggReport(),
      data,
      setLoading,
      setError,
      setSuccess,
      setMainData: () => {},
      setRefresh,
      setInputsToDefault,
      setData,
      FORM_HEADER: `الاضافة`,
    };

    // 

    await createFormHandler(create_props);
  };

  const dateHandler = (e) => {
    // 
    setData((prev) => {
      return {
        ...prev,
        date: e.target.value,
      };
    });
  };

  useEffect(() => {
    const inputDate = document.querySelector("#date");
    if (inputDate) {
      inputDate.value = data?.date;
      inputDate.onchange = dateHandler;
    }
  }, []);

  return (
    <>
      <PageMeta title={"اضافة تقرير بيض يومي"} originTitle={"تقارير البيض"} />

      <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
        <h3> اضافة تقرير بيض يومي</h3>
      </header>

      <Signboard {...signboard} />

      {data && (
        <EditForm
          visible={true}
          onSubmit={handleFormSubmit}
          toggle={toggle}
          onChange={handleInputChange}
          // handleInputChange={handleInputChange}
        >
          <>
            <InputDate value={data.date} className={"col-12"} title={"التاريخ"} name={"date"} />
          </>

          <div className="overflow-x-auto">
            <Grid.FlexResponsive className={"align-items-center "}>
              <Grid.Span size={4}> الكارتونه / الاوزان</Grid.Span>
              <Map data={weightNames} map={(item, index) => (
                  <Grid.Span key={index} size={4}>
                    {item.name}
                  </Grid.Span>
                )}
              />
            </Grid.FlexResponsive>

            <Map
              data={data?.cartons}
              map={(carton, cartonIndex) => {
                return (
                  <Grid.FlexResponsive key={cartonIndex} className={"align-items-center "}>
                    <Grid.Span size={4} >
                      {carton.name}
                    </Grid.Span>
                    <Map data={carton?.weights} map={(weight, index) => {
                        if (!weight?.is_connected) {
                            return <div className="span-4  px-1 " key={weight.weight ?? index}>
                              <div className="bg-slate-100 h-[35px]  rounded-md border-border border-[1px]" />
                            </div>;
                        }
                        return (
                          <div key={index} className="span-4 p-0">
                            <InputNumber
                              handleInputChange={(e) => handleInputChange(e, cartonIndex, index) }
                              className={" px-2"}
                              placeholder={"0"}
                              value={weight?.amount}
                              required={false}
                              input={{ className: "!my-0" }}
                              labelProps={{ className: "hidden" }}
                            />
                          </div>
                        );
                      }}
                    />
                  </Grid.FlexResponsive>
                );
              }}
            />
          </div>
        </EditForm>
      )}
    </>
  );
}


