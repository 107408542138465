import {
    createFormHandler,
    EditForm, InputDate, InputNumber, InputText, InputTextArea,
    setInputsToDefault,
    Signboard,
    useState,
    PageMeta
} from "../../../../../../../config/imports";
import {strainAPI} from "../../../../../../origin/bird_breed/bird_breed.routes";
import SelectOperator from "../../../../../../../components/Shared/SelectOperator";


const FORM_HEADER = "طلب شراء اعلاف";
const MAIN_TITLE = "قائمة تقرير علف";

const AddBuyFeedRequest = ({setMainData, toggle}) => {


    const [data, setData] = useState({
        packNo: 0,
        unitprice: 0,
        quantity: 0,
        operator: "",
        date: "",
        type: "",
        measuringUnit: "",
        total: 0,
        totalforhome: 0,
        home: [],
        info: "معلومات",
    });
    let names = Object.keys(data);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const create_props = {
            url: strainAPI.url,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER,
        };

      await  createFormHandler(create_props);
    };

    const handleInputChange = (e) => {
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };

    return (
        <div className="temp-form">
            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>
            <Signboard error={error} success={success} loading={loading}/>
            <EditForm  onSubmit ={handleFormSubmit} toggle={toggle}   onChange={handleInputChange}>
                <InputText  title={"الاسم"}   />
                <SelectOperator />
                <InputText  title={"التصنيف"}  />
                <InputNumber title={"نوع العلف"}  />
                <InputText title={"وحدة القياس"}   />
                <InputNumber name={"quantity"} title={"الكمية المطلوبة"} />
                <InputTextArea title={"ملاحظات"} />
            </EditForm>

        </div>
    );
};

export default AddBuyFeedRequest;
