import {
    _date,
    Grid,
    NavLink,
    PageMeta,
    RightArrow,
    Signboard, Table,
    useFetch,
    useParams
} from "../../../config/imports";
import "../../../styles/billing.styles.scss"

import React, {useEffect , useRef} from "react";
import {employees_api} from "../../../config/apis";

const UserOperations = (props) => {

    const updateRef = useRef(),
        deleteRef = useRef();

    const {limit} = props

    const {id} = useParams();

    let { data, isLoading , error ,setData } = useFetch(employees_api.movements(id))



    console.log({data})
    const signboard = {
        error : error,
        loading : isLoading
    }

    const sizes = {
        serial : 1,
        type : 4,
        name : 5,
        start_date : 4,
        end_date : 4,
        info : 5,
        settings : 2
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}>#</Grid.Span>,
        type:<Grid.Span size={sizes.type}> نوع الطلب او الاضافة  </Grid.Span>,
        name:<Grid.Span size={sizes.name}> اسم الطلب او الاضافة  </Grid.Span>,
        start_date:<Grid.Span size={sizes.start_date}>   تاريخ البداية    </Grid.Span>,
        end_date:<Grid.Span size={sizes.end_date}>   تاريخ الانتهاء     </Grid.Span>,
        info:<Grid.Span size={sizes.info}>   معلومات اضافية    </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
    }


    return (
        <div className="bg-white rounded mt-5 p-4 profile-operations">

            <PageMeta title={"تحركات المستخدم"} />
            <Signboard {...signboard} />

            <header className="my-3 row">
                <div className="col-1">
                    <NavLink to={"/salaries/" + id}><RightArrow/></NavLink>
                </div>
                <div className="col-11">
                    <h4 className="text-center">
                        تحركات المستخدم
                    </h4>
                </div>
            </header>

            <Table.Header header={header}   />

            {
                data &&  data.length > 0  && data.map((item , index) => (
                    <Grid.FlexResponsive  key={index} className={'align-items-center data-row'}>
                        <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                        <Grid.Span size={sizes.type}>{item.type}</Grid.Span>
                        <Grid.Span size={sizes.name}>{item.name}</Grid.Span>
                        <Grid.Span size={sizes.start_date}> { item.start_date ? _date(item.start_date).dmy() : '-'} </Grid.Span>
                        <Grid.Span size={sizes.end_date}> { item.end_date ? _date(item.end_date).dmy() : '-'} </Grid.Span>
                        <Grid.Span size={sizes.info}> {item.info} </Grid.Span>
                        <Grid.Span size={sizes.settings} className='settings-buttons'>
                            {/*<button  onClick={()=> updateRef.current?.toggle(item)}><Icons.Edit /></button>*/}
                            {/*<button  onClick={()=> deleteRef.current?.toggle(item)}><Icons.Delete /></button>*/}
                        </Grid.Span>
                    </Grid.FlexResponsive>
                ))
            }


        </div>
        )
}



export default UserOperations;
