import { useState } from "react";
import { createFormHandler, EditForm, InputNumber, InputText, PageMeta, setInputsToDefault, Signboard } from "../../../../config/imports";
import { homes_api } from "../../../../config/apis";

export default function CreateHome(props){

    const {setMainData, toggle , department} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name : '',
        department
    })
    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // console.log(data)
        const create_props = {
            url: homes_api.create,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.data})

        await createFormHandler(create_props);
    };
    return(<>
        <PageMeta title={'إضافة بيت جديد'} originTitle={'البيوت'}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>   إضافة بيت جديد  </h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>

  
        <EditForm
            visible={true}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}>
            <InputText name={'name'} className={"col-8"} placeholder={'h1'}  title={'اسم البيت'} value={data.name}/>
            <InputNumber name={'maximum_birds_capacity'}  placeholder={'56'}  title={'عدد الطيور الأعظمي'} value={data.maximum_birds_capacity}/>
            <InputNumber name={'maximum_feed_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من العلف بالطن '} value={data.maximum_feed_capacity}/>
            <InputNumber name={'maximum_drug_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الدواء بالقطعه '} value={data.maximum_drug_capacity}/>
            <InputNumber name={'maximum_other_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الاخرى بالقطعه '} value={data.maximum_other_capacity}/>
            <InputNumber name={'maximum_maintenance_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الصيانة بالقطعه '} value={data.maximum_maintenance_capacity}/>
            <InputNumber name={'maximum_carton_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الكرتون بالقطعه '} value={data.maximum_carton_capacity}/>
            <InputNumber name={'maximum_dish_capacity'}  placeholder={'10'}  title={'السعة الاعظمية من  الطبق بالقطعه '} value={data.maximum_dish_capacity}/> 
        </EditForm>

    </> )
}
