import { useCallback, useEffect, useMemo, useState } from 'react'
import useFetch from './useFetch'
import { apis } from '../config/apis'
import { accio } from '../helpers/axios/axios.get'


export default function useUnits() {

//  const { data  } = useFetch( apis.units.all() )

  const [data, setData] = useState([])




 useEffect(() => {

  const getUnits =  async  () => {
      const {data , error} = await accio(apis.units.all())
      if(error) return setData([])
      setData(data)
      // return data
  }



  getUnits()



 }, [])



  const getUnitNameWithSymbol  =  useCallback( (symbol) => {
    if(!symbol) return ''
    return data?.find(d => d?.symbol?.toLowerCase() === symbol.toLowerCase())?.ar_name
  }, [data])


  const getUnitNameWithUnit  =  useCallback( (unit) => {
    return data?.find(d => d?.unit.toLowerCase() === unit.toLowerCase())?.ar_name
  }, [data])



  function findUnitFactor(unit) {
    const unitInfo = data?.find((u) => u.unit.toLocaleLowerCase() === unit?.toLocaleLowerCase());
    if (unitInfo) {
        return unitInfo.factor;
    }
    return null;
}



function convertUnit(value, fromUnit, toUnit) {
  const fromFactor = findUnitFactor(fromUnit);
  const toFactor = findUnitFactor(toUnit);

  if (fromFactor === null || toFactor === null) {
      // console.error('Invalid units provided');
      return null;
  }
  const result = ( value * fromFactor) / toFactor;
  return result;
}


  return {

    units : useMemo(() =>{
      data?.map(d => d)
    } , [data]) ,
    getUnitNameWithSymbol,
    getUnitNameWithUnit,

     getUnitName : (unit) => {
          return getUnitNameWithSymbol(unit) ?? getUnitNameWithUnit(unit)
     },
     convertUnit,



  }
}
