import {RouterLinks, useNavigator} from "../../../config/imports";
import React from "react";
import useHomeBreadCrumbs from "../hooks/useHomeBreadCrumbs";

export default function HomeOrdersIndex(props){

    const HOME_REPORTS_LINKS = [
        {name: "طلبات الاعلاف",  path: "feed", },
        {name: "طلبات الادويه",  path: "drugs", },
        // {name: "طلبات التعبئه والتغليف",  path: "packaging", },
        {name: "طلبات الصيانه ",  path: "maintenance", },
        {name: "طلبات اخرى",  path: "other", },
        {name: "طلبات الكراتين",  path: "cartons", },
        {name: "طلبات الاطباق",  path: "dishes", },
    ];

    useHomeBreadCrumbs()



    useNavigator( "orders" ,"feed")



    return(
        <>
            <RouterLinks data={HOME_REPORTS_LINKS} />
        </>
    )
}


