import React, {useEffect, useState} from "react";
import {apis, reports_api} from "../../../../config/apis";
import {createFormHandler, setInputsToDefault, updateFormHandler} from "../../../../helpers/form.helper";
import {PageMeta, Signboard} from "../../../../config/imports";

export default function LockLevel(props){
    const {toggle , item , setMainData , setRefresh } = props

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    // console.log({item})
    const [data , setData] = useState({
        ...item,
        operator : item.operator ,
        feed_type : item.feed_type,
        strain : item.strain,
        status : item.status  === 'open' ? 'close' : 'open',

    })


    console.log({item , data})
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const create_props = {
            url: apis.levels.breeding.toggle(item._id) ,
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData :()=>{},
            setRefresh ,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log({data : create_props.data})

        await updateFormHandler(create_props);
    };


    useEffect(() => {
        document.querySelector(('.modal-container-content')).style.width = 'fit-content'
    })

    return(
        <>
            <PageMeta title={data.status !== 'open' ? ' هل انت متأكد من غلق المرحله' : ' هل انت متأكد من فتح المرحله'} originTitle={'المراحل'} />

            <header className="w-fit m-auto px-4 text-center border-bottm-line ">
                <h3>{data.status !== 'open' ? ' هل انت متأكد من غلق المرحله' : ' هل انت متأكد من فتح المرحله'}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleFormSubmit}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}
