import React, {useRef, useState} from "react";
import {
    TableHeader,
} from "../../../../../components/TableComponent";

import useFetch from "../../../../../hooks/useFetch";


import PageMeta from "../../../../../components/PageMeta";

import {wareHouseDetailsAPI} from "../../../warehouseDetails.helper";
import QuickView from "../../../../../components/QuickView";

import {TableView} from "../../../../../components/TableView";
import Signboard from "../../../../../components/Signboard";

import UpdateOrderSupplementInvoice from "./UpdateOrderSupplementInvoice";
import DeleteOrdserSupplementInvoice from "./DeleteOrdserSupplementInvoice";
import CreateOrderSupplementInvoice from "./CreateOrderSupplementInvoice";

const OrderSupplements = () => {
    const {
        data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(wareHouseDetailsAPI.feed);

    const title = "قائمة شراء التعبئة و التغليف"

    const createRef = React.createRef();
    const updateRef = React.createRef();
    const deleteRef = React.createRef();

    const buttons = [
        {
            title: "طلب تعبئة و تغليف",
            onclick: () => {

                createRef.current.toggle();
            },
        },
    ];

    const table = [
        {name: "idx", col: 1, title: "م"},
        {name: "invNumber", col: 1, title: "الاسم"},
        {name: "date", col: 2, custom : itm => itm.operator.name ,  title: "المورد"},
        {name: "invNumber", col: 1, title: "التصنيف"},
        {name: "type", col: 2, title: "نوع التعبئة و التغليف"},
        {name: "quantity", col: 1, title: "وحدة القياس"},
        {name: "unitprice", col: 1, title: "الكميات المطلوبة"},
        {name: "measuringUnit", col: 2, title: "معلومات اضافية"},
        {name: "settings", col: 1, title: ""},
    ];

    return (
        <>
            <PageMeta title={title}/>

            <QuickView ref={createRef}>
                <CreateOrderSupplementInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={updateRef}>
                <UpdateOrderSupplementInvoice setMainData={setMainData}/>
            </QuickView>

            <QuickView ref={deleteRef}>
                <DeleteOrdserSupplementInvoice setMainData={setMainData}/>
            </QuickView>


            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={title}
                buttons={buttons}
            />


            <TableView body={table} updateRef={updateRef} deleteRef={deleteRef} data={data}/>
        </>
    );
};

export default OrderSupplements;
