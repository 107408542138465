import {useEffect, useState} from "react";
import {PageMeta, Signboard, updateData} from "../../../config/imports";
import {users_api} from "../../../config/apis";
import EditForm from "../../../components/FormControls/EditForm";

export default function ToggleUserActiveState(props){

    const { toggle  , roles , updateMain , item} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [header , setHeader] = useState('')
    const [api , setApi] = useState('')

    useEffect(()=>{
        if(item){
              if(data.is_active){
                  // deactivate state
                  setApi(users_api.enable(item._id))
                  setHeader(' تفعيل المستخدم ')
              } else{
                  // activate state
                  setApi(users_api.disable(item._id))
                  setHeader(' تعطيل المستخدم ')
              }
        }
    } , [item])


    const [data , setData] = useState({
        _id : item._id,
        is_active : !item.is_active,
    })


    // when update is done and success is true will reload main data again
    useEffect(()=>{
        if(success && success.length > 0)
            updateMain(true)
        else updateMain(false)
    } , [success])


    const handleFormSubmit = async (e) => {
        setLoading(true);
        setError('')
        setSuccess('')
        e.preventDefault();

        const {data: postedData, error, statusCode, message} =
            await updateData(api ,  {...data})

        setLoading(false)
        if (error)  return setError(error)
        setSuccess('تم  ' + header + ' بنجاح ')

    };


    useEffect(() => {
        document.querySelector(('.modal-container-content')).style.width = 'fit-content'
    })
    return(
        <>
            <PageMeta title={  header + item?.username } originTitle={' المستخدمين '}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   {header + item?.username} </h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} className={'w-fit'} >


            </EditForm>
            </>
    )
}
