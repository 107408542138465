// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stats-index .birds-chart .chart-indicator {
  border-radius: 50% !important;
  width: 10px !important;
  height: 10px !important;
}
.stats-index .stats-component {
  height: 100%;
  margin: 15px 0;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
}
.stats-index .stats-component .chart-indicator {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  margin-left: 10px;
}
.stats-index .stats-component .center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats-index .stats-component .center-container [type=radialBar] .apexcharts-canvas svg path {
  stroke-linecap: round;
}
.stats-index .stats-component header {
  font-weight: bold;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/styles/stats.styles.scss"],"names":[],"mappings":"AAOM;EACE,6BAAA;EACA,sBAAA;EACA,uBAAA;AANR;AAeE;EACI,YAAA;EACA,cAAA;EACA,sBAAA;EACA,mBAAA;EACA,aAAA;AAbN;AAgBI;EACE,qBAAA;EACA,WAAA;EAAa,YAAA;EACb,kBAAA;EACA,iBAAA;AAbN;AAeI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAbN;AAmBY;EACE,qBAAA;AAjBd;AA2BI;EACE,iBAAA;EACA,mBAAA;AAzBN","sourcesContent":[".stats-index {\n  *{\n    //outline : 1px solid teal\n  }\n\n  .birds-chart{\n\n      .chart-indicator{\n        border-radius: 50% !important;\n        width : 10px !important;\n        height : 10px !important;\n\n\n\n      }\n\n\n  }\n\n  .stats-component {\n      height : 100%;\n      margin: 15px 0;\n      background-color: #fff;\n      border-radius: 10px;\n      padding : 10px;\n\n\n    .chart-indicator{\n      display : inline-block;\n      width: 20px; height : 20px;\n      border-radius: 5px;\n      margin-left: 10px;\n    }\n    .center-container{\n      display: flex;\n      justify-content: center;\n      align-items: center;\n\n\n      [type=\"radialBar\"]{\n        .apexcharts-canvas{\n          svg {\n            path {\n              stroke-linecap: round;\n            }\n          }\n        }\n      }\n\n\n\n    }\n\n    header{\n      font-weight: bold;\n      margin-bottom: 10px;\n    }\n  }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
