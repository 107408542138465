import {useState} from "react";
import {createFormHandler, setInputsToDefault} from "../../../helpers/form.helper";
import {createInputGenerator, EditForm, InputNumber, InputText, PageMeta, Signboard} from "../../../config/imports";
import {packaging_api} from "../../../config/apis";

export default function Create(props){
    const {setMainData, toggle} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [ data , setData] = useState({
        symbol : '',
        name : '',
        dishes_count : 0,
        egg_count : 0,
        total_egg_count : 0,
        packaging_type : '',
        dishes_type : '',
    })


    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type ==='number' ? +value : value,

        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: packaging_api.create,
            data : {
                ...data,
                total_egg_count : +data.egg_count * +data.dishes_count
            },
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };


        // console.log(create_props.data)
        await createFormHandler(create_props);
    };
    // console.log(data)

    return <>

        <PageMeta title={'اضافة وحدة تخزين جديده'} originTitle={'اضافة وحدة تخزين جديده'}/>

        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3>اضافة وحدة تخزين جديده</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>

        <EditForm
            visible={props.showFooter === undefined ? true : false}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}>
            <InputNumber name={'symbol'} handleInputChange={handleInputChange}  placeholder={'0'}  title={'الرمز'} value={data.symbol}/>
            <InputText name={'name'} handleInputChange={handleInputChange} placeholder={'0'}  title={'الاسم'} value={data.name}/>
            <InputNumber name={'dishes_count'} handleInputChange={handleInputChange}   placeholder={'0'} title={'عدد الاطباق'} value={data.dishes_count}/>
            <InputNumber name={'egg_count'} handleInputChange={handleInputChange}  className={'col-3'}  placeholder={'0'}  title={'عدد البيض فى كل طبق'} value={data.egg_count}/>
            <InputNumber name={'total_egg_count'}  handleInputChange={handleInputChange} className={'col-3'} placeholder={'0'}  title={'عدد البيض  فى الكرتونة'} defaultValue={+data.egg_count * +data.dishes_count}/>
            <InputText name={'packaging_type'} handleInputChange={handleInputChange}  className={'col-3'}  placeholder={'0'}  title={'نوع الكرتونة  '} value={data.packaging_type}/>
            <InputText name={'dishes_type'} handleInputChange={handleInputChange} className={'col-3'}   placeholder={'0'}  title={'  نوع الطبق'} value={data.dishes_type}/>
        </EditForm>




    </>
}
