import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Page from './Page'
import ArrayHelpers from '../../helpers/array.helpers'
import { key } from '../../helpers/id.helper'
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight } from "react-icons/fa6";

function PagesContainer(props) {

  const { setPage, pagesCount, currentPage } = props

  const [pages, setPages] = useState([])

  useEffect(() => {
    const generatePages = () => {
      let _pages = []
      if (pagesCount <= 8) {
        _pages = ArrayHelpers.range(pagesCount)
      } else {
        if (currentPage <= 4) {
          _pages = [1, 2, 3, 4, 5, '...', pagesCount]
        } else if (currentPage >= pagesCount - 3) {
          _pages = [1, '...', pagesCount - 4, pagesCount - 3, pagesCount - 2, pagesCount - 1, pagesCount]
        } else {
          _pages = [1, '...', currentPage - 1, currentPage, currentPage + 1, '...', pagesCount]
        }
      }
      return _pages
    }

    setPages(generatePages())
    
  }, [currentPage, pagesCount])



  return (
    <div className='flex justify-center items-center gap-2'>

      <button
        className={`px-2 py-1 ${currentPage === 1 && "hidden"}`}
        onClick={() => setPage(currentPage - 1)} >
        <FaAngleRight className='!text-[18px] ' />
      </button>


        {
          pages.map((page, index) => {
            if (page === '...') 
              return <span key={index}>...</span>
            
              return   <Page page={page} setPage={setPage} currentPage={currentPage} key={key()} />
            
          })
        }
      <button
          className={`px-2 py-1 ${currentPage === pagesCount && "hidden"}`}
          
          onClick={() => setPage(currentPage + 1)} >
  
         <FaAngleLeft className='!text-[18px] ' />

      </button>
    </div>
  )
}

PagesContainer.propTypes = {
  setPage: PropTypes.func.isRequired,
  pagesCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
}

export default PagesContainer
