import { useEffect, useState } from "react";
import PropTypes from 'prop-types'

const Accordion = (props) => {
  const { header , children  } = props
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };


  useEffect(() => {
    if (props.isOpen) {
      setIsOpen(props.isOpen);
    }else {
      setIsOpen(false);
    }
  }, [props.isOpen]);

  // TODO : Fix overflow-x-auto
  return (
    <div data-accordion  className="border rounded-md   w-full" >
      <button  className="w-full  focus:outline-none  relative   h-[50px] flex items-center text-right"
        onClick={toggleAccordion}
      >
        {header}

        {!isOpen ? (<span className="text-white absolute left-3 text-[24px] top-1/2 -translate-y-1/2">+</span>) : (<span className="text-white absolute left-3 text-[24px] top-1/2 -translate-y-1/2">-</span>) }
      </button>
      <div className={`overflow-y-auto overflow-x-visible  hide-scrollbar  transition-all duration-500 ease-in-out ${isOpen ? 'max-h-[500px]' : 'max-h-0'}`} > 
        <div className="pb-2">
          {children}
        </div>
      </div>
    </div>
  );
};



Accordion.propTypes = {
  header: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired
}

export default Accordion