import {projectsAPI} from "../../../../../projects.helper";


import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    addColumn,
    InputDate,
    InputNumber,
    InputText,
    InputTextArea,
    extractTableData,
    QuickView,
    SuperDelete, SuperCreate, SuperUpdate
} from '../../../../../../../config/imports'

import AddBuyFeedInvoice from "./AddBuyFeedInvoice";
import AddBuyFeedRequest from "./AddBuyFeedRequest";

import DeleteFeedConsumptionReport from "./DeleteFeedConsumptionReport";
import React from "react";



const FeedReports = () => {

    const MAIN_TITLE = "قائمة تقرير علف";
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            date: new Date(),
            inv_number: 121151,
            name: 654,
            quantity: 545,
            unit_price: 54,
            total: 215,
            extra: 215
        },
        {
            date: new Date(),
            inv_number: 5640454,
            name: 212,
            quantity: 123,
            unit_price: 562,
            total: 600,
            extra: 100
        }
    ]

    const dailyRef = useRef();
    const feedInvoiceRef = useRef();
    const buyFeedRef = useRef();
    const updateFeedRef = useRef();
    const deleteFeedRef = useRef();

    const buttons = [
        // {
        //     title: "اضافة تقرير يومي",
        //     onclick: () => {
        //         dailyRef.current.toggle();
        //     },
        // },
        // {
        //     title: "اضافة فاتورة اعلاف",
        //     onclick: () => {
        //
        //         feedInvoiceRef.current.toggle();
        //     },
        // },
        // {
        //     title: "طلب شراء اعلاف",
        //     onclick: () => {
        //
        //         buyFeedRef.current.toggle();
        //     },
        // },
    ];

    const table = [
        addColumn("", "م", ''),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        addColumn('name', 'الاسم', InputText, 2),
        addColumn('quantity', 'الكمية', InputNumber),
        addColumn('total', 'السعر الاجمالي', InputNumber, 2),
        addColumn('extra', 'معلومات اضافية', InputTextArea, 2),
        addColumn("settings", "", ''),

    ];

    const views =
        [
            // {
            //     ref: dailyRef,
            //     component: <AddDailyFeedReport setMainData={setMainData}/>
            // },

            {
                ref: dailyRef,
                component: <SuperCreate
                    table={table}
                    setMainData={setMainData}
                    mainTitle={MAIN_TITLE}
                    formHeader={"اضافة فاتورة تقرير يومي"}
                    api={"/api/users"}
                />
            },
            {
                ref: feedInvoiceRef,
                component: <AddBuyFeedInvoice setMainData={setMainData}/>
            },
            {
                ref: buyFeedRef,
                component: <AddBuyFeedRequest setMainData={setMainData}/>
            },
            {
                ref: updateFeedRef,
                component: <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={MAIN_TITLE}
                    formHeader={"تعديل فاتورة تقرير يومي"}
                    table={table}
                />
            }

        ]

    return (
        <>
            <PageMeta title={"قائمة تقرير علف"}/>


            <QuickViewContainer views={views}/>

            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={"اضافة فاتورة اعلاف"}
                buttons={buttons}
            />


            <QuickView ref={deleteFeedRef}>
                <SuperDelete
                    item={data[0]}
                    api={""}
                    setMainData={setMainData}
                    table={table}
                    // names = { ['name' , 'age' ]}
                />
            </QuickView>

            <TableView body={extractTableData(table)} data={data} updateRef={updateFeedRef} deleteRef={deleteFeedRef}/>
        </>
    );
};

export default FeedReports;
