import React, { useEffect } from 'react'
import useEggsWarehouseComponentData from '../../components/eggs_warehouse/useEggsWarehouseComponentData'
import EggsWarehouseComponent from '../../components/eggs_warehouse/EggsWarehouseComponent'
import { useParams } from 'react-router-dom'
import PageMeta from '../../components/PageMeta'
import { HomesAccordionItem } from '../../components/eggs_warehouse/EggsWarehouseAccordionItem'
import useEggsWarehouseStore from '../../components/eggs_warehouse/store/eggs_warehouse.store'
import useBreadCrumb from '../../components/breadcrumbs/hooks/breadcrumb.hook'
import { setProjectsBreadCrumbs } from '../../components/breadcrumbs/store/breadcrumbs.default'

export default function DepartmentEggsWarehouse() {
    const id = useParams().id


    const counts = useEggsWarehouseStore(state => state.formatted)
    const onSubmit = useEggsWarehouseStore(state => state.setSubmit)
    const submit = useEggsWarehouseStore(state => state.submit)
    const clear = useEggsWarehouseStore(state => state.clearDepartmentFilter)
    let filter = useEggsWarehouseStore(state => state.formatted)
    const setFilter = useEggsWarehouseStore(state => state.setFilter)
    const rendered = useEggsWarehouseStore(state => state.rendered)
 




    
    const {data : crumbs}  = useBreadCrumb({id , name : "department"})

   

    useEffect(() => {
        if(!crumbs || !crumbs.length) return
        const projectCrumb = crumbs.filter(crumb => crumb.model === "project" || crumb.section === "project")[0]
        const departmentCrumb = crumbs.filter(crumb => crumb.model === "department" || crumb.section === "department")[0]

        const _crumbs = [
            {name : projectCrumb.name , to : `/projects/${projectCrumb.id}/departments`},
            // TODO : check why name is not returning
            {name : departmentCrumb.name ?? "القسم" , to : `/departments/${departmentCrumb.id}/eggs-warehouse`},
            {name : "مستودع البيض" , to : `/departments/${departmentCrumb.id}/eggs-warehouse` }
        ]

        setProjectsBreadCrumbs.departmentHomes(_crumbs)
    } , [crumbs])
 


    const departmentHomes = homes =>{
      const data = homes?.filter( h => h.department_id === id  )
      // const _ids = 
      return data
   }





   useEffect(() => {
      setFilter({projects_ids : [] , departments_ids : [id], homes_ids : []})
   }, [id])

   const { projects_ids , departments_ids , homes_ids } = counts


   const resetAndClear = () => {
      clear(id)
      onSubmit()
   }

  return  rendered  &&  (

       <>
        <PageMeta title="مستودع البيض" />

        <div className="flex">

               <div className="flex-1">
                  <EggsWarehouseComponent filter={filter} deps={[id , submit]}   />
               </div>
               <div className="w-[350px] px-2 min-h-full mt-4">
                  <div className="bg-white h-full py-4 relative rounded-[10px]">

                  <div className="text-center">
                     <h3 className='text-[#fcaa30] text-2xl '> البحث</h3>
                     <hr />
                  </div>

  
                  <HomesAccordionItem   isOpen={true}  callback={ departmentHomes } />

                  <div className="bg-gradient-to-t flex  from-[#fff] justify-center  to-[#fff]/80  absolute bottom-0 w-full ">
                     <button 
                        onClick={onSubmit}
                        className='  bg-[#40a9f4] text-white text-center justify-center  
                        flex items-center px-2 py-2 w-[120px] text-[14px]  rounded-md  my-3 ml-3'>
                        اختر البيانات
                        ({projects_ids.length + departments_ids.length + homes_ids.length})
                     </button>
                     <button 
                        onClick={resetAndClear}
                        className='  bg-[#e46960] text-white text-center justify-center   ml-3
                        flex items-center px-2 py-2 w-[120px] text-[14px]  rounded-md  my-3'>
                           احذف الاختيارات
                     </button>

                  </div>

                  </div>
               </div>
        </div>
     </>
  )
}
