import React from 'react'
import { key } from '../../../helpers/id.helper'
import Stat from './Stat'

export default function StatsGroup(props) {
  return (
    <div className='flex'>
        {
            props?.data?.map( (stat , index) => <Stat key={key()} {...stat} />)
        }
    
    </div>
  )
}
