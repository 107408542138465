import {
    _date,
    createFormHandler, EditForm, Grid, InputDate, InputNumber,
    PageMeta,
    setInputsToDefault,
    Signboard,
    useFetch
} from "../../../../../../../config/imports";
import {departments_api, reports_api} from "../../../../../../../config/apis";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import "../../../../../../../styles/reports.styles.scss"

export default function Create(props){
    // get the id of the department
    const {id} = useParams();
    const {setMainData, toggle} = props;

    // get homes of a department
    // const {data : homes , isLoading : homesLoading , error : homesError} = useFetch(departments_api.homes(id))
    let {data : reports , isLoading : reportsLoading , error : reportsError , setData : setReports} = useFetch(reports_api.department.dead.form(id))

    console.log(reports)
    // create state for date of each home
    let [data , setData] = useState({
            date: new Date().toISOString(),
            homes: [],
    })

    useEffect(()=>{
        if(reports && reports.homes.length > 0){
            data.homes = [...reports.homes]
            setData({...data})
        }
    } , [reports])

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    let signboard = {
        error :  error  || reportsError,
        success  ,
        loading : loading  || reportsLoading,
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        const create_props = {
            url: reports_api.department.dead.create(),
            data : {
                homes : [...data.homes]
            },
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        // console.log(create_props.data)
        await createFormHandler(create_props);
    };


    const handleInputChange = (e , changeData) => {
        const {homeIndex } = changeData
        const {value , name } = e.target

    //  get home to be changed
        const home = data.homes[homeIndex]

        home[name] =  +value
        // home[name].date = new Date(data.date).toISOString()

        setData({...data})
    }


    const datePicker = e=>{
        const {value} = e.target
        data.date = new Date(value).toISOString()
        data.homes = [...data.homes.map(home =>{
            return {
                ...home,
                date : new Date(value).toISOString()
            }
        })]
        setData({...data})
    }

    console.log(data)
    return(
        <div className={'total-feed-report'}>

            <PageMeta title={'اضافة تقرير نافق شامل يومي'} originTitle={' الشامله تقارير النافق'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   اضافة تقرير نافق شامل يومي</h3>
            </header>

            <Signboard {...signboard}/>

            {
                reports && 
                    <EditForm 
                        
                        onSubmit={handleFormSubmit}
                        toggle={toggle}
                        onChange={handleInputChange}
                    >
                    <>

                        <InputDate title={'اختر التاريخ'}  handleInputChange={datePicker} defaultValue={_date(data.date).forInputDate()} />
                        {
                            reports && reports?.homes?.length > 0 && reports?.homes?.map( (home , homeIndex) => {
                                return (
                                    <duv key={homeIndex}>
                                        <Grid.Flex key={home.home} className={'data-row align-items-center'} >
                                            <Grid.Span size={6} className={'span-5'} > {home.name} </Grid.Span>
                                            <div className="span-5 mt-3">
                                                {_date(data?.date).dmy()}
                                            </div>
                                            <InputNumber className={'span-5'} name={'dead_chicken'} label={'نافق الدجاج'} handleInputChange={e => handleInputChange(e , {homeIndex})} value={home.dead_chicken}  />
                                            <InputNumber className={'span-5'} name={'dead_rooster'} label={'نافق الديوك'} handleInputChange={e => handleInputChange(e , {homeIndex})} value={home.dead_rooster}  />
                                        </Grid.Flex>

                                    </duv>
                                )
                            })
                        }

                    </>
                    </EditForm>
            }


        </div>
    )
}

