import { useEffect, useState } from 'react';

 const useDebounce = ( value  , delay  = 500  ) => {
    
  const [state, setState] = useState(value);

  useEffect(() => {

    const timeout = setTimeout(() => {

        setState(value);

    }, delay);



    return () => clearTimeout(timeout);



  }, [value, delay]);


  return state;
};


export default useDebounce


