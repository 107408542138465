import {useState} from "react";
import {apis} from "../../../../config/apis";
import {createFormHandler, setInputsToDefault} from "../../../../helpers/form.helper";

import {
    EditForm,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../config/imports";
import ArrayHelpers from "../../../../helpers/array.helpers";
import InputMassUnit from "../../../../components/Shared/InputMassUnit";

export default function Create(props){

    const {setMainData, toggle , operators} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name: '',
        measuringUnit: '',
        types: '',
        operator: '',
        classification: '',
        info: '',
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: apis.origins.drug.create(),
            data ,
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=> setMainData(pre =>{
                return [
                    ...pre,
                    {
                        ...data,
                        operator : operators.filter(o => o._id === data.operator)[0]
                    }
                ]
            }),
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };
        console.log(create_props.data)

        await createFormHandler(create_props);
    };
    return(
        <>
            <PageMeta title={' اضافة نوع دواء جديد  '} originTitle={' الادوية'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>اضافة نوع دواء جديد</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
               <InputText name={'name'} title={'الاسم'} value={data.value} />
               
               <InputMassUnit setData={setData} />

                <InputSelect name={'operator'} title={'المورد '} value={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />

                <InputSelect className='col-6' name={'classification'} title={'التصنيف '} value={data.classification}  options={[
                    {_id : "تحصين" , name : "تحصين" },
                    {_id : "دواء" , name : "دواء" },
                    {_id : "فتامين" , name : "فتامين" },
                    {_id : "مطهرات" , name : "مطهرات" },
                ]} />
                <InputSelect className='col-6' name={'types'} title={'التصنيف '} value={data.types}  options={[
                    {_id : "علبه" , name : "علبه" },
                    {_id : "كيس" , name : "كيس" },
                    {_id : "جالون" , name : "جالون" },
                ]} />

                <InputTextArea name={'info'} title={'التفاصيل'} value={data.info} />


            </EditForm>

        </>
    )
}
