import React, { useEffect } from 'react';
import { apis } from '../../config/apis';
import useFetch from '../../hooks/useFetch';
import PageMeta from '../../components/PageMeta';
import { Table } from '../../layout/table';
import { _date } from '../../helpers/date.helpers';
import Pagination from '../../layout/pagination/Pagination';
import { Link } from 'react-router-dom';
import { setBreadCrumbsStore } from '../../components/breadcrumbs/store/breadcrumb.store';
import { homeBreadCrumb, notificationsBreadCrumb } from '../../components/breadcrumbs/data/breadcrumbs.data';

export default function Notifications(props) {


  
    const api = apis.notifications.paginate()
    
  
    const { data  , paginate , setters , alert } = useFetch(api);


    const table = [
        {size : 2 , name : "#" , text : "#"  },
        {size : 5 , name : "title" , text :  'العنوان ' },
        {size : 10 , name : "body" , text :  'المحتوى ' , custom : d => <Link className='text-dark'  to={d?.link} > {d.body} </Link>  },
        {size : 4 , name : "createdAt" , text :  'تاريخ الاشعار', custom : d => _date(d.createdAt).dateAndTime() },
    ]


    useEffect(()=>{
      setBreadCrumbsStore([
          homeBreadCrumb,
          notificationsBreadCrumb,
      ])
    } , [])





  return (
    <div className='base-card bg-white r-16 p-3 mt-3'>
    <PageMeta title=" الاشعارات" />
    {/* <Signboard {...alert}/> */}
    {/* <TableHeader   api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} /> */}

    <Pagination   paginate={paginate}    api={api} setData={setters.data} setLoading={setters.loading} search={""} />
    <Table table={table} data={data}   className="mt-3" />
  </div>
  )
}
