import {createFormHandler, EditForm, InputText, Signboard , PageMeta, InputSelect, InputNumber, updateFormHandler} from "../../../../../config/imports";
import {apis} from "../../../../../config/apis";
import {useState} from "react";
import DisplayUploadedImage from '../../../../../components/images/DisplayUploadedImage';
import DisplayUploadedImagesGroup from '../../../../../components/images/DisplayUploadedImagesGroup';
import ArrayHelpers from "../../../../../helpers/array.helpers";
import origin_cards_tables from "../../assests_forms";
export default function Update (props){

    const {setMainData, toggle , item , asset} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({...item})

    let signboard = { error  ,success,loading  }

    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: apis.assets.asset(asset).update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setData,
            FORM_HEADER: `تعديل`,
        };

        await updateFormHandler(update_props);
    };




    return(
        <>
    
        <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
            <h3> تعديل بيانات الآله</h3>
        </header>

        <Signboard {...signboard}/>

        <EditForm
            visible={true}
            onSubmit={handleFormSubmit}
            toggle={toggle}
            onChange={handleInputChange}
        >
                    { ArrayHelpers.simpleInputsGenerator(origin_cards_tables[asset] , data , handleInputChange ) }

        </EditForm>



        </>
    )
}
