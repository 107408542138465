import RouterHelpers from "../../../helpers/router.helper"
import Feed from "../items_cards/Feed"
import Packaging from "../items_cards/Packaging"
import Drug from "../items_cards/Drug"
import Maintenance from "../items_cards/Maintenance"
import ItemCard from "../items_cards/ItemCard"
import { Route } from "react-router-dom"
import Others from "./Others"
import EggStorageUnitsIndex from "../egg_storage_units/EggStorageUnitsIndex"
import EggDishesIndex from "../egg_dishes/EggDishesIndex"

const item_cards_routes = {
    router : (protect)=>{

        return <Route path="items-cards" element={protect(<ItemCard />)} >
                    <Route path="feed" element={protect(<Feed />)} />
                    <Route path="drug" element={protect(<Drug />)} />
                    <Route path="packaging" element={protect(<Packaging />)} />
                    <Route path="maintenance" element={protect(<Maintenance />)} />
                    <Route path="others" element={protect(<Others />)} />
                    <Route path="cartons" element={protect(<EggStorageUnitsIndex />)} />
                    <Route path="dishes" element={protect(<EggDishesIndex />)} />
               </Route>
 
    }


}


export default item_cards_routes