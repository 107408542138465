import { Outlet } from "react-router-dom";
const ComponentsContainer = () => {
  return (
    <div className="px-4">
      <Outlet />
    </div>
  );
};

export default ComponentsContainer;
