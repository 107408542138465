import React from 'react';
import Pagination from "../../components/pagination/Pagination";
import PageMeta from "../../components/PageMeta";
import { apis } from "../../config/apis";
import usePaginate from "../../hooks/usePaginate";
import SearchInput from '../../components/search/SearchInput';
import Invoices from '../_invoices/Invoices';



const TestingHelperIndex = (props) => {


  
  // const Paginate = usePaginate(apis.notifications.paginate())


  return (
    <>
      <PageMeta title="testing " />
      {/* <div className="flex items-center justify-center my-10 flex-col">
            <Pagination />
            <SearchInput /> */}
            {/* <Paginate /> */}
      {/* </div> */}


        <Invoices />




     
    </>
  );
};


export default TestingHelperIndex;
