import {
    addColumn,
    createInputGenerator, EditForm, Grid, InputDate,
    InputNumber, InputSelect,
    InputText, InputTextAsNumber,
    PageMeta, Signboard,
    useCreateStates, useFetch,
    useState ,Icons , postData
} from "../../../config/imports";
import "../../../styles/employee.styles.scss";
import {departments_api, employees_api} from "../../../config/apis";
import {useEffect} from "react";
import Browser from "../../../layout/browser/Browser";



const CreateEmployee = (props) => {

    const { toggle , setMainData  , project , department }  = props

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);



    console.log({project , department})
    // const {data : projects,  isLoading : projects_loading, error : projects_error,} = useFetch(employees_api.projects)

    const {data : departmentData , error : department_error, isLoading : department_loading} = useFetch(departments_api.details(department))




    const signboard = {
        loading : department_loading || loading,
        error :  department_error || error,
        success
    }
  const [data , setData] = useState({
        employee_id : '',
        ar_name : "",
        en_name : '',
        nationality : '',
        job_title : '',
        basic_salary : '',
        phone_number : '',
        email : '',
        second_phone_number : '',
        // second tab
        id_number : '',
        id_release_date : '',
        id_expiry_date : '',
        id_img : '',
        // third tab
        work_nature_allowance: '',
        incentive_allowance: '',
        subsistence_allowance: '',
        technical_allowance: '',
        management_allowance: '',
        supervision_allowance: '',
        periodic_allowance: '',
        distinction_allowance: '',
        // forth tab
          job_start_date : '',
          insurance_num : '',
          business_office_num : '',
        project : project,
        department : department,
          work_hours : '',
          // fifth tab
          clothing_size : '',
          shoes_size : '',

    })


    const handleFormSubmit = async (e) =>{
        e.preventDefault()
        setError('')
        setSuccess('')
        // const form = e.target
        // console.log(form.elements)
        setLoading(true)
        const {data: postedData, error, statusCode,} = await postData(employees_api.create(), data  , {
            headers :{
                'Content-Type': 'multipart/form-data',
            }})
        setLoading(false)
        if (error) {
            return setError(error)
        }

        setSuccess("تم اضافة الموظف بنجاح")
        setMainData(pre => {
            console.log(pre)
            return [...pre , {...postedData}]
        })



    }
    // console.log(data)
    const handleInputChange = event =>{
        const {name , value } = event.target
        data[name] = value
        setData({...data})
    }



    useEffect(()=>{
        if(departmentData){
           data.project = departmentData?.project?._id
            setData({...data })
        }
    } , [departmentData])


    const browserData = [
        {
            tab  : { title : "البيانات الشخصية"},
            content :<>

                <InputNumber placeholder='0' name={'employee_id'} handleInputChange={handleInputChange}  title={'رقم الموظف'} defaultValue={data.employee_id}/>
                <InputText name={'ar_name'} handleInputChange={handleInputChange} title={'أسم الموظف عربي'} defaultValue={data.ar_name}/>
                <InputText name={'en_name'} handleInputChange={handleInputChange}title={'أسم الموظف أنجليزي'} defaultValue={data.en_name}/>
                <InputText name={'nationality'} handleInputChange={handleInputChange} title={'الجنسية'}   defaultValue={data.nationality}/>
                <InputText name={'job_title'} handleInputChange={handleInputChange}   title={'مسمى الوظيفة'} defaultValue={data.job_title}/>

                <div className="col-4 col-md-4 ">
                    <div data-error="" className="form-group">
                        <label htmlFor="personal_img">صورة الموظف</label>
                        <input
                            type="file"
                            name="personal_img"
                            id="personal_img"
                            required
                            placeholder="اختر صورة"
                            accept="image/*"
                            onChange={(e) =>
                                setData((prevState) => ({
                                    ...prevState,
                                    personal_img: e.target.files[0],
                                }))
                            }
                            className="hide-arrow form-control"
                        />
                    </div>
                </div>

                <InputText name={'phone_number'} handleInputChange={handleInputChange}   title={'رقم الجوال '} defaultValue={data.phone_number}/>
                <InputText name={'second_phone_number'} handleInputChange={handleInputChange}   title={' رقم الجوال 2'} defaultValue={data.second_phone_number}/>
                <InputText name={'email'} handleInputChange={handleInputChange}   title={'البريد الالكتروني'} defaultValue={data.email}/>

            </>
        },
        {
            tab  : {title : " تحقيق الشخصيه"},
            content :<>


                <div className="section-title"> <span>الاثبات الشخصي</span> </div>
                <InputNumber placeholder='0'  className={'col-6'} name={'id_number'} handleInputChange={handleInputChange} title={'رقم الاثبات الشخصي'}  defaultValue={data.id_number}  />
                <div className="col-6 col-md-6">
                    <div data-error="" className="form-group">
                        <label htmlFor="id_img">صورة الاثبات</label>
                        <input
                            type="file"
                            name="id_img"
                            id="id_img"
                            required
                            placeholder="اختر صورة"
                            accept="image/*"
                            onChange={(e) =>
                                setData((prevState) => ({
                                    ...prevState,
                                    id_img: e.target.files[0],
                                }))
                            }
                            className="hide-arrow form-control"
                        />
                    </div>
                </div>

                <InputDate className={'col-6'}  name={'id_release_date'} handleInputChange={handleInputChange} title={'تاريخ الاصدار  '}  defaultValue={data.id_release_date}  />
                <InputDate  className={'col-6'} name={'id_expiry_date'} handleInputChange={handleInputChange} title={' تاريخ الانتهاء'}  defaultValue={data.id_expiry_date}  />

                <div className="section-title">  <span>جواز السفر</span> </div>

                <InputNumber placeholder='0' className={'col-6'} name={'passport_number'} handleInputChange={handleInputChange} title={'رقم  جواز السفر'}  defaultValue={data.passport_number}  />

                <div className="col-6 col-md-6">
                    <div data-error="" className="form-group">
                        <label htmlFor="passport_img">صورة جواز السفر</label>
                        <input
                            type="file"
                            name="passport_img"
                            id="passport_img"
                            required
                            placeholder="اختر صورة"
                            accept="image/*"
                            onChange={(e) =>
                                setData((prevState) => ({
                                    ...prevState,
                                    passport_img: e.target.files[0],
                                }))
                            }
                            className="hide-arrow form-control"
                        />
                    </div>
                </div>
                <InputDate className={'col-6'} name={'passport_release_date'} handleInputChange={handleInputChange} title={'تاريخ الاصدار  '}  defaultValue={data.passport_release_date}  />
                <InputDate className={'col-6'} name={'passport_expiry_date'} handleInputChange={handleInputChange} title={' تاريخ الانتهاء'}  defaultValue={data.passport_expiry_date}  />


            </>
        },
        {
            tab  : {title : "الأجر والبدلات"},
            content :<>

                <div className="section-title"> <span> الأجر</span> </div>

                <InputNumber placeholder='0' className={'col-6'} name={'basic_salary'} handleInputChange={handleInputChange} title={'الراتب الأساسي'} defaultValue={data.basic_salary}/>
                <InputNumber placeholder='0' className={'col-6'} name={'work_hours'} handleInputChange={handleInputChange} title={' عدد ساعات العمل'} defaultValue={data.work_hours}/>

                <div className="section-title"> <span> البدلات الثابته</span> </div>


                <InputNumber placeholder='0' className={'col-6'} name={'work_nature_allowance'} handleInputChange={handleInputChange} title={' طبيعة عمل'}  defaultValue={data.work_nature_allowance}  />
                <InputNumber placeholder='0' className={'col-6'} name={'incentive_allowance'} handleInputChange={handleInputChange} title={' تحفيز'}  defaultValue={data.incentive_allowance}  />
                <InputNumber placeholder='0' className={'col-6'} name={'subsistence_allowance'} handleInputChange={handleInputChange} title={' أعاشة'}  defaultValue={data.subsistence_allowance}  />
                <InputNumber placeholder='0' className={'col-6'} name={'technical_allowance'} handleInputChange={handleInputChange} title={' فني'}  defaultValue={data.technical_allowance}  />

                <div className="section-title"> <span> البدلات المكتسبه</span> </div>


                <InputNumber placeholder='0' className={'col-6'} name={'management_allowance'} handleInputChange={handleInputChange} title={'إدارة'}  defaultValue={data.management_allowance}  />
                <InputNumber placeholder='0' className={'col-6'} name={'supervision_allowance'} handleInputChange={handleInputChange} title={'أشراف'}  defaultValue={data.supervision_allowance}  />
                <InputNumber placeholder='0' className={'col-6'} name={'periodic_allowance'}  handleInputChange={handleInputChange} title={'دورية'}  defaultValue={data.periodic_allowance}  />
                <InputNumber placeholder='0' className={'col-6'} name={'distinction_allowance'}   handleInputChange={handleInputChange} title={'تمييز'}  defaultValue={data.distinction_allowance}  />
            </>
        },
        {
            tab  : {title : "بيانات العمل"},
            content :<>
                <InputDate name={'job_start_date'} handleInputChange={handleInputChange} title={' بداية العمل  '}  defaultValue={data.job_start_date}  />
                <InputTextAsNumber placeholder='0' name={'insurance_num'} handleInputChange={handleInputChange} label={'الرقم بالتأمينات'}  defaultValue={data.insurance_num}  />
                <InputTextAsNumber placeholder='0' name={'business_office_num'} handleInputChange={handleInputChange} label={'رقم مكتب العمل'}  defaultValue={data.business_office_num}  />


                <InputText readonly className={'col-6'} name={'project'} title={'المشروع'}  defaultValue={departmentData?.project?.name}/>
                <InputText readonly className={'col-6'} name={'department'}  title={'القسم'}  defaultValue={departmentData?.name}/>


            </>
        },
        {
            tab  : {title : "بيانات ذات صلة", size : "4",},
            content :<>
                <InputText className={'col-6'} placeholder='xl' name={'clothing_size'} handleInputChange={handleInputChange} title={'  مقاس الملابس'}  defaultValue={data.clothing_size}  />
                <InputText className={'col-6'} placeholder='42' name={'shoes_size'} handleInputChange={handleInputChange} title={'مقاس الحذاء'}  defaultValue={data.shoes_size}  />
            </>
        }
    ]




    return <div className='employee-component'>
            <PageMeta title={'إضافة  موظف'} originTitle={"الرواتب / الاجور"}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>إضافة  موظف</h3>
            </header>
            <Signboard {...signboard} />

            <EditForm  onSubmit={handleFormSubmit}  onChange={handleInputChange} toggle={toggle} visible={false}  >
                <Browser data={browserData} toggle={toggle} />
            </EditForm>

        </div>
}

export default CreateEmployee
