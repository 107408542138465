import { homeBreadCrumb, projectsBreadCrumb, statsBreadCrumb } from "../data/breadcrumbs.data"
import { setStoreBreadCrumb } from "./breadcrumb.store"


    

export const setIndexPageBreadCrumbs = () => {
    return setStoreBreadCrumb([
        homeBreadCrumb
    ])
}

export const setStatsBreadCrumbs = () => {
    return setStoreBreadCrumb([
        homeBreadCrumb,
        statsBreadCrumb
    ])
}





export const setProjectsBreadCrumbs =  {
    index(){
        return setStoreBreadCrumb([
            homeBreadCrumb,
            projectsBreadCrumb
        ])
    },
    departments (breadCrumb){
        return setStoreBreadCrumb([
            homeBreadCrumb,
            projectsBreadCrumb,
            breadCrumb
        ])
    },
    departmentHomes (breadCrumbs){
        return setStoreBreadCrumb([
            homeBreadCrumb,
            projectsBreadCrumb,
            ...breadCrumbs
        ])
    },
    home : {
        index(breadCrumbs){
            return setStoreBreadCrumb([
                homeBreadCrumb,
                projectsBreadCrumb,
                ...breadCrumbs
            ])
        }
    }
    
}

