import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { Table } from '../../../layout/table'
import { _date } from '../../../helpers/date.helpers'
import Helpers from '../../../helpers/helpers'
import QuickView from '../../../components/QuickView'
import { approvalCategoriesEnum } from '../shared.approvals'
import ApprovalsMovementDetails from '../components/ApprovalsMovementDetails'
import ApprovalsHeader from '../components/ApprovalsHeader'
import ApprovalReturnLink from '../components/ApprovalReturnLink'
import PageMeta from '../../../components/PageMeta'

function OtherApprovalDetails(props) {

    const {getUnitName , data , setRefresh , Alert} = props

    const detailsRef = useRef()


    const headerTable =  [
        {size : 3 , name : "date" , text : "التاريخ"  , custom : d => _date(d.date).dmy() },
        {size : 4 , name : "last_updated_by" , text : " تعديل بواسطة"  },
        {size : 4 , name : "created_by" , text : " اضافة بواسطة"  },
        {size : 4 , name : "total_quantity" , text : "الكمية" , custom : d => Helpers.Number.commaFormat( d?.total_quantity ) },
        {size : 4 , name : "unit" , text : "وحدة القياس" , custom : d => getUnitName(d?.unit) },
        {size : 4 , name : "notes" , text : " ملاحظات"  },
        {size : 1 , name : "" , text : ""  },
    ]




    const bodyTable = [
        { name : "#"  , text : "" , size : 1  },
        { name : "ar_type" , text : "نوع الحركه" , size : 2 },
        { name : "from" , text : "من" , size : 4 },
        { name : "to" , text : "الي" , size : 3 },
        { name : "category" , text : "الصنف" , size : 2 , custom : d => approvalCategoriesEnum[d?.category] },
        { name : "sub_category" , text : "الفئة" , size : 2 },
        { name : "quantity" , text : "الكمية" , size : 2 },
        { name : "ar_unit" , text : "وحدة القياس" , size : 3   },
        { name : "date" , text : "التاريخ" , size : 3 , custom :d =>  <span style={{ fontSize : "13px"}}> { _date(d?.date).dmy()} </span>  },
        { name : "movements" , text : "تفاصيل" , size : 2  , custom : d => {
          return <button onClick={() => detailsRef.current?.toggle(d)}><i className="fa fa-eye " /></button>
        }},
    ]

    





  return (
    <>

        <PageMeta title={'تفاصيل الموافقة'} />

        <ApprovalReturnLink />

        <QuickView ref={detailsRef}  className="overflown-popup"><ApprovalsMovementDetails getUnitName={getUnitName}   /></QuickView>

        <div className="page my-3">
            
            <ApprovalsHeader setRefresh={setRefresh} type={data[0]?.ar_type}   />
            
            <Alert />

            <Table data={data} table={headerTable} />
            
        </div>

        <div className="page my-3">

            <h1 className='text-[20px] font-[500]'>  التفاصيل  </h1>

            <Alert />

            <Table data={data[0]?.movements} table={bodyTable} />

        </div>


    </>
  )
}

OtherApprovalDetails.propTypes = {
    data : PropTypes.array,
    getUnitName : PropTypes.func,
    setRefresh : PropTypes.func,
    Alert : PropTypes.any
}

export default OtherApprovalDetails
