import HatcheriesIndex from "./hatchries/HatcheriesIndex"
import LandsIndex from "./lands/LandsIndex"
import BuildingsIndex from "./buildings/BuildingsIndex"
import IncubatorsIndex from "./incubators/IncubatorsIndex"
import OriginCardsIndex from "./OriginCardsIndex"
import { Route } from "react-router-dom"
import PoultryHouseIndex from "./poultry-house/PoultryHouseIndex"
import MachineIndex from "./machine/MachineIndex"
import CarsIndex from "./cars/CarsIndex"
import BiologicalIndex from "./biological/BiologicalIndex"
import RoadsIndex from "./roads/RoadsIndex"


const origin_cards_routes = {
    router : (protect)=>{

        return <Route path="origin-cards" element={protect(<OriginCardsIndex />)} >
                    <Route path="hatcheries" element={protect(<HatcheriesIndex />)} />
                    <Route path="incubators" element={protect(<IncubatorsIndex />)} />
                    <Route path="lands" element={protect(<LandsIndex />)} />
                    <Route path="buildings" element={protect(<BuildingsIndex />)} />
                    <Route path="poultry-houses" element={protect(<PoultryHouseIndex />)} />
                    <Route path="machine" element={protect(<MachineIndex />)} />
                    <Route path="cars" element={protect(<CarsIndex />)} />
                    <Route path="biological" element={protect(<BiologicalIndex />)} />
                    <Route path="roads" element={protect(<RoadsIndex />)} />
               </Route>
    }
}


export default origin_cards_routes



