import { create } from "zustand";
import { homeBreadCrumb, projectsBreadCrumb } from "../../../components/breadcrumbs/data/breadcrumbs.data";
import axiosPost from "../../../helpers/axios/axios.post";
import { apis } from "../../../config/apis";
import { showErrorToast } from "../../../stores/toast.store";



export const useHomeBreadCrumbStore = create( (set) => ({
    crumbs : [],
    setCrumbs : (data) => set((state) => ({ ...state , crumbs : data })),
    fetch : async (id) => {
        
        const state = useHomeBreadCrumbStore.getState().crumbs

        // if(state && state.length > 2) return

        const { data : crumbs  , error} = await axiosPost(apis.helpers.breadCrumbs() , {
            id,
            name : "home"
           })
        if(error) return showErrorToast(error)

        const projectCrumb = crumbs.filter(crumb => crumb.model === "project" || crumb.section === "project")[0]
        const departmentCrumb = crumbs.filter(crumb => crumb.model === "department" || crumb.section === "department")[0]
        const homeCrumb = crumbs.filter(crumb => crumb.model === "home" || crumb.section === "home")[0]


        const _crumbs = [
            homeBreadCrumb,
            projectsBreadCrumb,
            {name : projectCrumb.name , to : `/projects/${projectCrumb.id}/departments`},
            // TODO : check why name is not returning
            {name : departmentCrumb.name ?? "القسم" , to : `/departments/${departmentCrumb.id}/homes/base`},
            {name : "البيوت" , to : `/departments/${departmentCrumb.id}/homes/base` },
            {name : homeCrumb.name ?? "البيت" , to : `/home/${homeCrumb.id}/details` },
        ]

        // console.log({scrumbs : _crumbs})



        set((state) => ({ ...state , crumbs : _crumbs }))

    }
}))