import {useState} from "react";

import {
    setInputsToDefault, createFormHandler,
} from "../../../../../helpers/form.helper";

import PageMeta from "../../../../../components/PageMeta";
import Signboard from "../../../../../components/Signboard";
import {strainAPI} from "../../../../origin/bird_breed/bird_breed.routes"

import InputText from "../../../../../components/FormControls/InputText";
import InputSelect from "../../../../../components/FormControls/InputSelect";
import useFetch from "../../../../../hooks/useFetch";
import {operatorAPI} from "../../../../operators/operator.routes";
import InputDate from "../../../../../components/FormControls/InputDate";
import InputNumber from "../../../../../components/FormControls/InputNumber";
import InputTextArea from "../../../../../components/FormControls/InputTextArea";
import SelectOperator from "../../../../../components/Shared/SelectOperator";
import EditForm from "../../../../../components/FormControls/EditForm";

const FORM_HEADER = "اضافة فاتورة شراء";
const MAIN_TITLE = "قائمة شراء الاعلاف";

const CreateFeedInvoice = ({setMainData, toggle}) => {


    const [data, setData] = useState({
        invNumber: "",
        operator: "",
        date: "",
        type: "",
        measuringUnit: "",
        unitprice: 0,
        quantity: 0,
        total: 0,
        totalforhome: 0,
        home: [],
        info: "معلومات"
    });
    const {data: ops} = useFetch(operatorAPI.url)
    let names = Object.keys(data);

    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // console.log(data)
        const create_props = {
            url: strainAPI.url,
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER,
        };

        createFormHandler(create_props);
    };

    const handleInputChange = (e) => {
        // console.log(e.target.value)
        setData((prevState) => ({
            ...prevState, [e.target.name]: e.target.value,
        }));
    };
    const WeightUnits = [{
        name: "طن", _id: "'طن"
    }, {
        name: "كيلو", _id: "'كيلو"
    }, {
        name: "جرام", _id: "'جرام"
    },]
    return (<div className="temp-form">
            <PageMeta title={FORM_HEADER} originTitle={MAIN_TITLE}/>
            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto">
                <h3>{FORM_HEADER}</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm toggle={toggle} onSubmit={handleFormSubmit} onChange={handleInputChange}>
                <InputText name={names[0]} title={"رقم الفاتورة"}/>
                <SelectOperator/>
                <InputDate title={"التاريخ"} name={names[2]}/>
                <InputText title={"نورع العلف"} name={names[3]}  className={"col-12 col-md-3 col-lg-3"}/>
                <InputSelect options={WeightUnits} name={names[4]} title={"وحدة العلف"}  className={"col-12 col-md-3 col-lg-3"}/>
                <InputNumber title={"سعر الوحدة"} name={names[5]}  className={"col-12 col-md-3 col-lg-3"}/>
                <InputNumber title={"الكمية"} name={names[6]}  className={"col-12 col-md-3 col-lg-3"}/>
                <InputNumber title={"اجمالي المبلغ"} name={names[7]} className={"col-12"} defaultValue={data["quantity"] * data["unitprice"]} isReadonly={true}/>
                <InputSelect options={ops} title={"اختر المستودع"} name={names[8]}  className={"col-12"}/>
                <InputTextArea name={names[9]} title={"ملاحظات"}/>
            </EditForm>

        </div>);
};

export default CreateFeedInvoice;
