import React, { useMemo } from 'react'
import { InputNumber, InputText, useFetch } from '../../../config/imports'
import { apis } from '../../../config/apis'

export default function InputTax(props) {

    const {data , setData , className  } = props

    const {data : taxData} = useFetch(apis.preferences.tax())

  
    const priceAfterTax = useMemo(() => {

      const percentage = taxData?.tax_percentage / 100

      const discount = 1 + percentage

      const _total_price = +data.quantity * +data.unit_price


      const price  =  (_total_price * discount).toFixed(2)

      return isNaN(price) ? 0 : price
      

    } , [data.quantity , data.unit_price])



  return (
    <div className={className}>
        <div className="row">
            <InputText 
              className={'col-4'} 
              name={'tax_percentage'} 
              title={'الضريبة'} 
              disabled={true}  
              defaultValue={taxData?.tax_percentage + "%"} 
          />
            <InputNumber 
              className={'col-8'} 
              name={'tax_percentage'} 
              title={'الاجمالي بعد الضريبة'} 
              disabled={true}  
              defaultValue={priceAfterTax} 
          />
        </div>
    </div>


  )
}
