import PageMeta from "../../../../../components/PageMeta";

import RouterLinks from "../../../../../components/LinkList";
import useNavigator from "../../../../../hooks/useNavigator";

const ProjectsFeedReports = () => {
    const FEED_LINKS = [
        {
            name: "قائمة تقرير علف",
            path: "feed-reports",
        },
        {
            name: "تقرير حركة الاعلاف",
            path: "feed-movement",
        }
    ];

    useNavigator("feed", "feed-reports");

    return (
        <>
            <PageMeta title={"الصيانة"} />

            {<RouterLinks underline={true} data={FEED_LINKS} />}
        </>
    );
};

export default ProjectsFeedReports;
