
import '../../styles/auth.styles.scss'
import {useEffect, useState} from "react";
import {useLogin} from "../../hooks/useLogin";
import PageMeta from "../../components/PageMeta";
import {Signboard} from "../../config/imports";

const Login = () => {
    const [user , setUser] = useState({
        email : '',
        password : ''
    })

    const [state , setState] = useState(false)
    const {login ,error ,isLoading  , setError} = useLogin()

    useEffect(()=>{
        if(error){
            setTimeout(()=>{
                setError(null)
              } , 5000 // time_to_show_error
            )
        }
    })



    const passwordHandler = ()=>{
        setState(!state)
        document.getElementById('password').type = state ? 'password' : 'text'
    }

    const handleLogin = async (e)=>{
        await login(user.email , user.password)
    }
    return (
        <div className="auth-container">
            <PageMeta title="تسجيل الدخول" />
               <div className="auth-form-container">
                   <div className="login-logo">
                       {/*<img src="/logo/logo.png" alt="company logo"/>*/}

                       <svg width="80" height="80"  viewBox="0 0 87 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                           <path d="M14.7869 26.1771L24.1501 20.7772L18.9237 11.7298H68.0762L43.5079 54.2981L38.2815 45.235L28.9185 50.6508L43.5079 75.9141L86.8025 0.914062H0.19751L14.7869 26.1771Z" fill="#FCA119"/>
                       </svg>
                    <h3>مرحبا بك من جديد</h3>

                <Signboard  loading={isLoading}/>
                   {
                       error && <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center d-flex align-items-center justify-content-center">
                                       <div className="alert alert-danger">
                                           {error}
                                       </div>
                                </div>
                   }
                   </div>
                   <div className="form-group my-3">
                       <label htmlFor="email">البريد الالكتروني</label>
                       <input type="email" value={user.email} onChange={(e)=>setUser({...user , email : e.target.value})} className="form-control" id="email" name='email' />
                   </div>
                   <div className="form-group my-3 password-container">
                       <label htmlFor="password">كلمة السر</label>
                       <input type="password" className="form-control" onChange={(e)=>setUser({...user , password : e.target.value})} value={user.password} id="password" name='password' />
                        <button onClick={passwordHandler}>
                            {
                                user.password && user.password.length > 0 ?
                                !state ?  <img src="/icos/eye.png" alt=""/> : <img src="/icos/eye-slash.webp" alt=""/> : <></>
                            }
                        </button>
                   </div>
                  <div className="text-center mt-4 d-flex align-items-center justify-content-center">
                      <button className="add-btn" onClick={handleLogin}>
                          تسجيل الدخول
                      </button>
                  </div>

           </div>
        </div>
    )
}

export default Login
