import React, {useState} from "react";
import {_date, deleteFormHandler, Grid, Signboard} from "../../../config/imports";
import {invoices_api} from "../../../config/apis";

export default function DeleteSalesInvoice(props){
    const  {toggle , item , setMainData , header } = props
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [loading, setLoading] = useState(false);
    const [confirmBtn, setConfirmBtn] = useState(true);

    const handleDelete = async () => {

        const delete_props = {
            url: invoices_api.custom("feedbuy"),
            id: item._id,
            setError,
            setSuccess,
            setLoading,
            setConfirmBtn,
            setMainData,
            item,
        };
        console.log({delete_props})
        await deleteFormHandler(delete_props);
    };
    return<>
        <div className="form-delete">
        <header className="w-fit m-auto px-4 text-center border-bottm-line ">
            <h3>هل انت متاكد من حذف هذا العنصر ؟</h3>
        </header>

        <Signboard error={error} success={success} loading={loading}/>

            <Grid.Flex className={'data-table-head mt-3'}>
                <Grid.Span size={4}>  رقم الفاتورة </Grid.Span>
                <Grid.Span size={4}> التاريخ </Grid.Span>
                <Grid.Span size={6}>  الكمية الكلية من الكرتونة </Grid.Span>
                <Grid.Span size={4}>السعر الاجمالى</Grid.Span>
            </Grid.Flex>

        <Grid.Flex className={'align-items-center data-row'}>
            <Grid.Span  size={4} >{item.invNumber}</Grid.Span>
            <Grid.Span  size={4} >{ _date(item.date).simple('date') }</Grid.Span>
            <Grid.Span  size={6} >{item.total_from_box || item.total_amount}</Grid.Span>
            <Grid.Span  size={4} >{item.total_price}</Grid.Span>
        </Grid.Flex>
            <div className="col-12 col-md-12 mt-4">
                <div className="form-group row justify-content-between align-items-center">
                    <div className="col">
                        <button className="close-btn" onClick={() => toggle()}>
                            {confirmBtn ? "الغاء" : "اغلاق"}
                        </button>
                    </div>
                    <div className="col d-flex justify-content-end">
                        {confirmBtn && (
                            <button className="add-btn" onClick={handleDelete}>
                                نعم
                            </button>
                        )}
                    </div>
                </div>
            </div>

        </div>

    </>
}
