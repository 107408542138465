import React from 'react';
import { Link } from 'react-router-dom';

export default function BreadCrumbItem({ name, to, className, style }) {

  return (
    <Link to={to} className={className} style={style}>
      <span className="text-[#505050] text-[16px] hover:text-[#000]">{name}</span>
    </Link>
  )
}
