// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.assets-data {
  background-color: #fff;
  border-radius: 16px;
  height: 100%;
}
.assets-data .asset-card {
  padding: 20px 40px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
  margin: 20px;
  text-align: center;
  border-radius: 10px;
  min-width: 230px;
}
.assets-data .asset-card .asset-card-value {
  font-size: 36px;
  font-weight: 600;
  background-color: #FFF6E8;
  padding: 5px 10px;
}
.assets-data .asset-card .asset-card-title {
  font-size: 20px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/styles/origins.styles.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EACE,kBAAA;EACA,8CAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,gBAAA;AAEJ;AADI;EACE,eAAA;EACA,gBAAA;EACA,yBAAA;EACA,iBAAA;AAGN;AADI;EACE,eAAA;EACA,gBAAA;AAGN","sourcesContent":[".assets-data{\n  background-color: #fff;\n  border-radius: 16px;\n  height : 100%;\n  .asset-card{\n    padding:  20px 40px;\n    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);\n    margin :  20px;\n    text-align: center;\n    border-radius: 10px;\n    min-width: 230px;\n    .asset-card-value{\n      font-size: 36px;\n      font-weight: 600;\n      background-color: #FFF6E8;\n      padding: 5px 10px;\n    }\n    .asset-card-title{\n      font-size: 20px;\n      margin-top: 20px;\n    }\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
