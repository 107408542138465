import React from 'react'
import PropTypes from 'prop-types'
import useEggsWarehouseComponentData from './useEggsWarehouseComponentData'
import EggsWarehouseBoxes from './EggsWarehouseBoxes'
import EggsWarehouseTable from './EggsWarehouseTable'

function EggsWarehouseComponent(props) {
    const {filter} = props
    const {Alert , data  } = useEggsWarehouseComponentData(filter , props.deps)


    return (
        <>
        <div className='page mt-4'>
            <Alert />
            <EggsWarehouseBoxes data={data.totals_egg_process} />
        </div>
        <div className='page mt-4'>
            <Alert   />
            <EggsWarehouseTable data={data.egg_info_in_array} rest={data}  />
        </div>
        </>
    )
}

EggsWarehouseComponent.propTypes = {
    filter : PropTypes.object.isRequired,
    deps : PropTypes.array
}

export default EggsWarehouseComponent
