import React, { useEffect, useState } from 'react'
import Label from './base/Label'

export default function InputFile(props) {
  

  const fileChangeHandler = async (e) =>{
      props.handleInputChange(e)
  }





  return (
    <div className={props.className ? props.className  + ' form-input  form-group ' : "col-6 col-md-6 form-group form-input"}>
                    {/* <div data-error="" className="form-group"> */}
                        {/* <label htmlFor={props.name ?? props.id}>ا{props.title ??  props.label ?? props.placeholder?? 'اختر صورة'}</label> */}
                        <Label {...props} />
                        <input
                            type="file"
                            name={props.name ?? props.id}
                            id={props.name ?? props.id}
                            required
                            placeholder={props.placeholder ?? props.title ?? 'اختر صورة'}
                            accept="image/*"
                            onChange={fileChangeHandler}
                            className="hide-arrow form-control"
                        />
                    {/* </div> */}
    </div>
  )
}
