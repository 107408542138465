import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import axiosPost from '../../../helpers/axios/axios.post'
import { apis } from '../../../config/apis'
import Signboard from '../../../components/Signboard'
import useHomeBreadCrumbs from './useHomeBreadCrumbs'

export default function useHomeDetailsData() {

    const {id} = useParams()
    const [state , setState] = React.useState({})
    const [loading , setLoading] = React.useState(false)
    const [error , setError] = React.useState(null)


    useEffect(() => {

        const controller = new AbortController();
        const signal = controller.signal;
    
        const  fetch = async () => {
            setError(null)
            setLoading(true)
            const response = await axiosPost(apis.homes.landing() , {location_id : id} , undefined , signal)

            setLoading(false)

            if (response.error) {
                setError(response.error)
                return
            }

            setState(response.data)


            // console.log(response.data);

        }



        fetch()

        // return () => {
        //     controller.abort(); // Cleanup function to cancel the request
        //   };


    } , [id])



  return{
    data : state,
    loading ,
    error,
    Alert : () => <Signboard error={error} loading={loading} />
  }
}
