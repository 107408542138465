import React from 'react'
import useNavigator from '../../../hooks/useNavigator';
import LinkList from '../../../components/LinkList';
import "../../../styles/orgin.cards.styles.scss"

export default function OriginCardsIndex() {


    const ORIGIN_CARDS_LINKS = [
        {
          name: "  الحضانات",
          path: "incubators",
        },
        {
          name: " الفقاسات",
          path: "hatcheries",
        },
        {
          name: "الارض",
          path: "lands",
        },
        {
          name: "المباني",
          path: "buildings",
        },
        {
          name: "بيوت الدواجن",
          path: "poultry-houses",
        },
        {
          name: "الالات والمعدات",
          path: "machine",
        },
        {
          name: "السيارات",
          path: "cars",
        },
        {
          name: "اصل حيوي",
          path: "biological",
        },
        {
          name: "الطرق",
          path: "roads",
        },
      ];
    
      useNavigator("origin-cards", "incubators");

  return (


   <div className="base-card origin-cards">
    <div className="ilc mt-2 bg-white px-3 py-3 r-16">
      <div className="icbc">
        <div className="base-card-links-container">
          <LinkList data={ORIGIN_CARDS_LINKS} underline={true} className={'card-index'}/>
        </div>
      </div>
    </div>
  </div>


  )
}
