import {InputText} from "../../../../config/imports";

const vehiclesInputs = [
    {name : 'name', title :  'الاسم', input :   InputText},
    {name : 'characteristic', title :  'صفة السيارة' , input :  InputText},
    {name : 'model', title :  'صفة السيارة' , input :  InputText},
    {name : 'color', title :  'اللون', input :   InputText},
    {name : 'plate_number', title :  'رقم اللوحة' , input :  InputText},
    {name : 'structure_number', title :  'رقم الهيكل' , input :  InputText},
    {name : 'device_value', title :  'قيمة المعدة' , input :  InputText , size : 3},
    {name : 'account_name', title :  'أسم الحساب' , input :  InputText , size : 3},
    {name : 'account_symbol', title :  'رمز الحساب' , input :  InputText , size : 3},
    {name : 'location', title :  'موقعها', input :   InputText , size : 3},
]

export default vehiclesInputs
