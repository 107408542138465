import InputNumber from "./InputNumber"
import InputText from "./InputText"
import InputTextarea from './InputTextarea'
import InputSearch from "./InputSearch";
import InputSelect from "./InputSelect";
import InputFile from "./InputFile";
import InputEmail from "./InputEmail";
import InputPassword from "./InputPassword";
import InputDate from "./InputDate";
import InputTime from "./InputTime";
import InputRadio from "./InputRadio"
import InputDetail from "./InputDetail";
import InputImage from "./InputImage";

const Inputs = {
    Text : InputText,
    Number :  InputNumber,
    TextArea : InputTextarea,
    Search : InputSearch,
    Select : InputSelect,
    File  : InputFile,
    Email : InputEmail,
    Password : InputPassword,
    Date : InputDate,
    Time : InputTime,
    Radio : InputRadio,
    Detail : InputDetail,
    Image : InputImage,
    Toggle : InputRadio,

}
export default Inputs
