import React from "react";
import { InputSelect } from "../../../config/imports";
import useSelectInvoiceStore from "../stores/select-invoice.store";
import { classificationOptions } from "../data/invoice-selectors.data";
import BuyInvoiceSelectors from "./BuyInvoiceSelectors";
import SellInvoiceSelectors from "./SellInvoiceSelectors";

export default function InvoiceSelectors() {

  const setClassification = useSelectInvoiceStore( (state) => state.setClassification );
  const classification = useSelectInvoiceStore((state) => state.classification);
  const hasAllSelected = useSelectInvoiceStore((state) => state.hasAllSelected() );

  return (
    <div className="">
      <header className="text-center">
        <h3 className="text-[22px]"> انشاء فاتورة </h3>
        <p>حدد مكان ونوع الفتورة ثم انشاء فاتورتك </p>
      </header>

      <div className="w-[80%] mx-auto">
        <InputSelect
          title={"تصنيف الفاتورة"}
          handleInputChange={(e) => setClassification(e.target.value)}
          className={"col-12 mb-3 col-md-12 col-lg-12 col-sm-12"}
          name="classification"
          defaultValue={classification}
          options={classificationOptions}
        />
        {classification !== "" && classification === "buy" && ( <BuyInvoiceSelectors /> )}
        {classification !== "" && classification === "sell" && ( <SellInvoiceSelectors /> )}

        <div className="row justify-content-center mt-3">
          <div className="col-12 col-sm-12 col-lg-3 col-md-4 flex justify-center">
            <button
              className="add-btn whitespace-nowrap"
              disabled={!hasAllSelected}>
              انشاء فاتورة
              {classification == "buy" && (
                <span className=" text-white"> شراء</span>
              )}
              {classification == "sell" && (
                <span className=" text-white"> بيع</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
