import React from 'react'
import InvoiceSelectors from './InvoiceSelectors'
import LatestInvoices from './LatestInvoices'
import useSelectInvoiceStore from '../stores/select-invoice.store'

export default function InvoicesLayout() {



  return (
    <div className='p-4 page flex flex-col h-full'>
        <div className="flex-1 min-h-[400px]">
          <InvoiceSelectors />
        </div>
        <div className="h-[300px]">
          <LatestInvoices />
        </div>
    </div>
  )
}
