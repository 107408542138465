import FormInput from "./FormInput";

const InputCheckBox = (props) => {

    const {className , handleInputChange , name, title , defaultValue, isReadonly} = props
    return (

        <>
            <FormInput
                {...props}
                type={"checkbox"} />
        </>

    );

}

export default InputCheckBox;