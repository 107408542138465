import React from "react";
import { TableHeader } from "../../../components/TableComponent";
import { TableView } from "../../../components/TableView";
import useFetch from "../../../hooks/useFetch";
import PageMeta from "../../../components/PageMeta";
import CreateStrain from "./forms/create/CreateStrain";
import ProductionDetails from "./forms/details/ProductionDetails";
import Signboard from "../../../components/Signboard";
import { strainAPI } from "./bird_breed.routes";
import DeleteStrain from "./forms/delete/DeleteStrain";
import QuickView from "../../../components/QuickView";
import UpdateProduction from "./forms/update/UpdateProduction";

const Production = () => {
  const { data: strains, error, setData: setMainData, isLoading, setRefresh } = useFetch(strainAPI.url);

  const productionDetailsRef = React.createRef();
  const createRef = React.createRef();
  const updateRef = React.createRef();
  const deleteRef = React.createRef();

  // console.log(strains);
  const table = [
    { name: "idx", col: 1, title: "م" },
    { name: "name", col: 4, title: "اسم السلالة" },
    { name: "production_age", col: 2, title: "عمر  المرحلة" },
    { name: "settings", col: 1, title: "" },
    { name: "details", col: 2, title: "", className: "text-center",
      custom: (item) => 
          <>
            <button
              className="btn"
              onClick={() => productionDetailsRef.current.toggle(item)}>
              تفاصيل <i className="fa-solid fa-angle-left"></i>
            </button>
          </>
    },
  ];

  return (
    <>
      <PageMeta title={"قائمة سلالة الطيور"} />

      <QuickView ref={createRef}>
        <CreateStrain setMainData={setMainData} setRefresh={setRefresh} />
      </QuickView>

      <QuickView ref={updateRef}>
        <UpdateProduction setMainData={setMainData}  setRefresh={setRefresh} />
      </QuickView>

      <QuickView ref={deleteRef}>
        <DeleteStrain setMainData={setMainData}  setRefresh={setRefresh} />
      </QuickView>

      <QuickView ref={productionDetailsRef}>
        <ProductionDetails setMainData={setMainData}  setRefresh={setRefresh} />
      </QuickView>

      <Signboard error={error} loading={isLoading} />

      <TableHeader
        component={"breeding"}
        buttons={[
          {
            title: "اضافة سلالة جديدة",
            onclick: () => {
              createRef.current.toggle();
            },
          },
        ]}
      />
      <TableView
        data={strains}
        body={table}
        updateRef={updateRef}
        deleteRef={deleteRef}
      />
    </>
  );
};

export default Production;
