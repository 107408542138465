import { useState } from "react";

import Label from "./base/Label";
import "../../../styles/inputDate.styles.scss"
import { _date } from "../../../helpers/date.helpers.js";

export default function InputDate(props){
    const {name , type , className , title , placeholder , id , handleInputChange  , value , defaultValue , required} = props

    console.log({handleInputChange })
    const [state, setState] = useState(_date(new Date()).forInputDate());

    const dateChangeHandler = (e) =>{
        const { value } = e.target
        setState(_date(value).forInputDate())
        handleInputChange(e)
    } 


    return <div className={className ? className + ' formated-input-date '  : ' formated-input-date col-4 '}>



            <Label {...props} />

            <input
                type={'date'}
                name={name ?? id ?? title}
                className={`form-control mt-2`}
                title={title ?? placeholder ?? id}
                id={id ?? name ?? title}
                placeholder={placeholder ?? title ?? id ?? name}
                onChange={dateChangeHandler}
                required={required ?? true}
                defaultValue={defaultValue}
                value={ value }
                data-formatted-date={state}
            />
        </div>
}
