import {useState} from "react";
import {apis, reports_api, weight_api} from "../../../../config/apis";
import {setInputsToDefault, updateFormHandler} from "../../../../helpers/form.helper";
import {
    EditForm,
    InputSelect,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard, updateData
} from "../../../../config/imports";

import ArrayHelpers from "../../../../helpers/array.helpers";
import postData from "../../../../helpers/axios/axios.post";
import {originAPI} from "../../origin.helpers";
import {errorHandler} from "../../../../helpers/error.helper";

export default function Update(props){

    const {toggle , item , setMainData , operators } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        name:item.name,
        types:item.types,
        operator:item.operator?._id,
        img:item.img,
        info:item.info,
    })

    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();


        const {
            data: postedData,
            error,
            statusCode,
        } = await updateData(apis.origins.maintenance.update(item._id), data  , {
            headers :{
                'Content-Type': 'multipart/form-data',
            }
        });
        if (error) {
            setLoading(false);
            setSuccess("");
            return setError(errorHandler(error));
        }
        if (statusCode === 201 && postedData) {
            setLoading(false);
            setSuccess("تم تعديل الصيانة بنجاح");
            setMainData((prevState) => {
                const itemIndex = prevState.findIndex(
                    (item) => {
                        if (postedData.data) return item._id === postedData.data._id
                        else return  item._id === postedData._id
                    }
                );
                prevState[itemIndex] = postedData.data || postedData;
                return [...prevState];
            });
        }


    };
    // console.log({item})
    return(
        <>
            <PageMeta title={'تعديل نوع الصيانه'} originTitle={'الصيانه'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>تعديل نوع الصيانه</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
              {/*<>*/}

                <InputText onChange={handleInputChange} className={'col-6'} name={'name'} title={'الاسم'} defaultValue={data.name} />
                      <div className="my-3 col-6 col-md-6">
                          <div data-error="" className="form-group">
                              <label htmlFor="img">اختر صورة</label>
                              <input
                                  type="file"
                                  name="img"
                                  id="img"
                                  placeholder="اختر صورة"
                                  accept="image/*"
                                  onChange={(e) =>
                                      setData((prevState) => ({
                                          ...prevState,
                                          img: e.target.files[0],
                                      }))
                                  }
                                  className="hide-arrow form-control"
                              />
                          </div>
                      </div>
                      <InputSelect onChange={handleInputChange} className={'col-6'} name={'operator'} title={'المورد'} defaultValue={data.operator}  options={ ArrayHelpers.makeOptionsForInputSelect(operators)} />
                      <InputSelect  onChange={handleInputChange} className={'col-6'} name={'types'} title={'النوع'} defaultValue={data.types}  options={[
                          {_id : "تركي" , name : "تركي" },
                      ]}
                      />

                      <InputTextArea name={'info'} title={'التفاصيل'} defaultValue={data.info} />


              {/*</>*/}


                </EditForm>
        </>
    )
}
