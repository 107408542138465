import {useState} from "react";
import {reports_api, weight_api} from "../../../../../config/apis";
import {setInputsToDefault, updateFormHandler} from "../../../../../helpers/form.helper";
import {
    _date,
    EditForm,
    InputDate,
    InputNumber,
    InputText,
    InputTextArea,
    PageMeta,
    Signboard
} from "../../../../../config/imports";
import {useParams} from "react-router-dom";

export default function Update(props){

    const {id} = useParams();
    const {toggle , item , setMainData } = props

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [data , setData] = useState({
        date :_date(item.date).forInputDate(),
        dead_chicken : item.dead_chicken,
        dead_rooster : item.dead_rooster,
        home : id
    })
    const handleInputChange = (e) => {
        const {name, value , type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };

    const handleFormSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();

        const update_props = {
            url: reports_api.home.dead.update(item._id),
            data,
            setLoading,
            setError,
            setSuccess,
            setMainData,
            setInputsToDefault,
            setData,
            FORM_HEADER: `تعديل`,
        };
        // console.log(update_props)


        await updateFormHandler(update_props);
    };
    return(
        <>
            <PageMeta title={'تعديل تقرير النافق يومي'} originTitle={'تقارير النافق'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>   تعديل تقرير النافق يومي</h3>
            </header>

            <Signboard error={error} success={success} loading={loading}/>

            <EditForm
                visible={props.showFooter === undefined}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}>
                <InputDate name={'date'}   handleInputChange={handleInputChange}  placeholder={'B'}  title={'التاريخ'} defaultValue={data.date}/>
                <InputNumber name={'dead_chicken'}   handleInputChange={handleInputChange} placeholder={'5'}  title={'نافق الدجاج'} defaultValue={data.dead_chicken}/>
                <InputNumber name={'dead_rooster'}   handleInputChange={handleInputChange} placeholder={'3'}  title={'نافق الديوك'} defaultValue={data.dead_rooster}/>
            </EditForm>
        </>
    )
}
