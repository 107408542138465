import EggDishesIndex from "./EggDishesIndex"
import {Route} from "react-router-dom"
const egg_dishes_routes = {
    router : (protect)=>{
      return <Route path="egg-dishes" element={protect(<EggDishesIndex />)} />
    }
  }
  
  
  
  
  export default egg_dishes_routes