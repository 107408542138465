import React, { useEffect, useState } from 'react'
import { apis } from '../../../config/apis'
import useFetch from '../../../hooks/useFetch'
import { _date } from '../../../helpers/date.helpers'
import { Link } from 'react-router-dom'
import Icons from '../../../layout/Icons'
import { Table } from '../../../layout/table'
import ProjectsFilter from '../components/ProjectsFilter'
import DepartmentsFilter from '../components/DepartmentsFilter'
import HomesFilter from '../components/HomesFilter'
import DateFilter from '../components/DateFilter'
import PageMeta from '../../../components/PageMeta'
import { TableHeader } from '../../../components/TableView'
import { setBreadCrumbsStore } from '../../../components/breadcrumbs/store/breadcrumb.store'
import { homeBreadCrumb, oldApprovalsBreadCrumb } from '../../../components/breadcrumbs/data/breadcrumbs.data'

export default function ApprovedReports() {

    const api = apis.approvals.paginatedApproved()

    const [query , setQuery] = useState({
        home : "",
        department : "",
        project : "",
        start_date : "",
        end_date : "",
        week_age : "",
      })

    const {data , Alert , setters , paginate } = useFetch(api   , query)



    const table = [
        {size : 1 , name : "#" , text : "#"  },
        {size : 2 , name : "ar_type" , text : "النوع"  },
        {size : 3 , name : "home" , text : "البيت"  },
        {size : 3 , name : "department" , text : "القسم"  },
        {size : 3 , name : "project" , text : "المشروع"  },
        {size : 3 , name : "date" , text : "تاريخ الانشاء"  , custom : d => _date(d.date).dmy()   },
        {size : 3 , name : "created_by" , text : "اُنشئ بواسطة"  },
        {size : 3 , name : "last_updated_by" , text : "تم التحديث بواسطة"  },
        {size : 3 , name : "date" , text : ""  , custom : d => {
           return <Link to={"/approvals/" + d._id} className='flex items-center'>
                    <span className='ml-2'> تفاصيل  </span>
                    <div className='scale-[.9]'>
                      <Icons.Arrow />
                    </div>
           </Link>
        }},
  
      ]

      useEffect(()=>{
        setBreadCrumbsStore([
            homeBreadCrumb,
            oldApprovalsBreadCrumb,
        ])
    } , [])






  return (
    <>

    <div className='page'>
        <PageMeta title={"التقارير"}  />
        <TableHeader buttons={[]}   api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate}  />
        <div className="row items-center ">
            <div className="col-3"><ProjectsFilter setQuery={setQuery} /> </div>
            <div className="col-3"><DepartmentsFilter setQuery={setQuery} /> </div>
            <div className="col-3"><HomesFilter setQuery={setQuery} /> </div>
            <div className="col-3 relative top-[-4px]"><DateFilter setQuery={setQuery} /> </div>
        </div>
        <Alert />
        <Table data={data}  table={table} />
    </div>
            
    </>
  )
}
