import { NavLink, useParams } from "react-router-dom";
import { BlueBox, GreenBox, useFetch, PageMeta, Signboard } from "../../config/imports";
import { projects_api } from "../../config/apis";
import "../../styles/boxs.styles.scss";
import Helpers from "../../helpers/helpers";
import useEggsWarehouseComponentData from "../../components/eggs_warehouse/useEggsWarehouseComponentData";
import EggsWarehouseBoxes from "../../components/eggs_warehouse/EggsWarehouseBoxes";
import EggsWarehouseTable from "../../components/eggs_warehouse/EggsWarehouseTable";
import useBreadCrumb from "../../components/breadcrumbs/hooks/breadcrumb.hook";
import { useEffect } from "react";
import { setProjectsBreadCrumbs } from "../../components/breadcrumbs/store/breadcrumbs.default";

const Departments = ()=>{



    const id = useParams().id


    const {data}  = useBreadCrumb({id , name : "project"})



    useEffect(() => {
        if(!data || !data.length) return
        setProjectsBreadCrumbs.departments(...data)
    } , [data])
 
    // useEffect(async() => {
    //     const res = await fetch()
    //     console.log({res})
    // } , [])



    // const { Alert , data : eggsData  } =  useEggsWarehouseComponentData(
    //     {
    //         projects_ids : [id]
    //     }
    // )

    // console.log(eggsData);


    const { data : departments ,error : departments_error ,isLoading : departments_isLoading} = useFetch(projects_api.departments(id))

    const { data : boxes , error : boxes_error , isLoading : boxes_isLoading} = useFetch(projects_api.boxes(id))


    const green_data = [
        {img : '/icos/receipt.png' , title : 'كمية فواتير الاعلاف  بالطن' , value : boxes?.green_boxes?.invoices_feed_amount    },
        {img : '/icos/usage.png' , title : 'استهلاك الاعلاف بالطن' , value : boxes?.green_boxes?.feed_consumption_in_ton    },
        {img : '/icos/chart.png' , title : 'عدد النافق الكلى' , value : boxes?.green_boxes?.total_dead},
        {img : '/icos/coin.png' , title : 'الانتاجية' , value : boxes?.green_boxes?.productivity?.toFixed(2)},
    ]
    const blue_data = [
        {title : 'عدد الطيور التراكمي' , value :  Helpers.Number.commaFormat(boxes?.blue_boxes?.cumulative_number_of_birds?.toFixed(2)) },
        {title : 'عدد الطيور الانتاج' , value :  Helpers.Number.commaFormat(boxes?.blue_boxes?.total_production_birds?.toFixed(2)) },
        {title : 'عدد طيور التربية' , value :  Helpers.Number.commaFormat(boxes?.blue_boxes?.total_breeding_birds?.toFixed(2)) },
        {title : 'عدد البيض المنتج' , value :  Helpers.Number.commaFormat(boxes?.blue_boxes?.total_produced_eggs?.toFixed(2)) },
        {img : '/icos/balance.png' , title : 'رصيد الانتاج' , value :  Helpers.Number.commaFormat(boxes?.blue_boxes?.production_balance?.toFixed(2)) },
        {img : '/icos/money-send.png' , title : 'استهلاك الاعلاف بالطن' , value :  Helpers.Number.commaFormat(boxes?.blue_boxes?.feed_consumption_in_ton?.toFixed(2)) },
    ]





  

return(
        <div className="departments py-5">
            <PageMeta title="الاقسام" />
            <div className="row green-box-container justify-content-center mt-5 mb-2 g-2 w-100">
                {
                    green_data.map((data , idx) => (
                        // <div className='col-12 col-sm-6 col-md-6 col-lg-3' key={idx}>
                            <GreenBox img={data.img} title={data.title} value={data.value} key={idx}/>
                        // </div>
                    ))
                }
            </div>
            <div className="row blue-box-container justify-content-center mt-2 g-2">
                        {
                            blue_data.map((data , idx) => (
                                // <div className='col-12 col-sm-6 col-md-4 col-lg-2' key={idx}>
                                    <BlueBox img={data?.img} title={data.title} value={data.value} key={idx}/>
                                // </div>
                            ))
                        }
            </div>

    <Signboard error={departments_error || boxes_error} loading={departments_isLoading || boxes_isLoading} />


    {/* <div className="w-[70%] mx-auto">
        <EggsWarehouseBoxes data={eggsData.totals_egg_process}  />
    </div> */}
    <div className="departments-links-container">

        <div className="departments-types">
            {departments && departments.departments && departments.departments.length > 0 && departments.departments.map(( d , i )=> 
                          <NavLink to={`/departments/${d._id}/homes/base`} className="department-box-link" key={i}> 
                                {d.name} 
                           </NavLink>
                
            )}

        </div>
    </div>



        {/* <div className="w-[70%] mx-auto mt-4">
            <EggsWarehouseTable data={eggsData.egg_info_in_array} rest={eggsData}   />
        </div> */}



        </div>
    )
}

export default Departments;
