import {_date} from "../config/imports";

const objectHelpers = {

    hasDate : obj => {
        if(!obj) return
        const dateKeys = []
        const entries = Object.entries(obj)
        for( const entry of entries ){
            const [key , value] = entry
            if (key.toLowerCase().includes('date')){
                dateKeys.push(key)
            }
        }

        return dateKeys
    },
    formatDateForInput : (obj) => {
       const dateKeys = objectHelpers.hasDate(obj)
       if(dateKeys && dateKeys.length > 0){
           for( const key of dateKeys ){
               obj[key] = _date(obj[key]).format('yyyy-MM-dd')
           }
           return obj
       }
    }


}


export default objectHelpers