import React from 'react'
import EggsWarehouseTable from '../../../components/eggs_warehouse/EggsWarehouseTable'

export default function HomeEggsWarehouse({data}) {

    
  return data && data.egg && (
   <div className='page'>
       <EggsWarehouseTable data={data.egg.egg_info_in_array} rest={data.egg}  />
   </div>
  )
}
