import React, { useEffect, useRef } from 'react'
import useStock from '../useStock'
import QuickView from '../../../components/QuickView'
import PageMeta from '../../../components/PageMeta'
import { TableHeader } from '../../../components/TableView'
import { Table } from '../../../layout/table'
import CreateTransfer from '../forms/CreateTransfer'
import { useParams } from 'react-router-dom'

export default function MaintenanceTab() {
  const { id } = useParams()
  const createRef = useRef()
  const {  Alert   , setRefresh  , quantities , fetchQuantities , quantitiesTable} =  useStock()
  useEffect(() => {
    fetchQuantities({ category :  "maintenance", location_id : id, location :  "stock" })
  } , [])
  return (
    <>
         <QuickView ref={createRef}  className="overflown-popup"><CreateTransfer type="maintenances" header="صيانه " setRefresh={setRefresh} /></QuickView>
          <div className='page base-card'>
            <Alert />
            <PageMeta title=" الصيانه " />
            <TableHeader
            //  buttons={[{ title : "إضافة حركه صيانه جديده" , onclick : () => createRef.current.toggle() }]}
              />
            <Table data={quantities?.totals_by_category_and_subcategory} table={quantitiesTable} />
        </div>
    </>
  )
}
