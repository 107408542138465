import React from 'react'
import Helpers from '../../../helpers/helpers';


export default function Stat(props) {
  return (
    <div className='flex-1 p-2 '>
        <div className="">

            <div className='text-[20px] bg-[#41E481] text-white text-center p-2 py-2 rounded-[10px_10px_0_0]  '>
              {props.ar_process}
              </div>
              {/* <hr className='m-0 bg-white' /> */}
            <div className='text-[18px] text-white flex  items-center justify-center  bg-[#41E481]  text-center p-2 py-2 rounded-[0_0_10px_10px] '>
              {Helpers.Number.commaFormat( props.amount || 0 )}
               <span className='text-[14px] mx-2 text-white'>بيضه</span>
            </div>
        </div>
      
    </div>
  )
}
