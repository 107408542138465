// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.operators-container .data-table-head {
  padding: 10px 0;
}
.operators-container .data-table-body-item {
  font-size: 14px;
  padding: 5px;
}`, "",{"version":3,"sources":["webpack://./src/styles/operators.styles.scss"],"names":[],"mappings":"AAEE;EACE,eAAA;AADJ;AAGE;EACE,eAAA;EACA,YAAA;AADJ","sourcesContent":[".operators-container{\n\n  .data-table-head{\n    padding: 10px 0;\n  }\n  .data-table-body-item{\n    font-size: 14px;\n    padding: 5px;\n  }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
