import { useCallback, useEffect, useMemo, useState } from 'react';
import useFetch from './useFetch';
import { apis } from '../config/apis';

export default function useHomeHierarchical() {

    const [state, setState] = useState([]);

    const {data , alert} = useFetch(apis.homes.homes_details()+ "?place_type=home")


    useEffect(() => {
        if(data) setState(data)
    },[data])


    const getHomeWithId = useCallback((id) => {
        if(!state.length) return undefined
        return  state.find(home => home.home_id === id)
    } , [state])

    const getStockHomes = useCallback((stock_id) => {
        if(!state.length) return undefined
        return state.filter(home => home.stock_id === stock_id)
    } , [state])
    const getProjectHomes = useCallback((project_id) => {
        if(!state.length) return undefined
        return state.filter(home => home.project_id === project_id)
    } , [state])

    const getLevelHomes = useCallback((level_id) => {
        if(!state.length) return undefined
        return state.filter(home => home.level_id === level_id)
    } , [state])


    const getHomeStock = useCallback((home_id)=>{
        if(!state.length) return undefined
         const home = getHomeWithId(home_id)
         return {
             _id : home.stock_id ,
             name : home?.stock
         }
    })

   const mapHomes = useCallback((fn) => {
        if(!state.length) return undefined
        // for (const home of state) 
        //     return fn(home)
        return state.map(home => home.home_id &&  fn(home)).filter(home => home !== undefined && home !== null)
        
   } , [state])

   const filterHomes = useCallback((filter) => {
        if(!state.length) return undefined
        // console.log("hello");
        // console.log({filter});
        const homes = mapHomes((home)=>{
            if(!filter) return home
            else return  home[filter.key] === filter.value ? home : null
        })
        return  homes
   })
    


    const homesOptions = useCallback((filter) => {
        if(!state.length) return []
        const homes = filterHomes(filter)
        return homes.map( h =>({ text : h.home , value : h.home_id }) )
    } , [state])




    const allHomesOptions = useMemo(() => {
        return homesOptions()
    } , [state])


    const getOptionText = useCallback((home_id) => {
        return homesOptions({key : "home_id" , value : home_id})[0]?.text
    }, [state])

    

    


    
    


  return {
        getHomeWithId ,
        getStockHomes ,
        getProjectHomes ,
        getLevelHomes , 
        state , 
        alert ,
        getters : {
            homeWithId : getHomeWithId,
            homeStock : getHomeStock
        },
        filterHomes,
        options : {
            all : allHomesOptions,
            getText : getOptionText
        }
    }
}
