import {
    PageMeta,
    createFormHandler,
    EditForm,
    Signboard,
    InputText,
    InputNumber,
    InputSelect,
    postData
} from "../../../../../config/imports";
import {useState} from "react";
import {apis} from "../../../../../config/apis";
import DisplayFileImagesGroup from "../../../../../components/images/DisplayFileImagesGroup";
import Axios from "../../../../../helpers/axios";

export default function Create (props){

    const {setMainData, toggle} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");


    const [data , setData] = useState({
        type : "hatchery",
        images : []
    })

    let signboard = {
        error : error  ,
        success,
        loading : loading ,
    }


    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };


    const handleFormSubmit = async (e) => {

     
        e.preventDefault()

        setError('')
        setSuccess('')

        setLoading(true)
        const {data: postedData, error, statusCode } = await Axios.file.post(apis.origins.incubators.create(), data )

        setLoading(false)

        if (error || !postedData)   return setError(error)
        
        setSuccess("تم اضافة الفقاسه بنجاح")

        setMainData("refresh-api")

    
    };



    return(
        <>
            <PageMeta title={'إضافة فقاسه'} originTitle={' الفقاسات'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>إضافة فقاسه</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >

                <InputText name={'brand'} title={'الماركة'} placeholder={'Petersime'} value={data.brand}/>
                <InputText name={'area'} title={'المساحة'} placeholder={'4*4'} value={data.area}/>
                <InputText name={'location'} title={'موقعه '} placeholder={'غرفة الحاضنات'} value={data.location}/>

                <InputSelect name={'trolleys_type'} title={'النوع'}  value={data.trolleys_type}  options={[
                    { value : 'اطباق' , text : 'اطباق'},
                    { value : 'صندوق' , text : 'صندوق'},
                ]} />

                <InputNumber name={'trolleys_type_count'} title={'عدد النوع'} placeholder={'87454'} value={data.trolleys_type_count}/>
                <InputNumber name={'trolleys_type_eggs'} title={'عدد  البيض في النوع'} placeholder={'453'} value={data.trolleys_type_eggs}/>
                <InputNumber name={'trolleys_count'} title={'عدد التروليات'} placeholder={'87454'} value={data.trolleys_count}/>
                <InputNumber name={'total_eggs'} title={'مجموع البيض '} placeholder={'63456'} value={data.total_eggs}/>
                <>
                    <div className="col-4 col-md-4 ">
                        <div data-error="" className="form-group">
                            <label htmlFor="images"> صورة  الفاتورة	</label>
                            <input
                                type="file"
                                name="images"
                                id="images"
                                required
                                placeholder="اختر صورة"
                                accept="image/*"
                                multiple
                                onChange={(e) =>
                                    setData((prevState) => ({
                                        ...prevState,
                                        images: [ ...prevState.images, ...e.target.files ], 
                                    }))
                                }
                                className="hide-arrow form-control"
                                />
                        </div>
                    </div>
                </>

              <>
                <DisplayFileImagesGroup files={data.images} setData={setData} keyName={'images'} />
              </>
                
            </EditForm>


        </>
    )
}
