import {projectsAPI} from "../../../../projects.helper"

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    SuperDelete,
    addColumn,
    InputDate,
    InputNumber,
    InputTextArea,
    InputText,
    extractTableData,
    SuperCreate, SuperUpdate
} from '../../../../../../config/imports';


const DrugsAndFortificationsReports = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {

            date: new Date(),
            inv_number: 112,
            name: "15515",
            quantity: 15,
            unit_price: 15,
            total_price: 15,
            expire_date: new Date(),
            extra: ""

        }, {

            date: new Date(),
            inv_number: 112,
            name: "101020",
            quantity: 15,
            unit_price: 15,
            total_price: 15,
            expire_date: new Date(),
            extra: ""

        },
    ]

    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "طلب شراء ادوية",
            onclick: () => {
                createRef.current.toggle();
            },
        }
    ];



    const main_title = "قائمة فواتير شراء الادوية و التحصينات";
    const table = [
        addColumn("", "م", ''),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },

        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        addColumn('name', 'الاسم', InputText, 2),
        addColumn('quantity', 'الكمية', InputNumber),
        addColumn('unit_price', 'سعر الوحدة', InputNumber),
        addColumn('total_price', 'السعر الاجمالي', InputNumber, 2),
        {
            gen: {name: "expire_date", title: "تاريخ انتهاء الصنف", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.expire_date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('extra', 'معلومات اضافية', InputTextArea, 2),
        addColumn("settings", "", ''),

    ];

    const views =
        [
            {
                ref: createRef,
                component: <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"طلب شراء ادوية وتحصينات"}
                    table={table}
                />
            },
            {
                ref: updateRef,
                component: <SuperUpdate
                        setMainData={setMainData}
                        api={"projectsAPI"}
                        mainTitle={main_title}
                        formHeader={"تعديل شراء ادوية وتحصينات"}
                        table={table}

                    />
            },
            {
                ref: deleteRef,
                component: <SuperDelete
                    api={""}
                    setMainData={setMainData}
                    table={table}
                />
            },
        ]

    return (
        <>
            <PageMeta title={main_title}/>


            <QuickViewContainer views={views}/>

            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={"قائمة فواتير شراء الادوية و التحصينات"}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};

export default DrugsAndFortificationsReports;
