import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import useFetch from '../../hooks/useFetch'
import { apis } from '../../config/apis'
import InputSelect from '../FormControls/InputSelect'

function InputMassUnit(props) {

    const [state , setState] = useState("")
    const {data} = useFetch(apis.units.mass.all())

    
    const options = useMemo(() => {
        return data?.map((unit) => {
            return {value : unit.unit , text : unit.ar_name}
        })
    } , [data])


    const changeHandler = (e) => {
        setState(e.target.value)
        props.setData((pre)=>{
            return {
                ...pre,
                [props.name ?? "measuringUnit"] : e.target.value
            }
        })
    }


    useEffect(() => {
        setState(props.value)
    } , [props.value])

  return  <InputSelect name={ props.name ??  'measuringUnit' } className={props.className ?? 'col-4'} title={props.title ?? 'وحدة القياس'} defaultValue={state} handleInputChange={changeHandler}  options={options ?? []} />
  

}

InputMassUnit.propTypes = {
    name : PropTypes.string,
    title : PropTypes.string,
    value : PropTypes.string,
    setData : PropTypes.func,
    size : PropTypes.string
}

export default InputMassUnit



