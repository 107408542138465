

import {
    useFetch,
    TableView,
    TableHeader,
    useRef,
    useState,
    PageMeta,
    Signboard,
    QuickViewContainer,
    QuickView,
    SuperCreate,
    addColumn,
    InputDate,
    InputNumber,
    InputText,
    extractTableData,
    InputTextArea, SuperUpdate, SuperDelete
} from '../../../../../../config/imports';
import {projectsAPI} from "../../../../projects.helper";
import SelectOperator from "../../../../../../components/Shared/SelectOperator";

const ProjectsOthersExpensesOperationsInvoices = () => {
    const {
        // data,
        setData: setMainData,
        error,
        isLoading
    } = useFetch(projectsAPI.feed);
    const data = [
        {
            date: new Date(),
            inv_number: 10,
            type: "15515",
            buy_quantity: 485,
            buy_value: 23,
            consumption_quantity: 135,
            consumption_value: 7789,
            remaining_quantity: 963,
            quantity_value: 105,

        }, {
            date: new Date(),
            inv_number: 1,
            type: "963258",
            buy_quantity: 23,
            buy_value: 15,
            consumption_quantity: 10,
            consumption_value: 65,
            remaining_quantity: 20,
            quantity_value: 75,
        }
    ]

    const createRef = useRef();
    const createOrderRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();


    const buttons = [
        {
            title: "أضافة تقرير مصروفات أخرى",
            onclick: () => {
                createRef.current.toggle();
            },
        },
        {
            title: "الطلبية",
            onclick: () => {
                createOrderRef.current.toggle();
            },
        },
    ];

    const main_title = "قائمة تقارير حركة المصروفات الأخرى";


    const table = [
        addColumn("", "م", ''),
        {
            gen: {name: "date", title: "التاريخ", elm: InputDate},
            table: {col: 1, custom: itm => new Date(itm.date).toLocaleDateString("ar-EG")},
            create: {props: {}, value: ''},
            update: {}
        },
        addColumn('inv_number', 'رقم الفاتورة', InputNumber),
        addColumn('type', 'نوع العلف', InputText),
        addColumn('buy_quantity', 'كمية الشراء', InputNumber),
        addColumn('buy_value', 'قيمة الشراء', InputNumber),
        addColumn('consumption_quantity', 'كمية الاستهلاك', InputNumber, 1),
        addColumn('consumption_value', 'قيمة الاستهلاك', InputNumber, 2),
        addColumn('remaining_quantity', 'الكمية المتبقية', InputNumber),
        addColumn('quantity_value', 'قيمة الكمية', InputNumber),
        addColumn("settings", "", ''),

    ];


    const order_table = [
        addColumn("", "م", ''),

        addColumn('name', 'الأسم', InputText, 2),
        addColumn('type', 'الكمية', InputText , 1),
        addColumn('operator', 'المورد', SelectOperator , 1),
        addColumn('quantity', 'الكمية', InputNumber , 2 , { lg : 12 , md : 12 }),
        addColumn('extra', 'معلومات إضافية', InputTextArea),
        addColumn("settings", "", ''),
    ];
    return (
        <>
            <PageMeta title={main_title}/>


            <QuickView ref={createRef}>
                <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"أضافة تقرير  حركة مصروفات أخرى"}
                    table={table}
                />
            </QuickView>
       <QuickView ref={createOrderRef}>
                <SuperCreate
                    setMainData={setMainData}
                    api={projectsAPI}
                    mainTitle={main_title}
                    formHeader={"اضافة طلبية"}
                    table={order_table}
                />
            </QuickView>

            <QuickView ref={updateRef}>
                <SuperUpdate
                    setMainData={setMainData}
                    api={"projectsAPI"}
                    mainTitle={main_title}
                    formHeader={"تعديل  تقرير  حركة مصروفات أخرى"}
                    table={table}

                />
            </QuickView>

            <QuickView ref={deleteRef}>
                <SuperDelete
                    api={""}
                    setMainData={setMainData}
                    table={table}
                    // names = { ['name' , 'age' ]}
                />
            </QuickView>

            <Signboard error={error} loading={isLoading}/>

            <TableHeader
                title={main_title}
                buttons={buttons}
            />


            <TableView body={extractTableData(table)} data={data} updateRef={updateRef} deleteRef={deleteRef}/>
        </>
    );
};

export default ProjectsOthersExpensesOperationsInvoices;
