import AppRouter from "./components/AppRouter";
import ErrorBoundary from "./services/ErrorBoundary";
import {AuthContextProvider} from "./context/AuthContext";
import ToastMessage from "./components/ui/toast/Toast";

function App() {
  return(
          <AuthContextProvider>
              <AppRouter />
              <ToastMessage />
          </AuthContextProvider>
  )

}

export default App;
