import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { reports_api } from "../../../../../config/apis";
import { createFormHandler, setInputsToDefault } from "../../../../../helpers/form.helper";
import { _date, EditForm, InputDate, InputNumber, InputSelect, InputTextArea, PageMeta, Signboard } from "../../../../../config/imports";
import useStockDrugs from "../../../../stocks/hooks/useStockDrugs";
import useDebounce from "../../../../../hooks/useDebounce";
import { useDrugReportQuantityPrice } from "../../hooks/useReportQuantityPrice";

export default function Create(props){

    const {id} = useParams();
    const {setRefresh, toggle} = props;

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");



    const {options : drugsOptions} = useStockDrugs("drug")    


    const [data , setData] = useState({
        date : new Date().toISOString(),
        home : id,
        sub_category : "",
        unit : "",
        notes : '',
        consumption : '',
    })

    const handleInputChange = (e) => {
        const {name, value, type} = e.target;
        setData((prev) => ({
            ...prev,
            [name]: type === "number" ? +value : value,
        }));
    };


    const units = useMemo(() => {
        return [
            { text : "قطعه" , value : "piece" },
        ]
    } , [])



    const debouncedConsumption = useDebounce(data.consumption)

    const {price , fetchPrice  , loading : loadingPrice } = useDrugReportQuantityPrice({
        unit : data.unit , consumption : debouncedConsumption , sub_category : data.sub_category 
    })

    useEffect(() => {
        fetchPrice()
    } , [data.unit , debouncedConsumption , data.sub_category])
    






    const handleFormSubmit = async (e) => {
        setLoading(true);

        e.preventDefault();

        const create_props = {
            url: reports_api.home.drugs.create(),
            data : {
                ...data,
                date : _date(data.date).toZeroUTC()
            },
            setLoading,
            setError,
            setSuccess,
            setMainData : ()=>{},
            setRefresh,
            setInputsToDefault,
            setData,
            FORM_HEADER: `الاضافة`,
        };

        // console.log(create_props.data);
        await createFormHandler(create_props);
    };

    return(
       <>
           <PageMeta title={'اضافة تقرير ادوية  يومي'} originTitle={'تقارير الادوية '}/>
           <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
               <h3>اضافة تقرير  ادوية  يومي</h3>
           </header>
           <Signboard error={error} success={success} loading={loading}/>
           <EditForm visible={true} onSubmit={handleFormSubmit} toggle={toggle} onChange={handleInputChange}>
               <InputDate className="col-6"  name={'date'}  handleInputChange={handleInputChange}  defaultValue={_date(data.date).forInputDate()}  title={'التاريخ'} value={data.date}/>
               <InputSelect className="col-6"  name={'sub_category'}  handleInputChange={handleInputChange}  options={drugsOptions}  title={'نوع الدواء'} defaultValue={data.sub_category}  />
               <InputNumber className="col-4"  name={'consumption'}  handleInputChange={handleInputChange}  defaultValue={data.consumption}  title={'الكمية'} value={data.consumption}/>
               <InputSelect className="col-4"  name={'unit'}  handleInputChange={handleInputChange}  options={units}  title={'وحدة القياس'} defaultValue={data.unit}  />
               <InputNumber className="col-4" disabled   placeholder={""}  labelProps={{ content : loadingPrice ?
                                <div className="flex justify-between items-center w-full">
                                <span  > قيمة الاستهلاك </span>
                               <i className="fa-solid fa-spinner fa-spin mx-2" />
                            </div> : ' قيمة الاستهلاك  ' }}   defaultValue={price}/>
               <InputTextArea name='notes'   handleInputChange={handleInputChange} placeholder={' ملاحظات على تقارير الادوية... '}  title={'ملاحظات'} value={data.notes}/>
           </EditForm>
       </>
    )
}
