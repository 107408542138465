import React, { forwardRef } from 'react';
import "../../../styles/inputRadio.styles.scss";
import Label from './base/Label';

const InputRadio = props => {
  const handleRadioChange = (e) => {
    console.log(e.target.checked);
    props.handleInputChange(e);
  }

  return (
    <div className={props.className ? props.className + ' input-radio ' :  " input-radio col-4 "}>
      <div className="tg-list" dir="ltr">
        <div className="tg-list-item">
          <Label {...props} />
          <input 
            className="tgl tgl-ios" 
            id="cb2" 
            checked={props.value ?? props.defaultValue ?? false} 
            name={props.name}
            onChange={handleRadioChange}
            type="checkbox"
          />
          <label className="tgl-btn" htmlFor="cb2"></label>
        </div>
      </div>
    </div>
  );
}

export default InputRadio;
