import {
    _date,
    Grid,
    Icons,
    PageMeta,
    QuickView,
    Signboard,
    Table,
    TableHeader,
    useFetch,
    useRef
} from "../../../config/imports";
import {originAPI} from "../../origin/origin.helpers";
import React from "react";
import {paths} from "../../../config/configs.env";
import {apis} from "../../../config/apis";
import OriginMaintenanceCrud from "../../origin/items_cards/maintenance_crud";
import OpeningBalanceCrud from "./opening_crud";
const MAX_HEIGHT_PADDING = 3 , ROW_HEIGHT = 55;

export default function OpeningBalancesIndex(props){
    const createRef = useRef();
    const updateRef = useRef();
    const deleteRef = useRef();

    const {data, error, setData: setMainData, isLoading} = useFetch(apis.balances.init.all());
    const  { data : years , error : yearsError , isLoading : yearsIsLoading }  = useFetch(apis.origins.weightBalance.years())



    const signboard =  {
        error : error ,
        loading : isLoading
    }

    const sizes = {
        serial : 1,
        year : 4,
        createdAt : 6,
        updatedAt : 6,
        settings : 6,
        name : 8,
        price : 8,
        amount : 7
    }
    const header = {
        serial: <Grid.Span size={sizes.serial}>#</Grid.Span>,
        year:<Grid.Span size={sizes.year}> العام  </Grid.Span>,
        createdAt:<Grid.Span size={sizes.createdAt}>   تاريخ الانشاء    </Grid.Span>,
        updatedAt:<Grid.Span size={sizes.updatedAt}>   تاريخ احر تعديل      </Grid.Span>,
        settings:<Grid.Span size={sizes.settings}> </Grid.Span>,
    }

    const weightHeader = {

        serial: <Grid.Span size={sizes.serial}>#</Grid.Span>,
        name:<Grid.Span size={sizes.name}> الاسم  </Grid.Span>,
        price:<Grid.Span size={sizes.price}>   السعر   </Grid.Span>,
        amount:<Grid.Span size={sizes.amount}>    الكميه       </Grid.Span>,

    }
    const accordionHandler = (e , height)=>{

        const maxHeight = (height + MAX_HEIGHT_PADDING) * ROW_HEIGHT + 'px'
        const defaultHeight = ROW_HEIGHT + 'px'

        const element = e.target.closest('.data-accordion-row')

        const accordions = [...document.querySelectorAll('.data-accordion-row')];
        accordions.forEach(item => {
            item.classList.remove('active-accordion')
            item.style.maxHeight = defaultHeight
        })
        element.classList.add('active-accordion')
        element.style.maxHeight = maxHeight
    }


    const closeAccordion = ()=>{
        const accordions = [...document.querySelectorAll('.data-accordion-row')];
        accordions.forEach(item => {
            item.classList.remove('active-accordion')
            item.style.maxHeight = ROW_HEIGHT + 'px'
        })
    }
    return(
            <div className={'opening-balance'}>

                <QuickView ref={createRef}><OpeningBalanceCrud.Create  years={years}   setMainData={setMainData}/></QuickView>
                <QuickView ref={updateRef}><OpeningBalanceCrud.Update  setMainData={setMainData}/></QuickView>
                <QuickView ref={deleteRef}><OpeningBalanceCrud.Delete  setMainData={setMainData}/></QuickView>

                <PageMeta title={"الارصدة الافتتاحيه"} />

                <TableHeader  buttons={ [
                    {
                        title: " اضافة   رصيد افتتاحي ",
                        onclick: () => createRef.current.toggle()
                    }
                ]}/>

                <Signboard {...signboard} />
                <div className="" style={{marginTop: '100px'}}></div>
                <div className={'text-start close-accordion'} onClick={ closeAccordion }>
                    <p className={'mb-0 text-underline flex '}>  <Icons.Collapse className="mx-2" /> اغلق  التفاصيل </p>
                </div>
                <Table.Header header={header}   />
                {
                    data &&  data.length > 0  && data.map((item , index) => (
                        <div className="data-accordion-row" key={item._id}>
                            <Grid.FlexResponsive   className={'align-items-center data-row'}>
                                <Grid.Span size={sizes.serial}>{index + 1}</Grid.Span>
                                <Grid.Span size={sizes.year}>{item.year}</Grid.Span>
                                <Grid.Span size={sizes.createdAt}> {_date(item.createdAt).dmy()} </Grid.Span>
                                <Grid.Span size={sizes.updatedAt}> {_date(item.updatedAt).dmy()} </Grid.Span>
                                <Grid.Span size={sizes.settings} className='settings-buttons d-flex justify-content-end'>
                                    <button  onClick={()=> updateRef.current.toggle(item)}><Icons.Edit /></button>
                                    <button  onClick={()=> deleteRef.current.toggle(item)}><Icons.Delete /></button>
                                    <button  onClick={(e) => accordionHandler(e  , item.weights.length) }><Icons.Expand /></button>
                                </Grid.Span>
                            </Grid.FlexResponsive>
                            <div className="data-accordion-body">
                                <Table.Header style={{marginTop: '0px !important'}}  header={weightHeader}  className={'data-accordion-header'} />
                                {
                                    item?.weights?.map((weight , weightIndex) =>{
                                        return (
                                            <Grid.Flex key={weight._id} className={'data-row'}>
                                                <Grid.Span size={sizes.serial}>{weightIndex + 1}</Grid.Span>
                                                <Grid.Span size={sizes.name} > {weight.weight?.name ?? 'غير محدد'} </Grid.Span>
                                                <Grid.Span size={sizes.price} > {weight.price ?? ''} </Grid.Span>
                                                <Grid.Span size={sizes.amount} > {weight.amount ?? ''} </Grid.Span>
                                            </Grid.Flex >
                                        )
                                    })
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
    )
}
