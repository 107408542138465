import React from 'react';
import PreviewStockInvoice from './PreviewStockInvoice';
import PreviewProjectInvoice from './PreviewProjectInvoice';
import PreviewEggsSellInvoice from './PreviewEggsSellInvoice';


export default function PreviewInvoice(props) {


  if(props.item?.invoice_type === "stock" ) return <PreviewStockInvoice {...props} />

  if(props.item?.invoice_type === "project" ) return <PreviewProjectInvoice {...props} />

  if(props.item?.__type === "eggSellInv") return <PreviewEggsSellInvoice {...props} />


  else {
    return <div>
      <h1>Not Found</h1>
      <p className='w-[90%] mx-auto '>{
        `invoice destination : ${props.invoice_type} not found , 
        invoice type : ${props.__type} not found
        `}</p>
    </div>

  }
}
