import Create from './Create';
import Update from './Update';
import Delete from './Delete';
import ResetPassword from './ResetPassword';
import ToggleUserActiveState from "./ToggleUserActiveState";
const UsersCrud = {
    Create,
    Update,
    Delete,
    Password : ResetPassword ,
    Activate : ToggleUserActiveState
}
export default UsersCrud
