import { useParams } from "react-router-dom";
import { _date, InputSelect, PageMeta, postData, QuickView, Signboard, TableHeader, useFetch } from "../../../../config/imports";
import { apis, reports_api } from "../../../../config/apis";
import { useEffect, useMemo, useRef, useState } from "react";
import ReportsCrud from "../reports_crud";
import { Table } from "../../../../layout/table";
import Helpers, { objectHasSomeValues, objectHasValues } from "../../../../helpers/helpers";
import ScrollX from "../../../../layout/ScrollX";
import useHomeType from "../hooks/useHomeType";
import DateFilter from "../../../approvals/components/DateFilter";
import { CSVLink } from 'react-csv';
import { showErrorToast } from "../../../../stores/toast.store";
import { BsFiletypeCsv } from "react-icons/bs";


export default function DeadDailyReportForProductionHome(props){
    const {id} = useParams();
    const deleteRef = useRef(),
        createRef = useRef(),
        updateRef = useRef();


    const {hasRoosterInfo} = useHomeType()

    const api = reports_api.home.dead.read(id)


    const  { data , setData , alert , paginate , setters , setRefresh }  = useFetch( api  );

    const {data : ages } = useFetch(apis.reports_api.home.dead.getAges(id))


    const [filterData , setFilter] = useState([])
    const [total , setTotal] = useState({
        startDate : "",
        endDate : "",
        total : ""
    })

    const [query , setQuery] = useState({
        age : "",
        start_date : "",
        end_date : "",
    })




    const agesOptions = useMemo(()=>{
        if(!ages) return []
        const _ages = ages?.map((item) => item?.age).filter((item) => item)
        const removedDuplicates = [...new Set(_ages)]
        const options =  removedDuplicates?.map((item) => ({value : item , text : item}))
        return [{value : "all" , text : "الكل"} , ...options]
    } , [ages])





      const headers  = [
        { key : "date" , label : "التاريخ"   },
        { key : "age" , label : "عمر الطير"   },
        { key : "chicken" , label : " عدد الدجاج  "  },
        { key : "dead_chicken" , label : "نافق الدجاج"   },
        { key : "dead_chicken_percentage" , label : " نسبة النافق"   },
        hasRoosterInfo &&  {  key : "rooster" , label : " عدد الديوك  "   },
        hasRoosterInfo &&  {  key : "dead_rooster" , label : "نافق الديوك"   },
        hasRoosterInfo &&  {  key : "dead_rooster_percentage" , label : " نسبة النافق"   },
        hasRoosterInfo &&  {  key : "total_birds" , label : " اجمالي الطيور"   },
        hasRoosterInfo &&  {  key : "total_dead" , label : " اجمالي النافق"   },
        hasRoosterInfo &&  {  key : "total_dead_percentage" , label : " نسبة النافق"   },
      ].filter(c => c)


     
      const csvData = useMemo(() => {

        if(!filterData) return []

       const rows =   filterData.map( d => {
            const commaFormat = (number) => Helpers.Number.commaFormat(number)
            return {
                date : _date(d.date).dmy() ,
                age : d.age,
                chicken :commaFormat(d?.chicken),
                dead_chicken :commaFormat(d?.dead_chicken),
                dead_chicken_percentage :d?.dead_chicken_percentage,
                rooster :commaFormat(d?.rooster),
                dead_rooster :commaFormat(d?.dead_rooster),
                dead_rooster_percentage :commaFormat(d?.dead_rooster_percentage),
                total_birds :commaFormat(d?.total_birds),
                total_dead :commaFormat(d?.total_dead),
                total_dead_percentage : d.total_dead_percentage
            }
        })



        return rows
          
      } , [filterData]) 



    
    useEffect(() => {

        const fetch = async (query) => {
            const response = await postData(reports_api.home.dead.dateFilter(id) , query)
            if(response.error) return showErrorToast(response.error)

            setData(response.data.paginated)
            setters.paginate(response.paginate)
            setFilter(response.data.all)

            setTotal({
                startDate : response.data.startDate,
                endDate : response.data.endDate,
                total : response.data.total
            })

        }

        
      
        if(query.age && query.start_date && query.end_date){ 
             fetch(query)
        }else if(query.start_date && query.end_date) {
             fetch({start_date : query.start_date , end_date : query.end_date})
        }else if(query.age){
              fetch({age : query.age})
        }

      

    } , [query])



    const table = [
        {size : 1 , name : "#" , text : "#"  },
        {size : hasRoosterInfo ? 2 : 4 , name : "date" , text : "التاريخ"  , custom : d => _date(d.date).dmy() },
        {size : hasRoosterInfo ? 1 : 4 , name : "age" , text : "عمر الطير"  , custom : d => Helpers.Number.commaFormat( d?.age ?? "-") },
        {size : hasRoosterInfo ? 2 : 4 , name : "chicken" , text : " عدد الدجاج  " , custom : d => Helpers.Number.commaFormat( d?.chicken )  },
        {size : hasRoosterInfo ? 2 : 4 , name : "dead_chicken" , text : "نافق الدجاج"  , custom : d => Helpers.Number.commaFormat( d?.dead_chicken ) },
        {size : hasRoosterInfo ? 2 : 4 , name : "dead_chicken_percentage" , text : " نسبة النافق"  , custom : d => Helpers.Number.commaFormat( ((d?.dead_chicken / d?.chicken) * 100).toFixed(2) ) + " %" },
        hasRoosterInfo &&  {size :  2 , name : "rooster" , text : " عدد الديوك  "  , custom : d => Helpers.Number.commaFormat( d?.rooster ) },
        hasRoosterInfo &&  {size :  2 , name : "dead_rooster" , text : "نافق الديوك"  , custom : d => Helpers.Number.commaFormat( d?.dead_rooster ) },
        hasRoosterInfo &&  {size :  2 , name : "dead_rooster_percentage" , text : " نسبة النافق"  , custom : d => Helpers.Number.commaFormat( ((d?.dead_rooster / d?.rooster) * 100).toFixed(2) ) + " %" },
        hasRoosterInfo &&  {size :  2 , name : "total_birds" , text : " اجمالي الطيور"  , custom : d => Helpers.Number.commaFormat( d?.chicken + d?.rooster ) },
        hasRoosterInfo &&  {size :  2 , name : "total_dead" , text : " اجمالي النافق"  , custom : d => Helpers.Number.commaFormat( d?.dead_chicken + d?.dead_rooster ) },
        hasRoosterInfo &&  {size :  2 , name : "total_dead_percentage" , text : " نسبة النافق"  , custom : d => Helpers.Number.commaFormat( (((d?.dead_chicken + d?.dead_rooster)/ (d?.chicken + d?.rooster)) * 100).toFixed(2) ) + " %" },
        {size : 1 , name : '' , text : '' , }
      ]




      const totalRow = (data) => {
          if(!data) return undefined

    
        const totalChickens =  data?.slice(-1)[0]?.chicken
        const lastAge  =  data?.slice(-1)[0]?.age 
        const firstDate =  data?.slice(0)[0]?.date
        const lastDate =  data?.slice(-1)[0]?.date
        const date = _date(firstDate).dmy() + " / " + _date(lastDate).dmy()
        if(!hasRoosterInfo){
            return {
                age : lastAge ,
                date ,
                chicken : totalChickens ,
                dead_chicken : data.reduce((acc , cur) => acc + cur.dead_chicken , 0), 
                dead_chicken_percentage : data.reduce((acc , cur) => acc + cur.dead_chicken_percentage , 0), 
            }
        }else {
            const totalRoosters =  data?.slice(-1)[0]?.rooster

            return {
                age : lastAge ,
                date ,
                chicken : totalChickens ,
                rooster : totalRoosters ,
                dead_chicken : data.reduce((acc , cur) => acc + cur.dead_chicken , 0), 
                dead_rooster : data.reduce((acc , cur) => acc + cur.dead_rooster , 0), 
                dead_chicken_percentage : data.reduce((acc , cur) => acc + cur.dead_chicken_percentage , 0), 
                dead_rooster_percentage : data.reduce((acc , cur) => acc + cur.dead_rooster_percentage , 0),
            }
        }



      }



      const buttons =  [
            {
                title: " اضافة تقرير نافق جديد",
                onclick: () => createRef.current.toggle()
            }
        ]


    const fileName = "تقرير النافق من  " + _date(total.startDate).dmy()  + " الي " + _date(total.endDate).dmy()

    const isTotalDataAvailable = !!(objectHasValues(data) || objectHasValues(total));


   
    return(
        <>
            <Signboard {...alert}/>
            <PageMeta title={'تقارير النافق اليوميه'} />
            <QuickView ref={createRef}><ReportsCrud.Dead.Create   setRefresh={setRefresh}/></QuickView>
            <QuickView ref={updateRef}><ReportsCrud.Dead.Update   setRefresh={setRefresh}/></QuickView>
            <QuickView ref={deleteRef}><ReportsCrud.Dead.Delete   setRefresh={setRefresh}/></QuickView>
            <TableHeader  buttons={buttons}  api={api} setData={setters.data} setLoading={setters.loading}  paginate={paginate} />

            <div className="flex my-5 mb-2  items-end">
               <div className="flex items-center">
               <span className="ml-2">اختر المده</span>
                <div className="w-[300px]">
                    <DateFilter setQuery={setQuery} setLoading={setters.loading}  />
                </div>
               </div>
              <div className="flex items-center mr-[15px]">
                <span className="mx-2 mt-[-10px] block">اختر العمر</span>
              <div className="w-[300px] mx-2 ">
                    <InputSelect hideLabel={true}   className="w-full mb-[-10px]"    options={agesOptions} handleInputChange={(e) => setQuery({ ...query, age: e.target.value })}    />
                </div>
              </div>
                <div className="w-fit mr-auto">

                    {
                        (!objectHasSomeValues(query) && data) ? (
                            <CSVLink data={[...data?.all?.map(d => {return {...d , date : new Date(d.date).toISOString().split("T")[0]  }}) , totalRow(data?.all)]} 
                            className="flex  items-center bg-[#f3f3f3] px-3 py-2 rounded" 
                            headers={headers.reverse()} 
                            filename={ "تقرير النافق من  " + _date(data?.startDate).dmy()  + " الي " + _date(data?.endDate).dmy() }
                        >
                            <BsFiletypeCsv  className="text-xl"/>
                            <span className="mx-3 capitalize">download all ({data?.all?.length})</span>
                        </CSVLink>

                        ) : (
                            <CSVLink data={[...csvData , totalRow(csvData)]} 
                                className="flex  items-center bg-[#f3f3f3] px-3 py-2 rounded" 
                                headers={headers.reverse()} 
                                filename={fileName}
                            >
                                <BsFiletypeCsv  className="text-xl"/>
                                <span className="mx-3 capitalize">download filtered ({filterData?.length ?? 0 })</span>
                            </CSVLink>
                        )
                    }


                


                </div>
            </div>




            <ScrollX>
                <Table data={data?.paginated ?? data} table={table}  />
            </ScrollX>
            {
                isTotalDataAvailable && (
                    <div className="flex items-center px-4 py-3 bg-[#0CC857] text-[18px] font-[500]  rounded-md ">
                    <div className="flex flex-1 ">
                        <span className="text-white">تاريخ اول تقرير</span>
                        <span className="mx-2 text-white">:</span>
                        <span className="text-white">{_date(data?.startDate ?? total?.startDate).dmy()}</span>
                    </div>
                    <div className="flex flex-1 ">
                        <span className="text-white">تاريخ اخر تقرير</span>
                        <span className="mx-2 text-white">:</span>
                        <span className="text-white">{_date(data?.endDate ?? total?.endDate).dmy()}</span>
                    </div>
                    <div className="flex flex-1 ">
                        <span className="text-white">المجموع</span>
                        <span className="mx-2 text-white">:</span>
                        <span className="text-white">{(data?.total ?? total.total)}</span>
                    </div>
                </div>
                )
            }
           
        </>
    )
}