import {
  _date,
  createFormHandler,
  deleteFormHandler,
  EditForm,
  Signboard,
  PageMeta,
} from "../../../../config/imports";
import { apis } from "../../../../config/apis";
import { useState } from "react";
import DeleteTable from "../../../../layout/table/DeleteTable";
export default function Delete(props) {
  const { setMainData, toggle, item } = props;

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [confirmBtn, setConfirmBtn] = useState(true);

  let signboard = {
    error: error,
    success,
    loading: loading,
  };

  const table = [
    { size: 4, name: "name", text: "الاسم" },
    { size: 4, name: "delegate", text: "اسم المندوب" },
    { size: 4, name: "phone", text: "رقم الهاتف" },
    { size: 4, name: "mobile", text: "رقم الجوال" },
    { size: 4,name: "createdAt",text: "تاريخ الانشاء",  custom: (d) => _date(d.createdAt).dmy(), },
    { size: 3, name: "updatedAt", text: "تاريخ اخر تعديل", custom: (d) => _date(d.updatedAt).dmy(), },
  ];

  const handleDelete = async () => {
    const delete_props = {
      url: apis.incubator.client.delete(),
      id: item._id,
      setError,
      setSuccess,
      setLoading,
      setConfirmBtn,
      setMainData,
      item,
    };

    // console.log(delete_props)
    await deleteFormHandler(delete_props);
  };

  return (
    <>

      <header className="w-fit m-auto px-4 text-center border-bottm-line ">
        <h3> حذف عميل الصوص ؟</h3>
      </header>
      <Signboard {...signboard} />

      <DeleteTable table={table} data={[item]} />

      <div className="col-12 col-md-12 mt-4">
        <div className="form-group row justify-content-between align-items-center">
          <div className="col">
            <button className="close-btn" onClick={() => toggle()}>
              {confirmBtn ? "الغاء" : "اغلاق"}
            </button>
          </div>
          <div className="col d-flex justify-content-end">
            {confirmBtn && (
              <button className="add-btn" onClick={handleDelete}>
                نعم
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
