import InputSelect from "../../../../components/form/inputs/InputSelect";
import {InputNumber, InputText} from "../../../../config/imports";

const livingInputs= [
    {name : 'name', title :  'الاسم', input :  InputText},
    {name : 'biological_num', title :  'عدد الأصل', input :  InputNumber},
    {name : 'location', title :  'موقعها', input :   InputText },
    { name :  'level' , title :  'المستوى', input :  InputSelect , options : [
            { value :  'أشجار' , text : 'أشجار' },
            { value :  'طيور' , text : 'طيور' },
    ]},
    {name : 'characteristic', title :  'صفة الأصل', input :  InputNumber },
    {name : 'value', title :  ' قيمة الأصل', input :  InputNumber },
]
export default livingInputs
