// import { useEffect } from "react";

const FormModal = ({ component, state, setState }) => {
  return (
    <>
      {state === true && (
        <div className="modal-container">
          <div className="form-modal p-3 row ">
            <div className="col-11 col-md-10 col-sm-11 col-lg-6 bg-white p-5 modal-container-content">
              <div className="modal-component">{component}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FormModal;
