import Map from "../../components/Map";
import Helpers from "../../helpers/helpers";
import { key } from "../../helpers/id.helper";
import Span from "../../layout/grid/Span";


export const weightsMap =   (w) => <Span size={3}  className={"border-b-[1px]"}   key={w._id}>{ Helpers.Number.commaFormat(w.amount || 0) }</Span>

export const totalWeightsMap = (rest) =>{

    return (w , index) => <Span size={3}  className=" bg-[#41e481]/90 text-white"   key={key()}>{Helpers.Number.commaFormat( rest?.totals_egg_for_weight?.find(t =>  t.weight_name === w)?.amount || 0)}</Span>


}

export const eggsTableMap = (carton , index) =>{

    const isOdd = index % 2 === 1

    return <div className={`flex py-1 hover:!opacity-[.8]`} key={carton._id}>
        <Span size={5}  className={"border-b-[1px]"} >{carton.name}</Span>

        <Map data={carton?.weights} map={weightsMap} />

    </div>


}



export const displayWeightNamesMap =  (w) => <Span size={3} className="bg-[#fcaa30] text-white"    key={key()}>{w}</Span>