import {RouterLinks} from "../../components/LinkList";
import useNavigator from "../../hooks/useNavigator";
import "../../styles/billing.styles.scss"
import {useFetch} from "../../config/imports";
import {apis} from "../../config/apis";

export default function BillingManagementIndex(){

    const {data} = useFetch(apis.origins.weightBalance.green() )

    // console.log({data})

    const BILLING_MANAGMENT_LINKS = [
        {
            name: "الارصدة الافتتاحيه",
            path: "opening-balances",
        },
        {
            name: "أرصدة الأوزان",
            path: "weight-balances",
        },
        // {
        //     name: " قائمة فواتير البيع",
        //     path: "sales-invoices",
        // },
        // {
        //     name: "قائمة وحدات تخزين البيض",
        //     path: "egg-storage-units",
        // },
        // {
        //     name: "احصائيات مهمة ",
        //     path: "important-stats",
        // },
    ];

    useNavigator("billing-management", "weight-balances")

    return (
        <div className="billing-management-container">
            <div className="row justify-content-around my-5">
                <div  className="green-info-box span-4 row  p-0  text-white r-16 ">

                    <div className="col-4">
                        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M40.44 3.5H34.56C32.02 3.5 30.5 5.02 30.5 7.56V13.44C30.5 15.98 32.02 17.5 34.56 17.5H40.44C42.98 17.5 44.5 15.98 44.5 13.44V7.56C44.5 5.02 42.98 3.5 40.44 3.5ZM40.88 10.12C40.64 10.36 40.32 10.48 40 10.48C39.68 10.48 39.36 10.36 39.12 10.12L38.76 9.76V14.24C38.76 14.94 38.2 15.5 37.5 15.5C36.8 15.5 36.24 14.94 36.24 14.24V9.76L35.88 10.12C35.4 10.6 34.6 10.6 34.12 10.12C33.64 9.64 33.64 8.84 34.12 8.36L36.62 5.86C36.72 5.76 36.86 5.68 37 5.62C37.04 5.6 37.08 5.6 37.12 5.58C37.22 5.54 37.32 5.52 37.44 5.52C37.48 5.52 37.52 5.52 37.56 5.52C37.7 5.52 37.82 5.54 37.96 5.6C37.98 5.6 37.98 5.6 38 5.6C38.14 5.66 38.26 5.74 38.36 5.84C38.38 5.86 38.38 5.86 38.4 5.86L40.9 8.36C41.38 8.84 41.38 9.64 40.88 10.12Z" fill="white"/>
                            <path d="M4.5 22.92V32.92C4.5 37.5 8.2 41.2 12.78 41.2H36.2C40.78 41.2 44.5 37.48 44.5 32.9V22.92C44.5 21.58 43.42 20.5 42.08 20.5H6.92C5.58 20.5 4.5 21.58 4.5 22.92ZM16.5 34.5H12.5C11.68 34.5 11 33.82 11 33C11 32.18 11.68 31.5 12.5 31.5H16.5C17.32 31.5 18 32.18 18 33C18 33.82 17.32 34.5 16.5 34.5ZM29.5 34.5H21.5C20.68 34.5 20 33.82 20 33C20 32.18 20.68 31.5 21.5 31.5H29.5C30.32 31.5 31 32.18 31 33C31 33.82 30.32 34.5 29.5 34.5Z" fill="white"/>
                            <path d="M27.5 9.22078V15.0808C27.5 16.4208 26.42 17.5008 25.08 17.5008H6.92C5.56 17.5008 4.5 16.3808 4.5 15.0408C4.52 12.7808 5.42 10.7208 6.92 9.22078C8.42 7.72078 10.5 6.80078 12.78 6.80078H25.08C26.42 6.80078 27.5 7.88078 27.5 9.22078Z" fill="white"/>
                        </svg>

                    </div>
                    <div className="col-8">
                        <p>الرصيد الافتتاحي </p>
                        <h5>{data?.opening_balance ?? 0}</h5>
                    </div>



                </div>
                <div  className="green-info-box span-4 row  p-0  text-white r-16 ">

                    <div className="col-4">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M32.38 4H15.62C8.34 4 4 8.34 4 15.62V32.36C4 39.66 8.34 44 15.62 44H32.36C39.64 44 43.98 39.66 43.98 32.38V15.62C44 8.34 39.66 4 32.38 4ZM18.22 33.8C18.22 34.36 17.78 34.8 17.22 34.8H11.64C11.08 34.8 10.64 34.36 10.64 33.8V24.56C10.64 23.3 11.66 22.28 12.92 22.28H17.22C17.78 22.28 18.22 22.72 18.22 23.28V33.8ZM27.78 33.8C27.78 34.36 27.34 34.8 26.78 34.8H21.2C20.64 34.8 20.2 34.36 20.2 33.8V15.48C20.2 14.22 21.22 13.2 22.48 13.2H25.52C26.78 13.2 27.8 14.22 27.8 15.48V33.8H27.78ZM37.36 33.8C37.36 34.36 36.92 34.8 36.36 34.8H30.78C30.22 34.8 29.78 34.36 29.78 33.8V26.7C29.78 26.14 30.22 25.7 30.78 25.7H35.08C36.34 25.7 37.36 26.72 37.36 27.98V33.8Z" fill="white"/>
                        </svg>
                    </div>
                    <div className="col-8">
                        <p>رصيد الإنتاج  </p>
                        <h5> {data?.production_balance ?? 0} </h5>
                    </div>


                </div>

                <div  className="green-info-box span-4 row  p-0  text-white r-16 ">

                    <div className="col-4">
                        <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 20.06C21 21.14 21.3 21.3 21.98 21.54L23 21.9V18.5H22.4C21.64 18.5 21 19.2 21 20.06Z" fill="white"/>
                            <path d="M26 29.4996H26.6C27.38 29.4996 28 28.7996 28 27.9396C28 26.8596 27.7 26.6996 27.02 26.4596L26 26.0996V29.4996Z" fill="white"/>
                            <path d="M39.66 10.96L35.56 15.06C35.26 15.36 34.88 15.5 34.5 15.5C34.12 15.5 33.74 15.36 33.44 15.06C32.86 14.48 32.86 13.52 33.44 12.94L37.54 8.84C34.02 5.84 29.48 4 24.5 4C13.46 4 4.5 12.96 4.5 24C4.5 35.04 13.46 44 24.5 44C35.54 44 44.5 35.04 44.5 24C44.5 19.02 42.66 14.48 39.66 10.96ZM28 23.64C29.28 24.1 31 25.02 31 27.96C31 30.46 29.02 32.52 26.6 32.52H26V33.02C26 33.84 25.32 34.52 24.5 34.52C23.68 34.52 23 33.84 23 33.02V32.52H22.84C20.18 32.52 18 30.28 18 27.52C18 26.68 18.68 26 19.5 26C20.32 26 21 26.68 21 27.5C21 28.6 21.82 29.5 22.84 29.5H23V25.06L21 24.36C19.72 23.9 18 22.98 18 20.04C18 17.54 19.98 15.48 22.4 15.48H23V15C23 14.18 23.68 13.5 24.5 13.5C25.32 13.5 26 14.18 26 15V15.5H26.16C28.82 15.5 31 17.74 31 20.5C31 21.32 30.32 22 29.5 22C28.68 22 28 21.32 28 20.5C28 19.4 27.18 18.5 26.16 18.5H26V22.94L28 23.64Z" fill="white"/>
                            <path d="M45.88 3.42C45.72 3.06 45.44 2.76 45.06 2.6C44.88 2.54 44.7 2.5 44.5 2.5H36.5C35.68 2.5 35 3.18 35 4C35 4.82 35.68 5.5 36.5 5.5H40.88L37.54 8.84C38.3 9.5 39 10.2 39.66 10.96L43 7.62V12C43 12.82 43.68 13.5 44.5 13.5C45.32 13.5 46 12.82 46 12V4C46 3.8 45.96 3.62 45.88 3.42Z" fill="white"/>
                        </svg>

                    </div>
                    <div className="col-8">
                        <p> سحب الفواتير</p>
                        <h5>{ data?.inv_balance ?? 0}</h5>
                    </div>


                </div>

                <div  className="green-info-box span-5 row  p-0  text-white r-16 ">

                    <div className="col-4">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M24 45.5008C16 45.5008 9.5 39.7608 9.5 32.7008V25.3008C9.5 24.4808 10.18 23.8008 11 23.8008C11.82 23.8008 12.5 24.4808 12.5 25.3008C12.5 30.5408 17.44 34.5008 24 34.5008C30.56 34.5008 35.5 30.5408 35.5 25.3008C35.5 24.4808 36.18 23.8008 37 23.8008C37.82 23.8008 38.5 24.4808 38.5 25.3008V32.7008C38.5 39.7608 32 45.5008 24 45.5008ZM12.5 32.9208C12.64 38.2208 17.74 42.5008 24 42.5008C30.26 42.5008 35.36 38.2208 35.5 32.9208C32.9 35.7408 28.78 37.5008 24 37.5008C19.22 37.5008 15.12 35.7408 12.5 32.9208Z" fill="white"/>
                            <path d="M24 27.5C18.48 27.5 13.52 25.02 11.1 21.02C10.06 19.32 9.5 17.34 9.5 15.3C9.5 11.86 11.04 8.62 13.82 6.18C16.54 3.8 20.16 2.5 24 2.5C27.84 2.5 31.44 3.8 34.18 6.16C36.96 8.62 38.5 11.86 38.5 15.3C38.5 17.34 37.94 19.3 36.9 21.02C34.48 25.02 29.52 27.5 24 27.5ZM24 5.5C20.88 5.5 17.96 6.54 15.78 8.46C13.66 10.3 12.5 12.74 12.5 15.3C12.5 16.8 12.9 18.2 13.66 19.46C15.56 22.58 19.52 24.5 24 24.5C28.48 24.5 32.44 22.56 34.34 19.46C35.12 18.2 35.5 16.8 35.5 15.3C35.5 12.74 34.34 10.3 32.2 8.42C30.02 6.54 27.12 5.5 24 5.5Z" fill="white"/>
                            <path d="M24 37.5C15.74 37.5 9.5 32.26 9.5 25.3V15.3C9.5 8.24 16 2.5 24 2.5C27.84 2.5 31.44 3.8 34.18 6.16C36.96 8.62 38.5 11.86 38.5 15.3V25.3C38.5 32.26 32.26 37.5 24 37.5ZM24 5.5C17.66 5.5 12.5 9.9 12.5 15.3V17.3C12.5 22.54 17.44 26.5 24 26.5C30.56 26.5 35.5 22.54 35.5 17.3V15.3C35.5 12.74 34.34 10.3 32.2 8.42C30.02 6.54 27.12 5.5 24 5.5Z" fill="white"/>
                        </svg>

                    </div>
                    <div className="col-8">
                        <p> رصيد المخزون الفعلي</p>
                        <h5>{data?.actual_inventory ?? 0}</h5>
                    </div>


                </div>

                <div  className="green-info-box span-5 row  p-0  text-white r-16 ">

                    <div className="col-4">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M37.6201 12.5009H40.3401C39.9601 11.9609 39.5601 11.4609 39.1401 10.9609L37.6201 12.5009Z" fill="white"/>
                            <path d="M37.04 8.84063C36.54 8.42063 36.04 8.02062 35.5 7.64062V10.3606L37.04 8.84063Z" fill="white"/>
                            <path d="M39.16 10.9609L45.06 5.06086C45.64 4.48086 45.64 3.52086 45.06 2.94086C44.48 2.36086 43.52 2.36086 42.94 2.94086L37.04 8.84086C37.8 9.50086 38.5 10.2209 39.16 10.9609Z" fill="white"/>
                            <path d="M35.5 6C35.5 5.18 34.82 4.5 34 4.5C33.2 4.5 32.56 5.14 32.52 5.92C33.56 6.42 34.56 6.98 35.5 7.64V6Z" fill="white"/>
                            <path d="M43.4998 14C43.4998 13.18 42.8198 12.5 41.9998 12.5H40.3398C40.9998 13.44 41.5798 14.44 42.0598 15.48C42.8598 15.44 43.4998 14.8 43.4998 14Z" fill="white"/>
                            <path d="M25.5 29.4996H26.1C26.88 29.4996 27.5 28.7996 27.5 27.9396C27.5 26.8596 27.2 26.6996 26.52 26.4596L25.5 26.0996V29.4996Z" fill="white"/>
                            <path d="M42.08 15.48C42.06 15.48 42.04 15.5 42 15.5H34C33.8 15.5 33.62 15.46 33.42 15.38C33.06 15.22 32.76 14.94 32.6 14.56C32.54 14.38 32.5 14.2 32.5 14V6C32.5 5.98 32.52 5.96 32.52 5.92C29.92 4.7 27.04 4 24 4C12.96 4 4 12.96 4 24C4 35.04 12.96 44 24 44C35.04 44 44 35.04 44 24C44 20.96 43.3 18.08 42.08 15.48ZM27.5 23.64C28.78 24.08 30.5 25.02 30.5 27.96C30.5 30.46 28.52 32.52 26.1 32.52H25.5V33.02C25.5 33.84 24.82 34.52 24 34.52C23.18 34.52 22.5 33.84 22.5 33.02V32.52H22.34C19.68 32.52 17.5 30.28 17.5 27.52C17.5 26.68 18.18 26 19 26C19.82 26 20.5 26.68 20.5 27.5C20.5 28.6 21.32 29.5 22.34 29.5H22.5V25.06L20.5 24.36C19.22 23.92 17.5 22.98 17.5 20.04C17.5 17.54 19.48 15.48 21.9 15.48H22.5V15C22.5 14.18 23.18 13.5 24 13.5C24.82 13.5 25.5 14.18 25.5 15V15.5H25.66C28.32 15.5 30.5 17.74 30.5 20.5C30.5 21.32 29.82 22 29 22C28.18 22 27.5 21.32 27.5 20.5C27.5 19.4 26.68 18.5 25.66 18.5H25.5V22.94L27.5 23.64Z" fill="white"/>
                            <path d="M20.5 20.06C20.5 21.14 20.8 21.3 21.48 21.54L22.5 21.9V18.5H21.9C21.14 18.5 20.5 19.2 20.5 20.06Z" fill="white"/>
                        </svg>
                    </div>
                    <div className="col-8">
                        <p>رصيد المخزون الكلي</p>
                        <h5>{data?.total_inventory ?? 0}</h5>
                    </div>

                </div>

            </div>
            <RouterLinks data={BILLING_MANAGMENT_LINKS} />
        </div>
    )


}
