import {
    PageMeta,
    createFormHandler,
    EditForm,
    Signboard,
    InputText,
    InputNumber,
    InputSelect,
    postData
} from "../../../../../config/imports";
import {useState} from "react";
import {apis} from "../../../../../config/apis";
import DisplayFileImagesGroup from "../../../../../components/images/DisplayFileImagesGroup";
import Axios from "../../../../../helpers/axios";
import ArrayHelpers from "../../../../../helpers/array.helpers";
import origin_cards_tables from "../../assests_forms";

export default function Create (props){

    const {setMainData, toggle} = props;


    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");
    const [data , setData] = useState({})

    let signboard = {
        error   ,
        success,
        loading ,
    }


    const handleInputChange = (e) => {
        const { name , value , type } = e.target;
        data[name] = type  === 'number' ? +value : value
        setData({
            ...data
        })
    };


    const handleFormSubmit = async (e) => {

        e.preventDefault();
        const create_props = {
            url: apis.assets.lands.create(),
            data,
            setLoading,
            setError,
            setSuccess,
            setData,
            setMainData  ,
        };

        await createFormHandler(create_props);
    };


 

    return(
        <>
            <PageMeta title={'إضافة الارض'} originTitle={' الارض'}/>

            <header className="text-center border-bottm-line w-fit px-3 pb-4 m-auto mb-3">
                <h3>إضافة الارض</h3>
            </header>

            <Signboard {...signboard}/>

            <EditForm
                visible={true}
                onSubmit={handleFormSubmit}
                toggle={toggle}
                onChange={handleInputChange}
            >
                    { ArrayHelpers.simpleInputsGenerator(origin_cards_tables["land"] , data , handleInputChange ) }

        
            </EditForm>


        </>
    )
}
