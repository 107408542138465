import PageMeta from "../../../../../components/PageMeta";

import RouterLinks from "../../../../../components/LinkList";
import useNavigator from "../../../../../hooks/useNavigator";

const BirdsNotes = () => {
    const BIRDS_NOTES_LINKS = [
        {
            name: "قائمة مدخلات الطيور",
            path: "inputs-menu",
        }
    ];

    useNavigator("birds-notes", "inputs-menu");

    return (
        <>
            <PageMeta title={"مذكرات الطيور"} />

            {<RouterLinks underline={true} data={BIRDS_NOTES_LINKS} />}
        </>
    );
};

export default BirdsNotes;
